import RotateCtrl from "./newRotateCtrl.vue";

import { getMouse3dDirection, rotateAroundAxisY, rotateAboutPoint } from "../editObject";
import { drawArrow, drawLine, drawTapArrow, drawPoint } from "../draw/draw.js";
import { rotatePoint2D, getPointHonBC, getPointB, calculateDistance2D } from "../geometry.js";

//#region fields 

const ctrlSize = 50;
var rotateCtrlsInitialized = false;
var clickPoint = new THREE.Vector3();
var normal = new THREE.Vector3();

var mesh = null;
var rotateAxis = null;
var needUpdate = false;

var rotateContext = {
	id: null, editedObject: null, tooth: null, isApexPlane: false, angle: 0,
	leftPosition: { editedObject: null, x: 0, y: 0, action: rotateLeft, iconPath: 'images/Rotate-left.png', display: 'none' },
	rightPosition: { editedObject: null, x: 0, y: 0, action: rotateRight, iconPath: 'images/Rotate-right.png', display: 'none' },
	moveLine: null,
	fromTapArrow: null,
	toTapArrow: null,
	from: null,
	to: null,
	apex: null,
	root: null
};
var allPositions = [
	rotateContext.leftPosition, rotateContext.rightPosition
];

//#endregion

//#region init 

function enterRotateMode(editedObject) {
	if (editedObject) {
		editedObject.isRotated = true;
		editedObject.isMoved = editedObject.isResized = false;

		rotateContext.id = editedObject.id;
		rotateContext.editedObject = editedObject;
		rotateContext.tooth = editedObject.tooth;
		rotateContext.angle = editedObject.sliderValue * Math.PI;

		//var mesh = 
		rotateContext.mesh = viewer.get_model_mesh(editedObject.id);
		// if (mesh && mesh.apexMesh) {
		// 	mesh.apexMesh.material.opacity = mesh.rootMesh.material.opacity = 1;
		// 	mesh.apexMesh.material.transparent = mesh.rootMesh.material.transparent = false;
		// }

		if (!rotateContext.tooth.isImplant) {
			allPositions.forEach(position => {
				position.display = 'block';
				position.editedObject = editedObject;
			});
		}

		updateRotateControlPositions();
		initRotateCtrls();
		rotateContext.apex = null;
		rotateContext.root = null;
		redrawRotateArrow();
	}
}

function exitRotateMode() {
	rotateContext.id = null;
	allPositions.forEach(position => position.display = 'none');

	var mesh = rotateContext.mesh;
	if (mesh && mesh.apexMesh) {
		mesh.apexMesh.material.opacity = mesh.rootMesh.material.opacity = 0.01;
		mesh.apexMesh.material.transparent = mesh.rootMesh.material.transparent = true;
	}

	if (rotateContext.moveLine) {
		viewer.scene.remove(rotateContext.moveLine);
		if (rotateContext.fromTapArrow) {
			viewer.scene.remove(rotateContext.fromTapArrow);
			viewer.scene.remove(rotateContext.toTapArrow);
		}
	}
}

function initRotateCtrls() {
	if (!rotateCtrlsInitialized) {
		allPositions.forEach(position => {
			const componentInstance = new Vue({
				...RotateCtrl,
				propsData: {
					rotateData: position
				}
			});
			const vueContainer = document.createElement('div');
			document.getElementById('main_div').appendChild(vueContainer);
			componentInstance.$mount(vueContainer);
		});

		viewer.controls.addEventListener('change', updateRotateControlPositions);
		rotateCtrlsInitialized = true;
	}
}
//#endregion

//#region redraw 

function redrawRotateArrow() {
	if (rotateContext.editedObject && rotateContext.tooth) {
		if (rotateContext.moveLine) {
			viewer.scene.remove(rotateContext.moveLine);
		}
		if (rotateContext.fromTapArrow) {
			viewer.scene.remove(rotateContext.fromTapArrow);
			viewer.scene.remove(rotateContext.toTapArrow);
		}

		var mesh = rotateContext.mesh;
		mesh.updateMatrixWorld();
		viewer.scene.updateMatrixWorld();
		//drawPoint(mesh.position);
		//console.log(mesh.rotation);

		if (!rotateContext.apex || rotateContext.isApexPlane) {
			var target1 = new THREE.Vector3();
			mesh.apexMesh.getWorldPosition(target1);
			rotateContext.apex = target1;
		}

		if (!rotateContext.root || !rotateContext.isApexPlane) {
			var target2 = new THREE.Vector3();
			mesh.rootMesh.getWorldPosition(target2);
			rotateContext.root = target2;
		}
		var center = rotateContext.isApexPlane ? rotateContext.root : rotateContext.apex;
		var cto = { x: center.x + 14, y: center.y, z: center.z }

		implantPlane.position.copy(center);
		implantPlane.lookAt(viewer.camera.position);

		var arrowCenter = rotateContext.isApexPlane ? rotateContext.apex : rotateContext.root;
		var from = { x: arrowCenter.x - 14, y: arrowCenter.y, z: arrowCenter.z }
		var to = { x: arrowCenter.x + 14, y: arrowCenter.y, z: arrowCenter.z }

		var dify = 3;
		var mfrom = { x: from.x, y: from.y - dify, z: from.z };
		var mto = { x: to.x, y: to.y - dify, z: to.z };

		if (rotateContext.angle != 0) {
			var rfrom = rotatePoint2D({ x: arrowCenter.x, y: arrowCenter.z }, rotateContext.angle, { x: from.x, y: from.z });
			from.x = rfrom.x;
			from.z = rfrom.y;
			var rto = rotatePoint2D({ x: arrowCenter.x, y: arrowCenter.z }, rotateContext.angle, { x: to.x, y: to.z });
			to.x = rto.x;
			to.z = rto.y;

			rto = rotatePoint2D({ x: center.x, y: center.z }, rotateContext.angle, { x: cto.x, y: cto.z });
			cto.x = rto.x;
			cto.z = rto.y;

			rfrom = rotatePoint2D({ x: arrowCenter.x, y: arrowCenter.z }, rotateContext.angle, { x: mfrom.x, y: mfrom.z });
			mfrom.x = rfrom.x;
			mfrom.z = rfrom.y;
			rto = rotatePoint2D({ x: arrowCenter.x, y: arrowCenter.z }, rotateContext.angle, { x: mto.x, y: mto.z });
			mto.x = rto.x;
			mto.z = rto.y;
		}

		from = new THREE.Vector3(from.x, from.y, from.z);
		to = new THREE.Vector3(to.x, to.y, to.z);
		mfrom = rotateContext.mfrom = new THREE.Vector3(mfrom.x, mfrom.y, mfrom.z);
		mto = rotateContext.mto = new THREE.Vector3(mto.x, mto.y, mto.z);

		rotateContext.moveLine = drawArrow(from, to);
		if (rotateContext.editedObject.showTapArrows) {
			rotateContext.fromTapArrow = drawTapArrow(mfrom, false, false, rotateContext.angle, "from", true);
			rotateContext.toTapArrow = drawTapArrow(mto, true, false, rotateContext.angle, "to", true);
		}

		rotateContext.center = new THREE.Vector3(center.x, center.y, center.z);
		rotateContext.to = new THREE.Vector3(cto.x, cto.y, cto.z);
	}
}

function updateRotateControlPositions() {
	if (rotateContext.id && rotateContext.tooth && !rotateContext.tooth.isImplant) {
		var canvasHalfWidth = viewer.renderer.domElement.offsetWidth / 2;
		var canvasHalfHeight = viewer.renderer.domElement.offsetHeight / 2;

		var mesh = viewer.get_model_mesh(rotateContext.id);
		var objectCenter = mesh.position;

		var centerPosition = new THREE.Vector3(objectCenter.x, objectCenter.y, objectCenter.z);
		centerPosition = centerPosition.project(viewer.camera);
		centerPosition.x = centerPosition.x * canvasHalfWidth + canvasHalfWidth;
		centerPosition.y = -(centerPosition.y * canvasHalfHeight) + canvasHalfHeight;

		rotateContext.leftPosition.x = centerPosition.x - ctrlSize - 60;
		rotateContext.leftPosition.y = centerPosition.y + 10;

		rotateContext.rightPosition.x = centerPosition.x + 60;
		rotateContext.rightPosition.y = centerPosition.y + 10;
	}
}

//#endregion

//#region events 

function rotateModeValueChanged(value) {
	rotateContext.angle = value * Math.PI;
	redrawRotateArrow();
}

function rotateModeMouseDown(tooth, event) {
	if (tooth && tooth.id == rotateContext.id) {
		mesh = viewer.get_model_mesh(tooth.id);
		if (mesh) {
			viewer.controls.enabled = false;

			normal.copy(viewer.camera.position).sub(rotateContext.center);

			implantPlane.position.copy(rotateContext.center);
			implantPlane.lookAt(viewer.camera.position);

			var intersects = raycaster.intersectObject(implantPlane);
			clickPoint.copy(intersects[0].point);

			rotateAxis = new THREE.Vector3();
			var start = getPointB(rotateContext.center, rotateContext.to, 90);
			var end = rotateContext.center;
			//drawLine(start, end);
			rotateAxis.subVectors(start, end).normalize();
		}
	} else {
		mesh = null;
	}
}

function rotateModeMouseMove(event) {
	if (mesh) {
		if (event.preventDefault)
			event.preventDefault();

		var direction = getMouse3dDirection(event);
		raycaster.set(viewer.camera.position, direction);

		var intersects = raycaster.intersectObject(implantPlane);
		if (intersects.length > 0) {
			var mousePoint = intersects[0].point;

			var rotateCenter = rotateContext.center;
			var v1 = new THREE.Vector3();
			v1.copy(clickPoint).sub(rotateCenter);

			var v2 = new THREE.Vector3();
			v2.copy(mousePoint).sub(rotateCenter);

			var angle = v1.angleTo(v2);
			var cross = v1.cross(v2);
			var temp = normal.dot(cross);
			if (temp < 0) {
				angle = 2 * Math.PI - angle;
			}
			rotateAboutPoint(mesh, rotateCenter, rotateAxis, angle);

			clickPoint.copy(mousePoint);
			updateRotateControlPositions();
			redrawRotateArrow();
		}
	}
}

function rotateModeMouseUp(event) {
	viewer.controls.enabled = true;
	mesh = null;
	if (needUpdate) {
		redrawRotateArrow();
		updateRotateControlPositions();
		needUpdate = false;
	}
}

//#endregion

//#region methods 

function changeRotatePlane(isApex) {
	rotateContext.angle = rotateContext.editedObject.sliderValue = 0;
	$("#movementVector").slider("value", 0);
	$("#movementVector2").slider("value", 0);

	rotateContext.isApexPlane = isApex;
	redrawRotateArrow();
}

function rotateLeft(objectId) {
	var angle = 0.03;
	rotateAround(angle, objectId);
}

function rotateRight(objectId) {
	var angle = -0.03;
	rotateAround(angle, objectId);
}

function rotateAround(angle, objectId) {
	var vmesh = rotateContext.mesh; //?? viewer.get_model_mesh(objectId);

	// var v1 = new THREE.Vector3();
	// v1.copy(vmesh.position);

	//var axis = 
	rotateAroundAxisY(objectId, angle, vmesh);
	// vmesh.updateMatrix();
	// vmesh.geometry.applyMatrix4(vmesh.matrix);
	// vmesh.position.set(0, 0, 0);
	// vmesh.rotation.set(0, 0, 0);
	// vmesh.scale.set(1, 1, 1);
	// vmesh.geometry.center();
	// vmesh.updateMatrix();
	// vmesh.position.copy(v1);

	// v1 = new THREE.Vector3();
	// rotateAboutPoint(vmesh.apexMesh, v1, axis, angle);
	// rotateAboutPoint(vmesh.rootMesh, v1, axis, angle);

	rotateContext.apex = null;
	rotateContext.root = null;

	redrawRotateArrow();
	updateRotateControlPositions();
}

function rotateByTap(dx) {
	if (dx != 0) {
		var mesh = viewer.get_model_mesh(rotateContext.id);
		var rotateCenter = rotateContext.center;

		if (mesh && rotateCenter) {

			normal.copy(viewer.camera.position).sub(rotateCenter);

			rotateAxis = new THREE.Vector3();
			var start = getPointB(rotateCenter, rotateContext.to, 90);
			rotateAxis.subVectors(start, rotateCenter).normalize();

			var angle = 0.03 * dx;
			rotateAboutPoint(mesh, rotateCenter, rotateAxis, angle);

			updateRotateControlPositions();
			redrawRotateArrow();
		}
	}
}

function rotateByTag(tag) {
	if (tag && rotateContext.tooth) {
		var tooth = rotateContext.tooth;
		var topArrow = (tooth.isMaxilla && rotateContext.isApexPlane) || (!tooth.isMaxilla && !rotateContext.isApexPlane);

		var canvasHalfWidth = viewer.renderer.domElement.offsetWidth / 2;
		var canvasHalfHeight = viewer.renderer.domElement.offsetHeight / 2;
		var from = new THREE.Vector3(rotateContext.mfrom.x, rotateContext.mfrom.y, rotateContext.mfrom.z);
		from = from.project(viewer.camera);
		from.x = from.x * canvasHalfWidth + canvasHalfWidth;
		from.y = -(from.y * canvasHalfHeight) + canvasHalfHeight;
		var to = new THREE.Vector3(rotateContext.mto.x, rotateContext.mto.y, rotateContext.mto.z);
		to = to.project(viewer.camera);
		to.x = to.x * canvasHalfWidth + canvasHalfWidth;
		to.y = -(to.y * canvasHalfHeight) + canvasHalfHeight;

		if (to.x < from.x) {
			topArrow = !topArrow;
		}

		switch (tag) {
			case "from":
				rotateByTap(topArrow ? -1 : 1);
				break;
			case "to":
				rotateByTap(topArrow ? 1 : -1);
				break;
		}
	}
}

//#endregion

export {
	enterRotateMode, exitRotateMode, redrawRotateArrow,
	changeRotatePlane, rotateModeValueChanged, rotateByTag,
	rotateModeMouseDown, rotateModeMouseMove, rotateModeMouseUp
};