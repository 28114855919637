<template>
    <div class="assign-annotation-panel" ref="assignPanel">

        <div class="dicom-annotation-settings">
            <li ref="assignHeader" class="list-group-item dicom-setting-item active">Assign Layer</li>
        </div>

        <div class="assign-list" ref="assignList"></div>

        <div class="assign-bottom">
            <button type="button" class="btn btn-primary btn-sm btn-block assign-btn" v-on:click="applyAssign">Assign
            </button>

            <button type="button" class="btn btn-primary btn-sm btn-block cancel-btn" v-on:click="cancelAssign">Cancel
            </button>
        </div>
    </div>
</template>

<script>

import { assignFigure } from "./DicomManager";

export default {
    name: "AssignAnnotationPanel",
    props: {
        app: {
            type: Object,
            required: false
        }
    },
    methods: {
        applyAssign: function () {
            $(`#${this.app.assignPanelId}`).css({
                display: "none",
            });
            assignFigure(this.app);
        },
        cancelAssign: function () {
            console.log("cancelAssign");
            $(`#${this.app.assignPanelId}`).css({
                display: "none",
            });
        }
    },
    mounted() {
        this.app.assignPanelId = `${this.app.orientation}_assign_panel`;
        this.$refs.assignPanel.setAttribute("id", this.app.assignPanelId);

        this.app.assignListId = `${this.app.orientation}_assign_list`;
        this.$refs.assignList.setAttribute("id", this.app.assignListId);

        this.app.assignHeaderId = `${this.app.orientation}_assign_header`;
        this.$refs.assignHeader.setAttribute("id", this.app.assignHeaderId);
    },
}

</script>

<style scoped>
.assign-annotation-panel {
    display: none;
    height: 100%;
    /* width: 100%; */
    width: 200px;
    border-radius: 0.25rem;
}

.dicom-annotation-settings {
    width: 100%;
    background-color: black;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.dicom-setting-item {
    text-align: center;
    padding: .3rem .4rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.assign-list {
    background-color: black;
    overflow-y: scroll;
    max-height: calc(100% - 120px);
    width: calc(100% - 1px);
    margin-left: 1px;
    margin-top: 1px;
}

.assign-bottom {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    width: 100%;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.assign-btn {
    margin: 0px 5px 0 5px;
    width: calc(100% - 10px);
}

.cancel-btn {
    margin: 8px 5px 0px 5px;
    width: calc(100% - 10px);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #007bff;
}
</style>