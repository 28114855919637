<template>
	<div id="toothFocusButtons" class="tooth-focus-mode-item">
		<button id="toothFocusSave" class="tooth-focus-button" v-on:click="exitToothFocusMode(false)">
			RESET
		</button>
		<button id="toothFocusCancel" class="tooth-focus-button" v-on:click="exitToothFocusMode(true)">
			EXIT
		</button>
	</div>
</template>

<script>

import {
	unfocusTooth
} from "./ToothFocusMode.js";

export default {
	name: "ToothFocusButtons",
	props: [],
	components: {},
	methods: {
		exitToothFocusMode: function (leaveFocusMode) {
			unfocusTooth(leaveFocusMode);
		}
	}
};

</script>

<style scoped>
#toothFocusButtons {
	position: absolute;
	text-align: center;
	white-space: nowrap;
	left: calc(50% - 80px);
	bottom: 70px;
	z-index: 9;
	display: none;
	width: 200px;
	height: 35px;
}

.tooth-focus-button {
	display: inline-block;
	margin-right: 10px;
	min-width: 90px;
	width: auto;
	height: 35px;
	border: 0;
	cursor: pointer;
	font-size: small;
	background-color: #4687fc;
	text-align: center;
	color: #ffffff;
}
</style>