<template>
    <div class="teeth-ctrls panel-group">
        <div id="teethListPanel" ref="teethListPanel" class="collapse list-group teeth-panel ">
            <div class="teeth-settings">
                <li class="list-group-item setting-item active">Settings</li>
                <li class="list-group-item setting-item">
                    <!-- <input type="range" class="form-control-range"> -->
                    <button type="button" class="btn btn-secondary btn-sm btn-block setting-btn"
                        v-on:click="addTooth">Add Tooth</button>
                </li>
                <li class="list-group-item setting-item active">Visibility</li>
                <li class="list-group-item setting-item" style="background-color: #77a7fd;">
                    <!-- <div class="mb-2 mt-1 setting-group"> -->
                    <label v-for="option in visibilities" class="setting-ctrl">
                        <span class="check-label" style="color: white;">{{ option.text }}</span>
                        <input type="checkbox" class="check-input" v-model="option.value" @change="visibilityChanged()">
                    </label>
                    <!-- </div> -->
                </li>
                <li class="list-group-item setting-item active">Elements</li>
            </div>
            <div id="teethList" class="teeth-list" ref="teethList"></div>
            <div class="teeth-list-bottom">
                <button type="button" class="btn btn-primary btn-sm btn-block cancel-btn" v-on:click="close">Close
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import { fillTeethList } from './TeethListManager';
import { showToothAddDialog } from "@/components/tooth/add/ToothAddMode";

export default {
    name: "TeethListPanel",
    props: {
        allItems: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            isFilled: false,
            visibilities: [
                { text: "Max teeth", key: "Maxilla", value: true },
                { text: "Mand teeth", key: "Mandible", value: true },
                // { text: "Maxilla", key: "maxilla", value: false },
                // { text: "Mandible", key: "mandible", value: false },
                // { text: "Airway", key: "airway", value: false }
            ],
        }
    },
    watch: {
        "allItems": {
            handler(newVal, oldVal) {
                if (newVal && newVal.length) {
                    // to do update list
                    // setTimeout(function () {
                    // }, 500);
                }
            }
        },
    },
    methods: {
        addTooth: function () {
            showToothAddDialog();
        },
        apply: function () {
            console.log("close teethListPanel");
            $(`#teethListPanel`).css({
                display: "none",
            });
        },
        close: function () {
            console.log("close teethListPanel");
            // $(`#teethListPanel`).css({
            //     display: "none",
            // });

            $(`#teethListPanel`).removeClass("show");
        },
        visibilityChanged: function () {
            let arr = [];
            this.visibilities.forEach(option => {
                let dentitionName = option.key;
                if (option.value) {
                    arr.push(dentitionName);
                }
                var dentation = vueApp.dentations.find(x => x.name === dentitionName);
                if (dentation) {
                    dentation.isVisible = option.value;
                    vueApp.allTeeth.forEach(function (item) {
                        if (item.dentition == dentitionName) { //(dentationTeeth.includes(item)) {
                            item.isVisible = dentation.isVisible;
                            itemVisibilityChanged(item, item.color, vueApp.teethOpacity)
                        }
                    });
                    vueApp.$emit('visibilityChanged', dentitionName);
                }
            });
            console.log("visibilityChanged", arr);
        }
    },
    mounted() {
        // console.log("TeethListPanel mounted", this.allItems);
        // this.app.assignPanelId = `${this.app.orientation}_assign_panel`;
        // this.$refs.teethListPanel.setAttribute("id", this.app.assignPanelId);
    },
}

</script>

<style scoped>
.btn {
    font-size: small;
}

.setting-group {
    text-align: right;
    border: 1px solid #ced4da;
    align-items: end;
    border-radius: 0.2rem;
    padding-right: 5px;
}

.setting-ctrl {
    text-align: right;
    display: block;
    white-space: nowrap;
    margin: 0;
}

.check-label {
    margin-right: 0.5rem;
    vertical-align: middle;
}

.check-input {
    /* margin-top: 20px; */
    vertical-align: middle;
}

.teeth-ctrls {
    position: absolute;
    top: 10px;
    right: 45px;
    z-index: 9;
    text-align: right;
    height: calc(100% - 15px);
    width: 0;
    font-family: Montserrat, sans-serif;
    font-size: small;
    background-color: red;
}

.teeth-panel {
    /* border-radius: 0.25rem;  */
    position: absolute;
    width: 200px;
    right: -40px;
    top: 30px;
    max-height: calc(100% - 40px);
    overflow-y: scroll;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    /* border: solid 1px white; */
}

.teeth-settings {
    width: 100%;
    background: #77a7fd;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.setting-item {
    text-align: center;
    padding: .3rem .4rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.setting-btn {
    margin: 2px 0;
    padding: 0;
}

.teeth-list {
    background: white;
    /* #77a7fd; */
    overflow-y: scroll;
    /* max-height: calc(100% - 120px); */
    width: 100%;
    /* calc(100% - 1px); */
    /* margin-left: 1px;
    margin-top: 1px; */
}

.teeth-list-bottom {
    padding-bottom: 8px;
    background-color: white;
    width: 100%;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.apply-btn {
    margin: 0px 5px 0 5px;
    width: calc(100% - 10px);
}

.cancel-btn {
    margin: 8px 5px 0px 5px;
    width: calc(100% - 10px);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #007bff;
}
</style>