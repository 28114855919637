import axios from "@/api/axios";

async function sendSaveIntraoralDataRequest(blob) {
  console.log("sendSaveIntraoralDataRequest");
  let url = "/api/intraoral/saveData";
  let formData = new FormData();
  formData.append("file", blob, `intraoral_data_${vueApp.patientId}.zip`);
  formData.append("patientId", vueApp.patientId);
  formData.append("s3Path", vueApp.stlBaseUrl);
  var config = {};
  config.headers = {
    "content-type": "multipart/form-data",
  }; // ; boundary=${form._boundary}

  const response = await axios.post(url, formData, config);
  return response;
}

async function sendApplyBorderPointsRequest(blob, isDouble, isUpper, callback) {
  let responseBlob = null;
  try {
    let url = "/api/intraoral/applyBorderPoints";
    let formData = new FormData();
    formData.append("file", blob, `points.json`);
    formData.append("patientId", vueApp.patientId);
    formData.append("s3Path", vueApp.stlBaseUrl);
    formData.append("isDouble", !!isDouble);
    formData.append("isUpper", !!isUpper);
    // formData.append("toothNumber", toothNumber ? +toothNumber : 0);
    var config = {};
    config.headers = {
      "content-type": "multipart/form-data",
    }; // ; boundary=${form._boundary}
    config.responseType = "arraybuffer";
    const response = await axios.post(url, formData, config);
    responseBlob = response.data;
  } catch (e) {
    console.log("sendApplyBorderPointsRequest error", e);
  }
  if (callback) {
    callback(responseBlob);
  }
}

export { sendSaveIntraoralDataRequest, sendApplyBorderPointsRequest };
