<template>
	<div id="implantsStep3" class="modal" tabindex="-1" role="dialog"
		aria-labelledby="implantsStep3Title" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content wizard-content">
				<div class="modal-header wizard-header">
					<h5 class="modal-title wizard-title"
						id="implantsStep3Title">
						{{isCrownPlacement?'Place The Crown on Occlusal Plane':'Place The Implant on Occlusal Plane'}}
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body wizard-body">
					<div class="step-description">
						{{isCrownPlacement?'Drag the red circle to exact crown location':'Drag the red circle to exact implant location'}}
					</div>
					<div class="step-main">
						<div class="btn-group" role="group">
							<button id="btnMaxilla" type="button"
								v-bind:class="{'dentition-selected': isMaxilla}"
								v-on:click="switchDentition(true)"
								class="btn btn-light">
								MAXILLA
							</button>
							<button id="btnMandible" type="button"
								v-bind:class="{'dentition-selected': !isMaxilla}"
								v-on:click="switchDentition(false)"
								class="btn btn-light">
								MANDIBLE
							</button>
						</div>
						<div id="stl_group">
							<div id="stl_dentitions">

							</div>
							<div id="stl_overlay">
								<img id="target_img" class="occlusal-img" src="images/implant-view.png" />
								<img id="move_img" class="occlusal-img" src="images/move_icon.png" />
							</div>
							<img id="implantSpinner" class="centered-spinner" src="images/dark-blue-spinner.gif" />
						</div>
					</div>
				</div>
				<div class="modal-footer wizard-footer">
					<button type="button"
						class="btn btn-primary btn-next"
						data-dismiss="modal"
						v-on:click="applyStep3()">
						NEXT
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
var step3 = {
	name: "ImplantsStep3",
	props: ["isCrownPlacement"],
	data() {
		return {
			isMaxilla: true,
			positionX: 0,
			positionY: 0
		};
	},
	methods: {
		switchDentition: function(maxilla) {
			if (isMaxilla != maxilla) {
				this.isMaxilla = isMaxilla = maxilla;
				if (isMaxilla) {
					changeDentition2("Maxilla");
				} else {
					changeDentition2("Mandible");
				}
			}
		},
		applyStep3: function() {
			var implantX = implantPoint.x; // - 2.3;
			var implantY = 0; //this.isMaxilla ? 0 : -20;
			var implantZ = implantPoint.z; // - 2.3;

			var toothModelId =
				vueApp.toothModels.length > 0 ? vueApp.toothModels[0].id : 0;
			if (toothModelId > 0) {
				// console.log("applyStep3 " + toothModelId);
				var mainPosition = vueApp.initPosition
					? vueApp.initPosition
					: vueApp.getItemById(toothModelId).initPosition;
				if (mainPosition) {
					//var step3Model = viewer2.get_model_info(toothModelId);
					var step3Model = viewer2.get_model_mesh(toothModelId);
					implantX += mainPosition.x - step3Model.position.x;
					implantY += mainPosition.y - step3Model.position.y;
					implantZ += mainPosition.z - step3Model.position.z;
				}
			}

			if (vueApp.isCrownPlacement) {
				this.$emit("add-crown", implantX, implantY, implantZ, isMaxilla);
			} else {
				this.$emit("add-implant", implantX, implantY, implantZ, isMaxilla);
			}
		}
	}
};

export default step3;

var isMaxilla = true;
function initStep3() {
	dragAndDropImplant();

	$("#implantsStep3").on("shown.bs.modal", function(event) {
		if (!viewer2) {
			if (vueApp.toothModels && vueApp.toothModels.length > 0)
				$("#implantSpinner").css({ display: "block" });

			viewer2 = new StlViewer(document.getElementById("stl_dentitions"), {
				models: vueApp.toothModels,
				load_three_files: "js/",
				center_models: false,
				all_loaded_callback: vueApp.isGlb ? null : stlAllLoaded2,
				model_loaded_callback: stlModelLoaded2,
				zoom: -1 //defaultZoom
			});
			if (vueApp.isGlb) {
				setTimeout(function() {
					stlAllLoaded2();
				}, 100);
			}
		} else {
			if (vueApp.crownNumber) isMaxilla = vueApp.crownNumber < 30;

			if (isMaxilla) {
				changeDentition2("Maxilla");
			} else {
				changeDentition2("Mandible");
			}

			//this.isMaxilla = true;
			//changeDentition2("Maxilla");
		}
	});
}

function showStep3() {
	$("#implantsStep3").modal();
}

function dragAndDropImplant() {
	var move_img = document.getElementById("move_img");
	var target_img = document.getElementById("target_img");
	var overlay = document.getElementById("stl_overlay");

	//$('.occlusal-img').bind('contextmenu', function(e) {
	//	return false;
	//});

	$("#stl_overlay").on("contextmenu", "img", function(e) {
		return false;
	});

	move_img.ondragstart = function() {
		return false;
	};

	function moveAt(pageX, pageY) {
		var overlayRect = overlay.getBoundingClientRect();
		var left = pageX - move_img.offsetWidth / 2 - overlayRect.left;
		var top = pageY - move_img.offsetHeight / 2 - overlayRect.top;
		if (
			left >= 0 &&
			top >= 0 &&
			left <= overlayRect.width - move_img.offsetWidth &&
			top <= overlayRect.height - move_img.height
		) {
			move_img.style.left = left + "px";
			move_img.style.top = top + "px";
			target_img.style.left = left - 20 + "px";
			target_img.style.top = top - 20 + "px";
		}
	}

	move_img.addEventListener("touchstart", function(e) {
		var event = e.targetTouches[0];
		if (event && event.stopPropagation) {
			event.stopPropagation();
		}
	});

	move_img.addEventListener("touchmove", function(e) {
		var event = e.targetTouches[0];
		e.preventDefault();
		moveAt(event.pageX, event.pageY);
	});

	move_img.addEventListener("touchend", function(e) {
		var event = e.changedTouches[0];
		// moveAt(event.pageX, event.pageY);

		var overlayRect = overlay.getBoundingClientRect();
		var left = event.pageX - overlayRect.left - 20;
		var top = event.pageY - overlayRect.top - 20;
		findImplantPoint(left, top);
	});

	move_img.onmousedown = function(event) {
		moveAt(event.pageX, event.pageY);

		function onMouseMove(event) {
			moveAt(event.pageX, event.pageY);
		}

		function onMouseUp(event) {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("mouseup", onMouseUp);
			var overlayRect = overlay.getBoundingClientRect();
			var left = event.pageX - overlayRect.left - 20;
			var top = event.pageY - overlayRect.top - 20;
			findImplantPoint(left, top);
		}

		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);
	};
}

var viewer2;
var defaultZoom2 = 200;
var dentitionZoom2 = 160;
var minX2, maxX2, minY2, maxY2, minZ2, maxZ2, sizeX2, sizeY2, sizeZ2;
var allLoaded2 = false;
var planeMesh;

var implantPoint = { x: 0, y: 0, z: 0 };

function stlModelLoaded2(model_id) {
	stlLogMessage(`${new Date()} stlModelLoaded2 ${model_id}`);
}

function stlAllLoaded2() {
	$("#implantSpinner").css({ display: "none" });

	stlLogMessage(`${new Date()} stlAllLoaded2`);

	if (allLoaded2) {
		changeDentition2("Maxilla");
		return;
	}

	vueApp.toothModels.forEach(function(model) {
		if (implantPoint) {
			viewer2.set_position(
				model.id,
				implantPoint.x - 2.3,
				implantPoint.y,
				implantPoint.z - 2.3
			);
		}
	});

	viewer2.controls.zoomSpeed /= 2;
	// viewer2.controls.noKeys = true;
	// viewer2.controls.noPan = true;
	// viewer2.controls.noRotate = true;
	// viewer2.controls.noZoom = true;

	var rotateY = (180 * Math.PI) / 180;
	var new_minx =
		viewer2.minx * Math.cos(rotateY) + viewer2.minz * Math.sin(rotateY);
	var new_minz =
		-viewer2.minx * Math.sin(rotateY) + viewer2.minz * Math.cos(rotateY);
	var new_maxx =
		viewer2.maxx * Math.cos(rotateY) + viewer2.maxz * Math.sin(rotateY);
	var new_maxz =
		-viewer2.maxx * Math.sin(rotateY) + viewer2.maxz * Math.cos(rotateY);
	var rotateX = (90 * Math.PI) / 180;
	var new_miny =
		viewer2.miny * Math.cos(rotateX) + new_minz * Math.sin(rotateX);
	new_minz = -viewer2.miny * Math.sin(rotateX) + new_minz * Math.cos(rotateX);
	var new_maxy =
		viewer2.maxy * Math.cos(rotateX) + new_maxz * Math.sin(rotateX);
	new_maxz = -viewer2.maxy * Math.sin(rotateX) + new_maxz * Math.cos(rotateX);

	minX2 = Math.min(new_maxx, new_minx);
	maxX2 = Math.max(new_maxx, new_minx);
	minY2 = Math.min(-new_maxy, -new_miny);
	maxY2 = Math.max(-new_maxy, -new_miny);
	minZ2 = Math.min(-new_maxz, -new_minz);
	maxZ2 = Math.max(-new_maxz, -new_minz);

	var xOffset = minX2 + (maxX2 - minX2) / 2;
	var yOffset = minY2 + (maxY2 - minY2) / 2;
	var zOffset = minZ2 + (maxZ2 - minZ2) / 2;

	vueApp.toothModels.forEach(function(model) {
		viewer2.rotate(model.id, 0, rotateY, 0);
		viewer2.rotate(model.id, rotateX, 0, 0);
		viewer2.set_position(model.id, -xOffset, -yOffset, -zOffset);
		model.defaultPosition = { x: -xOffset, y: -yOffset, z: -zOffset };
	});

	minX2 -= xOffset;
	maxX2 -= xOffset;
	minY2 -= yOffset;
	maxY2 -= yOffset;
	minZ2 -= zOffset;
	maxZ2 -= zOffset;

	sizeX2 = maxX2 - minX2;
	sizeY2 = maxY2 - minY2;
	sizeZ2 = maxZ2 - minZ2;

	var padding = 0;
	var w = (sizeX2 + padding) / 2;
	var h = (sizeY2 + padding) / 2;

	var fovX = viewer2.camera.fov * viewer2.camera.aspect;
	var fovY = viewer2.camera.fov;

	var distanceX = w / Math.tan((Math.PI * fovX) / 360) + w;
	var distanceY = h / Math.tan((Math.PI * fovY) / 360) + w;

	defaultZoom2 = viewer2.camera.position.z = Math.max(distanceX, distanceY);
	defaultZoom2 *= 1.1;
	dentitionZoom2 = defaultZoom2 * 0.8;

	addImplantPlane();

	isMaxilla = !vueApp.crownNumber || vueApp.crownNumber < 30;
	if (isMaxilla) {
		changeDentition2("Maxilla");
	} else {
		changeDentition2("Mandible");
	}

	allLoaded2 = true;
}

function addImplantPlane() {
	var planeGeometry = new THREE.PlaneGeometry(140, 80);
	var planeMaterial = new THREE.MeshBasicMaterial({
		color: 0xffffff,
		transparent: true,
		depthWrite: false,
		//depthTest: false,
		side: THREE.DoubleSide,
		opacity: 0.01
	});

	planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
	planeMesh.rotateX(Math.PI / 2);
	planeMesh.name = "implantPlane";
	viewer2.scene.add(planeMesh);

	// var geometry = new THREE.BoxGeometry(1, 1, 1);
	// var material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
	// var cube = new THREE.Mesh(geometry, material);
	// viewer2.scene.add(cube);
}

function changeDentition2(dentitionName) {
	var offsetZ = (maxZ2 - minZ2) / 5 - 10;
	var offsetX = (maxX2 - minX2) / 4;
	var offsetY = Math.abs((maxY2 - minY2) / 4);

	//var dentition = vueApp.dentations.find(x => x.name === dentitionName);
	//if (dentition) {
		// var dentationTeeth = dentition.groups.reduce(function(a, b) {
		// 	return a.concat(b.teeth);
		// }, []);
		//console.log(dentitionName);
		var dentationTeeth = vueApp.allTeeth.filter(tooth => tooth.dentition == dentitionName);
		vueApp.toothModels.forEach(function(item) {
			var existed = dentationTeeth.find(function(element) {
				return element.id === item.id;
			});
			//viewer2.set_color(item.id, isVisible ? vueApp.teethColor : "transparent");
			viewer2.set_color(item.id, existed ? vueApp.teethColor : "transparent");
			if (existed) {
				viewer2.set_opacity(item.id, existed.isVisible ? 1 : 0.3);
			}
		});
	//}

	if (dentitionName === "Maxilla") {
		$("#btnMaxilla").addClass("dentition-selected");
		$("#btnMandible").removeClass("dentition-selected");
		viewer2.camera.position.x = 0;
		viewer2.camera.position.y = -dentitionZoom2;
		viewer2.camera.position.z = 0;
		//planeMesh.position.set(0, maxY2, 0);
	} else if (dentitionName === "Mandible") {
		$("#btnMaxilla").removeClass("dentition-selected");
		$("#btnMandible").addClass("dentition-selected");
		viewer2.camera.position.x = 0;
		viewer2.camera.position.y = dentitionZoom2;
		viewer2.camera.position.z = 0;
		//planeMesh.position.set(0, minY2, 0);
	}

	viewer2.camera.updateProjectionMatrix();
	viewer2.controls.update();
}

function findImplantPoint(x, y) {
	var stlContainer = $("#stl_dentitions");
	var width = stlContainer.width(); // window.innerWidth
	var height = stlContainer.height();
	var mouse3D = new THREE.Vector3(
		(x / width) * 2 - 1,
		-(y / height) * 2 + 1,
		0.5
	);

	//var projector = new THREE.Projector(); // old threejs
	//projector.unprojectVector(mouse3D, Camera);

	mouse3D.unproject(viewer2.camera);
	mouse3D.sub(viewer2.camera.position);
	mouse3D.normalize();

	var raycaster = new THREE.Raycaster(
		viewer2.camera.position,
		mouse3D,
		0.1,
		100000
	);
	var intersects = raycaster.intersectObjects(viewer2.scene.children);
	if (intersects.length > 0) {
		implantPoint = intersects[0].point;
		stlLogMessage(implantPoint);
		stlLogMessage(
			`implant point: ${implantPoint.x}, ${implantPoint.y}, ${implantPoint.z},`
		);
	}
}

export { initStep3, showStep3 };
</script>

<style scoped>
@import "../../css/wizard.css";

.modal-dialog {
	max-width: 600px;
}

.modal-header {
	height: 80px;
}

.modal-title {
	max-width: 300px;
	left: calc(50% - 150px);
}

.modal-body {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 5px;
}

.step-description {
	text-align: center;
	color: #0069d9;
}

.step-main {
	margin-top: 15px;
	text-align: center;
}

.btn {
	border-radius: 0px;
	border: 1px solid #000000;
	width: 120px;
	font-size: 14px;
}

.dentition-selected {
	background-color: #deeaf6;
}

#stl_group {
	margin-top: 10px;
	height: 350px;
	width: 100%;
	position: relative;
}

#stl_dentitions {
	background-color: #deeaf6;
	position: absolute;
	width: 100%;
	height: 100%;
}

#stl_overlay {
	background-color: transparent;
	position: absolute;
	width: 100%;
	height: 100%;
}

@media (max-height: 600px) {
	#stl_group {
		height: 250px;
	}
}

.occlusal-img {
	position: absolute;
	width: 30px;
	height: 30px;
	z-index: 9;
}

#target_img {
	top: calc(50% - 15px);
	left: calc(50% - 15px);
}

#move_img {
	top: calc(50% + 5px);
	left: calc(50% + 5px);
	cursor: pointer;
}
</style>
