<template>
	<div class="dropdown border-bottom-item">
		<button class="dropdown-item" v-on:click="showIntraoralView()" type="button">
			Intraoral View
		</button>
	</div>
</template>

<script>

import {
    showIoViewer
} from "./IntraoralManager";

export default {
	name: "IntraoralMenu",
	data() {
		return {
			viewerInitialized: false
		};
	},
	mounted() {
		console.log("mounted IntraoralMenu");
		// setTimeout(() => {
		// 	showIoViewer()
		// }, 200);
	},
	methods: {
		showIntraoralView: function () {
			console.log("showIntraoralView");
			gtag('event', `View->Intraoral View`);
			showIoViewer();
		}
	}
};
</script>

<style scoped></style>