import TeethOpacity from "./TeethOpacity.vue";
import BonesOpacity from "./BonesOpacity.vue";

export default {
	functional: true,
	props: [],
	components: { TeethOpacity, BonesOpacity },
	render(createElement, context) {
		const teethOpacity = createElement(TeethOpacity, { props: context.props });
		const bonesOpacity = createElement(BonesOpacity, { props: context.props });
		return [teethOpacity, bonesOpacity];
	}
};