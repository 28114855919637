import $ from "jquery";
import swal from "sweetalert";
import ToothRemoveControls from "./ToothRemoveControls.vue";
import ToothRemoveButtons from "./ToothRemoveButtons.vue";
import { updateRaycaster, intersectTooth } from "@/App";
import { sendRemoveTeethRequest } from "@/api/toothApi";
// import { showToothNumberDialog } from "./ToothNumberDialog.vue";

let controlsInitialized = false;
let removedTeeth = [];

var ToothRemoveMode = {
  isActive: false,
  enterMode: function () {
    enterToothRemoveMode();
  },
  leaveMode: function (saveActiveMode) {
    leaveToothRemoveMode(false, saveActiveMode);
  },
  mouseMove: function (event) {
    return true;
  },
  mouseUp: function (event) {
    return true;
  },
  mouseClick: function (event) {
    updateRaycaster(event);
    var item = intersectTooth();
    if (item && item.isVisible) {
      if (removedTeeth.includes(item)) {
        let index = removedTeeth.indexOf(item);
        removedTeeth.splice(index, 1);
        viewer.set_opacity(item.id, 1);
      } else {
        viewer.set_opacity(item.id, 0.3);
        removedTeeth.push(item);
      }
      // viewer.set_color(item.id, hideModeTeethColor);
    }
  },
};

function enterToothRemoveMode(isOldNumbers) {
  initializeControls();
  vueApp.leaveModes(false, true);
  $(".tooth-remove-mode-item").css("display", "block");
  removedTeeth = [];
  vueApp.activeMode = ToothRemoveMode;
  ToothRemoveMode.isActive = true;
}

function leaveToothRemoveMode(saveChanges, saveActiveMode) {
  if (saveChanges && removedTeeth.length) {
    let arr = removedTeeth.map((x) => x.number);
    let text = `These teeth will be removed: ${arr.join(", ")}. Are you sure?`;

    swal({
      text: text,
      buttons: {
        yes: { text: "Yes", value: true },
        no: { text: "No", value: false },
      },
    }).then((willRemove) => {
      if (willRemove) {
        removedTeeth.forEach(function (item) {
          vueApp.removeTooth(item);
          viewer.remove_model(item.id);
        });

        leaveMode();
        sendRemoveTeethRequest(removedTeeth);

        vueApp.$emit("toothRemoved", removedTeeth);
      }
    });
  } else {
    leaveMode(saveActiveMode);
  }
}

function leaveMode() {
  vueApp.allItems.forEach(function (item) {
    if (item.isVisible) viewer.set_opacity(item.id, item.opacity);
  });
  $(".tooth-remove-mode-item").css("display", "none");
  vueApp.activeMode = null;

  ToothRemoveMode.isActive = false;
  vueApp.leaveModes(false, true);
}

function initializeControls() {
  if (!controlsInitialized) {
    var ctrls = [ToothRemoveControls, ToothRemoveButtons];
    ctrls.forEach((component) => {
      const componentInstance = new Vue({
        ...component,
        propsData: { mode: ToothRemoveMode },
      });
      const vueContainer = document.createElement("div");
      document.getElementById("stl_wrapper").appendChild(vueContainer);
      componentInstance.$mount(vueContainer);
    });
    controlsInitialized = true;
  }
}

export { ToothRemoveMode, enterToothRemoveMode, leaveToothRemoveMode };
