<template>
	<div class="dropdown dropright border-bottom-item" v-if="isEngineerMode">
		<button id="miOldToothNumber" class="dropdown-item" type="button" 
			v-on:click="showOldToothNumber()">
			Old Tooth Number
		</button>
	</div>
</template>

<script>
import { showOldToothNumbers } from "./ToothNumberMode";

export default {
	name: "ToothOldNumberMenu",
	props: ["isEngineerMode"],
	components: {},
	methods: {
		showOldToothNumber: function () {
			gtag('event', 'View->Old Tooth Number');
			showOldToothNumbers();
		},
	}
};
</script>

<style scoped></style>