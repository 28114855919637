<template>
    <div id="intraoralViewer" class="intraoral-viewer">
        <div id="intraoralContainer" class="intraoral-container"> </div>

        <div id="intraoralAddHint1" class="intraoral-hint intraoral-add-item">Click gingiva to add tooth point</div>
        <div id="intraoralAddHint2" class="intraoral-hint intraoral-add-item">Click last point to finish shape</div>

        <img v-if="isLoaded && isDouble"
            :src="isUpper ? 'images/intraoral/INTRAORAL_UPPER_RED.png' : 'images/intraoral/INTRAORAL_UPPER.png'"
            class="jaw-btn upper-jaw-btn" v-on:click="switchUpper()" title="Upper Jaw" />
        <img v-if="isLoaded && isDouble"
            :src="isLower ? 'images/intraoral/INTRAORAL_LOWER_RED.png' : 'images/intraoral/INTRAORAL_LOWER.png'"
            class="jaw-btn lower-jaw-btn" v-on:click="switchLower()" title="Lower Jaw" />
        <!-- <img v-if="isDouble" :src="isFront ? 'images/intraoral/INTRAORAL_FRONT_RED.png' : 'images/intraoral/INTRAORAL_FRONT.png'"
            class="jaw-btn front-jaw-btn" v-on:click="switchFront()" title="Fron View" /> -->

        <div v-show="isLoaded && !isViewMode" id="intraoralColor" class="input-group mb-2 mt-1 input-group-sm">
            <!-- <div class="input-group-prepend">
                <span class="input-group-text">Teeth Color:</span>
            </div> -->
            <select v-model="teethColor" class="form-control form-control-sm intraoral-tool-select"
                @change="teethColorChanged()">
                <option v-for="option in teethColors" v-bind:value="option.value">
                    {{ option.text }}
                </option>
            </select>
        </div>

        <div id="selectedToothHint" v-show="isLoaded && isEdit"></div>

        <div v-show="isLoaded && !isViewMode" class="intraoral-tools">

            <img src="images/undo_white.png" class="intraoral-tool-btn undo-btn" v-on:click="undo()" title="Undo" />
            <img src="images/redo_white.png" class="intraoral-tool-btn redo-btn" v-on:click="redo()" title="Redo" />

            <img id="intraoralRepairBtn" src="images/repair_white.png" class="intraoral-tool-btn repair-btn"
                v-on:click="repairColouring()" title="Repair Colouring" />

            <!-- <img id="intraoralSmoothBtn" src="images/border_white.png" class="intraoral-tool-btn border-btn"
                v-on:click="smoothBorder()" title="Smooth Border" />  -->

            <img v-if="!isFront" :src="isEdit ? 'images/annotation_red.png' : 'images/annotation_white.png'"
                class="intraoral-tool-btn edit-btn" v-on:click="edit()" title="Edit" />
            <img src="images/save_white.png" class="intraoral-tool-btn save-btn" v-on:click="saveOnServer()"
                title="Save" />
            <img src="images/reset.png" class="intraoral-tool-btn reset-btn" v-on:click="reset()" title="Reset" />
            <img src="images/GlobalReset.png" class="intraoral-tool-btn global-reset-btn" v-on:click="globalReset()"
                title="Global Reset" />

        </div>

        <img v-if="isLoaded && isClosable" src="images/cross_white.png" class="intraoral-tool-btn close-btn"
            v-on:click="close()" title="Exit Intraoral View" />
        <!--  -->
        <div v-show="isLoaded" id="intraoralRotatePanel">
            <img title="Occlusal View" src="images/intraoral/INTRAORAL_LOWER.png" class="intraoral-rotate-btn"
                v-on:click="rotateToView($event, 1)" />
            <img title="Occlusal Reversed" src="images/intraoral/INTRAORAL_UPPER.png" class="intraoral-rotate-btn"
                v-on:click="rotateToView($event, 2)" />
            <img title="Front View" src="images/intraoral/INTRAORAL_FRONT.png" class="intraoral-rotate-btn"
                v-on:click="rotateToView($event, 3)" />
            <img title="Rear View" src="images/intraoral/INTRAORAL_REAR.png" class="intraoral-rotate-btn"
                v-on:click="rotateToView($event, 4)" id="intraoralRearBtn" />
            <img title="Left Side" src="images/intraoral/INTRAORAL_RIGHT.png" class="intraoral-rotate-btn"
                v-on:click="rotateToView($event, 5)" />
            <img title="Right Side" src="images/intraoral/INTRAORAL_LEFT.png" class="intraoral-rotate-btn"
                v-on:click="rotateToView($event, 6)" />
        </div>

        <!-- <img id="intraoralRotateImg" class="intraoral-tool-btn" title="Rotate" src="images/angle_icon.png"
            v-on:click="toggleRotatePanel($event)" /> -->

        <div v-show="isLoaded" class="intraoral-slider-container-sm">
            <span class="intraoral-span-sm intraoral-opacity-span">OPACITY</span>
            <span class="intraoral-span-sm">1</span>
            <div ref="opacitySlider" class="intraoral-slider-sm"></div>
            <span class="intraoral-span-sm">0</span>
        </div>

        <div id="intraoralBottomButtons" class="intraoral-add-item">
            <button id="intraoralCancel" class="intraoral-bottom-button" v-on:click="cancelClick()">
                CANCEL
            </button>
        </div>
    </div>
</template>

<script>

//#region Fields

let upperModels = null;
let lowerModels = null;
let allModels = null;

//#endregion

import {
    initStlViewer, processGlbBuffer, applyBorderPoints, updateOpacity,
    switchViewModes, saveIntraoralData, resetIntraoral, globalResetIntraoral,
    updateIsEditMode, applySettings, cancelAdd, processStlModels,
    rotateIntraoralToView, randomColorValue, monochromeColorValue,
    greyColorValue, changeTeethColor, intraoralUndo, intraoralRedo,
    processDemoZip, smoothSelectedToothBorder, repairIntraoralColouring,
    applyAnchorPoints
} from "./IntraoralManager";

import {
    getGeometryFromStl
} from "./IntraoralLoader";

export default {
    name: "IntraoralViewer",
    data() {
        return {
            isLoaded: false,
            isClosable: true,
            isDouble: false,
            isUpper: true,
            isLower: false,
            isFront: false,
            isEdit: true,
            isIntraoralView: false,
            isViewMode: false,
            teethColor: 'random',
            teethColors: [
                { text: "Random Color", value: randomColorValue },
                { text: "Grey Color", value: greyColorValue, }, // 808080
                { text: "White Color", value: '#FFFFFF' },
                { text: "Monochrome", value: monochromeColorValue }
            ],
        };
    },
    mounted() {
        if (vueApp.isDemo) {
            let arr = vueApp.zipSegmentation.split("/");
            arr.pop();
            arr.push('demo.zip');
            let demoUrl = "demo/demo.zip"; // arr.join('/');
            console.log("demoUrl", demoUrl);
            this.fetchZip(demoUrl, (zip) => {
                console.log("demo fetchZip success", zip);
                processDemoZip(zip);
                this.isLoaded = true;
            });
            return;
        }

        if (vueApp.isViewMode) {
            this.isEdit = false;
            this.isViewMode = true;
        }

        this.isClosable = !!vueApp.zipFile;
        this.showLoader();

        let upperBuffer = null;
        let lowerBuffer = null;
        this.fetchZip(vueApp.zipSegmentation, (zip) => {
            console.log("fetchZip success", zip);
            let promises = [];
            let stlModels = [];

            if (!vueApp.isReset) {
                zip.forEach(function (relativePath, file) {
                    if (relativePath.startsWith("tooth_")) {
                        let zipFile = zip.file(relativePath);
                        let promise = zipFile.async("arraybuffer");
                        promises.push(promise);
                        promise.then(buffer => {

                            let model = {
                                name: relativePath.replace(".stl", ""),
                                geometry: getGeometryFromStl(buffer)
                            };
                            stlModels.push(model);
                        });
                    }
                });
            }
            Promise.all(promises).then((values) => {
                this.getArrayBufferFromZip(zip, "upper_segmented.glb", (buffer1) => {
                    upperBuffer = buffer1;
                    this.getArrayBufferFromZip(zip, "lower_segmented.glb", (buffer2) => {
                        lowerBuffer = buffer2;
                        if (upperBuffer === null && lowerBuffer === null) {
                            $("#loaderText").html(`Load error. Please contact support@cephx.com`);
                        } else {
                            this.isDouble = upperBuffer !== null && lowerBuffer !== null;
                            processGlbBuffer(upperBuffer, true, (models) => {
                                upperModels = models;
                                processGlbBuffer(lowerBuffer, false, (models) => {
                                    lowerModels = models;
                                    processStlModels(stlModels, upperModels.concat(lowerModels));

                                    allModels = upperModels.concat(lowerModels)
                                        .concat(stlModels.filter(m => m.isAdded));

                                    if (allModels.length) {
                                        this.loadSettingsFromZip(allModels, zip, () => {
                                            if (upperBuffer) {
                                                // this.fetchPointsJson(upperJsonUrl);
                                                this.loadBorderPointsFromZip(zip, "upper_border_points.json");
                                                this.loadAnchorPointsFromZip(zip, "upper_extr_anchors.json");
                                            }
                                            if (lowerBuffer) {
                                                this.loadBorderPointsFromZip(zip, "lower_border_points.json");
                                                this.loadAnchorPointsFromZip(zip, "lower_extr_anchors.json");
                                            }

                                            initStlViewer(allModels, stlModels, () => {
                                                this.isLoaded = true;
                                            });
                                        });
                                    } else {
                                        $("#loaderText").html(`Load error. Please contact support@cephx.com`);
                                    }
                                });
                            });
                        }
                    });
                });
            });
        });
        this.initControls();
    },
    methods: {
        initControls: function () {
            // this.sliderId = `${this.app.divId}_slider`;
            // $(`#${this.sliderId}`).slider("value", this.current);
            // this.$refs.opacitySlider.setAttribute("id", this.sliderId);
            $(`.intraoral-slider-sm`).slider({
                orientation: "vertical",
                min: 0,
                max: 1,
                step: 0.01,
                value: 1,
                slide: (slideEvent, ui) => {
                    updateOpacity(ui.value);
                    // console.log(slideEvent, ui);
                }
            });
        },
        fetchZip(path, callback) {
            fetch(path, { cache: "no-store" })
                .then((response) => {
                    if (response.status == 200) {
                        response.arrayBuffer().then(JSZip.loadAsync)
                            .then(function (zip) {
                                callback(zip);
                            })

                        // let buffer = await response.arrayBuffer();
                        // callback(buffer);
                    }
                    else {
                        console.log(response);
                        callback(null);
                    }
                }).catch((error) => {
                    console.log(`Error response for ${vueApp.zipSegmentation}. Error: ${error}`);
                    callback(null);
                });
        },
        getArrayBufferFromZip(zip, fileName, callback) {
            let zipFile = zip.file(fileName);
            if (zipFile) {
                zipFile.async("arraybuffer").then(buffer => {
                    callback(buffer);
                });
            } else {
                callback(null);
            }
        },
        loadSettingsFromZip(models, zip, callback) {
            let fileName = "intraoral_settings.json";
            let settingsFile = zip.file(fileName);
            if (settingsFile) {
                settingsFile.async("string").then(text => {
                    try {
                        let settings = JSON.parse(text);
                        applySettings(models, settings);
                        callback();
                    } catch (e) {
                        console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
                        callback();
                    }
                });
            } else {
                callback();
            }
        },
        loadAnchorPointsFromZip(zip, fileName) {
            let basePointsFile = zip.file(fileName);
            if (basePointsFile) {
                basePointsFile.async("string").then(text => {
                    try {
                        let anchorPoints = JSON.parse(text);
                        applyAnchorPoints(anchorPoints, true);

                        let savedPointsFile = zip.file("saved_" + fileName);
                        if (savedPointsFile && !vueApp.isReset) {
                            savedPointsFile.async("string").then(text => {
                                try {
                                    anchorPoints = JSON.parse(text);
                                    applyAnchorPoints(anchorPoints);
                                } catch (e) {
                                    console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
                                }
                            });
                        }
                    } catch (e) {
                        console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
                    }
                });
            } else {
                console.log(`No such file in zip response - ${fileName}.`);

                let savedPointsFile = zip.file("saved_" + fileName);
                if (savedPointsFile && !vueApp.isReset) {
                    savedPointsFile.async("string").then(text => {
                        try {
                            anchorPoints = JSON.parse(text);
                            applyAnchorPoints(anchorPoints);
                        } catch (e) {
                            console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
                        }
                    });
                }
            }
        },
        loadBorderPointsFromZip(zip, fileName) {
            let basePointsFile = zip.file(fileName);
            if (basePointsFile) {
                basePointsFile.async("string").then(text => {
                    try {
                        let borderPoints = JSON.parse(text);
                        applyBorderPoints(borderPoints, true);

                        let savedPointsFile = zip.file("saved_" + fileName);
                        if (savedPointsFile && !vueApp.isReset) {
                            savedPointsFile.async("string").then(text => {
                                try {
                                    borderPoints = JSON.parse(text);
                                    applyBorderPoints(borderPoints);
                                } catch (e) {
                                    console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
                                }
                            });
                        }
                    } catch (e) {
                        console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
                    }
                });
            } else {
                console.log(`No such file in zip response - ${fileName}.`);
            }
        },
        fetchZip2(callback) {
            var promise = new JSZip.external.Promise(function (resolve, reject) {
                JSZipUtils.getBinaryContent(vueApp.zipSegmentation, {
                    progress: function (event) {
                        let percent = Math.ceil(event.percent * 0.99);
                        console.log(`intraoral zip ${percent} %`);
                    },
                    callback: function (err, zipData) {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(`intraoral zip downloaded, ${vueApp.zipSegmentation}`);
                            resolve(zipData);
                            // callback(zipData);
                        }
                    },
                });
            });

            promise
                .then(JSZip.loadAsync)
                .then(function (zip) {
                    callback(zip);
                })
                .then(
                    function success(text) {
                        console.log("intraoral zip success");
                    },
                    function error(e) {
                        if (callback) callback(null);
                    }
                );
        },
        fetchGlbBuffer(glbUrl, callback) {
            if (!glbUrl) {
                callback(null);
                return;
            }
            fetch(glbUrl)
                .then(async (response) => {
                    // console.log(response);
                    if (response.status == 200) {
                        let buffer = await response.arrayBuffer();
                        callback(buffer);
                    }
                    else
                        callback(null);
                }).catch((error) => {
                    console.log(`Error response for ${glbUrl}. Error: ${error}`);
                    callback(null);
                });
        },
        fetchPointsJson: function (jsonUrl) {
            if (!jsonUrl)
                return;
            fetch(jsonUrl)
                .then((response) => {
                    response.text().then((text) => {
                        try {
                            let borderPoints = JSON.parse(text);
                            applyBorderPoints(borderPoints);
                        } catch (e) {
                            console.log(`Invalid JSON: ${jsonUrl}! Error: ${e}`);
                        }
                    });
                })
                .catch((error) => {
                    console.log(`Error response for ${jsonUrl}. Error: ${error}`);
                });
        },
        showLoader: function () {
            $id("loader").style.display = "block";
            $("#loaderText").html(`Loading data... Please wait`);
            $("#loaderText2").html(``);
            $("#loaderText3").html(``);
        },
        edit: function () {
            this.isEdit = !this.isEdit;
            updateIsEditMode(this.isEdit);
        },
        undo: function () {
            intraoralUndo();
        },
        redo: function () {
            intraoralRedo();
        },
        smoothBorder: function () {
            smoothSelectedToothBorder();
        },
        repairColouring: function () {
            repairIntraoralColouring();
        },
        saveOnServer: function () {
            swal({
                text: "Are you sure you want to save intraoral changes?",
                buttons: {
                    yes: { text: "Yes", value: true },
                    no: { text: "No", value: false }
                }
            }).then(save => {
                if (save) {
                    saveIntraoralData();
                }
            });
        },
        switchUpper: function () {
            if (!this.isUpper) {
                this.isUpper = this.isEdit = true;
                this.isLower = this.isFront = false;
                switchViewModes(this.isUpper, this.isLower);
            }
        },
        switchLower: function () {
            if (!this.isLower) {
                this.isLower = this.isEdit = true;
                this.isUpper = this.isFront = false;
                switchViewModes(this.isUpper, this.isLower);
            }
        },
        switchFront: function () {
            if (!this.isFront) {
                this.isFront = true;
                this.isUpper = this.isLower = this.isEdit = false;
                switchViewModes(true, true);
            }
        },
        reset: function () {
            swal({
                text: "Are you sure you want to reset to saved state?",
                buttons: {
                    yes: { text: "Yes", value: true },
                    no: { text: "No", value: false }
                }
            }).then(confirm => {
                if (confirm) {
                    resetIntraoral();
                }
            });
        },
        globalReset: function () {
            swal({
                text: "Are you sure you want to reset all changes?",
                buttons: {
                    yes: { text: "Yes", value: true },
                    no: { text: "No", value: false }
                }
            }).then(confirm => {
                if (confirm) {
                    globalResetIntraoral();
                }
            });
        },
        toggleRotatePanel: function (event) {
            let isVisible = $("#intraoralRotatePanel").css("display") != "none";
            if (isVisible) {
                $("#intraoralRotatePanel").fadeOut();
            } else {
                // vueApp.leaveModes(false);
                $("#intraoralRotatePanel").slideDown();
            }
        },
        rotateToView: function (event, viewNumber) {
            rotateIntraoralToView(viewNumber);
        },
        teethColorChanged: function () {
            changeTeethColor(this.teethColor);
        },
        close: function () {
            document.querySelector('#intraoralViewer').style.display = 'none';
            $(".menu3d").removeAttr("style")
            // $(".menu3d").attr("disabled", false);
            document.querySelector('#views_cont').style.display = 'flex';
            if (viewer) viewer.do_resize();
        },
        cancelClick: function () {
            updateIsEditMode(this.isEdit);
            cancelAdd();
        }
    }
};
</script>

<style scoped>
#selectedToothHint {
    position: absolute;
    width: 150px;
    left: calc(50% - 106px);
    top: 20px;
    background-color: transparent;
    color: white;
    /* font-weight: bold; */
    text-align: center;
}

#intraoralColor {
    position: absolute;
    width: 130px;
    top: 11px;
    left: 60px;
    /* right: 160px; */
}

.intraoral-tool-select {
    height: 27px;
    padding: 2px;
}

#intraoralRotateImg {
    width: 30px;
    height: 30px;
    bottom: 15px;
    left: calc(50% - 5px);
}

#intraoralRotatePanel {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 125px);
}

.intraoral-rotate-btn {
    width: 35px;
    height: 35px;
    margin-left: 8px;
    text-align: right;
    cursor: pointer;
    border: 1px solid #FFFFFF;
}

#intraoralRearBtn {
    padding: 5px;
}

#intraoralViewer {
    position: relative;
    width: 100%;
    height: calc(100% - 35px);
    /* z-index: 9; */
}

.intraoral-container {
    background: #77a7fd;
    width: 100%;
    height: 100%;
}

.intraoral-hint {
    position: absolute;
    z-index: 999;
    width: 350px;
    left: calc(50% - 175px);
    top: 15px;
    text-align: center;
    color: white;
    font-family: Montserrat, sans-serif;
    background-color: transparent;
    display: none;
}

#intraoralAddHint2 {
    top: 40px;
}

#intraoralBottomButtons {
    position: absolute;
    text-align: center;
    white-space: nowrap;
    left: calc(50% - 95px);
    top: 70px;
    z-index: 9;
    display: none;
    width: 200px;
    height: 35px;
}

.intraoral-bottom-button {
    display: inline-block;
    margin-right: 10px;
    min-width: 90px;
    width: auto;
    height: 35px;
    border: 0;
    cursor: pointer;
    font-size: small;
    background-color: #4687fc;
    text-align: center;
    color: #ffffff;
}

.intraoral-tools {
    position: absolute;
    top: 15px;
    right: 50px;
    display: flex;
    align-items: stretch;
    gap: 10px;
    z-index: 99;
    /* padding: 0 0.25rem; */
}

.intraoral-tool-btn {
    cursor: pointer;
    /* position: absolute; */
    /* right: 45px; */
    width: 25px;
    height: 25px;
}

.undo-btn {
    width: 27px;
    height: 27px;
    border: 1px solid #FFFFFF;
}

.redo-btn {
    width: 27px;
    height: 27px;
    border: 1px solid #FFFFFF;
    margin-right: 7px
}

.border-btn {
    width: 27px;
    height: 27px;
}

.save-btn {
    width: 25px;
    height: 25px;
    margin-top: 1px;
}

.reset-btn {
    width: 26px;
    height: 26px;
    margin-left: 3px;
    margin-top: 1px;
}

.global-reset-btn {
    margin-left: -3px;
    margin-top: 2px;
    width: 24px;
    height: 24px;
}

.close-btn {
    position: absolute;
    cursor: pointer;
    top: 17px;
    right: 15px;
    width: 24px;
    height: 24px;
}

.jaw-btn {
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 99;
    border: 1px solid #FFFFFF;
}

.upper-jaw-btn {
    top: 15px;
    left: 15px;
}

.lower-jaw-btn {
    top: 50px;
    left: 15px;
}

.front-jaw-btn {
    top: 85px;
    left: 15px;
}
</style>

<style>
.intraoral-menu {
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    display: none;
    position: absolute;
    width: auto;
    background-color: white;
    box-shadow: 0 0 5px grey;
    border-radius: 3px;
    z-index: 999;
    max-width: 120px;
}

.intraoral-menu .intraoral-menu-btn {
    width: 100%;
    background-color: #4687fc;
    color: white;
    margin: 0;
    padding: 5px;
    border: 0;
    border-top: 1px solid #D9E6FE;
}

.intraoral-menu>.intraoral-menu-btn:first-child {
    border: 0;
}

.intraoral-slider-container-sm {
    position: absolute;
    text-align: center;
    width: 35px;
    right: 20px;
    top: 62px;
    height: calc(100% - 180px);
    z-index: 3;
}

.intraoral-span-sm {
    color: #FFFFFF;
    display: block;
    font-size: small;
}

.intraoral-opacity-span {
    margin-left: -12px;
}

.intraoral-slider-sm {
    height: calc(100% - 7px);
    border-radius: 0;
    outline: none;
    width: 0.38rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: calc(50% - 0.25rem);
}

.intraoral-slider-sm .ui-slider-range {
    display: none;
}

.intraoral-slider-sm .ui-slider-handle {
    outline: none;
    border-radius: 30px;
    width: 1.4rem;
    height: 1.4rem;
    left: -.54rem;
}
</style>