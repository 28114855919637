<template>
    <div class="simple-dicom-viewer">

        <div v-show="!is3DMode" class="dicom-zoom-container">
            <img src="images/white_plus.png" class="dicom-zoom-btn" v-on:click="changeZoom(1)" />
            <img src="images/white_minus.png" class="dicom-zoom-btn" v-on:click="changeZoom(-1)" />
            <img src="images/reset.png" class="dicom-zoom-btn" v-on:click="resetZoom()" />
        </div>

        <div v-show="!is3DMode" class="dicom-slider-container-sm">
            <span class="dicom-span-sm">{{ max }}</span>
            <div ref="dicomSlider" class="dicom-slider-sm"></div>
            <span class="dicom-span-sm">1</span>
        </div>

        <img v-show="app.index == 2 && has3dItem" class="dicom-view-btn" v-on:click="switchView()" title="Switch view"
            :src="app.is3D ? 'images/3d_red.png' : 'images/3d_white.png'" />

        <img v-show="!is3DMode && app.parentApp.is3D" class="dicom-3dlayer-btn"
            :src="show3DLayer ? 'images/layer_red.png' : 'images/layer_white.png'" v-on:click="show3DLayerChanged()"
            title="Show slice in 3D" />

        <img v-show="!is3DMode" class="dicom-segmentation-btn"
            :src="showSegmentation ? 'images/segmentation_red.png' : 'images/segmentation_white.png'"
            v-on:click="showSegmentationChanged()" title="Segmentation" />

        <img v-show="!is3DMode" class="dicom-intersection-btn"
            :src="showIntersection ? 'images/intersection_red.png' : 'images/intersection_white.png'"
            v-on:click="showIntersectionChanged()" title="Show projection lines" />

        <div v-show="!is3DMode" class="dicom-offset-sm"> {{ offset }} </div>

        <div v-show="is3DMode" ref="auxiliaryViewer" class="auxiliary-viewer">

        </div>
    </div>
</template>

<script>

import {
    updateCanvasContext, setSliceIndex, setupCanvasEvents,
    update3DFrame, changeAppZoom, changeAppTool,
    updateSliceIntersection, updateNeighborIntersections
} from "./DicomManager";

import {
    switchAuxiliaryView
} from "./AuxiliaryManager";

let dwv = require('dwv');

export default {
    name: "SimpleDicomViewer",
    data() {
        return {
            current: 0,
            max: 100,
            show3DLayer: false,
            showIntersection: true,
            showSegmentation: true
        };
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    computed: {
        offset() {
            return `${this.current} / ${this.max}`;
        },
        is3DMode() {
            return this.app.is3D && (this.app.parentApp.editedAnnotation || this.app.parentApp.focusedAnnotation);
        },
        has3dItem() {
            return (this.app.parentApp.editedAnnotation || this.app.parentApp.focusedAnnotation);
        }
    },
    mounted() {
        console.log(this.app.divId);
        this.$el.setAttribute("id", this.app.divId);
        this.$el.setAttribute("style", `border: solid ${this.app.color} 0.1rem;`);

        this.app.auxiliaryId = this.app.divId + "_auxiliary";
        this.$refs.auxiliaryViewer.setAttribute("id", this.app.auxiliaryId);

        let app = this.app;

        const viewConfig = new dwv.ViewConfig(app.divId);
        viewConfig.orientation = app.orientation;
        const viewConfigs = { '*': [viewConfig] };
        const options = new dwv.AppOptions(viewConfigs);
        options.tools = {
            ZoomAndPan: {},
            Livewire: {},
            Annotation: {},
            Floodfill: {},
            Draw: {
                options: ['Ruler'],
                type: 'factory'
            }
        };
        options.viewOnFirstLoadItem = true;
        app.init(options);
        app.tools = options.tools;

        app.addEventListener('load', (event) => {
            app.setTool('Annotation');
            const size = app.getImage(0).getGeometry().getSize();
            this.max = app.max = size.get(app.viewIndex);
            this.current = app.current = Math.floor(app.max / 2) + 1;
            this.initControls();
        });
        app.addEventListener('renderend', () => {
            if (!app.baseSize) {
                const activeLayer = this.getActiveLayer();
                // app.baseSize = activeLayer.getBaseSize();
                const viewController = activeLayer.getViewController();
                app.worldSize = viewController.getImageWorldSize();
                app.spacing = viewController.get2DSpacing();
                app.baseSize = { x: app.worldSize.x / app.spacing[0], y: app.worldSize.y / app.spacing[1] };

                setupCanvasEvents(app);
                app.setTool('Annotation');
                // updateCanvasContext(app);
            }
        });
        app.addEventListener('positionchange', (event) => {
            this.updateCurrentIndex();
            $(`#${this.sliderId}`).slider("value", this.current);
            // if (app.index != app.parentApp.index) {
            updateNeighborIntersections(app.parentApp, app.parentApp.crossedApps);
            // }

            updateCanvasContext(app);
            update3DFrame(app);
        });
        app.addEventListener('offsetchange', (event) => {
            // console.log(`app ${app.index} offsetchange`, event);
            if (!app.offset) {
                app.offsetchanged = true;
            } else {
                for (let i = 0; i < event.value.length; i++) {
                    if (event.value[i] != app.offset[i]) {
                        app.offsetchanged = true;
                    }
                }
            }

            if (app.offsetchanged) {
                app.offset = event.value;
                updateSliceIntersection(app, app.parentApp.crossedApps);
            }
        });
        app.addEventListener('zoomchange', (event) => {
            if (!app.zoomValue || app.zoomValue != event.value) {
                app.zoomValue = event.value;
                updateSliceIntersection(app, app.parentApp.crossedApps);
                if (app.showSegmentation)
                    setTimeout(function () {
                        setSliceIndex(app, app.current - 1);
                        // updateCanvasContext(app);
                    }, 50);
            }
        });

        window.addEventListener('resize', (event) => {
            try {
                if (app.parentApp.visible) {
                    let current = this.current;
                    let currentConfigs = app.getDataViewConfigs();
                    app.setDataViewConfigs(currentConfigs);
                    for (let i = 0; i < app.getNumberOfLoadedData(); ++i) {
                        app.render(i);
                    }
                    app.onResize();
                    // app.resetZoom();
                    setSliceIndex(app, current - 1);
                    setupCanvasEvents(app);
                    app.setTool('Annotation');
                    // setTimeout(function () {
                    // }, 500);
                }
            } catch (error) {
                console.error(error);
            }
        });

        if (this.app.parentApp) {
            setTimeout(() => {
                const image0 = this.app.parentApp.getImage(0);
                this.app.addNewImage(image0.clone());
            }, 100);
        }

    },
    methods: {
        initControls: function () {
            if (this.app) {
                this.sliderId = `${this.app.divId}_slider`;
                this.$refs.dicomSlider.setAttribute("id", this.sliderId);
                $(`#${this.sliderId}`).slider({
                    orientation: "vertical",
                    min: 0,
                    max: this.max,
                    step: 1,
                    value: this.current,
                    slide: (slideEvent, ui) => {
                        setSliceIndex(this.app, ui.value - 1);
                    }
                });
            }
        },
        getActiveLayer: function () {
            const layerGroup = this.app.getLayerGroupByDivId(this.app.divId);
            return layerGroup.getActiveViewLayer();
        },
        getDrawLayer: function () {
            const layerGroup = this.app.getLayerGroupByDivId(this.app.divId);
            return layerGroup.getActiveDrawLayer();
        },
        getViewController: function () {
            const activeLayer = this.getActiveLayer();
            return activeLayer.getViewController();
        },
        updateCurrentIndex: function () {
            const viewController = this.getViewController();
            let currentIndex = viewController.getCurrentIndex();
            this.current = this.app.current = currentIndex.get(this.app.viewIndex) + 1;
            return this.current;
        },
        changeZoom: function (factor) {
            changeAppZoom(this.app, factor);
        },
        resetZoom: function (factor) {
            if (this.app) {
                this.app.resetZoom();
                updateCanvasContext(this.app);
            }
        },
        showIntersectionChanged: function () {
            this.showIntersection = !this.showIntersection;
            this.app.showIntersection = this.showIntersection;
            updateSliceIntersection(this.app, this.app.parentApp.crossedApps);
        },
        showSegmentationChanged: function () {
            this.showSegmentation = !this.showSegmentation;
            if (this.app) {
                this.app.showSegmentation = this.showSegmentation;
                updateCanvasContext(this.app);
                // setSliceIndex(this.app, this.current - 1);
            }
        },
        show3DLayerChanged: function () {
            this.show3DLayer = !this.show3DLayer;
            if (this.app) {
                this.app.show3DLayer = this.show3DLayer;
                update3DFrame(this.app);
                // setSliceIndex(this.app, this.current - 1);
            }
        },
        switchView: function () {
            this.app.is3D = !this.app.is3D;
            if (this.app.is3D) {
                this.app.showIntersection = false;
            } else {
                this.app.showIntersection = this.showIntersection;
            }
            updateSliceIntersection(this.app, this.app.parentApp.crossedApps);
            switchAuxiliaryView(this.app);
        }
    }
}

</script>

<style scoped>
.simple-dicom-viewer {
    position: relative;
    background-color: black;
    height: 33.333%;
    border: 1px solid red;
    box-sizing: border-box;
    color: white;
    align-self: stretch;
    flex-grow: 1;
}

.dicom-offset-sm {
    position: absolute;
    right: 8px;
    bottom: 4px;
    z-index: 9;
    font-size: 0.9rem;
    color: white;
}

.dicom-zoom-container {
    position: absolute;
    text-align: center;
    width: 35px;
    left: 2px;
    top: 12px;
    z-index: 3;
}

.dicom-zoom-btn {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    color: white;
    cursor: pointer;
    /* font-size: 2rem; */
}

.dicom-view-btn {
    cursor: pointer;
    position: absolute;
    top: 11px;
    left: 42px;
    width: 24px;
    height: 24px;
    z-index: 99;
}

.dicom-3dlayer-btn {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 79px;
    width: 24px;
    height: 24px;
    z-index: 3;
}

.dicom-segmentation-btn {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 42px;
    width: 30px;
    height: 30px;
    z-index: 3;
}

.dicom-intersection-btn {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 8px;
    width: 30px;
    height: 30px;
    z-index: 3;
}

.auxiliary-viewer {
    position: absolute;
    background: #77a7fd;
    width: 100%;
    height: 100%;
    z-index: 9;
}
</style>

<style>
.dicom-slider-container-sm {
    position: absolute;
    text-align: center;
    width: 35px;
    left: 1px;
    top: 102px;
    height: calc(100% - 160px);
    z-index: 3;
}

.dicom-span-sm {
    color: #FFFFFF;
    display: block;
    font-size: small;
}

.dicom-slider-sm {
    height: calc(100% - 7px);
    border-radius: 0;
    outline: none;
    width: 0.38rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: calc(50% - 0.25rem);
}

.dicom-slider-sm .ui-slider-range {
    display: none;
}

.dicom-slider-sm .ui-slider-handle {
    outline: none;
    border-radius: 30px;
    width: 1.4rem;
    height: 1.4rem;
    left: -.54rem;
}
</style>