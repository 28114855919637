import * as THREE from "three";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { STLLoader } from "three/addons/loaders/STLLoader.js";
import { STLExporter } from "three/addons/exporters/STLExporter.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

function getGeometryFromStl(arrayBuffer, callback) {
  let loader = new STLLoader();
  const geometry = loader.parse(arrayBuffer);
  if (callback) callback(geometry);
  return geometry;
}

function getGeometryFromGlb(arrayBuffer, callback) {
  let loader = new GLTFLoader();
  loader.parse(arrayBuffer, "", function (result) {
    let world = result.scene.children[0];
    let geometries = [];
    world.children.forEach((element) => {
      if (element.type === "Mesh") {
        geometries.push(element.geometry);
      }
    });

    let modelGeometry = getGMergedGeometry(geometries);
    if (callback) callback(modelGeometry);
  });
}

function getGeometryFromDrc(arrayBuffer, callback) {
  // <script src="https://www.gstatic.com/draco/versioned/decoders/1.4.1/draco_wasm_wrapper.js"></script>
  let dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
  );

  dracoLoader.parse(arrayBuffer, callback);
}

function getMergedGeometry(meshes) {
  let modelGeometry = null;
  if (meshes && meshes.length) {
    let geometries = [];
    meshes.forEach((b) => {
      let clone = b.clone();
      clone.updateMatrixWorld();

      let geometry = clone.geometry.clone().applyMatrix4(clone.matrixWorld);
      if (geometry.index) geometry = geometry.toNonIndexed();
      if (geometry.attributes) {
        geometry.deleteAttribute("color");
        geometry.deleteAttribute("uv");
        // geometry.deleteAttribute("normal");
        // geometry.computeVertexNormals();
        // geometry.computeBoundingBox();
        geometry.attributes.position.gpuType = 1015;
        geometry.attributes.normal.gpuType = 1015;
      }

      geometries.push(geometry);
    });

    modelGeometry = BufferGeometryUtils.mergeGeometries(geometries, true);
    modelGeometry.deleteAttribute("normal");
    modelGeometry = BufferGeometryUtils.mergeVertices(modelGeometry); // , 1
    modelGeometry.computeVertexNormals();
  }
  return modelGeometry;
}

function getGMergedGeometry(mgeometries) {
  let modelGeometry = null;
  if (mgeometries && mgeometries.length) {
    let geometries = [];
    mgeometries.forEach((mgeometry) => {
      let geometry = mgeometry.clone(); //.applyMatrix4(clone.matrixWorld);
      if (geometry.index) geometry = geometry.toNonIndexed();
      if (geometry.attributes) {
        geometry.deleteAttribute("color");
        geometry.deleteAttribute("uv");
        // geometry.deleteAttribute("normal");
        // geometry.computeVertexNormals();
        // geometry.computeBoundingBox();
        if (geometry.attributes.position)
          geometry.attributes.position.gpuType = 1015;
        if (geometry.attributes.normal)
          geometry.attributes.normal.gpuType = 1015;
      }
      geometries.push(geometry);
    });

    modelGeometry = BufferGeometryUtils.mergeGeometries(geometries, true);
    modelGeometry.deleteAttribute("normal");
    modelGeometry = BufferGeometryUtils.mergeVertices(modelGeometry); // , 1
    modelGeometry.computeVertexNormals();
  }
  return modelGeometry;
}

function exportMeshToStlBlob(mesh) {
  const stlExporter = new STLExporter();
  // const data = stlExporter.parse(mesh);
  // const blob = new Blob([data], { type: 'text/plain' });
  const data = stlExporter.parse(mesh, { binary: true });
  const blob = new Blob([data], { type: "application/octet-stream" });
  return blob;
}

export {
  getGeometryFromStl,
  getGeometryFromGlb,
  getGeometryFromDrc,
  getMergedGeometry,
  exportMeshToStlBlob,
};
