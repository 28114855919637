<template>

	<div id="crownPlanButtons" class="crown-ctrl" v-bind:style="{display: editContext.display}">
		<div id="crownSaveButtons">
			<button class="crown-button" v-on:click="saveMode()">
				SAVE
			</button>

			<button id="orthoPlanCancel" class="crown-button"
				v-on:click="cancelMode()">
				CANCEL
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "EditCrownMode",
	props: ["editContext"],
	methods: {
		saveMode: function() {
			this.editContext.display = "none";
			saveCrown();
			exitEditCrownMode();
		},
		cancelMode: function() {
			this.editContext.display = "none";
			var crown = vueApp.getItemById(this.editContext.id);
			resetCrown(crown);
			exitEditCrownMode();
		}
	}
};

import EditCrownMode from "./EditCrownMode.vue";
import ImplantSidePanel from "../implants/ImplantSidePanel.vue";
import {
	enterMoveMode,
	exitMoveMode,
	moveModeMouseDown,
	moveModeMouseMove,
	moveModeMouseUp,
	moveModeValueChanged,
	moveByTag
} from "../newMove/newMoveMode";
import {
	enterRotateMode,
	exitRotateMode,
	rotateModeValueChanged,
	rotateByTag,
	rotateModeMouseDown,
	rotateModeMouseMove,
	rotateModeMouseUp
} from "../newRotate/newRotateMode";
import { enterResizeMode, exitResizeMode } from "./resize/resizeMode";
import { focusToObject } from "../editObject";
import { saveViewerData, loadViewerData } from "../saveLoadData";

var editMode = {
	id: null,
	selectedId: null,
	tooth: null,
	defaultPosition: null,
	defaultBox: null,
	display: "none",
	isMoved: false,
	isRotated: false,
	isResized: false,
	isActive: false,
	isVertical: false,
	isApex: false,
	showTapArrows: false,
	sliderValue: 0,
	enterMode: function() {
		enterEditCrownMode();
	},
	leaveMode: function(saveActiveMode) {
		exitEditCrownMode(saveActiveMode);
	},
	toothSelected: function(tooth, event) {
		if (editMode.isMoved) {
			moveModeMouseDown(tooth, event);
		} else if (editMode.isRotated) {
			rotateModeMouseDown(tooth, event);
		}
	},
	tagDetected: function(tag) {
		if (editMode.isMoved) {
			moveByTag(tag);
		} else if (editMode.isRotated) {
			rotateByTag(tag);
		}
	},
	mouseMove: function(event) {
		if (editMode.isMoved) {
			moveModeMouseMove(event);
		} else if (editMode.isRotated) {
			rotateModeMouseMove(event);
		}
	},
	mouseUp: function(event) {
		if (editMode.isMoved) {
			moveModeMouseUp(event);
		} else if (editMode.isRotated) {
			rotateModeMouseUp(event);
		}
	}
};

function enterEditCrownMode(crown) {
	if (crown) {
		initializeControls();
		vueApp.leaveModes();
		vueApp.activeMode = editMode;

		disableImplantsMenu();

		editMode.id = editMode.selectedId = crown.id;
		editMode.isActive = true;
		editMode.display = "block";
		editMode.tooth = crown;
		editMode.defaultPosition = crown.defaultPosition;
		editMode.defaultBox = crown.defaultBox;

		vueApp.allDents.forEach(function(item) {
			if (item.isVisible) {
				viewer.set_opacity(item.id, 0.8);
			}
		});

		focusToObject(crown.defaultPosition, crown.focusCameraPosition);

		// !!!
		editedImplant = crown;
		$("#bones_opacity_container").css("display", "block");
		//$("#teeth_opacity_container").css("display", "block");
		setTimeout(function() {
			enterMoveMode(editMode);
		}, 250);
	}
}

var ctrlsInitialized = false;
function initializeControls() {
	initializeOpacity();

	if (!ctrlsInitialized) {
		var ctrls = [EditCrownMode, ImplantSidePanel];
		ctrls.forEach(component => {
			const componentInstance = new Vue({
				...component,
				propsData: { editContext: editMode }
			});
			const vueContainer = document.createElement("div");
			document.getElementById("main_div").appendChild(vueContainer);
			componentInstance.$mount(vueContainer);
		});

		initMovementVector(movementVectorValueChanged, "#movementVector2");
		canecode_switch("#moveShowTapArrowsButton2");

		switchToggle("moveShowTapArrowsDiv2", false);

		ctrlsInitialized = true;
	}
}

function movementVectorValueChanged(value) {
	editMode.sliderValue = value;
	if (editMode.isMoved) {
		moveModeValueChanged(value);
	} else if (editMode.isRotated) {
		rotateModeValueChanged(value);
	}
}

function saveCrown() {
	var crown = vueApp.getItemById(editMode.id);
	if (crown && crown.isCrown) {
		var mesh = viewer.get_model_mesh(crown.id);
		crown.defaultRotation = mesh.rotation.clone();
		crown.defaultPosition = mesh.position.clone();
		crown.defaultBuccalScale = crown.currentBuccalScale;
		crown.defaultMessialScale = crown.currentMessialScale;
		crown.defaultHeightScale = crown.currentHeightScale;
	} else if (editedImplant) {
		var mesh = viewer.get_model_mesh(editedImplant.id);
		editedImplant.defaultRotation = mesh.rotation.clone();
		editedImplant.rotationX = editedImplant.defaultRotation.x;
		editedImplant.rotationY = editedImplant.defaultRotation.y;
		editedImplant.rotationZ = editedImplant.defaultRotation.z;
		editedImplant.defaultPosition = mesh.position.clone();
		editedImplant.defaultDiameter = editedImplant.diameter;
		editedImplant.defaultLength = editedImplant.length;
	}
	vueApp.saveData();
}

function exitEditCrownMode() {
	exitMoveMode();
	exitRotateMode();
	exitResizeMode();

	if (editMode.isActive) {
		enableImplantsMenu();

		editMode.isMoved = editMode.isRotated = editMode.isResized = editMode.isActive = false;
		editMode.display = "none";
		$(".crown-ctrl").css({ display: "none" });

		vueApp.bones.forEach(function(model) {
			if (model.isVisible) {
				viewer.set_opacity(model.id, vueApp.bonesOpacity);
			}
		});

		vueApp.crowns.concat(vueApp.implants).forEach(function(item) {
			if (item.isVisible) {
				viewer.set_opacity(item.id, 1);
			}
		});

		vueApp.allTeeth.forEach(function(model) {
			if (model.isVisible) {
				viewer.set_opacity(model.id, vueApp.teethOpacity);
			}
		});

		editedImplant = null;
	}
}

function resetCrown(crown) {
	//var crown = vueApp.getItemById(this.editContext.id);
	if (crown && crown.isCrown) {
		viewer.set_position(
			crown.id,
			crown.defaultPosition.x,
			crown.defaultPosition.y,
			crown.defaultPosition.z
		);

		var mesh = viewer.get_model_mesh(crown.id);
		mesh.rotation.copy(crown.defaultRotation);

		var scaleX = crown.defaultMessialScale / crown.currentMessialScale;
		var scaleY = crown.defaultHeightScale / crown.currentHeightScale;
		var scaleZ = crown.defaultBuccalScale / crown.currentBuccalScale;

		mesh.geometry.scale(scaleX, scaleY, scaleZ);

		crown.currentBuccalScale = crown.defaultBuccalScale;
		crown.currentHeightScale = crown.defaultHeightScale;
		crown.currentMessialScale = crown.defaultMessialScale;
	} else if (editedImplant) {
		var implantCenter = editedImplant.defaultPosition;
		viewer.set_position(
			editedImplant.id,
			implantCenter.x,
			implantCenter.y,
			implantCenter.z
		);

		var mesh = viewer.get_model_mesh(editedImplant.id);
		mesh.rotation.copy(editedImplant.defaultRotation);

		var scaleX = editedImplant.defaultDiameter / editedImplant.diameter;
		var scaleY = editedImplant.defaultLength / editedImplant.length;
		editedImplant.diameter = editedImplant.defaultDiameter;
		editedImplant.length = editedImplant.defaultLength;
		var impl = mesh; //.children[0];
		impl.geometry.scale(scaleX, scaleY, scaleX);
	}
}

export { enterEditCrownMode, exitEditCrownMode, resetCrown };
</script>

<style>
@import "../../css/editControls.css";

.crown-ctrl {
	position: absolute;
	display: none;
	text-align: center;
	z-index: 9;
}

#crownPlanButtons {
	left: calc(50% - 75px);
	bottom: 65px;
	z-index: 9;
	display: none;
	width: 330px;
	height: 35px;
}

#crownSaveButtons {
	position: absolute;
	max-width: 330px;
	height: 35px;
	left: -5px;
	text-align: center;
	white-space: nowrap;
}

.crown-button {
	display: inline-block;
	margin-right: 10px;
	min-width: 90px;
	width: auto;
	height: 35px;
	/* left: calc(50% - 57px); */
	/* bottom: 65px; */
	border: 0;
	cursor: pointer;
	font-size: small;
	background-color: #4687fc; /*#F7FAFF;*/
	text-align: center;
	color: #ffffff;
	/* position: absolute; */
}

@media screen and (max-width: 650px) {
	#crownSaveButtons {
		left: -5px;
	}
}
</style>

