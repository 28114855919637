function rotatePoint2D(center, angle, point) {
	var cx = center.x;
	var cy = center.y;

	var sin = Math.sin(angle);
	var cos = Math.cos(angle);

	// translate point back to origin:
	var dx = point.x - cx;
	var dy = point.y - cy;

	// rotate point counter clockwise 
	var xnew = dx * cos - dy * sin;
	var ynew = dx * sin + dy * cos;

	// rotate point clockwise 
	// var xnew = dx * cos + dy * sin;
	// var ynew = -dx * sin + dy * cos;

	// translate point back:
	point.x = xnew + cx;
	point.y = ynew + cy;
	return point;
}

function rotatePoint3D(center, angleX, angleY, angleZ, point) { /// TODO???
	var newPoint = { x: point.x, y: point.y, z: point.z }

	var rotated = rotatePoint2D({ x: center.y, y: center.z }, angleX, { x: newPoint.y, y: newPoint.z });
	newPoint.y = rotated.x;
	newPoint.z = rotated.y;

	rotated = rotatePoint2D({ x: center.x, y: center.z }, angleY, { x: newPoint.x, y: newPoint.z });
	newPoint.x = rotated.x;
	newPoint.z = rotated.y;

	rotated = rotatePoint2D({ x: center.x, y: center.y }, angleZ, { x: newPoint.x, y: newPoint.y });
	newPoint.x = rotated.x;
	newPoint.y = rotated.y;

	return newPoint;
}

function getPointHonBC(A, B, C) {
	var AB = calculateDistance(A, B);
	var AC = calculateDistance(A, C);
	var BC = calculateDistance(B, C);

	if (Math.pow(AB, 2) > Math.pow(AC, 2) + Math.pow(BC, 2))
		return C;
	if (Math.pow(AC, 2) > Math.pow(AB, 2) + Math.pow(BC, 2))
		return B;

	// AH^2 = AC^2 - HC^2 = AB^2 - HB^2;
	// HB^2 - HC^2 = AB^2- AC^2;
	// HB + HC = BC;
	// HB - HC = (AB^2- AC^2) / BC;
	// 2*HB = (AB^2- AC^2) / BC + BC;
	// HB = ((AB^2- AC^2) / BC + BC) / 2;

	var HB = ((Math.pow(AB, 2) - Math.pow(AC, 2)) / BC + BC) / 2;
	// var HC = BC - HB;
	var coef = HB / BC;
	//var AH = Math.sqrt(Math.pow(AB, 2) - Math.pow(HB, 2));
	if (coef > 1) {
		coef = 1;
	}

	var newX = B.x + (C.x - B.x) * coef;
	var newY = B.y + (C.y - B.y) * coef;
	var newZ = B.z + (C.z - B.z) * coef;
	var pointH = new THREE.Vector3(newX, newY, newZ); // C.multiplyScalar(coef).add(B.multiplyScalar(1 - coef)); 
	return pointH;
}

function calculateDistance2D(point1, point2) {
	var dx = point1.x - point2.x;
	var dy = point1.y - point2.y;
	return Math.sqrt(dx * dx + dy * dy);
}

function calculateDistance3D(point1, point2) {
	var dx = point1.x - point2.x;
	var dy = point1.y - point2.y;
	var dz = point1.z - point2.z;
	return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

function degreesToRadians(degrees) {
	var pi = Math.PI;
	return degrees * (pi / 180);
}

// C - right angle point (vertex)
// a(C;B), b (C;A) - cathetus
// c(A;B) - hypotenuse
function getPointB(C, A, c) { // C = (1, 1), A = (1, 4), c = 5 c
	var dx = C.x - A.x; // 0
	var dz = C.z - A.z; // -3
	var b = Math.sqrt(dx * dx + dz * dz); // 3
	var a = Math.sqrt(c * c - b * b); // 4

	var ex = (A.x - C.x) / b; // 0 
	var ez = (A.z - C.z) / b; // 3 / 3 = 1

	var Bx1 = C.x + ez * a; // 1 + 4  = 5
	var Bz1 = C.z - ex * a; // 1 - 0 = 1

	var Bx2 = C.x - ez * a; // 1 - 4 = -3
	var Bz2 = C.z + ex * a; // 1 + 0 = 1

	var p1 = new THREE.Vector3(Bx1, C.y, Bz1); //{ x: Bx1, z: Bz1, y: С.y };
	var p2 = new THREE.Vector3(Bx2, C.y, Bz2); // { x: Bx2, z: Bz2, y: С.y };

	var cameraPos = viewer.camera.position; // { x: 0, y: 0, z: 0 };
	var d1 = calculateDistance3D(p1, cameraPos);
	var d2 = calculateDistance3D(p2, cameraPos);

	var B = d1 > d2 ? p2 : p1;
	// drawLine(C, B);
	return B;
}

function simplifyGeometry(geo, percent) {
	MeshoptSimplifier.ready.then(function () {
		// if (!geo.index.original) {
		// 	geo.index.original = geo.index.array;
		// }
		if (!percent) {
			percent = 5;
		}

		var positions = new Float32Array(geo.attributes.position.array);
		var indices = geo.index.array;
		var factor = percent / 100; // settings.ratio
		var target = Math.floor(indices.length * factor / 3) * 3;

		var flags = [];
		// if (settings.lockBorder)
		// 	flags.push("LockBorder");

		var threshold = 1; //Math.pow(10, -settings.errorThresholdLog10);
		var stride = (geo.attributes.position instanceof THREE.InterleavedBufferAttribute)
			? geo.attributes.position.data.stride : 3;
		var res = MeshoptSimplifier.simplify(indices, positions, stride, target, threshold, flags);

		geo.index.array = res[0];
		geo.index.count = res[0].length;
		geo.index.needsUpdate = true;
	});
}


export {
	rotatePoint2D, rotatePoint3D, getPointHonBC, calculateDistance2D,
	calculateDistance3D, getPointB, degreesToRadians, simplifyGeometry
};