import { render, staticRenderFns } from "./ToothNumberControls.vue?vue&type=template&id=6fbcf190&scoped=true&"
import script from "./ToothNumberControls.vue?vue&type=script&lang=js&"
export * from "./ToothNumberControls.vue?vue&type=script&lang=js&"
import style0 from "./ToothNumberControls.vue?vue&type=style&index=0&id=6fbcf190&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbcf190",
  null
  
)

export default component.exports