import axios from "@/api/axios";

async function sendGetPdfReportDataRequest(callBack) {
  let responseData = {
    doctor_number: 0,
    icon: null,
    iconWidth: 0,
    iconHeight: 0,
    iconDataUrl: null,
    patient_first_name: vueApp.patientFirstName,
    patient_last_name: vueApp.patientLastName,
    age: 0,
    cephDate: "" + new Date(),
  };
  try {
    let url = "/api/report/getPdfReportData";
    let requestData = {
      patientId: vueApp.patientId,
      serverTag: vueApp.prefix,
      s3Url: vueApp.s3Url,
    };
    const response = await axios.get(url, { params: requestData });
    responseData = response.data;
  } catch (e) {
    console.log("sendGetPdfReportDataRequest error", e);
  }

  if (callBack) {
    // let iconUrl = data.icon;
    // let blob = await fetch(iconUrl).then((r) => r.blob());
    // let dataUrl = await new Promise((resolve) => {
    //   let reader = new FileReader();
    //   reader.onload = () => resolve(reader.result);
    //   reader.readAsDataURL(blob);
    // });
    // data.iconDataUrl = dataUrl;
    callBack(responseData);
  }
}

export { sendGetPdfReportDataRequest };
