<template>
    <div class="annotation-container" v-if="!annotation.isDeleted" v-on:click="isSelectedChanged()">
        <input :checked="annotation.isSelected" class="annotation-selected" type="radio" name="assigned_annotation" />
        <span class="annotation-color" ref="annotationColor" :style="{ backgroundColor: annotation.color }"></span>
        <div class="annotation-name" :title="annotation.name">{{ annotation.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: "AssignAnnotation",
    props: {
        annotation: {
            type: Object,
            required: true
        },
        app: {
            type: Object,
            required: true
        },
    },
    methods: {
        isSelectedChanged: function () {
            console.log("isSelectedChanged", this.annotation.filename);
            this.annotation.isSelected = true;
            this.app.annotations.forEach(a => {
                if (a != this.annotation) {
                    a.isSelected = false;
                }
            });
        }
    }
}

</script>

<style scoped>
.annotation-container {
    color: white;
    padding: 0.2rem;
    border: solid white 0.1rem;
    display: flex;
    gap: 5px;
}

.annotation-selected {
    display: block;
}

.annotation-color {
    margin-top: 2px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.annotation-name {
    margin-top: 1px;
    white-space: nowrap;
    /* text-wrap: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    flex-grow: 1;
    max-width: calc(100% - 100px);
}
</style>