import { drawLine } from "../draw/draw";
import { enterEditCrownMode } from "./EditCrownMode.vue";
import swal from 'sweetalert';

function getCrownData(crown, callback) {
	if (vueApp.crownsPath && crown) {
		var jsonPath = `${vueApp.crownsPath}/${crown.number}.json`;
		fetch(jsonPath)
			.then((response) => {
				response.text().then((text) => {
					var crownData = null;
					try {
						crownData = JSON.parse(text);
					}
					catch (e) {
						swal(`Invalid JSON: ${jsonPath}! Error: ${e}`);
					}
					//crown.data = crownData;
					if (callback) {
						callback(crownData, crown);
					}
				});
			})
			.catch((error) => {
				console.log(`Load ${jsonPath} error: ${error}`);
			});
	}
}

function addCrownStl(crown) {
	crown.stlModelLoaded = initCrown;
	var stlModel = {
		filename: `${vueApp.crownsPath}/${crown.number}.stl`,
		color: vueApp.crownsColor
	};
	//var stlModel = { filename: "../stl/crowns/35.stl", color: vueApp.implantsColor };

	if (crown.id > 0) {
		stlModel.id = crown.id;
	}
	viewer.add_model(stlModel);
}

function initCrown(model_id) {
	if (model_id) {
		var crown = vueApp.getItemById(model_id);
		crown.isLoaded = true;
		var mesh = viewer.get_model_mesh(model_id);
		crown.uuid = mesh.uuid;

		var rotateY2 = 0;
		if (crown.number !== 44 && crown.number !== 34) {
			rotateY2 = -rotateX;
		}

		mesh.geometry.rotateY(rotateY);
		mesh.geometry.rotateX(rotateX);
		mesh.geometry.rotateY(rotateY2);

		var crownBox = convertToBox(mesh.geometry.boundingBox); //rotateBox

		var tempX = mesh.geometry.boundingBox.min.x;
		var tempY = mesh.geometry.boundingBox.min.y;
		var tempZ = mesh.geometry.boundingBox.min.z;
		mesh.geometry.center();
		var dx = mesh.geometry.boundingBox.min.x - tempX;
		var dy = mesh.geometry.boundingBox.min.y - tempY;
		var dz = mesh.geometry.boundingBox.min.z - tempZ;

		if (!crown.isFromData) {
			var x = crown.x;
			var z = crown.z;
			var y = getAverageJawTop(crown);
			if (!y) {
				y = crown.isMaxilla ? crownBox.size.y / 2 : -crownBox.size.y;
				if (crown.implantId) {
					y = crown.y;
				}
			} else {
				y += crown.isMaxilla ? crownBox.size.y / 2 : -crownBox.size.y / 2;
			}
			crown.defaultPosition = { x, y, z };

			var tooth2 = null, center1, center2;

			var tooth1 = findNearestTooth(crown.defaultPosition, crown.dentition, true, null);
			if (!tooth1)
				tooth1 = findNearestTooth(crown.defaultPosition, crown.dentition, false, null);
			updateToothData(tooth1);

			if (tooth1) {
				tooth2 = findNearestTooth(crown.defaultPosition, crown.dentition, true, tooth1);
				if (!tooth2)
					tooth2 = findNearestTooth(crown.defaultPosition, crown.dentition, false, tooth1);
				updateToothData(tooth2);
			}

			if (tooth1 && tooth2) {
				// calculate 'y'
				if (crown.isMaxilla) {
					var miny1 = Math.min(tooth1.axisPoint1.y, tooth1.axisPoint2.y);
					miny1 = Math.max(tooth1.defaultBox.min.y, miny1);
					miny1 = tooth1.defaultBox.min.y;
					var miny2 = Math.min(tooth2.axisPoint1.y, tooth2.axisPoint2.y);
					miny2 = Math.max(tooth2.defaultBox.min.y, miny2);
					miny2 = tooth2.defaultBox.min.y;
					y = miny1 + (miny2 - miny1) / 2 + crownBox.size.y / 2;
				} else {
					var maxy1 = Math.max(tooth1.axisPoint1.y, tooth1.axisPoint2.y);
					maxy1 = Math.min(maxy1, tooth1.defaultBox.max.y);
					maxy1 = tooth1.defaultBox.max.y;
					var maxy2 = Math.max(tooth2.axisPoint1.y, tooth2.axisPoint2.y);
					maxy2 = Math.min(maxy2, tooth2.defaultBox.max.y);
					maxy2 = tooth2.defaultBox.max.y;
					y = maxy1 + (maxy2 - maxy1) / 2 - crownBox.size.y / 2;
				}

				var splitPoint =
				{
					x: tooth1.splitPoint.x + (tooth2.splitPoint.x - tooth1.splitPoint.x) / 2,
					y: tooth1.splitPoint.y + (tooth2.splitPoint.y - tooth1.splitPoint.y) / 2,
					z: tooth1.splitPoint.z + (tooth2.splitPoint.z - tooth1.splitPoint.z) / 2
				};
				if (!crown.implantId) {
					x = splitPoint.x;
					z = splitPoint.z;
					var nearestPoints = findNearestPoints(tooth1.bbox, tooth2.bbox);
					if (nearestPoints && nearestPoints.length > 1) {
						var raycaster = new THREE.Raycaster();
						//var mesh1 = viewer.get_model_mesh(tooth1.id);
						//var mesh2 = viewer.get_model_mesh(tooth2.id);

						var origin1 = new THREE.Vector3(tooth1.splitPoint.x, tooth1.splitPoint.y, tooth1.splitPoint.z);
						//nearestPoints[0]; //new THREE.Vector3(x, y, z);
						var direction1 = new THREE.Vector3(tooth2.splitPoint.x, tooth2.splitPoint.y, tooth2.splitPoint.z);//tooth2.splitPoint;
						//drawPoint(direction1, 0x00FF00);

						direction1.subVectors(direction1, origin1).normalize();
						raycaster.set(origin1, direction1);

						var intersects = raycaster.intersectObject(tooth2.boxMesh, true);
						var point1 = intersects.length > 0 ? intersects[0].point : null;
						//drawPoint(point1, 0x00FF00);

						var origin2 = new THREE.Vector3(tooth2.splitPoint.x, tooth2.splitPoint.y, tooth2.splitPoint.z);
						//tooth2.splitPoint; //nearestPoints[1]; //new THREE.Vector3(x, y, z);
						var direction2 = new THREE.Vector3(tooth1.splitPoint.x, tooth1.splitPoint.y, tooth1.splitPoint.z);
						//drawPoint(direction2, 0xFF0000);

						direction2.subVectors(direction2, origin2).normalize();
						//raycaster = new THREE.Raycaster();
						raycaster.set(origin2, direction2);

						var intersects2 = raycaster.intersectObject(tooth1.boxMesh, true);
						var point2 = intersects2.length > 0 ? intersects2[0].point : null;
						//drawPoint(point2, 0xFF0000);

						if (point1 && point2) {
							x = point1.x + (point2.x - point1.x) / 2;
							z = point1.z + (point2.z - point1.z) / 2;
							//y = crown.isMaxilla ? point1.y + (point2.y - point1.y) / 2 + crownBox.size.y / 2
							//	: point1.y + (point2.y - point1.y) / 2 - crownBox.size.y / 2;

							var diameter = calculateDistance(point1, point2);
							var diameter2 = calculateDistance(nearestPoints[0], nearestPoints[1]);
							diameter = Math.min(diameter, diameter2);
							//var diameterX = diameter - tooth1.defaultBox.size.x / 2 - tooth2.defaultBox.size.x / 2;
							//var diameterZ = diameter - tooth1.defaultBox.size.z / 2 - tooth2.defaultBox.size.z / 2;
							//var scaleX = diameter / crownBox.size.x;
							//var scaleY = 1;
							//var scaleZ = diameter / crownBox.size.z;
							//mesh.geometry.scale(scaleX, scaleY, scaleZ);
							//viewer.set_scale(model_id, scaleX, scaleY, scaleZ);
						}
					}
				}
				center1 = tooth1.defaultBox.center;
				center2 = tooth2.defaultBox.center;
				tooth1.boxMesh = tooth2.boxMesh = null;
			}
			else if (tooth1) {
				y = getOpositeTop(crown);
				if (!y) {
					y = getAverageJawTop(crown); //crown.isMaxilla ? tooth1.defaultBox.min.y : tooth1.defaultBox.max.y;
				}
				// drawPoint({ x, y, z });
				y += crown.isMaxilla ? crownBox.size.y / 2 : -crownBox.size.y / 2;
				center1 = tooth1.defaultBox.center;
				center2 = { x, y, z };

				tooth1.boxMesh = null;
			} else {
				y = crown.isMaxilla ? crownBox.size.y / 2 : -crownBox.size.y / 2;
				center1 = { x: x - 10, y: y, z: z };
				center2 = { x: x + 10, y: y, z: z };
			}

			crown.defaultPosition = { x, y, z };
			//drawPoint(crown.defaultPosition, 0x0000FF);
			if (center1 && center2) {
				var mx = center1.x + (center2.x - center1.x) / 2;
				var my = center1.y + (center2.y - center1.y) / 2;
				var mz = center1.z + (center2.z - center1.z) / 2;
				var mpoint = { x: mx, y: my, z: mz };
				// drawPoint(center1, 0x0000FF);
				// drawPoint(mpoint, 0xFF0000);
				// drawLine(center1, mpoint,  0x0000FF);
				var opoint = getPoint3(mpoint, center1, 90);
				// drawLine(center1, opoint,  0x00FF00);
				crown.focusCameraPosition = opoint;
			}
		}

		mesh.updateMatrix();
		mesh.geometry.applyMatrix4(mesh.matrix);
		mesh.position.set(0, 0, 0);
		mesh.rotation.set(0, 0, 0);
		mesh.scale.set(1, 1, 1);
		mesh.geometry.center();
		mesh.updateMatrix();
		mesh.position.set(crown.defaultPosition.x, crown.defaultPosition.y, crown.defaultPosition.z);
		//mesh.material.opacity = 0.3;

		var cbox = convertToBox(mesh.geometry.boundingBox);
		//drawBox(offsetBox(cbox, mesh.position.x, mesh.position.y, mesh.position.z));
		var apex = { x: cbox.center.x, y: crown.isMaxilla ? cbox.min.y : cbox.max.y, z: cbox.center.z };
		var apexMesh = drawPoint(apex, 0x0000FF, true, 0.01);
		mesh.add(apexMesh);
		mesh.apexMesh = apexMesh;

		var root = { x: cbox.center.x, y: crown.isMaxilla ? cbox.max.y : cbox.min.y, z: cbox.center.z };
		var rootMesh = drawPoint(root, 0xFF0000, true, 0.01);
		mesh.add(rootMesh);
		mesh.rootMesh = rootMesh;
		//console.log(crown);

		if (!crown.isFromData) {
			crown.angle = getCrownAngle(crown);
			if (crown.angle) {
				var axis = new THREE.Vector3();
				axis.subVectors(crown.opoint, mesh.position).normalize();
				mesh.rotateOnWorldAxis(axis, crown.angle);
			}

			crown.defaultRotation = mesh.rotation.clone();
			crown.defaultBuccalScale = crown.currentBuccalScale =
				crown.defaultMessialScale = crown.currentMessialScale =
				crown.defaultHeightScale = crown.currentHeightScale = 1;

			//viewer.set_geo_minmax(model);

			dx += x;
			dy += y;
			dz += z;
			crownBox = offsetBox(crownBox, dx, dy, dz);
			crown.defaultBox = crownBox;

			getCrownData(crown, function (crownData) {
				crown.data = crownData;

				if (crown.data) {
					if (!crown.data.Apical) {
						crown.data.Apical = crown.data.Apex;
					}
					if (!crown.data.Palatal) {
						crown.data.Palatal = crown.data.Lingual;
					}
					if (!crown.data.Palatal) {
						crown.data.Palatal = crown.data.Linugal;
					}
					if (!crown.data.Occlusal) {
						crown.data.Occlusal = crown.data.OCclusal;
					}
					if (!crown.data.Distal) {
						crown.data.Distal = crown.data.Disal;
					}

					crown.data.Distal = rotatePoint(crown.data.Distal, dx, dy, dz, rotateY2);
					crown.data.Mesial = rotatePoint(crown.data.Mesial, dx, dy, dz, rotateY2);
					crown.data.Apical = rotatePoint(crown.data.Apical, dx, dy, dz, rotateY2);
					crown.data.Occlusal = rotatePoint(crown.data.Occlusal, dx, dy, dz, rotateY2);
					crown.data.Buccal = rotatePoint(crown.data.Buccal, dx, dy, dz, rotateY2);
					crown.data.Palatal = rotatePoint(crown.data.Palatal, dx, dy, dz, rotateY2);
					if (crown.data.Buccal) {
						var point1 = crown.data.Buccal;
						var point2 = { x: crown.defaultBox.center.x, y: point1.y, z: crown.defaultBox.center.z };
						var point3 = { x: point2.x, y: point2.y, z: point1.z };
						//drawPoint(crown.data.Buccal, 0x0000FF);
						//drawPoint(crown.data.Distal, 0x00FF00);
						//drawPoint(crown.data.Mesial, 0xFFFF00);
						//drawPoint(crown.data.Apical, 0xFF0000);
						//drawPoint(crown.data.Occlusal, 0x00FFFF);
						//drawPoint(crown.data.Palatal, 0xFF00FF);	
						//drawLine(point2, point1, 0xFFFF00);
						//drawLine(point1, point3);
						//drawLine(point2, point3);

						var hypotenuse = calculateDistance(point2, point1);
						var cathetus = calculateDistance(point2, point3);
						var cosinus = cathetus / hypotenuse;
						crown.rotateAngle = Math.acos(cosinus);
						mesh.rotateY(-crown.rotateAngle);
						mesh.updateMatrix();
						mesh.geometry.applyMatrix4(mesh.matrix);
						mesh.position.set(0, 0, 0);
						mesh.rotation.set(0, 0, 0);
						mesh.scale.set(1, 1, 1);
						mesh.geometry.center();
						mesh.updateMatrix();
						mesh.rotateY(crown.rotateAngle);
						mesh.position.set(crown.defaultPosition.x, crown.defaultPosition.y, crown.defaultPosition.z);
						crown.rotateUpdated = true;
					}
				}
				enterEditCrownMode(crown);
				vueApp.saveData();
			});
		}
		else {
			if (crown.rotateAngle && !crown.rotateUpdated) {
				mesh.rotateY(-crown.rotateAngle);
				mesh.updateMatrix();
				mesh.geometry.applyMatrix4(mesh.matrix);
				mesh.position.set(0, 0, 0);
				mesh.rotation.set(0, 0, 0);
				mesh.scale.set(1, 1, 1);
				mesh.geometry.center();
				mesh.updateMatrix();
				mesh.rotateY(crown.rotateAngle);
				mesh.position.set(crown.defaultPosition.x, crown.defaultPosition.y, crown.defaultPosition.z);
				crown.rotateUpdated = true;
			}

			mesh.geometry.scale(crown.defaultMessialScale, crown.defaultHeightScale, crown.defaultBuccalScale);

			mesh.rotation.x = crown.defaultRotation._x;
			mesh.rotation.y += crown.defaultRotation._y;
			mesh.rotation.z = crown.defaultRotation._z;
			crown.defaultRotation = mesh.rotation.clone();

			// vueApp.switchToDefaultView();
		}
	}
}

function getCrownAngle(crown) {
	var angle = 0;
	if (crown) {
		var lastMolars = vueApp.getLastMolars(crown.isMaxilla);
		var firstIncisor = vueApp.getFirstIncisor(crown.number);
		if (lastMolars && lastMolars.length === 2 && firstIncisor) {
			//var mesh1 = viewer.get_model_mesh(lastMolars[0].id);
			//var mesh2 = viewer.get_model_mesh(lastMolars[1].id);
			//var mesh3 = viewer.get_model_mesh(firstIncisor.id);
			var bbox1 = lastMolars[0].defaultBox;
			var bbox2 = lastMolars[1].defaultBox;
			var bbox3 = firstIncisor.defaultBox;
			var point1 = crown.isMaxilla ? new THREE.Vector3(bbox1.center.x, bbox1.min.y, bbox1.center.z)
				: new THREE.Vector3(bbox1.center.x, bbox1.max.y, bbox1.center.z);
			var point2 = crown.isMaxilla ? new THREE.Vector3(bbox2.center.x, bbox2.min.y, bbox2.center.z)
				: new THREE.Vector3(bbox2.center.x, bbox2.max.y, bbox2.center.z);
			var point3 = crown.isMaxilla ? new THREE.Vector3(bbox3.center.x, bbox3.min.y, bbox3.center.z)
				: new THREE.Vector3(bbox3.center.x, bbox3.max.y, bbox3.center.z);
			//drawLine(point1, point2);
			//drawLine(point2, point3);
			//drawLine(point1, point3);

			var firstIncisorY = point3.y;
			var point21 = new THREE.Vector3(bbox1.center.x, firstIncisorY, bbox1.center.z);
			var point22 = new THREE.Vector3(bbox2.center.x, firstIncisorY, bbox2.center.z);
			//drawLine(point21, point22,0x00FF00);
			//drawLine(point22, point3, 0x00FF00);
			//drawLine(point21, point3, 0x00FF00);

			var side1 = crown.number < 20 || crown.number > 40;

			var hypotenuse1 = point3.distanceTo(point1);
			var cathetus1 = point3.distanceTo(point21);
			var cosinus1 = cathetus1 / hypotenuse1;
			var angle1 = Math.acos(cosinus1);

			var hypotenuse2 = point3.distanceTo(point2);
			var cathetus2 = point3.distanceTo(point22);
			var cosinus2 = cathetus2 / hypotenuse2;
			var angle2 = Math.acos(cosinus2);

			angle = Math.abs(angle2) > Math.abs(angle1) ? angle2 : angle1;

			//var v1 = side1 ? point1 : point2;
			var v2 = side1 ? point21 : point22;
			//drawPoint(v1, 0x0000FF);
			//drawPoint(v2, 0x0000FF);

			var rp1 = new THREE.Vector3(point3.x, crown.defaultPosition.y, point3.z);
			//drawPoint(rp1);
			var rp2 = crown.number % 10 > 3 ? new THREE.Vector3(v2.x, crown.defaultPosition.y, v2.z) : new THREE.Vector3(point3.x, crown.defaultPosition.y, v2.z);
			//drawPoint(rp2, 0x0000FF);
			//drawLine(rp1, rp2, 0x0000FF);

			var mx = rp1.x + (rp2.x - rp1.x) / 2;
			var mz = rp1.z + (rp2.z - rp1.z) / 2;
			var difx = crown.defaultPosition.x - mx;
			var difz = crown.defaultPosition.z - mz;

			rp1 = new THREE.Vector3(point3.x + difx, crown.defaultPosition.y, point3.z + difz);
			//drawPoint(rp1);
			//drawLine(rp1, crown.defaultPosition);
			var opoint = getPoint3(crown.defaultPosition, rp1, 90);
			//drawLine(opoint, crown.defaultPosition);
			crown.opoint = opoint;
			if (opoint.x < crown.defaultPosition.x)//(side1)
				angle = -angle;
			//else
			//	angle *= 2;
			//console.log(`hypotenuse: ${hypotenuse}, cathetus: ${cathetus}, cosinus: ${cosinus}, angle: ${angle}`);

			//var midy = getAverageJawTop(crown);
			//point1 = new THREE.Vector3(bbox1.center.x, midy, bbox1.center.z);
			//point2 = new THREE.Vector3(bbox2.center.x, midy, bbox2.center.z);
			//point3 = new THREE.Vector3(bbox3.center.x, midy, bbox3.center.z);
			//drawLine(point1, point2, 0x0000FF);
			//drawLine(point2, point3, 0x0000FF);
			//drawLine(point1, point3, 0x0000FF);
		}
	}
	return angle;
}

function getOpositeTop(crown) {
	var oppositeTop = null;
	if (crown && crown.number) {
		var oppositeNumber = getOpositeNumber(crown.number);
		var oppositeTooth = vueApp.getToothByNumber(oppositeNumber);
		if (oppositeTooth) {
			oppositeTop = oppositeTooth.isMaxilla ? oppositeTooth.defaultBox.min.y : oppositeTooth.defaultBox.max.y;
		}
	}
	return oppositeTop;
}

function getOpositeNumber(crownNumber) {
	var oppositeNumber = null;
	if (crownNumber) {
		var crownRest = crownNumber % 10;
		if (crownNumber < 20)
			oppositeNumber = 40;
		else if (crownNumber < 30)
			oppositeNumber = 30;
		else if (crownNumber < 40)
			oppositeNumber = 20;
		else
			oppositeNumber = 10;
		oppositeNumber += crownRest;
	}
	return oppositeNumber;
}

function getAverageJawTop(crown) {
	var midy = null;
	if (crown) {
		var miny = null;
		var maxy = null;
		var teeth = vueApp.allTeeth.filter(tooth => tooth.isMaxilla === crown.isMaxilla);
		teeth.forEach(tooth => {
			if (crown.isMaxilla) {
				if (!miny)
					miny = maxy = tooth.defaultBox.min.y;
				else {
					if (tooth.defaultBox.min.y < miny)
						miny = tooth.defaultBox.min.y;
					if (tooth.defaultBox.min.y > maxy)
						maxy = tooth.defaultBox.min.y;
				}

			} else {
				if (!miny)
					miny = maxy = tooth.defaultBox.max.y;
				else {
					if (tooth.defaultBox.max.y < miny)
						miny = tooth.defaultBox.max.y;
					if (tooth.defaultBox.max.y > maxy)
						maxy = tooth.defaultBox.max.y;
				}
			}
		});
		if (miny && maxy) {
			midy = miny + (maxy - miny) / 2;
		}
	}

	return midy;
}

function getAverageJawTop2(crown) {
	var midy = null;
	if (crown) {
		var teeth = vueApp.allTeeth.filter(tooth => tooth.isMaxilla === crown.isMaxilla);
		var sum = 0;
		teeth.forEach(tooth => {
			if (crown.isMaxilla) {
				sum += tooth.defaultBox.min.y;
			} else {
				sum += tooth.defaultBox.max.y;
			}
		});
		if (sum)
			midy = sum / teeth.length;
	}

	return midy;
}

export { addCrownStl, getCrownData };