<template>
	<li class="nav-item dropdown active">
		<a class="nav-link implant-disabled"
			tabindex="0" v-on:click="hideSecondaryMenu()"
			data-toggle="dropdown"
			data-submenu
			aria-expanded="false">Implants</a>

		<div class="dropdown-menu">
			<div class="dropdown dropright dropdown-submenu border-bottom-item implant-hidden">
				<button class="dropdown-item" type="button" v-on:click="implantsMenuClick($event)">New Implant</button>
				<div class="dropdown-menu secondary-menu">
					<button v-for="crown in crowns"
						:key="crown.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="addImplant(crown.id)">
						{{ crown.name }}
					</button>
					<button class="dropdown-item border-bottom-item"
						type="button" v-on:click="addImplant()">
						Custom
					</button>
				</div>
			</div>

			<div class="dropdown dropright dropdown-submenu border-bottom-item implant-hidden">
				<button class="dropdown-item" type="button" v-on:click="implantsMenuClick($event)">New Crown</button>
				<div class="dropdown-menu secondary-menu">
					<button v-for="implant in implants"
						:key="implant.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="addCrown(implant.id)">
						{{ implant.name }}
					</button>
					<button class="dropdown-item border-bottom-item"
						type="button" v-on:click="addCrown()">
						Custom
					</button>
				</div>
			</div>

			<div class="dropdown dropright dropdown-submenu border-bottom-item implant-hidden">
				<button class="dropdown-item" type="button" v-on:click="implantsMenuClick($event)">Edit</button>
				<div class="dropdown-menu secondary-menu">
					<button v-for="implant in implants"
						:key="implant.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="editImplant(implant.id)">
						{{ implant.name }}
					</button>
					<button v-for="crown in crowns"
						:key="crown.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="editCrown(crown.id)">
						{{ crown.name }}
					</button>
					<button v-if="implants.length == 0 && crowns.length == 0"
						class="dropdown-item border-bottom-item"
						type="button">
						No Items
					</button>
				</div>
			</div>

			<div class="dropdown dropright dropdown-submenu border-bottom-item implant-hidden">
				<button class="dropdown-item" type="button" v-on:click="implantsMenuClick($event)">Remove</button>
				<div class="dropdown-menu secondary-menu">
					<button v-for="(implant, index) in implants"
						:key="implant.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="removeImplant(index)">
						{{ implant.name }}
					</button>
					<button v-for="(crown, index) in crowns"
						:key="crown.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="removeCrown(index)">
						{{ crown.name }}
					</button>
					<button v-if="implants.length == 0 && crowns.length == 0"
						class="dropdown-item border-bottom-item"
						type="button">
						No Items
					</button>
				</div>
			</div>
		</div>
	</li>
</template>

<script>
import swal from "sweetalert";
import "slick-carousel";
import {
	enterEditCrownMode,
	exitEditCrownMode
} from "../crowns/EditCrownMode.vue";
import { initCrownMap } from "../crowns/AddCrownMap.vue";
import { saveViewerData, loadViewerData } from "../saveLoadData";

export default {
	name: "ImplantsMenu",
	props: ["implants", "crowns"],
	methods: {
		addImplant: function(crownId) {
			gtag('event', `Implants->New Implant->${crownId}`);
			vueApp.leaveModes();

			vueApp.newImplantCrownId = crownId;
			$("#implantsStep1").modal();
		},
		addCrown: function(implantId) {
			gtag('event', `Implants->New Crown->${implantId}`);
			vueApp.leaveModes();

			vueApp.crownImplantId = implantId;
			initCrownMap();
			$("#addCrownMap").modal();
		},
		editImplant: function(id) {
			gtag('event', `Implants->Edit->${id}`);
			if (vueApp.activeMode && vueApp.activeMode.leaveMode) {
				vueApp.activeMode.leaveMode(true);
			}
			vueApp.switchToDefaultView();
			editedImplant = vueApp.getItemById(id);
			enterEditCrownMode(editedImplant);
		},
		removeImplant: function(index) {
			gtag('event', `Implants->Remove->${index}`);
			swal({
				text: "Are you sure you want to permanently delete this implant?",
				buttons: {
					yes: { text: "Yes", value: true },
					no: { text: "No", value: false }
				}
			}).then(willDelete => {
				if (willDelete) {
					if (editedImplant && vueApp.implants[index].id === editedImplant.id) {
						editedImplant = null;
						vueApp.leaveModes();
					}
					viewer.remove_model(vueApp.implants[index].id);
					vueApp.implants.splice(index, 1);
					vueApp.savedData.implants = JSON.parse(
						JSON.stringify(vueApp.implants)
					);
					saveViewerData();
				}
			});
		},
		editCrown: function(id) {
			if (vueApp.activeMode && vueApp.activeMode.leaveMode) {
				vueApp.activeMode.leaveMode(true);
			}
			var crown = vueApp.getItemById(id);
			enterEditCrownMode(crown);
		},
		removeCrown: function(index) {
			swal({
				text: "Are you sure you want to permanently delete this crown?",
				buttons: {
					yes: { text: "Yes", value: true },
					no: { text: "No", value: false }
				}
			}).then(willDelete => {
				if (willDelete) {
					viewer.remove_model(vueApp.crowns[index].id);
					vueApp.crowns.splice(index, 1);
					vueApp.savedData.crowns = JSON.parse(JSON.stringify(vueApp.crowns));
					saveViewerData();
				}
			});
		},
		implantsMenuClick: function(event) {
			if (vueApp.isHideMode || vueApp.isColorMode || vueApp.isOpacityMode) {
				vueApp.saveData();
			}
			gtag('event', `Implants->${event.srcElement.innerText}`);
		},
		hideSecondaryMenu: function() {
			$(".secondary-menu").removeClass("show");
		}
	}
};
</script>

<style scoped>
</style>