<template>
	<div id="toothNumberControls" class="tooth-number-mode-item">
		<div id="toothNumberHint">Click tooth to change number</div>
	</div>
</template>

<script>

export default {
	name: "ToothNumberControls",
	props: [],
	components: {},
	methods: {
		
	}
};

</script>

<style scoped>

#toothNumberControls {
	display: none;
	left: calc(50% - 180px);
	top: 30px;
	width: 350px;
	position: absolute;
	z-index: 9;
}

#toothNumberHint {
	width: 350px;
	text-align: center;
	color: #ffffff;
}

</style>