import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { drawPointBox, drawRectangle } from "../draw/draw";
import { drawOutlines } from "./slice";
import { processDicomZipData } from "../vtk/Dicom3dManager";
import {
  ToothNumberMode,
  initToothNumberControls,
  drawTeethNumbers,
  removeTeethNumbers,
} from "../tooth/number/ToothNumberMode";
import { showToothNumberDialog } from "../tooth/number/ToothNumberDialog.vue";

import {
  drawAnnotations,
  hexToCanvasColor,
  getAnnotationData,
  getAnnotationGeometry,
} from "./annotation";
import { sendDicomSegmentation, readDicomSegmentation } from "@/api/dicomApi";
import DicomViewer from "./DicomViewer.vue";
import SimpleDicomViewer from "./SimpleDicomViewer.vue";
import DicomAnnotation from "./DicomAnnotation.vue";
import AssignAnnotation from "./AssignAnnotation.vue";
import { mutationTypes } from "@/store/modules/dicom";
import store from "@/store";
import {
  focusTooth,
  unfocusTooth,
} from "@/components/tooth/focus/ToothFocusMode";
import { focusAuxilaryModel } from "./AuxiliaryManager";
import { enterAnnotationMode, leaveAnnotationMode } from "./AnnotationMode";

let dwv = require("dwv");

let _initialized = false;
let _appArr = [new dwv.App(), new dwv.App(), new dwv.App()];
let _orientations = ["axial", "sagittal", "coronal"];
let _colors = ["red", "blue", "green"];
let _vFroms = [
  new THREE.Vector3(0, 1, 0),
  new THREE.Vector3(1, 0, 0),
  new THREE.Vector3(0, 0, 1),
];
let _dicomBox;
let _originBox = null;
let _menuToothNode = null;
let _menuAddNode = null;

function initDwvCommons() {
  _menuToothNode = document.getElementById("annotation-tooth-menu");
  _menuAddNode = document.getElementById("annotation-add-menu");
  // dwv.luts = {
  //     plain: dwv.luts.plain,
  //     invPlain: dwv.luts.invPlain,
  //     rainbow: dwv.luts.rainbow,
  //     hot: dwv.luts.hot,
  //     hot_iron: dwv.luts.hot_iron,
  //     pet: dwv.luts.pet,
  //     hot_metal_blue: dwv.luts.hot_metal_blue,
  //     pet_20step: dwv.luts.pet_20step
  // };
  dwv.defaultpresets = {};
  dwv.defaultpresets.CT = {
    mediastinum: { center: 40, width: 400 },
    lung: { center: -500, width: 1500 },
    bone: { center: 500, width: 2000 },
    brain: { center: 40, width: 80 },
    head: { center: 90, width: 350 },
  };

  // dwv.logger.level = dwv.logger.levels.INFO;
  // dwv.defaultPresets.PT = {
  //     'suv5-10': { center: 5, width: 10 },
  //     'suv6-8': { center: 6, width: 8 }
  // };
  dwv.decoderScripts.jpeg2000 = "js/decoders/pdfjs/decode-jpeg2000.js";
  dwv.decoderScripts["jpeg-lossless"] =
    "js/decoders/rii-mango/decode-jpegloss.js";
  dwv.decoderScripts["jpeg-baseline"] =
    "js/decoders/pdfjs/decode-jpegbaseline.js";
  dwv.decoderScripts.rle = "js/decoders/dwv/decode-rle.js";
}

function loadDicoms(callbackAfterLoad) {
  if (!_initialized) {
    window.onbeforeunload = function () {
      let hasChanges = false;

      _appArr.forEach((app) => {
        if (app.annotations) {
          let changedAnnotations = app.annotations.filter((a) => a.wasChanged);
          hasChanges =
            (app.deletedAnnotations && app.deletedAnnotations.length) ||
            changedAnnotations.length;
        }
      });

      if (hasChanges) {
        // Recommended
        event.preventDefault();
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      }
    };

    _initialized = true;
    console.log("loadDicoms start");
    initDwvCommons();

    _appArr.forEach((app, index) => {
      app.index = index;
      app.viewIndex = app.index !== 0 ? app.index - 1 : 2;
      app.color = _colors[index];
      app.vFrom = _vFroms[index];
      app.orientation = _orientations[index];
      app.divId = `${app.orientation}_viewer`;
      app.toolName = "dragDrop";
      app.editMode = "bezier";
      app.editSubMode = "draw";
      app.opacity = 0.5;
      app.sliceOpacity = 0.25;
      app.annotationColor = "#CC5500";
      app.segmentationType = "fillTeeth";
      app.segmentations = ["mandTeeth", "maxTeeth"];
      app.showSegmentation = true;
      app.showIntersection = true;
      app.show3DLayer = true;
      app.isLoading = false;
      app.isAnnotating = false;
      app.isEditingAnnotation = false;
      app.editedAnnotation = null;
      app.focusedAnnotation = null;
      app.selectedAnnotation = null;
      app.show3DFiller = true;
      app.annotationFillSegmentations = true;
      app.annotationOpacity = 0.5;
      app.text1 = app.text2 = app.text3 = "";
      app.is3D = false;
      app.isDicom3DView = false;
      app.showTeethNumbers = false;
      app.isSaving = false;

      const viewerInstance = new Vue({
        ...DicomViewer,
        propsData: {
          app: app,
        },
      });
      const vueContainer = document.createElement("div");
      document.getElementById("views_cont").appendChild(vueContainer);
      viewerInstance.$mount(vueContainer);
    });

    let loadedCounter = 0;
    _appArr[0].addEventListener("loadend", (event) => {
      viewer.controls.keysEnabled = false;
      window.addEventListener("keydown", (event) => onKeyDown(event));
      console.log(`dicom loadend`, event);
      loadedCounter++;

      const app0 = _appArr[0];
      const image0 = app0.getImage(0);
      for (let index = 1; index < _appArr.length; index++) {
        const app = _appArr[index];

        app.addEventListener("loadend", (event) => {
          loadedCounter++;
          if (loadedCounter == _appArr.length) {
            $id("loader").style.display = "none";
            vueApp.isDicomLoaded = true;
            if (callbackAfterLoad) {
              callbackAfterLoad();
            }
          }
        });

        app.addNewImage(image0.clone());
      }
    });

    vueApp.$on("colorChanged", (target) => {
      console.log(`colorChanged handler`, target);
      _appArr.forEach((app) => {
        setSliceIndex(app, app.current - 1);
      });
    });
    vueApp.$on("visibilityChanged", (target) => {
      console.log(`visibilityChanged handler`, target);
      _appArr.forEach((app) => {
        setSliceIndex(app, app.current - 1);
      });
    });
    vueApp.$on("toothAdded", (tooth) => {
      console.log(`toothAdded handler`, tooth);
      _appArr.forEach((rootApp) => {
        if (rootApp.annotations) {
          const annotation = createAnnotationFromTooth(tooth, rootApp);
          rootApp.annotations.push(annotation);
          rootApp.allAnnotations.push(annotation);
          setSliceIndex(rootApp, rootApp.current - 1);

          const apanel = document.getElementById(rootApp.apanelId);
          const annotationInstance = new Vue({
            ...DicomAnnotation,
            propsData: {
              annotation: annotation,
              app: rootApp,
              dicomBox: _dicomBox,
            },
          });
          const vueContainer = document.createElement("div");
          apanel.appendChild(vueContainer);
          annotationInstance.$mount(vueContainer);

          rootApp.childApps.forEach((app) => {
            let item = structuredClone(annotation);
            item.appIndex = app.index;
            item.parentAnnotation = annotation;
            if (!annotation.childAnnotations) {
              annotation.childAnnotations = [];
            }
            annotation.childAnnotations.push(item);
            app.annotations.push(item);

            setSliceIndex(app, app.current - 1);
          });
        } else {
          setSliceIndex(rootApp, rootApp.current - 1);
        }
      });
    });
    vueApp.$on("toothRemoved", (removedTeeth) => {
      console.log(`toothRemoved handler`, removedTeeth);
      _appArr.forEach((rootApp) => {
        if (rootApp.annotations) {
          removedTeeth.forEach((tooth) => {
            let annotation = rootApp.annotations.find(
              (x) => x.filename == tooth.filename
            );
            if (annotation) {
              deleteAnnotation(rootApp, annotation);

              annotation.childAnnotations.forEach((annotation) => {
                let app = rootApp.childApps.find(
                  (c) => c.index == annotation.appIndex
                );
                deleteAnnotation(app, annotation);
              });
            }
          });

          setSliceIndex(rootApp, rootApp.current - 1);
          rootApp.childApps.forEach((app) => {
            setSliceIndex(app, app.current - 1);
          });
        } else {
          setSliceIndex(rootApp, rootApp.current - 1);
        }
      });
    });

    vueApp.$on("positionChanged", (target) => {
      console.log(`positionChanged`, target);
      if (target && target.isTooth) {
        target.needUpdateDicom = true;
      }
      _appArr.forEach((app) => {
        setSliceIndex(app, app.current - 1);
      });
    });

    vueApp.$on("isDicom3DViewChanged", (isDicom3DView) => {
      console.log(`isDicom3DViewChanged`, isDicom3DView);

      _appArr.forEach((app) => {
        app.isDicom3DView = isDicom3DView;
      });
    });

    let zipPath = `${vueApp.dicomUrl}/dicom.zip`; // "demo/dicom.zip"; // `${vueApp.dicomUrl}/dicom.zip`;
    fetch(zipPath, { method: "HEAD" })
      .then((response) => {
        if (response.status === 200) {
          loadZip(zipPath);
        } else {
          showLoaderPopup(`Prepare DICOM zip`, `Please wait...`);

          fetch(
            `${vueApp.viewerServerUrl}/api/dicom/getDicomZipUrl?s3Path=${vueApp.dicomUrl}`,
            {
              cache: "no-cache",
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              loadZip(json.zipUrl);
            })
            .catch((error) => {
              $id("loader").style.display = "none";
              console.log(`getDicomZipUrl error: ${error}`);
            });
        }
      })
      .catch((error) => {
        console.log("Error fetch HEAD dicom.zip: ", error);
      });
  }
}

function loadZip(zipUrl) {
  showLoaderPopup(`0 %`, `Download DICOM files...`);

  var firstProgress = true;
  JSZipUtils.getBinaryContent(zipUrl, {
    progress: function (event) {
      if (firstProgress) {
        $("#loaderText").css("margin-left", "10px");
        firstProgress = false;
      }
      var percent = Math.ceil(event.percent * 0.99);
      $("#loaderText").html(`${percent} %`);
    },
    callback: function (err, zipData) {
      if (err) {
        console.log(err);
      } else {
        try {
          console.log("readDicomSegmentation start");
          readDicomSegmentation(zipUrl);
          console.log("readDicomSegmentation success");
        } catch (e) {
          console.log("readDicomSegmentation error", e);
        }

        console.log(`dicom zip downloaded, ${zipUrl}`);
        $("#loaderText").html(`Loading...`);
        $("#loaderText2").html(` Just a few more seconds `);

        _appArr[0].loadImageObject([
          { name: "dicom", filename: "dicom.zip", data: zipData },
        ]);

        processDicomZipData(zipData);
      }
    },
  });
}

function showLoaderPopup(text1, text2) {
  $id("loader").style.display = "block";
  $id("loader").style.background = "gray";
  $id("loader").style.opacity = "0.7";
  $("#loaderText").html(text1);
  $("#loaderText2").html(text2);
  $("#loaderText3").html(``);
}

function renderDicomViews(indexes) {
  if (indexes) {
    _appArr.forEach((app) => {
      app.visible = indexes.includes(app.index);
      refreshDicomView(app);
    });
  }

  viewer.controls.keysEnabled = !indexes || !indexes.length;
}

function refreshDicomView(app) {
  if (app.frameMesh) {
    viewer.scene.remove(app.frameMesh);
  }

  if (app.visible) {
    // if (!app.rendered) {
    let currentConfigs = app.getDataViewConfigs();
    app.setDataViewConfigs(currentConfigs);
    for (let i = 0; i < app.getNumberOfLoadedData(); ++i) {
      app.render(i);
    }
    setupCanvasEvents(app);
    app.rendered = true;
    // }
    app.onResize();
    app.resetZoom();
    updateCanvasContext(app);
    // setSliceIndex(app, app.current - 1);
    update3DFrame(app);
  }
}

function setupCanvasEvents(app) {
  let canvasDiv = $(`#${app.divId}-layer-0`)[0];
  if (canvasDiv) {
    const names = [
      // "mousedown",
      // "mousemove",
      // "mouseup",
      // "mouseout",
      // "dblclick",
      // "touchstart",
      // "touchmove",
      // "touchend",
      "wheel",
    ];
    canvasDiv.addEventListener(
      "wheel",
      function (event) {
        if (!event.shiftKey) {
          event.stopImmediatePropagation();
          let newIndex = event.deltaY < 0 ? app.current + 1 : app.current - 1;
          if (event.deltaY != 0 && newIndex >= 1 && newIndex <= app.max)
            setSliceIndex(app, newIndex - 1);
        }
      },
      true
    );

    canvasDiv.addEventListener("mouseup", function (event) {
      if (app.offsetchanged) {
        app.offsetchanged = false;
        // setTimeout(function () {
        updateCanvasContext(app);
        // }, 300);
      }
    });
  }
}

function onKeyDown(event) {
  let app = _appArr.find((a) => a.visible);
  if (app) {
    if (event.code == "ArrowUp" || event.code == "ArrowDown") {
      event.stopImmediatePropagation();
      event.preventDefault();
      let newIndex =
        event.code == "ArrowUp" ? app.current + 1 : app.current - 1;
      if (newIndex >= 1 && newIndex <= app.max)
        setSliceIndex(app, newIndex - 1);
      // console.log("onKeyDown", event);
    } else if (event.code == "Escape") {
      _menuToothNode.style.display = _menuAddNode.style.display = "none";
    }
  }
}

function setSliceIndex(app, sliceIndex) {
  // console.log("setSliceIndex", sliceIndex, app);
  if (app && sliceIndex >= 0) {
    const layerGroup = app.getLayerGroupByDivId(app.divId);
    if (layerGroup) {
      const activeLayer = layerGroup.getActiveViewLayer();
      const viewController = activeLayer.getViewController();
      const currentIndex = viewController.getCurrentIndex();
      const values = currentIndex.getValues();
      values[app.viewIndex] = sliceIndex > 0 ? 0 : 1;
      viewController.setCurrentIndex(new dwv.Index(values), true);
      values[app.viewIndex] = sliceIndex; //app.current - 1;
      viewController.setCurrentIndex(new dwv.Index(values));
    }
  }
}

function updateCanvasContext(app) {
  if (app.baseSize) {
    app.offsetchanged = false;
    if (app.isAnnotating || (app.parentApp && app.parentApp.isAnnotating)) {
      // if (app.parentApp) {
      //   console.log("child updateCanvasContext", app.index);
      // }
      drawAnnotations(app, _dicomBox);
    } else if (app.segmentations && app.showSegmentation) {
      drawOutlines(app, _dicomBox); //, app.current, false, false);
    }
  }
}

function update3DFrame(app) {
  if (app.frameMesh) {
    viewer.scene.remove(app.frameMesh);
  }
  if (
    _dicomBox &&
    !app.isAnnotating &&
    app.visible &&
    app.show3DLayer &&
    (app.show3DSlice || app.show3DBorder || app.show3DFiller)
  ) {
    let offset = (app.current - 1) / (app.max - 1);
    if (app.index == 2) {
      offset = (app.max - app.current) / (app.max - 1);
    }
    let texture;
    if (app.baseSize && app.show3DSlice) {
      let width = app.baseSize.x;
      let height = app.baseSize.y;
      const layerGroup = app.getLayerGroupByDivId(app.divId);
      const layer = layerGroup.getActiveViewLayer();
      const imageData = layer.getImageData(); //ctx.getImageData(0, 0, width, height);
      texture = new THREE.DataTexture(imageData, width, height);
      texture.repeat.x = app.index === 0 ? 1 : app.index === 1 ? -1 : 1;
      texture.repeat.y = app.index === 0 ? -1 : app.index === 1 ? -1 : 1;
      // if (app.index != 1) {
      //     texture.flipY = true;
      //     texture.repeat.set(1, 1);
      // } else {
      //     texture.repeat.set(-1, 1);
      // }
      texture.needsUpdate = true;
    }

    app.frameMesh = drawRectangle(
      get3dSlicePoints(app.index, offset),
      app.color,
      app.vFrom,
      app.show3DBorder,
      app.show3DFiller,
      texture,
      app.sliceOpacity
    );
    // app.setWindowLevelPreset("bone");
    // app.setColourMap("rainbow");
  }
}

function get3dSlicePoints(index, offset) {
  if (index === 0) {
    let y = _dicomBox.min.y;
    if (offset) {
      y += _dicomBox.size.y * offset;
    }
    return [
      new THREE.Vector3(_dicomBox.min.x, y, _dicomBox.min.z),
      new THREE.Vector3(_dicomBox.max.x, y, _dicomBox.min.z),
      new THREE.Vector3(_dicomBox.max.x, y, _dicomBox.max.z),
      new THREE.Vector3(_dicomBox.min.x, y, _dicomBox.max.z),
    ];
  }

  if (index === 1) {
    let x = _dicomBox.min.x;
    if (offset) {
      x += _dicomBox.size.x * offset;
    }

    return [
      new THREE.Vector3(x, _dicomBox.min.y, _dicomBox.min.z),
      new THREE.Vector3(x, _dicomBox.max.y, _dicomBox.min.z),
      new THREE.Vector3(x, _dicomBox.max.y, _dicomBox.max.z),
      new THREE.Vector3(x, _dicomBox.min.y, _dicomBox.max.z),
    ];
  }

  if (index === 2) {
    let z = _dicomBox.min.z;
    if (offset) {
      z += _dicomBox.size.z * offset;
    }

    return [
      new THREE.Vector3(_dicomBox.min.x, _dicomBox.min.y, z),
      new THREE.Vector3(_dicomBox.max.x, _dicomBox.min.y, z),
      new THREE.Vector3(_dicomBox.max.x, _dicomBox.max.y, z),
      new THREE.Vector3(_dicomBox.min.x, _dicomBox.max.y, z),
    ];
  }
}

function updateOriginBox(app, viewController) {
  console.log("updateOriginBox", app.index, app.worldSize);
  let p1 = viewController.getPositionFromPlanePoint(0, 0);
  let p2 = viewController.getPositionFromPlanePoint(
    app.baseSize.x,
    app.baseSize.y
  );
  let dz = Math.abs(p2.get(2) - p1.get(2));
  console.log(`app ${app.index} points`, p1, p2, dz);
  if (app.index == 0) {
    let origins = app.getImage(0).getGeometry().getOrigins();
    let firstOrigin = origins[0];
    let lastOrigin = origins[origins.length - 1];
    console.log(`app ${app.index} origins`, firstOrigin, lastOrigin);

    // let minX = -p2.get(0);
    // let maxX = -p1.get(0);
    // let minY = -p2.get(1);
    // let maxY = -p1.get(1);
    let minX = -(firstOrigin.getX() + app.worldSize.x);
    let maxX = -lastOrigin.getX();

    let dy = Math.abs(firstOrigin.getY() - lastOrigin.getY());
    console.log(dy);
    let minY = -(firstOrigin.getY() + app.worldSize.y);
    let maxY = -lastOrigin.getY();

    let minZ = firstOrigin.getZ();
    let maxZ = lastOrigin.getZ();

    let sizeX = maxX - minX;
    let sizeY = maxY - minY;
    let sizeZ = maxZ - minZ;

    let xOffset = -defaultXOffset;
    let yOffset = -defaultYOffset;
    let zOffset = -defaultZOffset;

    var size = { x: sizeX, y: sizeY, z: sizeZ };
    var center = {
      x: minX + sizeX / 2,
      y: minY + sizeY / 2,
      z: minZ + sizeZ / 2,
    };

    var min = { x: minX, y: minY, z: minZ };
    var max = { x: maxX, y: maxY, z: maxZ };

    _originBox = { min, max, center, size };
    console.log("_originBox", _originBox);
    // drawBox(_originBox, 0xff0000);

    _dicomBox = rotateBox(_originBox, xOffset, yOffset, zOffset);

    if (dy != 0) {
      let minDZ = {
        x: _dicomBox.min.x,
        y: _dicomBox.min.y,
        z: _dicomBox.min.z + dy,
      };
      let maxDZ = {
        x: _dicomBox.max.x,
        y: _dicomBox.max.y,
        z: _dicomBox.max.z,
      };
      _dicomBox = convertToBox({ min: minDZ, max: maxDZ });
    }
    // drawBox(_dicomBox, 0x0000ff);
    console.log("_dicomBox", _dicomBox);
  } else if (app.worldSize.y != _dicomBox.size.y) {
    let dy = app.worldSize.y - _dicomBox.size.y;
    console.log("!!! dicom dif Y size", dy, app.worldSize, _dicomBox.size);
    // let minDY = {
    //   x: _dicomBox.min.x,
    //   y: _dicomBox.min.y - dy / 2,
    //   z: _dicomBox.min.z,
    // };
    // let maxDY = {
    //   x: _dicomBox.max.x,
    //   y: _dicomBox.max.y + dy / 2,
    //   z: _dicomBox.max.z,
    // };
    // _dicomBox = convertToBox({ min: minDY, max: maxDY });
    // console.log("_dicomBox", _dicomBox);
  }

  app.dicomBox = _dicomBox;
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const updateSegmentation = async (app, force) => {
  app.isLoading = true;
  app.text1 = "Slice segmentation";
  for (let i = 1; i <= app.max; i++) {
    app.text2 = `${i}/${app.max}`;
    await delay(10);
    drawOutlines(app, _dicomBox, i, true, force);
  }
  app.isLoading = false;

  try {
    sendDicomSegmentation();
    console.log("sendDicomSegmentation success");
  } catch (e) {
    console.log("sendDicomSegmentation error", e);
  }
};

function changeAppZoom(app, factor) {
  if (app) {
    const element = document.getElementById(app.divId);
    if (element) {
      const rect = element.getBoundingClientRect();
      const layerGroup = app.getActiveLayerGroup();
      const viewLayer = layerGroup.getActiveViewLayer();
      const viewController = viewLayer.getViewController();

      const planePos = viewLayer.displayToMainPlanePos(
        rect.width / 2,
        rect.height / 2
      );
      const center = viewController.getPlanePositionFromPlanePoint(planePos);

      let step = factor > 0 ? 0.1 : -0.1;
      layerGroup.addScale(step, center);
      layerGroup.draw();
    }
  }
}

function changeAppTool(app, toolName) {
  if (app && toolName) {
    app.toolName = toolName;
    if (toolName == "annotation") {
      app.setTool("Annotation");
      enterAnnotationTool(app);
      app.setTool("Annotation");
    } else {
      // updateAppLayout();
      if (toolName == "dragDrop") {
        app.setTool("ZoomAndPan");
      } else if (toolName == "measure") {
        app.setTool("Draw");
        app.setToolFeatures({ shapeName: "Ruler" });
      }
    }
  }
}

function enterAnnotationTool(rootApp) {
  vueApp.isAnnotationMode = true;

  if (!rootApp.isAnnotating) {
    // refreshDicomView(rootApp);
    // setTimeout(function () { }, 50);
    // app.setTool("Annotation");

    $(rootApp.annotationSpinnerId).css({ display: "block" });
    setTimeout(function () {
      if (!rootApp.childApps && rootApp.viewersId) {
        if (!rootApp.annotations) {
          let nonteethAnnotations = [];
          vueApp.bones
            .concat(vueApp.nerves)
            .concat(vueApp.gingivae)
            .concat(vueApp.airways)
            .forEach((item) => {
              const annotation = {
                name: item.name.toLowerCase(),
                filename: item.filename,
                color: vueApp.getActualColor(item),
                isVisible: item.isVisible,
                isTooth: false,
                isBone: vueApp.bones.includes(item),
                isEdited: false,
                isHovered: false,
                isFocused: false,
                hasFigures: true,
                appIndex: rootApp.index,
                model: item,
              };
              nonteethAnnotations.push(annotation);
            });

          rootApp.annotations = [];
          vueApp.allTeeth.forEach((item, index) => {
            const annotation = createAnnotationFromTooth(item, rootApp);
            rootApp.annotations.push(annotation);
          });
          rootApp.allAnnotations = nonteethAnnotations.concat(
            rootApp.annotations
          );
          const apanel = document.getElementById(rootApp.apanelId);
          rootApp.allAnnotations.forEach((annotation, index) => {
            annotation.opacityColor = hexToCanvasColor(annotation.color, 0.5);
            const annotationInstance = new Vue({
              ...DicomAnnotation,
              propsData: {
                annotation: annotation,
                app: rootApp,
                dicomBox: _dicomBox,
              },
            });
            const vueContainer = document.createElement("div");
            apanel.appendChild(vueContainer);
            annotationInstance.$mount(vueContainer);
          });

          let assignBtn = document.getElementById("annotation-assign-btn");
          assignBtn.callback = (
            currentApp,
            currentAnnotation,
            currentFigure
          ) => {
            if (currentFigure && currentAnnotation) {
              console.log(currentFigure, currentAnnotation);

              const assignHeader = document.getElementById(
                rootApp.assignHeaderId
              );
              assignHeader.innerHTML = `Assign ${currentAnnotation.name}`;

              const assignList = document.getElementById(rootApp.assignListId);
              assignList.innerHTML = "";
              // while (assignList.firstChild) {
              //   assignList.removeChild(assignList.lastChild);
              // }
              // assignList.replaceChildren(...arrayOfNewChildren);
              let neighbours = getNeighborAnnotations(
                rootApp,
                currentAnnotation
              );

              neighbours.forEach((annotation) => {
                if (annotation == currentAnnotation) {
                  return;
                }
                annotation.isSelected = false;
                const annotationInstance = new Vue({
                  ...AssignAnnotation,
                  propsData: {
                    annotation: annotation,
                    app: rootApp,
                  },
                });
                const vueContainer = document.createElement("div");
                assignList.appendChild(vueContainer);
                annotationInstance.$mount(vueContainer);
              });

              rootApp.assignedFigure = currentFigure;
              rootApp.assignedAnnotation = currentAnnotation;
              rootApp.assignedSlice = rootApp.current;
              $(`#${rootApp.assignPanelId}`).css({
                display: "block",
              });
            }
          };

          let focusBtn = document.getElementById("tooth-focus-btn");
          focusBtn.callback = (currentApp, currentAnnotation) => {
            if (currentApp && currentAnnotation) {
              focusAnnotation(currentApp, currentAnnotation);
            }
          };

          let editBtn = document.getElementById("tooth-edit-btn");
          editBtn.callback = (currentApp, currentAnnotation) => {
            if (currentApp && currentAnnotation) {
              currentAnnotation.isEdited = true;
              editAnnotation(currentApp, currentAnnotation);
            }
          };

          // let smoothBtn = document.getElementById("tooth-smooth-btn");
          // smoothBtn.callback = (currentApp, currentAnnotation) => {
          //   if (currentApp && currentAnnotation) {
          //     console.log("smooth tooth");
          //   }
          // };
        }
        let clonedAnnotations = [];
        vueApp.allTeeth.forEach((tooth, index) => {
          const annotation = createAnnotationFromTooth(tooth, rootApp);
          clonedAnnotations.push(annotation);
        });
        rootApp.childApps = [];
        rootApp.crossedApps = [];
        _orientations.forEach((orientation, index) => {
          const app = new dwv.App();
          rootApp.childApps.push(app);
          rootApp.crossedApps.push(app);
          app.parentApp = rootApp;
          app.index = index;
          app.viewIndex = app.index !== 0 ? app.index - 1 : 2;
          app.color = _colors[index];
          app.vFrom = _vFroms[index];
          app.orientation = orientation;
          app.divId = `${rootApp.orientation}_child_${app.orientation}`;
          app.isLoading = false;
          app.visible = true;
          app.opacity = 0.5;
          app.sliceOpacity = 0.25;
          app.showSegmentation = true;
          app.showIntersection = true;
          app.is3D = false;
          app.isDicom3DView = false;
          app.show3DLayer = false;
          app.show3DFiller = true;
          app.segmentationType = "fillTeeth";
          app.segmentations = ["mandTeeth", "maxTeeth"];
          // app.annotations = [];
          app.annotations = structuredClone(clonedAnnotations);
          app.annotations.forEach((item) => {
            item.appIndex = app.index;
            let parentAnnotation = rootApp.annotations.find(
              (element) => element.filename == item.filename
            );
            if (parentAnnotation) {
              item.parentAnnotation = parentAnnotation;
              if (!parentAnnotation.childAnnotations) {
                parentAnnotation.childAnnotations = [];
              }
              parentAnnotation.childAnnotations.push(item);
            }
          });

          const viewerInstance = new Vue({
            ...SimpleDicomViewer,
            propsData: {
              app: app,
              // app: { index: index, orientation: orientation },
            },
          });
          const vueContainer = document.createElement("div");
          document.getElementById(rootApp.viewersId).appendChild(vueContainer);
          viewerInstance.$mount(vueContainer);
        });
        rootApp.crossedApps.push(rootApp);
      }
      drawAnnotations(rootApp, _dicomBox);
      $(rootApp.annotationSpinnerId).css({ display: "none" });

      _appArr.forEach((app) => {
        if (app.frameMesh) {
          viewer.scene.remove(app.frameMesh);
        }
      });
      $(".flex-item").css({ display: "none" });
      $("#stl_menu").css({ display: "none" });
      $(".annotation-off-item").css({ display: "none" });
      $(".annotation-on-item").css({ display: "block" });
      $(`#${rootApp.containerId}`).css({
        display: "block",
        height: "calc(100% + 35px)",
      });
      rootApp.isAnnotating = true;

      rootApp.onResize();
      rootApp.resetZoom();

      if (rootApp.index > 0) {
        let currentConfigs = rootApp.getDataViewConfigs();
        rootApp.setDataViewConfigs(currentConfigs);
        for (let i = 0; i < rootApp.getNumberOfLoadedData(); ++i) {
          rootApp.render(i);
        }
        // to fix: mouse events
      }
      // setSliceIndex(rootApp, rootApp.current - 1);
      enterAnnotationMode(rootApp);
    }, 50);
  }
}

function createAnnotationFromTooth(tooth, app) {
  const annotation = {
    number: tooth.number,
    name: tooth.filename.replace(".stl", ""),
    filename: tooth.filename,
    addedTag: tooth.addedTag,
    color: vueApp.getActualColor(tooth),
    isVisible: tooth.isVisible,
    isSelected: false,
    isTooth: true,
    isEdited: false,
    isFocused: false,
    isDeleted: false,
    isHovered: false,
    changed: false,
    positionChanged: false,
    hasFigures: true,
    fillSegmentations: true,
    appIndex: app.index,
    model: tooth,
    smoothFactor: 1,
    // allFigures: {},
    // allAnnotationPoints: [],
  };
  annotation.opacityColor = hexToCanvasColor(annotation.color, 0.5);
  return annotation;
}

function getNeighborAnnotations(app, annotation) {
  let neighbors = [];
  if (app && annotation) {
    let aposition = annotation.model.defaultPosition;
    app.annotations.forEach((a) => {
      if (a !== annotation) {
        if (a.model && a.model.defaultPosition) {
          a.adistance = calculateDistance(a.model.defaultPosition, aposition);
        } else {
          a.adistance = 0;
        }
        neighbors.push(a);
      }
    });
    neighbors.sort((a, b) => a.adistance - b.adistance);
  }
  return neighbors;
}

function editAnnotation(app, annotation) {
  // console.log("editAnnotation", app, annotation);
  if (annotation.isEdited) {
    app.editedAnnotation = annotation;
    if (app.focusedAnnotation && app.focusedAnnotation != annotation) {
      unfocusAnnotation(app);
    }
    focusAuxilaryModel();
    if (annotation.figures) {
      annotation.figures.forEach((figure) => {
        if (figure.spline) {
          let children = figure.spline.getChildren();
          if (children && children.length > 1) children[1].fill("transparent");
        }
      });
    }
    if (annotation.childAnnotations) {
      annotation.childAnnotations
        .filter((a) => a.figures)
        .forEach((a) => {
          a.figures.forEach((figure) => {
            if (figure.spline) {
              let children = figure.spline.getChildren();
              if (children && children.length > 1)
                children[1].fill("transparent");
            }
          });
        });
    }

    // this.mouseleave();
    annotation.isHovered = true;
    app.isEditingAnnotation = true;
    app.allAnnotations.forEach((a) => {
      if (a != annotation) {
        a.isFocused = a.isEdited = a.isHovered = false;
      }
    });
    app.setToolFeatures({ edited: annotation });
  } else {
    if (annotation) annotation.isHovered = false;
    app.isEditingAnnotation = false;
    app.editedAnnotation = null;
    app.setToolFeatures({ edited: null });
  }
}

function editToothSettings(app, annotation) {
  initToothNumberControls();
  let tooth = annotation.model;
  if (tooth) {
    ToothNumberMode.changingAnnotation = annotation;
    ToothNumberMode.annotations = app.annotations;
    ToothNumberMode.setChangingTooth(tooth);

    showToothNumberDialog();
    // $("#toothNumberDialog").modal();
  }
}

function addNewAnnotation(rootApp, newTooth) {
  let toothNumber = +newTooth.number;
  let addedTag = vueApp.generateAddedTag(toothNumber);
  let fileName = `tooth_${toothNumber}-${addedTag}`;

  let numberType = newTooth.numberType;
  if (numberType && numberType.trim() && numberType != "primary")
    fileName = `${fileName}-${numberType}`;

  let remarks = newTooth.remarks;
  if (remarks && remarks.trim()) fileName = `${fileName}-${remarks}`;

  const annotation = {
    number: toothNumber,
    numberType: numberType,
    remarks: remarks,
    isTooth: true,
    name: fileName,
    filename: `${fileName}.stl`,
    addedTag: addedTag,
    color: getRandomColor(), //"#faeb36"
    isVisible: true,
    isSelected: false,
    isHovered: true,
    isEdited: true,
    isAdded: true,
    isDeleted: false,
    isFocused: false,
    changed: false,
    positionChanged: false,
    wasChanged: true,
    hasFigures: true,
    fillSegmentations: rootApp.annotationFillSegmentations,
    appIndex: rootApp.index,
    smoothFactor: 1,
    figures: [],
    // allFigures: {},
    // allAnnotationPoints: [],
    // childAnnotations: [],
    model: null, //{},
  };
  // annotation.allFigures[rootApp.current] = annotation.figures;

  let color = hexToCanvasColor(annotation.color, rootApp.annotationOpacity);
  annotation.opacityColor = color;

  let tooth = {
    id: vueApp.generateNewItemId(),
    number: annotation.number,
    savedNumber: annotation.number,
    newNumber: annotation.number,
    newNumberType: annotation.numberType,
    newRemarks: annotation.remarks,
    name: `${annotation.number}`,
    filename: annotation.filename,
    isVisible: true,
    isLoaded: false,
    isAdded: true,
    isFromData: false,
    isTooth: true,
    color: annotation.color,
    addedTag: addedTag,
  };
  tooth.isMaxilla = tooth.name.startsWith("1") || tooth.name.startsWith("2");
  tooth.dentition = tooth.isMaxilla ? "Maxilla" : "Mandible";
  annotation.model = tooth;

  const apanel = document.getElementById(rootApp.apanelId);
  rootApp.annotations.push(annotation);
  rootApp.allAnnotations.push(annotation);
  const annotationInstance = new Vue({
    ...DicomAnnotation,
    propsData: {
      annotation: annotation,
      app: rootApp,
      dicomBox: _dicomBox,
    },
  });
  const vueContainer = document.createElement("div");
  apanel.appendChild(vueContainer);
  annotationInstance.$mount(vueContainer);

  rootApp.childApps.forEach((app) => {
    let item = structuredClone(annotation);
    item.appIndex = app.index;
    item.parentAnnotation = annotation;
    if (!annotation.childAnnotations) {
      annotation.childAnnotations = [];
    }
    annotation.childAnnotations.push(item);
    app.annotations.push(item);
  });

  rootApp.isEditingAnnotation = true;
  rootApp.editedAnnotation = annotation;
  rootApp.selectedAnnotation = annotation;
  rootApp.annotations.forEach((a) => {
    a.isEdited = false;
    a.isHovered = false;
  });
  unfocusAnnotation(rootApp);
  annotation.isEdited = true;

  focusAuxilaryModel();

  // app.setTool("Draw");
  rootApp.setToolFeatures({ edited: annotation });
}

function deleteAnnotation(app, annotation) {
  if (app && annotation) {
    if (annotation.isEdited) {
      app.isEditingAnnotation = false;
      app.editedAnnotation = null;
      app.setToolFeatures({ edited: null });
    }
    annotation.isEdited = false;
    annotation.isDeleted = true;
    annotation.changed = false;
    annotation.positionChanged = false;
    annotation.hasFigures = false;
    let index = app.annotations.indexOf(annotation);
    app.annotations.splice(index, 1);
    if (app.allAnnotations) {
      index = app.allAnnotations.indexOf(annotation);
      app.allAnnotations.splice(index, 1);
    }
    if (annotation.figures)
      annotation.figures.forEach((figure) => {
        if (figure.spline) figure.spline.destroy();
        if (figure.anchors) {
          figure.anchors.forEach((anchor) => anchor.destroy());
        }
      });

    if (app.childApps) {
      if (!app.deletedAnnotations) {
        app.deletedAnnotations = [];
      }
      app.deletedAnnotations.push(annotation);
    }
  }
}

function updateAnnotationPosition(app, annotation) {
  if (annotation.positionChanged) {
    annotation.positionChanged = false;
    let difX = annotation.positionChange.difX;
    let difY = annotation.positionChange.difY;

    let appIndex = annotation.positionChange.appIndex;

    let dx = 0;
    let dy = 0;
    let dz = 0;
    if (appIndex == 0) {
      dx = +difX;
      dy = +difY;
    } else if (appIndex == 1) {
      dy = +difX;
      dz = +difY;
    } else if (appIndex == 2) {
      dx = +difX;
      dz = +difY;
    }

    console.log("updateAnnotationPosition", appIndex, dx, dy, dz);

    let newAllFigures = {};
    annotation.allAnnotationPoints = [];
    for (var sliceIndex in annotation.allFigures) {
      let sli = +sliceIndex;
      let figures = annotation.allFigures[sliceIndex];
      if (figures) {
        let newFigures = [];
        for (const afigure of figures) {
          let apoints = [...afigure.annotationPoints.values()];
          afigure.annotationPoints.clear();

          apoints.forEach((point) => {
            point.x += dx;
            point.y += dy;
            point.z += dz;
            const str = `${point.x},${point.y}`;
            afigure.annotationPoints.set(str, point);
          });
          annotation.allAnnotationPoints.push(
            ...afigure.annotationPoints.values()
          );
          newFigures.push(afigure);
        }
        newAllFigures[sli + dz] = newFigures;
        if (!Object.hasOwn(newAllFigures, sli) && dz !== 0)
          newAllFigures[sli] = [];
      }
    }
    annotation.allFigures = newAllFigures;

    updateCanvasContext(app);
    if (app.childApps) {
      app.childApps.forEach((childApp) => {
        updateCanvasContext(childApp);
      });
    }

    if (annotation.model) {
      let mesh = viewer.get_model_mesh(annotation.model.id);
      if (mesh) {
        let dif = get3dDif(dx, dy, dz);
        let position = mesh.position;
        let point = new THREE.Vector3(
          position.x + dif.dx,
          position.y + dif.dy,
          position.z + dif.dz
        );
        mesh.position.copy(point);
      }
    }
  }
}

function get3dDif(dx, dy, dz) {
  let retval = { dx: 0, dy: 0, dz: 0 };

  if (dx != 0) {
    let appSize = _appArr[1].max;
    retval.dx = dx * (_dicomBox.size.x / appSize);
  }
  if (dy != 0) {
    let appSize = _appArr[2].max;
    retval.dz = -dy * (_dicomBox.size.z / appSize);
  }
  if (dz != 0) {
    let appSize = _appArr[0].max;
    retval.dy = dz * (_dicomBox.size.y / appSize);
  }
  return retval;
}

function updateAppLayout() {
  $("#stl_menu").css({ display: "block" });
  $("#views3d_wrapper").css({ display: "block" });

  let orientations = [];
  let indexes = [];
  if (store.state.dicom.isAxial) {
    $("#axial_cont").css({ display: "block" });
    orientations.push("axial");
    indexes.push(0);
  }
  if (store.state.dicom.isSaggital) {
    $("#sagittal_cont").css({ display: "block" });
    orientations.push("sagittal");
    indexes.push(1);
  }
  if (store.state.dicom.isCoronal) {
    $("#coronal_cont").css({ display: "block" });
    orientations.push("coronal");
    indexes.push(2);
  }
  $(".flex-item").css({
    width: indexes.length > 0 ? "calc(50% - 2px)" : "100%",
  });
  $(".flex-item").css({
    height: indexes.length > 1 ? "calc(50% - 2px)" : "100%",
  });
  if (viewer) viewer.do_resize();

  if (indexes.length > 0 && !vueApp.isRandomTeethColor) {
    vueApp.isRandomTeethColor = true;
    updateRandomTeethColors(true);

    vueApp.bones.forEach(item => {
      if (item && item.color == vueApp.bonesColor) {
        item.color = item.isMaxilla ? "#e81416" : "#487de7"; // getRandomColor();
        viewer.set_color(item.id, item.color);
      };
    })
  }

  // console.log(orientations);
  renderDicomViews(indexes);
  // document.getElementById("dicomSubMenu").classList.remove("show");
  // document.getElementById("viewSubMenu").classList.remove("show");
  // setTimeout(function () {
  // 	renderDicomViews(indexes);
  // }, 2500);
}

function closeViewer(app) {
  $("#stl_menu").css({ display: "block" });
  $("#views3d_wrapper").css({ display: "block" });
  if (app) {
    if (app.isAnnotating) {
      vueApp.isAnnotationMode = false;
      switch3DView(app, false);

      app.isAnnotating = false;
      $(".annotation-off-item").css({ display: "block" });
      $(".annotation-on-item").css({ display: "none" });
      $(`.annotation-settings-panel`).collapse("hide");
      $(`.dicom-settings-panel`).collapse("hide");
      if (app.toolName == "annotation") {
        changeAppTool(app, "dragDrop");
      }
      // refreshDicomView(app);
      setSliceIndex(app, app.current - 1);
      updateStl3D(app);
      leaveAnnotationMode();
    } else {
      console.log("closeViewer", app.orientation);
      $(`#${app.containerId}`).css({ display: "none" });
      store.commit(mutationTypes.closeProjection, app.index);
    }
    updateAppLayout();
  }
}

function switchTeethNumbers(app) {
  app.showTeethNumbers = !app.showTeethNumbers;
  if (app.showTeethNumbers) {
    drawTeethNumbers();
    // enterToothNumberMode(true);
  } else {
    removeTeethNumbers();
    // leaveToothNumberMode(false, false);
  }
}

function switch3DView(app, is3D) {
  app.is3D = is3D;
  updateNeighborIntersections(app, app.crossedApps);
  let stlDiv = document.getElementById("views3d_cont");
  let dicomDiv = document.getElementById(app.divId);
  if (is3D) {
    updateStl3D(app);

    dicomDiv.style.display = "none";
    $(".3d-off-item").css("display", "none");
    let parentDiv = dicomDiv.parentNode;
    parentDiv.insertBefore(stlDiv, dicomDiv.nextSibling);
    // console.log(stlDiv.style.width);
    viewer.do_resize();

    app.childApps.forEach((childApp) => {
      update3DFrame(childApp);
    });

    let focusedAnnotation = app.annotations.find((a) => a.isFocused);
    app.allAnnotations.forEach((annotation) => {
      let mesh = viewer.get_model_mesh(annotation.model.id);
      if (mesh.visible === undefined) {
        return;
      }
      if (focusedAnnotation) {
        mesh.visible = annotation === focusedAnnotation;
      } else {
        mesh.visible = annotation.isVisible;
      }
    });
  } else {
    let parentDiv = document.getElementById("views3d_wrapper");
    let firstChild = parentDiv.firstChild;
    parentDiv.insertBefore(stlDiv, firstChild);
    dicomDiv.style.display = "block";
    $(".3d-off-item").css("display", "block");
    viewer.do_resize();

    app.childApps.forEach((childApp) => {
      if (childApp.frameMesh) {
        viewer.scene.remove(childApp.frameMesh);
      }
    });

    vueApp.allItems.forEach(function (item) {
      let color = item.color;
      if (item.isTooth) {
        color = vueApp.getActualColor(item);
      }
      itemVisibilityChanged(item, color);
    });
  }
}

function updateStl3D(app) {
  if (app.deletedAnnotations) {
    app.deletedAnnotations.forEach((annotation) => {
      if (annotation.model && annotation.model.id && !annotation.wasDeleted) {
        vueApp.removeTooth(annotation.model);
        viewer.remove_model(annotation.model.id);
        annotation.wasDeleted = true;
      }
    });
    // app.deletedAnnotations = [];
  }

  let changedAnnotations = app.annotations.filter(
    (a) => a.wasChanged || a.wasSaved
  );
  if (changedAnnotations.length) {
    changedAnnotations.forEach((annotation) => {
      if (annotation.isAdded && !annotation.created) {
        let blob = getAnnotationData(annotation, app);
        if (!blob) {
          console.log("updateStl3D empty annotation", annotation.name);
          return;
        }

        let tooth = annotation.model;
        vueApp.addTooth(tooth);
        tooth.stlModelLoaded = initAnnotationTooth;

        var stlModel = {
          local_file: new File([blob], annotation.filename),
          color: tooth.color,
        };
        stlModel.id = tooth.id;
        viewer.add_model(stlModel);
        annotation.created = true;
      } else {
        let model = annotation.model;
        let mesh = viewer.get_model_mesh(model.id);
        // if (!annotation.baseMesh) {
        //   annotation.baseMesh = mesh.clone();
        // }

        let newGeometry = getAnnotationGeometry(annotation, app);
        if (newGeometry) {
          // let vmesh = new THREE.Mesh(newGeometry);
          // let box = new THREE.Box3().setFromObject(vmesh);
          newGeometry.computeBoundingBox();
          let box = newGeometry.boundingBox;
          box = offsetBox(box);
          model.defaultBox = box;
          model.defaultPosition = {
            x: box.center.x,
            y: box.center.y,
            z: box.center.z,
          };
          mesh.position.set(box.center.x, box.center.y, box.center.z);

          newGeometry.center();
          newGeometry.computeVertexNormals();

          let geometry = new THREE.Geometry().fromBufferGeometry(newGeometry);
          geometry.mergeVertices();
          geometry.computeVertexNormals();
          geometry = new THREE.BufferGeometry().fromGeometry(geometry);
          geometry.computeBoundingBox();

          mesh.geometry.dispose();
          mesh.geometry = geometry;

          viewer.set_color(model.id, vueApp.getActualColor(model));

          if (viewer.scene.children.indexOf(mesh) < 0) {
            viewer.scene.add(mesh);
          }
          mesh.material.flatShading = false;
        } else {
          viewer.scene.remove(mesh);
        }
      }
    });
  }
}

function initAnnotationTooth(model_id) {
  console.log("initAnnotationTooth", model_id);
  let model = vueApp.getItemById(model_id);
  let vmesh = viewer.get_model_mesh(model.id);
  if (vmesh && vmesh.geometry) {
    let box = new THREE.Box3().setFromObject(vmesh);
    box = offsetBox(box);
    model.defaultBox = box;
    model.uuid = vmesh.uuid;
    model.defaultPosition = {
      x: box.center.x,
      y: box.center.y,
      z: box.center.z,
    };

    vmesh.geometry.center();
    vmesh.position.set(box.center.x, box.center.y, box.center.z);
    vmesh.material.flatShading = false;
    vmesh.geometry.computeVertexNormals();

    if (model.isTooth) {
      var clone_geometry = vmesh.geometry.clone();
      var clone_material = vmesh.material.clone();
      clone_material.color.set(0xffffff);
      clone_material.opacity = 0.0001;
      clone_material.transparent = true;
      var defaultMesh = new THREE.Mesh(clone_geometry, clone_material);
      defaultMesh.visible = false;
      viewer.scene.add(defaultMesh);
      defaultMesh.position.set(box.center.x, box.center.y, box.center.z);
      model.defaultMeshId = defaultMesh.id;
      defaultMesh.isVisible = false;

      setToothMidData(model);
      var apexMesh = drawPoint(model.apex, 0x0000ff, true, 0.01);
      vmesh.add(apexMesh);
      apexMesh.position.set(
        model.apex.x - box.center.x,
        model.apex.y - box.center.y,
        model.apex.z - box.center.z
      );
      vmesh.apexMesh = apexMesh;

      var rootMesh = drawPoint(model.root, 0xff0000, true, 0.01);
      vmesh.add(rootMesh);
      rootMesh.position.set(
        model.root.x - box.center.x,
        model.root.y - box.center.y,
        model.root.z - box.center.z
      );
      vmesh.rootMesh = rootMesh;
    }

    vueApp.switchToDefaultView();
    // vueApp.lookAtPoint(box.center);
  }
  focusAuxilaryModel();
}

function updateSliceIntersection(app, appArr) {
  if (app && app.divId) {
    removeSliceIntersection(app);

    if (app.showIntersection && app.getLayerGroupByDivId) {
      if (!appArr) {
        appArr = app.isAnnotating ? app.crossedApps : _appArr;
      }
      const layerGroup = app.getLayerGroupByDivId(app.divId);
      if (!layerGroup) return;

      const containerId = app.divId;
      const containerDiv = document.getElementById(containerId);

      const hColor = app.orientation == "axial" ? _colors[2] : _colors[0];
      const vColor = app.orientation == "sagittal" ? _colors[2] : _colors[1];

      let top = containerDiv.offsetHeight / 2;
      let left = containerDiv.offsetWidth / 2;

      let values = [0, 0, 0, 0];
      for (let appItem of appArr) {
        if (!appItem.current) {
          values = null;
          break;
        }
        if (!appItem.is3D || appItem.parentApp)
          values[appItem.viewIndex] = appItem.current - 1;
      }

      if (values) {
        const viewLayer = layerGroup.getActiveViewLayer();
        const viewController = viewLayer.getViewController();

        const index = new dwv.Index(values);
        const geometry = appArr[0].getImage(0).getGeometry();
        const position = geometry.indexToWorld(index);

        const p2D = viewController.getPlanePositionFromPosition(position);
        const displayPos = viewLayer.planePosToDisplay(p2D.x, p2D.y);
        top = displayPos.y;
        left = displayPos.x;
      }

      const lineH = document.createElement("div");
      lineH.id = containerId + "-intersection-horizontal";
      lineH.className = "dicom-intersection horizontal";
      lineH.style.borderTop = "2px dashed " + hColor;
      lineH.style.top = top + "px";
      if (top < 0) {
        lineH.style.display = "none";
      }

      const lineV = document.createElement("div");
      lineV.id = containerId + "-intersection-vertical";
      lineV.className = "dicom-intersection vertical";
      lineV.style.borderLeft = "2px dashed " + vColor;
      lineV.style.left = left + "px";
      if (left < 0) {
        lineV.style.display = "none";
      }

      containerDiv.appendChild(lineH);
      containerDiv.appendChild(lineV);
    }
  }
}

function updateNeighborIntersections(app, appArr) {
  if (!appArr) {
    appArr = app.isAnnotating ? app.crossedApps : _appArr;
  }

  appArr.forEach((appItem) => {
    updateSliceIntersection(appItem, appArr);
  });
}

function removeSliceIntersection(app) {
  if (app && app.divId) {
    const containerId = app.divId;

    const lineH = document.getElementById(
      containerId + "-intersection-horizontal"
    );
    if (lineH) lineH.remove();

    const lineV = document.getElementById(
      containerId + "-intersection-vertical"
    );
    if (lineV) lineV.remove();
  }
}

function focusAnnotation(app, annotation) {
  console.log("focusAnnotation");
  app.allAnnotations.forEach((a) => {
    if (a !== annotation) {
      a.isFocused = a.isEdited = a.isHovered = false;
      a.isHidden = true;
    }
  });
  annotation.isVisible = annotation.isFocused = true;
  annotation.isHidden = false;
  app.focusedAnnotation = annotation;
  focusAuxilaryModel();

  let appArr = [app].concat(app.childApps);
  focusTooth(annotation.model, appArr);
}

function unfocusAnnotation(mainApp) {
  // if (mainApp.focusedAnnotation) mainApp.focusedAnnotation.isFocused = false;

  mainApp.focusedAnnotation = null;
  unfocusTooth(false);
  mainApp.allAnnotations.forEach((a) => {
    a.isHidden = false;
    a.isFocused = false;
  });
  let appArr = [mainApp].concat(mainApp.childApps);
  appArr.forEach((app) => {
    setSliceIndex(app, app.current - 1);
    _appArr.forEach((rapp) => {
      if (rapp.frameMesh) {
        viewer.scene.remove(rapp.frameMesh);
      }
    });
  });

  mainApp.allAnnotations.forEach((annotation) => {
    let mesh = viewer.get_model_mesh(annotation.model.id);
    if (mesh.visible !== undefined) mesh.visible = annotation.isVisible;
  });
}

function focusDicomsTo3dPoint(point, apps) {
  if (_initialized && _dicomBox) {
    if (!apps) apps = _appArr;
    apps.forEach((app) => {
      if (app.visible) {
        if (
          app.isAnnotating &&
          app.childApps &&
          app.focusedAnnotation &&
          app.focusedAnnotation.hasFigures
        ) {
          drawAnnotations(app, _dicomBox);
          return;
        }

        let diff = point.y - _dicomBox.min.y;
        let offset = diff / _dicomBox.size.y;
        if (app.index === 1) {
          diff = point.x - _dicomBox.min.x;
          offset = diff / _dicomBox.size.x;
        }
        if (app.index === 2) {
          diff = point.z - _dicomBox.min.z;
          offset = 1 - diff / _dicomBox.size.z;
        }

        let sliceIndex = Math.floor(offset * app.max);
        setSliceIndex(app, sliceIndex + 1);
      }
    });
  }
}

function assignFigure(app) {
  let sliceIndex = app.assignedSlice;
  let figure = app.assignedFigure;
  let oldAnnotation = app.assignedAnnotation;
  let newAnnotation = app.annotations.find((a) => a.isSelected);
  if (sliceIndex > 0 && figure && oldAnnotation && newAnnotation) {
    console.log("assignFigure", newAnnotation.filename);
    let oldFigures = oldAnnotation.allFigures[sliceIndex];
    let figureIndex = oldFigures.indexOf(figure);
    oldFigures.splice(figureIndex, 1);
    figure.annotation = newAnnotation;

    let newFigures = newAnnotation.allFigures[sliceIndex];
    if (!newFigures) {
      newAnnotation.allFigures[sliceIndex] = newFigures = [];
    }
    newFigures.push(figure);
    // newFigures.splice(figureIndex, 0, figure);

    if (sliceIndex === app.current) {
      oldAnnotation.figures.splice(figureIndex, 1);
      if (!newAnnotation.figures) {
        newAnnotation.figures = [];
      }
      newAnnotation.figures.push(figure);
      figure.color = newAnnotation.opacityColor;
      figure.filename = newAnnotation.filename;
      if (figure.spline) {
        let spline = figure.spline.getChildren()[0];
        spline.fill(figure.color);
        spline.stroke(figure.color);
      }
    }

    oldAnnotation.changed = oldAnnotation.wasChanged = true;
    oldAnnotation.hasRemovedFigures = true;

    newAnnotation.changed = newAnnotation.wasChanged = true;
  }
}

function changeEditMode(app, editMode, editSubMode) {
  console.log("changeEditMode", editMode, editSubMode);

  if (app.editMode !== editMode) {
    app.editMode = editMode;
    app.isBrushMode = editMode === "brush";
    drawAnnotations(app, _dicomBox);
    app.setToolFeatures({ editMode: editMode });
  }

  if (app.editSubMode !== editSubMode) {
    app.editSubMode = editSubMode;
    app.setToolFeatures({ editSubMode: editSubMode });
  }
}

export {
  assignFigure,
  addNewAnnotation,
  editAnnotation,
  editToothSettings,
  deleteAnnotation,
  focusAnnotation,
  unfocusAnnotation,
  updateAnnotationPosition,
  updateAppLayout,
  loadDicoms,
  updateOriginBox,
  update3DFrame,
  updateCanvasContext,
  setSliceIndex,
  setupCanvasEvents,
  updateSegmentation,
  changeAppZoom,
  changeAppTool,
  changeEditMode,
  updateSliceIntersection,
  updateNeighborIntersections,
  switch3DView,
  switchTeethNumbers,
  closeViewer,
  focusDicomsTo3dPoint,
  removeSliceIntersection,
};
