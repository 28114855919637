<template>
	<div id="toothNumberDialog" class="modal" tabindex="-1" role="dialog" aria-labelledby="toothNumberDialogTitle"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<form class="modal-content dialog-content">
				<!-- <div class="modal-header wizard-header">
					<h5 class="modal-title wizard-title" id="toothNumberDialogTitle">
						Enter New Tooth Number
					</h5>
				</div> -->
				<div class="modal-body wizard-body">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"
						style="z-index: 9; width: 30px;">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="container-fluid">
						<div class="mb-3">
							<label for="currentNumberInput" class="form-label">Current Number:</label>
							<input v-model="mode.selectedTooth.savedNumber" id="currentNumberInput" type="number"
								class="form-control" disabled readonly>
						</div>
						<div class="mb-3">
							<label for="newNumberInput" class="form-label">New Number:</label>
							<input v-model="mode.selectedTooth.newNumber" id="newNumberInput" type="number"
								class="form-control" v-on:keydown.stop>
						</div>
						<div class="row container-group">
							<div class="form-check">
								<input type="radio" class="form-check-input" id="numberPrimaryCheck" name="numberType"
									value="primary" v-model="mode.selectedTooth.newNumberType">
								<label class="form-check-label text-truncate" for="numberPrimaryCheck">Permanent</label>
							</div>
							<div class="form-check">
								<input type="radio" class="form-check-input" id="numberDeciduousCheck" name="numberType"
									value="deciduous" v-model="mode.selectedTooth.newNumberType">
								<label class="form-check-label text-truncate"
									for="numberDeciduousCheck">Deciduous</label>
							</div>
							<div class="form-check">
								<input type="radio" class="form-check-input" id="numberSupernumeraryCheck"
									name="numberType" value="supernumerary" v-model="mode.selectedTooth.newNumberType">
								<label class="form-check-label text-truncate" for="numberSupernumeraryCheck">
									Supernumerary</label>
							</div>
						</div>
						<div class="mb-3">
							<label for="numberRemarksInput" class="form-label">Remarks:</label>
							<input v-model="mode.selectedTooth.newRemarks" id="numberRemarksInput" class="form-control"
								type="text" maxlength="50" pattern="[a-zA-Z0-9\s]+" title="Alphanumeric only"
								v-on:keydown.stop>
						</div>

					</div>
				</div>
				<div class="modal-footer wizard-footer">
					<button type="button" class="btn btn-primary btn-next" aria-label="OK"
						v-on:click="saveToothNumber()">
						OK
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import swal from "sweetalert";
import { redrawToothNumber, ToothNumberMode } from "./ToothNumberMode";

const alphanumericRegex = /^[a-z0-9 ]*$/i;

export default {
	name: "ToothNumberDialog",
	props: ["mode"],
	data() {
		return {
			remarks: ""
		};
	},
	methods: {
		processKeyDown: function (e) {
			// let char = String.fromCharCode(e.keyCode); // Get the character
			// if (/^[a-zA-Z0-9\s]+$/.test(char)) return true; // Match with regex 
			// else e.preventDefault(); // If not match, don't add to input text
		},
		saveToothNumber: function () {
			let selectedTooth = this.mode.selectedTooth;
			let changingTooth = this.mode.changingTooth;
			let newNumber = +selectedTooth.newNumber;
			if (!newNumber || newNumber < 1) {
				swal({
					text: "New tooth number must be positive value"
				});
			} else if (!alphanumericRegex.test(selectedTooth.newRemarks)) {
				swal({
					text: "Remarks can be alphanumeric only (with spaces)"
				});
			}
			else {
				if (changingTooth.newNumber != newNumber
					|| changingTooth.newNumberType != selectedTooth.newNumberType
					|| changingTooth.newRemarks != selectedTooth.newRemarks) {

					if (changingTooth.newNumber != newNumber) {
						changingTooth.newNumber = newNumber;
						redrawToothNumber(changingTooth);

						if (changingTooth.addedTag) {
							changingTooth.newAddedTag = vueApp.generateAddedTag(selectedTooth.newNumber);
						} else {
							let existed = vueApp.allTeeth.filter(x => x != changingTooth && (x.newNumber == newNumber
								|| (x.savedNumber == newNumber && !x.newNumber)));
							let i = 1;
							for (let tooth of existed) {
								tooth.newPrevTag = `prev(${i})`;
								tooth.displayName = vueApp.getToothFileNameWithPrev(tooth);
								if (this.mode.annotations) {
									let annotation = this.mode.annotations.find((x) => x.filename === tooth.filename);
									if (annotation) {
										annotation.name = tooth.displayName;
									}
								}
								this.mode.changedFiles.push(tooth.filename);
								i++;
							}
							changingTooth.newPrevTag = '';
						}
					}

					changingTooth.newNumberType = selectedTooth.newNumberType;
					changingTooth.newRemarks = selectedTooth.newRemarks;

					let name = `tooth_${changingTooth.newNumber}`;
					if (changingTooth.newAddedTag) {
						name += `-${changingTooth.newAddedTag}`;
					}
					if (changingTooth.newNumberType != 'primary') {
						name += `-${changingTooth.newNumberType}`;
					}
					if (changingTooth.newRemarks && changingTooth.newRemarks.length > 0) {
						name += `-${changingTooth.newRemarks}`;
					}
					changingTooth.displayName = name;
					console.log(changingTooth.displayName);

					let annotation = this.mode.changingAnnotation;
					if (annotation) {

						annotation.name = name;
						// annotation.number = changingTooth.newNumber;
						// annotation.numberType = changingTooth.newNumberType;
						// annotation.remarks = changingTooth.remarks;
						// if (annotation.wasSaved || !annotation.isAdded) {
						// 	this.mode.changedFiles.push(changingTooth.filename);
						// }
					}
					// else {
					// 	this.mode.changedFiles.push(changingTooth.filename);
					// }

					this.mode.changedFiles.push(changingTooth.filename);
				}
				this.mode.resetSelectedTooth();
				// this.mode.selectedTooth = { number: 0, newNumber: 0, newNumberType: "primary", newRemarks: '' };
				this.mode.changingAnnotation = null;
				$("#toothNumberDialog").modal("hide");
			}
		}
	}
};

function showToothNumberDialog() {
	// console.log(`showToothNumberDialog "${ToothNumberMode.selectedTooth.newNumberType}"`);
	$("#toothNumberDialog").modal();
}

export { showToothNumberDialog };
</script>

<style scoped>
@import "@/css/wizard.css";

.modal-sm {
	max-width: 500px;
	text-align: left;
}

.container-group {
	border: 2px solid #d7dee5;
	border-radius: 0.25rem;
	margin: 30px 0 20px 0;
	padding: 10px 0 4px 0;
	justify-content: space-between;
}

.form-check {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	max-width: calc(100% - 35px);
}

.form-check-label {
	display: inline-block;
	width: calc(100% + 25px);
}

.modal-header {
	height: 60px;
}

.modal-title {
	max-width: 270px;
	left: 45px;
	/* calc(50% - 55px); */
}

.modal-body {
	padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
}

.close {
	margin-right: -10px;
	margin-top: -20px;
}
</style>