// import * as THREE from "three";
// import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { getFaces, getPointIndex, getPointNodes } from "./GraphHelper";

let maxFacesCount = 0;
let tempMeshes = [];

function clearTempMeshes(scene) {
  tempMeshes.forEach((mesh) => {
    scene.remove(mesh);
  });
  tempMeshes = [];
}

function getFacesGeometry(
  model,
  points,
  globalMesh,
  globalGraph,
  scene,
  crossedPoints
) {
  let faces = getFacesToFill(
    points,
    points[0],
    globalGraph,
    scene,
    crossedPoints
  );
  if (!faces || !faces.length) return null;

  // faces = [faces[0]];

  let mesh = model.mesh;
  let faceCount = faces.length;
  const positionsLength = faceCount * 9;
  const positions = new Float32Array(positionsLength);
  let pi = 0;
  const indexLength = faceCount * 3;
  const indexes = new Uint32Array(indexLength);
  let ii = 0;

  if (mesh) {
    let invertedMatrix = mesh.matrixWorld.clone().invert();
    let invertedFaces = [];
    faces.forEach((face) => {
      let a = face.points[0];
      let b = face.points[1];
      let c = face.points[2];
      invertedFaces.push({
        pointA: new THREE.Vector3(a.x, a.y, a.z).applyMatrix4(invertedMatrix),
        pointB: new THREE.Vector3(b.x, b.y, b.z).applyMatrix4(invertedMatrix),
        pointC: new THREE.Vector3(c.x, c.y, c.z).applyMatrix4(invertedMatrix),
      });
    });
    faces = invertedFaces;
  }

  faces.forEach((face) => {
    indexes[ii++] = pi / 3;
    positions[pi++] = face.pointA.x;
    positions[pi++] = face.pointA.y;
    positions[pi++] = face.pointA.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.pointB.x;
    positions[pi++] = face.pointB.y;
    positions[pi++] = face.pointB.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.pointC.x;
    positions[pi++] = face.pointC.y;
    positions[pi++] = face.pointC.z;
  });

  let newGeometry = new THREE.BufferGeometry();
  let positionsAttr = new THREE.BufferAttribute(positions, 3);
  newGeometry.setAttribute("position", positionsAttr);

  const tempGeo = new THREE.Geometry().fromBufferGeometry(newGeometry);
  tempGeo.mergeVertices();
  tempGeo.computeVertexNormals();
  newGeometry = new THREE.BufferGeometry().fromGeometry(tempGeo);

  return newGeometry;
}

function fillArea(model, points, rootPoint, globalGraph, scene, isClearing) {
  let facesToFill = getFacesToFill(points, rootPoint, globalGraph, scene);

  // let facesToFill = getFacesToFillOld(globalGraph, points);

  let mesh = model.mesh;
  if (isClearing) {
    updateMeshFaces(mesh, null, facesToFill);
  } else {
    updateMeshFaces(mesh, facesToFill);
  }
}

function getFacesToFill(points, rootPoint, globalGraph, scene, crossedPoints) {
  // clearTempMeshes(scene);
  if (!maxFacesCount) {
    maxFacesCount = globalGraph.faces.length / 10;
  }
  points = points.filter((p) => p != undefined);
  let pointsLength = points.length;
  let pointNodes = [];

  //#region Create lasso segments
  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity,
    minZ = Infinity,
    maxZ = -Infinity;
  const segmentsXY = [];
  const segmentsXZ = [];
  const segmentsZY = [];
  for (let i = 0; i < pointsLength; i++) {
    let p1 = points[i];
    if (p1.x < minX) minX = p1.x;
    if (p1.y < minY) minY = p1.y;
    if (p1.z < minZ) minZ = p1.z;
    if (p1.x > maxX) maxX = p1.x;
    if (p1.y > maxY) maxY = p1.y;
    if (p1.z > maxZ) maxZ = p1.z;

    let p2 = points[i + 1];
    if (!p2) p2 = points[0];
    segmentsXY.push(new THREE.Line3(p1, p2));
    segmentsXZ.push(
      new THREE.Line3(
        new THREE.Vector2(p1.x, p1.z),
        new THREE.Vector2(p2.x, p2.z)
      )
    );
    segmentsZY.push(
      new THREE.Line3(
        new THREE.Vector2(p1.z, p1.y),
        new THREE.Vector2(p2.z, p2.y)
      )
    );

    let nodes = getPointNodes(globalGraph, p1, 0.001);
    // if (nodes.length < 1) {
    //   console.log("!!! Missed Point Nodes", i, point);
    //   continue;
    // } else if (nodes.length > 1) console.log("Many nodes", nodes.length);

    pointNodes = pointNodes.concat(nodes);
    if (p1.index === undefined && nodes.length > 0) {
      p1.index = nodes[0].index;
    }
  }
  //#endregion

  let pointIndexes = pointNodes.map((n) => n.index);
  pointIndexes = [...new Set(pointIndexes)];

  let borderPoints = points;
  let borderIndexes = [];
  let borderNodes = [];
  let borderFaces = [];
  let bfaceIndexes = [];

  let crossedPassed = crossedPoints && crossedPoints.length;
  if (crossedPassed) {
    let crossedLength = crossedPoints.length;
    for (let i = 0; i < crossedLength; i++) {
      let point = crossedPoints[i];
      let nodes = getPointNodes(globalGraph, point, 0.001);

      borderNodes = borderNodes.concat(nodes);
      if (point.index === undefined && nodes.length > 0) {
        point.index = nodes[0].index;
      }
    }
    borderNodes = pointNodes.concat(borderNodes);
    borderPoints = points.concat(crossedPoints);
  } else {
    borderNodes = pointNodes;
    borderIndexes = pointIndexes;
  }
  borderIndexes = [...new Set(borderIndexes)];

  borderIndexes = borderNodes.map((n) => n.index);
  borderIndexes = [...new Set(borderIndexes)];

  borderNodes.forEach((node) => {
    // let node = globalGraph.getNode(bindex);
    node.faces.forEach((bface) => {
      borderFaces.push(bface);
      bfaceIndexes.push(
        ...bface.indexes //.filter((i) => !borderIndexes.includes(i))
      );

      if (!bface.lassoArr) {
        bface.segmentsXY = [];
        bface.segmentsXZ = [];
        bface.segmentsZY = [];
        bface.points.forEach((bp1, index) => {
          let bp2 = bface.points[index + 1];
          if (!bp2) {
            bp2 = bface.points[0];
          }
          bface.segmentsXY.push(new THREE.Line3(bp1, bp2));
          bface.segmentsXZ.push(
            new THREE.Line3(
              new THREE.Vector2(bp1.x, bp1.z),
              new THREE.Vector2(bp2.x, bp2.z)
            )
          );
          bface.segmentsZY.push(
            new THREE.Line3(
              new THREE.Vector2(bp1.z, bp1.y),
              new THREE.Vector2(bp2.z, bp2.y)
            )
          );
          bface.lassoArr = [
            bface.segmentsXY,
            bface.segmentsXZ,
            bface.segmentsZY,
          ];
        });
      }
      // drawFace(scene, bface, 0xff0000, false);
    });
  });

  borderFaces = [...new Set(borderFaces)];

  let box = {
    min: { x: minX, y: minY, z: minZ },
    max: { x: maxX, y: maxY, z: maxZ },
  };
  box = offsetBox(box);
  // drawBox(box, 0xff0000, scene);
  // drawSpherePoint(scene, box.center, 0xFF0000, 0.4)

  let lassoArr = [segmentsXY, segmentsXZ, segmentsZY];
  let lassoFaces = [];
  globalGraph.faces.forEach((face) => {
    if (!isFaceInRanges(face, minX, minY, minZ, maxX, maxY, maxZ)) return;

    if (checkFaceInLasso(face, lassoArr)) {
      lassoFaces.push(face);
    }
  });

  // lassoFaces.forEach((face) => {
  //   drawFace(scene, face, 0x00ff00, true);
  // });

  let filteredFaces = lassoFaces;
  filteredFaces = [];
  lassoFaces.forEach((face) => {
    // let isFullIn = true;
    // face.points.forEach((point) => {
    //   if (isFullIn) {
    //     isFullIn = checkPointInLasso(point, lassoArr);
    //   }
    // });
    // let bindexes = face.indexes.filter((fi) => borderIndexes.includes(fi));
    let bfindexes = face.indexes.filter((fi) => bfaceIndexes.includes(fi)); // >= 2

    // if (isFullIn && bindexes.length === 0 && bfindexes.length === 3) {
    if (bfindexes.length === 0) {
      filteredFaces.push(face);
    }
  });
  // console.log("filteredFaces.length", filteredFaces.length);
  if (!filteredFaces.length) {
    filteredFaces = lassoFaces;
  }
  if (!filteredFaces.length) {
    return [];
  }

  // filteredFaces.forEach((face) => {
  //   drawFace(scene, face, 0x00ff00, true);
  // });

  let nearFace = null;
  let minDistance = Infinity;
  filteredFaces.forEach((face) => {
    let distance = getDistance2D(face.midPoint, box.center);
    if (distance < minDistance) {
      nearFace = face;
      minDistance = distance;
    }
  });
  filteredFaces = [nearFace];

  // filteredFaces.forEach((face) => {
  //   drawFace(scene, face, 0x0000ff, true);
  // });
  // borderFaces.forEach((face) => {
  //   drawFace(scene, face, 0xffff00, false);
  // });

  let facesToFill = [];
  let nborderFaces = [];
  fillNeighborFaces(
    filteredFaces,
    borderIndexes,
    borderPoints,
    borderFaces,
    facesToFill,
    nborderFaces,
    0
  );
  // console.log("FINISH fillNeighborFaces", new Date());
  // let filledIndexes = [];
  // return facesToFill;

  nborderFaces = [...new Set(nborderFaces)];
  let nindexes = [];
  nborderFaces.forEach((nface) => {
    nindexes.push(...nface.indexes.filter((i) => !borderIndexes.includes(i)));
    // drawFace(scene, nface, 0xff0000, true);
  });
  // console.log("nindexes", nindexes);

  facesToFill.push(...nborderFaces);

  let wasAdded = true;
  let counter = 0;
  while (wasAdded && counter < 10) {
    counter++;
    wasAdded = false;
    let tempFaces = [];
    nborderFaces.forEach((nface) => {
      nface.nodes.forEach((n) => {
        n.faces.forEach((nnface) => {
          if (facesToFill.includes(nnface)) {
            return;
          }

          if (nnface.indexes.find((i) => nindexes.includes(i))) {
            facesToFill.push(nnface);
            tempFaces.push(nnface);
            nindexes.push(
              ...nnface.indexes.filter((i) => !borderIndexes.includes(i))
            );
            wasAdded = true;
            // drawFace(scene, nnface, 0x00ff00, true);
          }
        });
      });
    });
    nborderFaces = tempFaces;
  }
  // console.log("FINISH nborderFaces", new Date());

  // pointIndexes = borderIndexes;
  pointIndexes.push(...nindexes);
  wasAdded = true;
  counter = 0;
  while (wasAdded && counter < 10) {
    counter++;
    wasAdded = false;
    borderFaces = borderFaces.filter((f) => !facesToFill.includes(f));
    borderFaces.forEach((face) => {
      let bindexes = face.indexes.filter((fi) => pointIndexes.includes(fi));
      if (bindexes.length == 3) {
        facesToFill.push(face);
        pointIndexes.push(...bindexes);
        wasAdded = true;
      } else if (bindexes.length == 2) {
        let innerIndex = face.indexes.find((fi) => !borderIndexes.includes(fi));
        let innerFaces = borderFaces.filter((f) =>
          f.indexes.includes(innerIndex)
        );
        let checkedFaces = innerFaces.filter(
          (f) =>
            f.indexes.filter(
              (i) => borderIndexes.includes(i) || i == innerIndex
            ).length == 3
        );

        if (
          innerFaces.length >= 4 &&
          innerFaces.length == checkedFaces.length
        ) {
          innerFaces.forEach((iface) => {
            facesToFill.push(iface);
          });
          borderIndexes.push(innerIndex);
          wasAdded = true;
        }
      }
    });
  }
  // console.log("FINISH borderIndexes", new Date());
  return facesToFill;
}

function fillNeighborFaces(
  faces,
  borderIndexes,
  borderPoints,
  borderFaces,
  facesToFill,
  nborderFaces,
  level
) {
  // console.log("fillNeighborFaces", level);
  if (!faces || !faces.length || facesToFill.length >= maxFacesCount) return;

  let nextLevelFaces = [];
  faces.forEach((face) => {
    if (face && !facesToFill.includes(face)) {
      let bindexes = face.indexes.filter((fi) => borderIndexes.includes(fi));
      if (bindexes.length > 0) {
        nborderFaces.push(face);
        return;
      }

      // let isInBorderFaces = false;
      // for (let bface of borderFaces) {
      //   isInBorderFaces = checkFaceInLasso(face, bface.lassoArr);
      //   if (isInBorderFaces) break;
      // }
      // if (isInBorderFaces) {
      //   nborderFaces.push(face);
      // } else {
      facesToFill.push(face);
      face.nodes.forEach((node) => {
        node.faces.forEach((nface) => {
          if (!faces.includes(nface) && !nextLevelFaces.includes(nface)) {
            // let nindexes = nface.indexes.filter((ni) =>
            //   face.indexes.includes(ni)
            // );
            // if (nindexes.length == 2)
            nextLevelFaces.push(nface);
          }
        });
      });
    }
    // }
  });

  // if (!nextLevelFaces.length) {
  //   console.log(level);
  // }

  fillNeighborFaces(
    nextLevelFaces,
    borderIndexes,
    borderPoints,
    borderFaces,
    facesToFill,
    nborderFaces,
    ++level
  );
}

function checkFaceInLasso(face, lassoArr) {
  let pointXY = face.midPoint;
  let pointXZ = face.midPointXZ;
  let pointZY = face.midPointZY;
  let lassoSegmentsXY = lassoArr[0];
  let lassoSegmentsXZ = lassoArr[1];
  let lassoSegmentsZY = lassoArr[2];

  let crossedSegmentsXY = pointRayCrossesSegments(pointXY, lassoSegmentsXY);
  let crossedSegmentsXZ = pointRayCrossesSegments(pointXZ, lassoSegmentsXZ);
  let crossedSegmentsZY = pointRayCrossesSegments(pointZY, lassoSegmentsZY);
  return (
    crossedSegmentsXY % 2 == 1 &&
    crossedSegmentsXZ % 2 == 1 &&
    crossedSegmentsZY % 2 == 1
  );
}

function checkPointInLasso(point, lassoArr) {
  if (!point || !lassoArr || !lassoArr.length) return false;
  let pointXY = point;
  let lassoSegmentsXY = lassoArr[0];
  let crossedSegmentsXY = pointRayCrossesSegments(pointXY, lassoSegmentsXY);

  let lassoSegmentsXZ = lassoArr[1];
  let crossedSegmentsXZ = lassoSegmentsXZ
    ? pointRayCrossesSegments({ x: point.x, y: point.z }, lassoSegmentsXZ)
    : 1;

  let lassoSegmentsZY = lassoArr[2];
  let crossedSegmentsZY = lassoSegmentsZY
    ? pointRayCrossesSegments({ x: point.z, y: point.y }, lassoSegmentsZY)
    : 1;

  return (
    crossedSegmentsXY % 2 == 1 &&
    crossedSegmentsXZ % 2 == 1 &&
    crossedSegmentsZY % 2 == 1
  );
}

function updateMeshFaces(mesh, facesToAdd, facesToDelete) {
  let allFaces = getFaces(mesh);

  if (facesToAdd) allFaces = allFaces.concat(facesToAdd);

  if (facesToDelete) {
    facesToDelete.forEach((fd) => {
      let ftd = allFaces.find((f) => areEqualFaces(f, fd));
      if (ftd) {
        let fi = allFaces.indexOf(ftd);
        allFaces[fi] = allFaces[allFaces.length - 1];
        allFaces.pop();
      }
    });
  }

  let faceCount = allFaces.length;
  let invertedMatrix = mesh.matrixWorld.clone().invert();
  let invertedFaces = [];
  allFaces.forEach((face) => {
    let a = face.points[0];
    let b = face.points[1];
    let c = face.points[2];
    invertedFaces.push({
      a: new THREE.Vector3(a.x, a.y, a.z).applyMatrix4(invertedMatrix),
      b: new THREE.Vector3(b.x, b.y, b.z).applyMatrix4(invertedMatrix),
      c: new THREE.Vector3(c.x, c.y, c.z).applyMatrix4(invertedMatrix),
    });
  });

  const positionsLength = faceCount * 9;
  const positions = new Float32Array(positionsLength);
  let pi = 0;
  const indexLength = faceCount * 3;
  const indexes = new Uint32Array(indexLength);
  let ii = 0;

  invertedFaces.forEach((face) => {
    indexes[ii++] = pi / 3;
    positions[pi++] = face.a.x;
    positions[pi++] = face.a.y;
    positions[pi++] = face.a.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.b.x;
    positions[pi++] = face.b.y;
    positions[pi++] = face.b.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.c.x;
    positions[pi++] = face.c.y;
    positions[pi++] = face.c.z;
  });

  let newGeometry = new THREE.BufferGeometry();
  let positionsAttr = new THREE.BufferAttribute(positions, 3);
  newGeometry.setAttribute("position", positionsAttr);

  const tempGeo = new THREE.Geometry().fromBufferGeometry(newGeometry);
  tempGeo.mergeVertices();
  tempGeo.computeVertexNormals();
  newGeometry = new THREE.BufferGeometry().fromGeometry(tempGeo);

  mesh.geometry.dispose();
  mesh.geometry = newGeometry;
}

function removeBorderPointsFaces(points, globalGraph, globalMesh, scene) {
  console.log("removeBorderPointsFaces", new Date());

  points = points.filter((p) => p != undefined);

  let borderFaces = [];
  let borderPoints = points; //.concat(crossedPoints);
  const pointsLength = borderPoints.length;
  let borderNodes = [];

  for (let i = 0; i < pointsLength; i++) {
    let p1 = borderPoints[i];
    let nodes = getPointNodes(globalGraph, p1, 0.001);
    if (nodes.length < 1) {
      // drawPoint(p1, scene);
      console.log("!!! Missed Point Nodes", i, p1);
      continue;
    }
    borderNodes = borderNodes.concat(nodes);
  }
  borderNodes = [...new Set(borderNodes)];

  borderNodes.forEach((node) => {
    // let node = globalGraph.getNode(bindex);
    node.faces.forEach((bface) => {
      borderFaces.push(bface);
    });
  });
  borderFaces = [...new Set(borderFaces)];
  // borderFaces.forEach((face) => {
  //   drawFace(scene, face, 0xffff00, false);
  // });

  let mesh = globalMesh;

  let allFaces = [...globalGraph.faces];
  //.filter((f) => f != undefined); //getFaces(mesh);
  if (borderFaces) {
    borderFaces.forEach((fd) => {
      let ftd = allFaces.find((f) => areEqualFaces(f, fd));
      if (ftd) {
        let fi = allFaces.indexOf(ftd);
        allFaces[fi] = allFaces[allFaces.length - 1];
        allFaces.pop();
        // allFaces.splice(fi, 1);
      }
    });
  }

  let faceCount = allFaces.length;
  // let invertedMatrix = mesh.matrixWorld.clone().invert();
  let invertedFaces = [];
  allFaces.forEach((face) => {
    let a = face.points[0];
    let b = face.points[1];
    let c = face.points[2];
    invertedFaces.push({
      a: new THREE.Vector3(a.x, a.y, a.z), //.applyMatrix4(invertedMatrix),
      b: new THREE.Vector3(b.x, b.y, b.z), //.applyMatrix4(invertedMatrix),
      c: new THREE.Vector3(c.x, c.y, c.z), //.applyMatrix4(invertedMatrix),
    });
  });

  const positionsLength = faceCount * 9;
  const positions = new Float32Array(positionsLength);
  let pi = 0;
  const indexLength = faceCount * 3;
  const indexes = new Uint32Array(indexLength);
  let ii = 0;

  invertedFaces.forEach((face) => {
    indexes[ii++] = pi / 3;
    positions[pi++] = face.a.x;
    positions[pi++] = face.a.y;
    positions[pi++] = face.a.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.b.x;
    positions[pi++] = face.b.y;
    positions[pi++] = face.b.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.c.x;
    positions[pi++] = face.c.y;
    positions[pi++] = face.c.z;
  });

  let newGeometry = new THREE.BufferGeometry();
  let positionsAttr = new THREE.BufferAttribute(positions, 3);
  newGeometry.setAttribute("position", positionsAttr);
  // newGeometry.setIndex(new THREE.BufferAttribute(indexes));
  console.log("end 1", new Date());

  // let retval = split_mesh_islands(newGeometry);
  // console.log("end 2", new Date(), retval);

  const tempGeo = new THREE.Geometry().fromBufferGeometry(newGeometry);
  tempGeo.mergeVertices();
  tempGeo.computeVertexNormals();
  newGeometry = new THREE.BufferGeometry().fromGeometry(tempGeo);

  mesh.geometry.dispose();
  mesh.geometry = newGeometry;
  console.log("end 3", new Date());
}

//#region Old

function getFacePoint(face, borderPoints) {
  let fpoint = null;
  fpoint = face.points.concat([face.midPoint]).find((fp) => {
    let bpoint = borderPoints.find((bp) => areNearPoints2D(fp, bp, 0.001));
    return bpoint;
  });
  return fpoint;
  // if (faces.length == 1) {
  //   face.points.forEach((fp) => {
  //     let bpoints = borderPoints
  //       .map((bp) => {
  //         return { point: bp, distance: getDistance2D(fp, bp) };
  //       })
  //       .sort((p1, p2) => p1.distance - p2.distance);
  //     drawPoint(bpoints[0], scene);
  //   });
  // }

  // if (faces.length == 1) {
  //   return;
  // }
}

function getCenterLassoFaces(
  box,
  globalMesh,
  globalGraph,
  lassoSegmentsXY,
  lassoSegmentsXZ,
  lassoSegmentsZY
) {
  // let box = {
  //   min: { x: minX, y: minY, z: minZ },
  //   max: { x: maxX, y: maxY, z: maxZ },
  // };
  // box = offsetBox(box);
  // drawPoint(box.center, scene, 0x0000ff, 0.05);
  // drawBox(box, 0xff0000, scene);
  // lassoFaces = getCenterLassoFaces(
  //   box,
  //   globalMesh,
  //   globalGraph,
  //   lassoSegmentsXY,
  //   lassoSegmentsXZ,
  //   lassoSegmentsZY
  // );

  box = offsetBox(box);

  let origin = new THREE.Vector3(
    box.center.x,
    box.center.y,
    box.center.z + 100
  );
  let direction = new THREE.Vector3(0, 0, -1);
  let ioRaycaster = new THREE.Raycaster(origin, direction, 0.1, 100000);
  let intersects = ioRaycaster.intersectObject(globalMesh);
  let centerFaces = [];
  if (intersects.length > 0) {
    // console.log("intersects", intersects);
    let centerPoint = intersects[0].point;
    let pointIndex = getPointIndex(globalGraph, centerPoint);
    let node = globalGraph.getNode(pointIndex);
    centerFaces = node.faces;
  }

  let lassoFaces = [];

  centerFaces.forEach((face) => {
    let crossedSegmentsXY = pointRayCrossesSegments(
      face.midPoint,
      lassoSegmentsXY
    );
    let crossedSegmentsXZ = pointRayCrossesSegments(
      face.midPointXZ,
      lassoSegmentsXZ
    );
    let crossedSegmentsZY = pointRayCrossesSegments(
      face.midPointZY,
      lassoSegmentsZY
    );

    if (
      // (!model.isAdded && crossedSegmentsXY % 2 == 1) ||
      crossedSegmentsXY % 2 == 1 &&
      crossedSegmentsXZ % 2 == 1 &&
      crossedSegmentsZY % 2 == 1
    ) {
      lassoFaces.push(face);
    }
  });

  return lassoFaces;
}

function createNewMesh(faces) {
  if (!faces || !faces.length) return null;

  let faceCount = faces.length;
  const positionsLength = faceCount * 9;
  const positions = new Float32Array(positionsLength);
  let pi = 0;
  const indexLength = faceCount * 3;
  const indexes = new Uint32Array(indexLength);
  let ii = 0;

  faces.forEach((face) => {
    indexes[ii++] = pi / 3;
    positions[pi++] = face.pointA.x;
    positions[pi++] = face.pointA.y;
    positions[pi++] = face.pointA.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.pointB.x;
    positions[pi++] = face.pointB.y;
    positions[pi++] = face.pointB.z;

    indexes[ii++] = pi / 3;
    positions[pi++] = face.pointC.x;
    positions[pi++] = face.pointC.y;
    positions[pi++] = face.pointC.z;
  });

  let newGeometry = new THREE.BufferGeometry();
  let positionsAttr = new THREE.BufferAttribute(positions, 3);
  newGeometry.setAttribute("position", positionsAttr);
  // newGeometry.computeVertexNormals();
  // newGeometry.normalizeNormals();
  // newGeometry.computeBoundingBox();

  const tempGeo = new THREE.Geometry().fromBufferGeometry(newGeometry);
  tempGeo.mergeVertices();
  tempGeo.computeVertexNormals();
  newGeometry = new THREE.BufferGeometry().fromGeometry(tempGeo);

  let material = new THREE.MeshPhongMaterial({
    color: 0x00ff00,
    flatShading: false,
    specular: 0x050505,
    shininess: 200,
    side: 2,
    transparent: true,
  });
  material.polygonOffset = true;
  material.polygonOffsetUnit = 1;
  material.polygonOffsetFactor = 1;

  let mesh = new THREE.Mesh(
    newGeometry,
    material
    // new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true })
  );
  return mesh;
}

function getFacesToFillOld(graph, points) {
  const lassoSegmentsXY = [];
  const lassoSegmentsXZ = [];
  const lassoSegmentsZY = [];
  points = points.filter((p) => p != undefined);
  const pointsLength = points.length;
  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity,
    minZ = Infinity,
    maxZ = -Infinity;
  for (let i = 0; i < pointsLength; i++) {
    let p1 = points[i];
    if (p1.x < minX) minX = p1.x;
    if (p1.y < minY) minY = p1.y;
    if (p1.z < minZ) minZ = p1.z;
    if (p1.x > maxX) maxX = p1.x;
    if (p1.y > maxY) maxY = p1.y;
    if (p1.z > maxZ) maxZ = p1.z;

    // drawSpherePoint(scene, p1, color, 0.01);
    let p2 = points[i + 1];
    if (!p2) p2 = points[0];

    lassoSegmentsXY.push(new THREE.Line3(p1, p2));

    lassoSegmentsXZ.push(
      new THREE.Line3(
        new THREE.Vector2(p1.x, p1.z),
        new THREE.Vector2(p2.x, p2.z)
      )
    );
    lassoSegmentsZY.push(
      new THREE.Line3(
        new THREE.Vector2(p1.z, p1.y),
        new THREE.Vector2(p2.z, p2.y)
      )
    );
  }
  minX -= 1;
  minY -= 1;
  minZ -= 1;
  maxX += 1;
  maxY += 1;
  maxZ += 1;

  let isMidpoint = true;
  // const indices = [];
  const facesToFill = [];
  // const crossObj = {};
  graph.faces.forEach((face) => {
    if (!isFaceInRanges(face, minX, minY, minZ, maxX, maxY, maxZ)) return;
    if (isMidpoint) {
      let crossedSegmentsXZ = pointRayCrossesSegments(
        face.midPointXZ,
        lassoSegmentsXZ
      );
      // if (!crossObj[crossedSegmentsXZ]) crossObj[crossedSegmentsXZ] = 0;
      // crossObj[crossedSegmentsXZ]++;
      // let crossedSegmentsXY = pointRayCrossesSegments(
      //   face.midPoint,
      //   lassoSegmentsXY
      // );
      // let crossedSegmentsZY = pointRayCrossesSegments(
      //   face.midPointZY,
      //   lassoSegmentsZY
      // );
      // let sum =
      //   (crossedSegmentsXY % 2) +
      //   (crossedSegmentsXZ % 2) +
      //   (crossedSegmentsZY % 2);

      // if (crossedSegmentsXY % 2 == 1 && crossedSegmentsXZ % 2 == 1) {
      if (crossedSegmentsXZ % 2 == 1) {
        // if (sum == 3) {
        facesToFill.push(face);
        // indices.push(face.a, face.b, face.c);
        // drawFace(scene, face, color);
      }
    } else {
      for (let j = 0; j < 3; j++) {
        let v = face.points[j];
        v = new THREE.Vector2(v.x, v.z);
        const crossings = pointRayCrossesSegments(v, lassoSegmentsXZ);
        if (crossings % 2 === 1) {
          facesToFill.push(face);
          // indices.push(face.a, face.b, face.c);
          // drawFace(scene, face, color);
        }
      }
    }
  });
  return facesToFill;
}

//#endregion

//#region Debug Draw

function drawFace(scene, face, color, fill) {
  if (!color) {
    color = 0x00ff00;
  }
  // drawThreeLine(scene, face.points[0], face.points[1], color);
  // drawThreeLine(scene, face.points[1], face.points[2], color);
  // drawThreeLine(scene, face.points[2], face.points[0], color);
  // scene.add(drawIoPoint(scene, face.midPoint, color, 0.01));

  let p0 = face.points[0];
  let p1 = face.points[1];
  let p2 = face.points[2];

  let vertices = new Float32Array([
    p0.x,
    p0.y,
    p0.z, // vertex 1
    p1.x,
    p1.y,
    p1.z, // vertex 2
    p2.x,
    p2.y,
    p2.z, // vertex 3
  ]);
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  const material = new THREE.MeshBasicMaterial({ color, wireframe: !fill });
  //  new THREE.MeshPhongMaterial({
  //   color: color,
  //   flatShading: false,
  //   specular: 0x050505,
  //   shininess: 200,
  //   side: 2,
  //   wireframe: false,
  // });
  //new THREE.MeshBasicMaterial({ color });
  const mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);
  tempMeshes.push(mesh);
  return mesh;
}

function drawSpherePoint(scene, point, color, radius) {
  if (point) {
    if (!color) {
      color = 0xff0000;
    }
    if (!radius) {
      radius = 0.1;
    }
    let geometry = new THREE.SphereGeometry(radius, 16, 16); // , 64, 32
    //new THREE.BoxGeometry(0.5, 0.5, 0.5),
    let mesh = new THREE.Mesh(
      geometry,
      new THREE.MeshPhongMaterial({
        color: color,
        flatShading: false,
        specular: 0x050505,
        shininess: 200,
        side: 2,
        // toneMapped: false,
        // depthWrite: false,
        // depthTest: false,
        transparent: true,
      })
      // new THREE.MeshBasicMaterial({ color })
    );
    mesh.position.set(point.x, point.y, point.z);
    scene.add(mesh);
    tempMeshes.push(mesh);
    return mesh;
  }
}

function drawThreeLine(scene, point1, point2, color) {
  if (point1 && point2) {
    if (!color) {
      color = 0xff0000;
    }
    var material = new THREE.LineBasicMaterial({ color: color });
    var geometry = new THREE.Geometry();
    geometry.vertices.push(new THREE.Vector3(point1.x, point1.y, point1.z));
    geometry.vertices.push(new THREE.Vector3(point2.x, point2.y, point2.z));
    geometry.dynamic = true;
    let line = new THREE.Line(geometry, material);
    scene.add(line);
  }
}

function drawLine(point1, point2, color, scene) {
  if (point1 && point2) {
    if (!color) {
      color = 0xff0000;
    }
    var material = new THREE.LineBasicMaterial({ color: color });
    var geometry = new THREE.Geometry();
    geometry.vertices.push(new THREE.Vector3(point1.x, point1.y, point1.z));
    geometry.vertices.push(new THREE.Vector3(point2.x, point2.y, point2.z));
    var line = new THREE.Line(geometry, material);
    scene.add(line);
    tempMeshes.push(line);
  }
}

function drawBox(box, color, scene) {
  if (box) {
    if (!color) {
      color = 0xff0000;
    }
    var minX = box.min.x;
    var minY = box.min.y;
    var minZ = box.min.z;
    var maxX = box.max.x;
    var maxY = box.max.y;
    var maxZ = box.max.z;

    var points = [
      { x: minX, y: maxY, z: minZ },
      { x: maxX, y: maxY, z: minZ },
      { x: maxX, y: maxY, z: maxZ },
      { x: minX, y: maxY, z: maxZ },
      { x: minX, y: maxY, z: minZ },

      { x: minX, y: minY, z: minZ },
      { x: maxX, y: minY, z: minZ },
      { x: maxX, y: maxY, z: minZ },
      { x: maxX, y: minY, z: minZ },

      { x: maxX, y: minY, z: maxZ },
      { x: maxX, y: maxY, z: maxZ },
      { x: maxX, y: minY, z: maxZ },

      { x: minX, y: minY, z: maxZ },
      { x: minX, y: maxY, z: maxZ },
      { x: minX, y: minY, z: maxZ },

      { x: minX, y: minY, z: minZ },
    ];

    points.forEach((point, index) => {
      // drawPoint(point, color);
      if (index < points.length - 1) {
        drawLine(point, points[index + 1], color, scene);
      }
    });
  }
}

function drawPoint(point, scene, color, radius) {
  if (point) {
    if (!color) {
      color = 0x0000ff;
    }
    if (!radius) {
      radius = 0.1;
    }

    //new THREE.BoxGeometry(0.5, 0.5, 0.5),
    const mesh = new THREE.Mesh(
      new THREE.SphereBufferGeometry(radius),
      new THREE.MeshBasicMaterial({ color })
    );
    scene.add(mesh);
    mesh.position.set(point.x, point.y, point.z);
    tempMeshes.push(mesh);
    return mesh;
  }
}

//#endregion

//#region Math

function getDistance(point1, point2) {
  var dx = point1.x - point2.x;
  var dy = point1.y - point2.y;
  var dz = point1.z - point2.z;
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

function getDistance2D(point1, point2) {
  var dx = point1.x - point2.x;
  var dy = point1.y - point2.y;
  return Math.sqrt(dx * dx + dy * dy);
}

function areNearPoints2D(p1, p2, distance) {
  return getDistance2D(p1, p2) < distance;
}

function areEqualFaces(face1, face2) {
  return (
    face1 &&
    face2 &&
    areNearPoints(face1.pointA, face2.pointA) &&
    areNearPoints(face1.pointB, face2.pointB) &&
    areNearPoints(face1.pointC, face2.pointC)
  );
}

function areNearPoints(p1, p2) {
  return isNear(p1.x, p2.x) && isNear(p1.y, p2.y) && isNear(p1.z, p2.z);
}

function isNear(n1, n2) {
  return Math.abs(n1 - n2) < 0.00001;
}

function isFaceInRanges(face, minX, minY, minZ, maxX, maxY, maxZ) {
  return (
    face.pointA.x >= minX &&
    face.pointB.x >= minX &&
    face.pointC.x >= minX &&
    face.pointA.y >= minY &&
    face.pointB.y >= minY &&
    face.pointC.y >= minY &&
    face.pointA.z >= minZ &&
    face.pointB.z >= minZ &&
    face.pointC.z >= minZ &&
    face.pointA.x <= maxX &&
    face.pointB.x <= maxX &&
    face.pointC.x <= maxX &&
    face.pointA.y <= maxY &&
    face.pointB.y <= maxY &&
    face.pointC.y <= maxY &&
    face.pointA.z <= maxZ &&
    face.pointB.z <= maxZ &&
    face.pointC.z <= maxZ
  );
}

function isFaceInRangesXY(face, minX, minY, maxX, maxY) {
  return (
    face.pointA.x >= minX &&
    face.pointB.x >= minX &&
    face.pointC.x >= minX &&
    face.pointA.y >= minY &&
    face.pointB.y >= minY &&
    face.pointC.y >= minY &&
    face.pointA.x <= maxX &&
    face.pointB.x <= maxX &&
    face.pointC.x <= maxX &&
    face.pointA.y <= maxY &&
    face.pointB.y <= maxY &&
    face.pointC.y <= maxY
  );
}

function pointRayCrossesSegments(point, segments) {
  let crossings = 0;
  const firstSeg = segments[segments.length - 1];
  let prevDirection = firstSeg.start.y > firstSeg.end.y;
  for (let s = 0, l = segments.length; s < l; s++) {
    const line = segments[s];
    const thisDirection = line.start.y > line.end.y;
    if (pointRayCrossesLine(point, line, prevDirection, thisDirection)) {
      crossings++;
    }

    prevDirection = thisDirection;
  }
  // if (crossings > 2) {
  // 	console.log(crossings);
  // }
  return crossings;
}

function pointRayCrossesLine(point, line, prevDirection, thisDirection) {
  const { start, end } = line;
  const px = point.x;
  const py = point.y;

  const sy = start.y;
  const ey = end.y;

  if (sy === ey) return false;

  if (py > sy && py > ey) return false; // above
  if (py < sy && py < ey) return false; // below

  const sx = start.x;
  const ex = end.x;
  if (px > sx && px > ex) return false; // right
  if (px < sx && px < ex) {
    // left
    if (py === sy && prevDirection !== thisDirection) {
      return false;
    }

    return true;
  }

  // check the side
  const dx = ex - sx;
  const dy = ey - sy;
  const perpx = dy;
  const perpy = -dx;

  const pdx = px - sx;
  const pdy = py - sy;

  const dot = perpx * pdx + perpy * pdy;

  if (Math.sign(dot) !== Math.sign(perpx)) {
    return true;
  }

  return false;
}

// Math Functions
// https://www.geeksforgeeks.org/convex-hull-set-2-graham-scan/
function getConvexHull(points) {
  function orientation(p, q, r) {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val == 0) {
      return 0; // colinear
    }
    // clockwise or counterclockwise
    return val > 0 ? 1 : 2;
  }

  function distSq(p1, p2) {
    return (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y);
  }

  function compare(p1, p2) {
    // Find orientation
    const o = orientation(p0, p1, p2);
    if (o == 0) return distSq(p0, p2) >= distSq(p0, p1) ? -1 : 1;

    return o == 2 ? -1 : 1;
  }

  // find the lowest point in 2d
  let lowestY = Infinity;
  let lowestIndex = -1;
  for (let i = 0, l = points.length; i < l; i++) {
    const p = points[i];
    if (p.y < lowestY) {
      lowestIndex = i;
      lowestY = p.y;
    }
  }

  // sort the points
  const p0 = points[lowestIndex];
  points[lowestIndex] = points[0];
  points[0] = p0;

  points = points.sort(compare);

  // filter the points
  let m = 1;
  const n = points.length;
  for (let i = 1; i < n; i++) {
    while (i < n - 1 && orientation(p0, points[i], points[i + 1]) == 0) {
      i++;
    }

    points[m] = points[i];
    m++;
  }

  // early out if we don't have enough points for a hull
  if (m < 3) return null;

  // generate the hull
  const hull = [points[0], points[1], points[2]];
  for (let i = 3; i < m; i++) {
    while (
      orientation(hull[hull.length - 2], hull[hull.length - 1], points[i]) !== 2
    ) {
      hull.pop();
    }

    hull.push(points[i]);
  }

  return hull;
}

// https://stackoverflow.com/questions/3838329/how-can-i-check-if-two-segments-intersect
function lineCrossesLine(l1, l2) {
  function ccw(A, B, C) {
    return (C.y - A.y) * (B.x - A.x) > (B.y - A.y) * (C.x - A.x);
  }

  const A = l1.start;
  const B = l1.end;

  const C = l2.start;
  const D = l2.end;

  return ccw(A, C, D) !== ccw(B, C, D) && ccw(A, B, C) !== ccw(A, B, D);
}

function trampoline(fn) {
  // const trampolineSum = trampoline(fillNeighborFaces);
  return function (...args) {
    let result = fn.apply(fn.context, args);
    while (typeof result === "function") {
      result = result();
    }
    return result;
  };
}

//#endregion

//#region Split Geometry 2

function find(parents, x) {
  if (typeof parents[x] != "undefined") {
    if (parents[x] < 0) {
      return x; //x is a parent
    } else {
      //recurse until you find x's parent
      return find(parents, parents[x]);
    }
  } else {
    // initialize this node to it's on parent (-1)
    parents[x] = -1;
    // console.log("init", x)
    return x; //return the index of the parent
  }
}

function union(parents, x, y) {
  var xpar = find(parents, x);
  var ypar = find(parents, y);
  if (xpar != ypar) {
    // x's parent is now the parent of y also.
    // if y was a parent to more than one node, then
    // all of those nodes are now also connected to x's parent.
    parents[xpar] += parents[ypar];
    parents[ypar] = xpar;
    return false;
  } else {
    return true; //this link creates a cycle
  }
}

function split_mesh_islands(check_geom) {
  let parents = [];
  let new_geoms = [];

  for (let i = 0; i < check_geom.index.array.length; i += 3) {
    let v_index = [
      check_geom.index.array[i],
      check_geom.index.array[i + 1],
      check_geom.index.array[i + 2],
    ];
    let tri = new THREE.Triangle();
    tri.a.fromBufferAttribute(check_geom.attributes.position, v_index[0]);
    tri.b.fromBufferAttribute(check_geom.attributes.position, v_index[1]);
    tri.c.fromBufferAttribute(check_geom.attributes.position, v_index[2]);
    // if (isTriDegenerate(tri)) {
    // 	console.warn("isTriDegenerate", i, tri);
    // 	continue;
    // }
    union(parents, check_geom.index.array[i], check_geom.index.array[i + 1]);
    union(
      parents,
      check_geom.index.array[i + 1],
      check_geom.index.array[i + 2]
    );
    union(parents, check_geom.index.array[i], check_geom.index.array[i + 2]);
  }

  for (let i = 0; i < parents.length; i++) {
    parents[i] = find(parents, parents[i]);
  }
  console.log("check_geom", check_geom);
  console.log("parents", check_geom.index.array.length, parents);

  for (let i = 0; i < parents.length; i++) {
    if (parents[i] < 0) {
      console.log("component", i, parents[i]);

      let new_geom = new THREE.BufferGeometry();
      let vertices = [];

      for (let j = 0; j < check_geom.index.array.length; j++) {
        if (
          parents[check_geom.index.array[j]] == i ||
          check_geom.index.array[j] == i
        ) {
          let v_index = check_geom.index.array[j];
          vertices.push(check_geom.attributes.position.getX(v_index));
          vertices.push(check_geom.attributes.position.getY(v_index));
          vertices.push(check_geom.attributes.position.getZ(v_index));
        }
      }

      // next, ignore indexes from the component
      for (let j = 0; j < parents.length; j++) {
        if (j == i || parents[j] == i) {
          parents[j] = parents.length;
        }
      }

      new_geom.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );
      new_geoms.push(new_geom);
    }
  }

  return new_geoms;
}

//#endregion

//#region Split Geometry 1

function unmergeGeometryArray(geometry, indexArray) {
  // Asumptions:
  // geometry is BufferGeometry
  // The geometry has no index duplicates (2 equal positions with different index) neither empty triangles, the geometry has been processed with mergeVertices function
  // normal attribute is discarded, can be recomputed after, only color and position attributes are taken into account

  const material = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    vertexColors: THREE.VertexColors,
  });

  // const indexArray = geometry.index.array;
  const positionArray = geometry.attributes.position.array;
  const positionCount = geometry.attributes.position.count;
  const totalTriangles = indexArray.length / 3;

  let triangleVisitedArray = new Uint8Array(totalTriangles);
  let indexVisitedArray = new Uint8Array(positionCount);
  let indexToTriangleIndexMap = [];

  let missingVertices = positionCount;
  let missingTriangles = totalTriangles;

  // Functions:
  function computeTrianglesRecursive(index, out) {
    //console.log("- start of computeTriangles with index:", index);
    if (
      indexVisitedArray[index] === 1 ||
      missingVertices === 0 ||
      missingTriangles === 0
    ) {
      return;
    }
    indexVisitedArray[index] = 1;
    missingVertices -= 1;
    let triangleIndexArray = indexToTriangleIndexMap[index];
    for (let i = 0; i < indexToTriangleIndexMap[index].length; i++) {
      let triangleIndex = indexToTriangleIndexMap[index][i];
      if (triangleVisitedArray[triangleIndex] === 0) {
        triangleVisitedArray[triangleIndex] = 1;
        missingTriangles -= 1;
        //console.log("-- index: ", index, "; i: ", i, "; triangleIndex: ", triangleIndex);
        out.push(triangleIndex);
        childIndex1 = indexArray[triangleIndex * 3 + 1];
        computeTriangles(childIndex1, out);
        childIndex2 = indexArray[triangleIndex * 3 + 2];
        computeTriangles(childIndex2, out);
      }
    }
  }

  function computeTriangles(indexTocheck) {
    let out = [];
    let startIndex = indexTocheck;
    let indexToCheckArray = [indexTocheck];
    let i = 0;

    while (i < indexToCheckArray.length) {
      let index = indexToCheckArray[i];
      if (indexVisitedArray[index] == 0) {
        indexVisitedArray[index] = 1;
        missingVertices -= 1;
        let triangleIndexArray = indexToTriangleIndexMap[index];
        for (let j = 0; j < indexToTriangleIndexMap[index].length; j++) {
          let triangleIndex = indexToTriangleIndexMap[index][j];
          if (triangleVisitedArray[triangleIndex] === 0) {
            triangleVisitedArray[triangleIndex] = 1;
            missingTriangles -= 1;
            out.push(triangleIndex);
            let rootIndex = indexArray[triangleIndex * 3];
            let child1Index = indexArray[triangleIndex * 3 + 1];
            let child2Index = indexArray[triangleIndex * 3 + 2];

            if (indexToCheckArray.indexOf(rootIndex) === -1) {
              indexToCheckArray.push(rootIndex);
            }

            if (indexToCheckArray.indexOf(child1Index) === -1) {
              indexToCheckArray.push(child1Index);
            }

            if (indexToCheckArray.indexOf(child2Index) === -1) {
              indexToCheckArray.push(child2Index);
            }
          }
        }
      }
      i += 1;
    }
    return out;
  }

  // In the first loop we reorder indices asc order + generate map
  for (let triangleIndex = 0; triangleIndex < totalTriangles; triangleIndex++) {
    const geoIndex1 = indexArray[triangleIndex * 3];
    const geoIndex2 = indexArray[triangleIndex * 3 + 1];
    const geoIndex3 = indexArray[triangleIndex * 3 + 2];
    const triangleIndexVertexArray = [geoIndex1, geoIndex2, geoIndex3].sort(
      function (a, b) {
        return a - b;
      }
    );
    if (indexToTriangleIndexMap[geoIndex1] === undefined) {
      indexToTriangleIndexMap[geoIndex1] = [triangleIndex];
    } else {
      indexToTriangleIndexMap[geoIndex1].push(triangleIndex);
    }
    if (indexToTriangleIndexMap[geoIndex2] === undefined) {
      indexToTriangleIndexMap[geoIndex2] = [triangleIndex];
    } else {
      indexToTriangleIndexMap[geoIndex2].push(triangleIndex);
    }
    if (indexToTriangleIndexMap[geoIndex3] === undefined) {
      indexToTriangleIndexMap[geoIndex3] = [triangleIndex];
    } else {
      indexToTriangleIndexMap[geoIndex3].push(triangleIndex);
    }
    //indexArray[triangleIndex*3] = triangleIndexVertexArray[0];
    //indexArray[triangleIndex*3+1] = triangleIndexVertexArray[1];
    //indexArray[triangleIndex*3+2] = triangleIndexVertexArray[2];
  }

  let geometryTriangleArray = [];
  let index = 0;
  while (
    index < indexToTriangleIndexMap.length &&
    missingVertices > 0 &&
    missingTriangles > 0
  ) {
    let out = [];
    if (indexVisitedArray[index] === 0) {
      out = computeTriangles(index);
    }
    if (out.length > 0) {
      geometryTriangleArray.push(out);
    }
    index += 1;
  }

  let geometryArray = [];
  for (let i = 0; i < geometryTriangleArray.length; i++) {
    let out = {
      positionArray: [],
      colorArray: [],
      indexArray: [],
      indexMap: [],
      currentIndex: 0,
    };
    let triangleArray = geometryTriangleArray[i];

    for (let j = 0; j < triangleArray.length; j++) {
      let triangleIndex = triangleArray[j];
      let rootIndex = indexArray[triangleIndex * 3];
      if (out.indexMap[rootIndex] === undefined) {
        out.indexMap[rootIndex] = out.currentIndex;
        // add vertex position and color
        out.positionArray.push(
          geometry.attributes.position.array[rootIndex * 3],
          geometry.attributes.position.array[rootIndex * 3 + 1],
          geometry.attributes.position.array[rootIndex * 3 + 2]
        );
        if (geometry.attributes.color != undefined) {
          out.colorArray.push(
            geometry.attributes.color.array[rootIndex * 3],
            geometry.attributes.color.array[rootIndex * 3 + 1],
            geometry.attributes.color.array[rootIndex * 3 + 2]
          );
        }
        out.currentIndex += 1;
      }

      let child1Index = indexArray[triangleIndex * 3 + 1];
      if (out.indexMap[child1Index] === undefined) {
        out.indexMap[child1Index] = out.currentIndex;
        // add vertex position and color
        out.positionArray.push(
          geometry.attributes.position.array[child1Index * 3],
          geometry.attributes.position.array[child1Index * 3 + 1],
          geometry.attributes.position.array[child1Index * 3 + 2]
        );
        if (geometry.attributes.color != undefined) {
          out.colorArray.push(
            geometry.attributes.color.array[child1Index * 3],
            geometry.attributes.color.array[child1Index * 3 + 1],
            geometry.attributes.color.array[child1Index * 3 + 2]
          );
        }

        out.currentIndex += 1;
      }

      let child2Index = indexArray[triangleIndex * 3 + 2];
      if (out.indexMap[child2Index] === undefined) {
        out.indexMap[child2Index] = out.currentIndex;
        // add vertex position and color
        out.positionArray.push(
          geometry.attributes.position.array[child2Index * 3],
          geometry.attributes.position.array[child2Index * 3 + 1],
          geometry.attributes.position.array[child2Index * 3 + 2]
        );
        if (geometry.attributes.color != undefined) {
          out.colorArray.push(
            geometry.attributes.color.array[child2Index * 3],
            geometry.attributes.color.array[child2Index * 3 + 1],
            geometry.attributes.color.array[child2Index * 3 + 2]
          );
        }

        out.currentIndex += 1;
      }

      // Add indices:
      out.indexArray.push(
        out.indexMap[rootIndex],
        out.indexMap[child1Index],
        out.indexMap[child2Index]
      );
    }

    const geoPositions = new Float32Array(out.positionArray);
    const geoColors = new Float32Array(out.colorArray);
    const geoIndices = new Uint32Array(out.indexArray);
    const geo = new THREE.BufferGeometry();
    geo.name = "G_" + i;
    geo.index = new THREE.BufferAttribute(geoIndices, 1, false);
    geo.attributes.position = new THREE.BufferAttribute(geoPositions, 3, false);
    geo.attributes.color = new THREE.BufferAttribute(geoColors, 3, true);
    geo.computeBoundingSphere();
    geo.computeBoundingBox();
    geometryArray.push(geo);
  }
  return geometryArray;
}

function groupGeometryIntoNonConnectedGeometries(geometry, indexArray) {
  const material = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    vertexColors: THREE.VertexColors,
  });
  let geometryArray = [];
  // const indexArray = geometry.index.array;
  const positionArray = geometry.attributes.position.array;
  const positionCount = geometry.attributes.position.count;
  // const color = new THREE.Vector3(
  //   geometry.attributes.color.array[0],
  //   geometry.attributes.color.array[1],
  //   geometry.attributes.color.array[2]
  // );
  const totalTriangles = indexArray.length / 3;
  let geometryCount = 0;
  let indexValueAlreadyVisited = new Uint8Array(indexArray.length);
  let structure = [];
  /*
   * indexValue: {
   *  child: [ [indexval0, indexval1], [] ],
   *  parent: null
   * }
   */
  // Initialize Structure:
  for (var vextexIdx = 0; vextexIdx < positionCount; vextexIdx++) {
    structure[vextexIdx] = {
      child: [],
      parent: null,
    };
  }

  for (var idx = 0; idx < totalTriangles; idx++) {
    const geoIndex1 = indexArray[idx * 3];
    const geoIndex2 = indexArray[idx * 3 + 1];
    const geoIndex3 = indexArray[idx * 3 + 2];
    const triangleIndexVertexArray = [geoIndex1, geoIndex2, geoIndex3].sort(
      function (a, b) {
        return a - b;
      }
    );
    //structure[ triangleIndexVertexArray[0] ].child.push(triangleIndexVertexArray[1], triangleIndexVertexArray[2]);
    //structure[ triangleIndexVertexArray[1] ].parent = triangleIndexVertexArray[0];
    //structure[ triangleIndexVertexArray[2] ].parent = triangleIndexVertexArray[0];
    structure[triangleIndexVertexArray[0]].child.push(
      triangleIndexVertexArray[1],
      triangleIndexVertexArray[2]
    );
    if (structure[triangleIndexVertexArray[1]].parent == null) {
      structure[triangleIndexVertexArray[1]].parent =
        triangleIndexVertexArray[0];
    }
    if (structure[triangleIndexVertexArray[2]].parent == null) {
      structure[triangleIndexVertexArray[2]].parent =
        triangleIndexVertexArray[0];
    }
    //if structure[ triangleIndexVertexArray[0] ].parent == null --> check children which parent is not itself
    if (structure[triangleIndexVertexArray[0]].parent == null) {
      for (
        var childIdx = 0;
        childIdx < structure[triangleIndexVertexArray[0]].child.length;
        childIdx++
      ) {
        let childIndex = structure[triangleIndexVertexArray[0]].child[childIdx];
        if (structure[childIndex].parent != triangleIndexVertexArray[0]) {
          structure[triangleIndexVertexArray[0]].parent =
            structure[childIndex].parent;
          break;
        }
      }
    }
  }

  console.log(structure);

  let count = 0;
  let currentCount = 0;
  let geometryStructureArray = [];

  for (let strIdx = 0; strIdx < structure.length; strIdx++) {
    if (
      structure[strIdx].parent == null ||
      geometryStructureArray[currentCount] == undefined
    ) {
      currentCount = count;
      geometryStructureArray[currentCount] = {
        name: "G_" + currentCount,
        indexMap: {},
        currentIndex: 0,
        indexArray: [],
        positionArray: [],
        colorArray: [],
        geoColor: [Math.random(), Math.random(), Math.random()],
      };
      count += 1;
    }
    if (structure[strIdx].child.length > 0) {
      const childLen = structure[strIdx].child.length / 2;
      for (let childIdx = 0; childIdx < childLen; childIdx++) {
        if (structure[strIdx] != undefined) {
          const vertexIndex0 = strIdx;
          const vertexIndex1 = structure[strIdx].child[childIdx * 2];
          const vertexIndex2 = structure[strIdx].child[childIdx * 2 + 1];
          const v0 = new THREE.Vector3(
            positionArray[strIdx * 3],
            positionArray[strIdx * 3 + 1],
            positionArray[strIdx * 3 + 2]
          );
          const v1 = new THREE.Vector3(
            positionArray[vertexIndex1 * 3],
            positionArray[vertexIndex1 * 3 + 1],
            positionArray[vertexIndex1 * 3 + 2]
          );
          const v2 = new THREE.Vector3(
            positionArray[vertexIndex2 * 3],
            positionArray[vertexIndex2 * 3 + 1],
            positionArray[vertexIndex2 * 3 + 2]
          );

          // check vertex0
          if (
            geometryStructureArray[currentCount].indexMap[vertexIndex0] ==
            undefined
          ) {
            geometryStructureArray[currentCount].indexMap[vertexIndex0] =
              geometryStructureArray[currentCount].currentIndex;
            geometryStructureArray[currentCount].indexArray.push(
              geometryStructureArray[currentCount].currentIndex
            );
            geometryStructureArray[currentCount].positionArray.push(
              v0.x,
              v0.y,
              v0.z
            );
            //geometryStructureArray[currentCount].colorArray.push(color.x, color.y, color.z);
            geometryStructureArray[currentCount].colorArray.push(
              geometryStructureArray[currentCount].geoColor[0],
              geometryStructureArray[currentCount].geoColor[1],
              geometryStructureArray[currentCount].geoColor[2]
            );
            geometryStructureArray[currentCount].currentIndex += 1;
          } else {
            geometryStructureArray[currentCount].indexArray.push(
              geometryStructureArray[currentCount].indexMap[vertexIndex0]
            );
          }

          // check vertex1
          if (
            geometryStructureArray[currentCount].indexMap[vertexIndex1] ==
            undefined
          ) {
            geometryStructureArray[currentCount].indexMap[vertexIndex1] =
              geometryStructureArray[currentCount].currentIndex;
            geometryStructureArray[currentCount].indexArray.push(
              geometryStructureArray[currentCount].currentIndex
            );
            geometryStructureArray[currentCount].positionArray.push(
              v1.x,
              v1.y,
              v1.z
            );
            //geometryStructureArray[currentCount].colorArray.push(color.x, color.y, color.z);
            geometryStructureArray[currentCount].colorArray.push(
              geometryStructureArray[currentCount].geoColor[0],
              geometryStructureArray[currentCount].geoColor[1],
              geometryStructureArray[currentCount].geoColor[2]
            );
            geometryStructureArray[currentCount].currentIndex += 1;
          } else {
            geometryStructureArray[currentCount].indexArray.push(
              geometryStructureArray[currentCount].indexMap[vertexIndex1]
            );
          }

          // check vertex1
          if (
            geometryStructureArray[currentCount].indexMap[vertexIndex2] ==
            undefined
          ) {
            geometryStructureArray[currentCount].indexMap[vertexIndex2] =
              geometryStructureArray[currentCount].currentIndex;
            geometryStructureArray[currentCount].indexArray.push(
              geometryStructureArray[currentCount].currentIndex
            );
            geometryStructureArray[currentCount].positionArray.push(
              v2.x,
              v2.y,
              v2.z
            );
            //geometryStructureArray[currentCount].colorArray.push(color.x, color.y, color.z);
            geometryStructureArray[currentCount].colorArray.push(
              geometryStructureArray[currentCount].geoColor[0],
              geometryStructureArray[currentCount].geoColor[1],
              geometryStructureArray[currentCount].geoColor[2]
            );
            geometryStructureArray[currentCount].currentIndex += 1;
          } else {
            geometryStructureArray[currentCount].indexArray.push(
              geometryStructureArray[currentCount].indexMap[vertexIndex2]
            );
          }
        }
      }
    }
  }

  // Convert to geometryArray:
  const geometryStructureArrayLen = geometryStructureArray.length;
  const object3d = new THREE.Object3D();

  for (let geoIdx = 0; geoIdx < geometryStructureArrayLen; geoIdx++) {
    const geo = new THREE.BufferGeometry();
    geo.name = "G_" + geoIdx;
    const geoPositions = new Float32Array(
      geometryStructureArray[geoIdx].positionArray
    );
    const geoColors = new Float32Array(
      geometryStructureArray[geoIdx].colorArray
    );
    const geoIndices = new Uint32Array(
      geometryStructureArray[geoIdx].indexArray
    );
    //console.log(geoIdx, "geoPositions: ", geoPositions);
    //console.log(geoIdx, "geoColors: ", geoColors);
    //console.log(geoIdx, "geoIndices: ", geoIndices);
    geo.index = new THREE.BufferAttribute(geoIndices, 1, false);

    geo.attributes.position = new THREE.BufferAttribute(geoPositions, 3, false);
    geo.attributes.color = new THREE.BufferAttribute(geoColors, 3, true);
    geo.computeBoundingSphere();
    geo.computeBoundingBox();

    const mesh = new THREE.Mesh(geo, material);
    mesh.name = "M_" + geoIdx;
    object3d.add(mesh);
  }
  return [structure, geometryStructureArray, object3d, count];
  //return object3d;
}

//#endregion

//#region Export
export {
  getFacesToFill,
  getFacesGeometry,
  drawPoint,
  fillArea,
  areNearPoints,
  clearTempMeshes,
  getDistance,
  removeBorderPointsFaces,
  drawSpherePoint,
  drawBox,
  drawLine
};

//#endregion
