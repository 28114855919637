function focusToObject(objectPosition, cameraPosition) {
	if (objectPosition && cameraPosition) {
		var target = new THREE.Vector3(objectPosition.x, objectPosition.y, objectPosition.z);
		viewer.camera.lookAt(target);
		viewer.controls.target = target;
		viewer.camera.position.x = cameraPosition.x;
		viewer.camera.position.y = cameraPosition.y;
		viewer.camera.position.z = cameraPosition.z;
		viewer.camera.updateProjectionMatrix();
		viewer.controls.update();

		implantPlane.position.copy(objectPosition);
		implantPlane.lookAt(viewer.camera.position);
	}
}

function moveObject(objectId, dx, dy) {
	if (objectId) {
		//var canvasTop = $("#stl_cont").offset().top;
		var canvasWidth = viewer.renderer.domElement.offsetWidth;
		var canvasHalfWidth = canvasWidth / 2;
		var canvasHeight = viewer.renderer.domElement.offsetHeight;
		var canvasHalfHeight = canvasHeight / 2;

		var mesh = viewer.get_model_mesh(objectId);
		var centerPosition = new THREE.Vector3(mesh.position.x, mesh.position.y, mesh.position.z);
		centerPosition = centerPosition.project(viewer.camera);
		centerPosition.x = centerPosition.x * canvasHalfWidth + canvasHalfWidth;
		centerPosition.y = -(centerPosition.y * canvasHalfHeight) + canvasHalfHeight;

		var mouseX = (centerPosition.x + dx) / canvasWidth * 2 - 1;
		var mouseY = -((centerPosition.y + dy) / canvasHeight) * 2 + 1;

		var vector = new THREE.Vector3(mouseX, mouseY, 0.5);
		vector.unproject(viewer.camera);

		var direction = vector.sub(viewer.camera.position).normalize();
		var raycaster = new THREE.Raycaster(
			viewer.camera.position,
			direction,
			0.1,
			100000
		);

		var intersects = raycaster.intersectObject(implantPlane);
		if (intersects.length > 0) {
			var point = intersects[0].point;
			mesh.position.copy(point);
			implantPlane.position.copy(point);
			implantPlane.lookAt(viewer.camera.position);

			//var implantMoves = $(".implant-move");
			//implantMoves.each(function() {
			//	$(this).css({
			//		left: `${$(this).position().left + dx}px`,
			//		top: `${$(this).position().top + dy}px`
			//	});
			//});
		}
	}
}

function rotateClock(objectId) {
	if (objectId) {
		var mesh = viewer.get_model_mesh(objectId);
		var axis = new THREE.Vector3();
		axis.subVectors(viewer.camera.position, mesh.position).normalize();
		mesh.rotateOnWorldAxis(axis, -0.03);
	}
}

function rotateCounter(objectId) {
	if (objectId) {
		var mesh = viewer.get_model_mesh(objectId);
		var axis = new THREE.Vector3();
		axis.subVectors(viewer.camera.position, mesh.position).normalize();
		mesh.rotateOnWorldAxis(axis, 0.03);
	}
}

function rotateClockAxisY(objectId) {
	rotateAroundAxisY(objectId, 0.03);
}

function rotateCounterAxisY(objectId) {
	rotateAroundAxisY(objectId, -0.03);
}

function rotateAroundAxisY(objectId, angle, mesh) {
	if (objectId) {
		if (!mesh) {
			mesh = viewer.get_model_mesh(objectId);
		}
		var axis = new THREE.Vector3();
		var start = mesh.position;
		var end = new THREE.Vector3(mesh.position.x, mesh.position.y + 50, mesh.position.z);
		axis.subVectors(start, end).normalize();
		//mesh.rotateOnWorldAxis(axis, angle);
		//rotateAboutPoint(mesh, mesh.position, axis, angle);
		mesh.rotateOnAxis(axis, angle);
		return axis;
	}
}

// obj - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// angle - radian value of rotation
// pointIsWorld - boolean indicating the point is in world coordinates (default = false)
function rotateAboutPoint(obj, point, axis, angle, pointIsWorld) {
	pointIsWorld = (pointIsWorld === undefined) ? false : pointIsWorld;

	if (pointIsWorld) {
		obj.parent.localToWorld(obj.position); // compensate for world coordinate
	}

	obj.position.sub(point); // remove the offset
	obj.position.applyAxisAngle(axis, angle); // rotate the POSITION
	obj.position.add(point); // re-add the offset

	if (pointIsWorld) {
		obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
	}

	obj.rotateOnAxis(axis, angle); // rotate the OBJECT

	// var geometry = obj.geometry.clone();
	// geometry.applyMatrix4(obj.matrix);
	// console.log(geometry);
}

const scaleStep = 1.05;

function changeHeight(objectId, step) {
	if (objectId) {
		var mesh = viewer.get_model_mesh(objectId);
		var stl = mesh; //.children[0];
		stl.geometry.scale(1, step, 1);
		var object = vueApp.getItemById(objectId);
		if (object)
			object.currentHeightScale = object.currentHeightScale * step;
		//stl.position.set(-diameter / 2, -length / 2, -diameter / 2);
	}
}

function increaseHeight(objectId) {
	changeHeight(objectId, scaleStep);
}

function decreaseHeight(objectId) {
	changeHeight(objectId, 1 / scaleStep);
}

function changeFrontBack(objectId, step) {
	if (objectId) {
		var mesh = viewer.get_model_mesh(objectId);
		var stl = mesh;//.children[0];
		stl.geometry.scale(step, 1, 1);
		var object = vueApp.getItemById(objectId);
		if (object)
			object.currentBuccalScale = object.currentBuccalScale * step;
	}
}

function increaseFrontBack(objectId) {
	changeFrontBack(objectId, scaleStep);
}

function decreaseFrontBack(objectId) {
	changeFrontBack(objectId, 1 / scaleStep);
}

function changeLeftRight(objectId, step) {
	if (objectId) {
		var mesh = viewer.get_model_mesh(objectId);
		var stl = mesh;//.children[0];
		stl.geometry.scale(1, 1, step);
		var object = vueApp.getItemById(objectId);
		if (object)
			object.currentMessialScale = object.currentMessialScale * step;
	}
}

function increaseLeftRight(objectId) {
	changeLeftRight(objectId, scaleStep);
}

function decreaseLeftRight(objectId) {
	changeLeftRight(objectId, 1 / scaleStep);
}

function changeBuccal(objectId, step) {
	var object = vueApp.getItemById(objectId);
	if (object) {
		var mesh = viewer.get_model_mesh(objectId);
		var stl = mesh;//.children[0];
		stl.scale.z *= step;

		object.currentBuccalScale = object.currentBuccalScale * step;
	}
}

function increaseBuccal(objectId) {
	changeBuccal(objectId, scaleStep);
}

function decreaseBuccal(objectId) {
	changeBuccal(objectId, 1 / scaleStep);
}

function changeMesial(objectId, step) {
	var object = vueApp.getItemById(objectId);
	if (object) {
		var mesh = viewer.get_model_mesh(objectId);
		var stl = mesh;//.children[0];
		//var angle = object.rotateAngle;

		var point4 = new THREE.Vector3(mesh.position.x, mesh.position.y + 10, mesh.position.z);
		var axis = new THREE.Vector3();
		axis.subVectors(mesh.position, point4).normalize();
		//stl.rotateOnWorldAxis(axis, -angle);

		stl.geometry.scale(step, 1, 1);
		if (stl.defaultMesh)
			stl.defaultMesh.geometry.scale(step, 1, 1);
		//stl.rotateOnWorldAxis(axis, angle);
		object.currentMessialScale = object.currentMessialScale * step;
	}
}

function increaseMesial(objectId) {
	changeMesial(objectId, scaleStep);
}

function decreaseMesial(objectId) {
	changeMesial(objectId, 1 / scaleStep);
}

function positiveZero(input) {
	return input == "-0.0" ? "0.0" : input;
}

function radians_to_degrees(radians) {
	var pi = Math.PI;
	return radians * (180 / pi);
}

function getMouse3dDirection(event) {
	var direction = null;
	if (event && event.pageX && event.pageY) {
		var overlayRect = stl_cont.getBoundingClientRect();
		var x = event.pageX - overlayRect.left;
		var y = event.pageY - overlayRect.top;
		direction = new THREE.Vector3(
			(x / overlayRect.width) * 2 - 1,
			-(y / overlayRect.height) * 2 + 1,
			0.5
		);
		direction.unproject(viewer.camera);
		direction.sub(viewer.camera.position);
		direction.normalize();
	}

	return direction;
}

function updateToothBeforeMove(tooth, oldPosition, newPosition) {
	if (tooth) {
		// var dx = newPosition.x - oldPosition.x;
		// var dy = newPosition.y - oldPosition.y;
		// var dz = newPosition.z - oldPosition.z;

		// tooth.root = new THREE.Vector3(tooth.root.x + dx, tooth.root.y + dy, tooth.root.z + dz);
		// tooth.apex = new THREE.Vector3(tooth.apex.x + dx, tooth.apex.y + dy, tooth.apex.z + dz);
	}
}

function updateMeshGeometryAndCenter(mesh) {
	if (mesh) {
		mesh.updateMatrix();
        mesh.geometry.applyMatrix4(mesh.matrix);
        mesh.position.set(0, 0, 0);
        mesh.rotation.set(0, 0, 0);
        mesh.scale.set(1, 1, 1);
        mesh.geometry.center();
        mesh.updateMatrix();
	}
}

export {
	focusToObject, moveObject, rotateClock, rotateCounter, rotateClockAxisY, rotateCounterAxisY, rotateAboutPoint, rotateAroundAxisY,
	increaseHeight, decreaseHeight, increaseFrontBack, decreaseFrontBack, increaseLeftRight, decreaseLeftRight, updateToothBeforeMove,
	increaseBuccal, decreaseBuccal, increaseMesial, decreaseMesial, positiveZero, radians_to_degrees, getMouse3dDirection, updateMeshGeometryAndCenter
};