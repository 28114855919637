import HelpDialog from "./HelpDialog.vue";

var helpControlsInitialized = false;

function showHelpDialog() {
    if (!helpControlsInitialized) {
        var ctrls = [HelpDialog];
        ctrls.forEach(component => {
            const componentInstance = new Vue({ ...component, propsData: { mode: null } });
            const vueContainer = document.createElement('div');
            document.getElementById('main_div').appendChild(vueContainer);
            componentInstance.$mount(vueContainer);
        });

        helpControlsInitialized = true;
    }
    $("#helpDialog").modal();
}

export { showHelpDialog };