<template>
    <div class="dicom-annotation-panel">
        <div class="dicom-annotation-settings">
            <li class="list-group-item dicom-setting-item active">Settings</li>
            <li class="list-group-item dicom-setting-item">
                <label class="dicom-ctrl mt-1">
                    <span class="dicom-check-label">Fill segmentations:</span>
                    <input type="checkbox" class="dicom-check-input" v-model="fillSegmentations"
                        @change="fillSegmentationsChanged()">
                </label>
                <div class="dicom-ctrl mt-1">
                    <span class="dicom-check-label">Opacity:</span>
                    <input type="range" class="dicom-slider-input" v-model="opacity.value" :min="opacity.min"
                        :max="opacity.max" :step="opacity.step" @mouseup="opacityChanged()">
                </div>
            </li>
        </div>
        <button type="button" class="btn btn-primary btn-sm btn-block dicom-add-btn" v-on:click="addAnnotation">Add
            Tooth
        </button>
        <div class="dicom-annotation-list" ref="dicomAnnotationList"></div>
        <button type="button" class="btn btn-primary btn-sm btn-block dicom-exit-btn" v-on:click="exitAnnotation">Exit
            Annotation</button>
    </div>
</template>

<script>
import { hexToCanvasColor } from "./annotation";
import { closeViewer } from "./DicomManager";
import AddDialog from "./AddDialog.vue";

let controlsInitialized = false;
let ToothAddContext = {
    newTooth: {
        number: 0,
        numberType: "primary",
        remarks: "",
        smooth: true,
    },
};

export default {
    name: "DicomAnnotationPanel",
    data() {
        return {
            fillSegmentations: true,
            opacity: {
                value: 0.5,
                min: 0.2,
                max: 0.8,
                step: 0.01,
                default: 0.5
            },
        };
    },
    props: {
        app: {
            type: Object,
            required: false
        }
    },
    mounted() {
        this.app.apanelId = `${this.app.orientation}_apanel`;
        this.$refs.dicomAnnotationList.setAttribute("id", this.app.apanelId);
    },
    methods: {
        fillSegmentationsChanged: function () {
            this.app.annotationFillSegmentations = this.fillSegmentations;
            // console.log("fillSegmentationsChanged", this.fillSegmentations);
            let annotations = [];
            annotations = annotations.concat(this.app.annotations);
            this.app.annotations.forEach(a => {
                annotations = annotations.concat(a.childAnnotations);
            });
            annotations.forEach(annotation => {
                if (!annotation)
                    return;
                annotation.fillSegmentations = this.fillSegmentations;
                annotation.figures.forEach((figure) => {
                    if (!figure.spline)
                        return;
                    if (this.fillSegmentations) {
                        figure.spline.getChildren()[0].fillEnabled(true);
                    }
                    else {
                        figure.spline.getChildren()[0].fillEnabled(false);
                    }
                });
            });
        },
        opacityChanged: function () {
            this.app.annotationOpacity = this.opacity.value;
            // console.log("opacityChanged", this.opacity.value);

            let annotations = [];
            annotations = annotations.concat(this.app.annotations);
            this.app.annotations.forEach(a => {
                annotations = annotations.concat(a.childAnnotations);
            });

            annotations.forEach(annotation => {
                let color = hexToCanvasColor(annotation.color, this.opacity.value);
                annotation.opacityColor = color;
                annotation.figures.forEach((figure) => {
                    if (figure.spline) {
                        let spline = figure.spline.getChildren()[0];
                        spline.fill(color);
                        spline.stroke(color);
                    }
                });
            });
        },
        addAnnotation: function () {
            // console.log("addAnnotation");
            if (!controlsInitialized) {
                var ctrls = [AddDialog];
                ctrls.forEach((component) => {
                    const componentInstance = new Vue({
                        ...component,
                        propsData: { mode: ToothAddContext, app: this.app },
                    });
                    const vueContainer = document.createElement("div");
                    document.getElementById("views_cont").appendChild(vueContainer);
                    componentInstance.$mount(vueContainer);
                });
                controlsInitialized = true;
            }

            $("#annotationAddDialog").modal();
        },
        exitAnnotation: function () {
            // console.log("exitAnnotation");
            closeViewer(this.app);
        }
    }
}

</script>

<style scoped>
.dicom-annotation-panel {
    height: 100%;
    width: 100%;
    background-color: black;
}

.dicom-annotation-settings {
    /* background-color: orange; */
    height: 100px;
    width: calc(100% - 5px);
}

.dicom-add-btn {
    margin: 2px 5px 8px 5px;
    width: calc(100% - 15px);
    /* padding: 0; */
}

.dicom-annotation-list {
    /* background-color: green; */
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 186px);
}

.dicom-exit-btn {
    margin: 8px 5px 0 5px;
    width: calc(100% - 15px);
    /* padding: 0; */
}

.dicom-setting-item {
    text-align: left;
    padding: .3rem .4rem;
}

.dicom-setting-btn {
    margin: 5px 0;
    padding: 0;
}

.dicom-ctrl {
    text-align: left;
    display: block;
    white-space: nowrap;
    margin: 0;
}

.dicom-check-label {
    margin-right: 0.5rem;
    vertical-align: middle;
}

.dicom-check-input {
    /* margin-top: 20px; */
    vertical-align: middle;
}

.dicom-slider-input {
    /* margin-top: 20px; */
    vertical-align: middle;
    width: calc(100% - 65px);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #007bff;
}
</style>