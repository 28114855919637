import axios from "@/api/axios";

// const addTooth = (newTooth) => {
//   let formData = new FormData();
//   formData.append("toothFile", newTooth.file);
//   const response = axios({
//     method: "POST",
//     url: "/api/tooth/add",
//     data: formData,
//     headers: {
//       "Content-Type": "multipart/form-data; boundary=${form._boundary}",
//     },
//   });
//   return axios.get(apiUrl);
// };

async function sendAddToothRequest(tooth) {
  console.log("sendAddToothRequest", tooth);
  if (tooth && tooth.file) {
    let url = "/api/tooth/add";
    let formData = new FormData();
    formData.append("file", tooth.file);
    formData.append("patientId", vueApp.patientId);
    formData.append("s3Path", vueApp.stlBaseUrl);
    formData.append("number", tooth.number);
    formData.append("numberType", tooth.numberType);
    formData.append("remarks", tooth.remarks);
    formData.append("smooth", false); //tooth.smooth
    var config = {};
    config.headers = {
      "content-type": "multipart/form-data",
    }; // ; boundary=${form._boundary}

    let response =  null;
    try {
      response = await axios.post(url, formData, config);
    } catch (e) {
      console.log("sendAddToothRequest", e);
    }
    return response;
  }
}

async function sendSmoothStlRequest(file, factor, isLayered) {
  console.log("sendSmoothStlRequest", factor, isLayered);
  if (file) {
    let url = "/api/tooth/smoothStl";
    let formData = new FormData();
    formData.append("file", file);
    if (factor) formData.append("factor", factor);
    formData.append('isLayered', !!isLayered);

    let config = {
      responseType: "blob",
    };
    config.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "content-type": "multipart/form-data",
    }; // ; boundary=${form._boundary}

    const response = await axios.post(url, formData, config);

    return response;
  }
}

async function sendUpdateTeethRequest(blob) {
  let url = "/api/tooth/updateTeeth";
  let formData = new FormData();
  formData.append("file", blob, `teeth_${vueApp.patientId}.zip`);
  formData.append("patientId", vueApp.patientId);
  formData.append("s3Path", vueApp.stlBaseUrl);
  var config = {};
  config.headers = {
    "content-type": "multipart/form-data",
  }; // ; boundary=${form._boundary}

  const response = await axios.post(url, formData, config);
  return response;
}

async function sendRemoveTeethRequest(teeth) {
  if (teeth && teeth.length) {
    let url = "/api/tooth/removeTeeth";
    let filenames = teeth.map((x) => x.filename);
    console.log("sendRemoveTeethRequest request", filenames);
    let data = {
      patientId: vueApp.patientId,
      s3Path: vueApp.stlBaseUrl,
      filenames: filenames,
    };
    var stringData = JSON.stringify(data);
    var config = {};
    config.headers = {
      "content-type": "application/json",
    };
    const response = await axios.post(url, stringData, config);
    console.log("sendRemoveTeethRequest response", response);
    return response;
  }
}

export {
  sendAddToothRequest,
  sendSmoothStlRequest,
  sendRemoveTeethRequest,
  sendUpdateTeethRequest,
};
