const cloudApiTimeout = 3000;
let authenticationToken = null;


function getAuthenticationToken(callBack) {
	//var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
	//var tokenUrl = "https://qa.cephx.com/cephx/token/createStlViewerToken";
	var tokenUrl = `${vueApp.apiPath}/cephx/token/createStlViewerToken`;
	fetch(tokenUrl, {
		timeout: cloudApiTimeout,
		// mode: 'no-cors', // no-cors, *cors, same-origin
		cache: 'no-cache' // *default, no-cache, reload, force-cache, only-if-cached
	})
		.then((response) => {
			response.text().then((text) => {
				authenticationToken = text;
				if (!authenticationToken)
					console.error('getAuthenticationToken Error: empty response');
				if (callBack)
					callBack(authenticationToken);
			});
		}).catch((error) => {
			console.error('getAuthenticationToken Error: ', error);
			if (callBack)
				callBack(authenticationToken);
		});

}

function getSavedViewerSettings(callBack) {
	if(vueApp.viewerServerUrl.includes("localhost")) {
		if (callBack)
			callBack(null);
		return;
	}

	if (!authenticationToken) {
		getAuthenticationToken((token) => {
			if (authenticationToken)
				getSavedViewerSettings(callBack);
			else if (callBack)
				callBack(null);
		});
	} else {
		var patientId = vueApp.patientId;
		var getUrl = `${vueApp.apiPath}/cephx/getSavedViewerSettings?authToken=${authenticationToken}&patientId=${patientId}`;
		fetch(getUrl, {
			timeout: cloudApiTimeout,
			cache: 'no-cache'
		})
			.then((response) => {
				response.text().then((text) => {
					var jsonObj = null;
					if (!text)
						console.error('getSavedViewerSettings Error: empty response');
					else {
						try {
							var json = b64_to_utf8(text);
							jsonObj = JSON.parse(json);
						}
						catch (e) {
							console.error('getSavedViewerSettings Error: invalid response');
						}
					}
					if (callBack)
						callBack(jsonObj);
				});
			}).catch((error) => {
				console.error('getSavedViewerSettings Error: ', error);
				if (callBack)
					callBack(null);
			});
	}
}

function sendViewerData(authToken, patientId, saved) {
	//var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
	//var uploadUrl = "https://qa.cephx.com/cephx/uploadViewerSettings";
	var uploadUrl = `${vueApp.apiPath}/cephx/uploadViewerSettings`;
	let formData = new FormData();
	formData.append('authToken', authToken);
	formData.append('patientId', patientId); // 308827
	formData.append('saved', saved);

	fetch(uploadUrl,
		{
			body: formData,
			method: "post"
		})
		.then((response) => {
			return response.text();
		})
		.then((text) => {
			stlLogMessage('Success uploadViewerSettings: ', text);
		})
		.catch((error) => {
			console.log('Error uploadViewerSettings: ', error);
		});
}

function saveViewerData() {
	if (vueApp.isViewMode)
		return;
	var patientId = vueApp.patientId;
	var data = vueApp.savedData;
	//console.log(data);
	var stringData = JSON.stringify(data);
	var saved = utf8_to_b64(stringData); //"ewoibmFtZSIgOiAiSm9obiIKfQ==";
	if (!authenticationToken) {
		getAuthenticationToken((token) => {
			if (token)
				sendViewerData(token, patientId, saved);
		});
	} else {
		sendViewerData(authenticationToken, patientId, saved);
	}
}

function loadViewerData() {
	if (vueApp.zipFile) {
		var filename = vueApp.zipFile.split('/').pop();
		var savedJson = vueApp.zipFile.replace(filename, "saved.json");

		fetch(savedJson, {
			cache: 'no-cache' // *default, no-cache, reload, force-cache, only-if-cached
		})
			.then((response) => {
				//console.log(response);
				return response.json();
			})
			.then((json) => {
				if (json) {
					updateZipsForSourceType(json.viewSourceType);

					if (json.clearCache) {
						vueApp.clearCache = true;
						console.log('clearCache from savedData');
					}

					if (isValidSavedData(json)) {
						vueApp.savedData = json;
						vueApp.savedData.cameraPosition = new THREE.Vector3(
							vueApp.savedData.cameraPosition.x,
							vueApp.savedData.cameraPosition.y,
							vueApp.savedData.cameraPosition.z);
						if (vueApp.savedData.cameraTarget) {
							var vc = vueApp.savedData.cameraTarget;
							vueApp.savedData.cameraTarget = new THREE.Vector3(vc.x, vc.y, vc.z);
						}
						vueApp.savedData.isLoaded = true;
					}

				}
				//console.log(vueApp.savedData);
			})
			.catch((error) => {
				console.log(`Load saved.json error: ${error}`);
			});
	}
}

function loadViewerDataFromApi(callback) {
	getSavedViewerSettings(
		(json) => {
			if (json) {
				updateZipsForSourceType(json.viewSourceType);
				if (json.clearCache) {
					vueApp.clearCache = true;
					console.log('clearCache from savedData');
				}

				if (isValidSavedData(json)) {
					vueApp.savedData = json;
					vueApp.savedData.cameraPosition = new THREE.Vector3(
						vueApp.savedData.cameraPosition.x,
						vueApp.savedData.cameraPosition.y,
						vueApp.savedData.cameraPosition.z);
					if (vueApp.savedData.cameraTarget) {
						var vc = vueApp.savedData.cameraTarget;
						vueApp.savedData.cameraTarget = new THREE.Vector3(vc.x, vc.y, vc.z);
					}
					vueApp.savedData.isLoaded = true;
				}
				else {
					vueApp.savedData.isLoaded = false;
					sendReport(`Invalid saved.json for patient id: ${vueApp.patientId}`);
				}
			} else {
				vueApp.savedData.isLoaded = false;
			}
			stlLogMessage("loadViewerDataFromApi: ", vueApp.savedData.isLoaded);
			if (callback)
				callback();
		});
}

function isValidSavedData(json) {
	return json.cameraPosition && json.cameraTarget;
}

function getIs3DAllowed(callBack) {
	var apiUrl = `${vueApp.apiPath}/cephx/patients/is3DAllowed?patientId=${vueApp.patientId}`;
	fetch(apiUrl, {
		timeout: cloudApiTimeout,
		cache: 'no-cache'
	})
		.then((response) => {
			response.text().then((text) => {
				//console.log(`is3DAllowed for patientId=${vueApp.patientId} - ${text}`);
				var is3DAllowed = undefined;
				if (text) {
					var trimmed = text.toLowerCase().trim();
					if (trimmed === 'true')
						is3DAllowed = true;
					else if (trimmed === 'false')
						is3DAllowed = false;
				}
				if (callBack) {
					callBack(is3DAllowed);
				}
			});
		}).catch((error) => {
			console.error('Error is3DAllowed: ', error);
			if (callBack) {
				callBack();
			}
		});
}

function utf8_to_b64(str) {
	return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str) {
	return decodeURIComponent(escape(window.atob(str)));
}

function updateToothNumbers(toothNumberChanges) {
	console.log(toothNumberChanges);
	let updateUrl = `${vueApp.viewerServerUrl}/api/toothnumbers/update`;
	let data = {
		"patientId": vueApp.patientId,
		"s3Path": vueApp.stlBaseUrl,
		"toothNumberChanges": toothNumberChanges
	};
	// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
	fetch(updateUrl,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			return response.json();
		})
		.then((json) => {
			console.log(json);
		})
		.catch((error) => {
			console.log('Error response for /api/toothnumbers/update: ', error);
		});
}

function updateZipsForSourceType(sourceType) {
	console.log("sourceType: ", sourceType);
	if (sourceType && vueApp.isStitched && vueApp.stlBaseUrl && vueApp.stlCopyUrl) {
		let obj = vueApp.baseObj;
		if (sourceType === 'cbct') {
			vueApp.isCbctParam = true;
			vueApp.zipFile = vueApp.zipFile.replace(vueApp.stlBaseUrl, vueApp.stlCopyUrl);
			if (obj) {
				obj.data.mobileZip = obj.data.mobileZip.replace(vueApp.stlBaseUrl, vueApp.stlCopyUrl);
				obj.data.desktopZip = obj.data.desktopZip.replace(vueApp.stlBaseUrl, vueApp.stlCopyUrl);
			}
		} else {
			vueApp.isCbctParam = false;
			vueApp.zipFile = vueApp.zipFile.replace(vueApp.stlCopyUrl, vueApp.stlBaseUrl);
			if (obj) {
				obj.data.mobileZip = obj.data.mobileZip.replace(vueApp.stlCopyUrl, vueApp.stlBaseUrl);
				obj.data.desktopZip = obj.data.desktopZip.replace(vueApp.stlCopyUrl, vueApp.stlBaseUrl);
			}
		}
	}
}

function sendReport(message) {
	if(vueApp.viewerServerUrl.includes("localhost")) {
		return;
	}
	console.log(message);
	let requestUrl = `${vueApp.viewerServerUrl}/api/report/addProblemReport`;
	let data = {
		"patientId": vueApp.patientId,
		"message": message,
		"prefix": vueApp.prefix
	};
	fetch(requestUrl,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			return response.json();
		})
		.then((json) => {
			// console.log(json);
		})
		.catch((error) => {
			console.log(`Error response for ${requestUrl}: `, error);
		});
}

function sendShareReport(message) {
	console.log(message);
	let requestUrl = `${vueApp.viewerServerUrl}/api/report/addShareReport`;
	let data = {
		"patientId": vueApp.patientId,
		"doctorId": vueApp.doctorId,
		"doctorName": vueApp.doctorName,
		"message": message,
		"prefix": vueApp.prefix,
		"recepientName": vueApp.recepientName,
		"recepientEmail": vueApp.recepientEmail
	};
	fetch(requestUrl,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			return response.json();
		})
		.then((json) => {
			console.log(json);
		})
		.catch((error) => {
			console.log(`Error response for ${requestUrl}: `, error);
		});
}

function sendShortLink(recepientName, recepientEmail, callBack) {
	let requestUrl = `${vueApp.viewerServerUrl}/api/share/sendShortLink`;
	let data = {
		"patientId": vueApp.patientId,
		"doctorName": vueApp.doctorName,
		"shortLink": vueApp.shortLink,
		"recepientName": recepientName,
		"recepientEmail": recepientEmail,
		"prefix": vueApp.prefix
	};
	fetch(requestUrl,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			console.log(response.status);
			if (callBack) {
				callBack(response.status === 200);
			}
			return response.json();
		})
		.then((json) => {
			console.log(json);
		})
		.catch((error) => {
			if (callBack) {
				callBack(false);
			}
			console.log(`Error response for ${requestUrl}: `, error);
		});
}

function getJsonWithCallback(url, callBack) {
	fetch(url, {
		cache: 'no-cache'
	})
		.then((response) => {
			//console.log(response);
			return response.json();
		})
		.then((json) => {
			if (callBack)
				callBack(json);
		})
		.catch((error) => {
			console.log(`getJsonWithCallback error: ${error}`);
			if (callBack)
				callBack();
		});
}

export {
	saveViewerData, loadViewerData, getIs3DAllowed,
	getSavedViewerSettings, loadViewerDataFromApi,
	updateToothNumbers, updateZipsForSourceType, getJsonWithCallback,
	utf8_to_b64, b64_to_utf8, sendReport, sendShareReport, sendShortLink
};