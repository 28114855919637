import { render, staticRenderFns } from "./ToothNumberMenu.vue?vue&type=template&id=754c1d6e&scoped=true&"
import script from "./ToothNumberMenu.vue?vue&type=script&lang=js&"
export * from "./ToothNumberMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754c1d6e",
  null
  
)

export default component.exports