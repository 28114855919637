<template>
	<img class="crown-ctrl edit-ctrl-img" :src="rotateData.iconPath" v-on:click="rotate(rotateData.action, $event)"
		v-bind:style="{left: rotateData.x + 'px', top: rotateData.y + 'px', display: rotateData.display}" />
</template>

<script>
import { positiveZero, radians_to_degrees } from "../editObject";
import { updateRaycaster, findTaggedControl } from "../../App";

export default {
	name: "RotateCtrl",
	props: ["rotateData"],
	methods: {
		rotate: function(action, event) {
			updateRaycaster(event);
			var tagged = findTaggedControl();
			if (!tagged && action) {
				var editedObject = this.rotateData.editedObject;
				action(editedObject.id);

				var mesh = viewer.get_model_mesh(editedObject.id);
				if (mesh) {
					var rotation = mesh.rotation;
					editedObject.rotateX = positiveZero(
						radians_to_degrees(rotation.x).toFixed(1)
					);
					editedObject.rotateY = positiveZero(
						radians_to_degrees(rotation.y).toFixed(1)
					);
					editedObject.rotateZ = positiveZero(
						radians_to_degrees(rotation.z).toFixed(1)
					);
				}
			}
		}
	}
};

</script>

<style scoped>
</style>