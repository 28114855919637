<template>
	<!-- <div id="marketingPromptDiv"> -->
	<img id="marketingPromptImg" v-if="startmarketingprompt"
		v-bind:style="{top:currentTop + 'px'}"
		src="images/try_us_triangle.png" v-on:click="openMarketing($event)" />
	<!-- </div> -->
</template>

<script>
import $ from "jquery";

export default {
	name: "MarketingPrompt",
	props: ["startmarketingprompt", "opennewtabprompt"],
	data() {
		return {
			isAnimated: false,
			currentTop: 255
		};
	},
	methods: {
		openMarketing: function(event) {
			window.open(this.opennewtabprompt, "_blank").focus();
		},
		moveUp: function() {
			//console.log("moveUp");
			var newTop = this.currentTop - 10;
			if (newTop >= 35) {
				this.currentTop = newTop;
				setTimeout(this.moveUp, 20);
			}
		}
	},
	updated: function() {
		if (!this.isAnimated) {
			this.isAnimated = true;
			this.moveUp();
		}
	}
};
</script>

<style scoped>
/* #marketingPromptDiv {
	position: absolute;
	right: 0;
	top: 35px;
	width: 100px;
	height: 200px;
} */

#marketingPromptImg {
	/* pointer-events: none; */
	position: absolute;
	right: 0;
	top: 155px;
	width: 150px;
	cursor: pointer;
	/* height: 150px; */
}

@media screen and (max-width: 650px) {
	#marketingPromptImg {
		width: 100px;
		/* height: 100px; */
	}
}
</style>