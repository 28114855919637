<template>
    <div class="auxiliary-viewer">
        <div class="input-group mb-2 mt-1 input-group-sm smooth-group">
            <span class="smooth-text">Smoothing:</span>
            <input v-model="smoothFactor" @change="smoothFactorChanged()" class="form-control-range smooth-input"
                type="range" min="1" max="100" step="1" />
        </div>
    </div>
</template>

<script>

import {
    initAuxilaryStlViewer, updateAuixiliaryMesh, smoothSelectedAnnotation
} from "./AuxiliaryManager";

export default {
    name: "AuxiliaryViewer",
    data() {
        return {
            isViewMode: false
        };
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    watch: {
        "app.selectedAnnotation.changed": {
            // immediate: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    let annotation = this.app.selectedAnnotation;
                    // console.log(`${annotation.name} changed`, newVal);
                    updateAuixiliaryMesh(annotation);
                }
            }
        },
    },
    mounted() {
        this.app.auxiliaryViewerId = this.app.divId + "_auxiliary_viewer";
        this.$el.setAttribute("id", this.app.auxiliaryViewerId);
        console.log("mounted " + this.app.auxiliaryViewerId);
        setTimeout(() => {
            initAuxilaryStlViewer(this.app);
        }, 100);
    },
    computed: {
        annotationSelected() {
            return !!this.app.selectedAnnotation;
        },
        smoothFactor: {
            get: function () {
                return this.app.selectedAnnotation ? this.app.selectedAnnotation.smoothFactor : 7;
            },
            set: function (value) {
                if (this.app.selectedAnnotation) {
                    this.app.selectedAnnotation.smoothFactor = +value;
                }
            }
        },
    },
    methods: {
        smoothFactorChanged: function () {
            smoothSelectedAnnotation();
        },
    }
};
</script>

<style scoped>
.auxiliary-viewer {
    background: #77a7fd;
    width: 100%;
    height: 100%;
}

.smooth-group {
    position: absolute;
    top: 3px;
    right: 3px;
    width: calc(50% + 25px);
}

.smooth-text {
    height: 27px !important;
    padding: 2px 4px !important;
    display: inline;
}

.smooth-input {
    display: inline;
    height: 27px;
    width: calc(100% - 92px);
    /* padding: 2px; */
}
</style>