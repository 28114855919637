//#region Imports

import Dicom3dViewer from "./Dicom3dViewer.vue";

import vtkITKHelper from "@kitware/vtk.js/Common/DataModel/ITKHelper";
// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import "@kitware/vtk.js/Rendering/Profiles/Volume";
import vtkLight from "@kitware/vtk.js/Rendering/Core/Light";
import vtkBoundingBox from "@kitware/vtk.js/Common/DataModel/BoundingBox";
import vtkColorTransferFunction from "@kitware/vtk.js/Rendering/Core/ColorTransferFunction";
import vtkFullScreenRenderWindow from "@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow";
import vtkPiecewiseFunction from "@kitware/vtk.js/Common/DataModel/PiecewiseFunction";
import vtkVolumeController from "@kitware/vtk.js/Interaction/UI/VolumeController";
import vtkVolume from "@kitware/vtk.js/Rendering/Core/Volume";
import vtkVolumeMapper from "@kitware/vtk.js/Rendering/Core/VolumeMapper";

// Force DataAccessHelper to have access to various data source
import "@kitware/vtk.js/IO/Core/DataAccessHelper/HtmlDataAccessHelper";
import "@kitware/vtk.js/IO/Core/DataAccessHelper/JSZipDataAccessHelper";

// import "@kitware/vtk.js/Rendering/Profiles/Geometry";
// import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
// import vtkConeSource from "@kitware/vtk.js/Filters/Sources/ConeSource";
// import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";

//#endregion

//#region Fields

const maxDicomFiles = 525;
const gaussianBasePosition = 0.6;
let gaussianPosition = 0.6;

let viewerInitialized = false;
let imageInitialized = false;
let vtkImage = null;

let renderer = null;
let renderWindow = null;
let camera = null;
let actor = null;
let controllerWidget = null;
let widget = null;

//#endregion

//#region Methods

function processDicomZipData(data) {
  JSZip.loadAsync(data).then((zip) => {
    let zipKeys = Object.keys(zip.files);
    zipKeys.sort((a, b) => b.localeCompare(a));
    //zipKeys = obj.sort().reverse();
    let promises = [];
    let files = [];
    console.log("processDicomZipData", new Date()); // , zipKeys
    // zip.forEach(function (filename, zipFile) {
    // for (let [filename, zipFile] of Object.entries(zip.files)) {
    for (let filename of zipKeys) {
      let zipFile = zip.file(filename);
      let promise = zipFile.async("uint8array");
      promises.push(promise);
      promise.then((arr) => {
        files.push(new File([arr], filename));
      });
      if (promises.length >= maxDicomFiles) {
        break;
      }
    }
    // }
    // });

    Promise.all(promises).then((values) => {
      itk.readImageDICOMFileSeries(files).then(({ image, webWorker }) => {
        if (webWorker) webWorker.terminate();
        console.log("itk image", image);
        vtkImage = vtkITKHelper.convertItkToVtkImage(image);
        console.log("vtk image", vtkImage);

        if (viewerInitialized && vueApp.isDicom3D && !imageInitialized) {
          loadVtkImage();
        }
      });
    });
  });
}

function loadDicom3D() {
  if (!viewerInitialized) {
    let container = document.getElementById("dicom_3d_wrapper");
    const viewerInstance = new Vue({
      ...Dicom3dViewer,
      propsData: {
        app: vueApp,
      },
    });
    const vueContainer = document.createElement("div");
    container.appendChild(vueContainer);
    viewerInstance.$mount(vueContainer);
    viewerInitialized = true;
  }
}

function loadVtkImage() {
  if (!imageInitialized) {
    $("#dicom3dSpinner").css({ display: "block" });
    if (vtkImage) {
      setTimeout(function () {
        let container = document.getElementById("dicom_3d_viewer");
        create3dDicomViewer(container, vtkImage, {});
        imageInitialized = true;
      }, 100);
    }
  }
}

function create3dDicomViewer(rootContainer, source, options) {
  console.log("create3dDicomViewer start", new Date());
  const background = options.background
    ? options.background.split(",").map((s) => Number(s))
    : [0, 0, 0];
  const containerStyle = options.containerStyle;
  const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
    background,
    rootContainer,
    containerStyle,
  });
  renderer = fullScreenRenderer.getRenderer();
  renderWindow = fullScreenRenderer.getRenderWindow();
  let interactor = renderWindow.getInteractor();
  interactor.setDesiredUpdateRate(30);
  interactor.setLightFollowCamera(true);

  const mapper = vtkVolumeMapper.newInstance();
  mapper.setMaximumSamplesPerRay(5000);
  actor = vtkVolume.newInstance();

  const dataArray =
    source.getPointData().getScalars() || source.getPointData().getArrays()[0];
  const dataRange = dataArray.getRange();

  // Pipeline handling
  actor.setMapper(mapper);
  mapper.setInputData(source);
  renderer.addActor(actor);

  // Configuration
  const sampleDistance =
    0.7 *
    Math.sqrt(
      source
        .getSpacing()
        .map((v) => v * v)
        .reduce((a, b) => a + b, 0)
    );
  mapper.setSampleDistance(sampleDistance);

  const lookupTable = vtkColorTransferFunction.newInstance();
  const piecewiseFunction = vtkPiecewiseFunction.newInstance();

  actor.getProperty().setRGBTransferFunction(0, lookupTable);
  actor.getProperty().setScalarOpacity(0, piecewiseFunction);
  actor.getProperty().setInterpolationTypeToFastLinear();
  // actor.getProperty().setInterpolationTypeToLinear();

  // For better looking volume rendering
  // - distance in world coordinates a scalar opacity of 1.0
  actor
    .getProperty()
    .setScalarOpacityUnitDistance(
      0,
      vtkBoundingBox.getDiagonalLength(source.getBounds()) /
        Math.max(...source.getDimensions())
    );
  // - control how we emphasize surface boundaries
  //  => max should be around the average gradient magnitude for the
  //     volume or maybe average plus one std dev of the gradient magnitude
  //     (adjusted for spacing, this is a world coordinate gradient, not a
  //     pixel gradient)
  //  => max hack: (dataRange[1] - dataRange[0]) * 0.05
  actor.getProperty().setGradientOpacityMinimumValue(0, 0);
  actor
    .getProperty()
    .setGradientOpacityMaximumValue(0, (dataRange[1] - dataRange[0]) * 0.05);
  // - Use shading based on gradient
  actor.getProperty().setShade(false);
  actor.getProperty().setUseGradientOpacity(0, true);
  // - generic good default
  actor.getProperty().setGradientOpacityMinimumOpacity(0, 0.0);
  actor.getProperty().setGradientOpacityMaximumOpacity(0, 1.0);
  actor.getProperty().setAmbient(0.2);
  actor.getProperty().setDiffuse(0.7);
  actor.getProperty().setSpecular(0.3);
  actor.getProperty().setSpecularPower(8.0);

  // Control UI
  controllerWidget = vtkVolumeController.newInstance({
    size: [300, 140],
    rescaleColorMap: true,
  });
  const isBackgroundDark = background[0] + background[1] + background[2] < 1.5;
  controllerWidget.setContainer(document.getElementById("dicom_3d_widget"));
  controllerWidget.setupContent(
    renderWindow,
    actor,
    isBackgroundDark,
    true,
    "Warm to Cool" // "X Ray"
  );

  // setUpContent above sets the size to the container.
  // We need to set the size after that.
  controllerWidget.setExpanded(false);
  widget = controllerWidget.getWidget();
  console.log("controllerWidget", controllerWidget.getWidget());

  // First render
  renderer.resetCamera();
  renderWindow.render();

  camera = renderer.getActiveCamera();
  camera.elevation(-90);
  camera.zoom(1.8);
  camera.computeCameraLightTransform();
  renderWindow.render();

  let pos = camera.getPosition();
  let up = camera.getViewUp();
  console.log("camera", pos, up);

  // Add one positional light
  const bounds = actor.getBounds();
  const center = [
    bounds[0] + (bounds[1] - bounds[0]) / 2.0,
    bounds[2] + (bounds[3] - bounds[2]) / 2.0,
    bounds[4] + (bounds[5] - bounds[4]) / 2.0,
  ];

  let lights = renderer.getLights();
  let light = lights[0];
  // renderer.removeAllLights();
  // light = vtkLight.newInstance();
  // light.setPositional(true);
  // light.setLightType('HeadLight');
  light.setPosition(pos);
  light.setFocalPoint(center);
  // light.setColor(1, 1, 1);
  light.setIntensity(1.2);
  // light.setConeAngle(50.0);
  // renderer.addLight(light);

  console.log("default dicom3d light", light.toJSON());

  document.getElementById("dicom_3d_widget").children[0].style.gap = "8px";

  const defaultSpacing = 0.01;
  let input = document.querySelector(".js-spacing");
  input.value = defaultSpacing;
  actor
    .getMapper()
    .setSampleDistance(sampleDistance * 2 ** (defaultSpacing * 3.0 - 1.5));

  widget.removeGaussian(0);
  widget.addGaussian(gaussianBasePosition, 0.6, 0.284, 0.3, 2);
  widget.applyOpacity(piecewiseFunction);

  renderWindow.render();

  $("#dicom3dSpinner").css({ display: "none" });

  new ResizeObserver((entry) => {
    // console.log("resized", entry);
    fullScreenRenderer.resize();
  }).observe(rootContainer);

  console.log("create3dDicomViewer end", new Date());
}

function updateGausianWidget(rangeValue) {
  rangeValue = +rangeValue;
  if (rangeValue != gaussianPosition) {
    gaussianPosition = rangeValue;
    let gaussians = widget.getGaussians();
    let cg = widget.getGaussians()[0];
    if (cg) {
      cg.position = gaussianPosition;
      widget.setGaussians([...gaussians]);

      // widget.removeGaussian(0);
      // widget.addGaussian(gaussianPosition, cg.height, cg.width, cg.xBias, cg.yBias);
    }
  }
}

//#endregion

export {
  processDicomZipData,
  loadDicom3D,
  loadVtkImage,
  gaussianBasePosition,
  updateGausianWidget,
};
