import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import { degreesToRadians } from "../geometry.js";
import {
  getTeethBox,
  getTeethZoom,
  switchToMaxilla,
  switchToMandibular,
} from "../occlusal/OcclusalTools.js";
import {
  getFormattedDate,
  getShortDate,
  zeroPad,
  saveFile,
} from "../fileMethods";

import { sendGetPdfReportDataRequest } from "@/api/reportApi";

import QRCode from "qrcode";

let patientData = null;

function getFileByFormat(format, dataToInclude) {
  $("#hintGetReport").html("Please wait, the file is being prepared...");

  let isMeasure = false;
  if (
    (format == "pdf" || format == "image") &&
    vueApp.activeMode &&
    vueApp.activeMode.isActive
  ) {
    if (vueApp.activeMode.hideControls) {
      vueApp.activeMode.hideControls();
    }
    if (vueApp.activeMode.isMeasureMode) {
      isMeasure = true;
    }
  }

  let timeout = 500; // isMeasure ? 1500 :
  setTimeout(function () {
    switch (format) {
      case "pdf":
      case "image":
        let canvas = viewer.renderer.domElement;
        let saveImage = function () {
          setTimeout(function () {
            try {
              let imgData = getImgDataForCurrentView(canvas);
              if (format == "pdf") {
                createPDF(imgData, dataToInclude);
              } else {
                $("#reportsDialog").modal("hide");
                var fileName = `${vueApp.patientFirstName}_${
                  vueApp.patientLastName
                }_${getFormattedDate(true)}.jpg`;
                saveFile(imgData, fileName);
              }

              if (
                vueApp.activeMode &&
                vueApp.activeMode.isActive &&
                vueApp.activeMode.showControls
              ) {
                vueApp.activeMode.showControls();
              }
            } catch (error) {
              console.error(error);
            }
          }, timeout);
        };

        if (isMeasure) {
          canvas = document.createElement("canvas");
          canvas.setAttribute("width", window.innerWidth);
          canvas.setAttribute("height", window.innerHeight);
          let ctx = canvas.getContext("2d");
          ctx.fillStyle = "#77a7fd";
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          let dataUrl = getImgDataForCurrentView();
          let img = new Image();
          img.src = dataUrl;
          img.addEventListener("load", (e) => {
            ctx.drawImage(img, 0, 0);

            let measureLabel = document.getElementById("measureLabel");
            let dx = window.innerWidth / 2 - 90;
            let dy = window.innerHeight - 129;
            ctx.font = "20px / 24px Montserrat, sans-serif";
            ctx.fillStyle = ctx.strokeStyle = "white";
            ctx.strokeText(measureLabel.innerText, dx, dy);

            saveImage();
          });
        } else {
          saveImage();
        }

        break;
      case "multiPdf":
        saveMultiPdf(dataToInclude);
        break;
      case "video":
        saveVideo();
        break;
    }
  }, timeout);
}

function saveVideo() {
  vueApp.leaveModes(true);
  var isMobileOrTablet = mobileAndTabletCheck();
  var mType = getMimeType(); //"video/webm";

  const rotationFactor = isMobileOrTablet ? 4 : 1;
  var width = isMobileOrTablet ? 700 : 800; //e.width;
  var height = isMobileOrTablet ? 400 : 600; //t * 0.68;
  var rect = viewer.parent_element.getBoundingClientRect();
  if (rect.width < rect.height) {
    var temp = width;
    width = height;
    height = temp;
  }

  viewer.camera.aspect = width / height;
  viewer.camera.updateProjectionMatrix();
  viewer.renderer.setSize(width, height);
  viewer.set_auto_resize(false);

  viewer.camera.position.z = defaultZoom * 0.8;
  viewer.controls.autoRotateSpeed *= rotationFactor;
  viewer.set_auto_rotate(true);

  let canvas = viewer.renderer.domElement;
  $("#btnCloseReportsDialog").attr("hidden", true);
  canvas.setAttribute("hidden", "hidden");

  var teethBox = getTeethBox();
  var teethZoom = getTeethZoom(teethBox);

  const duration = 42000;
  let RecordRTC = require("recordrtc"),
    recorder = RecordRTC(canvas, {
      type: "canvas",
      mimeType: mType,
      // bitsPerSecond: 128000,
      // videoBitsPerSecond: 128000,
      // frameInterval: 90,
      // frameRate: 30,
      // bitrate: 128000,
      // timeSlice: 100,
      disableLogs: true,
    });

  recorder.startRecording();
  for (let i = 1; i <= 100; i++) {
    setTimeout(function () {
      $("#hintGetReport").html(
        `Please wait, the file is being prepared... ${i} %` // zeroPad(i, 2)
      );
    }, (duration * i * 1.1) / 100);
  }

  setTimeout(function () {
    viewer.camera.position.x = teethBox.center.x;
    viewer.camera.position.y = teethBox.center.y;
    viewer.camera.position.z = teethBox.center.z + teethZoom * 0.9;
    vueApp.lookAtPoint(teethBox.center);
    vueApp.bones.forEach(function (item) {
      viewer.set_opacity(item.id, 0);
    });
  }, duration * 0.25);

  setTimeout(function () {
    viewer.camera.position.x = teethBox.center.x;
    viewer.camera.position.y = teethBox.center.y + teethZoom;
    viewer.camera.position.z = teethBox.center.z;
    if (viewer.controls_type == 1) {
      viewer.camera.up.y = 0;
      viewer.camera.up.x = 0;
      viewer.camera.up.z = -1;
    }
  }, duration * 0.5);

  setTimeout(function () {
    viewer.camera.position.x = teethBox.center.x;
    viewer.camera.position.y = teethBox.center.y - teethZoom;
    viewer.camera.position.z = teethBox.center.z;
    if (viewer.controls_type == 1) {
      viewer.camera.up.y = 0;
      viewer.camera.up.x = 0;
      viewer.camera.up.z = 1;
    }
  }, duration * 0.75);

  setTimeout(function () {
    let arr = mType.split("/");
    let extension = arr.length && arr.length > 1 ? arr[1] : "webm";
    recorder.stopRecording(function (url) {
      let fileName = `${vueApp.patientFirstName}_${
        vueApp.patientLastName
      }_${getFormattedDate(true)}.${extension}`;
      saveFile(url, fileName);
    });
    canvas.removeAttribute("hidden");
    $("#btnCloseReportsDialog").attr("hidden", false);

    viewer.set_auto_rotate(false);
    viewer.controls.autoRotateSpeed /= rotationFactor;
    viewer.set_auto_resize(true);
    viewer.do_resize();
    vueApp.leaveModes(true);
    $("#reportsDialog").modal("hide");
    vueApp.switchToDefaultView();
  }, duration * 1.1);
}

function getImgDataForCurrentView(canvas, callback) {
  var imgData = null;
  try {
    var strMime = "image/jpeg";
    if (!canvas) {
      canvas = viewer.renderer.domElement;
    }
    imgData = canvas.toDataURL(strMime);
    if (callback) {
      callback(imgData);
    }
  } catch (e) {
    console.log(e);
    return;
  }

  return imgData;
}

function createPDF(imgData, dataToInclude) {
  if (!patientData) {
    getPatientData(() => {
      createPdfFromPatientData(imgData, dataToInclude);
    });
  } else {
    createPdfFromPatientData(imgData, dataToInclude);
  }
}

function getPatientData(callback) {
  if (!vueApp.shortLink) {
    shortLink();
  }
  sendGetPdfReportDataRequest((data) => {
    patientData = data;
    console.log("patientData", patientData);
    QRCode.toDataURL(vueApp.shortLink, function (err, url) {
      console.log(url, err);
      patientData.qrDataUrl = url;
      patientData.date = getDateText(patientData.cephDate);
      // var img  = new Image();
      // img.src = patientData.iconDataUrl;
      // img.decode().then(() => {
      //     patientData.iconWidth = img.width;
      //     patientData.iconHeight = img.height;
      // });

      if (callback) {
        callback();
      }
    });
  });
}

function getDateText(dateString) {
  const date = new Date(dateString);
  if (isNaN(date)) return dateString;

  var intl = new Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 2,
  });

  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear() % 100;
  return `${intl.format(month)}-${intl.format(day)}-${intl.format(year)}`;
}

function createPdfFromPatientData(imgData, dataToInclude) {
  console.log("dataToInclude", dataToInclude);
  const pdf = new jsPDF();
  const imgProps = pdf.getImageProperties(imgData);
  //   if (imgProps.width > imgProps.height) {
  //     pdf = new jsPDF({
  //       orientation: "landscape",
  //     });
  //   }
  const pdfWidth = pdf.internal.pageSize.getWidth(); // 210
  const pdfHeight = pdf.internal.pageSize.getHeight(); // 297
  // console.log("jsPDF", pdfWidth, pdfHeight);

  const headerHeight = 30;
  const marginTop = 2;
  const marginLeft = 2;
  createPdfHeader(pdf, dataToInclude, headerHeight, marginTop, marginLeft);

  const imgMaxWidth = pdfWidth - marginLeft * 2;
  const imgMaxHeight = pdfHeight - headerHeight - marginTop;
  const imgWidthRel = imgMaxWidth / imgProps.width;
  const imgHeightRel = imgMaxHeight / imgProps.height;
  const imgMinRel = Math.min(imgWidthRel, imgHeightRel);
  const imgWidth = imgProps.width * imgMinRel;
  const imgHeight = imgProps.height * imgMinRel;

  let imgLeft = marginLeft;
  let imgTop = headerHeight + (imgMaxHeight - imgHeight) / 2;

  pdf.addImage(imgData, "JPEG", imgLeft, imgTop, imgWidth, imgHeight);

  let fullName = `${vueApp.patientFirstName}_${
    vueApp.patientLastName
  }_${getFormattedDate(true)}.pdf`;

  pdf.save(fullName);
  $("#reportsDialog").modal("hide");
}

function createPdfHeader(
  pdf,
  dataToInclude,
  headerHeight,
  marginTop,
  marginLeft,
  fontSize
) {
  const pdfWidth = pdf.internal.pageSize.getWidth(); // 210
  // const pdfHeight = pdf.internal.pageSize.getHeight(); // 297

  const lineTop = headerHeight - 1;
  pdf.line(0, lineTop, pdfWidth, lineTop);

  const logoMaxWidth = pdfWidth / 2 - marginLeft * 2;
  const logoMaxHeight = lineTop - marginTop * 2;
  let logoWidth = logoMaxHeight;
  let logoHeight = logoMaxHeight;

  if (
    dataToInclude.isCompanyLogo &&
    patientData.iconDataUrl &&
    patientData.iconWidth > 0 &&
    patientData.iconHeight > 0
  ) {
    const logoWidthRel = logoMaxWidth / patientData.iconWidth;
    const logoHeightRel = logoMaxHeight / patientData.iconHeight;
    const logoMinRel = Math.min(logoWidthRel, logoHeightRel);
    logoWidth = patientData.iconWidth * logoMinRel;
    logoHeight = patientData.iconHeight * logoMinRel;

    pdf.addImage(
      patientData.iconDataUrl,
      "JPEG",
      marginLeft,
      marginTop,
      logoWidth,
      logoHeight
    );
  }

  const logoRight = logoWidth + marginLeft * 2;
  pdf.setFontSize(fontSize ? fontSize : 10);
  pdf.setFont("helvetica");

  let name = `Name: ${vueApp.patientFirstName} ${vueApp.patientLastName}`;
  let dims = pdf.getTextDimensions(name);
  let textLineHeight = dims.h;
  let textWidth = dims.w;

  let textTop = marginTop + textLineHeight * 1.5;

  if (dataToInclude.isPatientInfo) {
    pdf.text(name, logoRight, textTop);
    let patientText = `Age: ${patientData.age}, Date: ${patientData.date}`;
    pdf.text(patientText, logoRight, textTop + textLineHeight * 1.5);
  }

  dims = pdf.getTextDimensions(vueApp.shortLink);
  if (dims.w > textWidth) textWidth = dims.w;

  if (dataToInclude.isCaseLink) {
    let linkHintText = `3D Viewer, click on link:`;
    pdf.text(linkHintText, logoRight, textTop + textLineHeight * 3.5);

    pdf.setTextColor("0", "0", "256");
    pdf.textWithLink(
      vueApp.shortLink,
      logoRight,
      textTop + textLineHeight * 5,
      {
        url: vueApp.shortLink,
      }
    );
  }

  if (dataToInclude.isQrCode) {
    const qrLeft = Math.min(
      logoRight + textWidth + marginLeft * 2,
      pdfWidth - logoMaxHeight - 2
    );
    const qrTop = marginTop;
    pdf.addImage(
      patientData.qrDataUrl,
      "JPEG",
      qrLeft,
      qrTop,
      logoMaxHeight,
      logoMaxHeight
    );
  }
}

function saveMultiPdf(dataToInclude) {
  if (!patientData) {
    getPatientData(() => {
      saveMultiPdf(dataToInclude);
    });
    return;
  }

  vueApp.leaveModes(true);
  //$("#reportsDialog").modal("hide");
  var e = viewer.parent_element.getBoundingClientRect();
  var t = e.width;
  var a = t * 0.68;
  viewer.camera.aspect = t / a;
  viewer.camera.updateProjectionMatrix();
  viewer.renderer.setSize(t, a);

  var teethBox = getTeethBox();
  var teethZoom = getTeethZoom(teethBox);
  viewer.camera.position.x = teethBox.center.x;
  viewer.camera.position.y = teethBox.center.y;
  viewer.camera.position.z = teethBox.center.z + teethZoom;
  vueApp.lookAtPoint(teethBox.center);

  var imgArr = [];
  var angles = [0, 180, -20, 20, -40, 40, -90, 90, 0];
  const tmt = 300;

  for (let i = 0; i < angles.length; i++) {
    setTimeout(function () {
      if (i == 4) {
        vueApp.bones.forEach(function (item) {
          viewer.set_opacity(item.id, 0);
        });
      }
      vueApp.lookAtPoint(teethBox.center);
      resetCameraUp();

      viewer.camera.position.x = teethBox.center.x;
      viewer.camera.position.y = teethBox.center.y;
      viewer.camera.position.z = teethBox.center.z + teethZoom;
      if (i == 1) {
        viewer.camera.position.z = teethBox.center.z + teethZoom / 3;
      }
      if (i >= 2 && i <= 5) {
        viewer.camera.position.z = teethBox.center.z + teethZoom * 0.8;
      }

      viewer.camera.position.applyQuaternion(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0), // The positive y-axis
          degreesToRadians(angles[i]) // The amount of rotation to apply this time
        )
      );

      if (i >= 4 && i <= 5) {
        viewer.camera.position.applyQuaternion(
          new THREE.Quaternion().setFromAxisAngle(
            new THREE.Vector3(1, 0, 0), // The positive x-axis
            degreesToRadians(-30) // The amount of rotation to apply this time
          )
        );
      }

      if (i == 6) {
        switchToMaxilla();
      }

      if (i == 7) {
        switchToMandibular();
      }

      var imgData = getImgDataForCurrentView();
      if (i > 0) {
        imgArr.push(imgData);
      }
    }, i * tmt);
  }
  setTimeout(function () {
    viewer.do_resize();
    vueApp.leaveModes(true);
    createMultiPDF(imgArr, dataToInclude);
    $("#reportsDialog").modal("hide");
  }, tmt * (angles.length + 1));
}

function createMultiPDF(imgArr, dataToInclude) {
  if (!imgArr || !imgArr.length || imgArr.length == 0) {
    return;
  }

  let pdf = new jsPDF();

  const headerHeight = 30;
  const marginTop = 2;
  const marginLeft = 2;
  createPdfHeader(pdf, dataToInclude, headerHeight, marginTop, marginLeft);

  let imgData = imgArr[0];
  let imgProps = pdf.getImageProperties(imgData);

  const pdfWidth = pdf.internal.pageSize.getWidth();
  // const pdfHeight = pdf.internal.pageSize.getHeight();
  const imgWidth = pdfWidth / 2 - marginLeft * 2;
  const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

  for (let i = 0; i < imgArr.length; i++) {
    imgData = imgArr[i];

    let x = i % 2 == 0 ? marginLeft * 3 : marginLeft * 2 + imgWidth;
    let y = i % 2 == 0 ? (i / 2) * imgHeight : ((i - 1) / 2) * imgHeight;
    if (y > 0) {
      y -= i % 2 == 0 ? (i / 2) * marginTop : ((i - 1) / 2) * marginTop;
    }
    pdf.addImage(
      imgData,
      "JPEG",
      x,
      y + headerHeight,
      imgWidth - marginLeft * 2,
      imgHeight - marginTop * 2
    );
  }

  let fullName = `${vueApp.patientFirstName} ${vueApp.patientLastName}`;
  let fileName = `${fullName}_${getFormattedDate(true)}.pdf`;
  pdf.save(fileName);
  vueApp.switchToDefaultView();
}

function getMimeType() {
  var mType = "video/webm";
  var mimeTypes = [
    "video/mp4", // safari
    "video/webm",
    "video/mpeg",
    // "video/webm;codecs=h264",
    // "video/webm;codecs=vp9",
    // "video/webm;codecs=vp8",
    // "video/x-matroska;codecs=avc1",
  ];

  // https://www.webrtc-experiment.com/RecordRTC/simple-demos/isTypeSupported.html
  if (typeof MediaRecorder === "undefined") {
    window.MediaRecorder = {
      isTypeSupported: function () {
        return false;
      },
    };
  }
  for (const item of mimeTypes) {
    if (MediaRecorder.isTypeSupported(item)) {
      mType = item;
      console.log(`video type: ${mType}`);
      break;
    }
  }
  return mType;
}

export { getFileByFormat };
