function runSmoothing(geometry, times) {
  let oldVertices = geometry.getAttribute("position").array;
  // let oldNormals = geometry.getAttribute("normal").clone().array;
  let oldIndices = geometry.index.array;
  let newVertices = laplacianFilter(oldVertices, oldIndices, times);
  // let newNormals = recalculateNormals(
  //   newVertices,
  //   oldIndices,
  //   oldNormals
  // );
  // console.log(newVertices);

  const vertices = new Float32Array(newVertices);
  let newGeometry = new THREE.BufferGeometry();
  newGeometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  // newGeometry.setAttribute("normal", geometry.getAttribute("normal"));
  newGeometry.setIndex(geometry.index);

  // newGeometry = BufferGeometryUtils.mergeVertices(newGeometry);
  newGeometry.computeVertexNormals();
  newGeometry.computeBoundingBox();

  return newGeometry;
}

function laplacianFilter(vertices, indices, iterations) {
  var network = buildNetwork(indices);

  for (var i = 0; i < iterations; i++)
    vertices = laplacianFilterStep(network, vertices, indices);

  return vertices;
}

function laplacianFilterStep(network, vertices, indices) {
  var filteredVertices = [];

  for (var i = 0; i < vertices.length; i += 3) {
    var vertexNumber = i / 3;
    var connections = network[vertexNumber].adjacentIndices;
    var newVertex = vec3.create();

    for (const index of Object.keys(connections)) {
      var currentVertex = vec3.fromValues(
        vertices[3 * parseInt(index)],
        vertices[3 * parseInt(index) + 1],
        vertices[3 * parseInt(index) + 2]
      );

      vec3.add(newVertex, newVertex, currentVertex);
    }
    var connectionsLength = Object.keys(connections).length;

    filteredVertices[i] = newVertex[0] / connectionsLength;
    filteredVertices[i + 1] = newVertex[1] / connectionsLength;
    filteredVertices[i + 2] = newVertex[2] / connectionsLength;
  }

  return filteredVertices;
}

function buildNetwork(indices) {
  var network = {};

  for (var i = 0; i < indices.length; i += 3) {
    var indexA = indices[i];
    var indexB = indices[i + 1];
    var indexC = indices[i + 2];

    if (!network.hasOwnProperty(indexA))
      network[indexA] = { adjacentIndices: {} };
    if (!network.hasOwnProperty(indexB))
      network[indexB] = { adjacentIndices: {} };
    if (!network.hasOwnProperty(indexC))
      network[indexC] = { adjacentIndices: {} };

    network[indexA].adjacentIndices[indexB] = true;
    network[indexA].adjacentIndices[indexC] = true;

    network[indexB].adjacentIndices[indexA] = true;
    network[indexB].adjacentIndices[indexC] = true;

    network[indexC].adjacentIndices[indexA] = true;
    network[indexC].adjacentIndices[indexB] = true;
  }

  return network;
}

function recalculateNormals(vertices, indices, oldNormals) {
  var faceNormals = {};

  for (var i = 0; i < indices.length; i += 3) {
    var indexA = indices[i];
    var indexB = indices[i + 1];
    var indexC = indices[i + 2];

    var vecA = vec3.fromValues(
      vertices[3 * indexA],
      vertices[3 * indexA + 1],
      vertices[3 * indexA + 2]
    );
    var vecB = vec3.fromValues(
      vertices[3 * indexB],
      vertices[3 * indexB + 1],
      vertices[3 * indexB + 2]
    );
    var vecC = vec3.fromValues(
      vertices[3 * indexC],
      vertices[3 * indexC + 1],
      vertices[3 * indexC + 2]
    );

    var edge1 = vec3.create();
    var edge2 = vec3.create();
    vec3.sub(edge1, vecB, vecA);
    vec3.sub(edge2, vecC, vecA);

    var normal = vec3.create();
    vec3.cross(normal, edge1, edge2);
    vec3.normalize(normal, normal);

    if (!faceNormals.hasOwnProperty(indexA)) faceNormals[indexA] = [];
    if (!faceNormals.hasOwnProperty(indexB)) faceNormals[indexB] = [];
    if (!faceNormals.hasOwnProperty(indexC)) faceNormals[indexC] = [];
    faceNormals[indexA].push(normal);
    faceNormals[indexB].push(normal);
    faceNormals[indexC].push(normal);
  }

  var newNormals = oldNormals;
  for (var index in Object.keys(faceNormals)) {
    var connectedFaceNormals = faceNormals[parseInt(index)];

    var vertexNormal = vec3.create();
    for (var i = 0; i < connectedFaceNormals.length; i++) {
      vec3.add(vertexNormal, vertexNormal, connectedFaceNormals[i]);
    }
    vec3.normalize(vertexNormal, vertexNormal);

    newNormals[3 * parseInt(index)] = vertexNormal[0];
    newNormals[3 * parseInt(index) + 1] = vertexNormal[1];
    newNormals[3 * parseInt(index) + 2] = vertexNormal[2];
  }

  return newNormals;
}

export { runSmoothing };
