<template>
	<div class="dropdown dropright border-bottom-item">
		<button class="dropdown-item implant-disabled"
			v-on:click="enterOpacityMode()"
			type="button">
			Opacity
		</button>
	</div>
</template>

<script>
export default {
	name: "OpacityMenu",
	props: ["bones", "allTeeth"],
	components: {},
	methods: {
		enterOpacityMode: function() {
			gtag('event', 'View->Opacity');
			vueApp.leaveModes();
			enterOpacity();
		}
	}
};

function enterOpacity() {
	if (!vueApp.isOpacityMode) {
		vueApp.isOpacityMode = true;
		initializeOpacity();

		$(".opacity-ctrl").css({ display: "block" });
	}
}
</script>

<style scoped>
</style>