<template>
	<div id="bones_opacity_container" class="opacity-ctrl implant-ctrl crown-ctrl ortho-mode-item" onmousedown="preventHide(event);">
		<span class="opacity-span">BONE</span>
		<span class="opacity-span">100</span>
		<div id="bones_opacity" class="opacity-slider"></div>
		<span class="opacity-span">0</span>
	</div>
</template>

<script>
	export default {
		name: "BonesOpacity",
		props: ["bones", "allTeeth"],
		components: {
		},
		methods: {
		}
	}
</script>

<style scoped>
	@import "opacity.css";

	#bones_opacity_container {
		width: 35px;
		right: 10px;
		top: 20px;
		height: calc(100% - 210px);
	}
</style>