<template>
	<div id="helpDialog" class="modal" tabindex="-1" role="dialog">

		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div class="modal-content wizard-content">
				<div class="modal-header wizard-header">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" title="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body wizard-body">
					<div class="">
						<p class="c14">
							<span class="c0 c17">3D STL viewer tools:</span>
						</p>
						<p class="c14 c9">
							<span class="c17 c0"></span>
						</p>
						<ul class="c10 lst-kix_wqu7vwcidg2r-0 start">
							<li class="c3 li-bullet-0">
								<span class="c0">View</span>
								<span class="c2">&nbsp;- Modify</span>
								<span class="c1">&nbsp;your 3D STL viewer and see the anatomy from any point of view.</span>
							</li>
						</ul>
						<p class="c5">
							<span class="c2 c7">Show\Hide</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - Click on a tooth to simulate tooth extraction or show\hide parts of the patient&rsquo;s anatomy.</span>
						</p>
						<p class="c5">
							<span class="c7 c2">Opacity</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - Change opacity levels of the teeth and bone.</span>
						</p>
						<p class="c5">
							<span class="c7 c2">Color</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - Select a tooth to color it or color all teeth.</span>
						</p>
						<p class="c5">
							<span class="c7 c2">Occlusal view</span>
							<span class="c2">&nbsp;-</span>
							<span class="c1">&nbsp;Point of view on the Mandibular or Maxilla. </span>
						</p>
						<p class="c5">
							<span class="c2">Learn more on this </span>
							<span class="c7 c2 c11">
								<a class="c8" v-on:click="showVideo('https://www.youtube.com/embed/TMD-qvltlBE')">video</a>
							</span>
							<span class="c1">.</span>
						</p>
						<p class="c5 c9">
							<span class="c1"></span>
						</p>
						<ul class="c10 lst-kix_7jyjdlmcpmq-0 start">
							<li class="c3 li-bullet-0">
								<span class="c0">Implants </span>
								<span class="c1">- Plan freehand implant\crown procedure.</span>
							</li>
						</ul>
						<p class="c5">
							<span class="c7 c2">New Implant</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - Add a custom implant, select a size, and move\rotate the implant. You are able to extract a tooth on the view show\hide tool. </span>
						</p>
						<p class="c5">
							<span class="c7 c2">New Crown</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - On top of the implant, add a crown and adjust it.</span>
						</p>
						<p class="c5">
							<span class="c7 c2">Edit\remove</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - After saving the implant\crown, you can edit\remove it.</span>
						</p>
						<p class="c5 c9">
							<span class="c1"></span>
						</p>
						<ul class="c10 lst-kix_gonqd7rlj7jz-0 start">
							<li class="c3 li-bullet-0">
								<span class="c0">Ortho </span>
								<span class="c1">- Create your treatment plan and teeth movement with stages.</span>
							</li>
						</ul>
						<p class="c5">
							<span class="c7 c2">New stage</span>
							<span class="c2">&nbsp;</span>
							<span class="c1"> - Click on a tooth, plan tooth movement, and save the stage.</span>
						</p>
						<p class="c5">
							<span class="c7 c2">Play Stages</span>
							<span class="c1"> - Play the video of the treatment.</span>
						</p>
						<p class="c5">
							<span class="c7 c2">Stages report</span>
							<span class="c1">&nbsp;- See the measurements of the teeth movement.</span>
						</p>
						<p class="c5">
							<span class="c7 c2 c11">
								<a class="c8" v-on:click="showVideo('https://www.youtube.com/embed/h3NoUUpYEWo')">Watch this video</a>
							</span>
							<span class="c1">&nbsp;to get more information.</span>
						</p>
						<p class="c5 c9">
							<span class="c1"></span>
						</p>
						<ul class="c10 lst-kix_szlrjr8lvcxn-0 start">
							<li class="c3 li-bullet-0">
								<span class="c0">Share </span>
								<span class="c2">-</span>
								<span class="c2"> Share</span>
								<span class="c1">&nbsp;your 3D STL viewer with colleagues.</span>
							</li>
						</ul>
						<p class="c5">
							<span class="c1">Select one or more modes: &ldquo;Reset Case&rdquo; to reset the view, &ldquo;Anonymized&rdquo; - to anonymize the file, and &ldquo;Read Only&rdquo; to prevent the other side from making changes. </span>
						</p>
						<p class="c5">
							<span class="c2">Get more information on this </span>
							<span class="c7 c2 c11">
								<a class="c8" v-on:click="showVideo('https://www.youtube.com/embed/h3NoUUpYEWo')">video</a>
							</span>
							<span class="c1">.</span>
						</p>
						<p class="c5 c9">
							<span class="c1"></span>
						</p>
						<ul class="c10 lst-kix_qezpou6juikq-0 start">
							<li class="c3 li-bullet-0">
								<span class="c0">2D cephalometric </span>
								<span class="c2">-</span>
								<span class="c0">&nbsp;</span>
								<span class="c2"> Download the tracing and ceph analysis on the 2D actions menu on the &ldquo;patients list&rdquo; page.</span>
							</li>
						</ul>
						<p class="c6">
							<span class="c1"></span>
						</p>
						<p class="c14">
							<span class="c1">See more reports (Airway volume, Asymmetry) on the 3D Actions menu.</span>
						</p>
						<p class="c14 c15 c9">
							<span class="c0 c4"></span>
						</p>
					</div>
				</div>
				<!-- <div class="modal-footer wizard-footer">

					v-on:click="showVideo('https://www.youtube.com/embed/TMD-qvltlBE')"

					v-on:click="showVideo('https://www.youtube.com/embed/h3NoUUpYEWo')"

					<button type="button"
						class="btn btn-primary btn-next"
						aria-label="OK"
						data-dismiss="modal">
						OK
					</button>
				</div> -->
			</div>
		</div>
	</div>

</template>

<script>
import $ from "jquery";
import swal from "sweetalert";

export default {
	name: "HelpDialog",
	props: ["mode"],
	methods: {
		showVideo: function(video) {
			var span = document.createElement("div");
			var sizeStr = screen.width > 600 ? 'width="560" height="315"' : "";
			span.innerHTML = `<iframe src="${video}" ${sizeStr} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
			swal({
				content: span,
				className: "swal-wide",
				button: false
			}).then(() => {
				const elements = document.getElementsByClassName("swal-overlay");
				while (elements.length > 0) {
					elements[0].parentNode.removeChild(elements[0]);
				}
			});
			//$(".swal-footer").attr("margin-top", "0");
			var $closeButton = $(
				"<button type='button' class='close swal-close' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
			);
			$(".swal-modal").append($closeButton);
			$(".swal-close").click(function() {
				swal.close();
			});
		}
	}
};
</script>

<style scoped>
@import "../../css/wizard.css";

.modal-sm {
	max-width: 700px;
}

.modal-header {
	height: 20px;
}

.modal-body {
	padding-left: 30px;
	padding-right: 30px;
}

ul {
	list-style-type: none;
}

ul > li:before {
	content: "\0025cf  ";
}

li.li-bullet-0:before {
	margin-left: -18pt;
	white-space: nowrap;
	display: inline-block;
	min-width: 18pt;
}

ol {
	margin: 0;
	padding: 0;
}

table td,
table th {
	padding: 0;
}

.c12 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 11pt;
	font-family: "Arial";
	font-style: normal;
}

.c4 {
	-webkit-text-decoration-skip: none;
	color: #000000;
	text-decoration: underline;
	vertical-align: baseline;
	text-decoration-skip-ink: none;
	font-family: "Arial";
	font-style: normal;
}

.c1 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 12pt;
	font-family: "Arial";
	font-style: normal;
}

.c3 {
	margin-left: 21.3pt;
	padding-top: 0pt;
	padding-left: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	text-align: justify;
}

.c13 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left;
}

.c6 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	text-indent: 36pt;
	text-align: left;
	height: 11pt;
}

.c17 {
	color: #000000;
	text-decoration: none;
	vertical-align: baseline;
	font-family: "Arial";
	font-style: normal;
}

.c5 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	text-align: justify;
}

.c14 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	text-align: left;
}

.c16 {
	background-color: #ffffff;
	max-width: 468pt;
	padding: 72pt 72pt 72pt 72pt;
}

.c7 {
	text-decoration-skip-ink: none;
	-webkit-text-decoration-skip: none;
	text-decoration: underline;
}

.c0 {
	font-size: 12pt;
	font-weight: 700;
}

.c8 {
	color: inherit;
	text-decoration: inherit;
}

.c10 {
	padding: 0;
	margin: 0;
}

.c15 {
	margin-left: 36pt;
}

.c2 {
	font-size: 12pt;
}

.c11 {
	color: #1155cc;
}

.c9 {
	height: 11pt;
}

.title {
	padding-top: 0pt;
	color: #000000;
	font-size: 26pt;
	padding-bottom: 3pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

.subtitle {
	padding-top: 0pt;
	color: #666666;
	font-size: 15pt;
	padding-bottom: 16pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

li {
	color: #000000;
	font-size: 11pt;
	font-family: "Arial";
}

p {
	margin: 0;
	color: #000000;
	font-size: 11pt;
	font-family: "Arial";
}

h1 {
	padding-top: 20pt;
	color: #000000;
	font-size: 20pt;
	padding-bottom: 6pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

h2 {
	padding-top: 18pt;
	color: #000000;
	font-size: 16pt;
	padding-bottom: 6pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

h3 {
	padding-top: 16pt;
	color: #434343;
	font-size: 14pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

h4 {
	padding-top: 14pt;
	color: #666666;
	font-size: 12pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

h5 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}

h6 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	font-style: italic;
	orphans: 2;
	widows: 2;
	text-align: left;
}
</style>
