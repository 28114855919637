<template>
	<div id="orthoStageDiv" class="ortho-mode-item">
		<div id="orthoStageHint" class="ortho-mode-item">
			<span>{{mode.stageName}}</span>
			<!-- <span id="ortoStageStar">&nbsp;*</span> -->
			</div>

		<div id="orthoPlanShowOriginalDiv" class="toggle-div ortho-mode-item"
			v-on:click.stop="switchShowOriginal()">
			<input id="orthoPlanShowOriginalButton"
				type="checkbox" />
			<div class="toggle-title">SHOW ORIGINAL</div>
		</div>
	</div>
</template>

<script>
import { OrthoPlanMode, toggleShowOriginal } from "./OrthoPlanMode";

export default {
	name: "OrthoPlanShowOriginal",
	props: ["mode"],
	components: {},
	methods: {
		switchShowOriginal: function() {
			toggleShowOriginal(!OrthoPlanMode.showOriginal);
		}
	}
};
</script>

<style scoped>
/* @import "opacity.css";
*/

#orthoStageDiv {
	left: calc(50% - 55px);
	top: 50px;
	width: 110px;
}

#orthoStageHint {
	width: 100px;
	left: calc(50% - 50px);
	text-align: center;
	/* font-weight: bold; */
	/* background-color: transparent; */
}

#orthoPlanShowOriginalDiv {
	width: 100px;
	left: calc(50% - 40px);
	top: 30px;
}

.toggle-title {
	margin-left: -15px;
}
</style>