<template>
	<div id="orthoPlayStagesDiv">
		<div id="orthoPlaySlider">
			<div id="playSlider" class="horizontal-slider"></div>

			<img id="orthoPlayBtn" class="ortho-play-img" v-on:click="switchPlay()"
				:src="isPlaying ? 'images/pause.png' : 'images/play.png'" />

			<img id="orthoCloseBtn" class="ortho-play-img" v-on:click="closePlay()"
				src="images/cross.png" />
		</div>

		<div id="orthoPlayStages">
			<button id="originalStage" class="ortho-stage ui-button ui-widget ui-corner-all"
				v-on:click="loadStage(-1)">
				{{windowWidth > 650 ? "Original" : "S0"}}
			</button>

			<button v-for="(stage, index) in midStages"
				:key="stage.name"
				v-bind:style="{left:'calc('+ (index + 1) * proportion + '% - 17px)'}"
				v-on:click="loadStage(stage.id)"
				:title="stage.name"
				class="ortho-stage ortho-stage-mid ui-button ui-widget ui-corner-all">
				{{ "S" + stage.id }}
			</button>

			<span v-for="(stage, index) in midStages"
				:key="stage.id"
				v-bind:style="{left:'calc('+ (index + 1) * proportion + '% - 8px)', top: '-31px'}"
				v-on:click="loadStage(stage.id)"
				:title="stage.name"
				class="ui-slider-handle ui-corner-all ui-state-default ortho-ellipse"></span>

			<!-- <button id="lastStage" class="ortho-stage ui-button ui-widget ui-corner-all"
				v-on:click="loadStage(0)">
				{{windowWidth > 650 ? "Last Save" : "Sn"}}
			</button> -->
		</div>

	</div>
</template>

<script>
import { loadOrthoStage } from "./OrthoPlanMode";

export default {
	name: "OrthoPlayStages",
	props: ["orthoStages"],
	data() {
		return {
			isPlaying: false,
			windowWidth: window.innerWidth
		};
	},
	methods: {
		switchPlay: function() {
			this.isPlaying = !this.isPlaying;
			if (this.isPlaying) {
				if (vueApp.currentStageId == this.orthoStages.length) {
					vueApp.currentStageId = -1;
					this.updateSliderValue(-1);
					loadOrthoStage(-1);
					this.playNextStage(false);
				} else {
					this.playNextStage(true);
				}
			}
		},
		closePlay: function() {
			$("#orthoPlayStagesDiv").css("display", "none");
		},
		loadStage: function(stageId) {
			this.updateSliderValue(stageId);
			loadOrthoStage(stageId);
			this.isPlaying = false;
		},
		updateSliderValue: function(stageId) {
			if (stageId != undefined)
				$("#playSlider").slider(
					"value",
					stageId == -1
						? -1
						: stageId - 1
				);
		},
		playNextStage: function(isFirstStep) {
			var timeout = isFirstStep ? 100 : 1000;
			setTimeout(() => {
				var stageId = vueApp.currentStageId;
				// console.log("playNextStage4: " + stageId);
				if (this.isPlaying) {
					if (stageId < this.orthoStages.length) {
						var nextStageId = stageId == -1 ? 1 : stageId + 1;
						this.updateSliderValue(nextStageId);
						loadOrthoStage(nextStageId);
						if (stageId != 0) {
							this.playNextStage();
						} else {
							this.isPlaying = false;
						}
					} else {
						this.isPlaying = false;
					}
				}
			}, timeout);
		}
	},
	computed: {
		midStages: function() {
			return this.orthoStages;
		},
		proportion: function() {
			return 100 / (this.orthoStages.length);
		}
	},
	mounted: function() {
		// $("#orthoPlayStagesDiv").tooltip();
		window.addEventListener("resize", () => {
			this.windowWidth = window.innerWidth;
		});
	}
};
</script>

<style>
#orthoPlayStagesDiv {
	position: absolute;
	text-align: center;
	left: 10px;
	bottom: 65px;
	z-index: 999;
	display: none;
	width: calc(100% - 15px);
	height: 70px;
}

#orthoPlaySlider {
	text-align: center;
	white-space: nowrap;
	width: 100%;
	height: 35px;
}

#orthoPlayStages {
	position: absolute;
	text-align: center;
	white-space: nowrap;
	width: calc(100% - 105px);
	height: 35px;
	top: 40px;
	left: 15px;
}

.ortho-stage {
	position: absolute;
	padding: 2px;
	font-size: small;
	width: 65px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ortho-stage-mid {
	width: 27px;
}

.ortho-ellipse {
	position: absolute;
	padding: 3px;
	height: 25px;
}

.horizontal-slider {
	/*position: relative;*/
	width: calc(100% - 105px);
	border-radius: 0;
	outline: none;
	height: 0.4em;
	margin-left: 5px;
	margin-right: 5px;
	display: inline-block;
}

.horizontal-slider .ui-slider-range {
	display: none;
}

.horizontal-slider .ui-slider-handle {
	outline: none;
	width: 1.6em;
	height: 1.6em;
	border-radius: 30px;
	top: -0.6em;
	margin-left: -0.9em;
}

.ortho-play-img {
	width: 40px;
	cursor: pointer;
	display: inline-block;
}

#originalStage {
	left: -15px;
	width: 55px;
}

#lastStage {
	right: -7px;
}

@media screen and (max-width: 650px) {
	#orthoPlayStagesDiv {
		left: 5px;
		bottom: 60px;
		width: calc(100% - 5px);
	}

	.ortho-stage {
		padding: 1px;
	}

	#originalStage {
		width: 27px;
	}

	#lastStage {
		width: 27px;
	}
}
</style>