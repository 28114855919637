<template>
	<div class="modal fade" id="shareParamsDialog" tabindex="-1" role="dialog" aria-labelledby="shareParamsDialogTitle"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div class="modal-content dialog-content">
				<div class="modal-body">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 9">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="container-fluid">
						<div class="mb-3">
							<label for="shareRecipientName" class="form-label">Recipient Name:</label>
							<input id="shareRecipientName" type="text" class="form-control" v-model="recepientName"
								v-on:keydown.stop>
						</div>
						<div class="mb-3">
							<label for="shareRecipientEmail" class="form-label">Recipient Email:</label>
							<input id="shareRecipientEmail" type="email" class="form-control" v-model="recepientEmail"
								v-on:keydown.stop>
						</div>

						<div class="row">
							<div class="col-6">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="readonlyCheck">
									<label class="form-check-label" for="readonlyCheck">Read-Only</label>
								</div>
							</div>
							<div class="col-6">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="resetCaseCheck">
									<label class="form-check-label" for="resetCaseCheck">Reset Case</label>
								</div>
							</div>
							<!-- <div class="col-4">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="anonymizedCheck" checked>
									<label class="form-check-label" for="anonymizedCheck">Anonymized</label>
								</div>
							</div> -->
						</div>
						<div class="row">
							<!-- <div class="col-6">
								<button id="get_link" class="btn btn-primary" aria-label="Get Link" v-on:click="getLink()"
									data-clipboard-text="shortLink">GET LINK</button>
							</div> -->
							<div class="col-12">
								<button id="send_link" class="btn btn-primary" aria-label="Send Link"
									v-on:click="sendLink()" data-clipboard-text="shortLink">SEND LINK</button>
							</div>
						</div>
						<div class="row" v-show="isEmailSent">
							<div class="col-10">
								<input id="inputCopiedLink" readonly class="form-control" type="text" placeholder=""
									v-on:keydown.stop>
							</div>
							<div class="col-2">
								<img id="copyShortLinkImg" title="Copy" src="images/copy_icon.png"
									v-on:click="copyShortLink()" />
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<small id="hintLinkCopied" class="form-text">Press button to send link</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { utf8_to_b64, sendReport, sendShortLink } from "../saveLoadData";
import swal from "sweetalert";

export default {
	name: "ShareParamsDialog",
	props: ["shortLink"],
	data() {
		return {
			recepientName: "",
			recepientEmail: "",
			isEmailSent: false
		};
	},
	methods: {
		getLink: function () {
			// this.setShareLink();
			// shortLink(() => {
			// 	this.copyShortLink();
			// });
		},
		sendLink: function () {
			let name = this.recepientName;
			if (!name) {
				swal({
					title: "Please enter recipient name.",
					icon: "warning"
				});
				return;
			}

			let email = this.recepientEmail;
			if (!validateEmail(email)) {
				swal({
					title: "Please enter valid email.",
					icon: "warning"
				});
				return;
			}

			this.setShareLink();
			shortLink(() => {
				sendShortLink(name, email, (isSuccess) => {
					this.isEmailSent = true;
					//copyShortLink();
					$("#inputCopiedLink").val(vueApp.shortLink);
					if (isSuccess) {
						$("#hintLinkCopied").text("Email with link successfully delivered.");
					} else {
						$("#hintLinkCopied").text("Failed to deliver email.");
					}
				});
			});
		},
		setShareLink: function () {
			$("#hintLinkCopied").text("Please wait a bit...");
			let readonly = $("#readonlyCheck").is(":checked");
			// let anonymized = true; //$("#anonymizedCheck").is(":checked");
			let resetCase = $("#resetCaseCheck").is(":checked");
			let json = JSON.stringify({
				jsonLocation: vueApp.jsonLocation,
				readonly,
				resetCase,
				cbct: vueApp.isCbctParam,
				rname: this.recepientName,
				remail: this.recepientEmail
				//anonymized,
			});
			console.log(json);
			let b64 = utf8_to_b64(json);
			vueApp.shareLink = `${window.location.href.split(/[?#]/)[0]}?p=${b64}`;
			if (!vueApp.isHighQuality) {
				vueApp.shareLink += "&lq=true";
			}
		},
		copyShortLink: function () {
			$("#inputCopiedLink").val(vueApp.shortLink);
			let copyText = document.getElementById("inputCopiedLink");
			copyText.select();
			copyText.setSelectionRange(0, 99999); /*For mobile devices*/
			document.execCommand("copy");
			$("#hintLinkCopied").text("Link copied to clipboard.");
			copyText.setSelectionRange(0, 0);
		}
	}
};

function validateEmail(inputText) {
	//let mailformat = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
	let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	return inputText.match(mailformat);
}


</script>

<style scoped>
#copyShortLinkImg {
	width: 30px;
	height: 30px;
	cursor: pointer;
	margin-left: -11px;
	margin-top: 4px;
}

.modal-sm {
	max-width: 400px;
}

.dialog-content {
	background-color: #ffffff;
}

.close {
	margin-right: -8px;
	margin-top: -10px;
}

.row {
	margin-top: 20px;
}

.form-check {
	text-align: center;
}

.btn {
	width: 100%;
	border-radius: 0px;
	height: 40px;
	background-color: #0069d9;
}

#inputCopiedLink {
	border-radius: 0px;
}

#hintLinkCopied {
	text-align: center;
	color: green;
	font-size: smaller;
	margin-top: -10px;
}

/* input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
} */
</style>
