<template>
	<li class="nav-item dropdown active">
		<a class="nav-link"
		   tabindex="0"
		   data-submenu
		   aria-expanded="false"
		   v-on:click="showShareParamsDialog()">Share</a>

		<div class="dropdown-menu dropdown-menu-right">
			<!-- <button id="copy_link"
					class="dropdown-item border-bottom-item"
					type="button"
					:data-clipboard-text="shareLink"
					v-on:click="showCopyLinkDialog(false)">
				Copy link
			</button>
			<button id="readonly_link"
					class="dropdown-item border-bottom-item"
					type="button"
					:data-clipboard-text="readonlyLink"
					v-on:click="showCopyLinkDialog(true)">
				Read only link
			</button>
			<button class="dropdown-item border-bottom-item"
					type="button"
					v-on:click="showShareLinkDialog()">
				Share link
			</button> -->
		</div>
	</li>
</template>

<script>
// https://clipboardjs.com/
import ClipboardJS from "clipboard";

import ShareLinkDialog from "./ShareLinkDialog.vue";

export default {
	name: "ShareMenu",
	props: ["shortLink", "shareLink", "readonlyLink"],
	components: {},
	methods: {
		showCopyLinkDialog: function (readonly) {
			vueApp.shortLink = readonly
				? vueApp.readonlyLink
				: vueApp.shareLink;
			$("#copyLinkDialog").modal();
		},
		showShareLinkDialog: function () {
			if (!$("#shareLinkDialog").length) {
				const componentInstance = new Vue({
					...ShareLinkDialog,
				});

				const vueContainer = document.createElement("div");
				document
					.getElementById("main_div")
					.appendChild(vueContainer);
				componentInstance.$mount(vueContainer);
			}
			$("#shareLinkDialog").modal();

			//componentInstance.$destroy();
			//componentInstance.$el.remove();
			//componentInstance = null;
		},
		showShareParamsDialog: function () {
			gtag('event', 'Share');
			if (vueApp.isShareEnabled) {
				$("#shareParamsDialog").modal();
			}
		},
	},
	mounted: function () {
		new ClipboardJS("#copy_link");
		//new ClipboardJS("#readonly_link");
		//this.$nextTick(function() {
		//	//shortLink();
		//});
	},
};
</script>

<style>
</style>