<template>
	<div id="main_div">

		<MainMenu :dentations="dentations" :bones="bones" :nerves="nerves" :allTeeth="allTeeth" :implants="implants"
			:crowns="crowns" :orthoStages="orthoStages" :shortLink="shortLink" :shareLink="shareLink"
			:readonlyLink="readonlyLink" :isStitched="isStitched" :isIntraoral="isIntraoral"
			:isEngineerMode="isEngineerMode" :isHighQuality="isHighQuality" />

		<div id="views_cont" class="flex-container">
			<div id="views3d_wrapper" class="flex-item">
				<div id="views3d_cont">
					<div id="stl_wrapper" class="view-flex-item" v-show="!isDicom3DView || isSplitView">
						<div id="stl_cont">
						</div>

						<img id="teethListBtn" ref="teethListBtn" data-toggle="collapse" data-target="#teethListPanel"
							aria-expanded="false" title="Settings" src="images/menu_white.png" class="teeth-list-btn"
							v-show="!isAnnotationMode" />

						<TeethListPanel v-show="!isAnnotationMode" :allItems="allItems"
							:isAnnotationMode="isAnnotationMode" />

						<ShowHideControls />
						<ColorControls />
						<input id="selectColorControl" type="text" class="coloris" value="#ffffff" />

						<OpacityMode />

						<SaveButtons />
						<BottomPanel v-show="isStlLoaded" />
					</div>
					<div id="dicom_3d_wrapper" class="view-flex-item" v-show="isDicom3DView || isSplitView">
					</div>
					<!-- <img id="segmented_view_btn" class="switch-view-btn" v-show="isDicomLoaded"
						v-on:click="switchSegmentedView"
						:src="isSegmentedView ? 'images/3d_red.png' : 'images/3d_white.png'" title="Segmented View"> -->

					<img id="dicom_view_btn" class="switch-view-btn" v-show="isDicomLoaded && !isSplitView"
						v-on:click="switchDicom3dView"
						:src="isDicom3DView ? 'images/dicom_red.png' : 'images/dicom_white.png'" title="Dicom 3D View">

					<img id="split_view_btn" class="switch-view-btn" v-show="isDicomLoaded" v-on:click="switchSplitView"
						:src="isSplitView ? 'images/split_red.png' : 'images/split_white.png'" title="Split View">
				</div>
			</div>

			<!--<DicomViewer :app="{ divId: 'demo_dicom', orientation: 'axial', toolName: 'dragDrop', color: 'red' }"
				style="width: 49%; height: 100%; display: block;" /> -->
		</div>

		<div id="open_json" class="custom-file" style="display:none">
			<input id="input_json" type="file" class="custom-file-input" onchange="loadJson();" accept="*.json" />
			<label class="custom-file-label" for="input_json">Open JSON configuration</label>
		</div>

		<img id="globalSpinner" class="centered-spinner" src="images/dark-blue-spinner.gif" />

		<div id="loader">
			<img id="loaderImage" src="images/Spinner-2.1s-200px.gif" />
			<div id="loaderText">Loading data... Please wait</div>
			<div id="loaderText2"></div>
			<div id="loaderText3"></div>
		</div>

		<div id="viewModeHint" v-if="isViewMode">VIEW MODE</div>

		<div id="annotation-add-menu" class="annotation-menu">
			<button id="annotation-add-btn">Add Layer</button>
			<button id="annotation-clone-btn">Clone Last Layer</button>
		</div>

		<div id="annotation-tooth-menu" class="annotation-menu">
			<button id="annotation-assign-btn">Assign Layer</button>
			<button id="annotation-delete-btn">Delete Layer</button>
			<button id="tooth-focus-btn">Focus Tooth</button>
			<button id="tooth-edit-btn">Edit Tooth</button>
		</div>

		<div id="intraoral-tooth-menu" class="intraoral-menu">
			<button id="intraoral-add-btn" class="intraoral-menu-btn">Add Tooth</button>
			<button id="intraoral-delete-btn" class="intraoral-menu-btn">Delete Tooth</button>
			<button id="intraoral-center-btn" class="intraoral-menu-btn">Center View</button>
			<button id="intraoral-flip-btn" class="intraoral-menu-btn">Flip View</button>
		</div>

		<OrthoPlanMode />

		<img id="trademarkImg" src="images/Logo_ORCA_White_100_height.png" />
		<!-- <img id="infoImgBtm" src="images/info_icon_white.png" title="Help" v-on:click="showHelp()" /> -->
		<div id="copyrightText">Copyright &copy; 2024 ORCA Dental AI LTD, All rights reserved</div>

		<!-- Modal -->
		<ImplantStep1 :lengths="lengths" :diameters="diameters" @set-implant-name="setImplantName" />
		<ImplantStep2 :lengths="lengths" :diameters="diameters" :toothModels="toothModels" :isResize="isResize"
			@set-implant-size="setImplantSize" @add-implant="addImplant" />
		<ImplantStep3 @add-implant="addImplant" @add-crown="addCrown" :isCrownPlacement="isCrownPlacement" />
		<AddCrownMap @set-crown-number="setCrownNumber" @add-crown="addCrown" />
		<Loader :patientFirstName="patientFirstName" :patientLastName="patientLastName" />
		<MarketingPrompt :startmarketingprompt="startmarketingprompt" :opennewtabprompt="opennewtabprompt" />
		<ShareParamsDialog :shortLink="shortLink" />
		<OrthoPlayStages :orthoStages="orthoStages" />
		<ClipDialog />
		<ReportsDialog />

	</div>
</template>

<script>
import MainMenu from "./components/MainMenu.vue";

import TeethListPanel from "./components/controls/TeethListPanel.vue";
import { fillTeethList } from './components/controls/TeethListManager';


import ImplantStep1 from "./components/implants/ImplantStep1.vue";
import ImplantStep2 from "./components/implants/ImplantStep2.vue";
import { initStep2 } from "./components/implants/ImplantStep2.vue";
import ImplantStep3 from "./components/implants/ImplantStep3.vue";

import ShareParamsDialog from "./components/share/ShareParamsDialog.vue";
import ClipDialog from "./components/reports/ClipDialog.vue";
import { applyClipData } from "./components/reports/ClipDialog.vue";
import ReportsDialog from "./components/reports/ReportsDialog.vue";

import AddCrownMap from "./components/crowns/AddCrownMap.vue";
import { addCrownStl } from "./components/crowns/addCrown.js";
import { addImplantStl } from "./components/implants/addImplant.js";

import {
	b64_to_utf8,
	saveViewerData,
	sendReport,
	sendShareReport,
	loadViewerDataFromApi,
	updateZipsForSourceType
} from "./components/saveLoadData";
import { getFullBox } from "./components/occlusal/OcclusalTools.js";
import { showHelpDialog } from "./components/help/HelpManager.js";
import { getMouse3dDirection } from "./components/editObject";
import { adjustModel } from "./components/model";
import { simplifyGeometry } from "./components/geometry";
import { airwaySetColor } from "./components/color/AirwayColor";

import { loadDicom3D } from "./components/vtk/Dicom3dManager";

import Loader from "./components/Loader.vue";
import MarketingPrompt from "./components/MarketingPrompt.vue";
import BottomPanel from "./components/BottomPanel.vue";
import OpacityMode from "./components/opacity/OpacityMode.js";
import ShowHideControls from "./components/showHide/ShowHideControls.vue";
import ColorControls from "./components/color/ColorControls.vue";
import SaveButtons from "./components/SaveButtons.vue";
import OrthoPlanMode from "./components/ortho/OrthoPlanMode.js";
import OrthoPlayStages from "./components/ortho/OrthoPlayStages.vue";
import DicomViewer from "./components/dicom/DicomViewer.vue";
import { drawLine, drawPoint } from "./components/draw/draw";
import {
	showIoViewer
} from "./components/intraoral/IntraoralManager";

export default {
	name: "app",
	data() {
		return {
			baseObj: null,
			stlBaseUrl: null,
			stlCopyUrl: null,
			isHighQuality: true,
			isAnnotationMode: false,
			isEngineerMode: false,
			isStitched: false,
			isIntraoral: false,
			isCbctParam: false,
			isStlLoaded: false,
			isDicomLoaded: false,
			isSegmentedView: true,
			isSplitView: false,
			isDicom3DView: false,
			defaultFont: null,

			isGlb: false,
			activeMode: null,
			cache: null,
			jsonLocation: null,
			isShareEnabled: true,
			fromShareLink: false,
			shareLink: window.location.href,
			shortLink: null,
			readonlyLink: `${window.location.href}?save=false`,
			apiPath: window.location.origin,
			prefix: "DEV",
			viewerServerUrl: window.location.origin,
			crownsPath: `${window.location.href.split(/[?#]/)[0]}/stl/crowns`,
			patientId: 0,
			patientFirstName: "Unknown Client",
			patientLastName: "",
			doctorName: "",
			showmarketingprompt: false,
			startmarketingprompt: false,
			opennewtabprompt: "http://cephx.com",
			dentations: [],
			bones: [],
			nerves: [],
			gingivae: [],
			airways: [],
			implants: [],
			crowns: [],
			orthoStages: [],
			currentStageId: -1,
			implantsColor: "#FFC0CB",
			crownsColor: "#FFC0CB",
			bonesColor: "#9A9899",
			bonesOpacity: 1,
			nervesColor: "#FFFFFF",
			nervesOpacity: 1,
			gingivaeColor: "#FFB6C1",
			gingivaeOpacity: 0.85,
			airwaysColor: "#FFFFFF", //"#BA68C8",
			airwaysOpacity: 1,
			teethColor: "#D0CECF",
			teethOpacity: 1,
			isRandomTeethColor: false,
			zipFile: null,
			zipSegmentation: null,
			isHideMode: false,
			isColorMode: false,
			isOpacityMode: false,
			isAutoRotated: false,
			isCrownPlacement: false,
			lengths: [],
			diameters: [],
			toothModels: [],
			newImplantName: null,
			newImplantLength: 0,
			newImplantDiameter: 0,
			newImplantCrownId: null,
			crownImplantId: null,
			crownNumber: null,
			midlineData: [],
			isResize: false,
			isOcclusal: false,
			isViewMode: false,
			isReset: false,
			isAnonymized: false,
			is3DAllowed: true,
			isCompany: true,
			loaderClosed: false,
			simplifyPercent: 5,
			savedData: {
				isLoaded: false,
				isRandomTeethColor: false,
				cameraPosition: null,
				cameraTarget: null,
				clipData: null,
				bonesColor: null,
				bonesOpacity: null,
				teethColor: null,
				teethOpacity: null,
				bones: [],
				dentations: [],
				implants: [],
				crowns: [],
				gingivae: [],
				airways: [],
				nerves: []
			},
			baseData: {
				isLoaded: false,
				cameraPosition: null,
				cameraTarget: null,
				bonesColor: null,
				bonesOpacity: null,
				teethColor: null,
				teethOpacity: null,
				bones: [],
				dentations: [],
				implants: [],
				crowns: [],
				gingivae: [],
				airways: [],
				nerves: []
			}
		};
	},
	components: {
		MainMenu,
		TeethListPanel,
		ImplantStep1,
		ImplantStep2,
		ImplantStep3,
		AddCrownMap,
		Loader,
		MarketingPrompt,
		BottomPanel,
		OpacityMode,
		ShowHideControls,
		ColorControls,
		OrthoPlanMode,
		OrthoPlayStages,
		ShareParamsDialog,
		ClipDialog,
		ReportsDialog,
		SaveButtons,
		DicomViewer
	},
	methods: {
		// switchSegmentedView: function () {
		// 	if (!this.isSegmentedView) {
		// 		this.isSegmentedView = true;
		// 		this.isSplitView = this.isDicom3DView = false;

		// 		setTimeout(function () {
		// 			viewer.do_resize();
		// 		}, 100);
		// 	}
		// },
		switchSplitView: function () {
			this.isSplitView = !this.isSplitView;
			setTimeout(() => {
				if (this.isSplitView) {
					loadDicom3D();
				}
				viewer.do_resize();
			}, 100);
		},
		switchDicom3dView: function () {
			this.isDicom3DView = !this.isDicom3DView;
			vueApp.$emit("isDicom3DViewChanged", this.isDicom3DView);
			setTimeout(() => {
				if (this.isDicom3DView) {
					loadDicom3D();
				} else {
					viewer.do_resize();
				}
			}, 100);
		},
		showHelp: function () {
			showHelpDialog();
		},
		initApp: function (obj) {
			vueApp.baseObj = obj;

			let versionKey = `orcaViewerVersion`;
			let versionValue = localStorage.getItem(versionKey);
			if (!versionValue || versionValue != cephxVersion) {
				this.clearCache = true;
				console.log(`clearCache: ${this.clearCache}`);
				localStorage.setItem(versionKey, cephxVersion);
			}

			if (obj.defaultSettings) {
				this.bonesColor = obj.defaultSettings.bonesColor;
				this.bonesOpacity = obj.defaultSettings.bonesOpacity;
				this.nervesColor = obj.defaultSettings.nervesColor;
				this.nervesOpacity = obj.defaultSettings.nervesOpacity;
				this.teethColor = obj.defaultSettings.teethColor;
				this.teethOpacity = obj.defaultSettings.teethOpacity;
				if (obj.defaultSettings.implantsColor)
					this.implantsColor = obj.defaultSettings.implantsColor;
			}

			if (obj.data) {
				if (obj.data.zipFile) {
					this.zipFile = obj.data.zipFile;
				}
				var isMobileOrTablet = mobileAndTabletCheck();
				if (isMobileOrTablet) {
					this.zipFile = obj.data.mobileZip;
					this.zipSegmentation = obj.data.desktopSegmentationStandaloneZip;
				}
				else {
					this.zipFile = obj.data.desktopZip;
					this.zipSegmentation = obj.data.mobileSegmentationStandaloneZip;
				}
				this.isIntraoral = !!this.zipSegmentation;

				if (obj.data.patientId) this.patientId = obj.data.patientId;
				if (obj.data.patientFirstName)
					this.patientFirstName = obj.data.patientFirstName;
				if (obj.data.patientLastName)
					this.patientLastName = obj.data.patientLastName;
				if (obj.data.doctorId)
					this.doctorId = obj.data.doctorId;
				if (obj.data.doctorName)
					this.doctorName = obj.data.doctorName;

				if (!this.zipFile) {
					showIoViewer();
					return;
				}

				adjustModel(obj);
				this.cacheStamp = obj.data.cacheStamp;
				if (this.cacheStamp) {
					console.log(`cacheStamp: ${this.cacheStamp}`);
					let stampName = `cacheStamp_${vueApp.jsonLocation}`;
					let localStamp = +localStorage.getItem(stampName);
					if (!localStamp || localStamp < this.cacheStamp) {
						this.clearCache = true;
						console.log(`clearCache: ${this.clearCache}`);
						localStorage.setItem(stampName, this.cacheStamp);
					}
				}

				this.isGlb = obj.data.isGlb;

				var sl = window.location.href;
				if (obj.data.shareLink) {
					sl = obj.data.shareLink;
				}
				addthis_share.url = this.shareLink = sl;

				this.readonlyLink = sl.endsWith(".json")
					? `${sl}&save=false`
					: `${sl}?save=false`;

				if (window.location.href === this.readonlyLink) {
					this.isViewMode = true;
				}

				if (!this.shortLink) {
					this.shortLink = window.location.href;
					shortLink();
				}

				if (obj.data.crownsPath) {
					this.crownsPath = trim(obj.data.crownsPath, "/");
				} else {
					var href = window.location.href;
					href = href.split(/[?#]/)[0];
					if (href.includes(".html"))
						href = href.substring(0, href.lastIndexOf("/"));
					href = trim(href, "/");
					this.crownsPath = `${href}/stl/crowns`;
				}

				if (
					window.location.href.includes("192.168.0.") ||
					window.location.href.includes("172.30.5.") ||
					window.location.href.includes("localhost")
				) {
					this.viewerServerUrl = "https://localhost:7060";
				}

				var prod_url = ".cephx.com";
				var qa_url = "qa.ceph-x.com";
				if (obj.data.apiPath) {
					this.apiPath = trim(obj.data.apiPath, "/");
				} else if (this.apiPath.includes(prod_url)) {
					this.prefix = "PROD";
					this.apiPath = "https://cloud.cephx.com";
				} else if (this.apiPath.includes(qa_url)) {
					this.prefix = "QA";
					this.apiPath = "https://qa.ceph-x.com";
				} else if (
					this.apiPath.includes("192.168.0.") ||
					this.apiPath.includes("172.30.5.") ||
					this.apiPath.includes("ec2-35-155-116-156.")
				) {
					this.apiPath = "https://qa.ceph-x.com";
					this.prefix = "DEV";
				}
				console.log(`apiPath: ${this.apiPath}`);

				if (this.fromShareLink) {
					let rmessage = `PatientId: ${this.patientId},  was accessed by ${this.recepientEmail}, ${this.recepientName}`;
					sendShareReport(rmessage);
				}

				if (obj.data.dentations) {
					obj.data.dentations.forEach(item => {
						item.isVisible = true;
					});
					var groups = obj.data.dentations.reduce((a, b) => {
						if (b.groups)
							b.groups.forEach(item => {
								item.dentition = b.name;
							});
						return b.groups ? a.concat(b.groups) : a;
					}, []);
					var teeth = groups.reduce((a, b) => {
						if (b.teeth)
							b.teeth.forEach(item => {
								if (b.dentition === "Maxilla" || b.dentition === "Mandible") {
									item.dentition = b.dentition;
									item.isMaxilla = b.dentition === "Maxilla";
								} else {
									sendReport(`Unknown tooth dentition. Tooth number: ${item.name}`);
									item.isMaxilla =
										item.name.startsWith("1") || item.name.startsWith("2");
									item.dentition = item.isMaxilla ? "Maxilla" : "Mandible";
								}
							});
						return b.teeth ? a.concat(b.teeth) : a;
					}, []);
					teeth.forEach(item => {
						if (!item.number) {
							const parsed = parseInt(item.name, 10);
							if (parsed) {
								item.number = parsed;
							}
						}
						if (!item.savedNumber) {
							item.savedNumber = item.number;
						}
						item.isTooth = true;
						item.isVisible = true;
						if (!item.color) {
							item.color = this.teethColor;
						}
						if (!item.opacity) {
							item.opacity = this.teethOpacity;
						}
						if (!item.addedTag && item.filename.includes("added")) {
							let add_arr = item.filename.split("-");
							item.addedTag = add_arr[1].replace(".stl", "");
							item.fromAnnotation = true;
							// console.log(item.filename, add_arr, item.addedTag, item.fromAnnotation);
						}
					});

					this.dentations = obj.data.dentations;
				}

				let maxillaDentation = this.dentations.find(d => d.name === "Maxilla");
				if (!maxillaDentation) {
					maxillaDentation = {
						name: "Maxilla", groups: []
					};
					this.dentations.push(maxillaDentation);
				}
				let maxillaGroup1 = maxillaDentation.groups.find(g => g.name === "11-19");
				if (!maxillaGroup1) {
					maxillaGroup1 = { name: "11-19", teeth: [] };
					maxillaDentation.groups.push(maxillaGroup1);
				}
				let maxillaGroup2 = maxillaDentation.groups.find(g => g.name === "21-29");
				if (!maxillaGroup2) {
					maxillaGroup2 = { name: "21-29", teeth: [] };
					maxillaDentation.groups.push(maxillaGroup2);
				}

				let mandibleDentation = this.dentations.find(d => d.name === "Mandible");
				if (!mandibleDentation) {
					mandibleDentation = {
						name: "Mandible", groups: []
					};
					this.dentations.push(mandibleDentation);
				}
				let mandibleGroup1 = mandibleDentation.groups.find(g => g.name === "31-39");
				if (!mandibleGroup1) {
					mandibleGroup1 = { name: "31-39", teeth: [] };
					mandibleDentation.groups.push(mandibleGroup1);
				}
				let mandibleGroup2 = mandibleDentation.groups.find(g => g.name === "41-49");
				if (!mandibleGroup2) {
					mandibleGroup2 = { name: "41-49", teeth: [] };
					mandibleDentation.groups.push(mandibleGroup2);
				}

				if (obj.data.bones) {
					obj.data.bones.forEach(item => {
						item.isVisible = true;
						item.isBone = true;
						item.isMaxilla = item.filename.includes("maxilla");
						if (!item.color) {
							item.color = this.bonesColor;
						}
						if (!item.opacity) {
							item.opacity = this.bonesOpacity;
						}
					});
					this.bones = obj.data.bones;
				}

				if (obj.data.nerves) {
					obj.data.nerves.forEach(item => {
						item.name = item.name.replace("Merged", "Nerve");
						item.isVisible = true;
						if (!item.color) {
							item.color = this.nervesColor;
						}
						if (!item.opacity) {
							item.opacity = this.nervesOpacity;
						}
					});
					this.nerves = obj.data.nerves;
				}

				if (obj.data.gingivae) {
					obj.data.gingivae.forEach(item => {
						item.isVisible = true;
						if (!item.color) {
							item.color = this.gingivaeColor;
						}
						if (!item.opacity) {
							item.opacity = this.gingivaeOpacity;
						}
					});
					this.gingivae = obj.data.gingivae;
				}
				if (obj.data.airways) {
					obj.data.airways.forEach(item => {
						item.isVisible = true;
						item.stlCentered = airwaySetColor;
						item.isFromData = true;
						if (!item.color) {
							item.color = this.airwaysColor;
						}
						if (!item.opacity) {
							item.opacity = this.airwaysOpacity;
						}
					});
					this.airways = obj.data.airways;
				}

				var tempLengths = obj.data.lengths
					? obj.data.lengths
					: [6, 8, 10, 11.5, 13, 15];
				if (tempLengths) {
					tempLengths.forEach((item, index) => {
						var length = { value: item, selected: item == 10 }; //index == Math.floor(tempLengths.length / 2) };
						this.lengths.push(length);
					});
				}

				var tempDiameters = obj.data.diameters
					? obj.data.diameters
					: [3.25, 3.75, 4, 4.2, 5, 6];
				if (tempDiameters) {
					tempDiameters.forEach((item, index) => {
						var diameter = { value: item, selected: item == 4 }; //index == obj.data.diameters ? Math.floor(tempDiameters.length / 2) : 2 };
						this.diameters.push(diameter);
					});
				}


				if (obj.data.is3DAllowed !== undefined) {
					this.is3DAllowed = obj.data.is3DAllowed;
				}

				var midlineJson = obj.data.midlineJson;
				if (!midlineJson && this.zipFile) {
					var filename = this.zipFile.split("/").pop();
					midlineJson = this.zipFile.replace(
						filename,
						"algo3d_tooth_info.json"
					);
				}
				stlLogMessage("midlineJson: " + midlineJson);

				if (midlineJson && !this.midlineData.length) {
					fetch(midlineJson)
						.then(response => {
							response.text().then(text => {
								var jsonObj = null;
								try {
									jsonObj = JSON.parse(text);
								} catch (e) {
									console.log(`Invalid JSON: ${midlineJson}! Error: ${e}`);
									sendReport(`Can't load ${midlineJson}`);
								}
								if (jsonObj) {
									if (!Array.isArray(jsonObj)) {
										console.log(`${midlineJson} should contain array!`);
										sendReport(`Can't load ${midlineJson}`);
									} else {
										this.midlineData = jsonObj;
										this.midlineData.forEach(function (data, index) {
											if (data.bbox && Array.isArray(data.bbox)) {
												var minX, minY, minZ, maxX, maxY, maxZ;
												minX = minY = minZ = maxX = maxY = maxZ = 0;

												data.rotated_bbox = [];

												data.bbox.forEach(function (bb) {
													data.rotated_bbox.push(rotatePoint(bb));
													if (bb.x < minX || !minX) minX = bb.x;
													if (bb.x > maxX || !maxX) maxX = bb.x;
													if (bb.y < minY || !minY) minY = bb.y;
													if (bb.y > maxY || !maxY) maxY = bb.y;
													if (bb.z < minZ || !minZ) minZ = bb.z;
													if (bb.z > maxZ || !maxZ) maxZ = bb.z;
												});

												var currentBox = {
													min: {
														x: minX,
														y: minY,
														z: minZ
													},
													max: {
														x: maxX,
														y: maxY,
														z: maxZ
													}
												};
												data.defaultBox = rotateBox(currentBox);
											} else {
												console.log(
													`${data.tooth_no} doesn't contain correct bbox`
												);
											}

											if (
												data.main_axis &&
												Array.isArray(data.main_axis) &&
												data.main_axis.length > 1
											) {
												data.axisPoint1 = rotatePoint(data.main_axis[0]);
												data.axisPoint2 = rotatePoint(data.main_axis[1]);
											} else {
												console.log(
													`${data.tooth_no} doesn't contain correct main_axis`
												);
											}

											if (data.split) {
												data.splitPoint = rotatePoint(data.split);
											} else {
												console.log(`${data.tooth_no} doesn't contain split`);
											}
										});
									}
								}
							});
						})
						.catch((error) => {
							console.log(`Error response for ${midlineJson}. Error: ${error}`);
							sendReport(`Can't load ${midlineJson}`);
						});
					;
				}

				if (vueApp.savedData.isLoaded || (vueApp.isReset && vueApp.isViewMode)) {
					initViewer();
				} else {
					loadViewerDataFromApi(initViewer);
				}
			}

			if (vueApp.isAnonymized) {
				let tempId = vueApp.patientId % 10000;
				vueApp.patientFirstName = `xxx${tempId}`; // vueApp.patientId;
				vueApp.patientLastName = "";
			}

			$("#loaderDialog").modal({
				backdrop: "static",
				keyboard: false
			});
		},
		getValidStlModels: function (callback) {
			if (this.zipFile) {
				if (vueApp.cache) {
					vueApp.loadGlbOrStlCache(this.zipFile, callback, true);
				} else {
					vueApp.downloadGlbOrStlZip(
						this.zipFile,
						(zip, zipPath) => {
							vueApp.processZip(zip, callback);
						},
						true
					);
				}
			} else {
				var models = [];
				this.allItems.forEach(item => {
					if (!isFalsyOrSpaces(item.filename)) {
						var model = {
							id: item.id,
							filename: item.filename,
							color: item.color,
							opacity: item.opacity
						};
						models.push(model);
						if (item.isTooth) {
							this.toothModels.push({
								id: item.id,
								filename: item.fileName,
								color: item.color
							});
						}
					}
				});
				if (callback) callback(models);
			}
		},
		loadGlbOrStlCache: function (zipPath, callback, firstTryGlb) {
			if (firstTryGlb) {
				var glbPath = zipPath.replace(new RegExp(".zip$"), ".glb.zip");
				vueApp.cache.match(glbPath).then(response => {
					if (response && !vueApp.clearCache) {
						console.log(`zip loaded from cache, ${glbPath}`);
						vueApp.isGlb = true;
						response.blob().then(blob => {
							JSZip.loadAsync(blob).then(zip => {
								vueApp.processZip(zip, callback, true);
							});
						});
					} else {
						if (vueApp.clearCache) {
							console.log(`delete cache, ${glbPath}`);
							vueApp.cache.delete(glbPath).then((response) => {
								console.log(response)
							});
						}

						vueApp.loadGlbOrStlCache(zipPath, callback, false);
					}
				});
			} else {
				vueApp.cache.match(zipPath).then(response => {
					if (response && !vueApp.clearCache) {
						console.log(`zip loaded from cache, ${zipPath}`);
						vueApp.isGlb = false;
						response.blob().then(blob => {
							JSZip.loadAsync(blob).then(zip => {
								vueApp.processZip(zip, callback, true);
							});
						});
					} else {
						if (vueApp.clearCache) {
							console.log(`delete cache, ${zipPath}`);
							vueApp.cache.delete(zipPath).then((response) => {
								console.log(response)
							});
						}

						vueApp.downloadGlbOrStlZip(
							this.zipFile,
							(zip, zipPath) => {
								if (zip) {
									zip.generateAsync({ type: "blob" }).then(content => {
										const response = new Response(content);
										vueApp.cache.put(zipPath, response);
									});
									vueApp.processZip(zip, callback);
								}
							},
							true
						);
					}
				});
			}
		},
		downloadGlbOrStlZip: function (zipPath, callback, firstTryGlb) {
			if (firstTryGlb) {
				var glbPath = zipPath.replace(new RegExp(".zip$"), ".glb.zip");
				vueApp.downloadZip(
					glbPath,
					zip => {
						if (zip == null || !zip.files || Object.entries(zip.files).length < 2) {
							vueApp.downloadGlbOrStlZip(zipPath, callback, false);
						} else if (callback) {
							vueApp.isGlb = true;
							callback(zip, glbPath);
						}
					},
					true
				);
			} else {
				vueApp.downloadZip(
					zipPath,
					zip => {
						if (callback) {
							vueApp.isGlb = false;
							callback(zip, zipPath);
						}
					},
					false
				);
			}
		},
		downloadZip: function (zipPath, callback, isGlb) {
			var firstProgress = true;
			let downloadPath = `${zipPath}?updated=${(new Date()).getTime()}`;
			var promise = new JSZip.external.Promise(function (resolve, reject) {
				JSZipUtils.getBinaryContent(downloadPath, {
					progress: function (event) {
						if (firstProgress) {
							$("#loaderText").css("margin-left", "10px");
							firstProgress = false;
						}
						var percent = Math.ceil(event.percent * 0.75);
						$("#loaderText").html(`${percent} %`);
						//stlLogMessage(event.percent + "% of " + event.path + " loaded");
					},
					callback: function (err, data) {
						stlLogMessage(`${new Date()} zip downloaded`);
						if (err) {
							if (!isGlb) {
								$("#loaderText").css("margin-left", "0");
								$("#loaderText").html(
									`Load zip error. Please contact support@cephx.com`
								);
								sendReport(`Load zip error. Zip URL:  ${zipPath}`);
							}
							reject(err);
						} else {
							$("#loaderText2").html(`Loading files...`);
							$("#loaderText3").html(`just a few more seconds`);
							stlLogMessage(`zip downloaded, ${zipPath}`);
							resolve(data);
						}
					}
				});
			});

			promise
				.then(JSZip.loadAsync)
				.then(function (zip) {
					if (callback) callback(zip);
				})
				.then(
					function success(text) {
						stlLogMessage("Download zip success");
					},
					function error(e) {
						if (callback) callback(null);
						if (!isGlb) {
							let message = `Download zip error. Zip URL: ${zipPath}. ${e}`;
							sendReport(message);
						}
					}
				);
		},
		processZip: function (zip, callback, fromCache) {
			if (this.isGlb) {
				this.processGlbZip(zip, callback);
			} else if (zip) {
				console.log("processZip", zip.files);

				for (let [filename, file] of Object.entries(zip.files)) {
					if (filename.startsWith("tooth_")) {
						// console.log(filename);
						let existedTooth = vueApp.allTeeth.find(x => x.filename === filename);
						// //vueApp.getToothByFileName(fileName);
						if (!existedTooth) {
							let modelName = filename.replace("tooth_", "").replace(".stl", "");
							let isMaxilla = modelName.startsWith("1") || modelName.startsWith("2");
							let dentition = isMaxilla ? "Maxilla" : "Mandible";
							let dentation = vueApp.dentations.find(d => d.name === dentition);
							let firstSymbol = modelName.substring(0, 1);
							let group = dentation.groups.find(g => g.name.startsWith(firstSymbol));
							let item = {
								// id: vueApp.generateNewItemId(),
								name: modelName,
								filename: filename,
							};

							if (!item.number) {
								const parsed = parseInt(modelName, 10);
								if (parsed) {
									item.number = parsed;
								}
							}
							if (!item.savedNumber) {
								item.savedNumber = item.number;
							}
							item.isTooth = true;
							item.isVisible = true;
							if (!item.color) {
								item.color = this.teethColor;
							}
							if (!item.opacity) {
								item.opacity = this.teethOpacity;
							}

							group.teeth.push(item);
						}
					}
				}

				let i = 1;
				vueApp.allItems.forEach(item => {
					item.id = i++;
					if (!item.isVisible) Vue.set(item, "isVisible", true);
				});

				let models = [];
				let promises = [];
				let totalCount = vueApp.allItems.length;
				let errorsExist = false;
				vueApp.allItems.forEach(item => {
					let fileName = item.filename;
					if (!isFalsyOrSpaces(fileName)) {
						let zipFile = zip.file(fileName);
						if (zipFile) {
							var promise = zipFile.async("arraybuffer");
							promises.push(promise);
							promise.then(buffer => {
								var file = new Blob([buffer], {
									//new File([buffer], fileName, { type: "application/octet-stream" }); // not working in edge
									type: "application/octet-stream"
								});
								file.lastModifiedDate = new Date();
								file.name = fileName;
								var model = {
									id: item.id,
									filename: fileName,
									local_file: file,
									color: item.color,
									opacity: item.opacity
								};
								models.push(model);
								if (item.isTooth) {
									this.toothModels.push({
										id: item.id,
										filename: fileName,
										color: item.color,
										local_file: file
									});
								}
								var startPoint = fromCache ? 1 : 75;
								var coef = fromCache ? 79 : 5;
								var percent = Math.floor(
									startPoint + (models.length * coef) / totalCount
								);
								stlLogMessage(
									`${new Date()} item unzipped ${fileName}, ${percent}%`
								);
								$("#loaderText").html(`${percent} %`);
							});
						} else {
							if (this.gingivae.indexOf(item) < 0
								&& this.airways.indexOf(item) < 0) {
								console.log("absent file:", fileName);
								errorsExist = true;
								sendReport(`Can't find file in zip with name ${fileName}`);
							}
							this.removeItem(item);
						}
					}
				});

				setTimeout(function () {
					fillTeethList();
				}, 50);
				vueApp.savedData.clearCache = errorsExist;

				Promise.all(promises).then(function (values) {
					stlLogMessage(`${new Date()} archive unzipped`);
					$("#loaderText").html(`80 %`);
					console.log("models", models);
					if (callback) callback(models, false);
				});
			}
		},
		processGlbZip: function (zip, callback, fromCache) {
			console.log("processGlbZip", zip);
			if (zip) {
				let models = [];
				let loader = new THREE.GLTFLoader();
				let errorsExist = false;
				let counter = 0;
				for (let [filename, file] of Object.entries(zip.files)) {
					if (filename.startsWith("tooth_")) {
						let existedTooth = vueApp.allTeeth.find(x => x.filename === filename);
						if (!existedTooth) {
							filename = filename.replace(".glb", ".stl");
							existedTooth = vueApp.allTeeth.find(x => x.filename === filename);
						}
						if (!existedTooth) {
							let modelName = filename.replace("tooth_", "").replace(".stl", "").replace(".glb", "");
							let isMaxilla = modelName.startsWith("1") || modelName.startsWith("2");
							let dentition = isMaxilla ? "Maxilla" : "Mandible";
							let dentation = vueApp.dentations.find(d => d.name === dentition);
							let firstSymbol = modelName.substring(0, 1);
							let group = dentation.groups.find(g => g.name.startsWith(firstSymbol));
							let item = {
								// id: vueApp.generateNewItemId(),
								name: modelName,
								filename: filename,
							};

							if (!item.number) {
								const parsed = parseInt(modelName, 10);
								if (parsed) {
									item.number = parsed;
								}
							}
							if (!item.savedNumber) {
								item.savedNumber = item.number;
							}
							item.isTooth = true;
							item.isVisible = true;
							if (!item.color) {
								item.color = this.teethColor;
							}
							if (!item.opacity) {
								item.opacity = this.teethOpacity;
							}

							group.teeth.push(item);
						}
					}
				}

				let totalCount = vueApp.allItems.length;

				let i = 1;
				vueApp.allItems.forEach(item => {
					item.id = i++;
					if (!item.isVisible) Vue.set(item, "isVisible", true);
				});

				vueApp.allItems.forEach(item => {
					let fileName = item.filename;
					if (!isFalsyOrSpaces(fileName)) {
						fileName = fileName.replace(new RegExp(".stl$"), ".glb");
						// console.log(fileName);
						let zipFile = zip.file(fileName);
						if (zipFile) {
							var promise = zipFile.async("arraybuffer");
							promise.then(buffer => {
								loader.parse(buffer, "", function (result) {
									var world = result.scene.children[0];
									var element = world.children[0];
									//console.log(item.id);
									var phongMaterial = new THREE.MeshPhongMaterial({
										color: 0xeaeff2,
										flatShading: false,
										specular: 0x050505,
										shininess: 100,
										side: 2
									});

									let elementGeometry = element.geometry;
									// console.log(fileName, elementGeometry);
									// if (item.isBone) {
									// 	simplifyGeometry(elementGeometry, 5);
									// }
									// if (!vueApp.isHighQuality) {
									// 	simplifyGeometry(elementGeometry, vueApp.simplifyPercent);
									// }

									var tmesh = new THREE.Mesh(elementGeometry, phongMaterial);
									var model = {
										id: item.id,
										mesh: tmesh,
										color: item.color,
										opacity: item.opacity
									};
									models.push(model);

									if (item.isTooth) {
										vueApp.toothModels.push({
											id: item.id,
											mesh: new THREE.Mesh(
												element.geometry.clone(),
												phongMaterial.clone()
											),
											color: item.color
										});
									}

									var startPoint = fromCache ? 1 : 75;
									var coef = fromCache ? 79 : 5;
									var percent = Math.floor(
										startPoint + (models.length * coef) / totalCount
									);
									stlLogMessage(
										`${new Date()} item unzipped ${fileName}, ${percent}%`
									);
									$("#loaderText").html(`${percent} %`);

									counter++;
									if (counter == totalCount) {
										stlLogMessage(`${new Date()} archive unzipped`);
										$("#loaderText").html(`80 %`);
										if (callback) {
											callback(models, true);
										}
									}
								});
							});
						} else {
							if (this.gingivae.indexOf(item) < 0
								&& this.airways.indexOf(item) < 0) {
								errorsExist = true;
								sendReport(`Can't find file in zip with name ${fileName}`);
							}
							this.removeItem(item);
							counter++;
						}
					}
				});

				setTimeout(function () {
					fillTeethList();
				}, 50);
				vueApp.savedData.clearCache = errorsExist;
			}
		},
		removeItem: function (item) {
			if (item.isTooth) {
				this.removeTooth(item);
			} else {
				let index = this.nerves.indexOf(item);
				if (index >= 0) {
					this.nerves.splice(index, 1);
				}
				index = this.gingivae.indexOf(item);
				if (index >= 0) {
					this.gingivae.splice(index, 1);
				}
				index = this.airways.indexOf(item);
				if (index >= 0) {
					this.airways.splice(index, 1);
				}
				index = this.bones.indexOf(item);
				if (index >= 0) {
					this.bones.splice(index, 1);
				}
			}
		},
		removeTooth: function (item) {
			if (item && item.isTooth) {
				for (let dentation of this.dentations) {
					for (let group of dentation.groups) {
						let index = group.teeth.indexOf(item);
						if (index > -1) { // only splice array when item is found
							group.teeth.splice(index, 1);
							// console.log(`removed tooth file: ${item.filename}`);
						}
					}
				}
			}
		},
		addTooth: function (item) {
			if (item && item.isTooth) {
				for (let dentation of this.dentations) {
					for (let group of dentation.groups) {
						const arr = group.name.split('-');
						if (arr.length == 1 || group.name[0] == item.name[0]) {
							group.teeth.push(item);
							console.log(group.name);
							return dentation;
						}
					}
				}
			}
		},
		applySavedClipData: function () {
			applyClipData(this.savedData.clipData);
		},
		setImplantName: function (name) {
			this.newImplantName = name;
		},
		setCrownNumber: function (number) {
			this.crownNumber = number;
		},
		setImplantSize: function (length, diameter) {
			stlLogMessage(`setImplantSize: ${length}, ${diameter}`);
			this.newImplantLength = length;
			this.newImplantDiameter = diameter;

			if (this.isResize) {
				var scaleX = diameter / editedImplant.diameter;
				var scaleY = length / editedImplant.length;
				var mesh = viewer.get_model_mesh(editedImplant.id);
				var impl = mesh; //.children[0];
				impl.geometry.scale(scaleX, scaleY, scaleX);
				//impl.position.set(-diameter / 2, -length / 2, -diameter / 2);

				editedImplant.diameter = diameter;
				editedImplant.length = length;
				onCameraChange();
			}
		},
		addImplant: function (implantX, implantY, implantZ, isMaxilla) {
			var implant = {
				id: 0,
				isVisible: true,
				name: this.newImplantName,
				crownId: this.newImplantCrownId,
				length: this.newImplantLength,
				diameter: this.newImplantDiameter,
				defaultLength: this.newImplantLength,
				defaultDiameter: this.newImplantDiameter,
				x: implantX,
				y: implantY,
				z: implantZ,
				isMaxilla: isMaxilla,
				dentition: isMaxilla ? "Maxilla" : "Mandible",
				isLoaded: false,
				isFromData: false,
				isImplant: true,
				color: vueApp.implantsColor
			};
			this.newImplantCrownId = null;
			implant.id = vueApp.generateNewItemId();
			this.implants.push(implant);
			$("#globalSpinner").css({ display: "block" });
			stlLogMessage(
				`addImplant: ${this.newImplantName}, ${implantX}, ${implantY}, ${implantZ}`
			);
			addImplantStl(implant);
		},
		loadImplant: function (item) {
			if (item) {
				//console.log(item);
				var implant = {
					isVisible: true,
					name: item.name,
					length: item.length,
					diameter: item.diameter,
					defaultLength: item.length,
					defaultDiameter: item.diameter,
					focusCameraPosition: item.focusCameraPosition,
					x: item.x,
					y: item.y,
					z: item.z,
					rotationX: item.rotationX,
					rotationY: item.rotationY,
					rotationZ: item.rotationZ,
					isMaxilla: item.isMaxilla,
					dentition: item.isMaxilla ? "Maxilla" : "Mandible",
					isLoaded: false,
					isFromData: true,
					isImplant: true,
					color: this.implantsColor,
					newRotation: item.newRotation
				};

				if (item.defaultPosition) {
					implant.x = item.defaultPosition.x;
					implant.y = item.defaultPosition.y;
					implant.z = item.defaultPosition.z;
				}

				if (item.defaultRotation) {
					implant.rotationX = item.defaultRotation._x;
					implant.rotationY = item.defaultRotation._y;
					implant.rotationZ = item.defaultRotation._z;
				}

				// if (item.id > 0) {
				// 	implant.id = item.id;
				// }
				implant.id = vueApp.generateNewItemId();

				this.implants.push(implant);
				stlLogMessage(`loadImplant: ${implant.id}, ${implant.name}`);
				addImplantStl(implant);
			}
		},
		addCrown: function (implantX, implantY, implantZ, isMaxilla) {
			var crown = {
				id: this.generateNewItemId(),
				number: this.crownNumber,
				name: `Crown ${this.crownNumber}`,
				implantId: this.crownImplantId,
				implant: null,
				isVisible: true,
				x: implantX,
				y: implantY,
				z: implantZ,
				isMaxilla: isMaxilla,
				dentition: isMaxilla ? "Maxilla" : "Mandible",
				isLoaded: false,
				isFromData: false,
				stlModelLoaded: null,
				isCrown: true,
				color: vueApp.crownsColor
			};

			this.crownImplantId = null;
			this.crownNumber = null;
			this.crowns.push(crown);
			$("#globalSpinner").css({ display: "block" });
			stlLogMessage(
				`addCrown: ${crown.id}, ${crown.name}, ${implantX}, ${implantY}, ${implantZ}`
			);
			addCrownStl(crown);
		},
		loadCrown: function (crown) {
			if (crown) {
				crown.isLoaded = false;
				crown.isFromData = true;
				crown.isCrown = true;
				crown.color = vueApp.crownsColor;
				this.crowns.push(crown);
				stlLogMessage(`loadCrown: ${crown.id}, ${crown.name}`);
				addCrownStl(crown);
			}
		},
		resizeImplant: function () {
			console.log("resizeImplant");
			this.isResize = true;
			initStep2(this.lengths, this.diameters);
		},
		getItemById: function (id) {
			return id ? this.allItems.find(x => x.id === id) : null;
		},
		getItemByFilename: function (filename) {
			return filename ? this.allItems.find(x => x.filename === filename) : null;
		},
		getItemByUuid: function (uuid) {
			return uuid ? this.allItems.find(x => x.uuid === uuid) : null;
		},
		generateNewItemId: function () {
			var allIds = this.allItems.map(function (item) {
				return item.id;
			});
			var maxId = Math.max.apply(null, allIds);
			return maxId + 1;
		},
		generateAddedTag: function (toothNumber) {
			let addedTag = "added";
			let existed = vueApp.getToothByAddedTag(toothNumber, addedTag);
			let i = 1;
			while (existed != null) {
				addedTag = `added(${i})`;
				existed = vueApp.getToothByAddedTag(toothNumber, addedTag);
				i++;
			}
			return addedTag;
		},
		getToothFileNameWithPrev: function (tooth) {
			let newNumber = tooth.newNumber
				? +tooth.newNumber
				: +tooth.savedNumber;
			let fileName = `tooth_${newNumber}`; //`added(${i})`
			// let newNumberType = tooth.newNumberType
			// 	? tooth.newNumberType
			// 	: "primary";
			// if (newNumberType && newNumberType != "primary") {}
			// let newRemarks = tooth.newRemarks ? tooth.newRemarks : "";
			let newPrevTag = tooth.newPrevTag ? tooth.newPrevTag : tooth.prevTag;
			if (newPrevTag) {
				fileName += `-${newPrevTag}`;
			}
			return fileName;
		},
		getToothByName: function (name) {
			return this.allTeeth.find(x => x.name === name);
		},
		getToothByNumber: function (number) {
			return this.allTeeth.find(x => x.number === number);
		},
		getToothByAddedTag: function (number, tag) {
			return this.allTeeth.find(x => x.number === number && x.addedTag === tag);
		},
		getToothByFileName: function (fileName) {
			return this.allTeeth.find(x => x.filename === fileName);
		},
		getToothByUuid: function (uuid) {
			return uuid ? this.allDents.find(x => x.uuid === uuid) : null;
		},
		getLastMolars: function (isMaxilla) {
			var tooth1 = null;
			var tooth2 = null;
			var teeth = this.allTeeth.filter(tooth => tooth.isMaxilla === isMaxilla);
			teeth.forEach(tooth => {
				if (isMaxilla) {
					if (tooth.number === 18 || (!tooth1 && tooth.number === 17)) {
						tooth1 = tooth;
					}
					if (tooth.number === 28 || (!tooth2 && tooth.number === 27)) {
						tooth2 = tooth;
					}
				} else {
					if (tooth.number === 48 || (!tooth1 && tooth.number === 47)) {
						tooth1 = tooth;
					}

					if (tooth.number === 38 || (!tooth2 && tooth.number === 37)) {
						tooth2 = tooth;
					}
				}
			});
			return tooth1 && tooth2 ? [tooth1, tooth2] : null;
		},
		getFirstIncisor: function (positionNumber) {
			var toothNumber = 0;
			if (positionNumber < 21) {
				toothNumber = 11;
			} else if (positionNumber < 31) {
				toothNumber = 21;
			} else if (positionNumber < 41) {
				toothNumber = 31;
			} else {
				toothNumber = 41;
			}
			return this.getToothByNumber(toothNumber);
		},
		saveData: function () {
			updateSavedData();
			saveViewerData();
		},
		afterStlLoad: function () {
			return;
			if (!vueApp.is3DAllowed && this.loaderClosed && allLoaded) {
				//setTimeout(function() {
				//	viewer.controls.enableRotate = false;
				//	viewer.controls.enableZoom = false;
				//	viewer.controls.enablePan = false;
				//	$(".nav-link").attr("disabled", true);
				//	var stl_menu = document.getElementById("stl_menu");
				//	stl_menu.addEventListener('mousedown', (event) => {
				//		$('#freeModeDialog').modal();
				//	}, false);
				//	$('#freeModeDialog').modal();
				//}, 5000);
			}

			var borderPointsJson = `${window.location.href.split(/[?#]/)[0]}/demo/border_points.json`;
			fetch(borderPointsJson)
				.then(response => {
					response.text().then(text => {
						var jsonObj = null;
						try {
							jsonObj = JSON.parse(text);
							let points = jsonObj.tooth_16;
							console.log("borderPointsJson", points);

							points.forEach((arr, index) => {
								let cs1 = arr;
								let cs2 = points[index + 1];
								if (!cs2)
									cs2 = points[0];

								let point1 = { x: cs1[0], y: cs1[1], z: cs1[2] };
								let point2 = { x: cs2[0], y: cs2[1], z: cs2[2] };
								// point1 = rotatePoint(point1);
								// point2 = rotatePoint(point2);

								let color = 0x00deff;
								drawPoint(point1, color, false, 0.03);
								drawLine(point1, point2, color);
							});

						} catch (e) {
							console.log(`Invalid JSON: ${borderPointsJson}! Error: ${e}`);
						}
					});
				})
				.catch((error) => {
					console.log(`Error response for ${borderPointsJson}. Error: ${error}`);
				});
		},
		showMarketing: function () {
			if (this.showmarketingprompt) {
				this.startmarketingprompt = true;
			}
		},
		leaveModes: function (toDefaultView, stayInOcclusal) {
			if (vueApp.activeMode && vueApp.activeMode.leaveMode) {
				var activeMode = vueApp.activeMode;
				vueApp.activeMode = null;
				activeMode.leaveMode(true);
			}

			$("#anglePanel").css("display", "none");
			$("#orthoPlayStagesDiv").css("display", "none");
			leaveOpacityMode();
			leaveColorMode();

			if (vueApp.isHideMode) {
				console.log("leaveHideMode", toDefaultView);
				let visibleBox = getFullBox(true);
				let vcenter = visibleBox.center;
				this.lookAtPoint(vcenter);
			}
			leaveHideMode();

			enableImplantsMenu();

			if (!stayInOcclusal || !vueApp.isOcclusal) {
				vueApp.allItems.forEach(function (model) {
					if (model.isVisible) {
						var color = vueApp.getActualColor(model);
						if (color)
							viewer.set_color(model.id, color);
					} else {
						viewer.set_color(model.id, "transparent");
					}
				});
				vueApp.bones.forEach(function (model) {
					if (model.isVisible) {
						viewer.set_opacity(model.id, vueApp.bonesOpacity);
					}
				});

				vueApp.allTeeth.forEach(function (model) {
					if (model.isVisible) {
						viewer.set_opacity(model.id, vueApp.teethOpacity);
					}
				});
			}

			return;

			if (toDefaultView || (!stayInOcclusal && vueApp.isOcclusal)) {
				vueApp.switchToDefaultView();
				vueApp.isOcclusal = false;
			}
		},
		switchToDefaultView: function () {
			if (vueApp.savedData && vueApp.savedData.cameraTarget) {
				this.lookAtPoint(vueApp.savedData.cameraTarget);
			} else {
				if (defaultBox && defaultBox.center) {
					this.lookAtPoint(defaultBox.center);
				} else {
					this.lookAtPoint({ x: 0, y: 0, z: 0 });
				}
			}
			// console.log(vueApp.savedData);
			resetCameraUp();

			if (vueApp.savedData && vueApp.savedData.cameraPosition) {
				viewer.camera.position.copy(vueApp.savedData.cameraPosition);
			} else {
				if (defaultBox && defaultBox.center) {
					viewer.camera.position.x = defaultBox.center.x;
					viewer.camera.position.y = defaultBox.center.y;
				}
				else {
					viewer.camera.position.x = 0;
					viewer.camera.position.y = 0;
				}
				viewer.camera.position.z = defaultZoom;
			}

			viewer.camera.updateProjectionMatrix();
			viewer.controls.update();
		},
		lookAtPoint: function (point) {
			viewer.camera.lookAt(point.x, point.y, point.z);
			viewer.controls.target = new THREE.Vector3(point.x, point.y, point.z);
		},
		getActualColor: function (model) {
			let color = "#FFFFFF";
			if (model) {
				color =
					vueApp.isRandomTeethColor &&
						model.randomColor &&
						model.randomColor != noRandomColor
						? model.randomColor
						: model.color;
			}
			return color;
		},
		isTeethListOpen: function () {
			return $(`#teethListPanel`).hasClass("show");
		},
		isFocusedTooth: function () {
			return this.allTeeth.find(t => t.isFocused);
		}
	},
	computed: {
		allGroups: function () {
			var groups = this.dentations.reduce(function (a, b) {
				return a.concat(b.groups);
			}, []);
			return groups.length > 0 ? groups.sort(sortByName) : groups;
		},
		allTeeth: function () {
			return this.allGroups.reduce(function (a, b) {
				return a.concat(b.teeth);
			}, []);
		},
		allItems: function () {
			return this.bones
				.concat(this.nerves)
				.concat(this.gingivae)
				.concat(this.airways)
				.concat(this.allTeeth)
				.concat(this.implants)
				.concat(this.crowns);
		},
		teethMeshes: function () {
			return this.allDents.map(model => viewer.get_model_mesh(model.id));
		},
		allMeshes: function () {
			return this.bones
				.concat(this.nerves)
				.concat(this.gingivae)
				.concat(this.airways)
				.concat(this.allTeeth)
				.concat(this.implants)
				.concat(this.crowns).map(model => viewer.get_model_mesh(model.id));
		},
		allDents: function () {
			return this.allTeeth.concat(this.implants).concat(this.crowns);
		}
	},
	mounted: function () { }
};

let isMouseDown = false;
let mouseDownEvent = null;
let highlightedTooth = null;

window.addEventListener("DOMContentLoaded", function () {
	console.log(cephxVersion);

	const urlParams = new URLSearchParams(window.location.search);
	const defaultParam = urlParams.get("default");
	const lqParam = urlParams.get("lq");
	if (lqParam && lqParam === "true") {
		vueApp.isHighQuality = false;
	}

	const demoParam = urlParams.get("demo");
	if (demoParam !== null) {
		vueApp.isDemo = true;
	}

	const engineerParam = urlParams.get("engineerMode");
	if (engineerParam !== null) {
		vueApp.isEngineerMode = true;
	}

	const sharedParams = urlParams.get("p");
	if (sharedParams) {
		vueApp.fromShareLink = true;
		vueApp.isShareEnabled = false;
		var jsonParams = b64_to_utf8(sharedParams);
		var params = JSON.parse(jsonParams);
		vueApp.jsonLocation = params.jsonLocation;
		console.log(vueApp.jsonLocation);
		vueApp.isViewMode = params.readonly;
		vueApp.isReset = params.resetCase;
		if (params.cbct)
			vueApp.isCbctParam = params.cbct;
		vueApp.isAnonymized = true; //params.anonymized;
		if (params.rname) {
			vueApp.recepientName = params.rname;
			console.log(vueApp.recepientName);
		}
		if (params.remail) {
			vueApp.recepientEmail = params.remail;
			console.log(vueApp.recepientEmail);
		}
	} else {
		vueApp.jsonLocation = urlParams.get("json_location");
		vueApp.isViewMode = urlParams.get("save") === "false";
		const reset = urlParams.get("reset");
		vueApp.isReset = reset && reset.toLowerCase() == "true";

		const cbctParam = urlParams.get("cbct");
		vueApp.isCbctParam = cbctParam && cbctParam === "true";
	}
	console.log("isCbctParam", vueApp.isCbctParam);

	vueApp.showmarketingprompt = urlParams.get("showmarketingprompt") === "true";
	var temp = urlParams.get("opennewtabprompt");
	if (temp) {
		vueApp.opennewtabprompt = temp;
	}

	if (window.caches) {
		window.caches.open("cephxStlCache").then(function (cache) {
			vueApp.cache = cache;
			init(defaultParam, vueApp.jsonLocation);
		});
	} else {
		init(defaultParam, vueApp.jsonLocation);
	}

	$(window).resize(function (e) {
		onCameraChange();
	});

	$("#main_div").on("contextmenu", "img", function (e) {
		return false;
	});

	// $("li.dropdown")
	$("a.nav-link").on("mousedown", function () {
		$(".secondary-menu").removeClass("show");
	});

	stl_cont = document.getElementById("stl_cont");
	if (isTouchEnabled()) {
		stl_cont.addEventListener("touchstart", function (e) {
			var event = e.targetTouches[0];
			onDocumentMouseDown(event);
		});
		stl_cont.addEventListener("touchmove", function (e) {
			var event = e.targetTouches[0];
			onDocumentMouseMove(event);
		});
		stl_cont.addEventListener("touchend", function (e) {
			var event = e.changedTouches[0];
			onDocumentMouseUp(event);
		});
	} else {
		//stl_cont.addEventListener("click", onDocumentClick, false);
		stl_cont.addEventListener("mousedown", onDocumentMouseDown, false);
		stl_cont.addEventListener("mousemove", onDocumentMouseMove, false);
		stl_cont.addEventListener("mouseup", onDocumentMouseUp, false);
	}

	const loader = new THREE.FontLoader();
	loader.load('fonts/helvetiker_regular.typeface.json', function (font) {
		vueApp.defaultFont = font;
	});
});

function isTouchEnabled() {
	return ('ontouchstart' in window) ||
		(navigator.maxTouchPoints > 0) ||
		(navigator.msMaxTouchPoints > 0);
}

function init(defaultParam, jsonLocation) {
	if (defaultParam && defaultParam === "true") {
		initModel(defaultObject);
	} else if (jsonLocation) {
		fetch(jsonLocation, {
			cache: "no-cache"
		})
			.then(function (response) {
				response.text().then(function (text) {
					setS3BaseUrls();
					let model = JSON.parse(text);
					checkIsStitched();
					initModel(model);
				});
			})
			.catch(error => {
				console.error("Error in main JSON download: ", error);
			});
	} else {
		// $id("open_json").style.display = "block";
		vueApp.showMarketing();
	}
}

function setS3BaseUrls() {
	let arr = vueApp.jsonLocation.split("/");
	arr.pop();
	vueApp.stlBaseUrl = arr.join('/');
	const index = arr.indexOf('STL');
	arr.splice(index, 1);
	vueApp.s3Url = arr.slice(0, arr.length - 1).join('/');
	// console.log("s3Url: ", vueApp.s3Url);
	vueApp.dicomUrl = arr.join('/');
	console.log("dicomUrl: ", vueApp.dicomUrl);
}

function checkIsStitched() {
	let arr = vueApp.jsonLocation.split("/");
	arr.pop();
	const index = arr.indexOf('STL');
	arr.splice(index, 1);
	arr.splice(index + 1, 0, "stlCopy");

	let stlCopyUrl = arr.join('/');
	arr.push('mobile.zip');
	let mobileUrl = arr.join('/');

	fetch(mobileUrl, { method: "HEAD" })
		.then((response) => {
			if (response.status === 200) {
				vueApp.isStitched = true;
				vueApp.stlCopyUrl = stlCopyUrl;

				let stampName = `stichedTime_${vueApp.jsonLocation}`;
				let stichedStamp = localStorage.getItem(stampName);
				if (!stichedStamp) {
					vueApp.clearCache = true;
					localStorage.setItem(stampName, new Date().toISOString());
				} else {
					console.log(`${stampName} : ${stichedStamp}`);
				}

				if (vueApp.isCbctParam) {
					updateZipsForSourceType("cbct");
				}
			}
		})
		.catch((error) => {
			console.log('Error fetch HEAD copy zip: ', error);
		});
}

function updateRaycaster(event) {
	try {
		if (!raycaster) {
			var direction = getMouse3dDirection(event);
			raycaster = new THREE.Raycaster(
				viewer.camera.position, // origin
				direction,
				0.1,
				100000
			);
		} else {
			var direction = getMouse3dDirection(event);
			raycaster.set(viewer.camera.position, direction);
		}
	} catch (error) {
		console.error("updateRaycaster", error);
	}
}

function intersectTooth(selectedId) {
	var tooth = null;
	var intersects = raycaster.intersectObjects(vueApp.teethMeshes); //viewer.scene.children);
	if (intersects.length > 0) {
		//var intersectPoint = null;
		intersects.find(intersect => {
			//intersectPoint = intersect.point;
			var objectUuid = intersect.object.uuid;
			tooth = vueApp.getToothByUuid(objectUuid);
			let retval = tooth != null && (!selectedId || tooth.id == selectedId)
			return retval;
		});

		if (tooth) {
			if (!tooth.isVisible && !vueApp.isHideMode) {
				tooth = null;
			}
			//event.intersectPoint = intersectPoint;
			//drawPoint(intersectPoint);
		}
	}
	return tooth;
}

function findTaggedControl(tagParam = "group") {
	// need precall -  updateRaycaster(event);
	var combinedArray = [];
	viewer.scene.children.forEach(element => {
		if (element.tag == tagParam) {
			combinedArray.push(...element.children);
		}
	});
	var intersects = raycaster.intersectObjects(combinedArray);
	var tagged = intersects.find(intersect => {
		return intersect.object.tag != undefined;
	});
	if (tagged && vueApp.activeMode.tagDetected) {
		vueApp.activeMode.tagDetected(tagged.object.tag);
	}

	return tagged;
}

function onDocumentMouseDown(event) {
	isMouseDown = true;
	mouseDownEvent = event;
	if (event.button && event.button > 0) {
		return;
	}
	var inMode =
		vueApp.activeMode &&
		vueApp.activeMode.isActive &&
		(vueApp.activeMode.toothSelected ||
			vueApp.activeMode.tagDetected ||
			vueApp.activeMode.mouseDown);
	if (inMode) {
		if (vueApp.activeMode.mouseDown) {
			vueApp.activeMode.mouseDown(event);
		} else {
			updateRaycaster(event);
			var selectedId = vueApp.activeMode.selectedId;
			if (selectedId) {
				var tagged = findTaggedControl();
				if (tagged) return true;
			}
			//console.log("toothSelected");
			var tooth = intersectTooth(null, event);
			vueApp.activeMode.toothSelected(tooth, event);
		}
	}
	return false;
}

function onDocumentMouseMove(event) {
	let needHighlight = false;
	if (
		vueApp.activeMode &&
		vueApp.activeMode.isActive &&
		vueApp.activeMode.mouseMove
	) {
		needHighlight = vueApp.activeMode.mouseMove(event);
	}

	if (
		(vueApp.isColorMode && !teethColorMouseDown) ||
		vueApp.isHideMode ||
		needHighlight ||
		vueApp.isTeethListOpen()
	) {
		if ((event.buttons && event.buttons > 0) || vueApp.isFocusedTooth()) {
			return;
		}
		updateRaycaster(event);
		var tooth = intersectTooth(null, event);
		if (highlightedTooth != tooth) {
			if (tooth) {
				highlightedTooth = tooth;
				highlightedTooth.isHovered = true;
				setHighlightedToothColor(highlightedTooth);
			}
			if (highlightedTooth) {
				vueApp.allTeeth.forEach(function (model) {
					if (model != tooth && (model.isVisible || vueApp.isHideMode)) {
						model.isHovered = false;
						let color = vueApp.getActualColor(model);
						viewer.set_color(
							model.id,
							vueApp.isHideMode ? hideModeTeethColor : color
						);
					}
				});
				highlightedTooth = tooth;
			}
			if (vueApp.activeMode && vueApp.activeMode.toothEnter) {
				vueApp.activeMode.toothEnter(highlightedTooth);
			}
		}
	}
}

function onDocumentMouseUp(event) {
	// console.log('onDocumentMouseUp');
	// console.log(event);
	let needClick = false;
	if (
		vueApp.activeMode &&
		vueApp.activeMode.isActive &&
		vueApp.activeMode.mouseUp
	) {
		needClick = vueApp.activeMode.mouseUp(event);
	}

	if (vueApp.isColorMode || vueApp.isHideMode || needClick) {
		if (event.button && event.button > 0) {
			return;
		}
		if (isMouseDown) {
			var distance = calculateEventDistance(mouseDownEvent, event);
			if (distance < 1) {
				if (vueApp.isColorMode || vueApp.isHideMode) {
					updateRaycaster(event);
					var tooth = intersectTooth(null, event);
					if (tooth) {
						onToothUp(tooth);
						// if (event.stopPropagation) {
						// 	event.stopPropagation(); //	event.preventDefault();
						// }
					}
				} else if (needClick && vueApp.activeMode.mouseClick) {
					vueApp.activeMode.mouseClick(event);
				}
			}
		}
	}
	isMouseDown = false;
}

function onToothUp(item) {
	if (!item)
		return;
	if (vueApp.isHideMode) {
		item.isVisible = !item.isVisible;
		viewer.set_opacity(item.id, item.isVisible ? 1 : 0.3); // 2.1 - 2.2
		viewer.set_color(item.id, hideModeTeethColor);
		vueApp.$emit('visibilityChanged', item);
	} else if (vueApp.isColorMode) {
		if (item.isVisible) {
			setTimeout(function () {
				openColorPicker(item.id);
			}, 100);
		}
	}
}

function setHighlightedToothColor(hTooth) {
	if (hTooth) {
		if (vueApp.isHideMode) {
			viewer.set_color(hTooth.id, "#ffffff");
		} else {
			let color = vueApp.getActualColor(hTooth);
			if (color.toLowerCase() == "#ffffff") {
				viewer.set_color(hTooth.id, hideModeTeethColor);
			} else {
				var mesh = viewer.get_model_mesh(hTooth.id);
				var rgb = mesh.material.color;
				var r = Math.min(1.0, rgb.r + 0.2);
				var g = Math.min(1.0, rgb.g + 0.2);
				var b = Math.min(1.0, rgb.b + 0.2);
				mesh.material.color.setRGB(r, g, b);
			}
		}
	}
}

function calculateEventDistance(event1, event2) {
	var x1 = event1.clientX;
	var x2 = event2.clientX;
	var a = x1 - x2;
	var y1 = event1.clientY;
	var y2 = event2.clientY;
	var b = y1 - y2;
	return Math.hypot(a, b); //Math.sqrt(a*a + b*b);
}

export { updateRaycaster, findTaggedControl, intersectTooth, setHighlightedToothColor };
</script>

<style>
@import "css/editControls.css";

#selectColorControl {
	position: fixed;
	opacity: 0;
	width: 1px;
	height: 1px;
	left: calc(50% - 97px);
	top: calc(50% - 145px);
}

.teeth-list-btn {
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 12px;
	width: 30px;
	height: 30px;
}

.switch-view-btn {
	position: absolute;
	width: 28px;
	height: 25px;
	cursor: pointer;
	top: 14px;
}

/* #segmented_view_btn {
	left: 14px;
	top: 14px;
} */

#dicom_view_btn {
	left: 14px;
}

#split_view_btn {
	left: 50px;
	height: 26px;
}

#loader {
	z-index: 9;
}

.flex-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	height: calc(100% - 35px);
	background: white;
	gap: 4px;
}

.flex-item {
	text-align: center;
	flex-grow: 1;
	box-sizing: border-box;
}

#views3d_wrapper {
	position: relative;
	display: block;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	background: #77a7fd;
}

#views3d_cont {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	background: white;
	gap: 2px;
}

.view-flex-item {
	text-align: center;
	flex-grow: 1;
	box-sizing: border-box;
}

#stl_wrapper {
	position: relative;
	margin: 0 auto;
	width: calc(50% - 1px);
	height: 100%;
	background: #77a7fd;
}

#dicom_3d_wrapper {
	position: relative;
	margin: 0 auto;
	width: calc(50% - 1px);
	height: 100%;
	font-size: 30px;
}

#stl_cont {
	margin: 0 auto;
	visibility: hidden;
	width: 100%;
	height: 100%;
}

/* .layer {
  position: absolute;
  pointer-events: none;
} */

#trademarkImg {
	position: absolute;
	left: 10px;
	bottom: 27px;
	z-index: 9;
	height: 25px;
}

@media (max-width: 430px) {
	#trademarkImg {
		height: 20px;
	}
}

#copyrightText {
	position: absolute;
	left: 10px;
	bottom: 7px;
	z-index: 9;
	color: #ffffff;
	font-size: x-small;
}

#infoImgBtm {
	position: absolute;
	left: 93px;
	bottom: 29px;
	z-index: 9;
	height: 30px;
	cursor: pointer;
}

.swal-wide {
	width: auto;
}

.swal-content {
	margin-top: 25px;
	margin-bottom: 15px;
}

.dropdown-menu {
	z-index: 99999;
}

#viewModeHint {
	position: absolute;
	width: 250px;
	left: calc(50% - 125px);
	top: 50px;
	background-color: transparent;
	color: red;
	font-weight: bold;
	text-align: center;
}

.centered-spinner {
	width: 100px;
	height: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -50px;
	margin-top: -50px;
	display: none;
	text-align: center;
	z-index: 9;
}

.slick-prev:before,
.slick-next:before {
	color: #0069d9;
}

.slider .slick-list .slick-slide {
	margin: 0 4px;
}

#tooltip {
	position: fixed;
	left: 0;
	top: 0;
	min-width: 100px;
	text-align: center;
	padding: 5px 12px;
	font-family: monospace;
	background: #a0c020;
	display: none;
	opacity: 0;
	border: 1px solid black;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
	transition: opacity 0.25s linear;
	border-radius: 3px;
}

.implant-disabled:disabled {
	color: #6c757d !important;
	text-decoration: none;
	background-color: transparent;
	pointer-events: none;
}

.annotation-menu {
	font-size: 14px;
	font-family: Montserrat, sans-serif;
	display: none;
	position: absolute;
	width: auto;
	background-color: white;
	box-shadow: 0 0 5px grey;
	border-radius: 3px;
	max-width: 125px;
	z-index: 999;
}

.annotation-menu>button {
	width: 100%;
	background-color: #4687fc;
	color: white;
	border: none;
	margin: 0;
	padding: 5px;
	border-top: 1px solid #D9E6FE;
}

.annotation-menu>button:first-child {
	border-top: 0;
}

.annotation-menu button:hover {
	background-color: rgb(182, 182, 182);
}
</style>
