<template>
	<div class="dropdown dropright border-bottom-item">
		<button class="dropdown-item implant-disabled"
			v-on:click="enterColorModeLocal()"
			type="button">
			Color
		</button>
	</div>

</template>

<script>
import $ from "jquery";
import swal from "sweetalert";

export default {
	name: "ColorMenu",
	props: ["bones", "allTeeth"],
	components: {},
	methods: {
		enterColorModeLocal: function() {
			gtag('event', 'View->Color');
			vueApp.leaveModes();
			this.showColorModeHint();
			enterColorMode();
		},
		showColorModeHint: function() {
			// if (
			// 	!document.cookie
			// 		.split("; ")
			// 		.find(row => row.startsWith("stlCephxSetColorTooltip"))
			// ) {
			// 	var imgSrc = "images/set-color.gif";
			// 	swal({
			// 		title: "Click any tooth to set its individual color",
			// 		icon: imgSrc
			// 	});
			// 	$(".swal-title").insertBefore($(".swal-icon"));

			// 	var $closeButton = $(
			// 		"<button type='button' class='close swal-close' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
			// 	);
			// 	$(".swal-modal").append($closeButton);
			// 	$(".swal-close").click(function() {
			// 		swal.close();
			// 	});

			// 	document.cookie =
			// 		"stlCephxSetColorTooltip=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
			// }
		}
	}
};

function enterColorMode() {
	if (!vueApp.isColorMode) {
		vueApp.isColorMode = true;

		switchToggle("randomTeethColorDiv", vueApp.isRandomTeethColor);

		$(".color-mode-item").css("display", "block");
	}
}
</script>

<style scoped>
</style>