import * as YUKA from "yuka";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

function createGraph(mesh) {
  if (!mesh.graph) {
    const graph = new YUKA.Graph();
    // let vertices = getVertices(mesh);
    // const positionAttr = mesh.geometry.getAttribute("position");
    const faces = getFaces(mesh);
    for (let j = 0; j < faces.length; j++) {
      let face = faces[j];
      let nodeA = getNodeForPoint(graph, face.pointA);
      let nodeB = getNodeForPoint(graph, face.pointB);
      let nodeC = getNodeForPoint(graph, face.pointC);
      face.nodes = [nodeA, nodeB, nodeC];

      nodeA.faces.push(face);
      nodeB.faces.push(face);
      nodeC.faces.push(face);

      let distanceAB = Math.sqrt(
        nodeA.position.squaredDistanceTo(nodeB.position)
      );
      graph.addEdge(new YUKA.NavEdge(nodeA.index, nodeB.index, distanceAB));
      // graph.addEdge(new YUKA.NavEdge(nodeB.index, nodeA.index, distanceAB));

      let distanceAC = Math.sqrt(
        nodeA.position.squaredDistanceTo(nodeC.position)
      );
      graph.addEdge(new YUKA.NavEdge(nodeA.index, nodeC.index, distanceAC));
      // graph.addEdge(new YUKA.NavEdge(nodeC.index, nodeA.index, distanceAC));

      let distanceBC = Math.sqrt(
        nodeB.position.squaredDistanceTo(nodeC.position)
      );
      graph.addEdge(new YUKA.NavEdge(nodeB.index, nodeC.index, distanceBC));
      // graph.addEdge(new YUKA.NavEdge(nodeC.index, nodeB.index, distanceBC));
    }
    mesh.graph = graph;
    graph.faces = faces;
  }
  return mesh.graph;
}

function getNodeForPoint(graph, point) {
  let node = graph.getNode(point.index);
  if (!node) {
    point.gindex = point.index;
    // let positionAttr = mesh.geometry.attributes.position;
    // let point = new THREE.Vector3();
    // point
    //   .fromBufferAttribute(positionAttr, index)
    //   .applyMatrix4(mesh.matrixWorld);

    const position = new YUKA.Vector3(point.x, point.y, point.z);
    node = new YUKA.NavNode(point.index, position);
    node.point = point;
    node.faces = [];
    graph.addNode(node);
  }
  return node;
}

function getShortPath(graph, start, end) {
  let points = [];
  let graphSearch = new YUKA.AStar(graph, start, end); // new YUKA.AStar(graph, 0, 10); new YUKA.Dijkstra(graph, 0, 10);
  graphSearch.search();
  if (graphSearch.found) {
    //   const searchTree = graphSearch.getSearchTree();
    const path = graphSearch.getPath();
    // console.log(path);
    path.forEach((index) => {
      let node = graph.getNode(index);
      // let p = node.position;
      // let point = new THREE.Vector3(p.x, p.y, p.z);
      // point.gindex = index;
      points.push(node.point);
    });
  }
  return points;
}

function getPointIndex(graph, point, tolerance) {
  if (!tolerance) {
    tolerance = 0.001;
  }
  let pindex = -1;
  const position = new YUKA.Vector3(point.x, point.y, point.z);
  let toler = tolerance;
  while (pindex < 0) {
    for (let [index, node] of graph._nodes) {
      let dist = position.squaredDistanceTo(node.position);
      if (dist < toler) {
        // console.log(dist, index);
        pindex = index;
      }
    }
    toler += tolerance;
  }
  return pindex;
}

function getPointNode(graph, point, tolerance) {
  if (!tolerance) {
    tolerance = 0.001;
  }

  let pnode = null;
  const position = new YUKA.Vector3(point.x, point.y, point.z);
  let toler = tolerance;
  while (!pnode) {
    for (let [index, node] of graph._nodes) {
      let dist = position.squaredDistanceTo(node.position);
      if (dist < toler) {
        pnode = node;
      }
    }
    toler += tolerance;
  }
  return pnode;
}

function getPointNodes(graph, point, tolerance) {
  if (!tolerance) {
    tolerance = 0.001;
  }
  let nodes = [];
  const position = new YUKA.Vector3(point.x, point.y, point.z);
  for (let [index, node] of graph._nodes) {
    let dist = position.squaredDistanceTo(node.position);
    if (dist < tolerance) {
      // console.log(dist, index);
      nodes.push(node);
    }
  }
  return nodes;
}

function getFaces(mesh) {
  const faces = [];
  let geometry = mesh.geometry;
  //.clone();
  // console.log("getFaces1", geometry.getAttribute("position").array.length);
  // geometry = geometry.toNonIndexed();
  // console.log("getFaces2", geometry.getAttribute("position").array.length);
  // geometry.deleteAttribute("normal");
  // geometry.deleteAttribute("uv");
  // geometry = BufferGeometryUtils.mergeVertices(geometry);
  // console.log("getFaces3", geometry.getAttribute("position").array.length);

  const position = geometry.getAttribute("position");

  const index = geometry.index; //mesh.geometry.getIndex();
  if (index != null) {
    for (let i = 0; i < index.count; i += 3) {
      const face = {
        a: index.getX(i),
        b: index.getX(i + 1),
        c: index.getX(i + 2),
      };
      face.indexes = [face.a, face.b, face.c];
      faces.push(face);
    }
  } else {
    for (let i = 0; i < position.count; i += 3) {
      const face = {
        a: i,
        b: i + 1,
        c: i + 2,
      };
      face.indexes = [face.a, face.b, face.c];
      faces.push(face);
    }
  }

  for (let j = 0; j < faces.length; j++) {
    let face = faces[j];
    face.pointA = new THREE.Vector3(
      position.getX(face.a),
      position.getY(face.a),
      position.getZ(face.a)
    );
    face.pointB = new THREE.Vector3(
      position.getX(face.b),
      position.getY(face.b),
      position.getZ(face.b)
    );
    face.pointC = new THREE.Vector3(
      position.getX(face.c),
      position.getY(face.c),
      position.getZ(face.c)
    );
    face.pointA.applyMatrix4(mesh.matrixWorld);
    face.pointB.applyMatrix4(mesh.matrixWorld);
    face.pointC.applyMatrix4(mesh.matrixWorld);
    face.pointA.index = face.a;
    face.pointB.index = face.b;
    face.pointC.index = face.c;
    face.points = [face.pointA, face.pointB, face.pointC];
    face.midPoint = new THREE.Vector3();
    face.midPoint
      .copy(face.points[0])
      .add(face.points[1])
      .add(face.points[2])
      .multiplyScalar(1 / 3);
    face.midPointXZ = new THREE.Vector2(face.midPoint.x, face.midPoint.z);
    face.midPointZY = new THREE.Vector2(face.midPoint.z, face.midPoint.y);

    face.normal = new THREE.Vector3();
    let faceTriangle = new THREE.Triangle(
      face.pointA,
      face.pointB,
      face.pointC
    );
    faceTriangle.getNormal(face.normal);
    face.triangle = faceTriangle;
  }

  return faces;
}

function getVertices(mesh) {
  const position = mesh.geometry.getAttribute("position");
  const vertices = [];

  for (let i = 0; i < position.count; i++) {
    const vertex = new THREE.Vector3(
      position.getX(i),
      position.getY(i),
      position.getZ(i)
    );

    vertices.push(vertex);
  }

  return vertices;
}

function getFaceVertexUvs(mesh) {
  const faceVertexUvs = [];
  const uv = mesh.geometry.getAttribute("uv");

  const index = mesh.geometry.index;
  // const index = mesh.geometry.getIndex();
  if (index != null) {
    for (let i = 0; i < index.count; i += 3) {
      const faceVertexUv = [
        new THREE.Vector2(uv.getX(index.getX(i)), uv.getY(index.getX(i))),
        new THREE.Vector2(
          uv.getX(index.getX(i + 1)),
          uv.getY(index.getX(i + 1))
        ),
        new THREE.Vector2(
          uv.getX(index.getX(i + 2)),
          uv.getY(index.getX(i + 2))
        ),
      ];

      faceVertexUvs.push(faceVertexUv);
    }
  } else {
    for (let i = 0; i < uv.count; i += 3) {
      const faceVertexUv = [
        new THREE.Vector2(uv.getX(i), uv.getY(i)),
        new THREE.Vector2(uv.getX(i + 1), uv.getY(i + 1)),
        new THREE.Vector2(uv.getX(i + 2), uv.getY(i + 2)),
      ];

      faceVertexUvs.push(faceVertexUv);
    }
  }

  return faceVertexUvs;
}

export {
  createGraph,
  getShortPath,
  getPointIndex,
  getFaces,
  getPointNodes,
  getVertices,
  getPointNode,
};
