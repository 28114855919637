<template>
	<img class="crown-ctrl edit-ctrl-img" :src="resizeData.iconPath" v-on:click="resize(resizeData.action)"
		 v-bind:style="{left: resizeData.x + 'px', top: resizeData.y + 'px', display: resizeData.display, width: resizeData.width + 'px'}" />
</template>

<script>
	import { moveObject } from "../../editObject";

	export default {
		name: "ResizeCtrl",
		props: ['resizeData'],
		methods: {
			resize: function(action) {
				if (action)
					action(this.resizeData.objectId);
			}
		}
	}
</script>

<style scoped>
</style>