<template>
	<div id="orthoStageDialog" class="modal" tabindex="-1" role="dialog"
		aria-labelledby="orthoStageDialogTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div class="modal-content wizard-content">
				<div class="modal-header wizard-header">
					<h5 class="modal-title wizard-title"
						id="orthoStageDialogTitle">
						Enter Stage Name
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body wizard-body">
					<form>
						<div class="form-group">
							<input id="ortoStageName" v-model="mode.stageName"
								type="text" class="form-control wizard-input">
						</div>
					</form>
				</div>
				<div class="modal-footer wizard-footer" v-if="mode.saveStage">
					<button type="button"
						class="btn btn-primary btn-next"
						aria-label="Save"
						data-dismiss="modal"
						v-on:click="saveStage()">
						SAVE
					</button>
				</div>
				<div class="modal-footer wizard-footer" v-if="!mode.saveStage">
					<button type="button"
						class="btn btn-primary btn-next"
						aria-label="OK"
						data-dismiss="modal"
						v-on:click="enterOrthoMode()">
						OK
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	OrthoPlanMode,
	enterOrthoPlanMode,
	saveOrthoChanges
} from "./OrthoPlanMode";

export default {
	name: "OrthoStageDialog",
	props: ["mode"],
	methods: {
		saveStage: function() {
			saveOrthoChanges();
		},
		enterOrthoMode: function() {
			//console.log(OrthoPlanMode.stageName);
			enterOrthoPlanMode();
		}
	}
};

function showOrthoStageDialog(save) {
	// console.log("showOrthoStageDialog");
	if (save) {
		OrthoPlanMode.saveStage = true;
	} else {
		OrthoPlanMode.stageName = `STAGE ${vueApp.orthoStages.length + 1}`;
		OrthoPlanMode.saveStage = false;
	}

	$("#orthoStageDialog").modal();
}

export { showOrthoStageDialog };
</script>

<style scoped>
@import "../../css/wizard.css";

.modal-sm {
	max-width: 350px;
}

.modal-header {
	height: 60px;
}

.modal-title {
	max-width: 200px;
	left: calc(50% - 100px);
}

.modal-body {
	padding-left: 50px;
	padding-right: 50px;
}
</style>