<template>
	<div id="smoothMenu" class="dropdown-item border-bottom-item">
		<div class="form-check form-check-inline">
			<input id="cbSmoothing" class="form-check-input" type="checkbox" v-model="isSmoothing"
				@change="changeSmoothing()">
			<label class="form-check-label" for="cbSmoothing" v-on:click.stop>Smoothing</label>
		</div>
	</div>
</template>

<script>

export default {
	name: "SmoothMenu",
	data() {
		return {
			isSmoothing: true
		};
	},
	methods: {
		changeSmoothing: function () {
			gtag('event', `View->Smoothing->${this.isSmoothing}`);
			let flatShading = !this.isSmoothing;
			vueApp.allItems.forEach(function (model) {
				var vmesh = viewer.get_model_mesh(model.id);
				if (vmesh && vmesh.material) {
					vmesh.material.flatShading = flatShading;
					vmesh.material.needsUpdate = true;
				}
			});
		}
	}
};
</script>