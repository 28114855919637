<template>
	<div class="dropdown dropright border-bottom-item">
		<button class="dropdown-item" type="button" v-on:click="enterHideModeLocal()">
			Show/Hide
		</button>
	</div>
</template>

<script>
import $ from "jquery";
import swal from "sweetalert";

export default {
	name: "ShowHideMenu",
	props: ["bones", "allTeeth"],
	components: {},
	methods: {
		enterHideModeLocal: function () {
			gtag('event', 'View->Show/Hide');
			vueApp.leaveModes();
			// if (
			// 	!document.cookie
			// 		.split("; ")
			// 		.find(row => row.startsWith("stlCephxShowHideTooltip"))
			// ) {
			// 	var imgSrc = "images/show-hide.gif";
			// 	swal({
			// 		title: "Click any tooth to set its visibility",
			// 		icon: imgSrc
			// 	});
			// 	$(".swal-title").insertBefore($(".swal-icon"));
			// 	var $closeButton = $(
			// 		"<button type='button' class='close swal-close' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
			// 	);
			// 	$(".swal-modal").append($closeButton);
			// 	$(".swal-close").click(function() {
			// 		swal.close();
			// 	});

			// 	document.cookie =
			// 		"stlCephxShowHideTooltip=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
			// }

			enterHideMode();
		}
	}
};

function enterHideMode() {
	if (!vueApp.isHideMode) {
		vueApp.isHideMode = true;

		vueApp.allTeeth.forEach(function (item) {
			viewer.set_color(item.id, hideModeTeethColor); // 1.3
			viewer.set_opacity(item.id, item.isVisible ? 1 : 0.3); // 1.4
		});
		$(".hide-mode-item").css("display", "block"); // 1.5 - 1.7

		if (vueApp.savedData) {
			if (vueApp.savedData.bones.length > 0)
				switchToggle('hideMaxillaDiv', vueApp.savedData.bones[0].isVisible);
			if (vueApp.savedData.bones.length > 1)
				switchToggle('hideMandibleDiv', vueApp.savedData.bones[1].isVisible);

			if (vueApp.savedData.dentations.length > 0)
				switchToggle('hideUpperDiv', vueApp.savedData.dentations[0].isVisible);
			if (vueApp.savedData.dentations.length > 1)
				switchToggle('hideLowerDiv', vueApp.savedData.dentations[1].isVisible);

			if (vueApp.savedData.nerves && vueApp.savedData.nerves.length > 0) {
				switchToggle('hideNervesDiv', vueApp.savedData.nerves[0].isVisible);
			}
			else {
				$("#hideNervesDiv").css("display", "none");
			}

			if (vueApp.savedData.gingivae && vueApp.savedData.gingivae.length > 0) {
				switchToggle('hideGingivaeDiv', vueApp.savedData.gingivae[0].isVisible);
			} else {
				$("#hideGingivaeDiv").css("display", "none");
			}

			if (vueApp.savedData.airways && vueApp.savedData.airways.length > 0) {
				switchToggle('hideAirwaysDiv', vueApp.savedData.airways[0].isVisible);
			} else {
				$("#hideAirwaysDiv").css("display", "none");
			}
		}

	}
}
</script>

<style>
.swal-title {
	margin: 0;
	padding: 0;
	font-size: x-large;
	/* font-weight: normal; */
}

.swal-icon {
	margin-bottom: 0;
}

.swal-checkbox-div {
	position: absolute;
	bottom: 31px;
	left: 21px;
	cursor: pointer;
	text-align: center;
	/* background-color: aqua; */
	padding: 0;
	min-width: 200px;
	min-height: 20px;

}

.swal-checkbox-cb {
	position: absolute;
	height: 14px;
	width: 14px;
	left: 0;
	top: 5px;
}

.swal-checkbox-lb {
	position: absolute;
	top: 1px;
	left: 23px;
	border: 0;
	max-width: 240px;
	text-align: left;
	font-size: 15px;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-user-select: none;
	/* Safari */
	-ms-user-select: none;
	/* IE 10 and IE 11 */
	user-select: none;
	/* Standard syntax */
	/* color: rgba(0, 0, 0, .64); */
}

.swal-close {
	position: absolute;
	right: 5px;
	top: 1px;
	outline: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.swal-close:focus {
	outline: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	/* mobile webkit */
}
</style>