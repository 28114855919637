<template>
	<div id="annotationAddDialog" class="modal" tabindex="-1" role="dialog" aria-labelledby="toothAddDialogTitle"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<form class="modal-content dialog-content">
				<div class="modal-body wizard-body">
					<h5 class="modal-title wizard-title" id="toothAddDialogTitle">
						Add Tooth
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"
						style="z-index: 9; width: 30px;">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="container-fluid">
						<div class="mb-3 mt-4">
							<label for="addToothNumberInput" class="form-label">Tooth Number:</label>
							<input v-model="mode.newTooth.number" id="addToothNumberInput" type="number"
								class="form-control" v-on:keydown.stop>
						</div>
						<div class="row container-group">
							<div class="form-check">
								<input type="radio" class="form-check-input" id="addToothPrimaryCheck" name="numberType"
									value="primary" v-model="mode.newTooth.numberType">
								<label class="form-check-label text-truncate" for="addToothPrimaryCheck">Permanent</label>
							</div>
							<div class="form-check">
								<input type="radio" class="form-check-input" id="addToothDeciduousCheck" name="numberType"
									value="deciduous" v-model="mode.newTooth.numberType">
								<label class="form-check-label text-truncate" for="addToothDeciduousCheck">Deciduous</label>
							</div>
							<div class="form-check">
								<input type="radio" class="form-check-input" id="addToothSupernumeraryCheck"
									name="numberType" value="supernumerary" v-model="mode.newTooth.numberType">
								<label class="form-check-label text-truncate" for="addToothSupernumeraryCheck">
									Supernumerary</label>
							</div>
						</div>
						<div class="mb-3">
							<label for="addToothRemarksInput" class="form-label">Remarks:</label>
							<input v-model="mode.newTooth.remarks" id="addToothRemarksInput" class="form-control"
								type="text" maxlength="50" pattern="[a-zA-Z0-9\s]+" title="Alphanumeric only"
								v-on:keydown.stop>
						</div>
						<!-- <div class="row container-group">
							<div class="form-check">
								<input type="checkbox" class="form-check-input" id="addToothSmooth"
									v-model="mode.newTooth.smooth">
								<label class="form-check-label text-truncate" for="addToothSmooth">Smooth STL</label>
							</div>
						</div> -->
					</div>
				</div>
				<div class="modal-footer wizard-footer">
					<button type="button" class="btn btn-primary btn-next" aria-label="OK" v-on:click="addToothClick()">
						OK
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { addNewAnnotation } from "./DicomManager";
import swal from "sweetalert";

const alphanumericRegex = /^[a-z0-9 ]*$/i;

export default {
	name: "annotationAddDialog",
	props: ["mode", "app"],
	data() {
		return {
			file: null,
		};
	},
	methods: {
		addToothClick: async function () {
			// console.log("addToothClick", this.mode.newTooth);
			let newNumber = this.mode.newTooth.number;
			if (!newNumber || newNumber < 1) {
				swal({
					text: "Tooth number must be positive value"
				});
			} else if (!alphanumericRegex.test(this.mode.newTooth.remarks)) {
				swal({
					text: "Remarks can be alphanumeric only (with spaces)"
				});
			} else {				
				$("#annotationAddDialog").modal("hide");
				addNewAnnotation(this.app, this.mode.newTooth);
			}
		}
	}
};

</script>

<style scoped>
@import "@/css/wizard.css";

#toothAddDialogTitle {
	position: absolute;
	left: calc(50% - 50px);
	top: 12px;
}

#addToothFileDiv {
	margin-top: 20px;
	margin-bottom: 20px;
}

.modal-sm {
	max-width: 500px;
	text-align: left;
}

.container-group {
	border: 2px solid #d7dee5;
	border-radius: 0.25rem;
	margin: 30px 0 20px 0;
	padding: 10px 0 4px 0;
	justify-content: space-between;
}

.form-check {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	max-width: calc(100% - 35px);
}

.form-check-label {
	display: inline-block;
	width: calc(100% + 25px);
}

.modal-header {
	height: 60px;
}

.modal-title {
	max-width: 270px;
	left: 45px;
	/* calc(50% - 55px); */
}

.modal-body {
	padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
}

.close {
	margin-right: -10px;
	margin-top: -20px;
}
</style>