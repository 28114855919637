import $ from "jquery"; // , { data }
import ToothNumberControls from "./ToothNumberControls.vue";
import ToothNumberButtons from "./ToothNumberButtons.vue";
import ToothNumberDialog from "./ToothNumberDialog.vue";
import { updateRaycaster, intersectTooth } from "@/App";
import { updateToothNumbers } from "@/components/saveLoadData";
import { loadFont, drawToothNumber } from "@/components/draw/draw.js";
import { globalPlanes, empty } from "@/components/reports/ClipDialog.vue";

let controlsInitialized = false;
let numberFont = null;
let dy = 3;
let dz = 6;
let maxBox = null;
let maxCenter = null;
let mandBox = null;
let mandCenter = null;
let numbersVisible = false;

var ToothNumberMode = {
  changedFiles: [],
  isActive: false,
  isOldNumbers: false,
  selectedTooth: {
    number: 0,
    savedNumber: 0,
    newNumber: 0,
    newNumberType: "primary",
    newRemarks: "",
  },
  changingTooth: null,
  changingAnnotation: null,
  setChangingTooth: function (tooth) {
    this.changingTooth = tooth;
    // if (!tooth.savedNumber) {
    // 	tooth.savedNumber = tooth.number;
    // }
    if (!tooth.newNumber) {
      tooth.newNumber = tooth.savedNumber;
    }
    if (!tooth.newNumberType) {
      tooth.newNumberType = tooth.numberType ? tooth.numberType : "primary";
    }
    if (!tooth.newRemarks) {
      tooth.newRemarks = tooth.remarks ? tooth.remarks : "";
    }
    this.selectedTooth.number = tooth.number;
    this.selectedTooth.savedNumber = tooth.savedNumber;
    this.selectedTooth.newNumber = tooth.newNumber;
    this.selectedTooth.newNumberType = tooth.newNumberType;
    this.selectedTooth.newRemarks = tooth.newRemarks;
  },
  resetSelectedTooth: function () {
    this.selectedTooth.number = 0;
    this.selectedTooth.savedNumber = 0;
    this.selectedTooth.newNumber = 0;
    this.selectedTooth.newNumberType = "primary";
    this.selectedTooth.newRemarks = "";
  },
  enterMode: function () {
    enterToothNumberMode();
  },
  leaveMode: function (saveActiveMode) {
    leaveToothNumberMode(false, saveActiveMode);
  },
  mouseMove: function (event) {
    return true;
  },
  mouseUp: function (event) {
    return true;
  },
  mouseClick: function (event) {
    if (this.isOldNumbers) return;
    updateRaycaster(event);
    var tooth = intersectTooth();
    if (tooth) {
      this.setChangingTooth(tooth);
      showToothNumberDialog();
    }
  },
};

async function showOldToothNumbers() {
  enterToothNumberMode(true);
}

async function enterToothNumberMode(isOldNumbers) {
  initToothNumberControls();
  vueApp.leaveModes(false, true);

  viewer.renderer.clippingPlanes = empty;

  $(".tooth-number-mode-item").css("display", "block");
  if (isOldNumbers) {
    $("#toothNumberSave").css("display", "none");
    $("#toothNumberControls").css("display", "none");
  } else {
    $("#toothNumberSave").css("display", "inline-block");
  }
  vueApp.activeMode = ToothNumberMode;
  ToothNumberMode.isActive = true;
  ToothNumberMode.isOldNumbers = isOldNumbers;

  //   vueApp.allItems.forEach((item) => {
  //     if (item.isVisible) viewer.set_opacity(item.id, item.isTooth ? 0.5 : 0);
  //   });

  await drawTeethNumbers();
}

function initToothNumberControls() {
  if (!controlsInitialized) {
    var ctrls = [ToothNumberControls, ToothNumberButtons, ToothNumberDialog];
    ctrls.forEach((component, index) => {
      const componentInstance = new Vue({
        ...component,
        propsData: { mode: ToothNumberMode },
      });
      const vueContainer = document.createElement("div");
      let wrapper = document.getElementById("stl_wrapper");
      if (index > 1) {
        wrapper = document.getElementById("views_cont");
      }
      wrapper.appendChild(vueContainer);
      componentInstance.$mount(vueContainer);
    });
    controlsInitialized = true;
  }
}

async function initToothNumberOptions() {
  if (!numberFont) {
    numberFont = vueApp.defaultFont
      ? vueApp.defaultFont
      : await loadFont("fonts/helvetiker_regular.typeface.json");
    viewer.controls.addEventListener("change", onCameraChangeForNumbers);
  }

  if (!maxCenter || !mandCenter) {
    maxBox = getTeethBox(true);
    maxCenter = new THREE.Vector3(
      maxBox.center.x,
      maxBox.min.y + dy,
      maxBox.min.z + dz * 2
    );
    //   drawPoint(maxCenter, 0x0000ff);

    mandBox = getTeethBox(false);
    mandCenter = new THREE.Vector3(
      mandBox.center.x,
      mandBox.max.y - dy * 1.8,
      mandBox.min.z + dz * 2
    );
    //   drawPoint(mandCenter, 0xffff00);
  }
}

function leaveToothNumberMode(saveChanges, saveActiveMode) {
  removeTeethNumbers();

  vueApp.allItems.forEach(function (item) {
    if (item.isVisible) viewer.set_opacity(item.id, item.opacity); // item.isVisible &&
  });
  $(".tooth-number-mode-item").css("display", "none");
  //if (!saveActiveMode)
  vueApp.activeMode = null;
  ToothNumberMode.resetSelectedTooth();
  ToothNumberMode.isActive = false;
  vueApp.leaveModes(false, true);

  viewer.renderer.clippingPlanes =
    vueApp.savedData.clipData && vueApp.savedData.clipData.isEnabled
      ? globalPlanes
      : empty;

  if (ToothNumberMode.changedFiles.length > 0) {
    let uniqueFiles = [...new Set(ToothNumberMode.changedFiles)];
    if (saveChanges) {
      let numberChanges = getNumberChanges();
      if (numberChanges.length > 0) {
        updateToothNumbers(numberChanges);
      }
    } else {
      for (let filename of uniqueFiles) {
        let tooth = vueApp.getItemByFilename(filename);
        if (tooth) {
          tooth.newNumber = tooth.savedNumber;
          tooth.newNumberType = tooth.numberType ? tooth.numberType : "primary";
          tooth.newRemarks = tooth.remarks ? tooth.remarks : "";
          tooth.newPrevTag = tooth.prevTag ? tooth.prevTag : "";
        }
      }
    }
    ToothNumberMode.changedFiles = [];
  }
}

function getNumberChanges(resetChanges) {
  let uniqueFiles = [...new Set(ToothNumberMode.changedFiles)];
  let numberChanges = [];
  for (let filename of uniqueFiles) {
    let tooth = vueApp.getItemByFilename(filename);
    if (tooth) {
      let oldNumber = +tooth.savedNumber;
      let newNumber = tooth.newNumber ? +tooth.newNumber : +tooth.savedNumber;
      let oldNumberType = tooth.numberType ? tooth.numberType : "primary";
      let newNumberType = tooth.newNumberType ? tooth.newNumberType : "primary";
      let oldRemarks = tooth.remarks ? tooth.remarks : "";
      let newRemarks = tooth.newRemarks ? tooth.newRemarks : "";
      let oldPrevTag = tooth.prevTag ? tooth.prevTag : "";
      let newPrevTag = tooth.newPrevTag ? tooth.newPrevTag : "";
      let oldAddedTag = tooth.addedTag ? tooth.addedTag : null;
      let newAddedTag = tooth.newAddedTag ? tooth.newAddedTag : null;

      if (
        oldNumber !== newNumber ||
        oldNumberType !== newNumberType ||
        oldRemarks !== newRemarks ||
        oldPrevTag !== newPrevTag // || oldAddedTag !== newAddedTag
      ) {
        numberChanges.push({
          baseNumber: +tooth.number,
          fileName: tooth.filename,
          oldNumber: oldNumber,
          newNumber: newNumber,
          oldNumberType: oldNumberType,
          newNumberType: newNumberType,
          oldRemarks: oldRemarks,
          newRemarks: newRemarks,
          oldPrevTag: oldPrevTag,
          newPrevTag: newPrevTag,
          oldAddedTag: oldAddedTag,
          newAddedTag: newAddedTag,
        });
      }

      // tooth.name = tooth.newNumber.toString();
      tooth.savedNumber = tooth.newNumber;
      tooth.numberType = tooth.newNumberType;
      tooth.remarks = tooth.newRemarks;
      tooth.prevTag = tooth.newPrevTag;
      if (tooth.newAddedTag) {
        tooth.addedTag = tooth.newAddedTag;
      }
    }
  }
  if (resetChanges) {
    ToothNumberMode.changedFiles = [];
  }
  return numberChanges;
}

async function drawTeethNumbers() {
  await initToothNumberOptions();
  vueApp.allTeeth.forEach((tooth) => {
    let mesh = viewer.get_model_mesh(tooth.id);
    if (!mesh.textPoint) {
      let center = tooth.isMaxilla ? maxCenter : mandCenter;
      let box = tooth.defaultBox;

      let x = box.center.x;
      let y = tooth.isMaxilla ? box.min.y + dy : box.max.y - dy * 1.8;
      let z = box.center.z;
      mesh.apexCenter = new THREE.Vector3(x, y, z);
      // drawPoint(tempCenter, 0x00ff00);
      let distance = calculateDistance(mesh.apexCenter, center);
      let coef = (distance + dz * 2) / distance;
      let px = (x - center.x) * coef + center.x;
      let py = (y - center.y) * coef + center.y;
      let pz = (z - center.z) * coef + center.z;
      mesh.textPoint = new THREE.Vector3(px, py, pz);
      // drawPoint(targetPomesh.textPoint int1, tooth.isMaxilla ? 0x0000ff : 0xffff00);
      // drawLine(textPoint, center);
      coef = (distance + dz) / distance;
      px = (x - center.x) * coef + center.x;
      py = (y - center.y) * coef + center.y;
      pz = (z - center.z) * coef + center.z;
      mesh.apexEdge = new THREE.Vector3(px, py, pz);
      // drawPoint(mesh.textLookPoint, tooth.isMaxilla ? 0x0000ff : 0xffff00);
    }

    if (vueApp.isEngineerMode) {
      redrawToothNumber(tooth, ToothNumberMode.isOldNumbers);
    } else {
      showToothNumber(tooth);
    }
  });
  numbersVisible = true;
}

function getTeethBox(isMaxilla) {
  var min = { x: 100000, y: 100000, z: 100000 };
  var max = { x: -10000, y: -100000, z: -100000 };
  var teethBox = { min, max };
  vueApp.allItems.forEach(function (model) {
    if (model.isTooth && (isMaxilla == null || model.isMaxilla == isMaxilla)) {
      var mesh = viewer.get_model_mesh(model.id);
      var geometry = mesh.geometry.clone();
      geometry.applyMatrix4(mesh.matrix);
      var toothBox = geometry.boundingBox;
      if (toothBox.min.x < teethBox.min.x) {
        teethBox.min.x = toothBox.min.x;
      }
      if (toothBox.min.y < teethBox.min.y) {
        teethBox.min.y = toothBox.min.y;
      }
      if (toothBox.min.z < teethBox.min.z) {
        teethBox.min.z = toothBox.min.z;
      }
      if (toothBox.max.x > teethBox.max.x) {
        teethBox.max.x = toothBox.max.x;
      }
      if (toothBox.max.y > teethBox.max.y) {
        teethBox.max.y = toothBox.max.y;
      }
      if (toothBox.max.z > teethBox.max.z) {
        teethBox.max.z = toothBox.max.z;
      }
    }
  });
  teethBox = offsetBox(teethBox, 0, 0, 0, 0);
  //   drawBox(teethBox);
  return teethBox;
}

function showToothNumber(tooth) {
  if (tooth) {
    let mesh = viewer.get_model_mesh(tooth.id);
    if (!mesh.numberGroup) {
      drawToothNumber(tooth, numberFont);
    } else {
      viewer.scene.add(mesh.numberGroup);
    }
    mesh.numberMesh.lookAt(viewer.camera.position);
  }
}

function redrawToothNumber(tooth, isOldNumbers) {
  if (tooth) {
    let mesh = viewer.get_model_mesh(tooth.id);
    if (!mesh || !mesh.geometry || !mesh.geometry.boundingBox) {
      return;
    }

    if (mesh.numberGroup) {
      viewer.scene.remove(mesh.numberGroup);
    }
    drawToothNumber(tooth, numberFont, isOldNumbers);
    if (mesh.numberMesh) {
      mesh.numberMesh.lookAt(viewer.camera.position);
    }
  }
}

function removeTeethNumbers() {
  vueApp.allTeeth.forEach((item) => {
    let mesh = viewer.get_model_mesh(item.id);
    if (mesh.numberGroup) {
      viewer.scene.remove(mesh.numberGroup);
    }
  });
  numbersVisible = false;
}

function onCameraChangeForNumbers(controls) {
  if (numbersVisible) {
    vueApp.allTeeth.forEach((item) => {
      let mesh = viewer.get_model_mesh(item.id);
      if (mesh.numberMesh) {
        mesh.numberMesh.lookAt(viewer.camera.position);
      }
    });
  }
}

import { showToothNumberDialog } from "./ToothNumberDialog.vue";

export {
  ToothNumberMode,
  initToothNumberControls,
  enterToothNumberMode,
  leaveToothNumberMode,
  redrawToothNumber,
  showOldToothNumbers,
  drawTeethNumbers,
  removeTeethNumbers,
  getNumberChanges,
};
