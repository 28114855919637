import { setHighlightedToothColor } from "../../App";
import { globalPlanes, empty } from "@/components/reports/ClipDialog.vue";
import { removeTeethNumbers } from "../tooth/number/ToothNumberMode";

let currentApp = null;
let currentAnnotation = null;

let AnnotationMode = {
  isActive: false,
  enterProcessing: false,
  enterMode: function () {
    enterAnnotationMode();
  },
  leaveMode: function (saveActiveMode) {
    leaveAnnotationMode(saveActiveMode);
  },
  mouseUp: function (event) {
    return false;
  },
  mouseMove: function (event) {
    return currentApp && currentApp.is3D && !currentApp.focusedAnnotation;
  },
  mouseClick: function (event) {},
  toothEnter: function (tooth) {
    if (currentApp) {
      // console.log("toothEnter", tooth, currentApp);
      let filename = tooth ? tooth.filename : null;
      let newAnnotation = currentApp.annotations.find(
        (a) => a.filename == filename
      );

      if (currentAnnotation != newAnnotation) {
        if (currentAnnotation) {
          //   if (!currentAnnotation.isTooth || currentApp.isEditingAnnotation) return;
          currentAnnotation.isHovered = false;
          if (currentAnnotation.figures) {
            currentAnnotation.figures.forEach((figure) => {
              let splineLayer = figure.spline.getChildren()[1];
              if (splineLayer) splineLayer.fill("transparent");
            });
          }
          if (currentAnnotation.childAnnotations) {
            currentAnnotation.childAnnotations
              .filter((a) => a.figures)
              .forEach((a) => {
                a.figures.forEach((figure) => {
                  let splineLayer = figure.spline.getChildren()[1];
                  if (splineLayer) splineLayer.fill("transparent");
                });
              });
          }
        }

        if (newAnnotation) {
          newAnnotation.isHovered = true;
          if (newAnnotation.figures) {
            newAnnotation.figures.forEach((figure) => {
              let splineLayer = figure.spline.getChildren()[1];
              if (splineLayer) splineLayer.fill(newAnnotation.color);
            });
          }
          if (newAnnotation.childAnnotations) {
            newAnnotation.childAnnotations
              .filter((a) => a.figures)
              .forEach((a) => {
                a.figures.forEach((figure) => {
                  let splineLayer = figure.spline.getChildren()[1];
                  if (splineLayer) splineLayer.fill(newAnnotation.color);
                });
              });
          }
        }
        currentAnnotation = newAnnotation;
      }
    }
  },
};

function enterAnnotationMode(app) {
  console.log("enterAnnotationMode", app);
  vueApp.leaveModes(false, true);
  vueApp.activeMode = AnnotationMode;
  AnnotationMode.isActive = true;
  currentApp = app;
  viewer.renderer.clippingPlanes = empty;
  $("#bottomPanel").css("display", "none");
}

function leaveAnnotationMode() {
  console.log("leaveAnnotationMode");
  vueApp.activeMode = null;
  AnnotationMode.isActive = false;
  vueApp.leaveModes(false, true);

  if (currentApp && currentApp.showTeethNumbers) {
    currentApp.showTeethNumbers = false;
    removeTeethNumbers();
  }
  viewer.renderer.clippingPlanes =
    vueApp.savedData.clipData && vueApp.savedData.clipData.isEnabled
      ? globalPlanes
      : empty;
  $("#bottomPanel").css("display", "block");

  currentApp = null;
}

function highlightAnnotation(annotation) {
  if (currentApp && currentApp.is3D && !currentApp.focusedAnnotation) {
    let tooth = annotation
      ? vueApp.allTeeth.find((t) => annotation.filename == t.filename)
      : null;
    vueApp.allTeeth.forEach(function (model) {
      if (model != tooth && model.isVisible) {
        let color = vueApp.getActualColor(model);
        viewer.set_color(model.id, color);
      }
    });

    if (tooth) setHighlightedToothColor(tooth);
  }
}

function unhighlightAnnotation(annotation) {
  if (currentApp && currentApp.is3D && !currentApp.focusedAnnotation) {
    let tooth = annotation
      ? vueApp.allTeeth.find((t) => annotation.filename == t.filename)
      : null;
    if (tooth) {
      let color = vueApp.getActualColor(tooth);
      viewer.set_color(tooth.id, color);
    }
  }
}

export {
  AnnotationMode,
  enterAnnotationMode,
  leaveAnnotationMode,
  highlightAnnotation,
  unhighlightAnnotation,
};
