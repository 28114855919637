import { getDistance, drawFace } from "./IntraoralDraw";
import { getPointNode } from "./GraphHelper";

//#region Tube Line

function createTubeLine(point1, point2, color, radius) {
  if (!color) {
    color = 0x000000;
  }
  if (!radius) {
    radius = 0.1;
  }

  let material = new THREE.MeshBasicMaterial({
    color,
    // transparent: true,
    // toneMapped: false,
    // depthWrite: false,
    // depthTest: false,
    // side: THREE.DoubleSide,
  });
  let curve = new THREE.LineCurve3(point1, point2);
  //   let curve =   new THREE.CatmullRomCurve3(points);
  let tubeGeometry = new THREE.TubeGeometry(
    curve,
    1, // tubularSegments
    radius, // radius
    8, // radialSegments
    false // closed
  );
  let line = new THREE.Mesh(tubeGeometry, material);
  return line;
}

function updateTubeLine(line, point1, point2, radius) {
  let oldGeometry = line.geometry;
  let oldParams = oldGeometry.parameters;
  if (!radius) {
    radius = oldParams.radius;
  }

  let newCurve = new THREE.LineCurve3(point1, point2);
  let newGeometry = new THREE.TubeGeometry(
    newCurve,
    oldParams.tubularSegments,
    radius,
    oldParams.radialSegments,
    oldParams.closed
  );

  oldGeometry.dispose();
  line.geometry = newGeometry;
  line.geometry.computeVertexNormals();
  // line.geometry.verticesNeedUpdate = true;
}

function createTubeCurve(points, color, radius) {
  if (!points || points.length < 2) {
    return;
  }

  if (!color) {
    color = 0x000000;
  }
  if (!radius) {
    radius = 0.1;
  }
  // radius = 0.02;
  radius = 0.1;
  let material = new THREE.MeshBasicMaterial({ color });

  let curvePath = new THREE.CurvePath();
  for (let i = 0; i < points.length - 1; i++) {
    let point1 = points[i];
    let point2 = points[i + 1];
    curvePath.add(new THREE.LineCurve3(point1, point2));
  }
  // let curvePath = new THREE.CatmullRomCurve3(points);
  let tubeGeometry = new THREE.TubeGeometry(
    curvePath,
    points.length * 2, // tubularSegments // 64
    radius, // radius
    8, // radialSegments
    false // closed
  );
  let line = new THREE.Mesh(tubeGeometry, material);
  line.name = "line";
  line.points = points;
  // line.visible = false;
  return line;
}

function updateTubeCurve(curve, points, fromBezier) {
  if (!curve || points.length < 2) {
    return;
  }

  let oldGeometry = curve.geometry;
  let oldParams = oldGeometry.parameters;

  let curvePath = new THREE.CurvePath();
  for (let i = 0; i < points.length - 1; i++) {
    let point1 = points[i];
    let point2 = points[i + 1];
    curvePath.add(new THREE.LineCurve3(point1, point2));
  }
  // let curvePath = new THREE.CatmullRomCurve3(points);
  let newGeometry = new THREE.TubeGeometry(
    curvePath,
    points.length * 2, //oldParams.tubularSegments,
    oldParams.radius,
    oldParams.radialSegments,
    oldParams.closed
  );

  oldGeometry.dispose();
  curve.geometry = newGeometry;
  curve.geometry.computeVertexNormals();
}

function updateBezierPoints(mainPoints, globalGraph) {
  if (!mainPoints || mainPoints.length < 2) {
    return;
  }
  const splitCount = 1;

  let pts = [];
  let lpts = [];
  mainPoints.forEach((mp1, i) => {
    // let mpt1 = mp1.position.clone();
    mp1.shortPoints = [];
    let linePoints = mp1.line1.shortPoints ? mp1.line1.shortPoints : mp1.line1.points;
    let ldistance = 0;
    linePoints.forEach((lp1, index) => {
      let lp2 = linePoints[index + 1];
      if (lp2) {
        lpts.push(lp1.clone());
        lp1.distanceFromStart = 0;
        if (index > 0) {
          // lp1 = lp1.clone();
          lp1.distanceFromStart = ldistance;
          // lp1.z = mpt1.z + mdz * (index / llength);
          mp1.shortPoints.push(lp1);
        }
        let distanceDif = Math.abs(lp2.z - lp1.z); //getDistance(lp1, lp2);
        ldistance += distanceDif;
        if (splitCount > 1) {
          let dx = lp2.x - lp1.x;
          let dy = lp2.y - lp1.y;
          let dz = lp2.z - lp1.z;
          for (let i = 1; i < splitCount; i++) {
            let part = i / splitCount;
            let sp = new THREE.Vector3(
              lp1.x + dx * part,
              lp1.y + dy * part,
              lp1.z + dz * part
            );
            sp.gindex = lp1.gindex;
            sp.distanceFromStart = lp1.distanceFromStart + distanceDif * part;
            mp1.shortPoints.push(sp);
            lpts.push(sp);
          }
        }
      }
    });
    mp1.totalDistance = ldistance;
    pts.push(mp1);
  });

  let bezierPoints = getBezierPoints(pts, globalGraph); //, radius * 2);
  // console.log("lpts", lpts.length, "bezierPoints", bezierPoints.length);

  mainPoints.forEach((mp1, i) => {
    mp1.line1.bezierPoints = mp1.bezierPoints;
    updateTubeCurve(mp1.line1, mp1.bezierPoints);
  });

  // return group;
}

//#endregion

//#region Bezier

function getBezierPoints(originalPoints, globalGraph, dz) {
  let pts = [];
  let maxZ = -Infinity;
  originalPoints
    .map((x) => x.position)
    .forEach((point3D) => {
      pts.push(point3D.x, point3D.y);
      if (point3D.z > maxZ) {
        maxZ = point3D.z;
      }
    });

  let cp = [];
  let length = pts.length;
  pts.push(pts[0], pts[1], pts[2], pts[3]);
  pts.unshift(pts[length - 1]);
  pts.unshift(pts[length - 1]);
  for (var i = 0; i < length; i += 2) {
    cp = cp.concat(
      getControlPoints(
        pts[i],
        pts[i + 1],
        pts[i + 2],
        pts[i + 3],
        pts[i + 4],
        pts[i + 5],
        0.5
      )
    );
  }
  cp = cp.concat(cp[0], cp[1]);
  let bezierPoints = []; // new Map();
  for (let i = 2, j = 0; i < length + 2; i += 2, j++) {
    let op1 = originalPoints[j];
    let p1 = op1.position.clone();
    op1.bezierPoints = [];
    op1.bezierPoints.push(p1);
    bezierPoints.push(p1);
    if (dz) p1.z += dz;
    op1.p0 = { x: pts[i], y: pts[i + 1] };
    op1.p1 = { x: cp[2 * i - 2], y: cp[2 * i - 1] };
    op1.p2 = { x: cp[2 * i], y: cp[2 * i + 1] };
    op1.p3 = { x: pts[i + 2], y: pts[i + 3] };

    op1.shortPoints.forEach((sp, index) => {
      let splitPoint = splitBezierCurve(
        op1.p0,
        op1.p1,
        op1.p2,
        op1.p3,
        sp.distanceFromStart / op1.totalDistance
      );
      splitPoint.z = sp.z;
      if (dz) splitPoint.z += dz;

      if (sp.gindex) {
        let node = globalGraph.getNode(sp.gindex);
        if (node) {
          // console.log(node);
          let shortestDist = Infinity;
          let npoint = null;
          node.faces.forEach((f) => {
            // console.log(f);
            let tpoint = new THREE.Vector3();
            f.triangle.closestPointToPoint(splitPoint, tpoint);
            let tdist = getDistance(tpoint, splitPoint);
            if (tdist < shortestDist) {
              npoint = tpoint;
              shortestDist = tdist;
            }
          });
          if (npoint) {
            splitPoint = npoint;
            // splitPoint.z = npoint.z;
          } else {
            console.log("!!! npoint");
          }
        }
      }
      let bp = new THREE.Vector3(splitPoint.x, splitPoint.y, splitPoint.z);
      op1.bezierPoints.push(bp);
      bezierPoints.push(bp);
    });

    let op2 = originalPoints[j + 1];
    if (!op2) {
      op2 = originalPoints[0];
    }
    let p2 = op2.position.clone();
    if (dz) p2.z += dz;
    op1.bezierPoints.push(p2);
  }
  return bezierPoints;
}

function getControlPoints(x0, y0, x1, y1, x2, y2, t) {
  //  x0,y0,x1,y1 are the coordinates of the end (knot) pts of this segment
  //  x2,y2 is the next knot -- not connected here but needed to calculate p2
  //  p1 is the control point calculated here, from x1 back toward x0.
  //  p2 is the next control point, calculated here and returned to become the
  //  next segment's p1.
  //  t is the 'tension' which controls how far the control points spread.

  //  Scaling factors: distances from this knot to the previous and following knots.
  var d01 = Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
  var d12 = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

  var fa = (t * d01) / (d01 + d12);
  var fb = t - fa;

  var p1x = x1 + fa * (x0 - x2);
  var p1y = y1 + fa * (y0 - y2);

  var p2x = x1 - fb * (x0 - x2);
  var p2y = y1 - fb * (y0 - y2);

  return [p1x, p1y, p2x, p2y];
}

function getSplitPoints(kp, splitCount) {
  const splitPoints = [];
  for (let i = 1; i < splitCount; i++) {
    let splitPoint = splitBezierCurve(
      kp.p0,
      kp.p1,
      kp.p2,
      kp.p3,
      i / splitCount
    );
    splitPoints.push(splitPoint);
  }
  return splitPoints;
}

function splitBezierCurve(p0, p1, p2, p3, proportion) {
  function lerp(a, b, t) {
    var s = 1 - t;
    return { x: a.x * s + b.x * t, y: a.y * s + b.y * t };
  }

  var p4 = lerp(p0, p1, proportion);
  var p5 = lerp(p1, p2, proportion);
  var p6 = lerp(p2, p3, proportion);
  var p7 = lerp(p4, p5, proportion);
  var p8 = lerp(p5, p6, proportion);
  var p9 = lerp(p7, p8, proportion);

  return p9;
}

function getPointsMesh(points, color) {
  const vertices = [];
  points.forEach((p) => {
    vertices.push(p.x, p.y, p.z);
  });
  let geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  let material = new THREE.PointsMaterial({ color, size: 0.05 });
  return new THREE.Points(geometry, material);
}

function adjustBezierPoints() {
  // bezierPoints.forEach((bp, index) => {
  //   let node = getPointNode(globalGraph, bp, 0.001);
  //   if (node) {
  //     let npoint = node.position;
  //     bp.x = npoint.x;
  //     bp.y = npoint.y;
  //     bp.z = npoint.z;
  //   }
  // });
  // let origins = [];
  // bezierPoints.forEach((bp) => {
  //   if (bp.shortPoints) return;
  //   let origin = new THREE.Vector3(bp.x, bp.y, bp.z - 10);
  //   origins.push(origin);
  //   let target = bp.clone();
  //   let direction = target.sub(origin).normalize();
  //   raycaster.set(origin, direction);
  //   let shortest = Infinity;
  //   let intersects = raycaster.intersectObject(globalMesh);
  //   let z =  bp.z;
  //   for (let intersect of intersects) {
  //     let dist = getDistance(bp, intersect.point);
  //     if (dist < shortest) {
  //       z = intersect.point.z;
  //       shortest = dist;
  //     }
  //   }
  //   bp.z = z;
  //   if (intersects.length == 0) {
  //     console.log("ALARM intersects!!!");
  //   }
  // });
}

//#endregion

//#region Export
export {
  createTubeLine,
  updateTubeLine,
  createTubeCurve,
  updateTubeCurve,
  updateBezierPoints,
};

//#endregion
