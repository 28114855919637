import { render, staticRenderFns } from "./ResizeCtrl.vue?vue&type=template&id=a73567fa&scoped=true&"
import script from "./ResizeCtrl.vue?vue&type=script&lang=js&"
export * from "./ResizeCtrl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a73567fa",
  null
  
)

export default component.exports