import $ from "jquery";
import swal from "sweetalert";
import ToothFocusControls from "./ToothFocusControls.vue";
import ToothFocusButtons from "./ToothFocusButtons.vue";
import { getFullBox } from "@/components/occlusal/OcclusalTools.js";
import { focusDicomsTo3dPoint } from "@/components/dicom/DicomManager.js";
import { updateRaycaster, intersectTooth } from "@/App";
import { drawPoint } from "../../draw/draw";
// import { showToothNumberDialog } from "./ToothNumberDialog.vue";

let controlsInitialized = false;
let toothFocused = false;

let ToothFocusMode = {
  isActive: false,
  enterMode: function () {
    enterToothFocusMode();
  },
  leaveMode: function (saveActiveMode) {
    leaveToothFocusMode(false, saveActiveMode);
  },
  mouseUp: function (event) {
    return true;
  },
  mouseMove: function (event) {
    return !toothFocused;
  },
  mouseClick: function (event) {
    updateRaycaster(event);
    let tooth = intersectTooth();
    focusTooth(tooth);
  },
};

function enterToothFocusMode() {
  console.log("enterToothFocusMode");
  initializeControls();
  vueApp.leaveModes(false, true);
  $(".tooth-focus-mode-item").css("display", "block");

  vueApp.activeMode = ToothFocusMode;
  ToothFocusMode.isActive = true;
}

function focusTooth(tooth, apps) {
  if (tooth && tooth.isVisible) {
    toothFocused = true;
    $("#toothFocusHint").css("display", "none");
    vueApp.allItems.forEach((model) => {
      if (model != tooth) {
        let mesh = viewer.get_model_mesh(model.id);
        mesh.visible = false;
        if (!apps) model.hidden = true;
        // viewer.set_color(model.id, "transparent");
        // viewer.set_opacity(model.id, 0);
      }
    });

    let tmesh = viewer.get_model_mesh(tooth.id);
    if (tmesh && tmesh.visible !== undefined) {
      tmesh.visible = true;
      let geometry = tmesh.geometry.clone();
      geometry.applyMatrix4(tmesh.matrix);
      let toothBox = offsetBox(geometry.boundingBox);
      geometry.dispose();

      vueApp.switchToDefaultView();
      let vcenter = toothBox.center;
      vueApp.lookAtPoint(vcenter);
      viewer.camera.position.z = defaultZoom / 3 + toothBox.center.z;

      if (apps) focusDicomsTo3dPoint(vcenter, apps);
    }
    // vueApp.$emit('visibilityChanged', 'focusTooth');
  }
}

function unfocusTooth(leaveFocusMode) {
  $("#toothFocusHint").css("display", "block");
  toothFocused = false;
  vueApp.allItems.forEach(function (model) {
    let mesh = viewer.get_model_mesh(model.id);
    mesh.visible = true;
    model.hidden = false;
    // viewer.set_opacity(model.id, model.opacity);
  });

  vueApp.switchToDefaultView();

  let visibleBox = getFullBox(true);
  let vcenter = visibleBox.center;
  vueApp.lookAtPoint(vcenter);

  if (leaveFocusMode) {
    leaveToothFocusMode();
  }
  vueApp.$emit("visibilityChanged", "unfocusTooth");
}

function leaveToothFocusMode() {
  $(".tooth-focus-mode-item").css("display", "none");
  vueApp.activeMode = null;

  ToothFocusMode.isActive = false;
  vueApp.leaveModes(false, true);
}

function initializeControls() {
  if (!controlsInitialized) {
    var ctrls = [ToothFocusControls, ToothFocusButtons];
    ctrls.forEach((component) => {
      const componentInstance = new Vue({
        ...component,
        propsData: { mode: ToothFocusMode },
      });
      const vueContainer = document.createElement("div");
      document.getElementById("stl_wrapper").appendChild(vueContainer);
      componentInstance.$mount(vueContainer);
    });
    controlsInitialized = true;
  }
}

export { ToothFocusMode, enterToothFocusMode, focusTooth, unfocusTooth };
