<template>
    <div id="implantsStep1" class="modal" tabindex="-1" role="dialog"
         aria-labelledby="implantsStep1Title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content wizard-content">
                <div class="modal-header wizard-header">
                    <h5 class="modal-title wizard-title"
                        id="implantsStep1Title">
                        Enter Name or Tooth Number
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body wizard-body">
                    <form>
                        <div class="form-group">
                            <input id="toothName" v-model="implantName"
                                   type="text" class="form-control wizard-input">
                        </div>
                    </form>
                </div>
                <div class="modal-footer wizard-footer">
                    <button type="button"
                            class="btn btn-primary btn-next"
                            aria-label="Next"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#implantsStep2"
                            v-on:click="applyStep1()">
                        NEXT
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import { initStep2 } from "./ImplantStep2.vue";
	import { initStep3 } from "./ImplantStep3.vue";

	export default {
		name: "ImplantsStep1",
		props: ["lengths", "diameters"],
		data() {
			return {
				implantName: "Implant 1",
				stepsInitialized: false
			};
		},
		methods: {
			applyStep1: function() {
				vueApp.isResize = false;
				vueApp.crownNumber = null;
				if (!this.stepsInitialized && this.lengths.length > 0) {
					initStep2(this.lengths, this.diameters);
					//initStep3();

					this.stepsInitialized = true;
				}
				this.$emit("set-implant-name", this.implantName);
			}
		}
	};
</script>

<style scoped>
	@import "../../css/wizard.css";

	.modal-sm {
		max-width: 350px;
	}

	.modal-header {
		height: 80px;
	}

	.modal-title {
		max-width: 200px;
		left: calc(50% - 100px);
	}

	.modal-body {
		padding-left: 50px;
		padding-right: 50px;
	}
</style>
