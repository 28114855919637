import { ArrowHelper2 } from "./ArrowHelper2";

function drawArrow(from, to, color) {
  if (from && to) {
    if (!color) {
      color = 0xff9900;
    }

    var material = new THREE.MeshBasicMaterial({ color });
    var mesh = cylindricalSegment(from, to, 0.2, material);

    var direction1 = to.clone().sub(from);
    var length = direction1.length() + 1;
    var arrowHelper1 = ArrowHelper2(
      direction1.normalize(),
      from,
      length,
      color,
      2,
      2
    );

    var direction2 = from.clone().sub(to);
    var arrowHelper2 = ArrowHelper2(
      direction2.normalize(),
      to,
      length,
      color,
      2,
      2
    );

    var group = new THREE.Group();
    group.add(mesh);
    group.add(arrowHelper1);
    group.add(arrowHelper2);

    viewer.scene.add(group);
    return group;
  }
}

function drawTapArrows1(from, to, color) {
  var color = 0x09900;
  var material = new THREE.MeshBasicMaterial({
    color: color,
    toneMapped: false,
    depthWrite: false,
    depthTest: false,
    transparent: true,
  });
  //var dy = 5;
  var arrowTh = 1.2;
  var direction = to.clone().sub(from);
  var dlength = direction.length();
  var value = 7 / dlength;

  var leftFrom = new THREE.Vector3(from.x, from.y, from.z);
  var newX = from.x + (to.x - from.x) * value;
  var newY = from.y + (to.y - from.y) * value;
  var newZ = from.z + (to.z - from.z) * value;
  var leftTo = new THREE.Vector3(newX, newY, newZ);
  var leftMesh = cylindricalSegment(leftFrom, leftTo, arrowTh, material);
  leftMesh.tag = "from";
  var direction1 = leftFrom.clone().sub(leftTo);
  var length = direction1.length() + 2;
  var arrowHelperLeft = ArrowHelper2(
    direction1.normalize(),
    leftTo,
    length,
    color,
    3,
    5,
    material,
    "from"
  );
  arrowHelperLeft.tag = "from";

  value = 1 - value;
  newX = from.x + (to.x - from.x) * value;
  newY = from.y + (to.y - from.y) * value;
  newZ = from.z + (to.z - from.z) * value;
  var rightFrom = new THREE.Vector3(newX, newY, newZ);
  var rightTo = new THREE.Vector3(to.x, to.y, to.z);
  var rightMesh = cylindricalSegment(rightFrom, rightTo, arrowTh, material);
  rightMesh.tag = "to";
  var direction2 = rightTo.clone().sub(rightFrom);
  var arrowHelperRight = ArrowHelper2(
    direction2.normalize(),
    rightFrom,
    length,
    color,
    3,
    5,
    material,
    "to"
  );
  arrowHelperRight.tag = "to";

  var group = new THREE.Group();
  group.add(leftMesh);
  group.add(rightMesh);
  group.add(arrowHelperLeft);
  group.add(arrowHelperRight);
  group.tag = "group";

  viewer.scene.add(group);
  return group;
}

function drawTapArrow(point, isLeftRight, isVertical, angle, tag, clearDepth) {
  var width = 1,
    height = 3.5,
    radius = 0.1;
  var arrowColor = 0xff0000; //"black";

  var material =
    // new THREE.MeshPhongMaterial({
    //     color: arrowColor,
    //     polygonOffset: true,
    //     polygonOffsetFactor: 1, // positive value pushes polygon further away
    //     polygonOffsetUnits: 1,
    //     //toneMapped: false,
    //     depthWrite: false,
    //     depthTest: false,
    //     transparent: true,
    //     //wireframe: true
    // });
    new THREE.MeshBasicMaterial({
      color: arrowColor,
      depthWrite: false,
      depthTest: false,
      transparent: true,
      //wireframe: true
    });
  var group = new THREE.Group();
  var arrow1Geometry = getArrowGeometry(
    width,
    height,
    radius,
    isLeftRight,
    isVertical
  );
  //var arrow2Geometry = getArrowGeometry(width, height, radius, isLeftRight, isVertical);
  var arrow1 = new THREE.Mesh(arrow1Geometry, material);
  arrow1.renderOrder = isLeftRight ? 1001 : 1000;

  //var arrow2 = new THREE.Mesh(arrow2Geometry, material);

  if (clearDepth) {
    //arrow1.onBeforeRender = function (renderer) { renderer.clearDepth(); };
    //arrow2.onBeforeRender = function( renderer ) { renderer.clearDepth(); };
  }

  // if (isVertical) {
  //     arrow2.position.y = -3;
  //     // if (!isLeftRight) {
  //     //     arrow1.position.y -= -2.5;
  //     //     arrow2.position.y -= -2.5;
  //     // }
  // } else {
  //     arrow2.position.x = -3;
  //     // if (!isLeftRight) {
  //     //     arrow1.position.x -= -2.5;
  //     //     arrow2.position.x -= -2.5;
  //     // }
  // }

  if (tag) {
    group.tag = "group";
    arrow1.tag = tag; //arrow2
  }
  group.add(arrow1);
  //group.add(arrow2);

  viewer.scene.add(group);
  group.position.copy(point);
  //group.renderOrder = 999;
  // if (clearDepth)
  //     arrow1.onBeforeRender = function (renderer) { renderer.clearDepth(); };

  if (angle) {
    var axis = new THREE.Vector3();
    var start = group.position;
    var end = isVertical
      ? new THREE.Vector3(start.x, start.y, start.z + 50)
      : new THREE.Vector3(start.x, start.y + 50, start.z);
    axis.subVectors(start, end).normalize();
    group.rotateOnWorldAxis(axis, isVertical ? -angle : angle);
  }
  return group;
}

function getArrowGeometry(width, height, radius, isLeftRight, isVertical) {
  var arrow = new THREE.Geometry();

  var rect1 = drawRect(
    width,
    height,
    radius,
    isLeftRight ? Math.PI / 4 : -Math.PI / 4
  );
  var rect2 = drawRect(
    width,
    height,
    radius,
    isLeftRight ? -Math.PI / 4 : Math.PI / 4
  );
  if (isVertical) {
    rect2.position.x = -(height / Math.sqrt(2)) + width / 1.5;
  } else {
    rect2.position.y = -(height / Math.sqrt(2)) + width / 1.5;
  }

  rect1.updateMatrix();
  arrow.merge(rect1.geometry, rect1.matrix);

  rect2.updateMatrix();
  arrow.merge(rect2.geometry, rect2.matrix);

  return arrow;
}

function drawRect(width, height, radius, degree) {
  var geometry = new THREE.BoxGeometry(width, height, 3, 10, 5, 1);
  var v1 = new THREE.Vector3();
  var w1 = (width - radius * 2) * 0.5,
    h1 = (height - radius * 2) * 0.5;
  var vTemp = new THREE.Vector3(),
    vSign = new THREE.Vector3(),
    vRad = new THREE.Vector3();
  geometry.vertices.forEach((v) => {
    v1.set(w1, h1, v.z);
    vTemp.multiplyVectors(v1, vSign.set(Math.sign(v.x), Math.sign(v.y), 1));
    vRad.subVectors(v, vTemp);
    if (
      Math.abs(v.x) > v1.x &&
      Math.abs(v.y) > v1.y &&
      vRad.length() > radius
    ) {
      vRad.setLength(radius).add(vTemp);
      v.copy(vRad);
    }
  });

  var mesh = new THREE.Mesh(
    geometry,
    new THREE.MeshBasicMaterial({
      color: "black",
      wireframe: true,
    })
  );
  //mesh.tag = "to";
  mesh.rotation.z = degree;

  return mesh;
}

function drawPoint(point, color, isTransparent, radius) {
  if (point) {
    if (!color) {
      color = 0x00ff00;
    }
    if (!radius) {
      radius = 0.4;
    }

    var mesh = new THREE.Mesh(
      new THREE.SphereBufferGeometry(radius),
      new THREE.MeshBasicMaterial({ color })
    );
    viewer.scene.add(mesh);
    mesh.position.set(point.x, point.y, point.z);

    if (isTransparent) {
      mesh.material.color.set(0xffffff);
      mesh.material.opacity = 0.01;
      mesh.material.transparent = true;
    }

    return mesh;
  }
}

function drawPointBox(point, color, size) {
  if (point) {
    if (!color) {
      color = 0x00ff00;
    }
    if (!size) {
      size = 1;
    }

    var mesh = new THREE.Mesh(
      new THREE.BoxGeometry(size, size, size),
      new THREE.MeshBasicMaterial({
        color,
        // transparent: true,
        // depthWrite: false,
      })
    );
    // mesh.material.opacity = 0.7;
    viewer.scene.add(mesh);
    mesh.position.set(point.x, point.y, point.z);

    return mesh;
  }
}

function drawLine(point1, point2, color) {
  if (point1 && point2) {
    if (!color) {
      color = 0xff0000;
    }
    var material = new THREE.LineBasicMaterial({ color: color });
    var geometry = new THREE.Geometry();
    geometry.vertices.push(new THREE.Vector3(point1.x, point1.y, point1.z));
    geometry.vertices.push(new THREE.Vector3(point2.x, point2.y, point2.z));
    var line = new THREE.Line(geometry, material);
    viewer.scene.add(line);
    return line;
  }
}

function cylindricalSegment(A, B, radius, material) {
  var vec = B.clone();
  vec.sub(A);
  var h = vec.length();
  vec.normalize();
  var quaternion = new THREE.Quaternion();
  quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), vec);
  var geometry = new THREE.CylinderGeometry(radius, radius, h, 32);
  geometry.translate(0, h / 2, 0);
  var cylinder = new THREE.Mesh(geometry, material);
  cylinder.applyQuaternion(quaternion);
  cylinder.position.set(A.x, A.y, A.z);
  return cylinder;
}

// async font loading
function loadFont(url) {
  const loader = new THREE.FontLoader();
  return new Promise((resolve, reject) => {
    loader.load(url, resolve, undefined, reject);
  });
}

function drawToothNumber(tooth, font, isOldNumbers) {
  if (tooth && font) {
    let number = tooth.newNumber
      ? tooth.newNumber
      : tooth.savedNumber
      ? tooth.savedNumber
      : tooth.number;
    if (isOldNumbers) {
      number = tooth.number;
    }
    let text = number.toString();
    if (tooth.filename.startsWith("UN")) {
      text = "UN " + text;
    }

    const geometry = new THREE.TextGeometry(text, {
      font: font,
      size: 2,
      height: 0.2,
      curveSegments: 12,
      bevelEnabled: true,
      bevelOffset: 0,
      bevelThickness: 0.5,
      bevelSize: 0.3,
      bevelSegments: 5,
    });
    let frontColor = isOldNumbers ? 0x6fa8dc : 0xff22cc;
    // geometry.translate(dx, dy, dz);
    const material = [
      new THREE.MeshPhongMaterial({
        color: frontColor,
        flatShading: true,
      }), // front
      new THREE.MeshPhongMaterial({
        color: 0xffcc22,
      }), // side
    ];
    const mesh = new THREE.Mesh(geometry, material);
    geometry.computeBoundingBox();
    geometry.computeVertexNormals();
    geometry.boundingBox.getCenter(mesh.position).multiplyScalar(-1);

    //var mesh = new THREE.Mesh(textGeo, new THREE.MeshBasicMaterial({ color }));
    let toothMesh = viewer.get_model_mesh(tooth.id);
    let point = getToothTextPoint(
      toothMesh.textPoint,
      geometry.boundingBox,
      tooth.isMaxilla
    );
    mesh.position.set(point.x, point.y, point.z);
    // mesh.lookAt(toothMesh.textLookPoint);

    const group = new THREE.Group();
    group.add(mesh);
    let line = drawLine(toothMesh.textPoint, toothMesh.apexEdge);
    group.add(line);
    viewer.scene.add(group);
    toothMesh.numberMesh = mesh;
    toothMesh.numberGroup = group;

    return mesh;
  }
}

function getToothTextPoint(point, textBox, isMaxilla) {
  if (textBox) {
    let sizeX = textBox.max.x - textBox.min.x;
    let sizeY = textBox.max.y - textBox.min.y;
    let sizeZ = textBox.max.z - textBox.min.z;
    let x = point.x - sizeX / 2;
    let dy = isMaxilla ? 0 : -sizeY / 1.5;
    let y = point.y + dy;
    let z = point.z - sizeZ / 2;
    return new THREE.Vector3(x, y, z);
  }

  return point;
}

function drawText(text, point, font, textColor) {
  if (text && point) {
    if (!font) {
      font = vueApp.defaultFont;
    }
    if (!textColor) {
      textColor = 0xffffff;
    }

    const geometry = new THREE.TextGeometry(text, {
      font: font,
      size: 2,
      height: 0.2,
      curveSegments: 12,
      // bevelEnabled: true,
      // bevelOffset: 0,
      // bevelThickness: 0.5,
      // bevelSize: 0.3,
      // bevelSegments: 5
    });

    const textMaterial = [
      new THREE.MeshPhongMaterial({
        color: textColor,
        flatShading: true,
      }), // front
      new THREE.MeshPhongMaterial({
        color: textColor,
      }), // side
    ];

    //const textMaterial = new THREE.MeshPhongMaterial({ color });
    const mesh = new THREE.Mesh(geometry, textMaterial);
    geometry.computeBoundingBox();
    geometry.computeVertexNormals();
    geometry.boundingBox.getCenter(mesh.position).multiplyScalar(-1);

    viewer.scene.add(mesh);
    mesh.position.set(
      point.x - geometry.boundingBox.max.x / 2,
      point.y - geometry.boundingBox.max.y / 2,
      point.z - geometry.boundingBox.max.z * 2
    );

    return mesh;
  }
}

function drawRectangle(
  points,
  color,
  vFrom,
  show3DBorder,
  showFiller,
  texture,
  opacity
) {
  const group = new THREE.Group();

  if (show3DBorder) {
    var geom = new THREE.BufferGeometry().setFromPoints(points);
    var lineLoop = new THREE.LineLoop(
      geom,
      new THREE.LineBasicMaterial({
        color: color,
      })
    );
    group.add(lineLoop);
    var pointsObj = new THREE.Points(
      geom,
      new THREE.PointsMaterial({
        color: color,
      })
    );
    group.add(pointsObj);
  }

  if (showFiller || texture) {
    if (!vFrom) vFrom = new THREE.Vector3(0, 1, 0); // the normal of target plane
    var vTo = new THREE.Vector3(0, 0, 1); // base normal of xy-plane
    // console.log(`p1: ${points[0].x};${points[0].y};${points[0].z};`);
    // 1 quaternions
    var quaternion = new THREE.Quaternion().setFromUnitVectors(vFrom, vTo);
    var quaternionBack = new THREE.Quaternion().setFromUnitVectors(vTo, vFrom);
    // 2 make it parallel to xy-plane
    points.forEach((p) => {
      p.applyQuaternion(quaternion);
    });
    // 3 create shape and shapeGeometry
    var shape = new THREE.Shape(points);
    var shapeGeom = new THREE.ShapeGeometry(shape);
    setUV(shapeGeom);
    // 4 put our points back to their origins
    points.forEach((p) => {
      p.applyQuaternion(quaternionBack);
    });
    // 5 assign points to .vertices
    shapeGeom.vertices = points;

    let material = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      depthWrite: false,
    });

    if (showFiller) {
      material.color = new THREE.Color(color);
    }

    if (texture) {
      // texture = new THREE.TextureLoader().load('images/try_us_triangle.png');
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.magFilter = THREE.NearestFilter;
      material.map = texture;
    }

    material.opacity = opacity;
    var shapeMesh = new THREE.Mesh(shapeGeom, material);
    group.add(shapeMesh);
  }

  viewer.scene.add(group);
  return group;
}

function setUV(geometry) {
  var mesh = new THREE.Mesh(geometry);
  var box = new THREE.Box3().setFromObject(mesh);
  var size = new THREE.Vector3();
  box.getSize(size);
  mesh.geometry.faceVertexUvs[0].forEach((fvUvs) => {
    fvUvs.forEach((fvUv) => {
      fvUv.x = (fvUv.x - box.min.x) / size.x;
      fvUv.y = 1 - (fvUv.y - box.min.y) / size.y;
    });
  });
}

function getMergedGeometry(children) {
  //const mergedGeometry = new THREE.BufferGeometry();
  const geometries = [];
  children.forEach((c) => {
    if (c.type == "Mesh" && c.visible && c.geometry) {
      let bufferGeometry = null;
      if (c.geometry._bufferGeometry)
        bufferGeometry = c.geometry._bufferGeometry;
      else if (c.geometry.type == "BufferGeometry") {
        bufferGeometry = c.geometry;
      }

      if (bufferGeometry) {
        const clonedGeometry = bufferGeometry.clone();
        clonedGeometry.applyMatrix4(c.matrixWorld);
        for (const key in clonedGeometry.attributes) {
          if (key === "position" || key === "normal") {
            continue;
          }
          clonedGeometry.deleteAttribute(key);
        }
        geometries.push(clonedGeometry);
      }
    }
  });

  const mergedGeometry =
    THREE.BufferGeometryUtils.mergeBufferGeometries(geometries);
  //let model = new THREE.Mesh(mergedGeometry, new THREE.MeshStandardMaterial());
  return mergedGeometry;
}

export {
  drawPoint,
  drawPointBox,
  drawLine,
  drawArrow,
  drawTapArrows1,
  drawTapArrow,
  loadFont,
  drawToothNumber,
  drawText,
  drawRectangle,
  getMergedGeometry,
};
