<template>
	<div class="dropdown dropright border-bottom-item">
		<button class="dropdown-item" v-on:click="focusTooth()" type="button">
			Focus Tooth
		</button>
	</div>
</template>

<script>
import { enterToothFocusMode } from "./ToothFocusMode";

export default {
	name: "ToothAddMenu",
	props: ["isEngineerMode"],
	components: {},
	methods: {
		focusTooth: function () {
			gtag('event', 'View->Focus Tooth');
			enterToothFocusMode();
		}
	}
};


</script>

<style scoped></style>