function saveFile(strDataUrl, filename) {
	var link = document.createElement("a");
	// if (typeof link.download === "string") {
	document.body.appendChild(link); //Firefox requires the link to be in the body
	link.download = filename;
	link.href = strDataUrl;
	link.click();
	document.body.removeChild(link); //remove the link when done
	// } else {
	// 	location.replace(uri);
	// }
}

function dataURLtoBlob(dataurl) {
	var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}

function blobToDataURL(blob, callback) {
	// URL.createObjectURL(blob);
	var a = new FileReader();
	a.onload = function (e) { callback(e.target.result); }
	a.readAsDataURL(blob);
}

function getFormattedDate(isFullDate) {
	var date = new Date();
	var month = date.getMonth() + 1;
	var day = date.getDate();
	if (month.toString().length == 1) {
		month = "0" + month;
	}
	if (day.toString().length == 1) {
		day = "0" + day;
	}

	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();
	if (hours.toString().length == 1) {
		hours = "0" + hours;
	}
	if (minutes.toString().length == 1) {
		minutes = "0" + minutes;
	}
	if (seconds.toString().length == 1) {
		seconds = "0" + seconds;
	}

	return isFullDate
		? `${day}_${month}_${date.getFullYear()}.${hours}${minutes}${seconds}`
		: `${date.getFullYear()}_${month}_${day}`;
}

function getShortDate() {
	var date = new Date();
	var month = date.getMonth() + 1;
	var day = date.getDate();
	return `${month}/${day}/${date.getFullYear()}`;
}

function zeroPad(num, places) {
	return String(num).padStart(places, '0');
}

export { saveFile, getFormattedDate, getShortDate, dataURLtoBlob, blobToDataURL, zeroPad };