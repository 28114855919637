<template>
	<div class="flex-item dicom-container">
		<div class="dicom-flex-container">

			<div ref="dicomBorder" class="dicom-flex-column dicom-main-container">
				<!-- <div ref="dicomBorder" class="dicom-border"></div> -->
				<div ref="dicomViewer" class="dicom-viewer"></div>

				<div class="dicom-tools" :style="{ 'margin-left': app.is3D ? '45px' : '0px' }">
					<img :src="app.toolName == 'dragDrop' ? 'images/drag_red.png' : 'images/drag_white.png'"
						class="dicom-tool-btn annotation-off-item" v-on:click="toolChanged('dragDrop')"
						title="Drag&Drop" />
					<img :src="app.toolName == 'measure' ? 'images/measure_red.png' : 'images/measure_white.png'"
						class="dicom-tool-btn annotation-off-item" v-on:click="toolChanged('measure')"
						title="Measure" />
					<img v-show="app.index == 0 && !app.isAnnotating" class="dicom-tool-btn annotation-tool"
						v-on:click="toolChanged('annotation')" title="Annotation"
						:src="app.isAnnotating ? 'images/annotation_red.png' : 'images/annotation_white.png'" />

					<img v-show="app.isAnnotating" class="dicom-tool-btn annotation-save-btn"
						v-on:click="saveOnServer()" title="Save on server" src="images/save_white.png" />
					<img v-show="app.isAnnotating" class="dicom-tool-btn annotation-view-btn"
						v-on:click="switchViewClick()" title="Switch view"
						:src="app.is3D ? 'images/3d_red.png' : 'images/3d_white.png'" />
					<img v-show="app.isAnnotating && app.is3D && !app.isDicom3DView"
						class="dicom-tool-btn annotation-numbers-btn" v-on:click="switchTeethNumbersClick()"
						:title="app.showTeethNumbers ? 'Hide teeth numbers' : 'Show teeth numbers'"
						:src="app.showTeethNumbers ? 'images/numbers_red.png' : 'images/numbers_white.png'" />

					<img v-show="isDrawEnabled" class="dicom-tool-btn annotation-contour-btn"
						v-on:click="changeDicomEditMode('bezier')" title="Contour Tool"
						:src="!isBrushMode ? 'images/bezier_red.png' : 'images/bezier_white.png'" />
					<img v-show="isDrawEnabled" class="dicom-tool-btn annotation-brush-btn"
						v-on:click="changeDicomEditMode('brush', 'draw')" title="Brush Tool"
						:src="isBrushMode ? 'images/brush_red.png' : 'images/brush_white.png'" />

					<img v-show="isBrushModeAndEdit" class="dicom-tool-btn annotation-draw-btn"
						v-on:click="changeDicomEditMode('brush', 'draw')" title="Draw"
						:src="isBrushDraw ? 'images/draw_red.png' : 'images/draw_white.png'" />
					<img v-show="isBrushModeAndEdit" class="dicom-tool-btn annotation-erase-btn"
						v-on:click="changeDicomEditMode('brush', 'erase')" title="Erase"
						:src="isBrushDraw ? 'images/erase_white.png' : 'images/erase_red.png'" />


					<div v-show="isBrushModeAndEdit" class="input-group mb-2 mt-1 input-group-sm">
						<!-- <div class="input-group-prepend">
							<span class="input-group-text">Size:</span>
						</div> -->
						<select v-model="drawSize" class="form-control form-control-sm dicom-tool-select"
							@change="drawSizeChanged()">
							<option v-for="option in drawSizes" v-bind:value="option">
								{{ option }}
							</option>
						</select>
					</div>

					<img v-show="isBrushModeAndEdit" class="dicom-tool-btn annotation-undo-btn"
						src="images/undo_white.png" title="Undo" v-on:click="undoBrushEdit()" />

					<img v-show="isBrushModeAndEdit" class="dicom-tool-btn annotation-redo-btn"
						src="images/redo_white.png" title="Redo" v-on:click="redoBrushEdit()" />

					<!-- <img class="dicom-tool-btn" v-on:click="downloadStlZip()"
						 title="Download STL zip" src="images/download_white.png" /> -->
				</div>

				<div v-show="app.isSaving" class="annotation-save-hint">
					<span>Saving changes...</span>
					<img class="annotation-save-img" src="images/dark-blue-spinner.gif" />
				</div>

				<img ref="annotationSpinner" class="annotation-spinner" src="images/dark-blue-spinner.gif" />

				<img class="dicom-layer-btn annotation-off-item"
					:src="show3DLayer ? 'images/layer_red.png' : 'images/layer_white.png'"
					v-on:click="show3DLayerChanged()" title="Show slice in 3D" />

				<img class="dicom-segmentation-btn annotation-off-item"
					:src="showSegmentation ? 'images/segmentation_red.png' : 'images/segmentation_white.png'"
					v-on:click="showSegmentationChanged()" title="Segmentation" />

				<img class="dicom-intersection-btn 3d-off-item"
					:src="showIntersection ? 'images/intersection_red.png' : 'images/intersection_white.png'"
					v-on:click="showIntersectionChanged()" title="Show projection lines" />

				<img ref="dicomSettingsBtn" data-toggle="collapse" data-target="#dicomSettingsPanel"
					aria-expanded="false" title="Settings" src="images/menu_white.png"
					class="dicom-settings-btn 3d-off-item" />

				<img src="images/cross_white.png" class="dicom-close-btn annotation-off-item"
					v-on:click="closeDicomViewer()" title="Close" />

				<!-- dicomSettingsPanel -->
				<div class="dicom-ctrls panel-group">
					<div id="dicomSettingsPanel" ref="dicomSettingsPanel"
						class="collapse list-group dicom-settings-panel">
						<li class="list-group-item dicom-setting-item active annotation-off-item">Segmentation</li>
						<li class="list-group-item dicom-setting-item annotation-off-item">
							<div class="mb-2 mt-1 dicom-setting-group">
								<label v-for="option in segmentations" class="dicom-ctrl">
									<span class="dicom-check-label">{{ option.text }}</span>
									<input type="checkbox" class="dicom-check-input" v-model="option.value"
										@change="segmentationChanged()">
								</label>
							</div>
							<div class="input-group mb-2 mt-1 input-group-sm">
								<div class="input-group-prepend">
									<span class="input-group-text">Thickness:</span>
								</div>
								<select v-model="outlineThickness" class="form-control form-control-sm"
									@change="outlineThicknessChanged()">
									<option v-for="option in outlineThicknesses" v-bind:value="option">
										{{ option }}
									</option>
								</select>
							</div>
							<div class="input-group mb-1 mt-1 input-group-sm">
								<div class="input-group-prepend">
									<span class="input-group-text">Opacity:</span>
								</div>
								<input v-model="opacity" @change="opacityChanged()" class="form-control form-control-sm"
									type="number" min="0.05" max="1" step="0.05" />
							</div>
							<div class="input-group mb-1 input-group-sm">
								<select v-model="segmentationType" class="form-control form-control-sm"
									@change="segmentationTypeChanged()">
									<option v-for="option in segmentationTypes" v-bind:value="option.value">
										{{ option.text }}
									</option>
								</select>
							</div>
							<button type="button" class="btn btn-secondary btn-sm btn-block dicom-setting-btn mt-2"
								v-on:click="calculateSegmentation">Calculate</button>
							<!-- {{ app.isLoading ? "Calculate" : "Stop" }} -->
						</li>
						<li class="list-group-item dicom-setting-item active annotation-off-item">3D View</li>
						<li class="list-group-item dicom-setting-item annotation-off-item">
							<label class="dicom-ctrl">
								<span class="dicom-check-label">Show slice</span>
								<input type="checkbox" class="dicom-check-input" v-model="show3DSlice"
									@change="show3DSliceChanged()">
							</label>
							<label class="dicom-ctrl mt-1">
								<span class="dicom-check-label">Show border</span>
								<input type="checkbox" class="dicom-check-input" v-model="show3DBorder"
									@change="show3DBorderChanged()">
							</label>
							<label class="dicom-ctrl mt-1">
								<span class="dicom-check-label">Show filler</span>
								<input type="checkbox" class="dicom-check-input" v-model="show3DFiller"
									@change="show3DFillerChanged()">
							</label>
							<div class="input-group mb-1 mt-1 input-group-sm">
								<div class="input-group-prepend">
									<span class="input-group-text">Opacity:</span>
								</div>
								<input v-model="sliceOpacity" @change="sliceOpacityChanged()"
									class="form-control form-control-sm" type="number" min="0.05" max="1" step="0.05" />
							</div>
						</li>
						<li class="list-group-item dicom-setting-item active">Contrast</li>
						<li class="list-group-item dicom-setting-item">
							<input type="range" class="form-control-range" v-model="contrast.value" :min="contrast.min"
								:max="contrast.max" :step="contrast.step" @input="contrastChanged()"
								@mouseup="updateCanvas()">
							<button type="button" class="btn btn-secondary btn-sm btn-block dicom-setting-btn"
								v-on:click="resetContrast">Reset</button>
						</li>
						<li class="list-group-item dicom-setting-item active">Brightness</li>
						<li class="list-group-item dicom-setting-item">
							<input type="range" class="form-control-range" v-model="brightness.value"
								:min="brightness.min" :max="brightness.max" :step="brightness.step"
								@input="brightnessChanged()" @mouseup="updateCanvas()">
							<button type="button" class="btn btn-secondary btn-sm btn-block dicom-setting-btn"
								v-on:click="resetBrightness">Reset</button>
						</li>
					</div>
				</div>

				<div class="dicom-zoom-container 3d-off-item">
					<img src="images/white_plus.png" class="dicom-zoom-btn" v-on:click="changeZoom(1)"
						title="Increase Zoom" />
					<img src="images/white_minus.png" class="dicom-zoom-btn" v-on:click="changeZoom(-1)"
						title="Decrease Zoom" />
					<img src="images/reset.png" class="dicom-zoom-btn" v-on:click="resetZoom()" title="Reset Zoom" />
				</div>

				<div class="dicom-slider-container 3d-off-item">
					<img class="dicom-slider-btn" src="images/arrow_top_white.png" title="Next Slice"
						v-on:click="moveSliceStep(1)" />
					<span class="dicom-span">{{ max }}</span>
					<div ref="dicomSlider" class="dicom-slider"></div>
					<span class="dicom-span">1</span>
					<img class="dicom-slider-btn" src="images/arrow_bottom_white.png" title="Previous Slice"
						v-on:click="moveSliceStep(-1)" />
				</div>
				<div class="dicom-offset 3d-off-item"> {{ offset }} </div>

				<img class="trash-tool" src="images/trash_icon.png" v-show="app.toolName === 'measure'"
					v-on:click="clearMeasures()" title="Clear Measures" />

				<div class="assign-layer annotation-on-item">
					<AssignAnnotationPanel :app="app" />
				</div>

				<!-- <DicomLoader :app="app" /> -->
			</div>

			<div class="dicom-flex-column annotation-column viewers-column annotation-on-item">
				<div ref="dicomAnnotationViewers" class="dicom-annotation-viewers">
					<!-- <SimpleDicomViewer :app="{ orientation: 'axial' }" />
					<SimpleDicomViewer :app="{ orientation: 'sagittal' }" />
					<SimpleDicomViewer :app="{ orientation: 'coronal' }" /> -->
				</div>
			</div>

			<div class="dicom-flex-column annotation-column apanel-column annotation-on-item">
				<DicomAnnotationPanel :app="app" />
			</div>
		</div>

	</div>
</template>

<script>

import swal from "sweetalert";

import { mutationTypes } from '@/store/modules/dicom';
import {
	update3DFrame, updateCanvasContext, updateSegmentation,
	updateOriginBox, setSliceIndex, setupCanvasEvents,
	changeAppZoom, changeAppTool, changeEditMode,
	updateSliceIntersection, updateNeighborIntersections,
	switch3DView, switchTeethNumbers, closeViewer
} from "./DicomManager";

import DicomAnnotationPanel from "./DicomAnnotationPanel.vue";
import AssignAnnotationPanel from "./AssignAnnotationPanel.vue";
import { saveAnnotationZip, updateTeethOnServer } from "./annotation";

let dwv = require('dwv');

export default {
	name: "DicomViewer",
	components: {
		DicomAnnotationPanel,
		AssignAnnotationPanel
	},
	data() {
		return {
			current: 0,
			max: 100,
			sliderId: null,
			settingsPanelId: 'settingsPanelId',
			showSegmentation: true,
			segmentations: [
				{ text: "Max teeth", key: "maxTeeth", value: true },
				{ text: "Mand teeth", key: "mandTeeth", value: true },
				{ text: "Maxilla", key: "maxilla", value: false },
				{ text: "Mandible", key: "mandible", value: false },
				{ text: "Airway", key: "airway", value: false }
			],
			segmentationType: 'fillTeeth',
			segmentationTypes: [
				{ text: "Outlines", value: 'outlines' },
				{ text: "Fill Teeth", value: 'fillTeeth' },
				{ text: "Fill All", value: 'fillAll' }
			],

			showIntersection: true,
			show3DLayer: true,

			opacity: 0.5,
			show3DSlice: false,
			show3DBorder: false,
			show3DFiller: true,
			sliceOpacity: 0.25,
			contrast: {
				value: 50,
				min: 1,
				max: 100,
				step: 1,
				default: 50
			},
			brightness: {
				value: 50,
				min: 1,
				max: 100,
				step: 1,
				default: 50
			},
			outlineThicknesses: [
				0.3,
				0.6,
				1.0,
				1.5
			],
			outlineThickness: 0.6,
			drawSizes: [
				1,
				2,
				3,
				4,
				5,
				6
			],
			drawSize: 4,
			renderChanged: false
		}
	},
	props: {
		app: {
			type: Object,
			required: true
		}
	},
	computed: {
		offset() {
			return `${this.current} / ${this.max}`;
		},
		isDrawEnabled() {
			return this.app.isAnnotating && !this.app.is3D;
			//this.app.toolName == 'annotation'; this.app.isAnnotating
		},
		isBrushMode() {
			return this.app.isAnnotating && !this.app.is3D && this.app.editMode === 'brush';
		},
		isBrushModeAndEdit() {
			return this.app.isAnnotating && this.app.isEditingAnnotation && !this.app.is3D && this.app.editMode === 'brush';
		},
		isBrushDraw() {
			return this.app.isAnnotating && !this.app.is3D
				&& this.app.editMode === 'brush' && this.app.editSubMode === 'draw';
		}
	},
	mounted() {
		if (!this.app.divId || this.app.divId == 'demo_dicom') {
			console.log("demo mounted");
			//this.app = { orientation : "axial" };
			this.$store.commit(mutationTypes.isAxialChange, true);
			this.initControls();
			this.app.setTool = (toolName) => {
				console.log("demo setTool", toolName);
			};
			this.app.setToolFeatures = (features) => {
				console.log("demo setToolFeatures", features);
			};
			this.app.viewersId = `${this.app.orientation}_viewers`;
			this.$refs.dicomAnnotationViewers.setAttribute("id", this.app.viewersId);
			this.app.isDemo = true;
			this.$refs.dicomViewer.setAttribute("id", this.app.divId);

			window.addEventListener('resize', () => {
				if (this.showIntersection)
					updateSliceIntersection(this.app);
			});
			return;
		}
		let app = this.app;
		// console.log(`mounted app ${app.index}`);
		this.app.outlineThickness = this.outlineThickness;
		this.app.segmentationType = this.segmentationType;

		this.$refs.dicomViewer.setAttribute("id", this.app.divId);

		this.app.containerId = `${this.app.orientation}_cont`;
		this.$el.setAttribute("id", this.app.containerId);

		this.$refs.dicomBorder.setAttribute("style", `border: solid ${this.app.color} 0.2rem;`);

		this.app.viewersId = `${this.app.orientation}_viewers`;
		this.$refs.dicomAnnotationViewers.setAttribute("id", this.app.viewersId);

		const viewConfig = new dwv.ViewConfig(app.divId);
		viewConfig.orientation = app.orientation;

		const viewConfigs = { '*': [viewConfig] };
		const options = new dwv.AppOptions(viewConfigs);
		var shapeList = [
			'Arrow',
			'Ruler',
			'Protractor',
			'Rectangle',
			'Roi',
			'Ellipse',
			'Circle',
			'FreeHand',
			'Empty'
		];
		options.tools = {
			ZoomAndPan: {},
			Livewire: {},
			Annotation: {},
			Floodfill: {},
			Draw: {
				options: shapeList,
				type: 'factory'
			}
		};
		options.viewOnFirstLoadItem = false;
		app.init(options);
		app.tools = options.tools;

		app.addEventListener('load', (event) => {
			app.setTool('ZoomAndPan');
			const size = app.getImage(0).getGeometry().getSize();
			this.max = app.max = size.get(app.viewIndex);
			this.current = app.current = Math.floor(app.max / 2) + 1;
			// console.log(`app ${app.index} size`, size);
			console.log(`app ${app.index}, max ${app.max} load`, event);
			this.initControls();
		});
		app.addEventListener('loadstart', (event) => {
			console.log(`app ${app.index} loadstart`, event);
		});
		app.addEventListener('renderend', () => {
			if (!app.baseSize) {
				const activeLayer = this.getActiveLayer();
				app.baseSize0 = activeLayer.getBaseSize();
				const viewController = activeLayer.getViewController();
				app.worldSize = viewController.getImageWorldSize();
				app.spacing = viewController.get2DSpacing();
				app.baseSize = { x: app.worldSize.x / app.spacing[0], y: app.worldSize.y / app.spacing[1] };
				console.log(`app ${app.index} renderend`, app.worldSize, app.baseSize0);
				setupCanvasEvents(app);

				this.initImageSettings();
				updateOriginBox(app, viewController);
			}
			this.renderChanged = true;
		});
		app.addEventListener('loadabort', (event) => {
			console.log(`app ${app.index} loadabort`, event);
		});
		app.addEventListener('loaderror', (event) => {
			console.log(`app ${app.index} loaderror`, event);
		});
		app.addEventListener('positionchange', () => {
			this.updateCurrentIndex();
			// console.log(`app ${app.index} ${app.current} / ${app.max} positionchange`);
			$(`#${this.sliderId}`).slider("value", this.current);
			update3DFrame(app);
			updateCanvasContext(app);

			updateNeighborIntersections(app);
		});
		app.addEventListener('offsetchange', (event) => {
			// console.log(`app ${app.index} offsetchange`, event);
			if (!app.offset) {
				app.offsetchanged = true;
			} else {
				for (let i = 0; i < event.value.length; i++) {
					if (event.value[i] != app.offset[i]) {
						app.offsetchanged = true;
					}
				}
			}

			if (app.offsetchanged) {
				app.offset = event.value;
				updateSliceIntersection(app);
			}
		});
		app.addEventListener('zoomchange', (event) => {
			if (!app.zoomValue || app.zoomValue != event.value) {
				// console.log(`app ${app.index} zoomchange`, event, app);
				app.zoomValue = event.value;
				updateSliceIntersection(app);
				if (app.showSegmentation)
					setTimeout(function () {
						setSliceIndex(app, app.current - 1);
						// updateCanvasContext(app);
					}, 50);
			}
		});

		window.addEventListener('resize', function () {
			try {
				if (app.visible) {
					let currentConfigs = app.getDataViewConfigs();
					app.setDataViewConfigs(currentConfigs);
					for (let i = 0; i < app.getNumberOfLoadedData(); ++i) {
						app.render(i);
					}
					changeAppTool(app, app.toolName);
					// app.onResize();
					updateCanvasContext(app);

					if (app.showIntersection)
						updateSliceIntersection(app);
				}
			} catch (error) {
				console.error(error);
			}
		});
	},
	beforeDestroy() {
		if (vueApp) {
			vueApp.$off('colorChanged');
			vueApp.$off('visibilityChanged');
			vueApp.$off('positionChanged');
			vueApp.$off('toothAdded');
			vueApp.$off('toothRemoved');
		}
	},
	methods: {
		initControls: function () {
			console.log("initControls");
			if (this.app) {
				this.app.containerId = `${this.app.orientation}_cont`;
				this.$el.setAttribute("id", this.app.containerId);
				this.sliderId = `${this.app.orientation}_slider`;
				this.$refs.dicomSlider.setAttribute("id", this.sliderId);
				$(`#${this.sliderId}`).slider({
					orientation: "vertical",
					min: 0,
					max: this.max,
					step: 1,
					value: this.current,
					slide: (slideEvent, ui) => {
						setSliceIndex(this.app, ui.value - 1);
					}
				});

				let settingsPanelId = this.settingsPanelId = `${this.app.orientation}SettingsPanel`; // dicomSettingsPanel
				this.$refs.dicomSettingsPanel.setAttribute("id", settingsPanelId);

				let settingsBtnId = `${this.app.orientation}SettingsBtn`; // dicomSettingsBtn
				this.$refs.dicomSettingsBtn.setAttribute("id", settingsBtnId);
				this.$refs.dicomSettingsBtn.setAttribute("data-target", settingsPanelId);

				let annotationSpinnerId = `${this.app.orientation}AnnotationSpinner`;
				this.$refs.annotationSpinner.setAttribute("id", annotationSpinnerId);
				this.app.annotationSpinnerId = "#" + annotationSpinnerId;

				$(`#${settingsBtnId}`).on('click', function () {
					$(`#${settingsPanelId}`).collapse('toggle');
				});
			}
		},
		toolChanged: function (toolName) {
			if (toolName) {
				this.app.toolName = toolName;
				if (toolName == 'annotation') {
					$(`#${this.settingsPanelId}`).collapse('hide');
				}
				if (this.app) {
					changeAppTool(this.app, toolName);
				}
			}
		},
		clearMeasures: function () {
			console.log("clearMeasures");
			const drawLayer = this.getDrawLayer();
			if (drawLayer) {
				console.log(drawLayer);
				drawLayer.deleteDraws(() => { });
			}
		},
		initImageSettings: function () {
			const vl = this.getActiveLayer();
			const image = this.app.getImage(vl.getDataIndex());
			// const dataRange = image.getDataRange();
			const rescaledDataRange = image.getRescaledDataRange();
			const vc = vl.getViewController();
			const wl = vc.getWindowLevel();

			this.contrast.min = 0;
			this.contrast.max = rescaledDataRange.max - rescaledDataRange.min;
			this.contrast.step = this.contrast.max / 100;
			this.contrast.default = this.contrast.value = wl.width;

			this.brightness.min = rescaledDataRange.min;
			this.brightness.max = rescaledDataRange.max;
			this.brightness.step = (this.brightness.max - this.brightness.min) / 100;
			this.brightness.default = this.brightness.value = wl.center;
		},
		updateImageSettings: function () {
			const vc = this.getViewController();
			const center = parseFloat(this.brightness.value);
			const width = parseFloat(this.contrast.value);
			vc.setWindowLevel(center, width);
		},
		changeZoom: function (factor) {
			changeAppZoom(this.app, factor);
		},
		resetZoom: function (factor) {
			if (this.app) {
				// console.log("resetZoom");
				this.app.resetZoom();
				updateCanvasContext(this.app);
			}
		},
		moveSliceStep: function (step) {
			// console.log("moveSliceStep", step);
			this.current += step;
			this.app.current = this.current;
			setSliceIndex(this.app, this.app.current - 1);
		},
		showSegmentationChanged: function () {
			this.showSegmentation = !this.showSegmentation;
			console.log("showSegmentationChanged", this.showSegmentation);
			if (this.app) {
				this.app.showSegmentation = this.showSegmentation;
				this.segmentationChanged();
			}
		},
		showIntersectionChanged: function () {
			this.showIntersection = !this.showIntersection;
			this.app.showIntersection = this.showIntersection;
			updateSliceIntersection(this.app);
		},
		show3DLayerChanged: function () {
			this.show3DLayer = !this.show3DLayer;
			console.log("show3DLayerChanged", this.show3DLayer);
			this.app.show3DLayer = this.show3DLayer;
			update3DFrame(this.app);
		},
		segmentationChanged: function () {
			let arr = [];
			this.segmentations.forEach(option => {
				if (option.value) {
					arr.push(option.key);
				}
			});
			console.log("segmentationChanged", arr);
			// this.showSegmentation = arr.length > 0;
			this.app.segmentations = arr;
			setSliceIndex(this.app, this.current - 1);
		},
		outlineThicknessChanged: function () {
			this.app.outlineThickness = this.outlineThickness;
			console.log("outlineThicknessChanged", this.outlineThickness);
			setSliceIndex(this.app, this.current - 1);
		},
		drawSizeChanged: function () {
			this.app.drawSize = this.drawSize;
			this.app.setToolFeatures({ drawSize: this.drawSize });
		},
		undoBrushEdit: function () {
			this.app.setToolFeatures({ undoBrush: true });
		},
		redoBrushEdit: function () {
			this.app.setToolFeatures({ redoBrush: true });
		},
		segmentationTypeChanged: function () {
			this.app.segmentationType = this.segmentationType;
			console.log("segmentationTypeChanged", this.segmentationType);
			setSliceIndex(this.app, this.current - 1);
		},
		opacityChanged: function () {
			this.app.opacity = this.opacity;
			setSliceIndex(this.app, this.current - 1);
		},
		contrastChanged: function () {
			// console.log("contrastChanged", this.brightness.value, this.contrast.value);
			this.updateImageSettings();
		},
		brightnessChanged: function () {
			// console.log("brightnessChanged", this.brightness.value, this.contrast.value);
			this.updateImageSettings();
		},
		resetContrast: function () {
			this.contrast.value = this.contrast.default;
			this.contrastChanged();
			this.updateCanvas();
		},
		resetBrightness: function () {
			this.brightness.value = this.brightness.default;
			this.brightnessChanged();
			this.updateCanvas();
		},
		show3DSliceChanged: function () {
			this.app.show3DSlice = this.show3DSlice;
			if (this.show3DSlice) {
				setSliceIndex(this.app, this.current - 1);
			} else {
				// updateCanvasContext(this.app);
				update3DFrame(this.app);
			}

		},
		show3DBorderChanged: function () {
			this.app.show3DBorder = this.show3DBorder;
			update3DFrame(this.app);
		},
		show3DFillerChanged: function () {
			this.app.show3DFiller = this.show3DFiller;
			update3DFrame(this.app);
		},
		sliceOpacityChanged: function () {
			this.app.sliceOpacity = this.sliceOpacity;
			update3DFrame(this.app);
		},
		getActiveLayer: function () {
			const layerGroup = this.app.getLayerGroupByDivId(this.app.divId);
			return layerGroup.getActiveViewLayer();
		},
		getDrawLayer: function () {
			const layerGroup = this.app.getLayerGroupByDivId(this.app.divId);
			return layerGroup.getActiveDrawLayer();
		},
		getViewController: function () {
			const activeLayer = this.getActiveLayer();
			return activeLayer.getViewController();
		},
		updateCurrentIndex: function () {
			const viewController = this.getViewController();
			let currentIndex = viewController.getCurrentIndex();
			this.current = this.app.current = currentIndex.get(this.app.viewIndex) + 1;
			return this.current;
		},
		updateCanvas: function () {
			if (this.renderChanged) {
				this.renderChanged = false;
				update3DFrame(this.app);
				updateCanvasContext(this.app);
			}
		},
		calculateSegmentation: function () {
			console.log("calculateSegmentation");
			updateSegmentation(this.app, true);
		},
		saveOnServer: function () {
			let smoothChecked = false;
			swal({
				text: "Are you sure you want to save this segmentation? (previous version will be overwritten)",
				buttons: {
					yes: { text: "OK", value: true },
					no: { text: "Cancel", value: false }
				}
			}).then(willUpload => {
				if (willUpload) {
					console.log("uploading annotation zip...");
					updateTeethOnServer(this.app, smoothChecked);
				}
			});

			var $swalCheckbox = $(
				`<div class='swal-checkbox-div'>
					<input class='swal-checkbox-cb' type='checkbox' id='swalCheckbox'/> 
					<label class='swal-checkbox-lb' for='swalCheckbox'>Smooth all edited objects</label>
				</div>`
			);
			$(".swal-footer").append($swalCheckbox);
			$("#swalCheckbox").change(() => {
				smoothChecked = $("#swalCheckbox").is(":checked");
				// let checked = document.querySelector('#swalCheckbox').checked;
				console.log("swal-checkbox ", smoothChecked);
			});
		},
		downloadStlZip: function () {
			console.log("downloadStlZip");
			saveAnnotationZip(this.app);
		},
		switchViewClick: function () {
			switch3DView(this.app, !this.app.is3D);
		},
		switchTeethNumbersClick: function () {
			switchTeethNumbers(this.app);
		},
		changeDicomEditMode: function (editMode, editSubMode) {
			changeEditMode(this.app, editMode, editSubMode);
		},
		closeDicomViewer: function () {
			closeViewer(this.app);
		},
	}
};

</script>

<style scoped>
.dicom-container {
	display: none;
	padding: 0;
	justify-content: center;
	position: relative;
	/* color: white; */
	font-family: Montserrat, sans-serif;
	font-size: .875rem;
}

.dicom-flex-container {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
	margin: 0;
	/* gap: 3px; */
	align-items: stretch;
	position: relative;
	background-color: white;
	z-index: 10;
}

.dicom-flex-column {
	position: relative;
	background-color: black;
	height: 100%;
	flex-grow: 1;
	box-sizing: border-box;
}

.assign-layer {
	display: none;
	position: absolute;
	height: calc(100% - 120px);
	top: 70px;
	left: 70px;
}

.annotation-save-hint {
	position: absolute;
	color: white;
	top: 25px;
	left: calc(50% - 100px);
	text-align: center;
	width: 200px;
}

.annotation-save-img {
	display: inline;
	width: 29px;
	height: 29px;
}

.annotation-spinner {
	position: absolute;
	display: none;
	width: 100px;
	height: 100px;
	left: calc(50% - 50px);
	top: calc(50% - 50px);
	text-align: center;
	z-index: 9;
}

.viewers-column {
	width: 25%;
	min-width: 25%;
}

.apanel-column {
	width: 15%;
	min-width: 175px;
}

.dicom-main-container {
	display: block;
	width: 70%;
	padding: 0.1rem;
	border: solid red 0.2rem;
}

.dicom-annotation-viewers {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
	gap: 3px;
}

.dicom-viewer {
	width: 100%;
	height: 100%;
	position: relative;
	/* margin: 0; */
	margin-top: -15px
}

.dicom-bordered-tool {
	position: absolute;
	color: white;
	border: 1px solid #007bff;
	border-radius: 0.2rem;
	padding: 0.25rem 0.5rem;
}

.dicom-tools {
	position: absolute;
	top: 14px;
	left: 55px;
	display: flex;
	align-items: stretch;
	gap: 14px;
	/* padding: 0 0.25rem; */
}

.dicom-tool-btn {
	width: 25px;
	height: 25px;
	cursor: pointer;
}

.dicom-tool-select {
	height: 25px;
	padding: 2px;
	margin-top: 2px;
}

.trash-tool {
	position: absolute;
	/* top: 12px; */
	bottom: 20px;
	left: calc(50% - 15px);
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.dicom-layer-btn {
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 175px;
	width: 25px;
	height: 25px;
}

.dicom-segmentation-btn {
	cursor: pointer;
	position: absolute;
	top: 12.9px;
	right: 135px;
	width: 30px;
	height: 30px;
}

.dicom-intersection-btn {
	cursor: pointer;
	position: absolute;
	top: 12.9px;
	right: 97px;
	width: 30px;
	height: 30px;
}

.dicom-settings-btn {
	cursor: pointer;
	position: absolute;
	top: 12.9px;
	right: 55px;
	width: 30px;
	height: 30px;
}

.dicom-close-btn {
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 20px;
	width: 25px;
	height: 25px;
}

.dicom-ctrls {
	position: absolute;
	top: 12px;
	right: 45px;
	z-index: 9;
	text-align: right;
	height: calc(100% - 20px);
	width: 0;
}

.dicom-setting-btn {
	margin: 5px 0;
	padding: 0;
}

.dicom-settings-panel {
	position: absolute;
	width: 175px;
	right: -40px;
	top: 40px;
	max-height: calc(100% - 40px);
	overflow-y: scroll;
	/* overflow-y: auto; */
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px transparent;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: transparent;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #007bff;
}

.dicom-setting-item {
	padding: .3rem .4rem;
}

.dicom-setting-group {
	text-align: right;
	border: 1px solid #ced4da;
	align-items: end;
	border-radius: 0.2rem;
	padding-right: 5px;
}

.dicom-ctrl {
	text-align: right;
	display: block;
	white-space: nowrap;
	margin: 0;
}

.dicom-check-label {
	margin-right: 0.5rem;
	vertical-align: middle;
}

.dicom-check-input {
	/* margin-top: 20px; */
	vertical-align: middle;
}

.dicom-offset {
	position: absolute;
	right: 10px;
	bottom: 10px;
	/* z-index: 9; */
	font-size: 1rem;
	color: white;
}

.dicom-zoom-container {
	position: absolute;
	text-align: center;
	width: 35px;
	left: 5px;
	top: 17px;
}

.dicom-zoom-btn {
	width: 20px;
	height: 20px;
	margin-bottom: 12px;
	color: white;
	cursor: pointer;
	/* font-size: 2rem; */
}
</style>

<style scoped>
.annotation-column {
	display: none;
	padding-left: 3px;
	background-color: white;
	/* border: 1px solid green; */
}

.annotation-tool {
	width: 26px;
	height: 26px;
}

.annotation-save-btn {
	margin-top: 2px;
}

.annotation-view-btn {
	margin-top: 3px;
}

.annotation-numbers-btn {
	margin-top: -4px;
	margin-left: -7px;
	width: 37px;
	height: 39px;
}

.annotation-contour-btn {
	margin-top: 1px;
	margin-left: 7px;
	width: 30px;
	height: 30px;
}

.annotation-brush-btn {
	margin-top: 1px;
	margin-left: -6px;
	width: 31px;
	height: 31px;
}

.annotation-draw-btn {
	margin-top: 3px;
	margin-left: 2px;
	width: 30px;
	height: 30px;
	/* border: 1px solid #FFFFFF; */
}

.annotation-erase-btn {
	margin-top: 3px;
	margin-left: -9px;
	width: 30px;
	height: 30px;
}

.annotation-undo-btn {
	margin-top: 3px;
	margin-left: 5px;
	width: 33px;
	height: 33px;
}

.annotation-redo-btn {
	margin-top: 3px;
	margin-left: -6px;
	width: 33px;
	height: 33px;
}

.annotation-on-item {
	display: none;
}
</style>

<style>
/* .layerGroup {
	position: relative;
	padding: 0;
	display: flex;
	justify-content: center;
	height: 90%;
} */

.layer {
	position: absolute;
	pointer-events: none;
}

.dicom-intersection {
	position: absolute;
	pointer-events: none;
	border: none;
	margin: 0;
	/* border-top: 2px dashed lime; */
	/* z-index: 99; */
}

.dicom-intersection.horizontal {
	left: 0;
	margin-top: -1px;
	width: 100%;
}

.dicom-intersection.vertical {
	top: 0;
	margin-left: -1px;
	height: 100%;
}

.dicom-slider-container {
	position: absolute;
	text-align: center;
	width: 35px;
	left: 5px;
	top: 110px;
	height: calc(100% - 230px);
	z-index: 3;
}

.dicom-span {
	color: #FFFFFF;
	display: block;
	font-size: small;
}

.dicom-slider {
	height: calc(100% - 7px);
	border-radius: 0;
	outline: none;
	width: 0.43rem;
	margin-top: 15px;
	margin-bottom: 10px;
	margin-left: calc(50% - 0.25rem);
}

.dicom-slider-btn {
	cursor: pointer;
	width: 30px;
	height: 30px;
}

.dicom-slider .ui-slider-range {
	display: none;
}

.dicom-slider .ui-slider-handle {
	outline: none;
	border-radius: 30px;
	width: 1.6rem;
	height: 1.6rem;
	left: -.65rem;
}
</style>