import { render, staticRenderFns } from "./DicomMenu.vue?vue&type=template&id=629ee33f&scoped=true&"
import script from "./DicomMenu.vue?vue&type=script&lang=js&"
export * from "./DicomMenu.vue?vue&type=script&lang=js&"
import style0 from "./DicomMenu.vue?vue&type=style&index=0&id=629ee33f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629ee33f",
  null
  
)

export default component.exports