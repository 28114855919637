import { enterEditCrownMode } from "../crowns/EditCrownMode.vue";
import { updateMeshGeometryAndCenter } from "../editObject";

function addImplantStl(implant, isNew) {
    //if (isNew)
    implant.stlModelLoaded = initImplant;

    var stlModel = { filename: "../stl/implant.stl", color: implant.color };
    // if (implant.id > 0) {
    //     stlModel.id = implant.id;
    // }
    viewer.add_model(stlModel);
}

function initImplant(model_id) {
    var addedImplant = vueApp.getItemById(model_id);
    if (addedImplant && addedImplant.length) {
        addedImplant.isLoaded = true;
        var length = addedImplant.length;
        var diameter = addedImplant.diameter;
        var model = viewer.models[viewer.models_ref[model_id]];
        var mesh = viewer.get_model_mesh(model_id);
        addedImplant.uuid = mesh.uuid;

        mesh.rotateX(-Math.PI / 2);
        updateMeshGeometryAndCenter(mesh);

        if (addedImplant.isMaxilla) {
            mesh.rotation.z = Math.PI;
            updateMeshGeometryAndCenter(mesh);
        }

        var cbox = convertToBox(mesh.geometry.boundingBox);
        var apex = { x: cbox.center.x, y: cbox.max.y, z: cbox.center.z };
        var root = { x: cbox.center.x, y: cbox.min.y, z: cbox.center.z };
        if (addedImplant.isMaxilla) {
            apex.y = cbox.min.y;
            root.y = cbox.max.y;
        }
        var apexMesh = drawPoint(apex, 0x0000FF, true, 0.01);
        var rootMesh = drawPoint(root, 0xFF0000, true, 0.01);
        mesh.add(apexMesh);
        mesh.apexMesh = apexMesh;
        mesh.add(rootMesh);
        mesh.rootMesh = rootMesh;

        viewer.set_geo_minmax(model);
        var modelInfo = viewer.get_model_info(model_id);
        var dims = modelInfo.dims;
        var scaleX = diameter / dims.x;
        var scaleY = length / dims.y;
        mesh.geometry.scale(scaleX, scaleY, scaleX);

        if (addedImplant.isFromData) {
            if (!addedImplant.defaultPosition)
                addedImplant.defaultPosition = { x: addedImplant.x, y: addedImplant.y, z: addedImplant.z };

            mesh.rotation.x = addedImplant.rotationX;
            mesh.rotation.y = addedImplant.rotationY;
            mesh.rotation.z = addedImplant.rotationZ;
            //console.log(addedImplant);
            if (addedImplant.isMaxilla && !addedImplant.newRotation) {
                addedImplant.newRotation = true;
                mesh.rotation.z -= Math.PI;
            }
        } else {
            addedImplant.newRotation = true;
            var pivotY = addedImplant.isMaxilla ? addedImplant.y + length / 2 : addedImplant.y - length / 2;
            addedImplant.defaultPosition = { x: addedImplant.x, y: pivotY, z: addedImplant.z };
        }
        addedImplant.defaultRotation = mesh.rotation.clone();
        mesh.position.set(addedImplant.defaultPosition.x, addedImplant.defaultPosition.y, addedImplant.defaultPosition.z);
        viewer.set_geo_minmax(model);

        if (!addedImplant.isFromData) {
            var implantCenter = addedImplant.defaultPosition;

            var tooth2 = null, center1, center2;
            var tooth1 = findNearestTooth(implantCenter, addedImplant.dentition, true, null);
            if (!tooth1)
                tooth1 = findNearestTooth(implantCenter, addedImplant.dentition, false, null);
            updateToothData(tooth1);
            if (tooth1) {
                tooth2 = findNearestTooth(implantCenter, addedImplant.dentition, true, tooth1);
                if (!tooth2)
                    tooth2 = findNearestTooth(implantCenter, addedImplant.dentition, false, tooth1);
                updateToothData(tooth2);
            }

            if (tooth1 && tooth2) {
                var x = addedImplant.defaultPosition.x;
                var z = addedImplant.defaultPosition.z;
                var y = tooth1.splitPoint.y + (tooth2.splitPoint.y - tooth1.splitPoint.y) / 2;
                var nearestPoints = findNearestPoints(tooth1.bbox, tooth2.bbox);
                if (!addedImplant.crownId && nearestPoints && nearestPoints.length > 1) {
                    var raycaster = new THREE.Raycaster();

                    var origin1 = new THREE.Vector3(tooth1.splitPoint.x, tooth1.splitPoint.y, tooth1.splitPoint.z);
                    var direction1 = new THREE.Vector3(tooth2.splitPoint.x, tooth2.splitPoint.y, tooth2.splitPoint.z);//tooth2.splitPoint;

                    direction1.subVectors(direction1, origin1).normalize();
                    raycaster.set(origin1, direction1);

                    var intersects = raycaster.intersectObject(tooth2.boxMesh, true);
                    var point1 = intersects.length > 0 ? intersects[0].point : null;

                    var origin2 = new THREE.Vector3(tooth2.splitPoint.x, tooth2.splitPoint.y, tooth2.splitPoint.z);
                    var direction2 = new THREE.Vector3(tooth1.splitPoint.x, tooth1.splitPoint.y, tooth1.splitPoint.z);

                    direction2.subVectors(direction2, origin2).normalize();
                    raycaster.set(origin2, direction2);

                    var intersects2 = raycaster.intersectObject(tooth1.boxMesh, true);
                    var point2 = intersects2.length > 0 ? intersects2[0].point : null;

                    if (point1 && point2) {
                        x = point1.x + (point2.x - point1.x) / 2;
                        z = point1.z + (point2.z - point1.z) / 2;
                    }
                }
                addedImplant.defaultPosition = { x: x, y: y, z: z };

                center1 = tooth1.defaultBox.center;
                center2 = tooth2.defaultBox.center;
                tooth1.boxMesh = tooth2.boxMesh = null;
            } else if (tooth1) {
                addedImplant.defaultPosition.y = tooth1.splitPoint.y;
                //drawPoint(tooth1.splitPoint);
                center1 = tooth1.defaultBox.center;
                center2 = addedImplant.defaultPosition;
                tooth1.boxMesh = null;
            } else {
                addedImplant.defaultPosition.y = addedImplant.isMaxilla ? length / 2 : -length / 2;
                center1 = { x: addedImplant.defaultPosition.x - 10, y: addedImplant.defaultPosition.y, z: addedImplant.defaultPosition.z };
                center2 = { x: addedImplant.defaultPosition.x + 10, y: addedImplant.defaultPosition.y, z: addedImplant.defaultPosition.z };
            }

            if (tooth1) {
                if (addedImplant.isMaxilla)
                    addedImplant.defaultPosition.y += length / 2;
                else
                    addedImplant.defaultPosition.y -= length / 2;
            }

            mesh.position.set(addedImplant.defaultPosition.x, addedImplant.defaultPosition.y, addedImplant.defaultPosition.z);

            if (center1 && center2) {
                var mx = center1.x + (center2.x - center1.x) / 2;
                var my = center1.y + (center2.y - center1.y) / 2;
                var mz = center1.z + (center2.z - center1.z) / 2;
                var mpoint = { x: mx, y: my, z: mz };
                var opoint = getPoint3(mpoint, center1, 90);
                addedImplant.focusCameraPosition = opoint;
            }

            //#region Default rotation

            if (tooth1 && tooth2)
                addedImplant.number = Math.min(tooth1.number, tooth2.number);
            else if (tooth1)
                addedImplant.number = tooth1.number;

            if (addedImplant.crownId) {
                var crown = vueApp.getItemById(addedImplant.crownId);
                if (crown) {
                    addedImplant.angle = crown.angle;
                }
            }
            var angle = getImplantAngle(addedImplant);
            if (!addedImplant.angle)
                addedImplant.angle = angle;
            if (addedImplant.angle && addedImplant.opoint) {
                var axis = new THREE.Vector3();
                axis.subVectors(addedImplant.opoint, mesh.position).normalize();
                mesh.rotateOnWorldAxis(axis, addedImplant.angle);
            }
            addedImplant.defaultRotation = mesh.rotation.clone();

            //#endregion

            editedImplant = addedImplant;
            enterEditCrownMode(editedImplant);
            vueApp.saveData();
            //focusToImplant(editedImplant);
        }
        cbox = offsetBox(cbox, addedImplant.defaultPosition.x, addedImplant.defaultPosition.y, addedImplant.defaultPosition.z);
        addedImplant.defaultBox = cbox;

        addedImplant = null;
    }
}


export { addImplantStl };