import axios from "@/api/axios";
// import { parse } from "raji"

function sendDicomSegmentation() {
  console.log("sendDicomSegmentation");
  if (dicomSegmentation) {
    // Object.fromEntries(vueApp.dicomSegmentation);
    let stringData = JSON.stringify(dicomSegmentation);
    // let saved = utf8_to_b64(stringData);

    var zip = new JSZip();
    zip.file("dicomSegmentation.json", stringData);

    zip
      .generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9,
        },
      })
      .then(async function (content) {
        let url = "/api/dicom/saveDicomSegmentation";
        let formData = new FormData();
        var file = new File([content], "dicomSegmentation.zip");
        formData.append("file", file);
        formData.append("patientId", vueApp.patientId);
        formData.append("s3Path", vueApp.stlBaseUrl);
        let config = {};
        config.headers = {
          "content-type": "multipart/form-data",
        };
        const response = await axios.post(url, formData, config);
        console.log("sendDicomSegmentation response", response);
      });
  }
}

async function readDicomSegmentation() {
  console.log("readDicomSegmentation");
  if (vueApp && vueApp.stlBaseUrl) {
    let url = `${vueApp.stlBaseUrl}/dicomSegmentation.zip`;
    JSZipUtils.getBinaryContent(url, {
      callback: function (err, zipData) {
        if (err) {
          console.log("readDicomSegmentation error", err);
        } else {
          console.log(`dicomSegmentation.zip downloaded, ${url}`);
          JSZip.loadAsync(zipData).then(function (zip) {
            let zipFile = zip.file("dicomSegmentation.json");
            if (zipFile) {
            //   console.log(zipFile);
              zipFile.async("string").then(function (data) {
                dicomSegmentation = JSON.parse(data); // await parse(data);//
                console.log("readDicomSegmentation success");
              });
            }
          });
        }
      },
    });
  }
}

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export { sendDicomSegmentation, readDicomSegmentation };
