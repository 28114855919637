<template>
	<div class="modal fade" id="reportsDialog" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div class="modal-content dialog-content">
				<div class="modal-body">
					<button id="btnCloseReportsDialog" type="button" class="close" data-dismiss="modal"
						aria-label="Close" style="z-index: 9">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="container-fluid">
						<div class="row report-row">
							<div class="col-6">
								<div class="form-check">
									<input type="radio" class="form-check-input" id="imageCheck" name="fileType"
										value="image" v-model="reportFormat">
									<!-- -->
									<label class="form-check-label" for="imageCheck">Image (*.jpeg)</label>
								</div>
							</div>
							<div class="col-6">
								<div class="form-check">
									<input type="radio" class="form-check-input" id="videoCheck" name="fileType"
										value="video" v-model="reportFormat">
									<label class="form-check-label" for="videoCheck">Video</label>
								</div>
							</div>
						</div>
						<div class="row report-row">
							<div class="col-6">
								<div class="form-check">
									<input type="radio" class="form-check-input" id="pdfCheck" name="fileType"
										value="pdf" v-model="reportFormat">
									<label class="form-check-label" for="pdfCheck">PDF (current view)</label>
								</div>
							</div>

							<div class="col-6">
								<div class="form-check">
									<input type="radio" class="form-check-input" id="multiPdfCheck" name="fileType"
										value="multiPdf" v-model="reportFormat">
									<!--  checked -->
									<label class="form-check-label" for="multiPdfCheck">PDF (8 in 1)</label>
								</div>
							</div>

							<div v-show="pdfSelected" class="col-12">
								<hr class="report-split-line" />
							</div>
						</div>

						<div v-show="pdfSelected" class="row report-row">
							<div class="col-6">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="companyLogoCb"
										v-model="dataToInclude.isCompanyLogo">
									<label class="form-check-label" for="companyLogoCb">Company Logo</label>
								</div>
							</div>
							<div class="col-6">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="patientInfoCb"
										v-model="dataToInclude.isPatientInfo">
									<label class="form-check-label" for="patientInfoCb">Patient Info</label>
								</div>
							</div>
						</div>

						<div v-show="pdfSelected" class="row report-row">
							<div class="col-6">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="caseLinkCb"
										v-model="dataToInclude.isCaseLink">
									<label class="form-check-label" for="caseLinkCb">Case Link</label>
								</div>
							</div>
							<div class="col-6">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="qrCodeCb"
										v-model="dataToInclude.isQrCode">
									<label class="form-check-label" for="qrCodeCb">QR Code</label>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<button id="btnGetFile" class="btn btn-primary" aria-label="Get File"
									v-on:click="getFile()">GET FILE</button>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<small id="hintGetReport" class="form-text">Press button to get file</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {
	getFileByFormat
} from "./ReportsManager";

export default {
	name: "ReportsDialog",
	props: [],
	data() {
		return {
			reportFormat: "multiPdf",
			dataToInclude: {
				isCompanyLogo: true,
				isPatientInfo: true,
				isCaseLink: true,
				isQrCode: true,
			}
		};
	},
	computed: {
		pdfSelected() {
			return this.reportFormat == "pdf" || this.reportFormat == "multiPdf";
		},
	},
	methods: {
		getFile: function () {
			// let format = $('input[name="fileType"]:checked').val();
			let format = this.reportFormat;
			getFileByFormat(format, this.dataToInclude);
		}
	}
};
</script>

<style scoped>
.report-split-line {
	margin-left: -15px;
	margin-top: 15px;
	margin-bottom: 0;
	border-top: 1px solid grey;
}

.report-row {
	margin-left: 5px;
}

.modal-sm {
	max-width: 400px;
}

.dialog-content {
	background-color: #ffffff;
}

.close {
	margin-right: -8px;
	margin-top: -10px;
}

.row {
	margin-top: 20px;
}

.btn {
	width: 100%;
	border-radius: 0px;
	height: 40px;
	background-color: #0069d9;
}

#hintGetReport {
	text-align: center;
	color: green;
	font-size: smaller;
	margin-top: -10px;
}

/* input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
} */
</style>
