<template>
	<div id="teeth_opacity_container" class="opacity-ctrl implant-ctrl" onmousedown="preventHide(event);">
		<span class="opacity-span">TEETH</span>
		<span class="opacity-span">100</span>
		<div id="teeth_opacity" class="opacity-slider"></div>
		<span class="opacity-span">0</span>
	</div>
</template>

<script>
	export default {
		name: "TeethOpacity",
		props: [],
		components: {
		},
		methods: {
		}
	}
</script>

<style scoped>
	@import "opacity.css";

	#teeth_opacity_container {
		width: 35px;
		left: 5px;
		top: 20px;
		height: calc(100% - 210px);
	}
</style>