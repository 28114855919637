<template>
    <div id="dicom_3d_viewer">

        <input id="gaussianFilterRange" type="range" min="0" max="1" step="0.02" v-model="gaussianFilter"
            @input="gaussianFilterChanged()" />

        <div id="dicom_3d_widget">

        </div>

        <img id="dicom3dSpinner" class="centered-spinner" src="images/dark-blue-spinner.gif" />
    </div>
</template>

<script>

import {
    gaussianBasePosition, loadVtkImage, updateGausianWidget
} from "./Dicom3dManager";

export default {
    name: "Dicom3dViewer",
    data() {
        return {
            gaussianFilter: gaussianBasePosition,
        };
    },
    props: {
        app: {
            type: Object,
            required: false
        }
    },
    mounted() {
        setTimeout(function () {
            loadVtkImage();
        }, 100);
    },
    methods: {
        gaussianFilterChanged: function () {
            console.log("gaussianFilter", this.gaussianFilter);
            updateGausianWidget(this.gaussianFilter);
        }
    }
};
</script>

<style>
#dicom_3d_viewer {
    position: absolute;
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    font-size: 14px;
    background-color: black;
    /* display: none; #77a7fd*/
}

#dicom_3d_widget {
    position: absolute;
    top: 45px;
    left: 7px;
    width: 300px;
    height: 150px;
    z-index: 99;
    /* background-color: gray; */
}

#gaussianFilterRange {
    position: absolute;
    top: 10px;
    left: calc(50% - 150px);
    ;
    width: 300px;
    z-index: 9;
}
</style>