//#region Imports

import AuxiliaryViewer from "./AuxiliaryViewer.vue";
import { getAnnotationData, getAnnotationGeometry } from "./annotation";
import { runSmoothing } from "./smoothing";

//#endregion

//#region Fields

const sceneBackgroundColor = "#77a7fd";

let viewerInitialized = false;
let ioViewer;
let defaultIoBox = null;
let defaultIoZoom = null;
let teethModels = [];
let dicomApp = null;
let childApp = null;

//#endregion

//#region Methods

function switchAuxiliaryView(app) {
  if (app.is3D) {
    childApp = app;
    showAuxiliaryViewer(app);
  }
}

function showAuxiliaryViewer(app) {
  dicomApp = app.parentApp;

  let annotation = dicomApp.focusedAnnotation;
  if (!annotation) {
    annotation = dicomApp.editedAnnotation;
  }
  dicomApp.selectedAnnotation = annotation;

  if (!viewerInitialized) {
    let containerId = app.auxiliaryId;
    let container = document.getElementById(containerId);
    const viewerInstance = new Vue({
      ...AuxiliaryViewer,
      propsData: {
        app: dicomApp,
      },
    });
    const vueContainer = document.createElement("div");
    container.appendChild(vueContainer);
    viewerInstance.$mount(vueContainer);
    viewerInitialized = true;
  } else if (ioViewer) {
    // ioViewer.do_resize();
    focusAuxilaryModel();
  }
}

function initAuxilaryStlViewer(app) {
  console.log("initAuxilaryStlViewer");

  vueApp.allTeeth.forEach((tooth) => {
    let mesh = viewer.get_model_mesh(tooth.id);
    if (mesh && mesh.geometry && mesh.geometry.boundingBox) {
      let tmesh = mesh.clone();
      let color = vueApp.getActualColor(tooth);
      const model = {
        name: tooth.filename,
        mesh: tmesh,
        color: color,
        rootModel: tooth,
        opacity: 0.01,
      };
      teethModels.push(model);
    } else {
      console.log("invalid tooth", tooth);
    }
  });

  ioViewer = new StlViewer(document.getElementById(app.auxiliaryViewerId), {
    models: teethModels,
    load_three_files: "js/",
    // center_models: false,
    controls: 1,
    model_loaded_callback: stlModelLoaded,
    // zoom: -1,
  });
  ioViewer.scene.background = new THREE.Color(sceneBackgroundColor);
  ioViewer.controls.zoomSpeed = 2.1;
  ioViewer.controls.keysEnabled = false;

  setTimeout(function () {
    auxilaryStlAllLoaded();
  }, 100);
}

function auxilaryStlAllLoaded() {
  console.log("auxilaryStlAllLoaded");
  // ioViewer.scene.add(new THREE.AxesHelper(80));
  ioViewer.controls.screenSpacePanning = true;
  ioViewer.renderer.outputEncoding = THREE.sRGBEncoding;
  let currentMin = { x: ioViewer.minx, y: ioViewer.miny, z: ioViewer.minz };
  let currentMax = { x: ioViewer.maxx, y: ioViewer.maxy, z: ioViewer.maxz };
  let currentBox = { min: currentMin, max: currentMax };
  defaultIoBox = convertToBox(currentBox);
  console.log("defaultIoBox", defaultIoBox);

  let padding = -10;
  let w = (defaultIoBox.size.x + padding) / 2;
  let h = (defaultIoBox.size.y + padding) / 2;
  let fovX = ioViewer.camera.fov * ioViewer.camera.aspect;
  let fovY = ioViewer.camera.fov;
  let distanceX = w / Math.tan((Math.PI * fovX) / 360) + w;
  let distanceY = h / Math.tan((Math.PI * fovY) / 360) + w;
  defaultIoZoom = Math.max(distanceX, distanceY) + 20;

  teethModels.forEach((model) => {
    ioViewer.set_opacity(model.id, 1);
    model.mesh.visible = false;
  });

  focusAuxilaryModel();

  resetIoCamera();
}

function focusAuxilaryModel() {
  if (dicomApp) {
    let annotation = dicomApp.focusedAnnotation;
    if (!annotation) {
      annotation = dicomApp.editedAnnotation;
    }
    dicomApp.selectedAnnotation = annotation;

    teethModels.forEach((model) => {
      model.mesh.visible = false;
    });

    if (annotation && annotation.isTooth) {
      let selectedModel = teethModels.find(
        (model) => model.rootModel == annotation.model
      );
      // console.log("focusAuxilaryModel", selectedModel);
      if (selectedModel) {
        selectedModel.mesh.visible = true;

        if (annotation.changed) {
          updateAuixiliaryMesh(annotation);
        }
      } else {
        let blob = getAnnotationData(annotation, dicomApp);
        if (blob) {
          let color = annotation.color;
          const model = {
            name: annotation.filename,
            local_file: new File([blob], annotation.filename),
            color: color,
            rootModel: annotation.model,
            opacity: 1,
          };
          teethModels.push(model);
          ioViewer.add_model(model);
        }
        annotation.changed = false;
      }
      resetIoCamera();
    }
  }
}

function stlModelLoaded(model_id) {
  let tooth = teethModels.find((model) => model.id == model_id);
  if (tooth) {
    console.log("stlModelLoaded", tooth.name);
    if (!tooth.mesh) {
      tooth.mesh = ioViewer.get_model_mesh(tooth.id);
    }
  }
}

function updateAuixiliaryMesh(annotation) {
  if (childApp && childApp.is3D && dicomApp && annotation) {
    annotation.changed = false;
    let selectedModel = teethModels.find(
      (model) => model.rootModel == annotation.model
    );
    if (selectedModel) {
      let mesh = ioViewer.get_model_mesh(selectedModel.id);
      if (mesh) {
        let newGeometry = getAnnotationGeometry(annotation, dicomApp);
        newGeometry.computeBoundingBox();
        let box = newGeometry.boundingBox;
        box = offsetBox(box);

        newGeometry.center();
        newGeometry.computeVertexNormals();

        let geometry = new THREE.Geometry().fromBufferGeometry(newGeometry);
        geometry.mergeVertices();
        geometry.computeVertexNormals();
        geometry = new THREE.BufferGeometry().fromGeometry(geometry);
        geometry.computeBoundingBox();
        // geometry.computeBoundingSphere();
        // geometry.attributes.position.needsUpdate = true;
        // mesh.material.needsUpdate = true;
        // mesh.updateMatrix();

        let oldGeometry = mesh.geometry;
        mesh.geometry = geometry;

        mesh.visible = true;

        if (
          !oldGeometry.attributes ||
          !oldGeometry.attributes.position ||
          !oldGeometry.attributes.position.count
        ) {
          resetIoCamera();
        }

        oldGeometry.dispose();
      }
    }
  }
}

async function updateAuixiliaryMeshAsync(annotation) {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (childApp && childApp.is3D && dicomApp && annotation) {
        annotation.changed = false;
        let selectedModel = teethModels.find(
          (model) => model.rootModel == annotation.model
        );
        if (selectedModel) {
          let mesh = ioViewer.get_model_mesh(selectedModel.id);
          if (mesh) {
            let newGeometry = getAnnotationGeometry(annotation, dicomApp);
            newGeometry.computeBoundingBox();
            let box = newGeometry.boundingBox;
            box = offsetBox(box);

            newGeometry.center();
            newGeometry.computeVertexNormals();

            let geometry = new THREE.Geometry().fromBufferGeometry(newGeometry);
            geometry.mergeVertices();
            geometry.computeVertexNormals();
            geometry = new THREE.BufferGeometry().fromGeometry(geometry);
            geometry.computeBoundingBox();

            let oldGeometry = mesh.geometry;
            mesh.geometry = geometry;

            mesh.visible = true;

            if (
              !oldGeometry.attributes ||
              !oldGeometry.attributes.position ||
              !oldGeometry.attributes.position.count
            ) {
              resetIoCamera();
            }

            oldGeometry.dispose();
          }
        }
      }
      resolve("resolved");
    }, 50);
  });
}

function resetIoCamera() {
  let point = defaultIoBox.center;

  ioViewer.camera.up.y = 1;
  ioViewer.camera.up.x = 0;
  ioViewer.camera.up.z = 0;
  ioViewer.camera.position.x = point.x;
  ioViewer.camera.position.y = point.y;
  ioViewer.camera.position.z = defaultIoZoom;

  ioViewer.camera.lookAt(point.x, point.y, point.z);
  ioViewer.controls.target = new THREE.Vector3(point.x, point.y, point.z);
}

function smoothSelectedAnnotation() {
  let annotation = dicomApp.focusedAnnotation;
  if (!annotation) {
    annotation = dicomApp.editedAnnotation;
  }
  if (annotation) {
    let factor = annotation.smoothFactor;
    console.log("smoothSelectedAnnotation", factor);
    let selectedModel = teethModels.find(
      (model) => model.rootModel == annotation.model
    );
    if (selectedModel) {
      let mesh = ioViewer.get_model_mesh(selectedModel.id);
      // let currentGeometry = mesh.geometry.clone().toIndexed();

      let newGeometry = getAnnotationGeometry(annotation, dicomApp);
      // runSmoothing(currentGeometry, 1);
      newGeometry.computeBoundingBox();
      let box = newGeometry.boundingBox;
      box = offsetBox(box);

      newGeometry.center();
      newGeometry.computeVertexNormals();

      let geometry = new THREE.Geometry().fromBufferGeometry(newGeometry);
      geometry.mergeVertices();
      geometry.computeVertexNormals();
      geometry = new THREE.BufferGeometry().fromGeometry(geometry);
      geometry.computeBoundingBox();

      mesh.geometry.dispose();
      mesh.geometry = geometry;

      annotation.wasChanged = true;

      let rootModel = selectedModel.rootModel;
      // vueApp.allTeeth.find(
      //   (model) => model.name == annotation.filename
      // );
      if (rootModel) {
        mesh = viewer.get_model_mesh(rootModel.id);
        if (mesh && mesh.geometry) {
          mesh.geometry.dispose();
          mesh.geometry = geometry;
        }
      }
    }
  }
}

//#endregion

//#region Export
export {
  switchAuxiliaryView,
  initAuxilaryStlViewer,
  focusAuxilaryModel,
  updateAuixiliaryMesh,
  smoothSelectedAnnotation,
};
//#endregion
