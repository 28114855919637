<template>
	<div id="implantSidePanel" onmousedown="preventHide(event);" v-bind:style="{display: editContext.display}">
		<img class="side-ctrl-img2" v-on:click="moveTooth()"
			:src="editContext.isMoved ? 'images/move_mode_on.png' : 'images/move_mode_off.png'" />
		<img class="side-ctrl-img2" v-on:click="rotateTooth()"
			:src="editContext.isRotated ? 'images/rotate_mode_on.png' : 'images/rotate_mode_off.png'" />

		<div id="implantSubPanel">
			<img id="rootRotateBtn2" class="side-sm-img" v-on:click="changeRotationBase(false)" v-show="editContext.isRotated"
				:src="editContext.isApex ? 'images/root_mode_off.png' : 'images/root_mode_on.png'" />
			<img id="apexRotateBtn2" class="side-sm-img" v-on:click="changeRotationBase(true)" v-show="editContext.isRotated"
				:src="editContext.isApex ? 'images/edge_mode_on.png' : 'images/edge_mode_off.png'" />

			<img id="vertMoveBtn2" class="side-sm-img" v-on:click="changePlane(false)" v-show="!editContext.isRotated"
				:src="editContext.isVertical ? 'images/leftright-off.png' : 'images/leftright-on.png'" />
			<img id="horMoveBtn2" class="side-sm-img" v-on:click="changePlane(true)" v-show="!editContext.isRotated"
				:src="editContext.isVertical ? 'images/updown-on.png' : 'images/updown-off.png'" />
		</div>

		<div id="movementVectorContainer2" onmousedown="preventHide(event);">
			<span id="movementVectorTitle2" class="opacity-span">Movement Vector</span>
			<div id="movementVector2" class="opacity-slider"></div>
			<span class="opacity-span">0</span>
		</div>

		<div id="moveShowTapArrowsDiv2" class="toggle-div" v-on:click.stop="switchTapArrows()">
			<div class="toggle-title">Show Tap Arrows</div>

			<input id="moveShowTapArrowsButton2"
				type="checkbox" />
		</div>

		<img class="side-ctrl-img2" v-on:click="resizeTooth()"
			:src="editContext.isResized ? 'images/Resize-ON.png' : 'images/resize.png'" />

		<img id="resetImplantBtn" class="side-ctrl-img2" v-on:click="resetTooth()"
			src="images/resetImplant.png" />
	</div>
</template>

<script>
import {
	enterMoveMode,
	exitMoveMode,
	redrawMoveArrow,
	changeMovePlane
} from "../newMove/newMoveMode";
import {
	enterRotateMode,
	exitRotateMode,
	changeRotatePlane,
	redrawRotateArrow
} from "../newRotate/newRotateMode";
// import { resetToothPositionAndRotation } from "./OrthoPlanMode";
import { enterResizeMode, exitResizeMode } from "../crowns/resize/resizeMode";
import { resetCrown } from "../crowns/EditCrownMode";

export default {
	name: "ImplantSidePanel",
	props: ["editContext"],
	components: {},
	methods: {
		changePlane: function(isVertical) {
			this.editContext.isVertical = isVertical;
			changeMovePlane(isVertical);
		},
		changeRotationBase: function(isApex) {
			this.editContext.isApex = isApex;
			changeRotatePlane(isApex);
		},
		moveTooth: function() {
			exitResizeMode();
			exitRotateMode();
			enterMoveMode(this.editContext);
		},
		rotateTooth: function() {
			exitResizeMode();
			exitMoveMode();
			enterRotateMode(this.editContext);
		},
		resizeTooth: function() {
			var crown = vueApp.getItemById(this.editContext.id);
			if (crown && crown.isCrown) {
				exitMoveMode();
				exitRotateMode();
				enterResizeMode(this.editContext);
			} else if (editedImplant) {
				vueApp.resizeImplant(editedImplant);
				$("#implantsStep2").modal();
			}
		},
		resetTooth: function() {
			var crown = vueApp.getItemById(this.editContext.id);
			resetCrown(crown);
			if (this.editContext.isRotated) {
				enterRotateMode(this.editContext);
			} else if (this.editContext.isMoved) {
				enterMoveMode(this.editContext);
			} else {
				enterResizeMode(this.editContext);
			}
		},
		switchTapArrows: function() {
			this.editContext.showTapArrows = !this.editContext.showTapArrows;
			switchToggle("moveShowTapArrowsDiv2", this.editContext.showTapArrows);

			if (this.editContext.isMoved) {
				redrawMoveArrow();
			} else if (this.editContext.isRotated) {
				redrawRotateArrow();
			}
		}
	}
};
</script>

<style scoped>
/* @import "../../css/editControls.css"; */
@import "../opacity/opacity.css";

#moveShowTapArrowsDiv2 {
	margin-left: 10px;
	margin-top: 10px;
}

.toggle-title {
	margin-left: -35px;
	margin-top: 5px;
	margin-bottom: 5px;
	width: 155px;
	font-size: small;
	font-weight: normal;
}

.opacity-slider {
	/*position: relative;*/
	height: calc(100% - 40px);
	border-radius: 0;
	outline: none;
	width: 0.5em;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: calc(50% - 0.25em);
}

#movementVectorTitle2 {
	margin-left: -50px;
	width: 135px;
}

#movementVectorContainer2 {
	/* background-color: chartreuse;  */
	width: 35px;
	margin-left: 35px;
	margin-top: 10px;
	height: calc(100% - 465px);
}

#implantSubPanel {
	/* background-color: chartreuse; */
	width: 100px;
}

.side-sm-img {
	width: 50px;
	cursor: pointer;
	display: inline-block;
}

@media screen and (max-width: 650px) {
	.side-sm-img {
		width: 40px;
	}
}

.side-ctrl-img2 {
	margin-left: 10px;
	width: 80px;
	cursor: pointer;
}

@media screen and (max-width: 650px) {
	.side-ctrl-img2 {
		width: 50px;
	}
}

#implantSidePanel {
	/* background-color: chartreuse; */
	display: none;
	left: 15px;
	top: 45px;
	width: 70px;
	height: calc(100% - 105px);
	position: absolute;
	text-align: center;
	z-index: 9;
	color: #ffffff;
}

#resetImplantBtn {
	position: absolute;
	bottom: 5px;
	left: 0;
}

@media screen and (max-width: 650px) {
	#implantSidePanel {
		left: 5px;
		width: 50px;
	}

	#movementVectorContainer2 {
		/* background-color: chartreuse;  */
		width: 35px;
		margin-left: 35px;
		margin-top: 10px;
		height: calc(100% - 335px);
	}
}
</style>