<template>
	<div id="intraoralAddDialog" class="modal" tabindex="-1" role="dialog" aria-labelledby="intraoralAddDialogTitle"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<form class="modal-content dialog-content">
				<div class="modal-body wizard-body">
					<h5 class="modal-title wizard-title" id="intraoralAddDialogTitle">
						Add Tooth
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"
						style="z-index: 9; width: 30px;">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="container-fluid">
						<div class="mb-3 mt-4">
							<label for="intraoralToothNumberInput" class="form-label">Tooth Number:</label>
							<input v-model="newTooth.number" id="intraoralToothNumberInput" type="number"
								class="form-control" v-on:keydown.stop>
						</div>
					</div>
				</div>
				<div class="modal-footer wizard-footer">
					<button type="button" class="btn btn-primary btn-next" aria-label="OK" v-on:click="addToothClick()">
						OK
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import swal from "sweetalert";
import {
	getModelByName,
	startAddingTooth
} from "./IntraoralManager";


export default {
	name: "IntraoralAddDialog",
	data() {
		return {
			newTooth: {
				number: 0
			},
		};
	},
	methods: {
		addToothClick: async function () {
			// console.log("addToothClick", this.mode.newTooth);
			let newNumber = this.newTooth.number;
			if (!newNumber || newNumber < 1) {
				swal({
					text: "Tooth number must be positive value"
				});
			} else {
				let toothName = `tooth_${newNumber}`;
				let existedTooth = getModelByName(toothName);
				if (existedTooth && !existedTooth.isDeleted) {
					swal({
						text: "Tooth with such number already exists"
					});
				}
				else {
					$("#intraoralAddDialog").modal("hide");
					startAddingTooth(toothName);
					// addNewAnnotation(this.app, this.mode.newTooth);
				}
			}
		}
	}
};

</script>

<style scoped>
@import "@/css/wizard.css";

#intraoralAddDialogTitle {
	position: absolute;
	left: calc(50% - 50px);
	top: 12px;
}

.modal-sm {
	max-width: 350px;
	text-align: left;
}

.container-group {
	border: 2px solid #d7dee5;
	border-radius: 0.25rem;
	margin: 30px 0 20px 0;
	padding: 10px 0 4px 0;
	justify-content: space-between;
}

.form-check {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	max-width: calc(100% - 35px);
}

.form-check-label {
	display: inline-block;
	width: calc(100% + 25px);
}

.modal-header {
	height: 60px;
}

.modal-title {
	max-width: 270px;
	left: 45px;
	/* calc(50% - 55px); */
}

.modal-body {
	padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
}

.close {
	margin-right: -10px;
	margin-top: -20px;
}
</style>