import Vue from 'vue';
import App from './App.vue';
import store from './store';

//Vue.prototype.$appName = 'My App';
// npm link dwv
Vue.config.productionTip = false;

var vue = new Vue({
    store,
    render: function(createElement) { return createElement(App); }
}).$mount('#main_div');

window.vueApp = vue.$children[0];
