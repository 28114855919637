function ArrowHelper2(dir, origin, length, color, headLength, headWidth, material, tag) {
    const _axis = new THREE.Vector3();
    let _lineGeometry, _coneGeometry;

    // dir is assumed to be normalized
    if (dir === undefined) dir = new THREE.Vector3(0, 0, 1);
    if (origin === undefined) origin = new THREE.Vector3(0, 0, 0);
    if (length === undefined) length = 1;
    if (color === undefined) color = 0xffff00;
    if (headLength === undefined) headLength = 0.2 * length;
    if (headWidth === undefined) headWidth = 0.2 * headLength;

    if (_lineGeometry === undefined) {

        _lineGeometry = new THREE.BufferGeometry();
        _lineGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0, 0, 1, 0], 3));

        _coneGeometry = new THREE.CylinderBufferGeometry(0, 0.5, 1, 5, 1);
        _coneGeometry.translate(0, - 0.5, 0);

    }

    var obj = new THREE.Object3D();

    obj.position.copy(origin);

    obj.line = new THREE.Line(_lineGeometry, new THREE.LineBasicMaterial({ color: color, toneMapped: false }));
    obj.line.matrixAutoUpdate = false;
    obj.add(obj.line);

    if (!material) {
        material = new THREE.MeshBasicMaterial({
            color: color
            //, toneMapped: false, 
            // depthWrite: false,
            // depthTest: false,
            // transparent: true
        });
    }
    obj.cone = new THREE.Mesh(_coneGeometry, material);
    if (tag)
        obj.cone.tag = tag;
    obj.cone.matrixAutoUpdate = false;
    obj.add(obj.cone);

    obj.setLength = function (length, headLength, headWidth) {
        if (headLength === undefined) headLength = 0.2 * length;
        if (headWidth === undefined) headWidth = 0.2 * headLength;

        obj.line.scale.set(1, Math.max(0.0001, length - headLength), 1); // see #17458
        obj.line.updateMatrix();

        obj.cone.scale.set(headWidth, headLength, headWidth);
        obj.cone.position.y = length;
        obj.cone.updateMatrix();

    };

    obj.setDirection = function (dir) {
        if (dir.y > 0.99999) {
            obj.quaternion.set(0, 0, 0, 1);
        } else if (dir.y < - 0.99999) {
            obj.quaternion.set(1, 0, 0, 0);
        } else {
            _axis.set(dir.z, 0, - dir.x).normalize();
            const radians = Math.acos(dir.y);
            obj.quaternion.setFromAxisAngle(_axis, radians);
        }

    };

    obj.setColor = function (color) {
        obj.line.material.color.set(color);
        obj.cone.material.color.set(color);
    };

    obj.setDirection(dir);
    obj.setLength(length, headLength, headWidth);

    return obj;
}

export { ArrowHelper2 };