<template>
    <div class="annotation-container" v-if="!annotation.isDeleted" @mouseover="mouseOver" @mouseleave="mouseleave"
        v-show="annotation.isTooth || annotation.isBone || app.is3D" v-bind:style="{
        backgroundColor: annotation.isHovered ? '#007bff' : 'black', color: annotation.hasFigures ? 'white' : '#A9A9A9',
        flexDirection: annotation.isTooth ? 'column' : 'row'
    }">
        <div class="annotation-header">
            <img class="annotation-btn annotation-visible" v-on:click="isVisibleChanged()" title="Visible"
                :src="annotation.isVisible ? 'images/visible_on_white.png' : 'images/visible_off_white.png'" />
            <span class="annotation-color" ref="annotationColor" :style="{ backgroundColor: annotation.color }"></span>
            <div class="an-name" :title="annotation.name">{{ annotation.wasChanged ? "* " : "" }}{{ annotation.name }}
            </div>
            <img v-if="annotation.isBone" class="annotation-btn" v-on:click="edit()" title="Edit"
                :src="annotation.isEdited ? 'images/annotation_red.png' : 'images/annotation_white.png'" />
        </div>

        <div class="annotation-tools" v-if="annotation.isTooth">

            <img v-if="annotation.isTooth" class="annotation-btn annotation-settings" v-on:click="editSettings()"
                title="Edit Settings" :src="'images/gear_white_icon.png'" />
            <img v-if="annotation.isTooth" class="annotation-btn annotation-focus" v-on:click="focus()" title="Focus"
                :src="annotation.isFocused ? 'images/focus_red.png' : 'images/focus_white.png'" />
            <img v-if="annotation.isTooth" class="annotation-btn" v-on:click="edit()" title="Edit"
                :src="annotation.isEdited ? 'images/annotation_red.png' : 'images/annotation_white.png'" />
            <img v-if="annotation.isTooth" class="annotation-btn" v-on:click="removeAnnotation()" title="Delete"
                :src="'images/trash_icon.png'" />
            <!-- <img class="annotation-download" v-on:click="download()" title="Download" :src="'images/download_white.png'" /> -->

        </div>

    </div>
</template>

<script>
import swal from "sweetalert";
import { highlightAnnotation, unhighlightAnnotation } from "./AnnotationMode";
import { saveMeshFromAnnotation } from "./annotation";
import { editAnnotation, editToothSettings, focusAnnotation, unfocusAnnotation, updateAnnotationPosition, deleteAnnotation } from "./DicomManager";

export default {
    name: "DicomAnnotation",
    props: {
        app: {
            type: Object,
            required: true
        },
        annotation: {
            type: Object,
            required: true
        },
        dicomBox: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isMouseOver: false
        };
    },
    watch: {
        "annotation.isHovered": {
            // immediate: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    if (this.app.is3D) {
                        highlightAnnotation(this.annotation);
                    }
                    if (!this.isMouseOver) {
                        // console.log(`${this.annotation.name} hovered`, this.$el);
                        this.$el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                    }
                } else if (this.app.is3D) {
                    unhighlightAnnotation(this.annotation);
                }
            }
        },
        "annotation.positionChanged": {
            // immediate: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    updateAnnotationPosition(this.app, this.annotation);
                }
            }
        },
    },
    methods: {
        mouseOver: function () {
            this.isMouseOver = true;
            if (!this.annotation.isTooth || this.app.isEditingAnnotation)
                return;
            this.annotation.isHovered = true;
            // this.$refs.annotationContainer.classList.add("mystyle")
            if (this.annotation.figures) {
                this.annotation.figures.forEach((figure) => {
                    figure.spline.getChildren()[1].fill(this.annotation.color);
                });
            }

            if (this.annotation.childAnnotations) {
                this.annotation.childAnnotations.filter(a => a.figures).forEach((a) => {
                    a.figures.forEach((figure) => {
                        figure.spline.getChildren()[1].fill(this.annotation.color);
                    });
                });
            }
        },
        mouseleave: function () {
            this.isMouseOver = false;
            if (!this.annotation.isTooth || this.app.isEditingAnnotation)
                return;
            this.annotation.isHovered = false;
            if (this.annotation.figures) {
                this.annotation.figures.forEach((figure) => {
                    figure.spline.getChildren()[1].fill('transparent');
                });
            }
            if (this.annotation.childAnnotations) {
                this.annotation.childAnnotations.filter(a => a.figures).forEach((a) => {
                    a.figures.forEach((figure) => {
                        figure.spline.getChildren()[1].fill('transparent');
                    });
                });
            }
        },
        download: function () {
            if (!this.annotation.isTooth)
                return;
            saveMeshFromAnnotation(this.annotation, this.app, this.dicomBox);
        },
        editSettings: function () {
            if (!this.annotation.isTooth)
                return;
            editToothSettings(this.app, this.annotation);
        },
        edit: function () {
            if (!this.annotation.isTooth && !this.annotation.isBone)
                return;
            this.annotation.isEdited = !this.annotation.isEdited;
            editAnnotation(this.app, this.annotation);
        },
        focus: function () {
            if (!this.annotation.isTooth)
                return;
            this.annotation.isFocused = !this.annotation.isFocused;
            if (this.annotation.isFocused) {
                focusAnnotation(this.app, this.annotation);
            } else {
                unfocusAnnotation(this.app, this.annotation);
            }
        },
        isVisibleChanged: function () {
            this.annotation.isVisible = !this.annotation.isVisible;
            this.annotation.model.isVisible = this.annotation.isVisible;

            let focusedAnnotation = this.app.annotations.find((a) => a.isFocused);
            if (focusedAnnotation && focusedAnnotation != this.annotation)
                return;

            itemVisibilityChanged(this.annotation.model, this.annotation.color);

            if (!this.annotation.isTooth)
                return;

            this.annotation.figures.forEach((figure) => {
                figure.konvaPoints.forEach((konvaPoint) => {
                    if (konvaPoint.anchor) {
                        if (this.annotation.isVisible)
                            konvaPoint.anchor.show();
                        else
                            konvaPoint.anchor.hide();
                    }
                });
                if (this.annotation.isVisible)
                    figure.spline.show();
                else
                    figure.spline.hide();
            });
            if (this.annotation.childAnnotations) {
                this.annotation.childAnnotations.filter(a => a.figures).forEach((a) => {
                    a.figures.forEach((figure) => {
                        if (this.annotation.isVisible)
                            figure.spline.show();
                        else
                            figure.spline.hide();
                    });
                });
            }
        },
        removeAnnotation: function () {
            if (!this.annotation.isTooth)
                return;
            swal({
                text: "Are you sure you want to delete this tooth?",
                buttons: {
                    yes: { text: "Yes", value: true },
                    no: { text: "No", value: false }
                }
            }).then(willDelete => {
                if (willDelete) {
                    console.log("deleteAnnotation");
                    this.annotation.hasFigures = false;
                    deleteAnnotation(this.app, this.annotation);
                    if (this.annotation.childAnnotations) {
                        this.annotation.childAnnotations.forEach((annotation) => {
                            let app = this.app.childApps.find(c => c.index == annotation.appIndex);
                            deleteAnnotation(app, annotation);
                        });
                    }
                }
            });
        }
    }
}

</script>

<style scoped>
.annotation-container {
    color: white;
    padding: 0.2rem;
    border: solid white 0.1rem;
    display: flex;
    gap: 3px;
    /* flex-direction: column; */
}

.annotation-header {
    display: flex;
    align-items: stretch;
    gap: 5px;
    width: 100%;
}

.annotation-tools {
    display: flex;
    align-items: stretch;
    gap: 14px;
    margin-left: 3px;
}

.annotation-selected {
    display: block;
}

.annotation-color {
    margin-top: 2px;
    margin-left: 8px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.annotation-download {
    margin-top: 3px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    /* display: inline-block; */
}

.an-name {
    margin-top: 1px;
    white-space: nowrap;
    /* text-wrap: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    flex-grow: 1;
    max-width: calc(100% - 68px);
}

.annotation-btn {
    margin-top: 1px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.annotation-visible {
    margin-top: 0px;
    height: 25px;
    width: 25px;
}

.annotation-focus {
    margin-top: 0px;
    height: 23px;
    width: 23px;
}

.annotation-settings {
    margin-top: 2px;
}
</style>