<template>
	<div id="toothNumberButtons" class="tooth-number-mode-item">
		<button id="toothNumberSave" class="tooth-number-button" 
			v-on:click="exitToothNumberMode(true)">
			SAVE
		</button>
		<button id="toothNumberCancel" class="tooth-number-button" 
			v-on:click="exitToothNumberMode(false)">
			CANCEL
		</button>
	</div>
</template>

<script>

import {
	leaveToothNumberMode
} from "./ToothNumberMode.js";

export default {
	name: "ToothNumberButtons",
	props: [],
	components: {},
	methods: {
		exitToothNumberMode: function (saveChanges) {
			leaveToothNumberMode(saveChanges);
		}
	}
};

</script>

<style scoped>
#toothNumberButtons {
	position: absolute;
	text-align: center;
	white-space: nowrap;
	left: calc(50% - 80px);
	bottom: 70px;
	z-index: 9;
	display: none;
	width: 200px;
	height: 35px;
}

.tooth-number-button {
	display: inline-block;
	margin-right: 10px;
	min-width: 90px;
	width: auto;
	height: 35px;
	border: 0;
	cursor: pointer;
	font-size: small;
	background-color: #4687fc;
	text-align: center;
	color: #ffffff;
}
</style>