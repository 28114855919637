function degreesToRadians(degrees) {
	var pi = Math.PI;
	return degrees * (pi / 180);
}

function getFullBox(onlyVisible) {
	var min = { x: 100000, y: 100000, z: 100000 };
	var max = { x: -10000, y: -100000, z: -100000 };
	var fullBox = { min, max };
	vueApp.allItems.forEach(function (model) {
		if (onlyVisible && !model.isVisible) {
			return;
		}
		var mesh = viewer.get_model_mesh(model.id);
		var geometry = mesh.geometry.clone();
		geometry.applyMatrix4(mesh.matrix);
		var toothBox = geometry.boundingBox;
		if (toothBox.min.x < fullBox.min.x) {
			fullBox.min.x = toothBox.min.x;
		}
		if (toothBox.min.y < fullBox.min.y) {
			fullBox.min.y = toothBox.min.y;
		}
		if (toothBox.min.z < fullBox.min.z) {
			fullBox.min.z = toothBox.min.z;
		}
		if (toothBox.max.x > fullBox.max.x) {
			fullBox.max.x = toothBox.max.x;
		}
		if (toothBox.max.y > fullBox.max.y) {
			fullBox.max.y = toothBox.max.y;
		}
		if (toothBox.max.z > fullBox.max.z) {
			fullBox.max.z = toothBox.max.z;
		}
	});
	fullBox = offsetBox(fullBox, 0, 0, 0, 0);
	return fullBox;
}

function getTeethBox(isMaxilla) {
	var min = { x: 100000, y: 100000, z: 100000 };
	var max = { x: -10000, y: -100000, z: -100000 };
	var teethBox = { min, max };
	vueApp.allItems.forEach(function (model) {
		if (model.isTooth && (isMaxilla == null || model.isMaxilla == isMaxilla)) {
			if (model.isVisible) {
				viewer.set_color(model.id, model.color);
			}
			var mesh = viewer.get_model_mesh(model.id);
			//console.log(mesh);
			var geometry = mesh.geometry.clone();
			geometry.applyMatrix4(mesh.matrix);
			var toothBox = geometry.boundingBox;
			//drawBox(toothBox);
			if (toothBox.min.x < teethBox.min.x) {
				teethBox.min.x = toothBox.min.x;
			}
			if (toothBox.min.y < teethBox.min.y) {
				teethBox.min.y = toothBox.min.y;
			}
			if (toothBox.min.z < teethBox.min.z) {
				teethBox.min.z = toothBox.min.z;
			}
			if (toothBox.max.x > teethBox.max.x) {
				teethBox.max.x = toothBox.max.x;
			}
			if (toothBox.max.y > teethBox.max.y) {
				teethBox.max.y = toothBox.max.y;
			}
			if (toothBox.max.z > teethBox.max.z) {
				teethBox.max.z = toothBox.max.z;
			}
		}
		else if (isMaxilla != null) {
			viewer.set_color(model.id, "transparent");
		}
	});
	teethBox = offsetBox(teethBox, 0, 0, 0, 0);
	//drawBox(teethBox);
	return teethBox;
}

function getTeethZoom(teethBox) {
	var padding = 0;
	var w = (teethBox.size.x + padding) / 2;
	var h = (teethBox.size.y + padding) / 2;
	var fovX = viewer.camera.fov * viewer.camera.aspect;
	var fovY = viewer.camera.fov;

	var distanceX = w / Math.tan((Math.PI * fovX) / 360) + w;
	var distanceY = h / Math.tan((Math.PI * fovY) / 360) + w;

	var defaultZoom = Math.max(distanceX, distanceY);
	return defaultZoom;
}

function switchToMandibular() {
	// vueApp.leaveModes();
	var teethBox = getTeethBox(false);
	var teethZoom = getTeethZoom(teethBox);
	//resetCameraUp();
	viewer.camera.position.x = teethBox.center.x;
	viewer.camera.position.y = teethBox.center.y + teethZoom;
	viewer.camera.position.z = teethBox.center.z;
	if (viewer.controls_type == 1) {
		viewer.camera.up.y = 0;
		viewer.camera.up.x = 0;
		viewer.camera.up.z = -1;
	}
	vueApp.lookAtPoint(teethBox.center);
	//vueApp.isOcclusal = true;
}

function switchToMaxilla() {
	//vueApp.leaveModes();
	var teethBox = getTeethBox(true);
	var teethZoom = getTeethZoom(teethBox);
	//resetCameraUp();
	viewer.camera.position.x = teethBox.center.x;
	viewer.camera.position.y = teethBox.center.y - teethZoom;
	viewer.camera.position.z = teethBox.center.z;
	if (viewer.controls_type == 1) {
		viewer.camera.up.y = 0;
		viewer.camera.up.x = 0;
		viewer.camera.up.z = 1;
	}
	vueApp.lookAtPoint(teethBox.center);
	//vueApp.isOcclusal = true;
}

export {
	degreesToRadians, getTeethBox, getFullBox, getTeethZoom, switchToMaxilla, switchToMandibular
};