<template>
	<div id="loaderDialog" class="modal" tabindex="-1" role="dialog"
		 aria-labelledby="loaderDialogTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div class="modal-content wizard-content">
				<div class="modal-header wizard-header">
					<h5 class="modal-title wizard-title"
						id="loaderDialogTitle">
						Patient: {{ patientFirstName }} {{ patientLastName }}
					</h5>
					<!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>-->
				</div>
				<div class="modal-body wizard-body">
					<form>
						<div class="form-group" style="text-align: start;">
							<label class="main-text">This Information is suggestive only. 
								Any diagnosis and prescription should be the decision and sole responsibility of the doctor using this material.</label>
							<label style="main-text">
								This website uses cookies. <a href="https://cephx.com/privacy-policy/" target="_blank">Learn more.</a>
							</label>
							<label class="main-text">
								Disclaimer: Cephx AI 3D service is not FDA cleared for marketing in the US.
							</label>
							<label class="main-text" >
								By clicking Accept you agree to CephX <a href="https://cephx.com/terms-of-service/" target="_blank">Terms of Services</a> and 
								<a href="https://cephx.com/privacy-policy/" target="_blank">Privacy Policy</a>.
							</label>
						</div>
					</form>
				</div>
				<div class="modal-footer wizard-footer">
					<button type="button"
							class="btn btn-primary btn-next"
							aria-label="OK"
							data-dismiss="modal"
							v-on:click="closeLoader()">
						ACCEPT
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import swal from "sweetalert";

export default {
	name: "Loader",
	props: ["patientFirstName", "patientLastName"],
	methods: {
		closeLoader: function() {
			vueApp.loaderClosed = true;
			vueApp.showMarketing();

			// if (
			// 	!document.cookie
			// 		.split("; ")
			// 		.find(row => row.startsWith("stlCephxIntroTooltip"))
			// ) {
			// 	var imgSrc = "images/intro.gif";
			// 	swal({
			// 		//title: "Intro",
			// 		icon: imgSrc
			// 	});
			// 	$(".swal-title").insertBefore($(".swal-icon"));

			// 	var $closeButton = $(
			// 		"<button type='button' class='close swal-close' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
			// 	);
			// 	$(".swal-modal").append($closeButton);
			// 	$(".swal-close").click(function() {
			// 		swal.close();
			// 	});
			// 	$(".swal-footer").css("margin-top", "0");
			// 	$(".swal-footer").css("text-align", "center");
			// 	document.cookie =
			// 		"stlCephxIntroTooltip=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
			// }
		}
	}
};
</script>

<style scoped>
@import "../css/wizard.css";

.modal-dialog {
	overflow-y: auto;
	overflow-x: auto;
}

.modal-sm {
	max-width: 450px;
}

.modal-header {
	min-height: 60px;
	text-align: center;
}

.modal-title {
	width: 400px;
	left: calc(50% - 200px);
	text-align: center;
}

.modal-body {
	padding-left: 50px;
	padding-right: 50px;
	text-align: center;
}

.main-text {
	text-align: justify;
}
</style>
