<template>
	<div id="orthoPlanSidePanel2" onmousedown="preventHide(event);" v-bind:style="{display: editContext.display}">
		<img class="side-ctrl-img2" v-on:click="moveTooth()"
			:src="editContext.isMoved ? 'images/move_mode_on.png' : 'images/move_mode_off.png'" />
		<img class="side-ctrl-img2" v-on:click="rotateTooth()"
			:src="editContext.isRotated ? 'images/rotate_mode_on.png' : 'images/rotate_mode_off.png'" />

		<div id="orthoSubPanel">
			<img id="vertMoveBtn" class="side-sm-img" v-on:click="changePlane(false)" v-show="editContext.isMoved"
				:src="editContext.isVertical ? 'images/leftright-off.png' : 'images/leftright-on.png'" />
			<img id="horMoveBtn" class="side-sm-img" v-on:click="changePlane(true)" v-show="editContext.isMoved"
				:src="editContext.isVertical ? 'images/updown-on.png' : 'images/updown-off.png'" />

			<img id="rootRotateBtn" class="side-sm-img" v-on:click="changeRotationBase(false)" v-show="editContext.isRotated"
				:src="editContext.isApex ? 'images/root_mode_off.png' : 'images/root_mode_on.png'" />
			<img id="apexRotateBtn" class="side-sm-img" v-on:click="changeRotationBase(true)" v-show="editContext.isRotated"
				:src="editContext.isApex ? 'images/edge_mode_on.png' : 'images/edge_mode_off.png'" />
		</div>

		<div id="movementVectorContainer" onmousedown="preventHide(event);">
			<span id="movementVectorTitle" class="opacity-span">Movement Vector</span>
			<div id="movementVector" class="opacity-slider"></div>
			<span class="opacity-span">0</span>
		</div>

		<div id="moveShowTapArrowsDiv" class="toggle-div" v-on:click.stop="switchTapArrows()">
			<div class="toggle-title">Show Tap Arrows</div>

			<input id="moveShowTapArrowsButton"
				type="checkbox"/>
		</div>

		<img id="resetToothBtn" class="side-ctrl-img2" v-on:click="resetTooth()"
			src="images/resetImplant.png" />
	</div>
</template>

<script>
import {
	enterMoveMode,
	exitMoveMode,
	redrawMoveArrow,
	changeMovePlane
} from "../newMove/newMoveMode";
import {
	enterRotateMode,
	exitRotateMode,
	changeRotatePlane,
	redrawRotateArrow
} from "../newRotate/newRotateMode";
import { resetToothPositionAndRotation } from "./OrthoPlanMode";

export default {
	name: "OrthoPlanSidePanel2",
	props: ["editContext"],
	components: {},
	methods: {
		changePlane: function(isVertical) {
			this.editContext.isVertical = isVertical;
			changeMovePlane(isVertical);
			// if (isVertical) {
			// 	$("#movementVector").slider("value", 0.5);
			// 	moveModeValueChanged(0.5);
			// } else {
			// 	$("#movementVector").slider("value", 0);
			// 	moveModeValueChanged(0);
			// }
			//redrawMoveArrow(isVertical);
		},
		changeRotationBase: function(isApex) {
			this.editContext.isApex = isApex;
			changeRotatePlane(isApex);
		},
		moveTooth: function() {
			exitRotateMode();
			enterMoveMode(this.editContext);
		},
		rotateTooth: function() {
			exitMoveMode();
			enterRotateMode(this.editContext);
		},
		resetTooth: function() {
			var tooth = vueApp.getItemById(this.editContext.id);
			resetToothPositionAndRotation(tooth);
			if (this.editContext.isRotated) {
				enterRotateMode(this.editContext);
			} else {
				enterMoveMode(this.editContext);
			}
		},
		switchTapArrows: function() {
			// console.log("switchTapArrows");
			this.editContext.showTapArrows = !this.editContext.showTapArrows;
			switchToggle("moveShowTapArrowsDiv", this.editContext.showTapArrows);

			if (this.editContext.isMoved) {
				redrawMoveArrow();
			} else {
				redrawRotateArrow();
			}
			// $(".move-ctrl-img").css(
			// 	"display",
			// 	this.editContext.showTapArrows ? "block" : "none"
			// );
		}
	}
};
</script>

<style scoped>
/* @import "../../css/editControls.css"; */
@import "../opacity/opacity.css";

#moveShowTapArrowsDiv {
	margin-left: 10px;
	margin-top: 10px;
}

.toggle-title {
	margin-left: -35px;
	margin-top: 5px;
	margin-bottom: 5px;
	width: 155px;
	font-size: small;
	font-weight: normal;
}

.opacity-slider {
	/*position: relative;*/
	height: calc(100% - 40px);
	border-radius: 0;
	outline: none;
	width: 0.5em;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: calc(50% - 0.25em);
}

#movementVectorTitle {
	margin-left: -50px;
	width: 135px;
}

#movementVectorContainer {
	/* background-color: chartreuse;  */
	width: 35px;
	margin-left: 35px;
	margin-top: 10px;
	height: calc(100% - 385px);
}

#orthoSubPanel {
	/* background-color: chartreuse; */
	width: 100px;
}

.side-sm-img {
	width: 50px;
	cursor: pointer;
	display: inline-block;
}

.side-ctrl-img2 {
	margin-left: 10px;
	width: 80px;
	cursor: pointer;
}

@media screen and (max-width: 650px) {
	.side-sm-img {
		width: 40px;
	}

	.side-ctrl-img2 {
		width: 50px;
	}

	#movementVectorContainer {
		/* background-color: chartreuse;  */
		width: 35px;
		margin-left: 35px;
		margin-top: 10px;
		height: calc(100% - 285px);
	}
}

#orthoPlanSidePanel2 {
	/* background-color: chartreuse; */
	display: none;
	left: 15px;
	top: 45px;
	width: 70px;
	height: calc(100% - 105px);
	position: absolute;
	text-align: center;
	z-index: 9;
	color: #ffffff;
}

#resetToothBtn {
	position: absolute;
	bottom: 5px;
	left: 0;
}

@media screen and (max-width: 650px) {
	#orthoPlanSidePanel2 {
		left: 5px;
		width: 50px;
	}
}
</style>