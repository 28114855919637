<template>
	<div id="implantsStep2" class="modal" tabindex="-1" role="dialog"
		 aria-labelledby="implantsStep2Title" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content wizard-content">
				<div class="modal-header wizard-header">
					<h5 class="modal-title wizard-title" id="implantsStep2Title">Implant Size</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body wizard-body">
					<div class="step-description">We utilize a generic implant, and let you choose its size. Later on you'll be able to adjust dimensions.</div>

					<div id="mode-block">
						<div class="btn-group" role="group">
							<button type="button"
									v-bind:class="{'mode-selected': isGeneric}"
									v-on:click="switchMode(true)"
									class="btn btn-light">
								GENERIC
							</button>
							<button type="button"
									v-bind:class="{'mode-selected': !isGeneric}"
									v-on:click="switchMode(false)"
									class="btn btn-light">
								CUSTOM
							</button>
						</div>
					</div>

					<div class="step-main">
						<img id="implant-img" src="images/implant_scr.png" />
						<div id="sliders-block">


							<div class="slider-block">
								<div class="slider-header">Length:</div>
								<div v-show="isGeneric" id="lengthSlider" class="slider">
									<div v-for="(length, index) in lengths"
										 v-bind:class="{'slider-item-selected': length.selected}"
										 v-on:click="selectLength(index)" :key="index"
										 class="slider-item"> {{ length.value }}mm</div>
								</div>

								<input v-show="!isGeneric" class="custom-item-input" type="number" min="5" max="25"
									   v-model.number="customLength" />
							</div>
							<div class="slider-block">
								<div class="slider-header">Diameter:</div>
								<div v-show="isGeneric" id="diameterSlider" class="slider">
									<div v-for="(diameter, index) in diameters"
										 v-bind:class="{'slider-item-selected': diameter.selected}"
										 v-on:click="selectDiameter(index)" :key="index"
										 class="slider-item"> {{ diameter.value }}mm</div>
								</div>

								<input v-show="!isGeneric" class="custom-item-input" type="number" min="1" max="8"
									   v-model.number="customDiameter" />
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer wizard-footer">
					<button v-if="!isResize" type="button"
							class="btn btn-primary btn-next"
							aria-label="Next"
							data-dismiss="modal"
							v-on:click="applyStep2($event)">
						NEXT
					</button>
					<button v-if="isResize" type="button"
							class="btn btn-primary btn-next"
							aria-label="Done"
							data-dismiss="modal"
							v-on:click="applyStep2($event)">
						DONE
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery';
	import "slick-carousel";
	import { initStep3, showStep3 } from "./ImplantStep3.vue";
	import swal from 'sweetalert';

	export default {
		name: "ImplantsStep2",
		props: ["lengths", "diameters", "isResize"],
		data() {
			return {
				isGeneric: true,
				customLength: 10, //11.5,
				customDiameter: 4
			};
		},
		methods: {
			switchMode: function(generic) {
				if (this.isGeneric != generic) {
					this.isGeneric = generic;
				}
			},
			selectLength: function(index) {
				for (var i = 0; i < this.lengths.length; i++) {
					this.lengths[i].selected = i === index;
				}
				if (index === -1) {
					$("#customLengthStep2").addClass("slider-item-selected");
				} else {
					$("#customLengthStep2").removeClass("slider-item-selected");
				}
			},
			selectDiameter: function(index) {
				for (var i = 0; i < this.diameters.length; i++) {
					this.diameters[i].selected = i === index;
				}

				if (index === -1) {
					$("#customDiameterStep2").addClass("slider-item-selected");
				} else {
					$("#customDiameterStep2").removeClass("slider-item-selected");
				}
			},
			applyStep2: function(event) {
				var length = 0;
				var diameter = 0;
				if (this.isGeneric) {
					for (var i = 0; i < this.lengths.length; i++) {
						if (this.lengths[i].selected) {
							length = this.lengths[i].value;
						}
					}
					for (var i = 0; i < this.diameters.length; i++) {
						if (this.diameters[i].selected) {
							diameter = this.diameters[i].value;
						}
					}
				} else {
					length = this.customLength;
					diameter = this.customDiameter;
				}
				if ((length < 5 || length > 25) || (diameter < 1 || diameter > 8)) {
					swal("Height min is 5mm, max is 25mm. Diameter min is 1mm, max is 8mm. ");
					if (event) {
						event.stopPropagation();
					}
					return;
				}
				vueApp.isCrownPlacement = false;
				this.$emit("set-implant-size", length, diameter);

				if (vueApp.newImplantCrownId) {
					//console.log(vueApp.newImplantCrownId);
					var crown = vueApp.getItemById(vueApp.newImplantCrownId);
					if (crown) {
						var position = crown.defaultPosition;
						this.$emit("add-implant", position.x, position.y, position.z, crown.isMaxilla);
					}
				} else if (!vueApp.isResize) {
					initStep3();
					showStep3();
				} else {
					vueApp.isResize = false;
				}
			}
		}
	};

	var step2Initialized = false;
	function initStep2(lengths, diameters) {
		if (!step2Initialized && lengths && diameters) {
			var lengthCenter = Math.floor(lengths.length / 2);
			var lengthStart = 0; //lengthCenter > 1 ? lengthCenter - 2 : 0;
			var lengthSlider = $("#lengthSlider");
			lengthSlider.slick({
				dots: false,
				infinite: false,
				initialSlide: lengthStart,
				slidesToShow: 4,
				slidesToScroll: 1,
				variableWidth: true
			});

			var diameterCenter = Math.floor(diameters.length / 2);
			var diameterStart = 0; //diameterCenter > 1 ? diameterCenter - 2 : 0;
			var diameterSlider = $("#diameterSlider");
			diameterSlider.slick({
				dots: false,
				infinite: false,
				initialSlide: diameterStart,
				slidesToShow: 4,
				slidesToScroll: 1,
				variableWidth: true
			});
			step2Initialized = true;
		}
	}

	export { initStep2 };
</script>

<style scoped>
	@import "../../css/wizard.css";

	.modal-dialog {
		max-width: 600px;
	}

	.modal-title {
		max-width: 200px;
		left: calc(50% - 80px);
	}

	.modal-body {
		padding-top: 0px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.step-main {
		margin-top: 5px;
	}

	.step-description {
		text-align: center;
		font-size: 14px;
	}

	#implant-img {
		width: 100px;
		display: inline-block;
		vertical-align: top;
		/*margin-top: 50px;*/
	}

	#sliders-block {
		display: inline-block;
		width: calc(100% - 150px);
		margin-left: 25px;
	}

	@media (max-width: 500px) {
		#implant-img {
			width: 0px;
		}

		#sliders-block {
			width: calc(100% - 30px);
			margin-left: 15px;
		}
	}

	.slider-block {
		margin-bottom: 10px;
	}

	.slider-header {
		color: #0069d9;
		font-weight: bold;
		margin-bottom: 5px;
	}

	.slider-item {
		min-width: 70px;
		min-height: 70px;
		background-color: #deeaf6;
		text-align: center;
		padding-top: 25px;
		font-size: 12px;
		border: 1px solid transparent;
	}

	.slider-item-selected {
		border: 1px solid #0069d9;
	}

	.custom-item {
		padding-top: 8px;
	}

	.custom-label {
		display: block;
		margin-bottom: 8px;
	}

	.custom-item-input {
		width: 100%;
	}

	@media (max-height: 400px) {
		#implant-img {
			width: 80px;
		}

		@media (max-width: 500px) {
			#implant-img {
				width: 0px;
			}
		}

		.slider-item {
			min-width: 50px;
			min-height: 50px;
			padding-top: 15px;
		}

		.step-description {
			display: none;
		}

		/*.step-main {
			margin-top: -15px;
		}*/
	}

	.btn {
		border-radius: 0px;
		border: 1px solid #000000;
		width: 120px;
		font-size: 14px;
	}

	#mode-block {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.mode-selected {
		background-color: #deeaf6;
	}
</style>
