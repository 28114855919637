import ListItem from "./ListItem.vue";
import { setHighlightedToothColor } from "../../App";
import {
  focusTooth,
  unfocusTooth,
} from "@/components/tooth/focus/ToothFocusMode";
import {
  ToothNumberMode,
  initToothNumberControls,
  drawTeethNumbers,
  removeTeethNumbers,
} from "../tooth/number/ToothNumberMode";
import { showToothNumberDialog } from "../tooth/number/ToothNumberDialog.vue";

function fillTeethList() {
  console.log("fillTeethList", vueApp.allItems);

  const teethList = document.getElementById("teethList");
  teethList.innerHTML = "";

  vueApp.allItems.forEach((item) => {
    item.displayName = item.isTooth
      ? item.filename.replace(".stl", "")
      : item.name.toLowerCase();

    item.isHovered = false;
    item.isDeleted = false;
    item.isFocused = false;
    item.isHidden = false;

    const itemInstance = new Vue({
      ...ListItem,
      propsData: {
        item: item,
      },
    });
    const vueContainer = document.createElement("div");
    teethList.appendChild(vueContainer);
    itemInstance.$mount(vueContainer);
  });
}

function highlightItem(item) {
  if (!vueApp.isFocusedTooth()) {
    vueApp.allTeeth.forEach(function (model) {
      unhighlightItem(model);
    });
    if (item && item.isVisible) {
      setHighlightedToothColor(item);
    }
  }
}

function unhighlightItem(item) {
  if (!vueApp.isFocusedTooth()) {
    if (item && item.isVisible && !item.isHidden) {
      let color = vueApp.getActualColor(item);
      viewer.set_color(item.id, color);
    }
  }
}

function focusItem(item) {
  vueApp.allTeeth.forEach(function (model) {
    if (model != item && model.isVisible) {
      model.isFocused = false;
      model.isHidden = true;
    }
  });

  if (item) {
    item.isVisible = item.isFocused = true;
    item.isHidden = false;

    focusTooth(item);
  }
}

function unfocusItem() {
  vueApp.allTeeth.forEach(function (model) {
    model.isFocused = false;
    model.isHidden = false;
  });

  unfocusTooth(false);
}

function deleteItem(item) {
  if (item && !item.isDeleted) {
    item.isDeleted = true;
    var mesh = viewer.get_model_mesh(item.id);
    if (mesh) {
      viewer.remove_model(item.id);
    }
    vueApp.removeItem(item);
  }
}

function editItemSettings(item) {
  if (item && !item.isDeleted) {
    initToothNumberControls();

    // ToothNumberMode.changingAnnotation = annotation;
    // ToothNumberMode.annotations = app.annotations;
    ToothNumberMode.setChangingTooth(item);

    showToothNumberDialog();
    // $("#toothNumberDialog").modal();
  }
}

export {
  fillTeethList,
  highlightItem,
  unhighlightItem,
  focusItem,
  unfocusItem,
  deleteItem,
  editItemSettings,
};
