import $ from "jquery";
import MeasureControls from "./MeasureControls.vue";
import { updateRaycaster } from "@/App";
import { calculateDistance3D } from "../geometry.js";
import { globalPlanesData, sagittalData, axialData, coronalData, updatePlane } from "../reports/ClipDialog.vue";

let firstPoint, secondPoint, measureLine;
let firstClick = true;
let controlsInitialized = false;
let clickTime = Date.now();
let isMobile = false;
let dragPoint = null;
let allMeshes = null;
let clickDif = 300;
let clipPlanes = [];
// let reportTextMesh = null;

var measureContext = { distance: 0 };

var MeasureMode = {
	isMeasureMode: true,
	selectedId: null,
	isActive: false,
	enterMode: function () {
		enterMeasureMode();
	},
	leaveMode: function (saveActiveMode) {
		leaveMeasureMode(saveActiveMode);
	},
	hideControls: function () {
		// viewer.scene.remove(firstPoint);
		// viewer.scene.remove(secondPoint);
		// viewer.scene.remove(measureLine);
	},
	showControls: function () {
		// if (firstClick) {
		// 	viewer.scene.add(firstPoint);
		// 	viewer.scene.add(secondPoint);
		// 	viewer.scene.add(measureLine);
		// }
	},
	showReportText: function () {
		// if (measureContext.distance > 0 && firstClick) {
		// 	let point = new THREE.Vector3(
		// 		Math.min(firstPoint.position.x, secondPoint.position.x) + 10,
		// 		Math.min(firstPoint.position.y, secondPoint.position.y) + 10,
		// 		Math.max(firstPoint.position.z, secondPoint.position.z) + 10
		// 	);
		// 	//let point = new THREE.Vector3(defaultBox.center.x, defaultBox.min.y, defaultBox.max.z);
		// 	reportTextMesh = drawText(`Distance: ${measureContext.distance} mm`, point);
		// 	reportTextMesh.lookAt(viewer.camera.position);
		// }		
	},
	hideReportText: function () {
		// if (reportTextMesh) {
		// 	viewer.scene.remove(reportTextMesh);
		// }
	},
	mouseDown: function (event) {
		if (firstPoint) {
			const now = Date.now();
			const millis = now - clickTime;
			if (millis > clickDif && firstClick) {
				updateRaycaster(event);
				dragPoint = intersectFirstPoint();
				if (!dragPoint)
					dragPoint = intersectSecondPoint();
				if (dragPoint) {
					if (event.preventDefault)
						event.preventDefault();
					viewer.controls.enabled = false;
				}
			}
		}
	},
	mouseMove: function (event) {
		if (dragPoint) {
			updateRaycaster(event);
			let point = intersectScene();
			if (point) {
				dragPoint.position.copy(point);
				viewer.scene.remove(measureLine);
				measureLine = drawMeasureLine(firstPoint.position, secondPoint.position);
				measureContext.distance = (calculateDistance3D(firstPoint.position, secondPoint.position)).toFixed(2);
			}
		}
		else if (!isMobile && !firstClick) {
			updateRaycaster(event);
			let point = intersectScene();
			if (point) {
				viewer.scene.remove(secondPoint);
				viewer.scene.remove(measureLine);
				secondPoint = drawPoint(point, 0x00FF00, false, 0.7);
				measureLine = drawMeasureLine(firstPoint.position, point);
				measureContext.distance = (calculateDistance3D(firstPoint.position, point)).toFixed(2);
			}
		}
		return false;
	},
	mouseUp: function (event) {
		//console.log("MeasureMode mouseUp");
		dragPoint = null;
		viewer.controls.enabled = true;
		return true;
	},
	mouseClick: function (event) {
		const now = Date.now();
		const millis = now - clickTime;
		if (millis > clickDif) {
			//console.log(millis);
			clickTime = now;
			updateRaycaster(event);
			let point = intersectScene();
			if (point) {
				//console.log(point);
				if (firstClick) {
					measureContext.distance = 0;
					viewer.scene.remove(firstPoint);
					viewer.scene.remove(secondPoint);
					viewer.scene.remove(measureLine);
					firstPoint = drawPoint(point, 0x0000FF, false, 0.7);
					firstClick = false;
				} else {
					viewer.scene.remove(secondPoint);
					viewer.scene.remove(measureLine);
					secondPoint = drawPoint(point, 0x00FF00, false, 0.7);
					measureLine = drawMeasureLine(firstPoint.position, secondPoint.position);
					firstClick = true;
					measureContext.distance = (calculateDistance3D(firstPoint.position, secondPoint.position)).toFixed(2);
				}
			}
		}
	}
};

function enterMeasureMode() {
	clipPlanes = [];
	if (globalPlanesData && vueApp.savedData.clipData && vueApp.savedData.clipData.isEnabled) {
		let sagittalRange = sagittalData.isFlipped ? -(+sagittalData.rangeValue + 0.5)
			: 1 - (+sagittalData.rangeValue + 0.5);
		let axialRange = axialData.isFlipped ? -(+axialData.rangeValue + 0.5)
			: 1 - (+axialData.rangeValue + 0.5);
		let coronalRange = coronalData.isFlipped ? -(+coronalData.rangeValue + 0.5)
			: 1 - (+coronalData.rangeValue + 0.5);

		for (let data of globalPlanesData) {
			updatePlane(data);
			let plane = data.plane;
			let coplanarPoint = plane.coplanarPoint(new THREE.Vector3());
			let focalPoint = new THREE.Vector3().copy(coplanarPoint).add(plane.normal);

			let width = defaultBox.size.x;
			let height = defaultBox.size.y;
			let dx = 0;
			let dy = 0;
			let dz = 0;
			let dif = 0.5;
			let normalValue = data.isFlipped ? -1 : 1;

			if (data == sagittalData) {
				width = defaultBox.size.z;
				dx = -dif * normalValue;
				dy = -axialRange * height;
				dz = -coronalRange * width;
			} else if (data == axialData) {
				height = defaultBox.size.z;
				dx = -sagittalRange * width;
				dy = -dif * normalValue;
				dz = -coronalRange * height;
			} else if (data == coronalData) {
				dx = -sagittalRange * width;
				dy = -axialRange * height;
				dz = -dif * normalValue;
			}

			let planeGeometry = new THREE.PlaneGeometry(width, height);
			planeGeometry.lookAt(focalPoint);
			planeGeometry.translate(coplanarPoint.x + dx, coplanarPoint.y + dy, coplanarPoint.z + dz);

			let planeMaterial = //new THREE.MeshLambertMaterial({ color: 0xffff00, transparent: true, depthWrite: false, side: THREE.DoubleSide, opacity: 0.3 });
				new THREE.MeshLambertMaterial({ color: 0xffffff, transparent: true, depthWrite: false, side: THREE.DoubleSide, opacity: 0.01 });
			let dispPlane = new THREE.Mesh(planeGeometry, planeMaterial);
			dispPlane.name = data.name;
			viewer.scene.add(dispPlane);
			clipPlanes.push(dispPlane);
		}
	}

	isMobile = mobileAndTabletCheck();
	clickDif = isMobile ? 400 : 200;
	allMeshes = vueApp.allMeshes;
	initializeControls();
	vueApp.leaveModes(false, true);

	$(".measure-mode-item").css("display", "block");

	vueApp.activeMode = MeasureMode;
	MeasureMode.isActive = true;

	if (implantPlane) {
		implantPlane.lookAt(viewer.camera.position);
		implantPlane.position.copy(new THREE.Vector3(0, 0, 0));
	}

	vueApp.allItems.forEach(function (item) {
		viewer.set_opacity(item.id, Math.min(item.opacity, 0.6)); // item.isVisible && 
	});

	firstClick = true;
}

function initializeControls() {
	if (!controlsInitialized) {
		var ctrls = [MeasureControls];
		ctrls.forEach(component => {
			const componentInstance = new Vue({ ...component, propsData: { editContext: measureContext } });
			const vueContainer = document.createElement('div');
			document.getElementById('stl_wrapper').appendChild(vueContainer);
			componentInstance.$mount(vueContainer);
		});

		controlsInitialized = true;
	}
}

function leaveMeasureMode(saveActiveMode) {
	for (let plane of clipPlanes) {
		viewer.scene.remove(plane);
	}
	viewer.scene.remove(firstPoint);
	viewer.scene.remove(secondPoint);
	viewer.scene.remove(measureLine);
	measureContext.distance = 0;
	firstPoint = secondPoint = null;

	vueApp.allItems.forEach(function (item) {
		viewer.set_opacity(item.id, item.opacity); // item.isVisible && 
	});

	$(".measure-mode-item").css("display", "none");
	//if (!saveActiveMode) {
	vueApp.activeMode = null;
	//}

	MeasureMode.isActive = false;
	vueApp.leaveModes(false, true);
}

function intersectScene() {
	let point = null;
	let intersects = raycaster.intersectObjects(allMeshes);
	if (intersects.length > 0) {
		let item = null;
		intersects.find(intersect => {
			if (intersect.object.visible && intersect.object.material.opacity > 0) {
				item = vueApp.getItemByUuid(intersect.object.uuid);
				if (item !== null) {
					let isVisible = isPointVisible(intersect.point);
					//console.log(isVisible);
					if (isVisible) {
						point = intersect.point;
					}
				}
			}

			return item != null;
		});
	}

	if (!point && clipPlanes.length > 0) {
		let distance = -1;
		intersects = raycaster.intersectObjects(clipPlanes);
		if (intersects.length > 0) {
			intersects.forEach(intersect => {
				//drawPoint(intersect.point, 0xFF0000, false, 1);
				if (distance < 0 || intersect.distance < distance) {
					distance = intersect.distance;
					point = intersect.point;
				}
			});
		}
	}

	if (!point) {
		intersects = raycaster.intersectObject(implantPlane);
		if (intersects.length > 0) {
			point = intersects[0].point;
		}
	}
	return point;
}

function isPointVisible(point) {
	if (clipPlanes.length == 0 || !vueApp.savedData.clipData || !vueApp.savedData.clipData.isEnabled) {
		return true;
	}
	let visible = true;
	if (point && defaultBox) {
		let sagittalRange = +sagittalData.rangeValue + 0.5;
		let sagittalAxis = defaultBox.min.x + defaultBox.size.x * sagittalRange;
		visible = sagittalData.isFlipped ? point.x >= sagittalAxis : point.x <= sagittalAxis;
		if (!visible) {
			return false;
		}

		let axialRange = +axialData.rangeValue + 0.5;
		let axialAxis = defaultBox.min.y + defaultBox.size.y * axialRange;
		visible = axialData.isFlipped ? point.y >= axialAxis : point.y <= axialAxis;
		if (!visible) {
			return false;
		}

		let coronalRange = +coronalData.rangeValue + 0.5;
		let coronalAxis = defaultBox.min.z + defaultBox.size.z * coronalRange;
		visible = coronalData.isFlipped ? point.z >= coronalAxis : point.z <= coronalAxis;
		if (!visible) {
			return false;
		}
	}
	return visible;
}

function intersectFirstPoint() {
	var point = null;
	if (firstPoint) {
		var intersects = raycaster.intersectObject(firstPoint);
		if (intersects.length > 0) {
			point = firstPoint; //intersects[0].point;
		}
	}
	return point;
}

function intersectSecondPoint() {
	var point = null;
	if (secondPoint) {
		var intersects = raycaster.intersectObject(secondPoint);
		if (intersects.length > 0) {
			point = secondPoint; //intersects[0].point;
		}
	}
	return point;
}

function drawMeasureLine(point1, point2, color) {
	if (!color) {
		color = 0xff9900; //0xbfbfbf;
	}
	var radius = 0.5;
	var vec = point2.clone(); vec.sub(point1);
	var h = vec.length();
	vec.normalize();
	var quaternion = new THREE.Quaternion();
	quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), vec);
	var geometry = new THREE.CylinderGeometry(radius, radius, h, 32);
	geometry.translate(0, h / 2, 0);
	var material = new THREE.MeshBasicMaterial({ color }); //, opacity: 0.2, transparent: true });
	var cylinder = new THREE.Mesh(geometry, material);
	cylinder.applyQuaternion(quaternion);
	cylinder.position.copy(point1);
	viewer.scene.add(cylinder);
	return cylinder;

}

export {
	MeasureMode, enterMeasureMode, leaveMeasureMode
};