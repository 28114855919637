<template>
	<li id="ortho_menu" class="nav-item dropdown active">
		<a class="nav-link"
			tabindex="0"
			data-toggle="dropdown"
			data-submenu
			aria-expanded="false">Ortho</a>

		<div class="dropdown-menu dropdown-menu-right menu3d">
			<button id="miEnterOrthoPlanMode"
				class="dropdown-item border-bottom-item"
				type="button" v-on:click="enterPlanMode()">
				New Stage
			</button>
			<div class="dropdown dropright dropdown-submenu border-bottom-item">
				<button class="dropdown-item" type="button" v-on:click="orthoMenuClick($event)">Load/Delete</button>
				<div class="dropdown-menu secondary-menu">
					<button class="dropdown-item border-bottom-item"
						type="button" v-on:click="loadStage(-1)">
						Original Position
						<img class="trash-img" src="images/trash_icon_red.png"
							v-on:click="deleteStage(index, $event)" />
					</button>
					<!-- <button class="dropdown-item border-bottom-item"
						type="button" v-on:click="loadStage(0)">
						Last Position
					</button> -->
					<button v-for="(stage, index) in orthoStages"
						:key="stage.id"
						class="dropdown-item border-bottom-item"
						type="button"
						v-on:click="loadStage(stage.id)">
						{{ stage.name }}
						<img class="trash-img" src="images/trash_icon_red.png"
							v-on:click="deleteStage(index, $event)" />
					</button>
				</div>
			</div>
			<button id="miPlayStages"
				class="dropdown-item border-bottom-item"
				type="button" v-on:click="playStages()">
				Play Stages
			</button>
			<button id="miStagesReport"
				class="dropdown-item border-bottom-item"
				type="button" v-on:click="stagesReport()">
				Stages Report
			</button>
			
		</div>
	</li>
</template>

<script>
import { jsPDF } from "jspdf";
import $ from "jquery";
import {
	OrthoPlanMode,
	loadOrthoStage,
	initializeControls
} from "./OrthoPlanMode";
import { showOrthoStageDialog } from "./OrthoStageDialog.vue";
import { radians_to_degrees } from "../editObject";
import swal from "sweetalert";
import { saveViewerData } from "../saveLoadData";
import { getFormattedDate } from "../fileMethods";

export default {
	name: "OrthoMenu",
	props: ["orthoStages"],
	components: {},
	data() {
		return {
			playInitialized: false
		};
	},
	methods: {
		orthoMenuClick: function(event) {
			gtag('event', `Ortho->${event.srcElement.innerText}`);
		},
		enterPlanMode: function() {
			gtag('event', 'Ortho->New Stage');
			initializeControls();
			showOrthoStageDialog(false);
			// enterOrthoPlanMode();

			// if (
			// 	!document.cookie
			// 		.split("; ")
			// 		.find(row => row.startsWith("stlCephxOrthoTooltip"))
			// ) {
			// 	var imgSrc = "images/ortho.gif";
			// 	swal({
			// 		title:
			// 			"Click any tooth to move and rotate it. Hide other teeth and change bone opacity for better planning. Save, so you can load it in the future",
			// 		icon: imgSrc
			// 	});
			// 	$(".swal-title").insertBefore($(".swal-icon"));
			// 	$(".swal-title").css("font-size", "large");

			// 	var $closeButton = $(
			// 		"<button type='button' class='close swal-close' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
			// 	);
			// 	$(".swal-modal").append($closeButton);
			// 	$(".swal-close").click(function() {
			// 		swal.close();
			// 	});
			// 	// $(".swal-title").css("background-color", "yellow");
			// 	document.cookie =
			// 		"stlCephxOrthoTooltip=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
			// }
		},
		loadStage: function(stageId) {
			gtag('event', `Ortho->Load->${stageId}`);
			loadOrthoStage(stageId, true);
			if (this.playInitialized) {
				var sliderValue = stageId == -1 ? -1 : stageId - 1;
				updateSlider("#playSlider", this.orthoStages.length - 1, sliderValue);
			}
		},
		deleteStage: function(index, event) {
			gtag('event', `Ortho->Delete->${index}`);
			if (event) {
				event.stopPropagation();
			}
			swal({
				text: "Are you sure you want to permanently delete this stage?",
				buttons: {
					yes: { text: "Yes", value: true },
					no: { text: "No", value: false }
				}
			}).then(willDelete => {
				if (willDelete) {
					var stage = vueApp.orthoStages[index];
					if (stage.id == vueApp.currentStageId && OrthoPlanMode.isActive) {
						loadOrthoStage(-1);
						//leaveOrthoPlanMode();
					}

					vueApp.orthoStages.splice(index, 1);
					vueApp.savedData.orthoStages = JSON.parse(
						JSON.stringify(vueApp.orthoStages)
					);
					saveViewerData();

					$("#orthoPlayStagesDiv").css("display", "none");					
				}
			});
		},
		playStages: function() {
			gtag('event', 'Ortho->Play Stages');
			vueApp.leaveModes();

			if (!this.playInitialized) {
				initMovementVector(
					this.playSliderChanged,
					"#playSlider",
					true,
					-1,
					this.orthoStages.length - 1,
					1,
					-1
				);
				this.playInitialized = true;
			} else {
				updateSlider("#playSlider", this.orthoStages.length - 1, -1);
			}
			vueApp.currentStageId = -1;
			$("#orthoPlayStagesDiv").css("display", "block");
		},
		playSliderChanged: function(value) {
			vueApp.currentStageId =
				value == -1 ? -1 : value + 1;
			loadOrthoStage(vueApp.currentStageId);
		},
		stagesReport: function() {
			gtag('event', 'Ortho->Stages Report');
			const doc = new jsPDF();
			var teethMap = new Map(vueApp.allTeeth.map(i => [i.name, i]));
			let reportMap = new Map();

			vueApp.orthoStages.forEach(function(stage) {
				var stageTeeth = JSON.parse(stage.teethString);
				var allTeeth = [];
				stageTeeth.forEach(function(model) {
					var savedPos = model.stagePosition;
					var savedRot = model.stageRotation;

					var tooth = teethMap.get(model.name);
					var reportItem = reportMap.get(model.name);

					var defPos = reportItem ? reportItem.lastPos : tooth.defaultPosition;
					var defRot = reportItem ? reportItem.lastRot : { x: 0, y: 0, z: 0 };

					var reportStage = getReportStage(
						tooth,
						stage,
						defPos,
						defRot,
						savedPos,
						savedRot
					);
					if (reportStage) {
						if (!reportItem) {
							reportItem = {
								id: tooth.id,
								name: tooth.name,
								tooth: tooth,
								stages: []
							};
							reportMap.set(model.name, reportItem);
						}

						reportItem.stages.push(reportStage);
						reportItem.lastPos = savedPos;
						reportItem.lastRot = savedRot;
					}

					allTeeth.push({
						id: model.id,
						name: model.name,
						stagePosition: model.stagePosition,
						stageRotation: model.stageRotation
					});
				});

				stage.teethString = JSON.stringify(allTeeth);
			});

			vueApp.savedData.orthoStages = JSON.parse(
				JSON.stringify(vueApp.orthoStages)
			);
			// saveViewerData();

			if (reportMap.size > 0) {
				var xOffset = 20;
				var x = xOffset;
				var yOffset = 20;
				var y = yOffset;
				var pageOffset = 50;
				var pageHeight = doc.internal.pageSize.height;
				var pageWidth = doc.internal.pageSize.width;
				doc.setFontSize(12);
				var lineSpace = 6;
				var stageSpace = 10;
				var toothSpace = 12;

				//var strings = [];
				for (var reportItem of reportMap.values()) {
					x = xOffset;
					doc.setFont("helvetica", "bold");
					doc.text(`Tooth ${reportItem.name}:`, x, y);
					doc.setFont("helvetica", "normal");

					var i = 0;
					var y0 = y;
					reportItem.stages.forEach(function(stage) {
						if (i % 2 == 0) {
							x = xOffset;
							y = y0 = y + stageSpace;
						} else {
							x = pageWidth / 2 + xOffset;
							y = y0;
						}

						if (y + pageOffset >= pageHeight) {
							doc.addPage();
							y = yOffset; // Restart height position
						}

						doc.text(`${stage.name}, from previous stage:`, x, y);
						doc.text(`x: ${stage.x} mm`, x, (y += lineSpace));
						doc.text(`y: ${stage.y} mm`, x, (y += lineSpace));
						doc.text(`z: ${stage.z} mm`, x, (y += lineSpace));
						doc.text(
							`rotation: ${stage.rotationX}, ${stage.rotationY}, ${stage.rotationZ} deg`,
							x,
							(y += lineSpace)
						);

						i += 1;
					});

					y += toothSpace;

					if (y + pageOffset > pageHeight) {
						doc.addPage();
						y = yOffset; // Restart height position
					}
				}
			}

			//doc.text("Tooth XX:", 10, 10);
			let fullName = `stagesReport_${getFormattedDate(true)}.pdf`;
			doc.save(fullName);
		}
	}
};

function getReportStage(tooth, stage, defPos, defRot, savedPos, savedRot) {
	var retval = null;

	if (!tooth || !stage || !defPos || !defRot || !savedPos || !savedRot) {
		return null;
	}

	if (
		Math.abs(savedPos.x - defPos.x) > 0.05 ||
		Math.abs(savedPos.y - defPos.y) > 0.05 ||
		Math.abs(savedPos.z - defPos.z) > 0.05 ||
		Math.abs(savedRot.x - defRot.x) > 0.01 ||
		Math.abs(savedRot.y - defRot.y) > 0.01 ||
		Math.abs(savedRot.z - defRot.z) > 0.01
	) {
		var dx = savedPos.x - defPos.x;
		var dy = savedPos.y - defPos.y;
		var dz = savedPos.z - defPos.z;

		var rdx = savedRot.x - defRot.x;
		var rdy = savedRot.y - defRot.y;
		var rdz = savedRot.z - defRot.z;

		retval = {
			name: stage.name,
			x: dx.toFixed(2),
			y: dy.toFixed(2),
			z: dz.toFixed(2),
			rotationX: radians_to_degrees(rdx).toFixed(2),
			rotationY: radians_to_degrees(rdy).toFixed(2),
			rotationZ: radians_to_degrees(rdz).toFixed(2)
		};
	}
	return retval;
}

</script>

<style>
.ortho-mode-item {
	position: absolute;
	text-align: center;
	z-index: 9;
	color: #ffffff; /*#797979;*/
	display: none;
}

.trash-img {
	width: 18px;
	margin-left: 8px;
	margin-bottom: 3px;
	cursor: pointer;
}
</style>