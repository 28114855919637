import ResizeCtrl from "./ResizeCtrl.vue";
import
{
	increaseHeight, decreaseHeight,
	//increaseFrontBack, decreaseFrontBack, increaseLeftRight, decreaseLeftRight,
	increaseBuccal, decreaseBuccal, increaseMesial, decreaseMesial
} from "../../editObject";


const iconSize = 50;
var resizeCtrlsInitialized = false;

var resizeContext = {
	id: null, size: null, currentMode: 0,
	upPosition: { objectId: null, x: 0, y: 0, action: increaseHeight, iconPath: 'images/Plus.png', display: 'none', width: 40 },
	downPosition: { objectId: null, x: 0, y: 0, action: decreaseHeight, iconPath: 'images/Minus.png', display: 'none', width: 40 },
	leftPosition: { objectId: null, x: 0, y: 0, action: decreaseBuccal, iconPath: 'images/Minus.png', display: 'none', width: 40 },
	rightPosition: { objectId: null, x: 0, y: 0, action: increaseBuccal, iconPath: 'images/Plus.png', display: 'none', width: 40 },

	buccalPosition: { objectId: null, x: 0, y: 0, action: enterBuccalMode, iconPath: 'images/BL-On.png', display: 'none', width: 70 },
	mesialPosition: { objectId: null, x: 0, y: 0, action: enterMessialMode, iconPath: 'images/MD-Off.png', display: 'none', width: 70 },
	heightPosition: { objectId: null, x: 0, y: 0, action: enterHeightMode, iconPath: 'images/Height-off.png', display: 'none', width: 70 }

};
var allPositions = [
	resizeContext.upPosition, resizeContext.downPosition,
	resizeContext.leftPosition, resizeContext.rightPosition,
	resizeContext.buccalPosition, resizeContext.mesialPosition, resizeContext.heightPosition
];

function enterResizeMode(editedObject) {
	if (editedObject) {
		editedObject.isResized = true;
		editedObject.isMoved = editedObject.isRotated = false;

		resizeContext.id = editedObject.id;
		resizeContext.size = editedObject.defaultBox.size;
		allPositions.forEach(position => { position.display = 'block'; position.objectId = editedObject.id; });
		updateResizeControlPositions();
		enterBuccalMode();
		initResizeCtrls();
	}
}

function exitResizeMode() {
	resizeContext.id = null;
	allPositions.forEach(position => position.display = 'none');
}

function initResizeCtrls() {
	if (!resizeCtrlsInitialized) {
		allPositions.forEach(position => {
			const componentInstance = new Vue({
				...ResizeCtrl,
				propsData: {
					resizeData: position
				}
			});
			const vueContainer = document.createElement('div');
			document.getElementById('main_div').appendChild(vueContainer);
			componentInstance.$mount(vueContainer);
		});

		viewer.controls.addEventListener('change', updateResizeControlPositions);
		resizeCtrlsInitialized = true;
	}
}

function updateResizeControlPositions() {
	if (resizeContext.id) {
		var canvasTop = $("#stl_cont").offset().top;
		var canvasHalfWidth = viewer.renderer.domElement.offsetWidth / 2;
		var canvasHalfHeight = viewer.renderer.domElement.offsetHeight / 2;

		var mesh = viewer.get_model_mesh(resizeContext.id);
		var objectCenter = mesh.position;

		var centerPosition = new THREE.Vector3(objectCenter.x, objectCenter.y, objectCenter.z);
		centerPosition = centerPosition.project(viewer.camera);
		centerPosition.x = centerPosition.x * canvasHalfWidth + canvasHalfWidth;
		centerPosition.y = -(centerPosition.y * canvasHalfHeight) + canvasHalfHeight;

		var upPosition = new THREE.Vector3(objectCenter.x,
			objectCenter.y + resizeContext.size.y / 2, objectCenter.z);
		upPosition = upPosition.project(viewer.camera);
		upPosition.x = upPosition.x * canvasHalfWidth
			+ canvasHalfWidth - iconSize / 2;
		upPosition.y = -(upPosition.y * canvasHalfHeight)
			+ canvasHalfHeight - iconSize - 5 + canvasTop;

		var downPosition = new THREE.Vector3(objectCenter.x,
			objectCenter.y - resizeContext.size.y / 2, objectCenter.z);
		downPosition = downPosition.project(viewer.camera);
		downPosition.x = downPosition.x * canvasHalfWidth
			+ canvasHalfWidth - iconSize / 2;
		downPosition.y = -(downPosition.y * canvasHalfHeight)
			+ canvasHalfHeight + 5 + canvasTop;

		var dy = downPosition.y - upPosition.y;
		if (dy < 200) {
			upPosition.y = centerPosition.y - 80;
			downPosition.y = centerPosition.y + 100;
		}

		resizeContext.upPosition.x = upPosition.x;
		resizeContext.upPosition.y = upPosition.y;

		resizeContext.downPosition.x = downPosition.x;
		resizeContext.downPosition.y = downPosition.y;

		resizeContext.leftPosition.x = centerPosition.x - iconSize - 60;
		resizeContext.leftPosition.y = centerPosition.y;

		resizeContext.rightPosition.x = centerPosition.x + 60;
		resizeContext.rightPosition.y = centerPosition.y;

		resizeContext.mesialPosition.x = downPosition.x - 10;
		resizeContext.mesialPosition.y = downPosition.y + 60;

		resizeContext.buccalPosition.x = resizeContext.mesialPosition.x - 70;
		resizeContext.buccalPosition.y = resizeContext.mesialPosition.y;

		resizeContext.heightPosition.x = resizeContext.mesialPosition.x + 70;
		resizeContext.heightPosition.y = resizeContext.mesialPosition.y;
	}
}

function enterBuccalMode() {
	resizeContext.currentMode = 1;

	resizeContext.leftPosition.display = resizeContext.rightPosition.display = 'block';
	resizeContext.upPosition.display = resizeContext.downPosition.display = 'none';

	resizeContext.buccalPosition.iconPath = 'images/BL-On.png';
	resizeContext.mesialPosition.iconPath = 'images/MD-Off.png';
	resizeContext.heightPosition.iconPath = 'images/Height-off.png';

	resizeContext.leftPosition.action = decreaseBuccal;
	resizeContext.rightPosition.action = increaseBuccal;
}

function enterMessialMode() {
	resizeContext.currentMode = 2;

	resizeContext.leftPosition.display = resizeContext.rightPosition.display = 'block';
	resizeContext.upPosition.display = resizeContext.downPosition.display = 'none';

	resizeContext.leftPosition.action = decreaseMesial;
	resizeContext.rightPosition.action = increaseMesial;

	resizeContext.buccalPosition.iconPath = 'images/BL-Off.png';
	resizeContext.mesialPosition.iconPath = 'images/MD-On.png';
	resizeContext.heightPosition.iconPath = 'images/Height-off.png';
}

function enterHeightMode() {
	resizeContext.currentMode = 3;

	resizeContext.leftPosition.display = resizeContext.rightPosition.display = 'none';
	resizeContext.upPosition.display = resizeContext.downPosition.display = 'block';

	resizeContext.buccalPosition.iconPath = 'images/BL-Off.png';
	resizeContext.mesialPosition.iconPath = 'images/MD-Off.png';
	resizeContext.heightPosition.iconPath = 'images/Height-ON.png';
}


export { enterResizeMode, exitResizeMode };