import $ from "jquery";
// import 'jquery-ui';
import OrthoPlanShowOriginal from "./OrthoPlanShowOriginal.vue";
import OrthoPlanExtract from "./OrthoPlanExtract.vue";
import OrthoPlanButtons from "./OrthoPlanButtons.vue";
import OrthoStageDialog from "./OrthoStageDialog.vue";
import OrthoPlanSidePanel2 from "./OrthoPlanSidePanel2.vue";
//import OrthoPlanSidePanel from "./OrthoPlanSidePanel.vue";
//import OrthoPlanToothMetrics from "./OrthoPlanToothMetrics.vue";
import { positiveZero, radians_to_degrees } from "../editObject";
import {
	enterMoveMode, exitMoveMode, moveModeMouseDown, moveModeMouseMove,
	moveModeMouseUp, moveModeValueChanged, moveByTag
} from "../newMove/newMoveMode";
import {
	enterRotateMode, exitRotateMode, rotateModeValueChanged, rotateByTag,
	rotateModeMouseDown, rotateModeMouseMove, rotateModeMouseUp
} from "../newRotate/newRotateMode";
import { saveViewerData } from "../saveLoadData";

var controlsInitialized = false;
var toothContext = {
	id: null, tooth: null, defaultPosition: null, defaultBox: null,
	display: 'none', isMoved: false, isRotated: false, isActive: false, isVertical: false, isApex: false,
	moveX: "0.0", moveY: "0.0", moveZ: "0.0", rotateX: "0.0", rotateY: "0.0", rotateZ: "0.0",
	showTapArrows: false, sliderValue: 0
};

var OrthoPlanMode = {
	selectedId: null,
	isActive: false,
	showOriginal: false,
	stageName: "STAGE 1",
	saveStage: false,
	toothSelected: function (tooth, event) {
		if (tooth && tooth.isTooth) {
			enterEditToothMode(tooth);
			if (toothContext.isMoved) {
				moveModeMouseDown(tooth, event);
			} else if (toothContext.isRotated) {
				rotateModeMouseDown(tooth, event);
			}
		}
	},
	tagDetected: function (tag) {
		if (toothContext.isMoved) {
			moveByTag(tag);
		} else if (toothContext.isRotated) {
			rotateByTag(tag);
		}
	},
	enterMode: function () {
		enterOrthoPlanMode();
	},
	leaveMode: function (saveActiveMode) {
		leaveOrthoPlanMode(saveActiveMode);
	},
	mouseMove: function (event) {
		if (toothContext.isMoved) {
			moveModeMouseMove(event);
		} else if (toothContext.isRotated) {
			rotateModeMouseMove(event);
		} 

		if (!toothContext.id)
			return true;
	},
	mouseUp: function (event) {
		if (toothContext.isMoved) {
			moveModeMouseUp(event);
		} else if (toothContext.isRotated) {
			rotateModeMouseUp(event);
		}
	}
};

export default {
	functional: true,
	props: [],
	components: { OrthoPlanShowOriginal, OrthoPlanButtons },
	render(createElement, context) {
		// const orthoPlanShowOriginal = createElement(OrthoPlanShowOriginal, { props: { mode: OrthoPlanMode }});
		const orthoPlanButtons = createElement(OrthoPlanButtons, { props: context.props });
		//const orthoStageDialog = createElement(OrthoStageDialog, { props: context.props });
		// const orthoPlanSidePanel = createElement(OrthoPlanSidePanel, { props: { editContext: toothContext } });
		// const orthoPlanToothMetrics = createElement(OrthoPlanToothMetrics, { props: { editContext: toothContext } });

		return [orthoPlanButtons];
	}
};

function enterOrthoPlanMode() {
	initializeControls();
	vueApp.leaveModes();

	$(".ortho-mode-item").css("display", "block");
	if (vueApp.isViewMode) {
		$(".ortho-save").css("display", "none");
		$("#orthoSaveButtons").css("left", "100px");
	}

	vueApp.allTeeth.forEach(function (item) {
		//if (item.isVisible) {
		//item.extracted = false;
		//viewer.set_color(item.id, hideModeTeethColor);
		//item.extracted = !item.isVisible;
		viewer.set_opacity(item.id, !item.extracted ? 1 : 0.3);
		//}

		// var mesh = viewer.get_model_mesh(item.id);
		// mesh.material.color.set(parseInt('ABA7A8', 16));
		// mesh.material.opacity = item.isVisible ? 1 : 0.3;
	});
	vueApp.activeMode = OrthoPlanMode;
	OrthoPlanMode.isActive = true;

	if (toothContext.id) {
		var tooth = vueApp.getItemById(toothContext.id);
		enterEditToothMode(tooth);
	} else {
		exitEditToothMode();
	}
}

function initializeControls() {
	initializeOpacity();

	if (!controlsInitialized) {
		var ctrls = [OrthoStageDialog, OrthoPlanShowOriginal, OrthoPlanSidePanel2, OrthoPlanExtract]; // OrthoPlanToothMetrics
		ctrls.forEach(component => {
			const componentInstance = new Vue({ ...component, propsData: { editContext: toothContext, mode: OrthoPlanMode } });
			const vueContainer = document.createElement('div');
			document.getElementById('main_div').appendChild(vueContainer);
			componentInstance.$mount(vueContainer);
		});

		initMovementVector(movementVectorValueChanged);

		canecode_switch("#orthoPlanShowOriginalButton");
		canecode_switch("#moveShowTapArrowsButton");
		canecode_switch("#orthoPlanExtractButton");

		switchToggle("moveShowTapArrowsDiv", false);
		switchToggle("orthoPlanShowOriginalDiv", false);
		switchToggle("orthoPlanExtractDiv", false);
		controlsInitialized = true;
	}
}

function leaveOrthoPlanMode(saveActiveMode) {
	toggleShowOriginal(false);
	vueApp.currentStageId = -1;
	$(".ortho-mode-item").css("display", "none");
	if (!saveActiveMode) {
		vueApp.activeMode = null;
		exitEditToothMode();
	} else {
		var tempId = toothContext.id;
		exitEditToothMode();
		toothContext.id = tempId;
	}
	OrthoPlanMode.isActive = false;
	vueApp.leaveModes();
}

function enterEditToothMode(tooth) {
	if (tooth && !OrthoPlanMode.selectedId) {
		$("#orthoSaveButtons").css("display", "none");
		$("#orthoToothDoneButton").css("display", "block");

		//var toothMesh = mesh.children && mesh.children.length > 0 ? mesh.children[0] : mesh;
		//toothMesh.material.color.set(parseInt('FFFFFF', 16));
		//viewer.set_color(tooth.id, '#FFFFFF');
		vueApp.allDents.forEach(function (item) {
			viewer.set_opacity(item.id, !item.extracted ? 0.8 : 0.3); // item.isVisible && 
		});

		toothContext.id = OrthoPlanMode.selectedId = tooth.id;
		toothContext.tooth = tooth;

		switchToggle('orthoPlanExtractDiv', tooth.extracted ? true : false);

		toothContext.isActive = true;
		toothContext.display = 'block';
		toothContext.defaultBox = tooth.defaultBox;
		toothContext.defaultPosition = tooth.defaultPosition;

		var mesh = viewer.get_model_mesh(tooth.id);
		updateToothMetrics(tooth, mesh);

		implantPlane.position.copy(mesh.position);

		//setTimeout(function () {
		if (toothContext.isRotated)
			enterRotateMode(toothContext);
		else
			enterMoveMode(toothContext);
		//}, 250);
	}
}

function updateToothMetrics(tooth, mesh) {
	if (tooth) {
		if (!mesh)
			mesh = viewer.get_model_mesh(tooth.id);

		var defPosition = tooth.defaultPosition;
		var mesPos = mesh.position;
		toothContext.moveX = positiveZero((mesPos.x - defPosition.x).toFixed(1));
		toothContext.moveY = positiveZero((mesPos.y - defPosition.y).toFixed(1));
		toothContext.moveZ = positiveZero((mesPos.z - defPosition.z).toFixed(1));

		var rotation = mesh.rotation;
		toothContext.rotateX = positiveZero(radians_to_degrees(rotation.x).toFixed(1));
		toothContext.rotateY = positiveZero(radians_to_degrees(rotation.y).toFixed(1));
		toothContext.rotateZ = positiveZero(radians_to_degrees(rotation.z).toFixed(1));
	}
}

function exitEditToothMode() {
	if (toothContext.id) {
		//toothMesh.material.color.set(parseInt('ABA7A8', 16));
		var color = vueApp.getActualColor(toothContext.tooth);
		viewer.set_color(toothContext.id, color);
	}
	vueApp.allDents.forEach(function (item) {
		viewer.set_opacity(item.id, !item.extracted ? 1 : 0.3);
	});

	$("#orthoToothDoneButton").css("display", "none");
	$("#orthoSaveButtons").css("display", "block");
	OrthoPlanMode.selectedId = null;

	toothContext.display = 'none';
	toothContext.id = 0;
	exitMoveMode();
	exitRotateMode();
}

function toggleExtract(tooth) {
	if (tooth) {
		tooth.extracted = !(tooth.extracted);
		viewer.set_opacity(tooth.id, !tooth.extracted ? 0.8 : 0.3); // item.isVisible && 
		switchToggle('orthoPlanExtractDiv', tooth.extracted);
	}
}

function toggleShowOriginal(newValue) {
	if (OrthoPlanMode.showOriginal != newValue) {
		OrthoPlanMode.showOriginal = newValue;
		switchToggle('orthoPlanShowOriginalDiv', OrthoPlanMode.showOriginal);
		if (OrthoPlanMode.showOriginal) {
			// vueApp.bones.forEach(function (item) {
			// 	viewer.set_color(item.id, "transparent");
			// });

			vueApp.allTeeth.forEach(function (item) {
				var mesh = viewer.scene.getObjectById(item.defaultMeshId, true);
				if (mesh) {
					mesh.material.color.set(0x0000FF); // parseInt('ABA7A8', 16)
					// mesh.material.opacity = 1;
					// mesh.material.transparent = false;
					mesh.material.opacity = 0.3;
					mesh.visible = mesh.isVisible = true;
					//mesh.material.depthTest = false;
					// mesh.material.transparent = true;
				}
			});
		} else {
			vueApp.allTeeth.forEach(function (item) {
				var mesh = viewer.scene.getObjectById(item.defaultMeshId, true);
				if (mesh) {
					mesh.material.color.set(0xFFFFFF);
					mesh.material.opacity = 0.0001;
					mesh.visible = mesh.isVisible = false;

					// mesh.material.transparent = true;
				}
			});

			// vueApp.bones.forEach(function (item) {
			// 	if (item.isVisible)
			// 		viewer.set_color(item.id, vueApp.bonesColor);
			// });
		}
	}
}

function saveOrthoChanges() {
	var stage = vueApp.orthoStages.find(s => s.name == OrthoPlanMode.stageName);
	if (!stage) {
		var allIds = vueApp.orthoStages.map(function (item) {
			return item.id ? item.id : 0;
		});
		var maxId = allIds.length > 0 ? Math.max.apply(null, allIds) : 0;

		stage = {
			id: maxId + 1,
			name: OrthoPlanMode.stageName
		};
		vueApp.orthoStages.push(stage);
	}
	vueApp.currentStageId = stage.id;

	var allTeeth = []; // vueApp.allTeeth;
	vueApp.allTeeth.forEach(function (model) {
		var mesh = viewer.get_model_mesh(model.id);
		model.stagePosition = { x: mesh.position.x, y: mesh.position.y, z: mesh.position.z };
		model.stageRotation = { x: mesh.rotation.x, y: mesh.rotation.y, z: mesh.rotation.z };
		allTeeth.push({
			id: model.id,
			name: model.name,
			stagePosition: model.stagePosition,
			stageRotation: model.stageRotation,
			extracted: model.extracted
		});
	});
	stage.teethString = JSON.stringify(allTeeth);

	// var lastStage = vueApp.orthoStages[vueApp.orthoStages.length - 1];
	// if (stage.id == lastStage.id) {
	// 	console.log(lastStage);
	vueApp.allTeeth.forEach(function (model) {
		var mesh = viewer.get_model_mesh(model.id);
		model.savedPosition = { x: mesh.position.x, y: mesh.position.y, z: mesh.position.z };
		model.savedRotation = { x: mesh.rotation.x, y: mesh.rotation.y, z: mesh.rotation.z };
		//model.savedExtracted = model.extracted;
		model.isVisible = !model.extracted;
	});
	updateSavedData();
	// }

	//vueApp.savedData.orthoStages = JSON.parse(JSON.stringify(vueApp.orthoStages));
	saveViewerData();

	leaveOrthoPlanMode();
}

function loadOrthoStage(stageId, fromMenu) {
	vueApp.currentStageId = stageId;
	var stage = vueApp.orthoStages.find(x => x.id === stageId);
	if (stage) {
		var stageTeeth = JSON.parse(stage.teethString);
		stageTeeth.forEach(function (model) {
			var existed = vueApp.getItemById(model.id);
			if (existed) {
				existed.extracted = model.extracted;
			}

			var mesh = viewer.get_model_mesh(model.id);
			var savedPos = model.stagePosition;
			var savedRot = model.stageRotation;
			mesh.position.set(savedPos.x, savedPos.y, savedPos.z);
			mesh.rotation.set(savedRot.x, savedRot.y, savedRot.z);
			viewer.set_opacity(model.id, !model.extracted ? 1 : 0);
		});
		if (fromMenu) {
			OrthoPlanMode.stageName = stage.name;
			enterOrthoPlanMode();
		}
	} else if (stageId == -1) { // Original Position
		vueApp.allTeeth.forEach(function (model) {
			model.extracted = false;
			var mesh = viewer.get_model_mesh(model.id);
			var savedPos = model.defaultPosition;
			var savedRot = { x: 0, y: 0, z: 0 };
			mesh.position.set(savedPos.x, savedPos.y, savedPos.z);
			mesh.rotation.set(savedRot.x, savedRot.y, savedRot.z);
			viewer.set_opacity(model.id, 1);
		});
		if (OrthoPlanMode.isActive) {
			leaveOrthoPlanMode();
		}
	} else {
		console.log(`stage with id ${stageId} not found`);
	}
}

function cancelOrthoChanges() {
	toggleShowOriginal(false);
	vueApp.allTeeth.forEach(function (model) {
		resetToothPositionAndRotation(model);
		// model.extracted = model.savedExtracted;
		model.extracted = !model.isVisible;
	});
	leaveOrthoPlanMode();
}

function resetToothPositionAndRotation(tooth) {
	if (tooth) {
		var mesh = viewer.get_model_mesh(tooth.id);
		var savedPos = tooth.savedPosition ? tooth.savedPosition : tooth.defaultPosition;
		var savedRot = tooth.savedRotation ? tooth.savedRotation : { x: 0, y: 0, z: 0 };
		mesh.position.set(savedPos.x, savedPos.y, savedPos.z);
		mesh.rotation.set(savedRot.x, savedRot.y, savedRot.z);
		updateToothMetrics(tooth, mesh);
		// toothContext.moveX = toothContext.moveY = toothContext.moveZ =
		// 	toothContext.rotateX = toothContext.rotate = toothContext.rotateZ = "0.0";
	}
}

function movementVectorValueChanged(value) {
	toothContext.sliderValue = value;
	if (toothContext.isMoved) {
		moveModeValueChanged(value);
	} else if (toothContext.isRotated) {
		rotateModeValueChanged(value);
	}
}

export {
	OrthoPlanMode, initializeControls, enterOrthoPlanMode, leaveOrthoPlanMode, exitEditToothMode, resetToothPositionAndRotation,
	toggleShowOriginal, saveOrthoChanges, loadOrthoStage, cancelOrthoChanges, toggleExtract
};