//#region Imports

import IntraoralViewer from "./IntraoralViewer.vue";
import IntraoralAddDialog from "./IntraoralAddDialog.vue";
import {
  createGraph,
  getShortPath,
  getPointIndex,
  getVertices,
  getFaces,
} from "./GraphHelper";
import { getBorderSegments } from "./BorderSegments";
import {
  getDistance,
  getFacesGeometry,
  fillArea,
  areNearPoints,
  clearTempMeshes,
  removeBorderPointsFaces,
  drawSpherePoint,
  drawBox,
  drawLine,
} from "./IntraoralDraw";
import {
  createTubeLine,
  updateTubeLine,
  createTubeCurve,
  updateTubeCurve,
  updateBezierPoints,
} from "./IntraoralTube";
import {
  getMergedGeometry,
  exportMeshToStlBlob,
  getGeometryFromStl,
  getGeometryFromDrc,
  getGeometryFromGlb,
} from "./IntraoralLoader";
import {
  sendSaveIntraoralDataRequest,
  sendApplyBorderPointsRequest,
} from "@/api/intraoralApi";
import { degreesToRadians } from "@/components/geometry.js";
import { C } from "@kitware/vtk.js/macros2";

//#endregion

//#region Fields

const randomColorValue = "random";
const monochromeColorValue = "monochrome";
const greyColorValue = "#D0CECF";
const gingivaColor = "#ffb6c1";

const ioColors = [
  // "e81416",
  "ffa500",
  "faeb36",
  "79c314",

  "487de7",
  // "4b369d",
  "90e0ef",

  "f72585",
  // "00008B",
  "e76f51",
  "2a9d8f",

  "00ff00",
  "ff9966",
  "ff00ff",
  // "0000ff",

  "BA68C8",
  "ff5050",
  "9933ff",
  "0096c7",
];
const sceneBackgroundColor = "#77a7fd";
const pongMaterial = new THREE.MeshPhongMaterial({
  // color: gingivaColor,
  flatShading: false,
  // flatShading: true,
  specular: 0x050505,
  shininess: 200,
  side: 2,
  polygonOffset: true,
  // transparent: true,
  // polygonOffsetUnit: 1,
  // wireframe: false,
});

const gingivaNames = ["gingiva", "upper_gingiva", "lower_gingiva"];
const addedSuffix = "added_";
const upperSuffix = "upper_";
const lowerSuffix = "lower_";
const globalDz = 0;
const cameraDistance = 30;
const camera_dz = 0;

let viewerInitialized = false;
let controlsInitialized = false;
let prevColor = null;
let allModels = [];

let isDoubleMode = false;
let isUpperMode = false;
let isEditMode = true;
let ioViewer;
let ioPlane = null;
let ioRaycaster = new THREE.Raycaster(
  new THREE.Vector3(),
  new THREE.Vector3(),
  0.1,
  100000
);
let defaultIoBox = null;
let defaultIoZoom = null;

let selectedTooth = null;
let dragPoint = null;
let intersectedTooth = null;
let mdTime = null;

let teeth = [];
let teethMeshes = [];

let upperMesh = null;
let upperBox = null;
let upperCameraCenter = null;
let upperGraph = null;
let lowerMesh = null;
let lowerBox = null;
let lowerCameraCenter = null;
let lowerGraph = null;
let upperCrossedPoints = [];
let lowerCrossedPoints = [];

let intraoralMenu = null;
let intraoralAddBtn = null;
let intraoralDeleteBtn = null;
let intraoralCenterBtn = null;
let intraoralFlipViewBtn = null;
let addToothGroup = null;
let addToothName = null;
let loadedCallback = null;

let ioChanges = [];
// let sampleChange = {
//   model: null,
//   oldGeometry: null,
//   oldPoints: null,
//   oldAnchorPoints: null,
//   newGeometry: null,
//   newPoints: null,
//   newAnchorPoints: null,
// };
let currentIoChange = null;

//#endregion

//#region Init

function processDemoZip(zip) {
  console.log("processDemoZip", new Date(), zip);
  let zipFile = zip.file("lower_segmented.glb");
  // let zipFile = zip.file("lower_transformed.stl");
  // let zipFile = zip.file("lower_gingiva.stl");
  zipFile.async("arraybuffer").then((buffer) => {
    getGeometryFromGlb(buffer, (elementGeometry) => {
      // getGeometryFromStl(buffer, (elementGeometry) => {
      // elementGeometry = elementGeometry.toNonIndexed();
      // console.log(elementGeometry);

      const tempGeo = new THREE.Geometry().fromBufferGeometry(elementGeometry);
      tempGeo.mergeVertices();
      tempGeo.computeVertexNormals();
      elementGeometry = new THREE.BufferGeometry().fromGeometry(tempGeo);
      elementGeometry =
        THREE.BufferGeometryUtils.mergeVertices(elementGeometry);
      elementGeometry.computeVertexNormals();
      elementGeometry.computeBoundingBox();
      let demoBox = elementGeometry.boundingBox;
      demoBox = offsetBox(demoBox);

      defaultIoBox = demoBox;

      let material = pongMaterial.clone();
      material.color = new THREE.Color(gingivaColor);
      material.polygonOffsetUnit = 1;
      material.flatShading = false;
      material.polygonOffsetFactor = 9;

      let tmesh = new THREE.Mesh(elementGeometry, material);
      const model = {
        id: 1,
        name: "lower_gingiva",
        mesh: tmesh,
        color: gingivaColor, // "#00FF00",
        opacity: 1,
        isGingiva: true,
      };
      allModels.push(model);

      upperMesh = lowerMesh = tmesh;

      ioViewer = new StlViewer(document.getElementById("intraoralContainer"), {
        models: allModels,
        load_three_files: "js/",
        // center_models: false,
        // controls: 1,
        // zoom: -1,
      });
      ioViewer.scene.background = new THREE.Color(sceneBackgroundColor);
      ioViewer.controls.zoomSpeed = 2.1;
      ioViewer.controls.noZoom = true;

      console.log(ioViewer);

      setTimeout(() => {
        // console.log(ioViewer.scene.children);
        // console.log(ioViewer.camera);
        console.log("demoBox", demoBox);

        let point = demoBox.center;
        ioViewer.controls.target = new THREE.Vector3(point.x, point.y, point.z);
        ioViewer.camera.lookAt(point.x, point.y, point.z);

        let graph = createGraph(tmesh);
        console.log("createdGraph", new Date(), graph);
        lowerGraph = upperGraph = graph;

        $id("loader").style.display = "none";
        let promises = [];
        zip.forEach(function (relativePath, file) {
          // if (relativePath.startsWith("tooth_")) {
          if (relativePath.startsWith("smoothed_")) {
            // if (relativePath.startsWith("filled_")) {
            let zipFile = zip.file(relativePath);
            let promise = zipFile.async("arraybuffer");
            promises.push(promise);
            promise.then((buffer) => {
              getGeometryFromStl(buffer, (elementGeometry) => {
                const tempGeo = new THREE.Geometry().fromBufferGeometry(
                  elementGeometry
                );
                tempGeo.mergeVertices();
                tempGeo.computeVertexNormals();
                elementGeometry = new THREE.BufferGeometry().fromGeometry(
                  tempGeo
                );
                elementGeometry.computeBoundingBox();

                let color = getRandomColor(ioColors);

                let material = pongMaterial.clone();
                material.color = new THREE.Color(color);
                material.polygonOffsetUnit = 1;

                let vmesh = new THREE.Mesh(elementGeometry, material);

                const model = {
                  name: relativePath
                    .replace(".stl", "")
                    .replace("smoothed_", "")
                    .replace("filled_", ""),
                  mesh: vmesh,
                  color: color,
                  opacity: 1,
                  isGingiva: false,
                  isTooth: true,
                };
                model.uuid = vmesh.uuid;
                teeth.push(model);
                teethMeshes.push(vmesh);
                allModels.push(model);
                ioViewer.add_model(model);
              });
            });
          }
        });

        Promise.all(promises).then((values) => {
          console.log("promises", new Date(), values);
          ioViewer.set_opacity(1, 1);

          // setTimeout(() => {
          let basePointsFile = zip.file("lower_border_points_smoothed.json");
          if (basePointsFile) {
            basePointsFile.async("string").then((text) => {
              try {
                let borderPoints = JSON.parse(text);
                applyBorderPoints(borderPoints, true);
                console.log("allModels", allModels);
                attachEvents();
              } catch (e) {
                console.log(`Invalid JSON: ${fileName}! Error: ${e}`);
              }
            });
          } else {
            console.log(`No such file in zip response - ${fileName}.`);
          }
          // }, 200);
        });
      }, 100);
    });
  });
}

function showIoViewer() {
  if (!viewerInitialized) {
    const viewerInstance = new Vue({
      ...IntraoralViewer,
      propsData: {
        // app: app,
      },
    });
    const vueContainer = document.createElement("div");
    document.getElementById("main_div").appendChild(vueContainer);
    viewerInstance.$mount(vueContainer);

    viewerInitialized = true;
  }

  initControls();

  if (vueApp.isViewMode) {
    isEditMode = false;
  }

  document.querySelector("#views_cont").style.display = "none";
  $(".menu3d").css({ display: "none" });
  // $(".menu3d").attr("disabled", true);

  document.querySelector("#intraoralViewer").style.display = "block";
  if (ioViewer) ioViewer.do_resize();
}

function processGlbBuffer(buffer, isUpper, callback) {
  if (!buffer) {
    callback([]);
    return;
  }
  let loader = new THREE.GLTFLoader();
  loader.parse(buffer, "", function (result) {
    let world = result.scene.children[0];
    let vmodels = [];
    world.children.forEach((element) => {
      if (element.type === "Mesh") {
        let elementGeometry = element.geometry;
        let elementName = element.name.replace("stl", "").replace(".", "");
        let isGingiva = gingivaNames.includes(elementName);
        let color = isGingiva ? gingivaColor : getRandomColor(ioColors);
        if (!isGingiva) {
          while (color == prevColor) {
            color = getRandomColor();
          }
          prevColor = color;
        }
        let material = pongMaterial.clone();
        material.color = new THREE.Color(color);
        material.polygonOffsetUnit = 1;
        material.polygonOffsetFactor = isGingiva ? 9 : 3;

        let tmesh = new THREE.Mesh(elementGeometry, material);
        // tmesh.castShadow = false;
        // tmesh.receiveShadow = false;
        const model = {
          name: elementName,
          mesh: tmesh,
          color: color, // "#00FF00",
          opacity: 0.01,
          isGingiva: isGingiva,
          isUpper: isUpper,
          isTooth: !isGingiva,
          isAdded: false,
        };
        vmodels.push(model);
      }
    });
    callback(vmodels);
  });
}

function processStlModels(stlModels, glbModels) {
  stlModels.forEach((model) => {
    let elementName = model.name;
    let existedGlbModel = glbModels.find((m) => m.name == elementName);
    let isAdded = !existedGlbModel; // elementName.includes(addedSuffix);
    if (isAdded) {
      console.log("added", elementName);
      let elementGeometry = model.geometry.clone();
      let color = getRandomColor(ioColors);
      let material = pongMaterial.clone();
      material.color = new THREE.Color(color);
      material.polygonOffsetUnit = 1;
      material.polygonOffsetFactor = 3;
      let tmesh = new THREE.Mesh(elementGeometry, material);
      model.mesh = tmesh;
      model.color = color;
      model.opacity = 0.01;
      model.isGingiva = false;
      model.isTooth = true;
      model.isAdded = true;
      model.isUpper = !elementName.includes(lowerSuffix);
    }
  });
}

function applySettings(models, settings) {
  allModels = models;
  if (settings && settings.teeth) {
    for (let name in settings.teeth) {
      let model = getModelByName(name);
      if (model) {
        let savedColor = settings.teeth[name].color;
        model.color = savedColor;
        model.isSaved = settings.teeth[name].isSaved;
      }
    }
  }
}

function initStlViewer(models, stlModels, callback) {
  loadedCallback = callback;
  // console.log("initStlViewer", models, stlModels);
  allModels = models;
  upperCrossedPoints = fillCrossedPoints(allModels.filter((m) => m.isUpper));
  lowerCrossedPoints = fillCrossedPoints(allModels.filter((m) => !m.isUpper));

  allModels.forEach((model) => {
    let mesh = model.mesh;
    mesh.geometry.computeBoundingBox();
    model.baseGeometry = mesh.geometry.clone();

    let stlModel = stlModels.find((m) => m.name == model.name);
    if (stlModel) {
      // if (model.isSaved) {
      model.savedGeometry = stlModel.geometry;
      // mesh.geometry.dispose();
      // mesh.geometry = stlModel.geometry;
      // }
      stlModel.geometry = null;
    } else if (model.isTooth) {
      model.isDeleted = true;
      mesh.visible = false;
    }
    mesh.geometry.computeBoundingBox();
    // model.savedGeometry = mesh.geometry.clone();
  });

  ioViewer = new StlViewer(document.getElementById("intraoralContainer"), {
    models: allModels,
    load_three_files: "js/",
    // center_models: false,
    controls: 1,
    // zoom: -1,
  });
  ioViewer.scene.background = new THREE.Color(sceneBackgroundColor);
  ioViewer.controls.zoomSpeed = 2.1;
  ioViewer.controls.noZoom = true;

  setTimeout(function () {
    stlAllLoaded();
  }, 100);
}

function fillCrossedPoints(models) {
  if (!models || !models.length) return;

  console.log("fillCrossedPoints start", new Date());

  let crossedPoints = [];
  let allVolumes = models
    .filter((m) => m.isTooth)
    .map((model) => {
      let mesh = model.mesh;
      if (!mesh.geometry.boundingBox) {
        mesh.geometry.computeBoundingBox();
      }
      let box = mesh.geometry.boundingBox;
      // box = offsetBox(box, 0, 0, 0);
      let vertices = getVertices(mesh);
      let volume = {
        box: box,
        model: model,
        vertices: vertices,
      };
      model.crossedPoints = [];
      model.crossedTeeth = [];
      return volume;
    });

  // allVolumes.forEach((volume) => {
  //   let distance = Infinity;
  //   for (let vl of allVolumes) {
  //     let dst = getDistance(volume.center, vl.center);
  //   }
  //   console.log(volume.model.name, volume);
  // });

  allVolumes.forEach((vl) => {
    allVolumes.forEach((nvl) => {
      if (nvl != vl && nvl.box.intersectsBox(vl.box)) {
        vl.vertices.forEach((v) => {
          nvl.vertices.forEach((nv) => {
            if (v.x == nv.x && v.y == nv.y && v.z == nv.z) {
              crossedPoints.push(v);
              vl.model.crossedPoints.push(v);
              // console.log(vl.model.name, nvl.model.name, v);
              // drawPoint(v, ioViewer.scene);
            }
          });
        });
        vl.model.crossedTeeth.push(nvl.model.name);
      }
    });
  });
  // crossedPoints = [...new Set(crossedPoints)];
  console.log("fillCrossedPoints end", crossedPoints.length, new Date());
  return crossedPoints;
}

function applyBorderPoints(borderPoints, isBasePoints) {
  for (let prop in borderPoints) {
    let name = prop.replace("stl", "").replace(".", "");
    let model = getModelByName(name);
    if (model) {
      if (isBasePoints) {
        model.basePoints = model.points = borderPoints[prop];
      } else {
        model.savedPoints = model.points = borderPoints[prop];
      }
      // createPointsGroup(model);
    }
  }
}

function applyAnchorPoints(anchorPoints, isBasePoints) {
  console.log("anchorPoints", isBasePoints, anchorPoints);
  for (let prop in anchorPoints) {
    let name = prop.replace("stl", "").replace(".", "");
    let model = getModelByName(name);
    if (model) {
      if (isBasePoints) {
        model.baseAnchorPoints = model.anchorPoints = anchorPoints[prop];
      } else {
        model.savedAnchorPoints = model.anchorPoints = anchorPoints[prop];
        if (!model.baseAnchorPoints) {
          model.baseAnchorPoints = model.savedAnchorPoints;
        }
      }
      // createPointsGroup(model);
    }
  }
}

function stlAllLoaded() {
  $id("loader").style.display = "none";
  console.log(`stlAllLoaded`, allModels.length);
  ioPlane = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(500, 500, 8, 8),
    new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      depthWrite: false,
      side: THREE.DoubleSide,
      opacity: 0.01,
    })
  );
  ioViewer.scene.add(ioPlane);
  ioViewer.controls.screenSpacePanning = true;
  ioViewer.controls.addEventListener("change", onCameraChange);
  ioViewer.renderer.outputEncoding = THREE.sRGBEncoding;
  // ioViewer.scene.add(new THREE.AxesHelper(80));

  let currentMin = { x: ioViewer.minx, y: ioViewer.miny, z: ioViewer.minz };
  let currentMax = { x: ioViewer.maxx, y: ioViewer.maxy, z: ioViewer.maxz };
  let currentBox = { min: currentMin, max: currentMax };
  defaultIoBox = convertToBox(currentBox);
  console.log("defaultIoBox", defaultIoBox);

  let upperGingiva = null;
  let lowerGingiva = null;
  let upperMeshes = [];
  let lowerMeshes = [];

  allModels.forEach((model) => {
    let vmesh = model.mesh;
    vmesh.name = model.name;
    vmesh.material.flatShading = false;
    vmesh.material.color = new THREE.Color(model.color);
    model.uuid = vmesh.uuid;

    if (!model.isGingiva) {
      teeth.push(model);
      teethMeshes.push(vmesh);
    } else {
      if (model.isUpper) upperGingiva = model;
      else lowerGingiva = model;
    }
    if (model.isUpper) upperMeshes.push(vmesh);
    else lowerMeshes.push(vmesh);

    let box = vmesh.geometry.boundingBox;
    box = offsetBox(box, 0, 0, 0);
    model.defaultBox = box;
    // vmesh.position.set(box.center.x, box.center.y, box.center.z);

    let geometry = prepareGeometry(vmesh.geometry, model.isGingiva);
    vmesh.geometry.dispose();
    vmesh.geometry = geometry;
  });

  if (upperMeshes.length && !vueApp.isViewMode) {
    upperMesh = createGlobalMesh(upperMeshes, upperGingiva);
    upperMesh.geometry.computeBoundingBox();
    upperBox = offsetBox(upperMesh.geometry.boundingBox);
    upperCameraCenter = new THREE.Vector3(
      upperBox.center.x,
      upperBox.center.y,
      upperBox.center.z
    );
    upperGraph = createGraph(upperMesh);
  }
  if (lowerMeshes.length && !vueApp.isViewMode) {
    lowerMesh = createGlobalMesh(lowerMeshes, lowerGingiva);
    lowerMesh.geometry.computeBoundingBox();
    lowerBox = offsetBox(lowerMesh.geometry.boundingBox);
    lowerCameraCenter = new THREE.Vector3(
      lowerBox.center.x,
      lowerBox.center.y,
      lowerBox.center.z
    );

    lowerGraph = createGraph(lowerMesh);
  }

  allModels.forEach((model) => {
    if (model.isGingiva) {
      return;
    }

    let vmesh = model.mesh;
    if (model.savedGeometry) {
      vmesh.geometry.dispose();
      vmesh.geometry = model.savedGeometry.clone();
      vmesh.geometry.computeBoundingBox();

      let box = vmesh.geometry.boundingBox;
      box = offsetBox(box, 0, 0, 0);
      model.defaultBox = box;
      model.defaultPosition = {
        x: box.center.x,
        y: box.center.y,
        z: box.center.z,
      };
      // vmesh.position.set(box.center.x, box.center.y, box.center.z);

      let geometry = prepareGeometry(vmesh.geometry, model.isGingiva);
      vmesh.geometry.dispose();
      vmesh.geometry = geometry;
    }
    model.savedGeometry = vmesh.geometry; //.clone();
  });

  let padding = -10;
  let w = (defaultIoBox.size.x + padding) / 2;
  let h = (defaultIoBox.size.y + padding) / 2;
  let fovX = ioViewer.camera.fov * ioViewer.camera.aspect;
  let fovY = ioViewer.camera.fov;
  let distanceX = w / Math.tan((Math.PI * fovX) / 360) + w;
  let distanceY = h / Math.tan((Math.PI * fovY) / 360) + w;
  defaultIoZoom = Math.max(distanceX, distanceY);

  allModels.forEach((model) => {
    // ioViewer.set_opacity(model.id, 0.7);
    ioViewer.set_opacity(model.id, 1);
    // ioViewer.set_opacity(model.id, model.isTooth ? 0.2 : 1);
    model.mesh.visible = !model.isDeleted;
    // createPointsGroup(model);
  });

  if (upperMeshes.length > 0) {
    switchViewModes(true, false);
  } else {
    switchViewModes(false, true);
  }

  // upperCrossedPoints.forEach(p => {
  //   drawPoint(p, ioViewer.scene);
  // });

  attachEvents();

  if (loadedCallback) loadedCallback();
}

function prepareGeometry(baseGeometry, isGingiva) {
  // baseGeometry.center();
  // baseGeometry.computeVertexNormals();

  let geometry = new THREE.Geometry().fromBufferGeometry(baseGeometry);
  if (!isGingiva) {
    geometry.mergeVertices();
  }
  geometry.computeVertexNormals();

  geometry = new THREE.BufferGeometry().fromGeometry(geometry);
  geometry.computeBoundingBox();
  return geometry;
}

function createGlobalMesh(meshes, model) {
  let geometry = getMergedGeometry(meshes);
  let material = pongMaterial.clone();
  material.color = new THREE.Color(gingivaColor);
  material.polygonOffsetUnit = 1;
  material.polygonOffsetFactor = 9;
  let mesh = new THREE.Mesh(geometry, material);
  mesh.castShadow = false;
  mesh.receiveShadow = false;
  ioViewer.scene.add(mesh);
  ioViewer.scene.remove(model.mesh);
  model.mesh = mesh;
  mesh.name = model.name;
  return mesh;
}

function initControls() {
  if (!controlsInitialized) {
    var ctrls = [IntraoralAddDialog];
    ctrls.forEach((component) => {
      const componentInstance = new Vue({
        ...component,
        // propsData: { mode: ToothAddContext, app: this.app },
      });
      const vueContainer = document.createElement("div");
      document.getElementById("intraoralViewer").appendChild(vueContainer);
      componentInstance.$mount(vueContainer);
    });
    controlsInitialized = true;
  }
}

//#endregion

//#region Events

function attachEvents() {
  intraoralMenu = document.getElementById("intraoral-tooth-menu");
  document.getElementById("intraoralViewer").appendChild(intraoralMenu);

  intraoralAddBtn = document.getElementById("intraoral-add-btn");
  intraoralAddBtn.addEventListener("click", () => {
    addClick();
  });

  intraoralDeleteBtn = document.getElementById("intraoral-delete-btn");
  intraoralDeleteBtn.addEventListener("click", () => {
    deleteClick();
  });

  intraoralCenterBtn = document.getElementById("intraoral-center-btn");
  intraoralCenterBtn.addEventListener("click", () => {
    centerViewClick();
  });

  intraoralFlipViewBtn = document.getElementById("intraoral-flip-btn");
  intraoralFlipViewBtn.addEventListener("click", () => {
    flipViewClick();
  });

  let intraoralContainer = document.getElementById("intraoralContainer");
  let is_touch_enabled =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
  if (is_touch_enabled) {
    intraoralContainer.addEventListener("touchstart", function (e) {
      var event = e.targetTouches[0];
      onDocumentMouseDown(event);
    });
    intraoralContainer.addEventListener("touchmove", function (e) {
      var event = e.targetTouches[0];
      onDocumentMouseMove(event);
    });
    intraoralContainer.addEventListener("touchend", function (e) {
      var event = e.changedTouches[0];
      onDocumentMouseUp(event);
    });
  } else {
    intraoralContainer.addEventListener(
      "mousedown",
      onDocumentMouseDown,
      false
    );
    intraoralContainer.addEventListener(
      "mousemove",
      onDocumentMouseMove,
      false
    );
    intraoralContainer.addEventListener("mouseup", onDocumentMouseUp, false);
    intraoralContainer.addEventListener("dblclick", onDocumentDblClick, false);

    // if (ioViewer.controls.setPreWheelCallback)
    //   ioViewer.controls.setPreWheelCallback(onDocumentWheel);

    let canvas = [...intraoralContainer.children].find(
      (c) => c.tagName.toLowerCase() == "canvas"
    );
    if (canvas) canvas.addEventListener("wheel", onDocumentWheel, false);
  }
}

function onDocumentWheel(event) {
  var factor = 5;

  let intraoralContainer = document.getElementById("intraoralContainer");
  let overlayRect = intraoralContainer.getBoundingClientRect();

  var mX = (event.clientX / overlayRect.width) * 2 - 1;
  var mY = -(event.clientY / overlayRect.height) * 2 + 1;
  var vector = new THREE.Vector3(mX, mY, 0.1);

  let trackBallControls = ioViewer.controls;
  let camera = ioViewer.camera;

  vector.unproject(camera);
  vector.sub(camera.position);
  if (event.deltaY < 0) {
    camera.position.addVectors(camera.position, vector.setLength(factor));
    trackBallControls.target.addVectors(
      trackBallControls.target,
      vector.setLength(factor)
    );
  } else {
    camera.position.subVectors(camera.position, vector.setLength(factor));
    trackBallControls.target.subVectors(
      trackBallControls.target,
      vector.setLength(factor)
    );
  }
}

function onDocumentDblClick(event) {
  if (!isEditMode) return;

  updateRaycasterFromEvent(event);
  let tooth = intersectTooth();

  intraoralDeleteBtn.style.display =
    intraoralCenterBtn.style.display =
    intraoralFlipViewBtn.style.display =
      tooth ? "initial" : "none";

  intraoralMenu.style.display = "initial";
  intraoralMenu.style.left = event.offsetX + 1 + "px";
  intraoralMenu.style.top = event.offsetY + 1 + "px";

  // updateRaycaster(event);
  // let point = intersectScene();
  // if (point) {
  //   let canvasHalfWidth = ioViewer.renderer.domElement.offsetWidth / 2;
  //   let canvasHalfHeight = ioViewer.renderer.domElement.offsetHeight / 2;

  //   let canvasPoint = point.project(ioViewer.camera);
  //   canvasPoint.x = canvasPoint.x * canvasHalfWidth + canvasHalfWidth;
  //   canvasPoint.y = -(canvasPoint.y * canvasHalfHeight) + canvasHalfHeight;
  // }
}

function addClick() {
  intraoralMenu.style.display = "none";
  $("#intraoralAddDialog").modal();
}

function cancelAdd() {
  $(".intraoral-add-item").css("display", "none");
  if (addToothGroup) {
    ioViewer.scene.remove(addToothGroup);
    addToothGroup = null;
  }
}

function deleteClick() {
  intraoralMenu.style.display = "none";
  if (selectedTooth) {
    document.getElementById("selectedToothHint").innerHTML = "";
    selectedTooth.isDeleted = true;
    selectedTooth.recentlyDeleted = true;
    selectedTooth.mesh.visible = false;
    ioViewer.scene.remove(selectedTooth.pointsGroup);
    selectedTooth.pointsGroup = null;
    // ioViewer.scene.remove(selectedTooth.mesh);
    // let index = teeth.indexOf(selectedTooth);
    // teeth.splice(index, 1);
    // teethMeshes.splice(index, 1);
    selectedTooth = null;
  }
}

function centerViewClick() {
  intraoralMenu.style.display = "none";
  if (selectedTooth) {
    selectedTooth.isCenterView = true;
    selectedTooth.isBacksideView = false;

    let tooth = selectedTooth;
    setToothCameraPoints(tooth);

    if (tooth.centerCameraPoint) {
      let box = tooth.defaultBox;

      rotateIntraoralToView(3);

      ioViewer.camera.position.copy(tooth.centerCameraPoint);

      ioViewer.controls.target.copy(
        new THREE.Vector3(box.center.x, box.center.y, box.center.z)
      );
    }
  }
}

function flipViewClick() {
  intraoralMenu.style.display = "none";
  if (selectedTooth) {
    if (selectedTooth.isBacksideView) {
      centerViewClick();
      return;
    }

    selectedTooth.isBacksideView = true;
    selectedTooth.isCenterView = false;
    let tooth = selectedTooth;
    setToothCameraPoints(tooth);

    if (tooth.backsideCameraPoint) {
      rotateIntraoralToView(3);

      ioViewer.camera.position.copy(tooth.backsideCameraPoint);

      let box = tooth.defaultBox;
      ioViewer.controls.target.copy(
        new THREE.Vector3(box.center.x, box.center.y, box.center.z)
      );
    }
  }
}

function setToothCameraPoints(tooth) {
  if (tooth && !tooth.centerCameraPoint) {
    let mesh = ioViewer.get_model_mesh(tooth.id);
    if (mesh) {
      // clearTempMeshes(ioViewer.scene);
      if (!tooth.defaultBox) {
        mesh.geometry.computeBoundingBox();
        tooth.defaultBox = offsetBox(mesh.geometry.boundingBox);
      }

      let tootBoxCenter = tooth.defaultBox.center;
      let globalCenter = tooth.isUpper ? upperCameraCenter : lowerCameraCenter;

      let centersDistance = calculateDistance(tootBoxCenter, globalCenter);
      if (!tooth.centerCameraPoint) {
        let coef = (centersDistance + cameraDistance) / centersDistance;
        let px = (tootBoxCenter.x - globalCenter.x) * coef + globalCenter.x;
        let py = (tootBoxCenter.y - globalCenter.y) * coef + globalCenter.y;
        let pz = tootBoxCenter.z; // (tootBoxCenter.z - center.z) * coef + globalCenter.z;
        tooth.centerCameraPoint = new THREE.Vector3(px, py, pz);
        // drawSpherePoint(ioViewer.scene, tooth.centerCameraPoint, 0xff0000, 1);
        // drawLine(tootBoxCenter, tooth.centerCameraPoint,  null, ioViewer.scene);
      }

      if (!tooth.backsideCameraPoint) {
        let coef = (centersDistance - cameraDistance) / centersDistance;
        let px = (tootBoxCenter.x - globalCenter.x) * coef + globalCenter.x;
        let py = (tootBoxCenter.y - globalCenter.y) * coef + globalCenter.y;
        let pz = tootBoxCenter.z + tooth.defaultBox.size.z;
        tooth.backsideCameraPoint = new THREE.Vector3(px, py, pz);
      }
    }
  }
}

function onDocumentMouseDown(event) {
  // console.log("onDocumentMouseDown", event);
  intraoralMenu.style.display = "none";

  mdTime = new Date();
  if (!isEditMode && !addToothGroup) return;

  updateRaycasterFromEvent(event);

  if (selectedTooth) {
    let point = intersectPoint(selectedTooth.pointsGroup);
    if (point) {
      startDragPoint(point, event);
      return;
    }
  }
  if (event.button === 0) {
    intersectedTooth = intersectTooth();
  }
}

function onDocumentMouseMove(event) {
  if (!isEditMode) return;
  // console.log("onDocumentMouseMove", event);
  if (dragPoint) {
    moveDragPoint(event);
  }
}

function onDocumentMouseUp(event) {
  let now = new Date();
  let ts = now - mdTime;

  if (!isEditMode && !addToothGroup) return;

  updateRaycasterFromEvent(event);
  if (addToothGroup && event.button === 0 && ts < 200) {
    addingToothMouseUp();
  } else if (dragPoint) {
    endDragPoint();
  } else {
    if (event.button === 2 && selectedTooth && ts < 400) {
      changingToothMouseUp(event);
    } else if (event.button === 0 && ts < 400) {
      updateSelectedTooth(event);
    }
  }
  intersectedTooth = null;
  ioViewer.controls.enabled = true;
}

function onCameraChange() {
  // controls
  if (ioPlane) {
    ioPlane.lookAt(ioViewer.camera.position);
  }
}

//#endregion

//#region Adding tooth

function startAddingTooth(toothName) {
  addToothName = toothName;
  document.getElementById("selectedToothHint").innerHTML = "";
  $(".intraoral-add-item").css("display", "block");
  isEditMode = false;

  addToothGroup = new THREE.Group();
  ioViewer.scene.add(addToothGroup);

  if (selectedTooth) {
    if (selectedTooth.pointsGroup) selectedTooth.pointsGroup.visible = false;
    selectedTooth = null;
  }
}

function addingToothMouseUp() {
  if (addToothGroup) {
    let iogPoints = addToothGroup.children.filter(
      (item) => item.name === "point"
    );
    let length = iogPoints.length;
    let prevPoint = iogPoints[length - 1];

    let firstPointIntersected = length > 2 && intersectMesh(iogPoints[0]);
    if (firstPointIntersected) {
      addToothGroup.add(createLine(prevPoint, iogPoints[0]));
      endAddingTooth(iogPoints);
    } else {
      addingToothAddPoint(prevPoint);
    }
  }
}

function addingToothAddPoint(prevPoint) {
  let groupPoint = intersectPoint(addToothGroup);
  if (!groupPoint) {
    let scenePoint = intersectScene();
    if (scenePoint) {
      let globalGraph = getGlobalGraph();
      let index1 = getPointIndex(globalGraph, scenePoint, 0.01);
      let node = globalGraph.getNode(index1);
      let ioPoint = drawIoPoint(node.point, 0x00ff00, 0.2);
      ioPoint.gindex = index1;
      if (prevPoint) {
        addToothGroup.add(createLine(prevPoint, ioPoint));
      }
      addToothGroup.add(ioPoint);
    }
  }
}

function endAddingTooth(iogPoints) {
  let allPoints = [];
  let iogLines = addToothGroup.children.filter((item) => item.name === "line");
  iogLines.forEach((line) => {
    let linePoints = line.points.slice(1);
    linePoints.forEach((point) => {
      allPoints.push(point);
    });
  });
  let globalMesh = getGlobalMesh();
  let globalGraph = getGlobalGraph();
  // iogPoints.forEach((p) => (p.visible = false));

  document.body.style.cursor = "wait";
  setTimeout(function () {
    let color = getRandomColor(ioColors);
    const model = {
      name: addToothName,
      color: color, // "#00FF00",
      isGingiva: false,
      isUpper: globalMesh == upperMesh,
      isAdded: true,
      isChanged: true,
      recentlyAdded: true,
    };
    if (!model.name) model.name = getAddedToothName();
    model.mainPoints = iogPoints;
    model.pointsGroup = addToothGroup;

    updateBezierPoints(model.mainPoints, globalGraph);

    let geometry = getFacesGeometry(
      model,
      allPoints,
      globalMesh,
      globalGraph,
      ioViewer.scene,
      [] //getCrossedPoints()
    );
    if (geometry) {
      let material = pongMaterial.clone();
      material.color = new THREE.Color(color);
      let mesh = new THREE.Mesh(geometry, material);
      model.mesh = mesh;
      model.uuid = mesh.uuid;

      ioViewer.scene.add(mesh);
      teethMeshes.push(mesh);
      teeth.push(model);
      allModels.push(model);

      selectedTooth = model;
    } else {
      ioViewer.scene.remove(addToothGroup);
    }

    // console.log(faces);
    $(".intraoral-add-item").css("display", "none");
    document.getElementById("selectedToothHint").innerHTML = model.name;
    addToothGroup = null;
    isEditMode = true;
    document.body.style.cursor = "default";
  }, 100);
}

//#endregion

//#region Changing tooth

function updateSelectedTooth(event) {
  let tooth = intersectTooth();

  if (tooth && tooth == intersectedTooth) {
    if (selectedTooth != tooth) {
      selectedTooth = tooth;
      teeth.forEach((model) => {
        if (model.pointsGroup) {
          model.pointsGroup.visible = false;
          model.mesh.material.polygonOffsetFactor = 3;
        }
      });

      tooth.mesh.material.polygonOffsetFactor = 1;
      console.log(tooth.name);
      document.getElementById("selectedToothHint").innerHTML = tooth.name;

      const group = createPointsGroup(tooth);
      if (group) {
        group.visible = true;
      }
    }
  }
  // else if (selectedTooth) {
  //   if (selectedTooth.pointsGroup) {
  //     selectedTooth.pointsGroup.visible = false;
  //   }
  //   selectedTooth = null;
  // }
}

function changingToothMouseUp(event) {
  if (selectedTooth) {
    let point = intersectPoint(selectedTooth.pointsGroup);
    if (!point) {
      changingToothAddPoint(event);
    } else if (selectedTooth.mainPoints.length > 3) {
      setNextPrevPoints(point, selectedTooth.mainPoints);
      changingToothRemovePoint(point);
    }
    selectedTooth.isChanged = true;
  }
}

function changingToothAddPoint(event) {
  if (selectedTooth) {
    updateRaycasterFromEvent(event);
    let point = intersectScene();
    if (point) {
      let areaPoints = [];
      let globalGraph = getGlobalGraph();

      let oldGeometry = selectedTooth.mesh.geometry.clone();
      let oldAllPoints = getAllBorderPointsArr(selectedTooth);
      let oldAnchorPoints = getSavedAnchorPointsArr(selectedTooth);

      let npoint = getNearestMainPoint(point);
      let newIndex = selectedTooth.mainPoints.indexOf(npoint);

      setNextPrevPoints(npoint, selectedTooth.mainPoints);
      let prevDist = getSquaredDistance(point, npoint.prevPoint.position);
      let nextDist = getSquaredDistance(point, npoint.nextPoint.position);
      if (prevDist > nextDist) {
        newIndex++;
      }
      let newPoint = drawIoPoint(point, 0x00ff00, 0.2);
      selectedTooth.mainPoints.splice(newIndex, 0, newPoint);
      setNextPrevPoints(newPoint, selectedTooth.mainPoints);

      let index0 = newPoint.prevPoint.gindex;
      let index2 = newPoint.nextPoint.gindex;
      let index1 = getPointIndex(globalGraph, newPoint.position, 0.01);
      if (index1 == index2 || index1 == index0) {
        return;
      }

      let group = selectedTooth.pointsGroup;
      let lineIndex = group.children.indexOf(newPoint.prevPoint.line1);
      group.remove(newPoint.prevPoint.line1);
      areaPoints = areaPoints.concat(newPoint.prevPoint.line1.points);

      let points1 = getShortPath(globalGraph, index1, index2);
      areaPoints = areaPoints.concat(points1.toReversed().slice(1));
      let line1 = createTubeCurve(points1, 0x000000, 0.02);
      group.children.splice(lineIndex, 0, line1);

      line1.point1 = points1[0];
      line1.point2 = points1[points1.length - 1];
      newPoint.line1 = line1;
      newPoint.position.copy(line1.point1);
      group.children.splice(lineIndex, 0, newPoint);
      newPoint.nextPoint.line2 = line1;

      let points2 = getShortPath(globalGraph, index0, index1);
      areaPoints = areaPoints.concat(points2.slice(1).toReversed().slice(1));
      let line2 = createTubeCurve(points2, 0x000000, 0.02);
      group.children.splice(lineIndex, 0, line2);
      line2.point1 = points2[0];
      line2.point2 = points2[points2.length - 1];
      newPoint.line2 = line2;
      newPoint.prevPoint.line1 = line2;

      document.body.style.cursor = "wait";

      updateBezierPoints(selectedTooth.mainPoints, globalGraph);

      // let tooth = intersectTooth();
      // let isClearing = tooth == selectedTooth;

      let rootPoint = newPoint.position.clone();

      let intersectedPoint = intersectFirst(
        selectedTooth.mesh,
        ioRaycaster,
        rootPoint
      );
      let isClearing = !!intersectedPoint;

      fillArea(
        selectedTooth,
        areaPoints,
        rootPoint,
        globalGraph,
        ioViewer.scene,
        isClearing
      );

      let newGeometry = selectedTooth.mesh.geometry.clone();
      let newAllPoints = getAllBorderPointsArr(selectedTooth);
      let newAnchorPoints = getSavedAnchorPointsArr(selectedTooth);
      let change = {
        model: selectedTooth,
        oldGeometry: oldGeometry,
        oldPoints: oldAllPoints,
        oldAnchorPoints: oldAnchorPoints,
        newGeometry: newGeometry,
        newPoints: newAllPoints,
        newAnchorPoints: newAnchorPoints,
      };
      console.log("changingToothAddPoint ioChange", change);
      addIoChange(change);

      document.body.style.cursor = "default";
      return;
      setTimeout(function () {
        let allPoints = getAllBorderPoints(selectedTooth);
        let globalMesh = getGlobalMesh();
        let geometry = getFacesGeometry(
          selectedTooth,
          allPoints,
          globalMesh,
          globalGraph,
          ioViewer.scene,
          getCrossedPoints()
        );
        if (geometry) {
          selectedTooth.mesh.geometry.dispose();
          selectedTooth.mesh.geometry = geometry;
        }
        document.body.style.cursor = "default";
      }, 100);
    }
  }
}

function changingToothRemovePoint(point) {
  clearTempMeshes(ioViewer.scene);
  let areaPoints = [];

  let oldGeometry = selectedTooth.mesh.geometry.clone();
  let oldAllPoints = getAllBorderPointsArr(selectedTooth);
  let oldAnchorPoints = getSavedAnchorPointsArr(selectedTooth);

  let pindex = selectedTooth.mainPoints.indexOf(point);
  selectedTooth.mainPoints.splice(pindex, 1);
  let group = selectedTooth.pointsGroup; // point.parent;
  let lineIndex = group.children.indexOf(point.line2);
  group.remove(point);
  group.remove(point.line1);
  group.remove(point.line2);

  areaPoints = areaPoints.concat(point.line2.points);
  areaPoints = areaPoints.concat(point.line1.points.slice(1));

  let p1 = point.prevPoint;
  let p2 = point.nextPoint;
  let globalGraph = getGlobalGraph();
  let points = getShortPath(globalGraph, p1.gindex, p2.gindex);

  areaPoints = areaPoints.concat(points.slice(1).toReversed().slice(1));

  let newLine = createTubeCurve(points, 0x000000, 0.02);
  newLine.points = newLine.shortPoints = points;
  newLine.point1 = p1.position;
  newLine.point2 = p2.position;
  p1.line1 = newLine;
  p2.line2 = newLine;
  group.children.splice(lineIndex, 0, newLine);

  document.body.style.cursor = "wait";

  updateBezierPoints(selectedTooth.mainPoints, globalGraph);

  let isClearing = true;
  let middlePoint = points[Math.floor(points.length / 2)];
  if (middlePoint) {
    let origin = ioViewer.camera.position.clone();
    // let origin = new THREE.Vector3(
    //   middlePoint.x,
    //   middlePoint.y + 10,
    //   middlePoint.z
    // );
    let target = middlePoint.clone();
    let direction = target.sub(origin).normalize();
    updateIoRaycaster(origin, direction);

    let intersectedPoint = intersectFirst(
      selectedTooth.mesh,
      ioRaycaster,
      middlePoint
    );
    isClearing = !!intersectedPoint;
    console.log("changingToothRemovePoint ", isClearing);
    // if (intersectedPoint) {
    //   drawSpherePoint(ioViewer.scene, intersectedPoint, 0x0000ff);
    // } else {
    //   drawSpherePoint(ioViewer.scene, middlePoint);
    // }
  }

  fillArea(
    selectedTooth,
    areaPoints,
    middlePoint,
    globalGraph,
    ioViewer.scene,
    isClearing
  );

  let newGeometry = selectedTooth.mesh.geometry.clone();
  let newAllPoints = getAllBorderPointsArr(selectedTooth);
  let newAnchorPoints = getSavedAnchorPointsArr(selectedTooth);
  let change = {
    model: selectedTooth,
    oldGeometry: oldGeometry,
    oldPoints: oldAllPoints,
    oldAnchorPoints: oldAnchorPoints,
    newGeometry: newGeometry,
    newPoints: newAllPoints,
    newAnchorPoints: newAnchorPoints,
  };
  // console.log("changingToothRemovePoint ioChange", change);
  addIoChange(change);

  document.body.style.cursor = "default";
  return;

  setTimeout(function () {
    let allPoints = getAllBorderPoints(selectedTooth);
    let globalMesh = getGlobalMesh();
    let geometry = getFacesGeometry(
      selectedTooth,
      allPoints,
      globalMesh,
      globalGraph,
      ioViewer.scene,
      getCrossedPoints()
    );
    if (geometry) {
      selectedTooth.mesh.geometry.dispose();
      selectedTooth.mesh.geometry = geometry;
    }
    document.body.style.cursor = "default";
  }, 100);
}

//#endregion

//#region Drag point

function startDragPoint(point, event) {
  setNextPrevPoints(point, selectedTooth.mainPoints);
  if (event.button === 0) {
    dragPoint = point;
    dragPoint.basePosition = point.position.clone();
    dragPoint.baseIndex = getPointIndex(
      getGlobalGraph(),
      dragPoint.basePosition
    );
    if (event.preventDefault) event.preventDefault();
    ioViewer.controls.enabled = false;
  }
}

function moveDragPoint(event) {
  if (dragPoint) {
    updateRaycasterFromEvent(event);
    let point = intersectScene();
    if (point) {
      dragPoint.position.copy(point);

      if (dragPoint.line1) {
        let line = dragPoint.line1;
        updateTubeLine(line, point, line.point2);
        line.point1 = point;
      }

      if (dragPoint.line2) {
        let line = dragPoint.line2;
        updateTubeLine(line, line.point1, point);
        line.point2 = point;
      }
    }
  }
}

function endDragPoint() {
  clearTempMeshes(ioViewer.scene);

  if (dragPoint) {
    let globalGraph = getGlobalGraph();
    let index1 = getPointIndex(globalGraph, dragPoint.position, 0.01);
    let newPosition = globalGraph.getNode(index1).position;
    if (
      index1 === dragPoint.baseIndex ||
      areNearPoints(dragPoint.basePosition, newPosition)
    ) {
      let line = dragPoint.line1;
      updateTubeCurve(
        line,
        line.bezierPoints ? line.bezierPoints : line.points
      );
      line = dragPoint.line2;
      updateTubeCurve(
        line,
        line.bezierPoints ? line.bezierPoints : line.points
      );
      dragPoint.position.copy(dragPoint.basePosition);
      // selectedTooth.pointsGroup.visible = false;
      // selectedTooth = null;
      dragPoint = null;
      return;
    }

    let index0 = dragPoint.prevPoint.gindex;
    let index2 = dragPoint.nextPoint.gindex;

    if (index1 == index2 || index1 == index0) {
      return;
    }

    let areaPoints = [];
    let oldPoints = [];
    let newPoints = [];
    let oldGeometry = selectedTooth.mesh.geometry.clone();
    let oldAllPoints = getAllBorderPointsArr(selectedTooth);
    let oldAnchorPoints = getSavedAnchorPointsArr(selectedTooth);

    dragPoint.basePosition = null;

    let tbPoints = getShortPath(globalGraph, index0, index2);
    tbPoints = tbPoints.slice(1).toReversed().slice(1);

    let oldPoints1 = dragPoint.line2.shortPoints
      ? dragPoint.line2.shortPoints
      : dragPoint.line2.points;
    // getShortPath(globalGraph, index0, dragPoint.baseIndex);
    areaPoints = areaPoints.concat(oldPoints1);
    let oldPoints2 = dragPoint.line1.shortPoints
      ? dragPoint.line1.shortPoints
      : dragPoint.line1.points;
    // getShortPath(globalGraph, dragPoint.baseIndex, index2);
    areaPoints = areaPoints.concat(oldPoints2.slice(1));
    oldPoints = areaPoints;

    let line = dragPoint.line1;
    line.points = line.shortPoints = getShortPath(globalGraph, index1, index2);
    areaPoints = areaPoints.concat(line.points.toReversed().slice(1));
    updateTubeCurve(line, line.points);
    line.point1 = line.points[0];

    dragPoint.position.copy(line.point1);

    line = dragPoint.line2;
    line.points = line.shortPoints = getShortPath(globalGraph, index0, index1);
    areaPoints = areaPoints.concat(line.points.slice(1).toReversed().slice(1));
    updateTubeCurve(line, line.points);
    line.point2 = line.points[line.points.length - 1];

    updateBezierPoints(selectedTooth.mainPoints, globalGraph);

    newPoints = newPoints.concat(dragPoint.line2.points);
    newPoints = newPoints.concat(dragPoint.line1.points.slice(1));

    // oldPoints.forEach((p) => drawPoint(p, ioViewer.scene, 0xff0000, 0.05));
    // newPoints.forEach((p) => drawPoint(p, ioViewer.scene, 0x0000ff, 0.05));
    // tbPoints.forEach((p) => drawPoint(p, ioViewer.scene, 0xffff00, 0.05));

    let crossPoints = [];
    let newLength = newPoints.length;
    let oldLenth = oldPoints.length;
    oldPoints.forEach((oldPoint, oldIndex) => {
      newPoints.forEach((newPoint, newIndex) => {
        if (
          oldIndex != newIndex &&
          newLength - newIndex != oldLenth - oldIndex &&
          areNearPoints(oldPoint, newPoint)
        ) {
          crossPoints.push(oldPoint);
          // drawPoint(oldPoint, ioViewer.scene, 0x00ff00, 0.05);
          return;
        }
      });
    });
    // console.log("crossPoints", crossPoints.length);

    oldPoints = oldPoints.concat(tbPoints);
    newPoints = newPoints.concat(tbPoints);

    // oldPoints.forEach((p) => drawPoint(p, ioViewer.scene, 0xff0000, 0.05));

    let rootPoint = dragPoint.position.clone();
    dragPoint = null;

    document.body.style.cursor = "wait";

    let intersectedPoint = intersectFirst(
      selectedTooth.mesh,
      ioRaycaster,
      rootPoint
    );
    let isClearing = !!intersectedPoint;
    let model = selectedTooth;
    let scene = ioViewer.scene;
    console.log(
      "endDragPoint",
      "isClearing:",
      isClearing,
      "crossPoints:",
      crossPoints.length
    );

    setTimeout(function () {
      if (crossPoints.length > 0) {
        // newPoints.forEach((p) => drawPoint(p, ioViewer.scene, 0x00ff00, 0.05));
        fillArea(model, oldPoints, rootPoint, globalGraph, scene, !isClearing);
        fillArea(model, newPoints, rootPoint, globalGraph, scene, isClearing);
      } else {
        // areaPoints.forEach((p) => drawPoint(p, ioViewer.scene, 0x00ff00, 0.05));
        fillArea(model, areaPoints, rootPoint, globalGraph, scene, isClearing);
      }

      let newGeometry = selectedTooth.mesh.geometry.clone();
      let newAllPoints = getAllBorderPointsArr(selectedTooth);
      let newAnchorPoints = getSavedAnchorPointsArr(selectedTooth);
      let change = {
        model: selectedTooth,
        oldGeometry: oldGeometry,
        oldPoints: oldAllPoints,
        oldAnchorPoints: oldAnchorPoints,
        newGeometry: newGeometry,
        newPoints: newAllPoints,
        newAnchorPoints: newAnchorPoints,
      };
      // console.log("endDragPoint ioChange", change);
      addIoChange(change);
      selectedTooth.isChanged = true;
      document.body.style.cursor = "default";
    }, 100);
    return;

    setTimeout(function () {
      let allPoints = getAllBorderPoints(selectedTooth);
      let globalMesh = getGlobalMesh();
      let geometry = getFacesGeometry(
        selectedTooth,
        allPoints,
        globalMesh,
        globalGraph,
        ioViewer.scene,
        getCrossedPoints()
      );
      if (geometry) {
        selectedTooth.mesh.geometry.dispose();
        selectedTooth.mesh.geometry = geometry;
      }
      document.body.style.cursor = "default";
    }, 100);
  }
}

//#endregion

//#region Get logic

function getGlobalMesh() {
  return isUpperMode ? upperMesh : lowerMesh;
}

function getGlobalBox() {
  return isUpperMode ? upperBox : lowerBox;
}

function getGlobalGraph() {
  return isUpperMode ? upperGraph : lowerGraph;
}

function getCrossedPoints() {
  return isUpperMode ? upperCrossedPoints : lowerCrossedPoints;
}

function getModelByName(name) {
  if (name && allModels.length) {
    return allModels.find((m) => m.name === name);
  }
}

function getToothByUuid(uuid) {
  return uuid ? teeth.find((x) => x.uuid === uuid && !x.isDeleted) : null;
}

function getNearestMainPoint(newPoint) {
  if (selectedTooth) {
    let npoint = null;
    let points = selectedTooth.mainPoints;
    let minDistance = Infinity;
    points.forEach((mesh) => {
      let distance = getSquaredDistance(newPoint, mesh.position);
      if (distance < minDistance) {
        minDistance = distance;
        npoint = mesh;
      }
    });
    return npoint;
  }
}

function getSquaredDistance(p1, p2) {
  const dx = p1.x - p2.x,
    dy = p1.y - p2.y,
    dz = p1.z - p2.z;

  return dx * dx + dy * dy + dz * dz;
}

function getAddedToothName() {
  let prefix = "tooth_" + addedSuffix;
  if (isUpperMode) {
    prefix += upperSuffix;
  } else {
    prefix += lowerSuffix;
  }
  for (let i = 1; ; i++) {
    let tempName = prefix + i;
    let existedTooth = teeth.find((t) => t.name === tempName);
    if (!existedTooth) {
      return tempName;
    }
  }
}

//#endregion

//#region Intersect logic

function updateRaycasterFromEvent(event) {
  try {
    let direction = getMouse3dDirection(event);
    let origin = ioViewer.camera.position;
    ioRaycaster.set(origin, direction);
  } catch (error) {
    console.error("updateRaycasterFromEvent", error);
  }
}

function updateIoRaycaster(origin, direction) {
  try {
    ioRaycaster.set(origin, direction);
  } catch (error) {
    console.error("updateRaycaster", error);
  }
}

function getMouse3dDirection(event) {
  let direction = null;
  if (event && event.pageX && event.pageY) {
    let intraoralContainer = document.getElementById("intraoralContainer");
    let overlayRect = intraoralContainer.getBoundingClientRect();
    let x = event.pageX - overlayRect.left;
    let y = event.pageY - overlayRect.top;
    direction = new THREE.Vector3(
      (x / overlayRect.width) * 2 - 1,
      -(y / overlayRect.height) * 2 + 1,
      0.5
    );
    direction.unproject(ioViewer.camera);
    direction.sub(ioViewer.camera.position);
    direction.normalize();
  }
  return direction;
}

function intersectPoint(pointsGroup) {
  let pointMesh = null;
  if (pointsGroup) {
    let meshes = pointsGroup.children.filter((item) => item.name === "point");
    let globalMesh = getGlobalMesh();
    meshes.push(globalMesh);
    let intersects = ioRaycaster.intersectObjects(meshes);
    if (intersects.length > 0) {
      let firstMesh = intersects[0].object;
      // console.log(firstMesh.name);
      if (firstMesh != globalMesh) {
        pointMesh = firstMesh;
      }
    }
  }
  return pointMesh;
}

function intersectTooth(raycaster) {
  if (!raycaster) {
    raycaster = ioRaycaster;
  }

  let tooth = null;
  let intersects = raycaster.intersectObjects(teethMeshes); // ioViewer.scene.children);
  if (intersects.length > 0) {
    intersects.find((intersect) => {
      let objectUuid = intersect.object.uuid;
      let temp = getToothByUuid(objectUuid);
      if (temp && !temp.isDeleted && temp.mesh.visible) tooth = temp;
      return tooth != null;
    });
  }
  return tooth;
}

function intersectScene() {
  let point = null;
  let intersects = ioRaycaster.intersectObject(getGlobalMesh());
  if (intersects.length > 0) {
    point = intersects[0].point;
  }
  return point;
}

function intersectMesh(mesh, raycaster) {
  let point = null;
  if (!raycaster) {
    raycaster = ioRaycaster;
  }
  if (mesh) {
    let intersects = raycaster.intersectObject(mesh);
    if (intersects.length > 0) {
      point = intersects[0].point;
    }
  }
  return point;
}

function intersectFirst(mesh, raycaster, targetPoint) {
  let point = null;
  if (!raycaster) {
    raycaster = ioRaycaster;
  }
  if (mesh) {
    let intersects = raycaster.intersectObject(mesh);
    // let globalMesh = getGlobalMesh();
    //.intersectObjects([mesh, globalMesh]);
    for (let intersect of intersects) {
      let dist = getDistance(targetPoint, intersect.point);
      if (dist < 0.999) {
        point = intersect.point;
      }
    }
  }
  return point;
}

//#endregion

//#region Points

const minDistance = 1.5;

function createPointsGroupBase(model) {
  if (!model.pointsGroup && model.points) {
    let globalGraph = getGlobalGraph();
    const group = (model.pointsGroup = new THREE.Group());
    group.visible = false;
    model.mainPoints = [];

    const points = model.points.map(
      (p) => new THREE.Vector3(p[0], p[1], p[2] + globalDz)
    );

    const anchorPoints = model.anchorPoints
      ? model.anchorPoints.map(
          (p) => new THREE.Vector3(p[0], p[1], p[2] + globalDz)
        )
      : null;
    let firstAnchor = anchorPoints ? anchorPoints.shift() : null;

    if (points.length > 1) {
      let distance = 0;
      let indexDif = 0;
      points.forEach((ppp, index) => {
        if (index != 0) {
          distance += getDistance(points[index - 1], points[index]);
          indexDif++;
        }
        if (index == points.length - 1) {
          distance = minDistance;
        }
        let adistance =
          anchorPoints && anchorPoints.length
            ? getDistance(anchorPoints[0], points[index])
            : 0;
        if (anchorPoints && !anchorPoints.length && index != points.length - 1)
          return;

        if (
          (anchorPoints && adistance < 0.01) ||
          (!anchorPoints && distance >= minDistance)
        ) {
          if (anchorPoints) anchorPoints.shift();
          let subset = points.slice(index - indexDif, index + 1);
          distance = 0;
          indexDif = 0;
          let point1 = subset[0];

          if (index == points.length - 1) {
            subset.push(points[0]);
          }

          let point2 = subset[subset.length - 1];

          if (subset.length < 2) {
            console.log("wrong subset");
            return;
          }

          let line = createTubeCurve(subset, 0x000000, 0.02);
          line.point1 = point1;
          line.point2 = point2;
          line.points = subset;

          let index1 = getPointIndex(globalGraph, point1);
          let index2 = getPointIndex(globalGraph, point2);
          let tempSubset = getShortPath(globalGraph, index1, index2);
          if (tempSubset.length > 1) {
            line.shortPoints = tempSubset;
            line.bezierPoints = tempSubset;
            subset = tempSubset;
            point1 = subset[0];
            point2 = subset[subset.length - 1];
          }

          let color = 0x00ff00;
          // if (index === 0) {
          //   color = 0xff0000;
          // }
          // if (index === 10) {
          //   color = 0x0000ff;
          // }

          let point = drawIoPoint(point1, color, 0.2);
          point.line1 = line;
          point.line2 = group.children.slice(-1)[0];
          model.mainPoints.push(point);

          group.add(point, line);
        }
      });
      group.children[0].line2 = group.children.slice(-1)[0];

      updateBezierPoints(model.mainPoints, globalGraph);
    } else {
      console.log("invalid points for model", model);
    }

    group.visible = false;
    ioViewer.scene.add(group);

    // let globalGraph = getGlobalGraph();
    // let globalMesh = getGlobalMesh();
    // let bezier = createBezierCurve(model.mainPoints, globalGraph, globalMesh, ioRaycaster);
    // group.add(bezier);
  }
  return model.pointsGroup;
}

function createPointsGroup(model) {
  if (!model.pointsGroup) {
    if (!model.anchorPoints) {
      return createPointsGroupBase(model);
    }

    let globalGraph = getGlobalGraph();
    const group = (model.pointsGroup = new THREE.Group());
    group.visible = false;
    model.mainPoints = [];

    const anchorPoints = model.anchorPoints
      ? model.anchorPoints.map(
          (p) => new THREE.Vector3(p[0], p[1], p[2] + globalDz)
        )
      : null;
    let firstAnchor = anchorPoints ? anchorPoints.shift() : null;

    if (anchorPoints.length > 1) {
      anchorPoints.forEach((ppp, index) => {
        let point1 = anchorPoints[index];
        let point2 = anchorPoints[index + 1];
        if (!point2) {
          point2 = anchorPoints[0];
        }

        let index1 = getPointIndex(globalGraph, point1);
        let index2 = getPointIndex(globalGraph, point2);
        let subset = getShortPath(globalGraph, index1, index2);
        if (subset.length > 1) {
          let line = createTubeCurve(subset, 0x000000, 0.02);
          line.point1 = point1;
          line.point2 = point2;
          line.points = subset;

          line.shortPoints = subset;
          line.bezierPoints = subset;

          let color = 0x00ff00;
          let point = drawIoPoint(point1, color, 0.2);
          point.line1 = line;
          point.line2 = group.children.slice(-1)[0];
          model.mainPoints.push(point);
  
          group.add(point, line);
        }
      });
      group.children[0].line2 = group.children.slice(-1)[0];

      updateBezierPoints(model.mainPoints, globalGraph);
    } else {
      console.log("invalid points for model", model);
    }

    group.visible = false;
    ioViewer.scene.add(group);
  }
  return model.pointsGroup;
}

function drawIoPoint(point, color, radius) {
  if (point) {
    if (!color) {
      color = 0x00ff00;
    }
    if (!radius) {
      radius = 0.4;
    }
    // radius = 0.01;
    let geometry = new THREE.SphereGeometry(radius, 16, 16); // , 64, 32

    //new THREE.BoxGeometry(0.5, 0.5, 0.5),
    let mesh = new THREE.Mesh(
      geometry,
      new THREE.MeshPhongMaterial({
        color: color,
        flatShading: false,
        specular: 0x050505,
        shininess: 200,
        side: 2,
        // toneMapped: false,
        // depthWrite: false,
        // depthTest: false,
        transparent: true,
      })
      // new THREE.MeshBasicMaterial({ color })
    );
    mesh.position.set(point.x, point.y, point.z);
    mesh.name = "point";

    return mesh;
  }
}

function setNextPrevPoints(point, points) {
  let pindex = points.indexOf(point);
  point.prevPoint = points[pindex - 1];
  if (!point.prevPoint) {
    point.prevPoint = points[points.length - 1];
  }
  point.nextPoint = points[pindex + 1];
  if (!point.nextPoint) {
    point.nextPoint = points[0];
  }
  let globalGraph = getGlobalGraph();
  point.prevPoint.gindex = getPointIndex(globalGraph, point.prevPoint.position);
  point.nextPoint.gindex = getPointIndex(globalGraph, point.nextPoint.position);
}

function createLine(prevPoint, nextPoint) {
  let globalGraph = getGlobalGraph();
  let index0 = prevPoint.gindex;
  let index1 = nextPoint.gindex;
  let pathPoints = getShortPath(globalGraph, index0, index1);
  let line = createTubeCurve(pathPoints, 0x000000, 0.02);
  line.point1 = prevPoint.position;
  line.point2 = nextPoint.position;
  line.points = line.shortPoints = pathPoints;
  prevPoint.line1 = line;
  nextPoint.line2 = line;
  return line;
}

function getAllBorderPoints(tooth) {
  let allPoints = [];
  if (tooth) {
    if (!tooth.pointsGroup) createPointsGroup(tooth);
    // let globalGraph = getGlobalGraph();
    // let mainPoints = tooth.pointsGroup.children.filter(
    //   (mesh) => mesh.name === "point"
    // );
    // mainPoints.forEach((p1, index) => {
    //   let p2 = mainPoints[index + 1];
    //   if (!p2) p2 = mainPoints[0];

    //   let index1 = getPointIndex(globalGraph, p1.position);
    //   let index2 = getPointIndex(globalGraph, p2.position);

    //   let points = getShortPath(globalGraph, index1, index2);
    //   allPoints.push(...points.slice(1));
    // });

    tooth.pointsGroup.children.forEach((mesh) => {
      if (mesh.name != "point") {
        let line = mesh;
        let linePoints = line.shortPoints ? line.shortPoints : line.points;
        //line.bezierPoints ? line.bezierPoints : line.points;
        allPoints.push(...linePoints.slice(1));
      }
    });

    // allPoints = Array.from(new Set(allPoints));
  }
  return allPoints;
}

function getAllBorderPointsArr(tooth) {
  let allPoints = [];
  if (tooth && (tooth.points || tooth.pointsGroup)) {
    if (!tooth.pointsGroup) {
      allPoints = tooth.points;
    } else {
      tooth.pointsGroup.children.forEach((mesh) => {
        if (mesh.name != "point") {
          let line = mesh;
          let linePoints = line.shortPoints ? line.shortPoints : line.points;
          allPoints.push(...linePoints.slice(1).map((p) => [p.x, p.y, p.z]));
        }
      });
    }
  }
  return allPoints;
}

function getSavedBorderPointsArr(tooth) {
  let allPoints = [];
  if (tooth && (tooth.points || tooth.pointsGroup)) {
    if (!tooth.pointsGroup) {
      allPoints = tooth.points;
    } else {
      tooth.pointsGroup.children.forEach((mesh) => {
        if (mesh.name != "point") {
          let line = mesh;
          let linePoints = line.bezierPoints ? line.bezierPoints : line.points;
          allPoints.push(...linePoints.slice(1).map((p) => [p.x, p.y, p.z]));
        }
      });
    }
  }
  return allPoints;
}

function getSavedAnchorPointsArr(tooth) {
  let aPoints = [];
  if (tooth && (tooth.points || tooth.pointsGroup)) {
    if (!tooth.pointsGroup) {
      aPoints = tooth.anchorPoints;
    } else {
      tooth.pointsGroup.children.forEach((mesh) => {
        if (mesh.name == "point") {
          let p = mesh.basePosition ? mesh.basePosition : mesh.position;
          // if (mesh.basePosition) {
          //   console.log("mesh.basePosition");
          // }
          aPoints.push([p.x, p.y, p.z]);
        }
      });
    }
  }
  return aPoints;
}

function getAllMainPoints(tooth) {
  if (tooth) {
    if (!tooth.pointsGroup) createPointsGroup(tooth);
    let mainPoints = tooth.pointsGroup.children.filter(
      (mesh) => mesh.name === "point"
    );
    return mainPoints;
  }
}

function smoothSelectedToothBorder() {
  if (selectedTooth) {
    document.body.style.cursor = "wait";
    document.getElementById("intraoralSmoothBtn").style.cursor = "wait";

    let model = selectedTooth;
    setTimeout(() => {
      let mainPoints = getAllMainPoints(model);

      let globalGraph = getGlobalGraph();
      mainPoints.forEach((p1, index) => {
        let p2 = mainPoints[index + 1];
        if (!p2) p2 = mainPoints[0];
        let index1 = getPointIndex(globalGraph, p1.position);
        let index2 = getPointIndex(globalGraph, p2.position);
        let points = getShortPath(globalGraph, index1, index2);
        let line = p1.line1;
        line.point1 = p1;
        line.point2 = p2;
        line.points = line.shortPoints = points;
        updateTubeCurve(line, points);
      });

      // updateBezierPoints(selectedTooth.mainPoints, globalGraph);

      document.body.style.cursor = "default";
      document.getElementById("intraoralSmoothBtn").style.cursor = "pointer";
    }, 100);
  }
}

function repairIntraoralColouring() {
  if (selectedTooth) {
    let repairBtn = document.getElementById("intraoralRepairBtn");
    document.body.style.cursor = "wait";
    if (repairBtn) repairBtn.style.cursor = "wait";

    let model = selectedTooth;
    let jsonData = {};
    let sPoints = getSavedBorderPointsArr(model);
    let allPoints = getAllBorderPointsArr(model);
    jsonData[model.name] = sPoints;
    jsonData["teeth"] = [model.name];
    if (model.crossedTeeth && model.crossedTeeth.length) {
      jsonData["crossedTeeth"] = model.crossedTeeth;
    } else {
      jsonData["crossedTeeth"] = [];
      // allModels.filter(m => m.isUpper == isUpperMode && m.isTooth && !m.isAdded).map(m => m.name);
      // && !m.isDeleted
    }

    const jsonBlob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });
    sendApplyBorderPointsRequest(
      jsonBlob,
      upperMesh && lowerMesh,
      model.isUpper,
      (zipBlob) => {
        document.body.style.cursor = "default";
        if (repairBtn) repairBtn.style.cursor = "pointer";

        if (!zipBlob) {
          return;
        }
        // var zip = new JSZip();
        JSZip.loadAsync(zipBlob).then((zip) => {
          console.log(zip.files);
          zip.forEach(function (fileName, file) {
            let tooth = getModelByName(fileName);
            if (tooth) {
              let zipFile = zip.file(fileName);
              zipFile.async("arraybuffer").then((buffer) => {
                getGeometryFromStl(buffer, (elementGeometry) => {
                  const tempGeo = new THREE.Geometry().fromBufferGeometry(
                    elementGeometry
                  );
                  tempGeo.mergeVertices();
                  tempGeo.computeVertexNormals();
                  elementGeometry = new THREE.BufferGeometry().fromGeometry(
                    tempGeo
                  );
                  elementGeometry.computeBoundingBox();

                  let box = elementGeometry.boundingBox;
                  box = offsetBox(box, 0, 0, 0);
                  tooth.defaultBox = box;
                  tooth.defaultPosition = {
                    x: box.center.x,
                    y: box.center.y,
                    z: box.center.z,
                  };

                  let geometry = prepareGeometry(elementGeometry, false);

                  // let change = {
                  //   model: selectedTooth,
                  //   oldGeometry: tooth.mesh.geometry.clone(),
                  //   oldPoints: allPoints,
                  //   newGeometry: geometry.clone(),
                  //   newPoints: allPoints,
                  // };
                  // addIoChange(change);

                  tooth.mesh.geometry.dispose();
                  tooth.mesh.geometry = geometry;
                });
              });
            }
          });
        });
      }
    );
  }
}

function updateOpacity(opacityValue) {
  allModels.forEach((model) => {
    ioViewer.set_opacity(model.id, opacityValue);
  });
}

//#endregion

//#region Modes/Camera

function switchViewModes(isUpper, isLower) {
  let previosDouble = isDoubleMode;

  isDoubleMode = isUpper && isLower;
  isEditMode = !isDoubleMode && !vueApp.isViewMode;
  isUpperMode = isUpper;
  allModels.forEach((model) => {
    model.mesh.visible =
      !model.isDeleted && (model.isUpper === isUpper || isDoubleMode);
    if (model.pointsGroup) {
      model.pointsGroup.visible = false;
      model.mesh.material.polygonOffsetFactor = 3;
    }
  });
  resetIoCamera();

  if (isUpperMode) rotateIntraoralToView(2);

  if (previosDouble != isDoubleMode) {
    let scaleMatrix = new THREE.Matrix4().makeScale(1, 1, -1);
    let scaleInvert = scaleMatrix.clone().invert();
    let translationMatrix = new THREE.Matrix4().makeTranslation(0, 0, 3);
    let translationInvert = translationMatrix.clone().invert();

    allModels.forEach((model) => {
      if (model.isUpper) {
        if (isDoubleMode) {
          model.mesh.applyMatrix4(scaleMatrix);
          model.mesh.applyMatrix4(translationMatrix);
        } else {
          model.mesh.applyMatrix4(translationInvert);
          model.mesh.applyMatrix4(scaleInvert);
        }
      }
    });

    if (isDoubleMode) {
      let point = defaultIoBox.center;
      ioViewer.camera.position.x = point.x;
      ioViewer.camera.position.y = -defaultIoZoom;
      ioViewer.camera.position.z = point.z;

      ioViewer.camera.up.y = 0;
      ioViewer.camera.up.x = 0;
      ioViewer.camera.up.z = 1;
    }
  }
}

function resetIoCamera() {
  // let point = { x: 0, y: 0, z: 0 };
  let point = defaultIoBox.center;
  // console.log("resetIoCamera", point);
  ioViewer.camera.lookAt(point.x, point.y, point.z);
  ioViewer.controls.target = new THREE.Vector3(point.x, point.y, point.z);

  ioViewer.camera.up.y = 1;
  ioViewer.camera.up.x = 0;
  ioViewer.camera.up.z = 0;
  ioViewer.camera.position.x = 0; //point.x;
  ioViewer.camera.position.y = 0; //point.y;
  ioViewer.camera.position.z = defaultIoZoom;
}

function updateIsEditMode(isEdit) {
  isEditMode = isEdit;
  if (!isEditMode) {
    dragPoint = null;
    intersectedTooth = null;
    ioViewer.controls.enabled = true;
    teeth.forEach((model) => {
      if (model.pointsGroup) {
        model.pointsGroup.visible = false;
      }
    });
  } else {
    teeth.forEach((model) => {
      if (model.pointsGroup && model == selectedTooth) {
        model.pointsGroup.visible = true;
      }
    });
  }
}

function rotateIntraoralToView(viewNumber) {
  // console.log("rotateIntraoralToView", viewNumber, ioViewer.camera);
  resetIoCamera();
  let viewQuarterinion1 = null;
  let viewQuarterinion2 = null;
  switch (viewNumber) {
    case 1:
      break;
    case 2:
      viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 0, 1), // z-axis
        degreesToRadians(180)
      );
      break;
    case 3:
      if (isUpperMode) {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(-90)
        );
        viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          degreesToRadians(180)
        );
        // viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
        //   new THREE.Vector3(0, 1, 0),
        //   degreesToRadians(180)
        // );
        // viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
        //   new THREE.Vector3(1, 0, 0),
        //   degreesToRadians(-90)
        // );
      } else {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(90)
        );
      }
      break;
    case 4:
      if (isUpperMode) {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(-90)
        );
      } else {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(-90)
        );
        viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          degreesToRadians(180)
        );
      }

      break;
    case 5:
      if (isUpperMode) {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(-90)
        );
        viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          degreesToRadians(-90)
        );
      } else {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(90)
        );
        viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          degreesToRadians(-90)
        );
      }
      break;
    case 6:
      if (isUpperMode) {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(-90)
        );
        viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          degreesToRadians(90)
        );
      } else {
        viewQuarterinion1 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          degreesToRadians(90)
        );
        viewQuarterinion2 = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          degreesToRadians(90)
        );
      }
      break;
  }

  if (viewQuarterinion1) {
    ioViewer.camera.up.applyQuaternion(viewQuarterinion1);
    ioViewer.camera.position.applyQuaternion(viewQuarterinion1);
  }

  if (viewQuarterinion2) {
    ioViewer.camera.up.applyQuaternion(viewQuarterinion2);
    ioViewer.camera.position.applyQuaternion(viewQuarterinion2);
  }

  ioViewer.camera.updateProjectionMatrix();
  ioViewer.controls.update();
}

function changeTeethColor(teethColor) {
  console.log("changeTeethColor", teethColor);
  teeth.forEach((tooth) => {
    if (teethColor == randomColorValue) {
      let color = getRandomColor();
      while (color == prevColor) {
        color = getRandomColor();
      }
      ioViewer.set_color(tooth.id, color);
      prevColor = color;
    } else if (teethColor == monochromeColorValue) {
      ioViewer.set_color(tooth.id, greyColorValue);
    } else {
      ioViewer.set_color(tooth.id, teethColor);
    }

    tooth.mesh.visible =
      !tooth.isDeleted && (tooth.isUpper === isUpperMode || isDoubleMode);
  });

  let gingivas = allModels.filter((model) => model.isGingiva);
  gingivas.forEach((model) => {
    if (teethColor == monochromeColorValue) {
      ioViewer.set_color(model.id, greyColorValue);
    } else {
      ioViewer.set_color(model.id, gingivaColor);
    }

    model.mesh.visible = model.isUpper === isUpperMode || isDoubleMode;
  });
}

//#endregion

//#region Save/Reset

function saveIntraoralData() {
  let upperPointsData = {};
  let lowerPointsData = {};
  let upperAnchorsData = {};
  let lowerAnchorsData = {};
  let settingsData = { teeth: {} };
  var zip = new JSZip();
  teeth.forEach((tooth) => {
    // let isChanged = tooth.savedGeometry != tooth.mesh.geometry;
    settingsData.teeth[tooth.name] = {
      color: tooth.color,
      isSaved: true,
      isDeleted: tooth.isDeleted,
      isChanged: tooth.isChanged,
    };
    if (tooth.isChanged) {
      console.log("changed tooth: ", tooth.name);
    }

    tooth.recentlyAdded = tooth.recentlyDeleted = false;
    tooth.isChanged = false;
    if (tooth.isDeleted) return;

    let stlBlob = exportMeshToStlBlob(tooth.mesh);
    tooth.savedGeometry = tooth.mesh.geometry;
    tooth.savedPoints = getSavedBorderPointsArr(tooth);
    tooth.savedAnchorPoints = getSavedAnchorPointsArr(tooth);

    zip.file(tooth.name + ".stl", stlBlob);

    if (tooth.isUpper) {
      upperPointsData[tooth.name] = tooth.savedPoints;
      upperAnchorsData[tooth.name] = tooth.savedAnchorPoints;
    } else {
      lowerPointsData[tooth.name] = tooth.savedPoints;
      lowerAnchorsData[tooth.name] = tooth.savedAnchorPoints;
    }
  });
  zip.file("intraoral_settings.json", JSON.stringify(settingsData));

  if (upperMesh) {
    zip.file("saved_upper_border_points.json", JSON.stringify(upperPointsData));
    zip.file("saved_upper_extr_anchors.json", JSON.stringify(upperAnchorsData));
  }

  if (lowerMesh) {
    zip.file("saved_lower_border_points.json", JSON.stringify(lowerPointsData));
    zip.file("saved_lower_extr_anchors.json", JSON.stringify(lowerAnchorsData));
  }

  console.log("saveIntraoralData", zip);

  zip.generateAsync({ type: "blob" }).then(function (blob_data) {
    sendSaveIntraoralDataRequest(blob_data);
  });
}

function globalResetIntraoral() {
  clearTempMeshes(ioViewer.scene);

  teeth.forEach((model) => {
    let vmesh = model.mesh;

    if (model.isDeleted && !model.isAdded) {
      model.isDeleted = false;
      model.recentlyAdded = true;
      vmesh.visible = true;
    }

    if (model.isAdded) {
      model.isDeleted = model.recentlyDeleted = true;
      vmesh.visible = false;
    }

    if (vmesh.visible && model.baseGeometry) {
      let cloneGeometry = model.baseGeometry.clone();
      cloneGeometry.computeBoundingBox();

      let box = cloneGeometry.boundingBox;
      box = offsetBox(box, 0, 0, globalDz);
      // vmesh.position.set(box.center.x, box.center.y, box.center.z);

      let geometry = prepareGeometry(cloneGeometry, model.isGingiva);
      vmesh.geometry.dispose();
      vmesh.geometry = geometry;
    }

    if (model.pointsGroup) {
      ioViewer.scene.remove(model.pointsGroup);
      model.pointsGroup = null;
    }

    if (model.basePoints) {
      model.points = model.basePoints;
      model.anchorPoints = model.baseAnchorPoints;
      if (model == selectedTooth && !model.isDeleted) {
        createPointsGroup(model);
        model.pointsGroup.visible = true;
      }
    }
  });
  clearIoChanges();
  // selectedTooth = null;
}

function resetIntraoral() {
  clearTempMeshes(ioViewer.scene);

  // resetIoCamera();
  teeth.forEach((model) => {
    let vmesh = model.mesh;

    if (model.recentlyDeleted) {
      model.isDeleted = model.recentlyDeleted = false;
      vmesh.visible = true;
    }

    if (model.recentlyAdded) {
      model.isDeleted = true;
      vmesh.visible = false;
    }

    if (model.savedGeometry && vmesh.visible) {
      let cloneGeometry = model.savedGeometry; //.clone();
      vmesh.geometry.dispose();
      vmesh.geometry = cloneGeometry;
    }

    if (model.pointsGroup) {
      ioViewer.scene.remove(model.pointsGroup);
      model.pointsGroup = null;
    }

    let sPoints = model.savedPoints ? model.savedPoints : model.basePoints;
    let aPoints = model.savedAnchorPoints
      ? model.savedAnchorPoints
      : model.baseAnchorPoints;
    if (sPoints) {
      model.points = sPoints;
      model.anchorPoints = aPoints;
      if (model == selectedTooth && !model.isDeleted) {
        createPointsGroup(model);
        model.pointsGroup.visible = true;
      }
    }
  });
  clearIoChanges();
  // selectedTooth = null;
}

//#endregion

//#region Undo/Redo

function addIoChange(change) {
  if (currentIoChange) {
    let length = ioChanges.length;
    let index = ioChanges.indexOf(currentIoChange) + 1;
    let deletedChanges = ioChanges.splice(index, length - index);
    deletedChanges.forEach((c) => {
      c.oldGeometry.dispose();
      c.newGeometry.dispose();
    });
  } else {
    clearIoChanges();
  }
  ioChanges.push(change);
  currentIoChange = change;
}

function applyIoChange(change, applyNew) {
  if (!change) return;

  let model = change.model;
  if (model && !model.isDeleted) {
    let vmesh = model.mesh;
    let cloneGeometry = applyNew ? change.newGeometry : change.oldGeometry;
    vmesh.geometry = cloneGeometry;

    if (model.pointsGroup) {
      ioViewer.scene.remove(model.pointsGroup);
      model.pointsGroup = null;
    }

    model.points = applyNew ? change.newPoints : change.oldPoints;
    model.anchorPoints = applyNew
      ? change.newAnchorPoints
      : change.oldAnchorPoints;
    if (!model.isDeleted) {
      if (
        selectedTooth != null &&
        selectedTooth != model &&
        selectedTooth.pointsGroup
      ) {
        selectedTooth.pointsGroup.visible = false;
      }
      selectedTooth = model;
      createPointsGroup(model);
      model.pointsGroup.visible = true;
    }
  }
}

function intraoralUndo() {
  if (currentIoChange) {
    let index = ioChanges.indexOf(currentIoChange);
    // console.log("intraoralUndo", index, currentIoChange);
    applyIoChange(currentIoChange, false);
    currentIoChange = ioChanges[index - 1];
  }
}

function intraoralRedo() {
  let index = ioChanges.indexOf(currentIoChange) + 1;
  let nextIoChange = ioChanges[index];
  // console.log("intraoralRedo", index, nextIoChange);
  if (nextIoChange) {
    applyIoChange(nextIoChange, true);
    currentIoChange = nextIoChange;
  }
}

function clearIoChanges() {
  ioChanges.forEach((change) => {
    change.oldGeometry.dispose();
    change.newGeometry.dispose();
  });

  ioChanges = [];
}

//#endregion

//#region Export
export {
  processDemoZip,
  randomColorValue,
  monochromeColorValue,
  greyColorValue,
  changeTeethColor,
  processGlbBuffer,
  saveIntraoralData,
  resetIntraoral,
  globalResetIntraoral,
  updateIsEditMode,
  showIoViewer,
  initStlViewer,
  applyBorderPoints,
  applyAnchorPoints,
  switchViewModes,
  applySettings,
  cancelAdd,
  processStlModels,
  rotateIntraoralToView,
  smoothSelectedToothBorder,
  repairIntraoralColouring,
  intraoralUndo,
  intraoralRedo,
  updateOpacity,
  getModelByName,
  startAddingTooth,
};
//#endregion
