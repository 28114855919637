<template>
	<div class="dropdown dropright border-bottom-item">
		<button class="dropdown-item" v-on:click="addTooth()" type="button">
			Add Tooth <!-- Missing -->
		</button>
	</div>
</template>

<script>
import { showToothAddDialog } from "./ToothAddMode";

export default {
	name: "ToothAddMenu",
	props: ["isEngineerMode"],
	components: {},
	methods: {
		addTooth: function () {
			gtag('event', 'View->Add Tooth');
			showToothAddDialog();
		}
	}
};


</script>

<style scoped></style>