<template>
	<nav id="stl_menu" class="navbar navbar-dark navbar-expand">
		<ul class="navbar-nav mr-auto">
			<li id="view_menu" class="nav-item dropdown active">
				<a class="nav-link" tabindex="0" data-toggle="dropdown" data-submenu aria-expanded="false">View</a>
				<div id="viewSubMenu" class="dropdown-menu menu3d">
					<ShowHideMenu :bones="bones" :allTeeth="allTeeth" />
					<OpacityMenu :bones="bones" :allTeeth="allTeeth" />
					<ColorMenu :bones="bones" :allTeeth="allTeeth" />
					<OcclusalMenu />
					<DicomMenu />
					<IntraoralMenu v-if="isIntraoral"/>
					<ToothOldNumberMenu :isEngineerMode="isEngineerMode" />
					<ToothNumberMenu />
					<ToothAddMenu />
					<ToothFocusMenu />
					<ToothRemoveMenu />
					<SmoothMenu />

					<div class="dropdown dropright dropdown-submenu border-bottom-item" v-if="isStitched">
						<button class="dropdown-item implant-disabled" type="button">
							Switch Source
						</button>
						<div class="dropdown-menu secondary-menu">
							<button class="dropdown-item border-bottom-item" type="button"
								v-on:click="switchSource('cbct')">
								CBCT
							</button>
							<button class="dropdown-item border-bottom-item" type="button"
								v-on:click="switchSource('stitched')">
								Stitched
							</button>
						</div>
					</div>

				</div>
			</li>


			<!-- <ImplantsMenu :implants="implants" :crowns="crowns" /> -->

			<OrthoMenu :orthoStages="orthoStages" />

			<ShareMenu :shortLink="shortLink" :shareLink="shareLink" :readonlyLink="readonlyLink" />

			<img id="infoImg" src="images/info_icon_white.png" title="Help" v-on:click="showHelp()" />
		</ul>
	</nav>
</template>

<script>
import ShowHideMenu from "./showHide/ShowHideMenu.vue";
import OpacityMenu from "./opacity/OpacityMenu.vue";
import ColorMenu from "./color/ColorMenu.vue";
import OcclusalMenu from "./occlusal/OcclusalMenu.vue";
import DicomMenu from "./dicom/DicomMenu.vue";
import ToothNumberMenu from "./tooth/number/ToothNumberMenu.vue";
import ToothOldNumberMenu from "./tooth/number/ToothOldNumberMenu.vue";
import ToothAddMenu from "./tooth/add/ToothAddMenu.vue";
import ToothFocusMenu from "./tooth/focus/ToothFocusMenu.vue";
import ToothRemoveMenu from "./tooth/remove/ToothRemoveMenu.vue";
import SmoothMenu from "./quality/SmoothMenu.vue";
import IntraoralMenu from "./intraoral/IntraoralMenu.vue";

import ImplantsMenu from "./implants/ImplantsMenu.vue";
import OrthoMenu from "./ortho/OrthoMenu.vue";
import ShareMenu from "./share/ShareMenu.vue";

import { updateZipsForSourceType } from "./saveLoadData";
import { saveTestCurveMesh } from "./dicom/annotation";

import $ from "jquery";
import "bootstrap-submenu"; // https://vsn4ik.github.io/bootstrap-submenu/#html-examples

export default {
	name: "MainMenu",
	props: [
		"dentations",
		"bones",
		"nerves",
		"allTeeth",
		"implants",
		"crowns",
		"orthoStages",
		"shortLink",
		"shareLink",
		"readonlyLink",
		"isStitched",
		"isIntraoral",
		"isEngineerMode",
		"isHighQuality"
	],
	components: {
		ShowHideMenu,
		OpacityMenu,
		ColorMenu,
		OcclusalMenu,
		DicomMenu,
		ToothNumberMenu,
		ToothOldNumberMenu,
		ToothAddMenu,
		ToothFocusMenu,
		ToothRemoveMenu,
		SmoothMenu,
		IntraoralMenu,

		ImplantsMenu,
		OrthoMenu,
		ShareMenu
	},
	methods: {
		switchSource: function (sourceType) {
			gtag('event', `View->Switch Source->${sourceType}`);
			vueApp.savedData.viewSourceType = sourceType;
			vueApp.saveData();

			allLoaded = stlShown = false;
			viewer.controls.removeEventListener('change', onCameraChange);
			viewer.clean();
			viewer.renderer.domElement.remove();
			viewer = null;

			$id('loader').style.display = 'block';
			$id('stl_cont').style.visibility = 'hidden';
			vueApp.isStlLoaded = false;
			// $("#bottomPanel").css("display", "none");

			updateZipsForSourceType(sourceType);
			vueApp.initApp(vueApp.baseObj);
		},
		showHelp: function () {		
			// saveTestCurveMesh();	
			gtag('event', 'Help');
			vueApp.showHelp();
		}
	},
	mounted: function () {
		// mounted does not guarantee that all child components have also been mounted
		this.$nextTick(function () {
			// Code that will run only after the entire view has been rendered
			$("[data-submenu]").submenupicker();
		});
	}
};
</script>

<style>
#stl_menu {
	background-color: #4687fc;
	padding: 0;
	height: 35px;
	z-index: 999;
}

.navbar {
	padding-left: 0;
	padding-right: 0;
	font-family: Montserrat, sans-serif;
	font-size: small;
}

.navbar-nav {
	display: table;
	width: 100%;
	margin: 0;
}

.navbar-nav>li {
	float: none;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	width: 33%;
}

.navbar-nav>li>a {
	border-right: 1px solid #8EB5FD;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 35px;
	display: flex;
	justify-content: center;
	/* align horizontal */
	align-items: center;
	/* align vertical */
	line-height: normal;
}

.navbar-nav>li:last-child>a {
	border-right: 0;
}

.nav-link {
	outline: 0;
	cursor: default;
}

.dropdown-menu {
	background-color: #8EB5FD;
	border-radius: 0;
	color: #FFFFFF;
	padding: 0;
	margin: 0;
	width: 100%;
	min-width: 0;
	font-size: small;
	border-width: 0;
}

.dropdown-menu>.border-bottom-item:last-child {
	border-bottom: 0;
}

.dropright .dropdown-menu {
	margin: 0;
}

.dropleft .dropdown-menu {
	margin: 0;
}

.border-bottom-item {
	border-bottom: 1px solid #D9E6FE;
}

.secondary-menu {
	background-color: #B8D1FE;
}

/* .third-menu {
	background-color: #F7FAFF;
    color: #797979;
} */

.dropdown-item {
	color: #FFFFFF;
	padding: .25rem .25rem;
	width: 100%;
	text-align: center;
	outline: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.dropdown-item:focus {
	background-color: transparent;
	color: #FFFFFF;
	outline: 0;
}

.dropdown-item:hover {
	color: #FFFFFF;
	text-decoration: none;
	background-color: transparent;
}

.dropdown-item:disabled {
	color: #6c757d;
	text-decoration: none;
	background-color: transparent;
}

#infoImg {
	z-index: 999;
	height: 35px;
	cursor: pointer;
	border-right: 1px solid #8EB5FD;
	margin-right: 33px;
}
</style>