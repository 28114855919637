<template>
	<div id="dicom_menu" class="dropdown dropright dropdown-submenu border-bottom-item">
		<button class="dropdown-item" type="button" v-on:click="loadDicomFiles()">
			Dicom View
		</button>
		<div id="dicomSubMenu" class="dropdown-menu secondary-menu">
			<!-- <button class="dropdown-item border-bottom-item dicom-off-item" type="button" v-on:click="loadDicomFiles()">
				Load DICOM files
			</button> -->
			<label class="dropdown-item border-bottom-item dicom-on-item" for="cbAllOrientations"
				@click.prevent.stop="allOnClick">
				<div class="form-check form-check-inline flex-menu-item">
					<span class="form-check-label">All Views</span>
					<div class="flex-separator"></div>
					<input id="cbAllOrientations" class="form-check-input" type="checkbox" v-model="isAllLocal"
						@click.stop="allOnClick">
				</div>
			</label>
			<label class="dropdown-item border-bottom-item dicom-on-item" for="cbAxial"
				@click.prevent.stop="axialOnClick">
				<div class="form-check form-check-inline flex-menu-item">
					<span class="form-check-label">Axial</span>
					<div class="flex-separator"></div>
					<input id="cbAxial" class="form-check-input" type="checkbox" v-model="isAxialLocal"
						@click.stop="axialOnClick">
				</div>
			</label>
			<label class="dropdown-item border-bottom-item dicom-on-item" for="cbSaggital"
				@click.prevent.stop="saggitalOnClick">
				<div class="form-check form-check-inline flex-menu-item">
					<span class="form-check-label">Saggital</span>
					<div class="flex-separator"></div>
					<input id="cbSaggital" class="form-check-input" type="checkbox" v-model="isSaggitalLocal"
						@click.stop="saggitalOnClick">
				</div>
			</label>
			<label class="dropdown-item border-bottom-item dicom-on-item" for="cbCoronal"
				@click.prevent.stop="coronalOnClick">
				<div class="form-check form-check-inline flex-menu-item">
					<span class="form-check-label">Coronal</span>
					<div class="flex-separator"></div>
					<input id="cbCoronal" class="form-check-input" type="checkbox" v-model="isCoronalLocal"
						@click.stop="coronalOnClick">
				</div>
			</label>
		</div>
	</div>
	<!-- <li class="nav-item dropdown active">
				<a class="nav-link" 
				tabindex="0" v-on:click="hideSecondaryMenu()"
				data-toggle="dropdown" 
				data-submenu 
				aria-expanded="false">Dicom</a>

				<div class="dropdown-menu dropdown-menu-right" >
					<div class="dropdown dropright dropdown-submenu border-bottom-item">
						<button class="dropdown-item implant-disabled" type="button">
							Load
						</button>
						<div class="dropdown-menu secondary-menu">
							<button class="dropdown-item border-bottom-item" type="button">
								Lossless
							</button>
							<button class="dropdown-item border-bottom-item" type="button">
								Compressed
							</button>
						</div>
					</div>
				</div>
			</li> -->
</template>

<script>
import { mutationTypes } from '@/store/modules/dicom';

import {
	loadDicoms, updateAppLayout
} from "./DicomManager.js";

export default {
	name: "DicomMenu",
	data() {
		return {
			isLoaded: false
		};
	},
	computed: {
		isAllLocal: {
			get: function () {
				return this.$store.state.dicom.isAxial
					&& this.$store.state.dicom.isSaggital
					&& this.$store.state.dicom.isCoronal;
			},
			set: function (value) {
				this.$store.commit(mutationTypes.isAxialChange, value);
				this.$store.commit(mutationTypes.isSaggitalChange, value);
				this.$store.commit(mutationTypes.isCoronalChange, value);
				$(".dicom-container").css({ display: value ? "block" : "none" });
				updateAppLayout();
			}
		},
		isAxialLocal: {
			get: function () {
				return this.$store.state.dicom.isAxial;
			},
			set: function (value) {
				this.$store.commit(mutationTypes.isAxialChange, value);
				$("#axial_cont").css({ display: value ? "block" : "none" });
				updateAppLayout();
			}
		},
		isSaggitalLocal: {
			get: function () {
				return this.$store.state.dicom.isSaggital;
			},
			set: function (value) {
				this.$store.commit(mutationTypes.isSaggitalChange, value);
				$("#sagittal_cont").css({ display: value ? "block" : "none" });
				updateAppLayout();
			}
		},
		isCoronalLocal: {
			get: function () {
				return this.$store.state.dicom.isCoronal;
			},
			set: function (value) {
				this.$store.commit(mutationTypes.isCoronalChange, value);
				$("#coronal_cont").css({ display: value ? "block" : "none" });
				updateAppLayout();
			}
		}
	},
	methods: {
		allOnClick: function (event) {
			this.isAllLocal = !this.isAllLocal;
			console.log("allOnClick", this.isAllLocal, event);
			// event.preventDefault();
			// event.stopPropagation();
		},
		axialOnClick: function (event) {
			this.isAxialLocal = !this.isAxialLocal;
			// console.log("axialOnClick", this.isAxialLocal, event);
		},
		saggitalOnClick: function (event) {
			this.isSaggitalLocal = !this.isSaggitalLocal;
			// console.log("saggitalOnClick", this.isSaggitalLocal, event);
		},
		coronalOnClick: function (event) {
			this.isCoronalLocal = !this.isCoronalLocal;
			// console.log("coronalOnClick", this.isCoronalLocal, event);
		},
		loadDicomFiles: function () {
			gtag('event', `View->Dicom View`);
			if (!this.isLoaded) {
				document.getElementById("dicomSubMenu").classList.remove("show");
				document.getElementById("viewSubMenu").classList.remove("show");
				this.isLoaded = true;
				loadDicoms(
					// after load
					() => {
						$(".dicom-on-item").css({ display: "block" });
						$(".dicom-off-item").css({ display: "none" });
						this.isAllLocal = true;
					}
				);
			}
		}
	}
};
</script>

<style scoped>
.border-bottom-item {
	margin-bottom: 0;
}

.flex-menu-item {
	width: 85px;
	/* display: flex; */
	/* align-items: flex-end; */
	/* justify-content: center; */
}

.flex-separator {
	flex-grow: 1;
}

.dicom-on-item {
	display: none;
}
</style>