const state = {
  isColorChanging: false,
};

export const mutationTypes = {
  colorChanging: "[color] ColorChanging",
  colorChanged: "[color] ColorChanged",
};

const mutations = {
  [mutationTypes.colorChanging](state) {
    state.isColorChanging = true;
  },
  [mutationTypes.colorChanged](state) {
    state.isColorChanging = false;
  },
};

export default {
  state,
  mutations,
};
