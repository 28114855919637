<template>
	<div id="addCrownMap" class="modal" tabindex="-1" role="dialog"
		 aria-labelledby="addCrownMapTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content wizard-content">
				<div class="modal-header wizard-header">
					<h5 class="modal-title wizard-title"
						id="addCrownMapTitle">
						Add Crown
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body wizard-body">
					<div class="btn-group" role="group">
						<button id="btnUniversalMap" type="button"
								v-bind:class="{'map-selected': !isFDI}"
								v-on:click="switchFDI(false)"
								class="btn btn-light">
							UNIVERSAL
						</button>
						<button id="btnFdiMap" type="button"
								v-bind:class="{'map-selected': isFDI}"
								v-on:click="switchFDI(true)"
								class="btn btn-light">
							FDI
						</button>
					</div>
					<div class="btn-group" role="group">
						<button id="btnMaxillaMap" type="button"
								v-bind:class="{'map-selected': isMaxilla}"
								v-on:click="switchMaxilla(true)"
								class="btn btn-light" click="initCrownMap();">
							MAXILLA
						</button>
						<button type="button"
								v-bind:class="{'map-selected': !isMaxilla}"
								v-on:click="switchMaxilla(false)"
								class="btn btn-light" click="initCrownMap();">
							MANDIBLE
						</button>
					</div>
					<div id="mapContainer">
						<img id="imgMap" :src="mapImage" />
						<svg id="svgCircles" class="svg-map"></svg>
						<svg id="svgMapMaxilla" class="svg-map"></svg>
						<svg id="svgMapMandible" class="svg-map" style="display:none"></svg>
					</div>
					<label id="lbCrownNumber">Selected number: 11</label>
				</div>
				<div class="modal-footer wizard-footer">
					<button type="button"
							class="btn btn-primary btn-next"
							aria-label="Next"
							data-dismiss="modal"
							v-on:click="applyStep1()">
						NEXT
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { initStep3 } from "../implants/ImplantStep3.vue";

	export default {
		name: "AddCrownMap",
		props: [],
		data() {
			return {
				isFDI: false,
				isMaxilla: true
			};
		},
		methods: {
			applyStep1: function() {
				this.$emit("set-crown-number", selectedCrownNumber);
				if (!vueApp.crownImplantId) {
					vueApp.isCrownPlacement = true;
					initStep3();
					$('#implantsStep3').modal();
				} else {
					var implant = vueApp.getItemById(vueApp.crownImplantId);
					if (implant) {
						var position = implant.defaultPosition;
						this.$emit("add-crown", position.x, position.y, position.z, implant.isMaxilla);
					}
				}
			},
			switchMaxilla: function(maxilla) {
				var allCircles = document.getElementsByClassName("crown-circle");
				for (let item of allCircles) {
					item.setAttributeNS(null, "stroke", "transparent");
				}
				this.isMaxilla = maxilla;
				if (this.isMaxilla) {
					$("#svgMapMaxilla").css({ display: "block" });
					$("#svgMapMandible").css({ display: "none" });
				} else {
					$("#svgMapMaxilla").css({ display: "none" });
					$("#svgMapMandible").css({ display: "block" });
				}
			},
			switchFDI: function(fdi) {
				var allCircles = document.getElementsByClassName("crown-circle");
				for (let item of allCircles) {
					item.setAttributeNS(null, "stroke", "transparent");
				}
				this.isFDI = fdi;
			}
		},
		computed: {
			mapImage: function() {
				var retval = 'map/Teeth_FDI_Max.png';
				if (this.isMaxilla) {
					retval = this.isFDI ? 'map/Teeth_FDI_Max.png' : 'map/Teeth_Universal_Max.png';
				} else {
					retval = this.isFDI ? 'map/Teeth_FDI_Mand.png' : 'map/Teeth_Universal_Mand.png';
				}
				return retval;
			}
		}
	};

	const baseWidth = 545;
	const baseHeight = 407;
	var initialized = false;
	var selectedCrownNumber = 11;

	function initCrownMap() {
		$("#addCrownMap").css("padding-left", "0");
		if (!initialized) {
			var currentWidth = 310; //$("#imgMap")[0].offsetWidth;
			var coef = currentWidth * 1.0 / baseWidth;

			var href = window.location.href;
			href = href.split(/[?#]/)[0];
			if (href.includes('.html'))
				href = href.substring(0, href.lastIndexOf('/'));
			href = trim(href, '/');

			initSvg('svgMapMaxilla', href, 'maxilla_map.json', coef);
			initSvg('svgMapMandible', href, 'mandible_map.json', coef);
			initSvgCircles('svgCircles', href, 'Max_FDI_Circles.json', coef, "fdi");
			initSvgCircles('svgCircles', href, 'Mand_FDI_Circles.json', coef, "fdi");
			initSvgCircles('svgCircles', href, 'Max_Universal_Circles.json', coef, "universal");
			initSvgCircles('svgCircles', href, 'Mand_Uni_Circles.json', coef, "universal");

			initialized = true;
		}
	}

	function initSvg(svgId, href, jsonFile, coef) {
		var svgns = "http://www.w3.org/2000/svg";
		var svgMap = document.getElementById(svgId);

		var jsonUrl = `${href}/map/${jsonFile}`;
		fetch(jsonUrl, {
			cache: 'no-cache'
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				var map = json;
				map.forEach((element, index) => {
					var pointsValue = null;
					element.coords.forEach(coord => {
						coord.x = Math.round(coord.x * coef);
						coord.y = Math.round(coord.y * coef);
						if (!pointsValue) {
							pointsValue = `${coord.x} ${coord.y}`;
						} else {
							pointsValue = `${pointsValue}, ${coord.x} ${coord.y}`;
						}
					});
					var shape = document.createElementNS(svgns, "polygon");
					shape.setAttributeNS(null, "points", pointsValue);
					shape.setAttributeNS(null, "tag", element.number);
					shape.setAttributeNS(null, "fill", "transparent");
					svgMap.appendChild(shape);
				});

				var allMaps = $(`#${svgId} > *`);
				allMaps.on("click", function() {
					selectedCrownNumber = $(this).attr("tag");
					var shownNumber = selectedCrownNumber = parseInt(selectedCrownNumber, 10);

					var isFdi = $('#btnFdiMap').hasClass('map-selected')
					if (!isFdi) {
						shownNumber = convertToUniversal(selectedCrownNumber);
					}

					var allCircles = document.getElementsByClassName("crown-circle");
					for (let item of allCircles) {
						item.setAttributeNS(null, "stroke", "transparent");
					}

					var prefix = isFdi ? "fdi" : "universal";
					var circleId = prefix + shownNumber;
					var shape = document.getElementById(circleId);
					if (shape)
						shape.setAttributeNS(null, "stroke", "#0069d9");

					$("#lbCrownNumber").html(`Selected number: ${shownNumber}`);
				});
			})
			.catch((error) => {
				console.log(`initSvg ${jsonFile} error: ${error}`);
			});
	}

	function initSvgCircles(svgId, href, jsonFile, coef, prefix) {
		var svgns = "http://www.w3.org/2000/svg";
		var svgMap = document.getElementById(svgId);

		var jsonUrl = `${href}/map/${jsonFile}`;
		fetch(jsonUrl, {
			cache: 'no-cache'
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				var map = json;
				map.forEach((element, index) => {
					var shape = document.createElementNS(svgns, "circle");
					shape.setAttributeNS(null, "cx", Math.round(element.cx * coef) + 5);
					shape.setAttributeNS(null, "cy", Math.round(element.cy * coef));
					shape.setAttributeNS(null, "r", Math.round(element.r * coef));
					//shape.setAttributeNS(null, "tag", element.number);
					shape.setAttributeNS(null, "id", prefix + element.number);
					shape.setAttributeNS(null, "class", "crown-circle");
					shape.setAttributeNS(null, "fill", "transparent");
					shape.setAttributeNS(null, "stroke", "transparent");
					shape.setAttributeNS(null, "stroke-width", "3");
					svgMap.appendChild(shape);
				});

				var allMaps = $(`#${svgId} > *`);
				allMaps.on("click", function() {
					selectedCrownNumber = $(this).attr("tag");
					var shownNumber = selectedCrownNumber = parseInt(selectedCrownNumber, 10);

					if ($('#btnUniversalMap').hasClass('map-selected')) {
						shownNumber = convertToUniversal(selectedCrownNumber);
						//alert(selectedCrownNumber);
					}

					$("#lbCrownNumber").html(`Selected number: ${shownNumber}`);
				});
			})
			.catch((error) => {
				console.log(`initSvgCircles ${jsonFile} error: ${error}`);
			});
	}

	function convertToUniversal(toothNumber) {
		var retval = toothNumber;
		if (toothNumber <= 18) {
			retval = 19 - toothNumber;
		} else if (toothNumber <= 28) {
			retval = toothNumber - 12;
		} else if (toothNumber <= 38) {
			retval = 55 - toothNumber;
		} else if (toothNumber <= 48) {
			retval = toothNumber - 16;
		}
		return retval;
	}

	export { initCrownMap };

</script>

<style scoped>
	@import "../../css/wizard.css";

	.modal-dialog {
		max-width: 400px;
	}

	.modal-header {
		/*height: 80px;*/
	}

	.modal-title {
		width: 200px;
		left: calc(50% - 100px);
	}

	.modal-body {
		height: 320px;
		overflow: auto;
		padding: 0;
		margin-top: 5px;
		margin-bottom: 0px;
		text-align: center;
	}

	#mapContainer {
		width: 310px;
		display: inline-flex;
	}

	#imgMap {
		width: 310px;
		height: 231px;
	}

	.svg-map {
		width: 310px;
		height: 231px;
		position: absolute;
	}

	#svgCircles {
		margin-left: -5px;
		width: 320px;
		height: 231px;
		position: absolute;
	}

	#lbCrownNumber {
		position: absolute;
		font-weight: bold;
		font-size: 18px;
		width: 200px;
		left: calc(50% - 100px);
		bottom: 5px;
		color: #0069d9;
		display: none;
	}

	.btn {
		width: 120px;
		/*text-align: center;*/
	}

	.map-selected {
		background-color: #deeaf6;
	}

	.shape {
		fill: transparent;
		stroke: transparent;
		stroke-width: 0;
	}
</style>
