<template>
	<div id="bottomPanel">
		<!-- <transition name="fade-angle-panel"> -->
		<!-- v-if="isAnglePanelVisible" -->
		<div id="anglePanel">
			<img title="Rotate -90" src="images/projections/-90.png" class="angle-button" v-on:click="rotateByAngle($event, -90)" />
			<img title="Rotate -45" src="images/projections/-45.png" class="angle-button" v-on:click="rotateByAngle($event, -45)" />
			<img title="Rotate 0" src="images/projections/0.png" class="angle-button" v-on:click="rotateByAngle($event, 0)" />
			<img title="Rotate 45" src="images/projections/45.png" class="angle-button" v-on:click="rotateByAngle($event, 45)" />
			<img title="Rotate 90" src="images/projections/90.png" class="angle-button" v-on:click="rotateByAngle($event, 90)" />
			<img title="Rotate 180" src="images/projections/180.png" class="angle-button" v-on:click="rotateByAngle($event, 180)" />
		</div>
		<!-- </transition> -->
		<img id="resetImg" title="Reset View" class="stl-button"
			src="images/reset.png" v-on:click="reset($event)" />
		<img id="globalResetImg" title="Global Reset" class="stl-button"
			src="images/GlobalReset.png" v-on:click="globalReset($event)" />
		<img id="rotateImg" title="Rotate View" class="stl-button"
			src="images/rotate.png" v-on:click="switchAutoRotate($event);" />
		<img id="anglePanelImg" title="Rotate By Angle" class="stl-button"
			src="images/angle_icon.png" v-on:click="toggleAnglePanel($event)" />
		<img id="clipImg" title="Clip Scene" class="stl-button"
			src="images/clip_icon.png" v-on:click="showClipDialog($event)" />
		<img id="measureImg" title="Measure" class="stl-button"
			src="images/measure_icon.png" v-on:click="enterMeasureModeClick($event)" />
		<img id="saveReportImg" title="Save File" class="stl-button"
			src="images/save_icon.png" v-on:click="showReportsDialog($event)" />
	</div>
</template>

<script>
import swal from "sweetalert";
import { degreesToRadians } from "./geometry.js";
import { MeasureMode, enterMeasureMode, leaveMeasureMode } from "./measure/MeasureMode.js";
import { resetClipData, showClipDialog } from "./reports/ClipDialog.vue";

export default {
	name: "BottomPanel",
	props: [],
	data() {
		return {
			isAnglePanelVisible: false
		};
	},
	methods: {
		bottomPanelClick: function(event, parameter) {
			if (event && event.srcElement && event.srcElement.title) {
				let eventTitle = `Bottom->${event.srcElement.title}`;
				// if(parameter) {
				// 	eventTitle += `->${parameter}`;
				// }
				gtag('event', eventTitle);
			}
		},
		switchAutoRotate: function (event) {
			this.bottomPanelClick(event, !vueApp.isAutoRotated);
			if (vueApp.isAutoRotated) {
				viewer.set_auto_rotate(false);
				vueApp.isAutoRotated = false;
			} else {
				viewer.set_auto_rotate(true);
				vueApp.isAutoRotated = true;
				if (event) event.stopPropagation();
			}
		},
		enterMeasureModeClick: function(event) {
			this.bottomPanelClick(event, !MeasureMode.isActive);
			if (MeasureMode.isActive) {
				leaveMeasureMode();
			} else {
				enterMeasureMode();
			}
		},
		globalReset: function(event) {
			this.bottomPanelClick(event);
			swal({
				text: "All changes will be lost. Are you sure?",
				buttons: {
					yes: { text: "Yes", value: true },
					no: { text: "No", value: false }
				}
			}).then(willDelete => {
				if (willDelete) {
					resetAll();
				}
			});
		},
		reset: function(event) {
			this.bottomPanelClick(event);
			resetView();
			resetTeeth(false);
		},
		toggleAnglePanel: function(event) {
			var isVisible = $("#anglePanel").css("display") != "none";
			this.bottomPanelClick(event, !isVisible);
			if (isVisible) {
				$("#anglePanel").fadeOut(); // .fadeOut(500) slideUp
			} else {
				// console.log("#anglePanel show");
				vueApp.leaveModes(false);
				$("#anglePanel").slideDown();
			}
		},
		rotateByAngle: function(event, angle) {
			this.bottomPanelClick(event);
			vueApp.lookAtPoint({ x: 0, y: 0, z: 0 });
			resetCameraUp();

			viewer.camera.position.x = 0;
			viewer.camera.position.y = 0;
			viewer.camera.position.z = defaultZoom;
			viewer.camera.position.applyQuaternion(
				new THREE.Quaternion().setFromAxisAngle(
					new THREE.Vector3(0, 1, 0), // The positive y-axis
					degreesToRadians(angle) // The amount of rotation to apply this time
				)
			);

			viewer.camera.updateProjectionMatrix();
			viewer.controls.update();
		},
		showClipDialog: function(event) {
			this.bottomPanelClick(event);
			showClipDialog();
		},
		showReportsDialog: function(event) {
			this.bottomPanelClick(event);
			$("#hintGetReport").html("Press button to get file");
			$("#reportsDialog").modal();
		}
	},
	mounted: function() {
		var stl_cont = document.getElementById("stl_cont");
		stl_cont.addEventListener("mouseup", viewerMouseUp, false);
	}
};

function resetAll() {
	resetClipData();

	$("#main_div").css("background-color", sceneBackgroundColor);
	viewer.scene.background = new THREE.Color(sceneBackgroundColor);

	vueApp.leaveModes();

	vueApp.implants.forEach(element => {
		viewer.remove_model(element.id);
	});
	vueApp.implants.splice(0, vueApp.implants.length);

	vueApp.crowns.forEach(element => {
		viewer.remove_model(element.id);
	});
	vueApp.crowns.splice(0, vueApp.crowns.length);
	vueApp.orthoStages.splice(0, vueApp.orthoStages.length);

	viewer.camera.lookAt(0, 0, 0);
	viewer.controls.target = new THREE.Vector3(0, 0, 0);
	resetCameraUp();
	viewer.camera.position.x = 0;
	viewer.camera.position.y = 0;
	viewer.camera.position.z = defaultZoom;
	viewer.camera.updateProjectionMatrix();
	viewer.controls.update();

	vueApp.bonesColor = vueApp.baseData.bonesColor;
	vueApp.bonesOpacity = vueApp.baseData.bonesOpacity;
	vueApp.teethColor = vueApp.baseData.teethColor;
	vueApp.teethOpacity = vueApp.baseData.teethOpacity;
	vueApp.isRandomTeethColor = false;

	if (opacityInitialized) {
		$("#bones_opacity").slider("value", vueApp.bonesOpacity);
		$("#teeth_opacity").slider("value", vueApp.teethOpacity);
	}

	resetTeeth(true);

	vueApp.bones.forEach(item => {
		var savedBone = vueApp.baseData.bones.find(function(data, index) {
			return data.name === item.name;
		});
		if (savedBone) {
			item.isVisible = savedBone.isVisible;
			item.color = savedBone.color;
		} else {
			item.color = vueApp.bonesColor;
		}
		itemVisibilityChanged(item, item.color, vueApp.bonesOpacity);
	});

	vueApp.dentations.forEach(item => {
		var savedDentation = vueApp.baseData.dentations.find(function(data, index) {
			return data.name === item.name;
		});
		if (savedDentation) {
			item.isVisible = savedDentation.isVisible;
		}
		//itemVisibilityChanged(item, item.color, vueApp.bonesOpacity);
	});
	vueApp.$emit('visibilityChanged', 'resetAll');

	vueApp.nerves.forEach(item => {
		var savedNerve = vueApp.baseData.nerves.find(function(data, index) {
			return data.name === item.name;
		});
		if (savedNerve) {
			item.isVisible = savedNerve.isVisible;
		}
		itemVisibilityChanged(item, vueApp.nervesColor);
	});

	vueApp.gingivae.forEach(item => {
		var saved = vueApp.baseData.gingivae.find(function(data, index) {
			return data.name === item.name;
		});
		if (saved) {
			item.isVisible = saved.isVisible;
		}
		itemVisibilityChanged(item, vueApp.gingivaeColor);
	});

	vueApp.airways.forEach(item => {
		var saved = vueApp.baseData.airways.find(function(data, index) {
			return data.name === item.name;
		});
		if (saved) {
			item.isVisible = saved.isVisible;
		}
		itemVisibilityChanged(item, vueApp.airwaysColor);
	});

	vueApp.saveData();
}

function resetTeeth(fullReset) {
	var savedGroups = vueApp.baseData.dentations.reduce(function(a, b) {
		return a.concat(b.groups);
	}, []);
	var savedTeeth = savedGroups.reduce(function(a, b) {
		return a.concat(b.teeth);
	}, []);

	vueApp.allTeeth.forEach(tooth => {
		var savedTooth = savedTeeth.find(function(data, index) {
			return data.filename === tooth.filename;
		});
		if (savedTooth) {
			tooth.isVisible = savedTooth.isVisible;
			if (fullReset) {
				tooth.color = savedTooth.color;
			}
		}

		if (tooth.randomColor == noRandomColor) {
			tooth.randomColor = null;
		}
		var color =
			vueApp.isRandomTeethColor && tooth.randomColor != null
				? tooth.randomColor
				: tooth.color;
		itemVisibilityChanged(tooth, color, vueApp.teethOpacity);

		var mesh = viewer.get_model_mesh(tooth.id);
		var savedPos = tooth.defaultPosition;
		mesh.position.set(savedPos.x, savedPos.y, savedPos.z);
		mesh.rotation.set(0, 0, 0);

		tooth.savedPosition = {
			x: mesh.position.x,
			y: mesh.position.y,
			z: mesh.position.z
		};
		tooth.savedRotation = {
			x: mesh.rotation.x,
			y: mesh.rotation.y,
			z: mesh.rotation.z
		};
	});
	vueApp.$emit('visibilityChanged', 'resetTeeth');
}

//#region AutoRotate

function viewerMouseUp() {
	viewer.controls.noRotate = false; // 3.2
	if (vueApp.isAutoRotated) {
		viewer.set_auto_rotate(false);
		vueApp.isAutoRotated = false;
	}
}

//#endregion
</script>

<style>
.fade-angle-panel-enter-active,
.fade-angle-panel-leave-active {
	transition: all 0.5s;
}
.fade-angle-panel-enter,
.fade-angle-panel-leave-to {
	transform: translateY(20px);
	opacity: 0;
}

#bottomPanel {
	width: 320px;
	position: absolute;
	left: calc(50% - 150px);
	bottom: 18px;
	z-index: 9;
	/* display: none; */
}

@media (max-width: 430px) {
	#bottomPanel {
		left: calc(50% - 130px);
	}
}

.stl-button {
	/*position: absolute;*/
	/*right: 15px;*/
	width: 30px;
	height: 30px;
	margin-left: 16px;
	cursor: pointer;
}

#saveReportImg {
	height: 25px;
	width: 25px;
}

#clipImg,
#measureImg {
	height: 27px;
	width: 27px;
}

#anglePanel {
	text-align: left;
	font-weight: bold;
	color: white;
	font-family: Montserrat, sans-serif;
	display: none;
	margin-left: 52px;
	margin-bottom: 10px;
}

.angle-button {
	width: 30px;
	height: 30px;
	margin-left: 8px;
	text-align: right;
	cursor: pointer;
}

.swal-modal {
	/*background-color: rgba(63,255,106,0.69);
		border: 3px solid white;*/
	border-radius: 0;
}

.swal-button {
	border-radius: 0;
	background-color: #4687fc;
	/*border: 1px solid #3e549a;*/
}

/* .snap-dg{
	background-color: white;
} */

.snap-dg .swal-footer {
	text-align: left;
}
</style>
