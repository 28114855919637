<template>
	<div id="colorModeDiv" class="color-mode-item">

		<div id="colorModeHint">Click a tooth to set its own color</div>

		<!-- v-on:click.stop="openSelectColorControl('bone_color')"  -->
		<button id="colorMaxillaButton" type="button" class="color-mode-item color-button"
			onclick="openColorPicker('Maxilla');">
			MAXILLA
		</button>
		<button id="colorMandibleButton" type="button" class="color-mode-item color-button"
			onclick="openColorPicker('Mandibula');">
			MANDIBLE
		</button>
		<button id="colorMaxTeethButton" type="button" class="color-mode-item color-button"
			onclick="openColorPicker('max_teeth');">
			MAX TEETH
		</button>
		<button id="colorMandTeethButton" type="button" class="color-mode-item color-button"
			onclick="openColorPicker('mand_teeth');">
			MAND TEETH
		</button>
		<button id="colorBackgroundButton" type="button" class="color-mode-item color-button"
			onclick="openColorPicker('background_color');">
			BACKGROUND
		</button>
		<div id="randomTeethColorDiv" class="color-mode-item toggle-div" v-on:click.stop="setRandomTeethColor($event)">
			<input id="randomTeethColorButton" type="checkbox" checked />
			<div id="randomTeethColorTitle" class="toggle-title">RANDOM TEETH COLOR</div>
		</div>

	</div>
</template>

<script>
import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";
Coloris.init();
Coloris({
	el: ".coloris",
	defaultColor: "#ffffff",
	theme: "default", // large, polaroid, pill (horizontal)
	themeMode: "light",
	focusInput: false,
	alpha: false,
	closeButton: true,
	closeLabel: "Apply",
	clearButton: true,
	clearLabel: "Cancel",
	swatches: [
		"#ffffff",
		"#000000",
		"#d0cecf", // b6ad90
		"#2a9d8f",
		"#8ac926",
		"#f72585",
		"#e9c46a",
		"#f4a261",
		"#e76f51",
		"#d62828",
		"#023e8a",
		"#0077b6",
		"#0096c7",
		"#90e0ef"
	]
});

export default {
	name: "ColorControls",
	props: [],
	data() {
		return {};
	},
	methods: {
		setRandomTeethColor: function (event) {
			vueApp.isRandomTeethColor = !vueApp.isRandomTeethColor;
			if (event) {
				switchToggle(event.currentTarget.id, vueApp.isRandomTeethColor);
			}
			if (vueApp.isRandomTeethColor) {
				updateRandomTeethColors(true);
			} else {
				vueApp.allTeeth.forEach(item => {
					if (item.isVisible) {
						item.color = vueApp.teethColor;
						viewer.set_color(
							item.id,
							item.color ? item.color : vueApp.teethColor
						);
					}
				});
			}
			vueApp.$emit('colorChanged', "randomTeethColor");
		}
	},
	mounted: function () {
		canecode_switch("#randomTeethColorButton");
	}
};
</script>

<style scoped>
#colorModeDiv {
	left: calc(50% - 175px);
	top: 10px;
	width: 450px;
}

#colorModeHint {
	width: 350px;
	left: calc(50% - 175px);
	/* background-color: transparent; */
}

.color-mode-item {
	position: absolute;
	text-align: center;
	z-index: 9;
	color: #ffffff;
	display: none;
}

.color-button {
	width: 75px;
	height: 30px;
	border: 0;
	cursor: pointer;
	font-size: 11px;
	background-color: #4687fc;
	z-index: 9;
	top: 40px;
}

#colorMaxillaButton {
	left: calc(50% - 275px);
}

#colorMandibleButton {
	left: calc(50% - 190px);
}

#colorMaxTeethButton {
	left: calc(50% - 105px);
	width: 95px;
}

#colorMandTeethButton {
	left: calc(50%);
	width: 95px;
}

#colorBackgroundButton {
	left: calc(50% + 105px);
	width: 95px;
}

#randomTeethColorDiv {
	left: calc(50% - 80px);
	top: 90px;
}

#randomTeethColorTitle {
	margin-left: -35px;
	font-size: small;
	font-weight: normal;
}
</style>