<template>
	<div id="measureControls" class="measure-mode-item">
		<label id="measureLabel">Distance: {{ editContext.distance }} mm</label>
		<button id="measureDoneButton" class="measure-button"
			v-on:click="exitMeasureMode()">
			DONE
		</button>
	</div>
</template>

<script>

import {
	leaveMeasureMode
} from "./MeasureMode.js";

export default {
	name: "MeasureControls",
	props: ["editContext"],
	components: {},
	methods: {
		exitMeasureMode: function() {
			leaveMeasureMode();
		}		
	}
};

</script>

<style scoped>
#measureControls {
	left: calc(50% - 200px);
	bottom: 0;
	display: none;
	width: 400px;
	height: 70px;
	position: absolute;
	/* background-color: red; */
}

/* #measureDoneButton {
	width: 90px;
} */

#measureLabel {
	width: 170px;
	/* margin-left: -40px; */
	left: calc(50% - 70px);
	text-align: center;
	color: #ffffff;
	position: absolute;
	bottom: 105px;
}

.measure-button {
	position: absolute;
	left: calc(50% - 30px);
	bottom: 70px;
	display: inline-block;
	min-width: 90px;
	width: auto;
	height: 35px;
	border: 0;
	cursor: pointer;
	font-size: small;
	background-color: #4687fc; /*#F7FAFF;*/
	text-align: center;
	color: #ffffff;
}

</style>