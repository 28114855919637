<template>
	<div id="clipDialog" data-backdrop="false" style="display: none;" class="modal-dialog modal-dialog-centered modal-sm"
		role="document">
		<div class="modal-content dialog-content">

			<div class="modal-body">
				<button id="btnCloseClipDialog" type="button" class="close" data-dismiss="modal" aria-label="Close"
					style="z-index: 9; width: 30px;">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="container-fluid">
					<div class="row row-grey">
						<div class="col-12">
							<div class="form-check">
								<input type="checkbox" class="form-check-input" id="enableClipCheck" @change="applyClip()"
									v-model="isEnabled">
								<label class="form-check-label" for="enableClipCheck">Enable</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<div class="form-check">
								<input type="radio" class="form-check-input" id="sagittalCheck" name="clipType"
									value="sagittal" @change="clipTypeChanged()" checked>
								<label class="form-check-label" for="sagittalCheck">Sagittal</label>
							</div>
						</div>
						<div class="col-4">
							<div class="form-check" id="axialDiv">
								<input type="radio" class="form-check-input" id="axialCheck" name="clipType" value="axial"
									@change="clipTypeChanged()">
								<label class="form-check-label" for="axialCheck">Axial</label>
							</div>
						</div>
						<div class="col-4">
							<div class="form-check">
								<input type="radio" class="form-check-input" id="coronalCheck" name="clipType"
									value="coronal" @change="clipTypeChanged()">
								<label class="form-check-label" for="coronalCheck">Coronal</label>
							</div>
						</div>
					</div>
					<div class="container-group">
						<div class="row">
							<div class="col-12">
								<div class="form-check">
									<input type="checkbox" class="form-check-input" id="flipClipCheck" @change="applyClip()"
										v-model="currentData.isFlipped">
									<label class="form-check-label" for="flipClipCheck">Flip</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<input id="clipRange" type="range" class="form-range" min="-0.5" max="0.5" step="0.01"
									@input="applyClip()" v-model="currentData.rangeValue">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<button id="btnResetClip" class="btn btn-primary" aria-label="Reset" v-on:click="resetClip()"
								data-clipboard-text="shortLink">RESET</button>
						</div>
						<div class="col-6">
							<button id="btnSaveClip" class="btn btn-primary" aria-label="Save" v-on:click="saveClip()"
								data-clipboard-text="shortLink">SAVE</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { saveViewerData } from "../saveLoadData";

// import { getFullBox } from "../occlusal/OcclusalTools";
const sagittalPlane = new THREE.Plane(new THREE.Vector3(-1, 0, 0), 0);
const axialPlane = new THREE.Plane(new THREE.Vector3(0, -1, 0), 0);
const coronalPlane = new THREE.Plane(new THREE.Vector3(0, 0, -1), 0);
const globalPlanes = [sagittalPlane, axialPlane, coronalPlane];
const empty = Object.freeze([]);
let fullBox = null;
let sagittalData = {
	name: 'sagittalData',
	isFlipped: false,
	normalX: 1,
	normalY: 0,
	normalZ: 0,
	rangeValue: 0.5,
	plane: sagittalPlane
};
let axialData = {
	name: 'axialData',
	isFlipped: false,
	normalX: 0,
	normalY: 1,
	normalZ: 0,
	rangeValue: 0.5,
	plane: axialPlane
};
let coronalData = {
	name: 'coronalData',
	isFlipped: false,
	normalX: 0,
	normalY: 0,
	normalZ: 1,
	rangeValue: 0.5,
	plane: coronalPlane
};
const globalPlanesData = [sagittalData, axialData, coronalData];

export default {
	name: "ClipDialog",
	props: [],
	data() {
		return {
			isEnabled: true,
			currentData: {
				isFlipped: false,
				rangeValue: 0.5
			}
		};
	},
	methods: {
		clipTypeChanged: function () {
			var clipType = $('input[name="clipType"]:checked').val();
			switch (clipType) {
				case "sagittal":
					this.currentData = sagittalData;
					break;
				case "axial":
					this.currentData = axialData;
					break;
				case "coronal":
					this.currentData = coronalData;
					break;
			}
			if (fullBox == null) {
				$("#flipClipCheck").prop('checked', this.currentData.isFlipped);
				this.applyClip();
			}
		},
		applyClip: function () {
			var constant = 0;
			var normalValue = -1;
			this.isEnabled = $("#enableClipCheck").is(":checked");
			if (fullBox == null) {
				fullBox = defaultBox; //getFullBox();
				if (!this.currentData.plane) {
					this.currentData = sagittalData;
					this.currentData.isFlipped = $("#flipClipCheck").is(":checked");
					this.currentData.rangeValue = $("#clipRange").val();
				}

				updatePlane(sagittalData);
				updatePlane(axialData);
				updatePlane(coronalData);
			}

			if (this.isEnabled) {
				var clipType = $('input[name="clipType"]:checked').val();
				var isFlip = this.currentData.isFlipped;
				var rangeVal = this.currentData.rangeValue;
				normalValue = isFlip ? 1 : -1;
				if (isFlip) {
					rangeVal = -rangeVal;
				}
				switch (clipType) {
					case "sagittal":
						constant = fullBox.center.x + rangeVal * fullBox.size.x;
						sagittalPlane.set(new THREE.Vector3(normalValue, 0, 0), constant);
						break;
					case "axial":
						constant = fullBox.center.y + rangeVal * fullBox.size.y;
						axialPlane.set(new THREE.Vector3(0, normalValue, 0), constant);
						break;
					case "coronal":
						constant = fullBox.center.z + rangeVal * fullBox.size.z;
						coronalPlane.set(new THREE.Vector3(0, 0, normalValue), constant);
						break;
				}
			}
			viewer.renderer.clippingPlanes = this.isEnabled ? globalPlanes : empty;
		},
		saveClip: function () {
			this.applyClip();
			$("#clipDialog").modal("hide");

			updateSavedData();
			vueApp.savedData.clipData = {
				isEnabled: this.isEnabled,
				sagittalData: {
					isFlipped: sagittalData.isFlipped,
					rangeValue: sagittalData.rangeValue
				},
				axialData: {
					isFlipped: axialData.isFlipped,
					rangeValue: axialData.rangeValue
				},
				coronalData: {
					isFlipped: coronalData.isFlipped,
					rangeValue: coronalData.rangeValue
				},
			};

			saveViewerData();
		},
		resetClip: function () {
			this.currentData.isFlipped = false;
			this.currentData.rangeValue = 0.5;
			this.applyClip();
		}
	}
};

function showClipDialog() {
	if (vueApp.activeMode && vueApp.activeMode.isMeasureMode) {
		vueApp.leaveModes();
	}

	$("#clipDialog").modal();
}

function applyClipData(clipData) {
	if (clipData) {
		viewer.renderer.clippingPlanes = clipData.isEnabled ? globalPlanes : empty;

		sagittalData.isFlipped = clipData.sagittalData.isFlipped;
		sagittalData.rangeValue = clipData.sagittalData.rangeValue;
		updatePlane(sagittalData);

		axialData.isFlipped = clipData.axialData.isFlipped;
		axialData.rangeValue = clipData.axialData.rangeValue;
		updatePlane(axialData);

		coronalData.isFlipped = clipData.coronalData.isFlipped;
		coronalData.rangeValue = clipData.coronalData.rangeValue;
		updatePlane(coronalData);

		$("#clipRange").val(sagittalData.rangeValue);
		$("#flipClipCheck").prop('checked', sagittalData.isFlipped);
		$("#enableClipCheck").prop('checked', clipData.isEnabled);
	}
}

function resetClipData() {
	vueApp.savedData.clipData = {
		isEnabled: true,
		sagittalData: {
			isFlipped: false,
			rangeValue: 0.5
		},
		axialData: {
			isFlipped: false,
			rangeValue: 0.5
		},
		coronalData: {
			isFlipped: false,
			rangeValue: 0.5
		},
	};
	applyClipData(vueApp.savedData.clipData);
}

function updatePlane(data) {
	if (data && defaultBox) {
		let rangeVal = data.rangeValue;
		if (data.isFlipped) {
			rangeVal = -rangeVal;
		}

		let constant = rangeVal * (defaultBox.size.x * data.normalX
			+ defaultBox.size.y * data.normalY
			+ defaultBox.size.z * data.normalZ);
		constant += (defaultBox.center.x * data.normalX
			+ defaultBox.center.y * data.normalY
			+ defaultBox.center.z * data.normalZ);

		let normalValue = data.isFlipped ? 1 : -1;
		let normalX = data.normalX * normalValue;
		let normalY = data.normalY * normalValue;
		let normalZ = data.normalZ * normalValue;
		data.plane.set(new THREE.Vector3(normalX, normalY, normalZ), constant);
	}
}

function fillBones() {
	// Set up clip plane rendering
	let planeObjects = [];
	const planeGeom = new THREE.PlaneGeometry(400, 400);

	let allMeshes = vueApp.allMeshes;
	for (let i = 0; i < 3; i++) {
		const plane = globalPlanes[i];
		for (const mesh of allMeshes) {
			const stencilGroup = createPlaneStencilGroup(
				mesh.geometry,
				plane,
				i + 1
			);
			viewer.scene.add(stencilGroup);
		}

		// plane is clipped by the other clipping planes
		const planeMat = new THREE.MeshStandardMaterial({
			color: 0xe91e63,
			// metalness: 0.1,
			// roughness: 0.75,
			clippingPlanes: globalPlanes.filter(p => p !== plane),

			stencilWrite: true,
			stencilRef: 0,
			stencilFunc: THREE.NotEqualStencilFunc,
			stencilFail: THREE.ReplaceStencilOp,
			stencilZFail: THREE.ReplaceStencilOp,
			stencilZPass: THREE.ReplaceStencilOp
		});

		const po = new THREE.Mesh(planeGeom, planeMat);
		po.onAfterRender = function (renderer) {
			viewer.renderer.clearStencil();
		};
		po.renderOrder = i + 1.1;
		planeObjects.push(po);
		viewer.scene.add(po);
	}

	let tempAnimate = viewer.animate;
	viewer.animate = function () {
		for (let i = 0; i < planeObjects.length; i++) {
			const plane = globalPlanes[i];
			const po = planeObjects[i];
			plane.coplanarPoint(po.position);
			po.lookAt(
				po.position.x - plane.normal.x,
				po.position.y - plane.normal.y,
				po.position.z - plane.normal.z
			);
		}

		tempAnimate();
	};
}

function createPlaneStencilGroup(geometry, plane, renderOrder) {
	const group = new THREE.Group();
	const baseMat = new THREE.MeshBasicMaterial();
	baseMat.depthWrite = false;
	baseMat.depthTest = false;
	baseMat.colorWrite = false;
	baseMat.stencilWrite = true;
	baseMat.stencilFunc = THREE.AlwaysStencilFunc;

	// back faces
	const mat0 = baseMat.clone();
	mat0.side = THREE.BackSide;
	mat0.clippingPlanes = [plane];
	mat0.stencilFail = THREE.IncrementWrapStencilOp;
	mat0.stencilZFail = THREE.IncrementWrapStencilOp;
	mat0.stencilZPass = THREE.IncrementWrapStencilOp;

	const mesh0 = new THREE.Mesh(geometry, mat0);
	mesh0.renderOrder = renderOrder;
	group.add(mesh0);

	// front faces
	const mat1 = baseMat.clone();
	mat1.side = THREE.FrontSide;
	mat1.clippingPlanes = [plane];
	mat1.stencilFail = THREE.DecrementWrapStencilOp;
	mat1.stencilZFail = THREE.DecrementWrapStencilOp;
	mat1.stencilZPass = THREE.DecrementWrapStencilOp;

	const mesh1 = new THREE.Mesh(geometry, mat1);
	mesh1.renderOrder = renderOrder;

	group.add(mesh1);

	return group;
}

export {
	applyClipData, resetClipData, showClipDialog, updatePlane,
	globalPlanesData, sagittalData, axialData, coronalData, globalPlanes, empty
};
</script> 

<style scoped>
.modal {
	overflow: hidden;
}

.container-group {
	border: 2px solid #d7dee5;
	margin: 10px -5px 0 -5px;
	padding: 0 10px;
}

.modal-dialog {
	max-width: 400px;
	position: fixed;
	bottom: 5px;
	left: calc(50% - 180px);
	z-index: 9;
}

.dialog-content {
	background-color: #ffffff;
}

.close {
	margin-right: -8px;
	margin-top: -10px;
}

.row {
	margin-top: 20px;
}

.btn {
	width: 100%;
	border-radius: 0px;
	height: 40px;
	background-color: #0069d9;
}

#clipRange {
	width: 100%;
}

#axialDiv {
	margin-left: 12px;
}
</style>
