import {
  getFiguresForModel,
  getDrawBox2D,
  setupClippingPlane,
  getAllFiguresForModel,
  getFigurePerimeter,
} from "./slice";
import {
  ToothNumberMode,
  getNumberChanges,
} from "../tooth/number/ToothNumberMode";
import { runSmoothing } from "./smoothing";

import { sendAddToothRequest, sendSmoothStlRequest } from "@/api/toothApi";
import { sendUpdateTeethRequest } from "@/api/toothApi";

import * as THREE from "three";
import { STLExporter } from "three/addons/exporters/STLExporter.js";
import { ConvexGeometry } from "three/addons/geometries/ConvexGeometry.js";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { MarchingCubes } from "three/addons/objects/MarchingCubes.js";

const annotationShapeName = "annotationShape";
const anchorShapeName = "anchorShape";
const clippingPlane = new THREE.Plane();
const anchorPointsMaxD = 2;
const maxMarchingCubesPolyCount = 300000; //300000;
const meshBasicMaterial = new THREE.MeshBasicMaterial({
  color: 0xffff00,
  wireframe: true,
  side: 2,
});

function calculateAllFigures(app, dicomBox) {
  if (app && !app.isCalculated && app.annotations && app.annotations.length) {
    const drawBox2D = getDrawBox2D(dicomBox, app.index);
    const coefX = app.baseSize.x / drawBox2D.size.x;
    const coefY = app.baseSize.y / drawBox2D.size.y;

    // let annotations = app.allAnnotations ? app.allAnnotations : app.annotations;
    let annotations = app.annotations;
    annotations = annotations.filter((a) => a.isTooth || a.isBone);
    console.log("start calculateAllFigures", new Date(), annotations);
    annotations.forEach((annotation) => {
      let allFigures = getAllFiguresForModel(annotation.model, app, dicomBox);
      annotation.allFigures = allFigures;
      annotation.allAnnotationPoints = [];
      for (var sliceIndex in annotation.allFigures) {
        let sli = +sliceIndex;
        let figures = annotation.allFigures[sliceIndex];
        if (figures) {
          figures.forEach((figure) => {
            fillKonvaPoints(figure, app, drawBox2D, coefX, coefY);
            figure.annotationPoints = getAnnotationPoints(
              figure,
              app,
              drawBox2D,
              coefX,
              coefY
            );
            figure.annotationPoints.forEach((p) => (p.z = sli - 1));
            annotation.allAnnotationPoints.push(
              ...figure.annotationPoints.values()
            );
          });
        }
      }
      if (annotation.allAnnotationPoints.length) annotation.calculated = true;
    });
    console.log("end calculateAllFigures", new Date());
    app.isCalculated = true;
  }
}

function drawAnnotations(app, dicomBox) {
  if (app && dicomBox) {
    destroyAnnotationShapes(app);

    if ((app.parentApp && !app.showSegmentation) || !app.annotations) {
      return;
    }

    if (!app.parentApp && !app.isCalculated) {
      calculateAllFigures(app, dicomBox);
    }

    const drawBox2D = getDrawBox2D(dicomBox, app.index);
    let sliceIndex = +app.current;
    setupClippingPlane(clippingPlane, app, dicomBox, sliceIndex);
    let dy = app.index != 0 ? app.worldSize.y - dicomBox.size.y : 0;
    const coefX = app.baseSize.x / drawBox2D.size.x;
    const coefY = app.baseSize.y / drawBox2D.size.y;
    // let annotations = app.allAnnotations ? app.allAnnotations : app.annotations;
    let annotations = app.annotations;
    annotations = annotations.filter((a) => a.isTooth || a.isBone);
    annotations.forEach((annotation, index) => {
      annotation.figures = [];
      if (!app.parentApp && !annotation.allAnnotationPoints) {
        annotation.allAnnotationPoints = [];
      }
      if (!annotation.allFigures) {
        annotation.allFigures = {};
      }

      if (
        annotation.isHidden ||
        (annotation.parentAnnotation && annotation.parentAnnotation.isHidden)
      ) {
        annotation.hasFigures = false;
        return;
      }

      let figures = app.parentApp
        ? getFiguresFromParentAnnotation(annotation, sliceIndex)
        : annotation.allFigures[sliceIndex];
      if (!figures)
        figures = getFiguresForModel(
          annotation.model,
          clippingPlane,
          app,
          sliceIndex,
          false,
          dy
        );

      if (figures && figures.length) {
        let a_figures = [];
        figures.forEach((figure) => {
          if (!figure.konvaPoints)
            fillKonvaPoints(figure, app, drawBox2D, coefX, coefY);

          if (!figure.annotationPoints) {
            figure.annotationPoints = getAnnotationPoints(
              figure,
              app,
              drawBox2D,
              coefX,
              coefY
            );
          }

          let a_figure = {
            filename: annotation.filename,
            color: annotation.opacityColor,
            konvaPoints: figure.konvaPoints,
            annotationPoints: figure.annotationPoints,
            annotation: annotation,
            isStraight: figure.isStraight,
          };
          a_figures.push(a_figure);
        });
        annotation.figures = a_figures;
        annotation.hasFigures = true;
      } else {
        annotation.figures = [];
        annotation.hasFigures = false;
      }
      annotation.allFigures[sliceIndex] = annotation.figures;
    });

    app.setToolFeatures({
      annotations: annotations,
    });
  }
}

function getFiguresFromParentAnnotation(annotation, sliceIndex) {
  let figures = [];
  if (
    annotation &&
    annotation.parentAnnotation &&
    annotation.parentAnnotation.allAnnotationPoints &&
    sliceIndex
  ) {
    let parent = annotation.parentAnnotation;
    sliceIndex -= 1;
    let figurePoints = [];
    if (annotation.appIndex == 0) {
      figurePoints = parent.allAnnotationPoints.filter(
        (p) => p.z == sliceIndex
      );
    } else if (annotation.appIndex == 1) {
      figurePoints = parent.allAnnotationPoints
        .filter((p) => p.x == sliceIndex)
        .map((p) => {
          return { x: p.y, y: p.z };
        });
    } else if (annotation.appIndex == 2) {
      figurePoints = parent.allAnnotationPoints
        .filter((p) => p.y == sliceIndex)
        .map((p) => {
          return { x: p.x, y: p.z };
        });
    }
    if (figurePoints.length > 1) {
      let figure = {
        filename: annotation.filename,
        vertices: [],
        konvaPoints: [],
        annotationPoints: figurePoints,
      };
      figures.push(figure);
    }
  }
  return figures;
}

function destroyAnnotationShapes(app) {
  const layerGroup = app.getActiveLayerGroup();
  const drawLayer = layerGroup.getActiveDrawLayer();
  const konvaLayer = drawLayer.getKonvaLayer();
  let annotationShapes = konvaLayer
    .find("." + anchorShapeName)
    .concat(konvaLayer.find("." + annotationShapeName));
  annotationShapes.forEach((shape) => {
    shape.destroy();
  });
}

function getPlaneConstant(app, sliceIndex) {
  let constant = 0;
  let dicomBox = app.dicomBox;
  let offset = (sliceIndex - 1) / (app.max - 1);
  if (app.index == 0) {
    constant = dicomBox.min.y + dicomBox.size.y * offset;
  }
  if (app.index == 1) {
    constant = dicomBox.min.x + dicomBox.size.x * offset;
  }
  if (app.index == 2) {
    offset = (app.max - sliceIndex) / app.max;
    constant = dicomBox.min.z + dicomBox.size.z * offset;
  }
  return constant;
}

function convertToBezierPoint(p, coefX, coefY, drawBox, appIndex, normal) {
  if (drawBox) {
    let newX = p.x / coefX;
    let newY = p.y / coefY;

    newX = appIndex == 1 ? drawBox.max.x - newX : drawBox.min.x + newX;
    newY = appIndex == 0 ? drawBox.max.y - newY : drawBox.min.y + newY;

    let point = {
      x: newX,
      y: newY,
    };
    // let vector = null;
    return point;
  }
}

function convertToKonvaPoint(p, coefX, coefY, drawBox, appIndex) {
  if (drawBox) {
    let newX = appIndex == 1 ? drawBox.max.x - p.x : p.x - drawBox.min.x;
    let newY = appIndex == 0 ? drawBox.max.y - p.y : p.y - drawBox.min.y;
    //-p.y - drawBox.min.y;

    let point = {
      x: newX * coefX,
      y: newY * coefY,
    };
    return point;
  }
}

function getControlPoints(x0, y0, x1, y1, x2, y2, t) {
  //  x0,y0,x1,y1 are the coordinates of the end (knot) pts of this segment
  //  x2,y2 is the next knot -- not connected here but needed to calculate p2
  //  p1 is the control point calculated here, from x1 back toward x0.
  //  p2 is the next control point, calculated here and returned to become the
  //  next segment's p1.
  //  t is the 'tension' which controls how far the control points spread.

  //  Scaling factors: distances from this knot to the previous and following knots.
  var d01 = Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
  var d12 = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

  var fa = (t * d01) / (d01 + d12);
  var fb = t - fa;

  var p1x = x1 + fa * (x0 - x2);
  var p1y = y1 + fa * (y0 - y2);

  var p2x = x1 - fb * (x0 - x2);
  var p2y = y1 - fb * (y0 - y2);

  return [p1x, p1y, p2x, p2y];
}

function hexToCanvasColor(hexColor, opacity) {
  opacity = opacity || "1.0";
  hexColor = hexColor.replace("#", "");
  var r = parseInt(hexColor.substring(0, 2), 16);
  var g = parseInt(hexColor.substring(2, 4), 16);
  var b = parseInt(hexColor.substring(4, 6), 16);
  return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
}

function fillKonvaPoints(figure, app, drawBox, coefX, coefY) {
  if (figure && !figure.konvaPoints) {
    figure.konvaPoints = [];
    if (!app.parentApp) {
      let verticePoints = [];
      if (!coefX) coefX = app.baseSize.x / drawBox.size.x;
      if (!coefY) coefY = app.baseSize.x / drawBox.size.x;
      // let spacingY = app.spacing[1];

      if (app.index == 0) {
        if (!figure.perimeter) {
          figure.perimeter = getFigurePerimeter(figure);
        }
        const requiredDistance = Math.min(
          figure.perimeter / 4,
          anchorPointsMaxD
        );
        let distance = 0;
        figure.vertices.forEach((p, index) => {
          if (distance >= requiredDistance) {
            verticePoints.push(p);
            distance = 0;
          }
          distance += p.distance;
        });
        if (distance >= requiredDistance / 2) {
          verticePoints.push(figure.vertices[0]);
        }

        verticePoints.forEach((p) =>
          figure.konvaPoints.push(
            convertToKonvaPoint(p, coefX, coefY, drawBox, app.index)
          )
        );
      } else {
        figure.isStraight = true;
        let prevY = Infinity;
        figure.vertices.forEach((p, index) => {
          let kp = convertToKonvaPoint(p, coefX, coefY, drawBox, app.index);
          // let rem = kp.y % spacingY;
          kp.y = Math.floor(kp.y);
          if (kp.y !== prevY) {
            if (prevY != Infinity) {
              let mp = kp;
              let diff = Math.abs(prevY - mp.y);
              while (diff > 1) {
                let midY = prevY < mp.y ? mp.y - 1 : mp.y + 1;
                mp = { x: mp.x, y: midY };
                figure.konvaPoints.push(mp);
                diff = Math.abs(prevY - mp.y);
              }
            }
            figure.konvaPoints.push(kp);
            prevY = kp.y;
          }
        });
      }
    }
  }
}

function getAnnotationData(annotation, app) {
  let modelGeometry = getAnnotationGeometry(annotation, app);
  if (!modelGeometry) return null;

  let vmesh = new THREE.Mesh(modelGeometry);
  const stlExporter = new STLExporter();
  // const data = stlExporter.parse(mesh);
  // const blob = new Blob([data], { type: 'text/plain' });
  const data = stlExporter.parse(vmesh, { binary: true });
  const blob = new Blob([data], { type: "application/octet-stream" });
  return blob;
}

function getAnnotationGeometry(annotation, app) {
  let modelGeometry = null;
  let meshes = getAnnotationMeshes(annotation, app);
  if (meshes && meshes.length) {
    let geometries = [];
    meshes.forEach((b) => {
      b.updateMatrixWorld();
      let geometry = b.geometry;
      if (geometry.index) geometry = geometry.toNonIndexed();
      geometry.applyMatrix4(b.matrixWorld);
      geometry.deleteAttribute("uv");
      geometry.attributes.position.gpuType = 1015;
      geometry.attributes.normal.gpuType = 1015;
      geometries.push(geometry);
    });

    modelGeometry = BufferGeometryUtils.mergeGeometries(geometries, true);
    modelGeometry.deleteAttribute("normal");
    modelGeometry = BufferGeometryUtils.mergeVertices(modelGeometry); // , 1
    modelGeometry.computeVertexNormals();
  }
  return modelGeometry;
}

function getAnnotationMeshes(annotation, app) {
  let meshes = [];

  let dicomBox = app.dicomBox;
  if (
    !annotation.allFigures ||
    (!annotation.isAdded && !annotation.calculated)
  ) {
    let allFigures = getAllFiguresForModel(annotation.model, app, dicomBox);
    if (annotation.allFigures) {
      for (var sliceIndex in annotation.allFigures) {
        allFigures[sliceIndex] = annotation.allFigures[sliceIndex];
      }
    }
    annotation.allFigures = allFigures;
    annotation.calculated = true;
  }

  const drawBox2D = getDrawBox2D(dicomBox, app.index);
  const coefX = app.baseSize.x / drawBox2D.size.x;
  const coefY = app.baseSize.y / drawBox2D.size.y;

  let annotationPoints = [];
  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity,
    minZ = Infinity,
    maxZ = -Infinity;
  for (var sliceIndex in annotation.allFigures) {
    // console.log("!!! sliceIndex", sliceIndex);
    let sliceY = getPlaneConstant(app, +sliceIndex);
    let figures = annotation.allFigures[sliceIndex];
    if (!figures) continue;
    for (const figure of figures) {
      fillKonvaPoints(figure, app, drawBox2D, coefX, coefY);

      if (figure.konvaPoints) {
        // figure.bezierPoints = [];
        // figure.konvaPoints.forEach((point) => {
        //   figure.bezierPoints.push(
        //     convertToBezierPoint(point, coefX, coefY, drawBox2D, app.index)
        //   );
        // });
        // let mesh = getExtrudeMesh(figure, sliceY);
        // if (mesh) meshes.push(mesh);

        if (!figure.annotationPoints) {
          figure.annotationPoints = getKonvaAnnotationPoints(figure);
        }

        figure.annotationPoints.forEach((point) => {
          if (point) {
            let voxelPoint = convertToBezierPoint(
              point,
              coefX,
              coefY,
              drawBox2D,
              app.index
            );
            let x = voxelPoint.x;
            let y = sliceY;
            let z = voxelPoint.y;
            if (x && y && z) {
              annotationPoints.push({ x, y, z });
              if (x < minX) minX = x;
              if (y < minY) minY = y;
              if (z < minZ) minZ = z;
              if (x > maxX) maxX = x;
              if (y > maxY) maxY = y;
              if (z > maxZ) maxZ = z;
            } else {
              console.log("convertToBezierPoint", point, voxelPoint);
            }
          }
        });
        // annotationPoints = annotationPoints.concat(points);
      }
    }
  }
  let figureBB = {
    min: { x: minX, y: minY, z: minZ },
    max: { x: maxX, y: maxY, z: maxZ },
  };
  setBoundingBoxCenter(figureBB);
  // drawBox(figureBB);

  let effect = new MarchingCubes(
    200,
    meshBasicMaterial,
    false,
    false,
    maxMarchingCubesPolyCount
  );
  // effect.init(200);
  // effect.reset();

  // let geoms = [];
  // let simpleGeometry = new THREE.BoxGeometry(0.25, 0.25, 0.25);

  let spCoef = 1 / app.spacing[0];

  annotationPoints.forEach((point) => {
    let x = Math.round((point.x - minX) * spCoef) + 2;
    let y = Math.round((point.y - minY) * spCoef) + 2;
    let z = Math.round((point.z - minZ) * spCoef) + 2;
    effect.setCell(x, y, z, 10000);

    // let voxelGeometry = simpleGeometry
    //   .clone()
    //   .translate(point.x, point.y, -point.z);
    // geoms.push(voxelGeometry);
  });
  effect.update();

  // console.log("MarchingCubes", effect);
  let marchingGeometry = effect.geometry.clone();
  marchingGeometry.deleteAttribute("normal");
  marchingGeometry.deleteAttribute("uv");
  let positionData = marchingGeometry.attributes.position.array;
  let positionLength = positionData.length;
  let zeroIndex = positionData.indexOf(0);

  while (
    zeroIndex > 0 &&
    zeroIndex < positionLength - 1 &&
    positionData[zeroIndex + 1] != 0
  ) {
    zeroIndex = positionData.indexOf(0, zeroIndex + 1);
  }
  if (zeroIndex > 0) {
    positionData = positionData.subarray(0, zeroIndex);
  }

  positionData = positionData.filter(function (value) {
    return !Number.isNaN(value);
  });

  marchingGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(positionData, 3)
  );

  marchingGeometry = BufferGeometryUtils.mergeVertices(marchingGeometry);
  marchingGeometry.computeVertexNormals();
  marchingGeometry.normalizeNormals();
  marchingGeometry.computeBoundingBox();

  let marchingBB = marchingGeometry.boundingBox.clone();
  setBoundingBoxCenter(marchingBB);
  // drawBox(marchingBB);

  let coef = figureBB.size.x / marchingBB.size.x;
  console.log("bb coef", coef);

  marchingGeometry.translate(
    -marchingBB.center.x,
    -marchingBB.center.y,
    -marchingBB.center.z
  );
  marchingGeometry.scale(coef, coef, coef);
  marchingGeometry.translate(
    figureBB.center.x,
    figureBB.center.y,
    figureBB.center.z
  );

  // let meshGeometry = BufferGeometryUtils.mergeGeometries(geoms);
  // const mesh = new THREE.Mesh(meshGeometry, meshBasicMaterial);
  // viewer.scene.add(mesh);

  // const vmesh = new THREE.Mesh(marchingGeometry, meshBasicMaterial);
  // viewer.scene.add(vmesh);
  // drawBox(marchingGeometry.boundingBox);

  let smoothedGeom = marchingGeometry;
  let factor = annotation.smoothFactor ? +annotation.smoothFactor : 1;
  // factor -= 1;
  factor += 3;
  if (factor > 0) {
    smoothedGeom = runSmoothing(marchingGeometry, factor);
  }
  const vmesh = new THREE.Mesh(smoothedGeom, meshBasicMaterial);
  meshes.push(vmesh);

  return meshes;
}

function setBoundingBoxCenter(bb) {
  bb.size = {
    x: bb.max.x - bb.min.x,
    y: bb.max.y - bb.min.y,
    z: bb.max.z - bb.min.z,
  };

  bb.center = {
    x: bb.min.x + bb.size.x / 2,
    y: bb.min.y + bb.size.y / 2,
    z: bb.min.z + bb.size.z / 2,
  };
}

function getAnnotationPoints(figure, app, drawBox, coefX, coefY) {
  let annotationPoints = new Map();
  figure.vertices.forEach((p) => {
    let kp = convertToKonvaPoint(p, coefX, coefY, drawBox, app.index);
    let x = Math.round(kp.x);
    let y = Math.round(kp.y);
    const str = `${x},${y}`;
    annotationPoints.set(str, { x, y });
  });
  fillAnnotationPoints(annotationPoints);
  fillAnnotationPoints(annotationPoints);
  return annotationPoints;
}

function getAnnotationPointsNew(figure, app, drawBox, coefX, coefY) {
  let prevX = Infinity;
  let prevY = Infinity;
  let annotationPoints = new Map();
  if (figure.vertices) {
    figure.vertices.forEach((p) => {
      let kp = convertToKonvaPoint(p, coefX, coefY, drawBox, app.index);
      let x = Math.floor(kp.x); // floor round
      let y = Math.floor(kp.y);
      const str = `${x},${y}`;

      if (
        (x !== prevX && prevX != Infinity) ||
        (y !== prevY && prevY != Infinity)
      ) {
        let diffX = Math.abs(prevX - x);
        let diffY = Math.abs(prevY - y);

        if (diffX > 30 || diffY > 30) {
          console.log(
            `figure: ${figure.filename}, diffX - ${diffX}, diffY - ${diffY}`
          );

          // ctx.fillStyle = "green";
          // ctx.fillRect(prevX, prevY, 1, 1);
          // ctx.fillStyle = "red";
          // ctx.fillRect(x, y, 1, 1);
        }

        let px = prevX;
        let py = prevY;

        // while (diffY > 1) {
        while (diffX > 1 || diffY > 1) {
          if (diffX > 1) px = px > x ? px - 1 : px + 1;
          if (diffY > 1) py = py > y ? py - 1 : py + 1;

          const str = `${px},${py}`;
          annotationPoints.set(str, { x: px, y: py });

          diffX = Math.abs(px - x);
          diffY = Math.abs(py - y);
        }
      }

      let ap = { x, y };
      annotationPoints.set(str, ap);

      prevX = x;
      prevY = y;
    });
    fillAnnotationPoints(annotationPoints);
  }
  return annotationPoints;
}

function getKonvaAnnotationPoints(figure) {
  let pts = [];
  figure.konvaPoints.forEach((konvaPoint) => {
    pts.push(konvaPoint.x, konvaPoint.y);
  });

  let cp = [];
  let length = pts.length;
  pts.push(pts[0], pts[1], pts[2], pts[3]);
  pts.unshift(pts[length - 1]);
  pts.unshift(pts[length - 1]);
  for (var i = 0; i < length; i += 2) {
    cp = cp.concat(
      getControlPoints(
        pts[i],
        pts[i + 1],
        pts[i + 2],
        pts[i + 3],
        pts[i + 4],
        pts[i + 5],
        0.5
      )
    );
  }
  cp = cp.concat(cp[0], cp[1]);
  let annotationPoints = new Map();
  for (let i = 2, j = 0; i < length + 2; i += 2, j++) {
    let kp = figure.konvaPoints[j];
    kp.p0 = { x: pts[i], y: pts[i + 1] };
    kp.p1 = { x: cp[2 * i - 2], y: cp[2 * i - 1] };
    kp.p2 = { x: cp[2 * i], y: cp[2 * i + 1] };
    kp.p3 = { x: pts[i + 2], y: pts[i + 3] };

    let kpNext = figure.konvaPoints[j + 1];
    if (!kpNext) {
      kpNext = figure.konvaPoints[0];
    }

    let width = Math.abs(kp.x - kpNext.x);
    let height = Math.abs(kp.y - kpNext.y);
    let max = Math.max(width, height);
    let splitPoints = getSplitPoints(kp, max * 2);
    splitPoints.forEach((p) => {
      let x = Math.floor(p.x);
      let y = Math.floor(p.y);
      const str = `${x},${y}`;
      annotationPoints.set(str, { x, y });
    });
  }
  fillAnnotationPoints(annotationPoints);
  return annotationPoints;
}

function fillAnnotationPoints(annotationPoints) {
  if (annotationPoints) {
    let columns = new Map();
    let rows = new Map();
    annotationPoints.forEach((p) => {
      if (!columns.has(p.x)) {
        columns.set(p.x, []);
      }
      columns.get(p.x).push(p.y);
      if (!rows.has(p.y)) {
        rows.set(p.y, []);
      }
      rows.get(p.y).push(p.x);
    });

    columns.forEach((yset, x) => {
      const arr = yset.sort((a, b) => a - b);
      let y0 = arr[0];
      let yl = arr[arr.length - 1];
      if (arr.length == 1) {
      }

      for (let y = y0 + 1; y < yl; y++) {
        const str = `${x},${y}`;
        annotationPoints.set(str, { x, y });
      }
    });

    rows.forEach((xset, y) => {
      const arr = xset.sort((a, b) => a - b);
      let x0 = arr[0];
      let xl = arr[arr.length - 1];
      if (arr.length == 1) {
      }

      for (let x = x0 + 1; x < xl; x++) {
        const str = `${x},${y}`;
        annotationPoints.set(str, { x, y });
      }
    });
  }
}

function fillAnnotationPointsNew(annotationPoints) {
  if (annotationPoints) {
    let rows = new Map();
    annotationPoints.forEach((p, str) => {
      if (!rows.has(p.y)) {
        rows.set(p.y, []);
      }
      let arr = rows.get(p.y);
      if (!arr.includes(p.x)) arr.push(p.x);
    });
    // let borderKeys = new Set(annotationPoints.keys());
    // console.log(borderKeys);

    rows = new Map([...rows.entries()].sort((e1, e2) => e1[0] - e2[0]));
    let keys = [...rows.keys()];
    // let minY = keys[0];
    // let maxY = keys[rows.size - 1];
    // console.log(`rows size: ${rows.size}, minY: ${minY}, maxY: ${maxY}`);

    let startPoint = null;
    rows.forEach((xset, y) => {
      const sorted = xset.sort((a, b) => a - b);
      rows.set(y, sorted);

      if (!startPoint && sorted.length > 1 && sorted[1] - sorted[0] > 1) {
        startPoint = { x: sorted[0] + 1, y: y };
      }
    });

    while (startPoint) {
      const pixelsToCheck = [startPoint.x, startPoint.y];
      while (pixelsToCheck.length > 0) {
        const y = pixelsToCheck.pop();
        const x = pixelsToCheck.pop();
        const str = `${x},${y}`;

        if (!annotationPoints.has(str) && annotationPoints.size < 100000) {
          annotationPoints.set(str, { x, y });
          pixelsToCheck.push(x + 1, y);
          pixelsToCheck.push(x - 1, y);
          pixelsToCheck.push(x, y + 1);
          pixelsToCheck.push(x, y - 1);
        }
      }

      startPoint = null;
    }
  }
}

function getSplitPoints(kp, splitCount) {
  const splitPoints = [];
  for (let i = 1; i < splitCount; i++) {
    let splitPoint = splitBezierCurve(
      kp.p0,
      kp.p1,
      kp.p2,
      kp.p3,
      i / splitCount
    );
    splitPoints.push(splitPoint);
  }
  return splitPoints;
}

function splitBezierCurve(p0, p1, p2, p3, proportion) {
  function lerp(a, b, t) {
    var s = 1 - t;
    return { x: a.x * s + b.x * t, y: a.y * s + b.y * t };
  }

  var p4 = lerp(p0, p1, proportion);
  var p5 = lerp(p1, p2, proportion);
  var p6 = lerp(p2, p3, proportion);
  var p7 = lerp(p4, p5, proportion);
  var p8 = lerp(p5, p6, proportion);
  var p9 = lerp(p7, p8, proportion);

  return p9;
}

function getExtrudeMesh(figure, y) {
  const extrudeSettings = {
    // curveSegments: figure.perimeter,
    // steps: 2,
    //   bevelEnabled: false,
    //   bevelThickness: 1,
    //   bevelSize: 1,
    //   bevelOffset: 0,
    //   bevelSegments: 1,
    bevelEnabled: false,
    depth: 0.25,
  };

  let pts = [];
  figure.bezierPoints.forEach((point) => {
    pts.push(point.x, point.y);
  });
  let cp = []; // array of control points, as x0,y0,x1,y1,...
  let n = pts.length;
  pts.push(pts[0], pts[1], pts[2], pts[3]);
  pts.unshift(pts[n - 1]);
  pts.unshift(pts[n - 1]);
  for (var i = 0; i < n; i += 2) {
    cp = cp.concat(
      getControlPoints(
        pts[i],
        pts[i + 1],
        pts[i + 2],
        pts[i + 3],
        pts[i + 4],
        pts[i + 5],
        0.5
      )
    );
  }
  cp = cp.concat(cp[0], cp[1]);

  const shape = new THREE.Shape();
  for (let i = 2; i < n + 2; i += 2) {
    if (i == 2) {
      shape.moveTo(pts[i], pts[i + 1]);
    }
    shape.bezierCurveTo(
      cp[2 * i - 2],
      cp[2 * i - 1],
      cp[2 * i],
      cp[2 * i + 1],
      pts[i + 2],
      pts[i + 3]
    );
  }
  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  geometry.rotateX(THREE.MathUtils.degToRad(-90));
  // geometry.applyMatrix4(new THREE.Matrix4().makeScale(1, -1, 1));
  // geometry.scale(1, -1, 1);

  // const material = new THREE.MeshStandardMaterial({
  //   color: 0x00ff00,
  //   wireframe: true,
  // });
  const mesh = new THREE.Mesh(geometry);
  mesh.position.set(0, y - extrudeSettings.depth, 0);
  return mesh;
}

async function saveMeshFromAnnotation(annotation, app, y, extrudeSettings) {
  if (annotation) {
    const blob = getAnnotationData(annotation, app);
    if (!blob) {
      console.log("saveMeshFromAnnotation empty annotation", annotation.name);
      return;
    }
    saveFile(blob, annotation.filename);

    let file1 = new File([blob], annotation.filename);
    const response = await sendSmoothStlRequest(file1, 6, true);
    if (response.status == 200) {
      saveFile(response.data, `smoothed-${annotation.filename}`);
    }
  }
}

function saveAnnotationZip(app) {
  const zip = new JSZip();
  app.annotations.forEach((annotation) => {
    const ablob = getAnnotationData(annotation, app);
    if (!ablob) {
      console.log("saveAnnotationZip empty annotation", annotation.name);
    } else {
      zip.file(annotation.filename, ablob);
    }
  });
  console.log("saveAnnotationZip", zip);
  zip
    .generateAsync({
      type: "blob",
    })
    .then(function (content) {
      saveFile(content, `AnnotationSTL_${vueApp.patientId}.zip`);
    });
}

function saveFile(blob, filename) {
  const strDataUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.download = filename;
  link.href = strDataUrl;
  link.click();
  document.body.removeChild(link);
}

function saveTestCurveMesh() {
  const curve = new THREE.CubicBezierCurve3(
    new THREE.Vector3(-10, 0, 0),
    new THREE.Vector3(-5, 15, 0),
    new THREE.Vector3(20, 15, 0),
    new THREE.Vector3(10, 0, 0)
  );
  const points = curve.getPoints(50);
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
  // const curveObject = new THREE.Mesh(geometry, material);
  // viewer.scene.add(curveObject);

  const pointsMaterial = new THREE.PointsMaterial({
    color: 0x0080ff,
    size: 1,
    alphaTest: 0.5,
  });
  let tpoints = new THREE.Points(geometry, pointsMaterial);
  viewer.scene.add(tpoints);

  const curve2 = new THREE.QuadraticBezierCurve3(
    new THREE.Vector3(-5, 0, 5),
    new THREE.Vector3(0, 9, 5),
    new THREE.Vector3(4, 0, 5)
  );
  const points2 = curve2.getPoints(20);
  const geometry2 = new THREE.BufferGeometry().setFromPoints(points2);
  const material2 = new THREE.MeshBasicMaterial({ color: 0x00ffff });
  // const curveObject2 = new THREE.Mesh(geometry2, material2);
  // viewer.scene.add(curveObject2);

  tpoints = new THREE.Points(geometry2, pointsMaterial);
  viewer.scene.add(tpoints);

  const allPoints = points.concat(points2);
  const cgeometry = new ConvexGeometry(allPoints);
  const cmaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  const cmesh = new THREE.Mesh(cgeometry, cmaterial);
  // viewer.scene.add(cmesh);

  const mesh = cmesh;
  //viewer.get_model_mesh(vueApp.allTeeth[0].id);
  const stlExporter = new STLExporter();
  const data = stlExporter.parse(mesh, { binary: true });
  const blob = new Blob([data], { type: "application/octet-stream" });
  // const data = stlExporter.parse(mesh);
  // const blob = new Blob([data], { type: 'text/plain' });
  const strDataUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.download = "tooth0.stl";
  link.href = strDataUrl;
  link.click();
  document.body.removeChild(link);
}

function updateTeethOnServer(app, smoothTeeth) {
  app.isSaving = true;
  let changedAnnotations = app.annotations.filter((a) => a.wasChanged);
  let deletedAnnotations = app.deletedAnnotations
    ? app.deletedAnnotations.filter((a) => !a.isAdded)
    : [];
  app.deletedAnnotations = [];
  let numberChanges = getNumberChanges(true);
  if (
    changedAnnotations.length > 0 ||
    deletedAnnotations.length > 0 ||
    numberChanges.length > 0
  ) {
    const teethData = { deletedTeeth: [], changedTeeth: [], addedTeeth: [] };
    const zip = new JSZip();
    changedAnnotations.forEach((annotation) => {
      if (annotation.isAdded) {
        teethData.changedTeeth.push(annotation.filename);
        let addedTooth = {
          number: +annotation.number,
          numberType: annotation.numberType,
          remarks: annotation.remarks,
          filename: annotation.filename,
        };
        teethData.addedTeeth.push(addedTooth);
      } else {
        teethData.changedTeeth.push(annotation.filename);
      }
      annotation.wasChanged = false;
      annotation.wasSaved = true;
      // const ablob = getAnnotationData(annotation, app);
      let modelGeometry = getAnnotationGeometry(annotation, app);
      if (modelGeometry) {
        let vmesh = new THREE.Mesh(modelGeometry);
        vmesh.geometry.translate(
          defaultXOffset,
          defaultYOffset,
          defaultZOffset
        );
        let matrix1 = new THREE.Matrix4();
        matrix1.makeRotationAxis(new THREE.Vector3(1, 0, 0), -rotateX);
        vmesh.geometry.applyMatrix4(matrix1);
        let matrix2 = new THREE.Matrix4();
        matrix2.makeRotationAxis(new THREE.Vector3(0, 1, 0), -rotateY);
        vmesh.geometry.applyMatrix4(matrix2);

        const stlExporter = new STLExporter();
        const data = stlExporter.parse(vmesh, { binary: true });
        const blob = new Blob([data], { type: "application/octet-stream" });
        zip.file(annotation.filename, blob);
        // annotation.isAdded = false;
      }
    });
    deletedAnnotations.forEach((a) => teethData.deletedTeeth.push(a.filename));
    // teethData.deletedTeeth.push("tooth_12-added.stl");
    teethData.smoothTeeth = !!smoothTeeth;
    zip.file("teethData.json", JSON.stringify(teethData));
    zip.file("numberChanges.json", JSON.stringify(numberChanges));

    console.log("updateTeethOnServer", zip);
    zip
      .generateAsync({
        type: "blob",
      })
      .then(async (content) => {
        try {
          await sendUpdateTeethRequest(content);
        } catch (e) {
          console.log(e);
        }
        app.isSaving = false;
      });
  } else {
    console.log("No changes in teeth");
    app.isSaving = false;
  }
}

export {
  saveTestCurveMesh,
  drawAnnotations,
  hexToCanvasColor,
  saveMeshFromAnnotation,
  saveAnnotationZip,
  updateTeethOnServer,
  getAnnotationData,
  getAnnotationGeometry,
};
