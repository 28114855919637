<template>
	<div class="dropdown dropright dropdown-submenu border-bottom-item">
		<button class="dropdown-item implant-disabled txt-ellipsis" type="button">
			Occlusal View
			<!-- <div class="txt-ellipsis"></div> -->
		</button>
		<div class="dropdown-menu secondary-menu">
			<button class="dropdown-item border-bottom-item" type="button" v-on:click="switchToMandibular()">
				Mandibular
			</button>
			<button class="dropdown-item border-bottom-item" type="button" v-on:click="switchToMaxilla()">
				Maxilla
			</button>
			<button class="dropdown-item border-bottom-item" type="button" v-on:click="switchToDefault()">
				Default
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "OcclusalMenu",
	props: ["implants", "crowns"],
	methods: {
		switchToMandibular: function () {
			gtag('event', 'View->Occlusal View->Mandibular');
			vueApp.leaveModes();
			var teethBox = this.getTeethBox(false);
			var teethZoom = this.getTeethZoom(teethBox);
			//resetCameraUp();
			viewer.camera.position.x = teethBox.center.x;
			viewer.camera.position.y = teethBox.center.y + teethZoom;
			viewer.camera.position.z = teethBox.center.z;
			if (viewer.controls_type == 1) {
				viewer.camera.up.y = 0;
				viewer.camera.up.x = 0;
				viewer.camera.up.z = -1;
			}

			vueApp.lookAtPoint(teethBox.center);
			vueApp.isOcclusal = true;
		},
		switchToMaxilla: function () {
			gtag('event', 'View->Occlusal View->Default');
			vueApp.leaveModes();
			var teethBox = this.getTeethBox(true);
			var teethZoom = this.getTeethZoom(teethBox);
			//resetCameraUp();
			viewer.camera.position.x = teethBox.center.x;
			viewer.camera.position.y = teethBox.center.y - teethZoom;
			viewer.camera.position.z = teethBox.center.z;
			if (viewer.controls_type == 1) {
				viewer.camera.up.y = 0;
				viewer.camera.up.x = 0;
				viewer.camera.up.z = 1;
			}

			vueApp.lookAtPoint(teethBox.center);
			vueApp.isOcclusal = true;
		},
		switchToDefault: function () {
			gtag('event', 'View->Occlusal View->Maxilla');
			vueApp.leaveModes(true);
			//vueApp.switchToDefaultView();
			vueApp.isOcclusal = false;
		},
		getTeethBox: function (isMaxilla) {
			var min = { x: 100000, y: 100000, z: 100000 };
			var max = { x: -10000, y: -100000, z: -100000 };
			var teethBox = { min, max };

			vueApp.allItems.forEach(function (model) {
				if (model.isTooth && model.isMaxilla == isMaxilla) {
					if (model.isVisible) {
						viewer.set_color(model.id, model.color);
					}
					var mesh = viewer.get_model_mesh(model.id);
					//console.log(mesh);
					var geometry = mesh.geometry.clone();
					geometry.applyMatrix4(mesh.matrix);

					var toothBox = geometry.boundingBox;
					//drawBox(toothBox);
					if (toothBox.min.x < teethBox.min.x) {
						teethBox.min.x = toothBox.min.x;
					}
					if (toothBox.min.y < teethBox.min.y) {
						teethBox.min.y = toothBox.min.y;
					}
					if (toothBox.min.z < teethBox.min.z) {
						teethBox.min.z = toothBox.min.z;
					}
					if (toothBox.max.x > teethBox.max.x) {
						teethBox.max.x = toothBox.max.x;
					}
					if (toothBox.max.y > teethBox.max.y) {
						teethBox.max.y = toothBox.max.y;
					}
					if (toothBox.max.z > teethBox.max.z) {
						teethBox.max.z = toothBox.max.z;
					}
				} else {
					viewer.set_color(model.id, "transparent");
				}
			});
			teethBox = offsetBox(teethBox, 0, 0, 0, 0);
			//drawBox(teethBox);
			return teethBox;
		},
		getTeethZoom: function (teethBox) {
			var padding = 10;
			var w = (teethBox.size.x + padding) / 2;
			var h = (teethBox.size.y + padding) / 2;
			var fovX = viewer.camera.fov * viewer.camera.aspect;
			var fovY = viewer.camera.fov;

			var distanceX = w / Math.tan((Math.PI * fovX) / 360) + w;
			var distanceY = h / Math.tan((Math.PI * fovY) / 360) + w;

			var defaultZoom = Math.max(distanceX, distanceY);
			return defaultZoom;
		},
		rotateView(x, y, z) { }
	}
};
</script>

<style scoped>
.txt-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
</style>