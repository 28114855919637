<template>
	<div id="toothRemoveControls" class="tooth-remove-mode-item">
		<div id="toothRemoveHint">Click tooth to remove</div>
		<!-- to mark for removal -->
	</div>
</template>

<script>

export default {
	name: "ToothRemoveControls",
	props: [],
	components: {},
	methods: {}
};

</script>

<style scoped>
#toothRemoveControls {
	display: none;
	left: calc(50% - 180px);
	top: 30px;
	width: 350px;
	position: absolute;
	z-index: 9;
}

#toothRemoveHint {
	width: 350px;
	text-align: center;
	color: #ffffff;
}
</style>