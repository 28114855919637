import { drawArrow, drawTapArrow } from "../draw/draw.js";
import { getMouse3dDirection, updateToothBeforeMove } from "../editObject";
import { rotatePoint2D, getPointHonBC } from "../geometry.js";

var offset = new THREE.Vector3();
var mesh = null;
var needUpdate = false;

var moveContext = {
	id: null, editedObject: null, tooth: null, isVerticalPlane: false, angle: 0,
	moveLine: null,
	fromTapArrow: null,
	toTapArrow: null,
	from: null,
	to: null
};

function enterMoveMode(editedObject) {
	if (editedObject) {
		editedObject.isMoved = true;
		editedObject.isRotated = editedObject.isResized = false;
		moveContext.id = editedObject.id;
		moveContext.editedObject = editedObject;
		moveContext.tooth = editedObject.tooth;
		moveContext.angle = editedObject.sliderValue * Math.PI;
	
		redrawMoveArrow();
	}
}

function exitMoveMode() {
	moveContext.id = null;
	if (moveContext.moveLine) {
		viewer.scene.remove(moveContext.moveLine);
		if (moveContext.fromTapArrow) {
			viewer.scene.remove(moveContext.fromTapArrow);
			viewer.scene.remove(moveContext.toTapArrow);
		}
	}
}


function redrawMoveArrow() {
	if (moveContext.editedObject && moveContext.tooth) {
		if (moveContext.moveLine) {
			viewer.scene.remove(moveContext.moveLine);
		}
		if (moveContext.fromTapArrow) {
			viewer.scene.remove(moveContext.fromTapArrow);
			viewer.scene.remove(moveContext.toTapArrow);
		}

		var tooth = moveContext.tooth;
		var mesh = viewer.get_model_mesh(moveContext.id);
		implantPlane.position.copy(mesh.position);
		implantPlane.lookAt(viewer.camera.position);

		var center = mesh.position;
		var min = {
			x: tooth.defaultBox.min.x + (center.x - tooth.defaultPosition.x),
			y: tooth.defaultBox.min.y + (center.y - tooth.defaultPosition.y),
			z: tooth.defaultBox.min.z + (center.z - tooth.defaultPosition.z),
		};
		var max = {
			x: tooth.defaultBox.max.x + (center.x - tooth.defaultPosition.x),
			y: tooth.defaultBox.max.y + (center.y - tooth.defaultPosition.y),
			z: tooth.defaultBox.max.z + (center.z - tooth.defaultPosition.z),
		};

		var dy = Math.abs(max.y - min.y);

		center = {
			x: center.x,
			y: tooth.isMaxilla ? center.y - dy / 6 : center.y + dy / 6,
			z: center.z,
		};

		var from = moveContext.isVerticalPlane
			? { x: center.x, y: center.y - 14, z: center.z }
			: { x: center.x - 14, y: center.y, z: center.z };
		var to = moveContext.isVerticalPlane
			? { x: center.x, y: center.y + 14, z: center.z }
			: { x: center.x + 14, y: center.y, z: center.z };

		var difx = 5;
		var dify = 3;
		var mfrom = moveContext.isVerticalPlane
			? { x: from.x + difx, y: from.y, z: from.z }
			: { x: from.x, y: from.y - dify, z: from.z };
		var mto = moveContext.isVerticalPlane
			? { x: to.x + difx, y: to.y, z: to.z }
			: { x: to.x, y: to.y - dify, z: to.z };

		if (moveContext.angle != 0) {
			if (moveContext.isVerticalPlane) {
				var rfrom = rotatePoint2D(center, moveContext.angle, from);
				from.x = rfrom.x;
				from.y = rfrom.y;
				var rto = rotatePoint2D(center, moveContext.angle, to);
				to.x = rto.x;
				to.y = rto.y;

				rfrom = rotatePoint2D(center, moveContext.angle, mfrom);
				mfrom.x = rfrom.x;
				mfrom.y = rfrom.y;
				rto = rotatePoint2D(center, moveContext.angle, mto);
				mto.x = rto.x;
				mto.y = rto.y;
			} else {
				var rfrom = rotatePoint2D({ x: center.x, y: center.z }, moveContext.angle, { x: from.x, y: from.z });
				from.x = rfrom.x;
				from.z = rfrom.y;
				var rto = rotatePoint2D({ x: center.x, y: center.z }, moveContext.angle, { x: to.x, y: to.z });
				to.x = rto.x;
				to.z = rto.y;

				rfrom = rotatePoint2D({ x: center.x, y: center.z }, moveContext.angle, { x: mfrom.x, y: mfrom.z });
				mfrom.x = rfrom.x;
				mfrom.z = rfrom.y;
				rto = rotatePoint2D({ x: center.x, y: center.z }, moveContext.angle, { x: mto.x, y: mto.z });
				mto.x = rto.x;
				mto.z = rto.y;
			}
		}

		from = new THREE.Vector3(from.x, from.y, from.z);
		to = new THREE.Vector3(to.x, to.y, to.z);
		mfrom = new THREE.Vector3(mfrom.x, mfrom.y, mfrom.z);
		mto = new THREE.Vector3(mto.x, mto.y, mto.z);

		moveContext.moveLine = drawArrow(from, to);
		if (moveContext.editedObject.showTapArrows) {
			moveContext.fromTapArrow = drawTapArrow(mfrom, false, moveContext.isVerticalPlane, moveContext.angle, "from", true);
			moveContext.toTapArrow = drawTapArrow(mto, true, moveContext.isVerticalPlane, moveContext.angle, "to", true);
		}

		moveContext.from = new THREE.Vector3(from.x, tooth.isMaxilla ? from.y + dy / 6 : from.y - dy / 6, from.z);
		moveContext.to = new THREE.Vector3(to.x, tooth.isMaxilla ? to.y + dy / 6 : to.y - dy / 6, to.z);
	}
}

function moveModeMouseDown(tooth, event) {
	if (tooth && tooth.id == moveContext.id) {
		mesh = viewer.get_model_mesh(tooth.id);
		if (mesh) {
			viewer.controls.enabled = false;
			implantPlane.position.copy(mesh.position);
			implantPlane.lookAt(viewer.camera.position);
			var intersects = raycaster.intersectObject(implantPlane);
			offset
				.copy(intersects[0].point)
				.sub(implantPlane.position);
		}
	} else {
		mesh = null;
	}
}

function moveModeMouseMove(event) {
	if (mesh) {
		if (event.preventDefault)
			event.preventDefault();

		var direction = getMouse3dDirection(event);
		raycaster.set(viewer.camera.position, direction);

		var intersects = raycaster.intersectObject(implantPlane);
		if (intersects.length > 0) {
			var pos = intersects[0].point.sub(offset);
			var point = getPointHonBC(pos, moveContext.from, moveContext.to);
			
			updateToothBeforeMove(moveContext.tooth, mesh.position, point);
			mesh.position.copy(point);
			needUpdate = true;
		}
	}
}

function moveByTap(dx) {
	if (dx != 0) {
		var mesh = viewer.get_model_mesh(moveContext.id);
		var from = mesh.position; //moveContext.from;
		var to = moveContext.to;
		var length = to.clone().sub(from).length();
		var value = dx / length;
		var newX = from.x + (to.x - from.x) * value;
		var newY = from.y + (to.y - from.y) * value;
		var newZ = from.z + (to.z - from.z) * value;
		var point = new THREE.Vector3(newX, newY, newZ);
		
		updateToothBeforeMove(moveContext.tooth, mesh.position, point);

		mesh.position.copy(point);
		redrawMoveArrow();
		vueApp.$emit('positionChanged', moveContext.tooth);
	}
}

function moveByTag(tag) {
	if (tag) {
		switch (tag) {
			case "from":
				moveByTap(-1);
				break;
			case "to":
				moveByTap(1);
				break;
		}
	}
}

function moveModeMouseUp(event) {
	//$('#stl_cont').css('cursor', 'default');
	viewer.controls.enabled = true;
	mesh = null;
	if (needUpdate) {
		redrawMoveArrow();
		vueApp.$emit('positionChanged', moveContext.tooth);
		needUpdate = false;
	}
}

function moveModeValueChanged(value) {
	moveContext.angle = value * Math.PI;
	redrawMoveArrow();
	// var mesh = viewer.get_model_mesh(moveContext.id);
	// if (mesh) {
	// 	var from = moveContext.from;
	// 	var to = moveContext.to;
	// 	var newX = from.x + (to.x - from.x) * value;
	// 	var newY = from.y + (to.y - from.y) * value;
	// 	var newZ = from.z + (to.z - from.z) * value;
	// 	var point = new THREE.Vector3(newX, newY, newZ);
	// 	mesh.position.copy(point);
	// }
}

function changeMovePlane(isVertical) {
	moveContext.angle = moveContext.editedObject.sliderValue = 0;
	$("#movementVector").slider("value", 0);
	$("#movementVector2").slider("value", 0);
	moveContext.isVerticalPlane = isVertical;
	redrawMoveArrow();
}

export {
	enterMoveMode, exitMoveMode,
	moveModeMouseDown, moveModeMouseMove,
	moveModeMouseUp, moveModeValueChanged,
	redrawMoveArrow, moveByTap, moveByTag, changeMovePlane
};