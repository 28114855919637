import { render, staticRenderFns } from "./SimpleDicomViewer.vue?vue&type=template&id=446aa603&scoped=true&"
import script from "./SimpleDicomViewer.vue?vue&type=script&lang=js&"
export * from "./SimpleDicomViewer.vue?vue&type=script&lang=js&"
import style0 from "./SimpleDicomViewer.vue?vue&type=style&index=0&id=446aa603&prod&scoped=true&lang=css&"
import style1 from "./SimpleDicomViewer.vue?vue&type=style&index=1&id=446aa603&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446aa603",
  null
  
)

export default component.exports