<template>
	<div class="dropdown dropright border-bottom-item">
		<button class="dropdown-item" v-on:click="removeTooth()" type="button">
			Remove Tooth
		</button>
	</div>
</template>

<script>
import { enterToothRemoveMode } from "./ToothRemoveMode";

export default {
	name: "ToothRemoveMenu",
	components: {},
	methods: {
		removeTooth: function () {
			gtag('event', 'View->Remove Tooth');
			enterToothRemoveMode();
		}
	}
};


</script>

<style scoped></style>