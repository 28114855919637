import { computeBoundsTree, MeshBVH, MeshBVHVisualizer } from "three-mesh-bvh";
import { drawLine, drawPoint } from "../draw/draw";

//#region Fields
const params = {
  useBVH: true,
  helperDepth: 5,
};

// let colliderBvh, colliderMesh, bvhHelper;
// const zeroPoint = { x: 0, y: 0 };
let ctxTransformed = false;

const tempVector = new THREE.Vector3();
const tempLine = new THREE.Line3();
const localPlane = new THREE.Plane();
const clippingPlane = new THREE.Plane();

//#endregion

function drawOutlines(app, dicomBox, sliceIndex, isCalculating, force) {
  // console.log("drawOutlines", app.divId, app.segmentations);
  if (!app.segmentations) {
    app.segmentations = [];
    return;
  }

  ctxTransformed = false;
  let canvasContext = $(`#${app.divId}-layer-0 canvas`)[0].getContext("2d");
  let dy = app.index != 0 ? app.worldSize.y - dicomBox.size.y : 0;
  // need fix: 5883551701953156148, 5596721689254332792
  // let currentTransform = canvasContext.getTransform();
  // let cy = Math.abs(currentTransform.a / currentTransform.d);
  // if (currentTransform.f != 0) {
  // }
  let drawingBox = getDrawBox2D(dicomBox, app.index);
  // console.log("drawingBox", drawingBox);
  if (!sliceIndex) sliceIndex = app.current;
  setupClippingPlane(clippingPlane, app, dicomBox, sliceIndex);
  let sliceConstant = clippingPlane.constant;

  let items = getSegmentItems(app, isCalculating);

  items.forEach((model, index) => {
    if (model.isHidden) return;
    // if (model.isAdded) {
    //   console.log("isAdded", model);
    // }

    // if (app.demo && index != 0) return; // index != 6 !model.isTooth
    let figures = getFiguresForModel(
      model,
      clippingPlane,
      app,
      sliceIndex,
      force,
      dy
    );
    if (!isCalculating && figures && figures.length) {
      // console.log(model.filename, figures);

      model.actualColor = getActualColor(
        app,
        canvasContext,
        model,
        sliceConstant,
        drawingBox
      );

      drawOn2DCanvas(figures, canvasContext, app, model, drawingBox);
    }
  });
}

function getActualColor(app, ctx, model, sliceConstant, drawingBox) {
  let actualColor = vueApp.getActualColor(model);
  if (model.colors && model.boundingBox) {
    if (app.index == 0) {
      let boundingBox = model.boundingBox;
      model.colors.forEach((colorObj) => {
        let constY =
          boundingBox.min.y + (colorObj.stop - 0.05) * boundingBox.size.y;
        if (constY <= sliceConstant) {
          actualColor = colorObj.color;
        }
      });
    } else {
      let sizeHeight = app.baseSize ? app.baseSize.y : ctx.canvas.clientHeight;
      let coefY = sizeHeight / drawingBox.size.y;

      let boundingBox = model.boundingBox;
      const gradient = ctx.createLinearGradient(
        0,
        boundingBox.min.y * coefY,
        0,
        boundingBox.max.y * coefY
      );
      model.colors.forEach((colorObj) => {
        gradient.addColorStop(colorObj.stop, colorObj.color);
      });
      // gradient.addColorStop(0.5, "green");
      // gradient.addColorStop(1, "blue");
      actualColor = gradient;
    }
  }
  return actualColor;
}

function getSegmentItems(app, isCalculating) {
  let items = [];
  let teeth = vueApp.allTeeth;
  let bones = vueApp.bones;
  let airways = vueApp.airways;
  if (app.segmentations.includes("maxilla") || isCalculating) {
    items = items.concat(
      bones.filter(
        (item) => item.name == "Maxilla" && item.isVisible && !item.hidden
      )
    );
  }
  if (app.segmentations.includes("mandible") || isCalculating) {
    items = items.concat(
      bones.filter(
        (item) => item.name != "Maxilla" && item.isVisible && !item.hidden
      )
    );
  }
  if (app.segmentations.includes("maxTeeth") || isCalculating) {
    items = items.concat(
      teeth.filter((item) => item.isMaxilla && item.isVisible && !item.hidden)
    );
  }
  if (app.segmentations.includes("mandTeeth") || isCalculating) {
    items = items.concat(
      teeth.filter((item) => !item.isMaxilla && item.isVisible && !item.hidden)
    );
  }
  if (app.segmentations.includes("airway") || isCalculating) {
    items = items.concat(
      airways.filter((item) => item.isVisible && !item.hidden)
    );
  }
  return items;
}

function getDrawBox2D(dicomBox, appIndex) {
  let drawingBox = {};
  drawingBox.min = convertTo2dPoint(dicomBox.min, appIndex);
  drawingBox.max = convertTo2dPoint(dicomBox.max, appIndex);
  drawingBox.center = convertTo2dPoint(dicomBox.center, appIndex);
  drawingBox.size = convertTo2dPoint(dicomBox.size, appIndex);
  return drawingBox;
}

function setupClippingPlane(clippingPlane, app, dicomBox, sliceIndex) {
  if (!sliceIndex) sliceIndex = +app.current;
  let constant = 0;
  let offset = (sliceIndex - 1) / (app.max - 1);
  if (app.index == 0) {
    clippingPlane.normal.set(0, -1, 0);
    constant = dicomBox.min.y + dicomBox.size.y * offset;
  }
  if (app.index == 1) {
    clippingPlane.normal.set(-1, 0, 0);
    constant = dicomBox.min.x + dicomBox.size.x * offset;
  }
  if (app.index == 2) {
    offset = (app.max - sliceIndex) / app.max;
    clippingPlane.normal.set(0, 0, -1);
    constant = dicomBox.min.z + dicomBox.size.z * offset;
  }
  clippingPlane.constant = constant;
}

function getFiguresForModel(model, clippingPlane, app, sliceIndex, force, dy) {
  let figures = [];
  if (model && model.filename) {
    let modelSegmentation = dicomSegmentation[model.filename];
    if (!modelSegmentation) {
      modelSegmentation = {};
      dicomSegmentation[model.filename] = modelSegmentation;
    }
    let segmentationKey = `${app.orientation}_${sliceIndex}`;
    figures = modelSegmentation[segmentationKey];

    if (!figures || model.needUpdateDicom || force) {
      let mesh = viewer.get_model_mesh(model.id);
      if (mesh && mesh.type == "Mesh" && mesh.geometry) {
        // && mesh.visible
        let segments = getMeshSegmentsForPlane(mesh, clippingPlane, app);
        if (dy) {
          segments.forEach((segment) => {
            segment.p1.y -= dy;
            segment.p2.y -= dy;
          });
        }
        let verticesArr = [];
        while (segments.length > 0) {
          let vertices = getVerticesFromSegments(segments);
          if (vertices.length) {
            verticesArr.push(vertices);
          }
        }
        figures = getFiguresFromVertices(verticesArr, model);
        modelSegmentation[segmentationKey] = figures;
      }
    }
  }
  return figures;
}

function getAllFiguresForModel(model, app, dicomBox) {
  let allFigures = {};
  if (model && app) {
    let dy = app.index != 0 ? app.worldSize.y - dicomBox.size.y : 0;
    let mesh = viewer.get_model_mesh(model.id);
    let sliceIndexes = getSliceIndexes(mesh, app, dicomBox);
    for (const sliceIndex of sliceIndexes) {
      setupClippingPlane(clippingPlane, app, dicomBox, sliceIndex);
      let tempFigures = getFiguresForModel(
        model,
        clippingPlane,
        app,
        sliceIndex,
        false,
        dy
      );
      if (tempFigures && tempFigures.length) {
        allFigures[+sliceIndex] = tempFigures;
      }
    }
  }
  return allFigures;
}

function getSliceIndexes(mesh, app, dicomBox) {
  let indexes = [];
  let geometry = mesh.geometry.clone();
  geometry.applyMatrix4(mesh.matrix);
  let toothBox = geometry.boundingBox;
  for (let i = 1; i <= app.max; i++) {
    let constant = 0;
    let offset = (i - 1) / (app.max - 1);
    if (app.index == 0) {
      constant = dicomBox.min.y + dicomBox.size.y * offset;
      if (constant >= toothBox.min.y && constant <= toothBox.max.y)
        indexes.push(i);
    }
    if (app.index == 1) {
      constant = dicomBox.min.x + dicomBox.size.x * offset;
      if (constant >= toothBox.min.x && constant <= toothBox.max.x)
        indexes.push(i);
    }
    if (app.index == 2) {
      offset = (app.max - sliceIndex) / app.max;
      constant = dicomBox.min.z + dicomBox.size.z * offset;
      if (constant >= toothBox.min.z && constant <= toothBox.max.z)
        indexes.push(i);
    }
  }
  return indexes;
}

function drawOn2DCanvas(figures, ctx, app, model, drawingBox) {
  if (!figures || !figures.length || !ctx) {
    return;
  }

  let appIndex = app.index;
  let color = model ? model.actualColor : app.color;
  ctx.strokeStyle = color;
  ctx.fillStyle = color;
  ctx.lineWidth = app.outlineThickness;
  ctx.globalAlpha = app.opacity; //0.5;

  // TO OPTIMIZE
  let sizeWidth = app.baseSize ? app.baseSize.x : ctx.canvas.clientWidth;
  let sizeHeight = app.baseSize ? app.baseSize.y : ctx.canvas.clientHeight;
  // let coef = appIndex == 0 ? sizeWidth / drawingBox.size.x : sizeHeight / drawingBox.size.y;
  let coefX = sizeWidth / drawingBox.size.x;
  let coefY = sizeHeight / drawingBox.size.y;

  if (!ctxTransformed) {
    ctxTransformed = true;
    if (appIndex == 0) {
      ctx.translate(
        -drawingBox.min.x * coefX,
        -drawingBox.min.y * coefY + drawingBox.center.y * coefY * 2
      );
      ctx.rotate(Math.PI);
      ctx.scale(-1, 1);
    }
    if (appIndex == 1) {
      ctx.translate(
        -drawingBox.min.x * coefX + drawingBox.center.x * coefX * 2,
        -drawingBox.min.y * coefY
      );
      ctx.scale(-1, 1);
    }
    if (appIndex == 2) {
      ctx.translate(-drawingBox.min.x * coefX, -drawingBox.min.y * coefY);
    }
  }

  ctx.beginPath();

  figures.forEach((figure) => {
    figure.vertices.forEach((p, index) => {
      let x1 = p.x * coefX;
      let y1 = p.y * coefY;
      if (index == 0) {
        ctx.moveTo(x1, y1);
      } else {
        ctx.lineTo(x1, y1);
      }
    });
    if (model.isTooth) {
      ctx.closePath();
    }
  });
  // ctx.mozFillRule = 'evenodd';
  ctx.stroke();
  if (
    app.segmentationType === "fillAll" ||
    (model.isTooth && app.segmentationType === "fillTeeth")
  ) {
    ctx.fill("evenodd");
  }
}

// https://github.com/gkjohnson/three-mesh-bvh/blob/master/README.md#constructor
function getMeshSegmentsForPlane(mesh, clippingPlane, app) {
  if (!mesh.colliderBvh || !mesh.inverseMatrix) {
    let bufferGeometry = null;
    if (mesh.geometry._bufferGeometry)
      bufferGeometry = mesh.geometry._bufferGeometry;
    else if (mesh.geometry.type == "BufferGeometry") {
      bufferGeometry = mesh.geometry;
    }
    // else {
    //   bufferGeometry = new THREE.BufferGeometry().fromGeometry(mesh.geometry);
    // }
    const clonedGeometry = bufferGeometry.clone();
    clonedGeometry.applyMatrix4(mesh.matrixWorld);
    for (const key in clonedGeometry.attributes) {
      if (key === "position" || key === "normal") {
        continue;
      }
      clonedGeometry.deleteAttribute(key);
    }

    const colliderBvh = (mesh.colliderBvh = new MeshBVH(clonedGeometry, {
      maxLeaafTris: 20, // 10
      maxDepth: 30, // 40 //5
    }));
    bufferGeometry.boundsTree = colliderBvh;
    const colliderMesh = new THREE.Mesh(bufferGeometry);
    colliderMesh.renderOrder = 2;
    const inverseMatrix = new THREE.Matrix4();
    inverseMatrix.copy(colliderMesh.matrixWorld).invert();
    mesh.inverseMatrix = inverseMatrix;
  }
  let segments = renderBVH(
    mesh.colliderBvh,
    mesh.inverseMatrix,
    clippingPlane,
    app.index
  );
  return segments;
}

function renderBVH(colliderBvh, inverseMatrix, clippingPlane, appIndex) {
  let segments = [];
  if (colliderBvh) {
    // const startTime = window.performance.now();
    localPlane.copy(clippingPlane).applyMatrix4(inverseMatrix);
    let i = 0;
    colliderBvh.shapecast({
      intersectsBounds: (box) => {
        //return CONTAINED;
        return localPlane.intersectsBox(box);
      },
      intersectsTriangle: (tri) => {
        i++;
        // if (i > 27) {
        //   return;
        // }
        // let color = 0xff0000;
        // if (i % 2 == 0) color = 0x00ff00;
        // if (i % 3 == 0) color = 0x0000ff;
        // drawLine(tri.a, tri.b, color);
        // drawLine(tri.b, tri.c, color);
        // drawLine(tri.c, tri.a, color);
        // const vectors = [];
        const points = [];
        tempLine.start.copy(tri.a);
        tempLine.end.copy(tri.b);
        if (localPlane.intersectLine(tempLine, tempVector)) {
          points.push(convertTo2dPoint(tempVector, appIndex));
          // vectors.push(tempVector.clone());
        }

        tempLine.start.copy(tri.b);
        tempLine.end.copy(tri.c);
        if (localPlane.intersectLine(tempLine, tempVector)) {
          points.push(convertTo2dPoint(tempVector, appIndex));
          // vectors.push(tempVector.clone());
        }

        tempLine.start.copy(tri.c);
        tempLine.end.copy(tri.a);
        if (localPlane.intersectLine(tempLine, tempVector)) {
          points.push(convertTo2dPoint(tempVector, appIndex));
          // vectors.push(tempVector.clone());
        }

        if (points.length === 2) {
          let segment = {
            p1: points[0],
            p2: points[1],
          };
          segments.push(segment);
          // drawLine(vectors[0], vectors[1], color);
          // drawPoint(vectors[0], color, false, 0.05);
          // drawPoint(vectors[1], color, false, 0.05);
        }
      },
    });

    // const delta = window.performance.now() - startTime;
    // console.log(`slice time: ${parseFloat(delta.toFixed(3))}ms`);
  }
  return segments;
}

function getVerticesFromSegments(segments) {
  // console.log(segments);
  let vertices = [];
  let head = segments.length ? segments[0] : null;
  let tail = head;
  let headIndex = 0;
  let tailIndex = headIndex;
  while (head || tail) {
    if (head && headIndex >= 0) {
      vertices.unshift(head.p1);
      segments.splice(headIndex, 1);
    }
    if (tail && tailIndex >= 0) {
      vertices.push(tail.p2);
      if (head != tail) {
        segments.splice(
          !head || headIndex > tailIndex ? tailIndex : tailIndex - 1,
          1
        );
      }
    }

    if (head) {
      headIndex = findHeadIndex(segments, head);
      head = headIndex >= 0 ? segments[headIndex] : null;
    }
    if (tail) {
      tailIndex = findTailIndex(segments, tail);
      tail = tailIndex >= 0 ? segments[tailIndex] : null;
    }
  }
  // console.log("segments.length", segments.length);
  return vertices;
}

function getFiguresFromVertices(verticesArr, model) {
  let figures = [];
  if (verticesArr && verticesArr.length) {
    verticesArr = verticesArr.sort((a, b) => b.length - a.length);
    let figure = null;
    verticesArr.forEach((vertices, index) => {
      let p1 = vertices[0];
      let p2 = vertices[vertices.length - 1];
      let distance = getDistance(p1, p2);
      if (figure && !model.isTooth && distance > 1 && index === 1) {
        let fp1 = figure.vertices[0];
        let fp2 = figure.vertices[figure.vertices.length - 1];
        let fdistance = getDistance(fp1, fp2);
        if (fdistance > 1) {
          let d1 = getDistance(p1, fp2);
          let d2 = getDistance(p2, fp2);
          figure.vertices =
            d1 < d2
              ? figure.vertices.concat(vertices)
              : figure.vertices.concat(vertices.toReversed());
          figure = null;
          return;
        }
      }
      figure = { vertices: vertices, filename: model.filename };
      figures.push(figure);
    });
  }
  return figures;
}

function getFigurePerimeter(figure) {
  let perimeter = 0;
  if (figure && figure.vertices && figure.vertices.length) {
    const vertices = figure.vertices;
    const length = vertices.length;
    for (let i = 0; i < length - 1; i++) {
      const p1 = vertices[i];
      const p2 = vertices[i + 1];
      const distance = getDistance(p1, p2);
      p1.distance = distance;
      perimeter += distance;
    }
    const lastDistance = getDistance(vertices[length - 1], vertices[0]);
    vertices[length - 1].distance = lastDistance;
    perimeter += lastDistance;
  }
  return perimeter;
}

function convertTo2dPoint(vector, appIndex) {
  let point = {
    x: appIndex == 1 ? vector.z : vector.x,
    y: appIndex == 0 ? vector.z : vector.y,
  };
  point.x = +point.x.toFixed(3);
  point.y = +point.y.toFixed(3);
  // if (vector.clone) point.vector = vector.clone();
  // if (calculateZeroDistance) {
  //   point.zeroDistance = getDistance(point, zeroPoint);
  // }
  return point;
}

function getDistance(p1, p2) {
  var dx = p1.x - p2.x;
  var dy = p1.y - p2.y;
  return Math.sqrt(dx * dx + dy * dy);
}

function findHeadIndex(segments, head) {
  let headIndex = segments.findIndex(
    (segment) => segment.p2.x == head.p1.x && segment.p2.y == head.p1.y
  );
  if (headIndex < 0) {
    headIndex = segments.findIndex(
      (segment) => segment.p1.x == head.p1.x && segment.p1.y == head.p1.y
    );
    let nhead = headIndex >= 0 ? segments[headIndex] : null;
    if (nhead) {
      let temp = nhead.p1;
      nhead.p1 = nhead.p2;
      nhead.p2 = temp;
    }
  }

  // if (headIndex < 0) {
  //   headIndex = segments.findIndex(
  //     (segment) => getDistance(segment.p2, head.p1) < 1
  //   );
  //   if (headIndex < 0) {
  //     headIndex = segments.findIndex(
  //       (segment) => getDistance(segment.p1, head.p1) < 1
  //     );
  //     let nhead = headIndex >= 0 ? segments[headIndex] : null;
  //     if (nhead) {
  //       let temp = nhead.p1;
  //       nhead.p1 = nhead.p2;
  //       nhead.p2 = temp;
  //     }
  //   }
  // }

  return headIndex;
}

function findTailIndex(segments, tail) {
  let tailIndex = segments.findIndex(
    (segment) => segment.p1.x == tail.p2.x && segment.p1.y == tail.p2.y
  );
  if (tailIndex < 0) {
    tailIndex = segments.findIndex(
      (segment) => segment.p2.x == tail.p2.x && segment.p2.y == tail.p2.y
    );
    let ntail = tailIndex >= 0 ? segments[tailIndex] : null;
    if (ntail) {
      let temp = ntail.p1;
      ntail.p1 = ntail.p2;
      ntail.p2 = temp;
    }
  }

  // if (tailIndex < 0) {
  //   tailIndex = segments.findIndex(
  //     (segment) => getDistance(segment.p1, tail.p2) < 1
  //   );
  //   if (tailIndex < 0) {
  //     tailIndex = segments.findIndex(
  //       (segment) => getDistance(segment.p2, tail.p2) < 1
  //     );
  //     let ntail = tailIndex >= 0 ? segments[tailIndex] : null;
  //     if (ntail) {
  //       let temp = ntail.p1;
  //       ntail.p1 = ntail.p2;
  //       ntail.p2 = temp;
  //     }
  //   }
  // }

  return tailIndex;
}

export {
  drawOutlines,
  convertTo2dPoint,
  getDistance,
  getFiguresForModel,
  getDrawBox2D,
  setupClippingPlane,
  getAllFiguresForModel,
  getFigurePerimeter,
};
