<template>
	<div id="orthoPlanButtons" class="ortho-mode-item">
		<div id="orthoSaveButtons">
			<button id="orthoPlanCancel" class="ortho-button"
				v-on:click="cancelPlanMode()">
				CANCEL
			</button>
			<button id="orthoPlanSaveExit" class="ortho-button ortho-save"
				v-on:click="saveExitPlanMode()">
				SAVE & EXIT
			</button>
			<button id="orthoPlanSaveAsStage" class="ortho-button ortho-save"
				v-on:click="saveAsStagePlanMode()">
				SAVE AS...
			</button>
		</div>
		<button id="orthoToothDoneButton" class="ortho-button"
			v-on:click="exitToothMode()">
			DONE
		</button>
	</div>
</template>

<script>
import {
	cancelOrthoChanges,
	saveOrthoChanges,
	exitEditToothMode
} from "./OrthoPlanMode";

import { showOrthoStageDialog } from "./OrthoStageDialog.vue";

export default {
	name: "OrthoPlanButtons",
	props: [],
	components: {},
	methods: {
		exitToothMode: function() {
			exitEditToothMode();
		},
		cancelPlanMode: function() {
			cancelOrthoChanges();
		},
		saveExitPlanMode: function() {
			saveOrthoChanges();
		},
		saveAsStagePlanMode: function() {
			showOrthoStageDialog(true);
		}
	}
};
</script>

<style scoped>
/* @import "opacity.css";
*/

#orthoPlanButtons {
	left: calc(50% - 128px);
	bottom: 65px;
	z-index: 9;
	display: none;
	width: 330px;
	height: 35px;
}

#orthoSaveButtons {
	position: absolute;
	max-width: 330px;
	height: 35px;
	left: -5px;
	text-align: center;
	white-space: nowrap;
}

.ortho-button {
	display: inline-block;
	margin-right: 10px;
	min-width: 90px;
	width: auto;
	height: 35px;
	/* left: calc(50% - 57px); */
	/* bottom: 65px; */
	border: 0;
	cursor: pointer;
	font-size: small;
	background-color: #4687fc; /*#F7FAFF;*/
	text-align: center;
	color: #ffffff;
	/* position: absolute; */
}

#orthoToothDoneButton {
	display: none;
	position: absolute;
	width: 90px;
	left: 110px;
	/* left: calc(50% - 5x); */
}

@media screen and (max-width: 650px) {
	#orthoSaveButtons {
		left: -25px;
	}
}
</style>