<template>
	<div class="dropdown dropright border-bottom-item">
		<button id="miChangeToothNumber" class="dropdown-item" type="button" v-on:click="changeToothNumber()">
			Tooth Number
		</button>
	</div>
</template>

<script>
import { enterToothNumberMode } from "./ToothNumberMode";

export default {
	name: "ToothNumberMenu",
	props: ["isEngineerMode"],
	components: {},
	methods: {
		changeToothNumber: function () {
			gtag('event', 'View->Tooth Number');
			enterToothNumberMode();
		}
	}
};
</script>

<style scoped></style>