// import * as THREE from "three";
import { computeBoundsTree, MeshBVH, MeshBVHVisualizer } from "three-mesh-bvh";
import { drawLine, drawPoint } from "../draw/draw";
import { runSmoothing } from "../dicom/smoothing";

//#region Fields
const params = {
  useBVH: true,
  helperDepth: 5,
};

const tempVector = new THREE.Vector3();
const tempLine = new THREE.Line3();
const localPlane = new THREE.Plane();
const clippingPlane = new THREE.Plane();

//#endregion

function airwaySetColor() {
  setTimeout(function () {
    if (!vueApp.airways || !vueApp.airways.length) return;
    let airway = vueApp.airways[0];
    let mesh = viewer.get_model_mesh(airway.id);
    let smoothedGeometry = runSmoothing(mesh.geometry, 10);

    let geometry = mesh.geometry.clone();
    geometry.applyMatrix4(mesh.matrix);
    let airwayBox = offsetBox(geometry.boundingBox);
    airway.boundingBox = airwayBox;

    // drawBox(airwayBox);
    // console.log("airwaySetColor", airwayBox);
    // drawSlices(airwayBox);

    const colors = getColorsForAirway(mesh, airwayBox);
    airway.colors = colors;
    const threeColors = colors.map((c) => {
      return { stop: c.stop, color: new THREE.Color(c.color) };
    });

    mesh.geometry = smoothedGeometry;

    updateToGradient(mesh, threeColors);
    // mesh.geometry = runSmoothing(new THREE.BufferGeometry().fromGeometry(mesh.geometry), 10);
    // viewer.set_opacity(airway.id, 0.6);
  }, 1);
}

function getColorsForAirway(mesh, airwayBox) {
  const dy = 0.2;
  const min = airwayBox.min.y + dy;
  const height = airwayBox.size.y - dy * 2;
  const colors = [];

  clippingPlane.normal.set(0, -1, 0);
  for (let i = 0; i <= 10; i++) {
    clippingPlane.constant = min + (height * i) / 10;

    let segments = getMeshSegmentsForPlane(mesh, clippingPlane);
    let verticesArr = [];
    while (segments.length > 0) {
      let vertices = getVerticesFromSegments(segments);
      if (vertices.length) {
        verticesArr.push(vertices);
      }
    }

    let area = 0;
    let figures = getFiguresFromVertices(verticesArr);
    figures.forEach((figure, index) => {
      let figureArea = Math.abs(THREE.ShapeUtils.area(figure.vertices));
      area += figureArea;
    });
    // console.log("area " + i, area);

    const color = getColorFromArea(area);
    colors.push({
      stop: i / 10,
      color: color,
    });
  }
  console.log("airway colors", colors);
  return colors;
}

function getColorFromArea(area) {
  if (area < 100) {
    return "#FF0000";
  } else if (area < 130) {
    return "#F1C232";
  } else if (area < 200) {
    return "#FAFF00";
  } else if (area < 300) {
    return "#00FF16";
  } else if (area < 350) {
    return "#00C6FF";
  } else {
    return "#000FFF";
  }
}

function updateToGradient(mesh, colors) {
  let geometry = mesh.geometry;
  let bufferGeometry = null;
  if (geometry._bufferGeometry) bufferGeometry = geometry._bufferGeometry;
  else if (mesh.geometry.type == "BufferGeometry") {
    bufferGeometry = mesh.geometry;
  } else {
    bufferGeometry = new THREE.BufferGeometry().fromGeometry(mesh.geometry);
  }
  if (bufferGeometry) {
    geometry = new THREE.Geometry();
    geometry.fromBufferGeometry(bufferGeometry);

    mesh.geometry.dispose();
    mesh.geometry = geometry;
  }

  let reverse = false;

  setGradient(geometry, colors, "y", reverse);
  geometry.computeVertexNormals();

  var phongMaterial = new THREE.MeshPhongMaterial({
    vertexColors: THREE.VertexColors,
    flatShading: false,
    specular: 0x050505,
    shininess: 100,
    side: 2,
  });

  mesh.material = phongMaterial;
  mesh.receiveShadow = false;
  mesh.castShadow = false;
}

function setGradient(geometry, colors, axis, reverse) {
  geometry.computeBoundingBox();

  var bbox = geometry.boundingBox;
  var size = new THREE.Vector3().subVectors(bbox.max, bbox.min);

  var vertexIndices = ["a", "b", "c"];
  var face,
    vertex,
    normalized = new THREE.Vector3(),
    normalizedAxis = 0;

  for (var c = 0; c < colors.length - 1; c++) {
    var colorDiff = colors[c + 1].stop - colors[c].stop;

    for (var i = 0; i < geometry.faces.length; i++) {
      face = geometry.faces[i];
      for (var v = 0; v < 3; v++) {
        vertex = geometry.vertices[face[vertexIndices[v]]];
        normalizedAxis = normalized.subVectors(vertex, bbox.min).divide(size)[
          axis
        ];
        if (reverse) {
          normalizedAxis = 1 - normalizedAxis;
        }
        if (
          normalizedAxis >= colors[c].stop &&
          normalizedAxis <= colors[c + 1].stop
        ) {
          var localNormalizedAxis =
            (normalizedAxis - colors[c].stop) / colorDiff;
          face.vertexColors[v] = colors[c].color
            .clone()
            .lerp(colors[c + 1].color, localNormalizedAxis);
        }
      }
    }
  }
}

function getMeshSegmentsForPlane(mesh, clippingPlane) {
  if (!mesh.colliderBvh || !mesh.inverseMatrix) {
    let bufferGeometry = null;
    if (mesh.geometry._bufferGeometry)
      bufferGeometry = mesh.geometry._bufferGeometry;
    else if (mesh.geometry.type == "BufferGeometry") {
      bufferGeometry = mesh.geometry;
    } else {
      bufferGeometry = new THREE.BufferGeometry().fromGeometry(mesh.geometry);
    }
    const clonedGeometry = bufferGeometry.clone();
    clonedGeometry.applyMatrix4(mesh.matrixWorld);
    for (const key in clonedGeometry.attributes) {
      if (key === "position" || key === "normal") {
        continue;
      }
      clonedGeometry.deleteAttribute(key);
    }

    const colliderBvh = (mesh.colliderBvh = new MeshBVH(clonedGeometry, {
      maxLeaafTris: 20, // 10
      maxDepth: 30, // 40 //5
    }));
    bufferGeometry.boundsTree = colliderBvh;
    const colliderMesh = new THREE.Mesh(bufferGeometry);
    colliderMesh.renderOrder = 2;
    const inverseMatrix = new THREE.Matrix4();
    inverseMatrix.copy(colliderMesh.matrixWorld).invert();
    mesh.inverseMatrix = inverseMatrix;
  }
  let segments = renderBVH(mesh.colliderBvh, mesh.inverseMatrix, clippingPlane);
  return segments;
}

function renderBVH(colliderBvh, inverseMatrix, clippingPlane, appIndex) {
  let segments = [];
  if (colliderBvh) {
    // const startTime = window.performance.now();
    localPlane.copy(clippingPlane).applyMatrix4(inverseMatrix);
    let i = 0;
    colliderBvh.shapecast({
      intersectsBounds: (box) => {
        //return CONTAINED;
        return localPlane.intersectsBox(box);
      },
      intersectsTriangle: (tri) => {
        // i++;
        // let color = 0xff0000;
        // if (i % 2 == 0) color = 0x00ff00;
        // if (i % 3 == 0) color = 0x0000ff;
        // drawLine(tri.a, tri.b, color);
        // drawLine(tri.b, tri.c, color);
        // drawLine(tri.c, tri.a, color);
        const points = [];
        tempLine.start.copy(tri.a);
        tempLine.end.copy(tri.b);
        if (localPlane.intersectLine(tempLine, tempVector)) {
          points.push(convertTo2dPoint(tempVector));
        }

        tempLine.start.copy(tri.b);
        tempLine.end.copy(tri.c);
        if (localPlane.intersectLine(tempLine, tempVector)) {
          points.push(convertTo2dPoint(tempVector));
        }

        tempLine.start.copy(tri.c);
        tempLine.end.copy(tri.a);
        if (localPlane.intersectLine(tempLine, tempVector)) {
          points.push(convertTo2dPoint(tempVector));
        }

        if (points.length === 2) {
          let segment = {
            p1: points[0],
            p2: points[1],
          };
          segments.push(segment);
          //   drawLine(points[0].vector, points[1].vector, color);
          //   drawPoint(vectors[0], color, false, 0.05);
          //   drawPoint(vectors[1], color, false, 0.05);
        }
      },
    });
    // const delta = window.performance.now() - startTime;
    // console.log(`slice time: ${parseFloat(delta.toFixed(3))}ms`);
  }
  return segments;
}

function getVerticesFromSegments(segments) {
  // console.log(segments);
  let vertices = [];
  let head = segments.length ? segments[0] : null;
  let tail = head;
  let headIndex = 0;
  let tailIndex = headIndex;
  while (head || tail) {
    if (head && headIndex >= 0) {
      vertices.unshift(head.p1);
      segments.splice(headIndex, 1);
    }
    if (tail && tailIndex >= 0) {
      vertices.push(tail.p2);
      if (head != tail) {
        segments.splice(
          !head || headIndex > tailIndex ? tailIndex : tailIndex - 1,
          1
        );
      }
    }

    if (head) {
      headIndex = findHeadIndex(segments, head);
      head = headIndex >= 0 ? segments[headIndex] : null;
    }
    if (tail) {
      tailIndex = findTailIndex(segments, tail);
      tail = tailIndex >= 0 ? segments[tailIndex] : null;
    }
  }
  // console.log("segments.length", segments.length);
  return vertices;
}

function getFiguresFromVertices(verticesArr) {
  let figures = [];
  if (verticesArr && verticesArr.length) {
    verticesArr = verticesArr.sort((a, b) => b.length - a.length);
    let figure = null;
    verticesArr.forEach((vertices, index) => {
      figure = { vertices: vertices };
      figures.push(figure);
    });
  }
  return figures;
}

function convertTo2dPoint(vector) {
  let point = new THREE.Vector2(+vector.x.toFixed(3), +vector.z.toFixed(3));
  //   if (vector.clone) point.vector = vector.clone();
  return point;
}

function findHeadIndex(segments, head) {
  let headIndex = segments.findIndex(
    (segment) => segment.p2.x == head.p1.x && segment.p2.y == head.p1.y
  );
  if (headIndex < 0) {
    headIndex = segments.findIndex(
      (segment) => segment.p1.x == head.p1.x && segment.p1.y == head.p1.y
    );
    let nhead = headIndex >= 0 ? segments[headIndex] : null;
    if (nhead) {
      let temp = nhead.p1;
      nhead.p1 = nhead.p2;
      nhead.p2 = temp;
    }
  }
  return headIndex;
}

function findTailIndex(segments, tail) {
  let tailIndex = segments.findIndex(
    (segment) => segment.p1.x == tail.p2.x && segment.p1.y == tail.p2.y
  );
  if (tailIndex < 0) {
    tailIndex = segments.findIndex(
      (segment) => segment.p2.x == tail.p2.x && segment.p2.y == tail.p2.y
    );
    let ntail = tailIndex >= 0 ? segments[tailIndex] : null;
    if (ntail) {
      let temp = ntail.p1;
      ntail.p1 = ntail.p2;
      ntail.p2 = temp;
    }
  }
  return tailIndex;
}

function drawSlices(airwayBox) {
  const size = Math.max(airwayBox.size.x, airwayBox.size.z);
  const planeGeom = new THREE.PlaneGeometry(size, size);
  const planeMat = new THREE.MeshBasicMaterial({
    color: 0xff0000,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.5,
    depthWrite: false,
  });

  const plane1 = new THREE.Mesh(planeGeom, planeMat);
  plane1.rotation.set(Math.PI / 2, 0, 0);
  plane1.position.set(airwayBox.center.x, airwayBox.min.y, airwayBox.center.z);
  viewer.scene.add(plane1);
  for (let i = 1; i < 10; i++) {
    const plane2 = plane1.clone();
    plane2.position.set(
      airwayBox.center.x,
      airwayBox.min.y + (airwayBox.size.y * i) / 9,
      airwayBox.center.z
    );
    viewer.scene.add(plane2);
  }
}

function updateToGradient1(mesh) {
  console.log("updateToGradient1");
  var geometry = mesh.geometry;
  geometry.computeBoundingBox();
  var material = new THREE.ShaderMaterial({
    uniforms: {
      color1: {
        value: new THREE.Color("red"),
      },
      color2: {
        value: new THREE.Color("purple"),
      },
    },
    vertexShader: `
          varying vec2 vUv;
      
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
          }
        `,
    fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
        
          varying vec2 vUv;
          
          void main() {
            
            gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
          }
        `,
    wireframe: true,
  });
  mesh.material = material;
}

function updateToGradient2(mesh) {
  console.log("updateToGradient2");
  var geometry = mesh.geometry;
  // geometry.applyMatrix4(mesh.matrix);
  geometry.computeBoundingBox();
  var material = new THREE.ShaderMaterial({
    uniforms: {
      color1: {
        value: new THREE.Color("red"),
      },
      color2: {
        value: new THREE.Color("purple"),
      },
      bboxMin: {
        value: geometry.boundingBox.min,
      },
      bboxMax: {
        value: geometry.boundingBox.max,
      },
    },
    vertexShader: `
          uniform vec3 bboxMin;
          uniform vec3 bboxMax;
        
          varying vec2 vUv;
      
          void main() {
            vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
          }
        `,
    fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
        
          varying vec2 vUv;
          
          void main() {
            
            gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
          }
        `,
    // wireframe: true,
  });
  mesh.material = material;
}

export { airwaySetColor };
