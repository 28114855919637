<template>
	<div id="hideModeDiv" class="hide-mode-item">
		<div id="hideModeHint" class="hide-mode-item">Click teeth to Show/Hide</div>

		<div id="hideMaxillaDiv" class="hide-mode-item toggle-div" v-on:click.stop="setBoneVisibility(true, $event)">
			<input id="hideMaxillaButton" type="checkbox" class="toggle-hide-button" checked />
			<div class="toggle-title">MAXILLA</div>
		</div>

		<div id="hideMandibleDiv" class="hide-mode-item toggle-div" v-on:click.stop="setBoneVisibility(false, $event)">
			<input id="hideMandibleButton" type="checkbox" class="toggle-hide-button" checked />
			<div class="toggle-title">MANDIBLE</div>
		</div>

		<div id="hideUpperDiv" class="hide-mode-item toggle-div"
			v-on:click.stop="setDentitionVisibility('Maxilla', $event)">
			<input id="hideUpperButton" type="checkbox" class="toggle-hide-button" checked />
			<div class="toggle-title">MAX. TEETH</div>
		</div>

		<div id="hideLowerDiv" class="hide-mode-item toggle-div"
			v-on:click.stop="setDentitionVisibility('Mandible', $event)">
			<input id="hideLowerButton" type="checkbox" class="toggle-hide-button" checked />
			<div id="hideLowerLabel" class="toggle-title">MAND. TEETH</div>
		</div>

		<div id="hideNervesDiv" class="hide-mode-item toggle-div" v-on:click.stop="setNervesVisibility($event)">
			<input id="hideNervesButton" type="checkbox" class="toggle-hide-button" checked />
			<div class="toggle-title">NERVE</div>
		</div>

		<div id="hideGingivaeDiv" class="hide-mode-item toggle-div" v-on:click.stop="setGingivaeVisibility($event)">
			<input id="hideGingivaeButton" type="checkbox" class="toggle-hide-button" checked />
			<div class="toggle-title">GINGIVA</div>
		</div>

		<div id="hideAirwaysDiv" class="hide-mode-item toggle-div" v-on:click.stop="setAirwaysVisibility($event)">
			<input id="hideAirwaysButton" type="checkbox" class="toggle-hide-button" checked />
			<div class="toggle-title">AIRWAY</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "ColorControls",
	props: [],
	data() {
		return {};
	},
	methods: {
		setBoneVisibility: function (isMaxilla, event) {
			var item = vueApp.bones.find(b => b.isMaxilla === isMaxilla);
			if (!item && vueApp.bones.length && vueApp.bones.length > 1) {
				if (isMaxilla)
					item = isMaxilla ? vueApp.bones[0] : vueApp.bones[1];
			}
			if (item) {
				item.isVisible = !item.isVisible;
				if (event) {
					switchToggle(event.currentTarget.id, item.isVisible);
				}
				itemVisibilityChanged(item, vueApp.bonesColor);
				vueApp.$emit('visibilityChanged', item);
			}
		},
		setNervesVisibility: function (event) {
			vueApp.nerves.forEach(item => {
				item.isVisible = !item.isVisible;
				if (event) {
					switchToggle(event.currentTarget.id, item.isVisible);
				}
				itemVisibilityChanged(item, vueApp.nervesColor);
			});
		},
		setGingivaeVisibility: function (event) {
			vueApp.gingivae.forEach(item => {
				item.isVisible = !item.isVisible;
				if (event) {
					switchToggle(event.currentTarget.id, item.isVisible);
				}
				itemVisibilityChanged(item, vueApp.gingivaeColor);
			});
		},
		setAirwaysVisibility: function (event) {
			vueApp.airways.forEach(item => {
				item.isVisible = !item.isVisible;
				if (event) {
					switchToggle(event.currentTarget.id, item.isVisible);
				}
				itemVisibilityChanged(item, vueApp.airwaysColor);
			});
		},
		setDentitionVisibility: function (dentitionName, event) {
			var dentation = vueApp.dentations.find(x => x.name === dentitionName);
			if (dentation) {
				dentation.isVisible = !dentation.isVisible;
				if (event) {
					switchToggle(event.currentTarget.id, dentation.isVisible);
				}
				// var dentationTeeth = dentation.groups.reduce(function(a, b) {
				// 	return a.concat(b.teeth);
				// }, []);
				vueApp.allTeeth.forEach(function (item) {
					if (item.dentition == dentitionName) { //(dentationTeeth.includes(item)) {
						item.isVisible = dentation.isVisible;
						viewer.set_opacity(item.id, item.isVisible ? 1 : 0.3);
					}
				});
				vueApp.$emit('visibilityChanged', dentitionName);
			}
		}
	},
	mounted: function () {
		canecode_switch(".toggle-hide-button");
	}
};
</script>

<style scoped>
#hideModeDiv {
	left: calc(50% - 175px);
	top: 10px;
	width: 350px;
}

#hideModeHint {
	width: 350px;
	left: calc(50% - 175px);
	text-align: center;
	/* background-color: transparent; */
}

/*#hideModeDoneButton {
	width: 140px;
	left: calc(50% - 70px);
	bottom: 60px;
}*/

#hideMaxillaDiv {
	left: calc(50% - 215px);
	top: 40px;
}

#hideMandibleDiv {
	left: calc(50% - 105px);
	top: 40px;
}

#hideUpperDiv {
	left: calc(50% + 5px);
	top: 40px;
}

#hideLowerDiv {
	left: calc(50% + 115px);
	top: 40px;
}

#hideLowerLabel {
	width: 100px;
	margin-left: -10px;
}

#hideNervesDiv {
	left: calc(50% - 155px);
	top: 115px;
}

#hideGingivaeDiv {
	left: calc(50% - 45px);
	top: 115px;
}

#hideAirwaysDiv {
	left: calc(50% + 65px);
	top: 115px;
}
</style>