<template>
	<div id="orthoPlanExtractDiv" class="toggle-div" v-bind:style="{display: editContext.display}"
		 v-on:click.stop="switchExtract()">
		<input id="orthoPlanExtractButton"
			   type="checkbox"/>
		<div class="toggle-title">EXTRACT</div>
	</div>
</template>

<script>
import { toggleExtract } from "./OrthoPlanMode";

export default {
	name: "OrthoPlanExtract",
	props: ["editContext"],
	components: {},
	methods: {
		switchExtract: function() {
			toggleExtract(this.editContext.tooth);
		}
	}
};
</script>

<style scoped>

#orthoPlanExtractDiv {
	display: none;
	left: calc(50% + 65px);
	top: 79px;
	position: absolute;
	text-align: center;
	z-index: 9;
	color: #ffffff; /*#797979;*/
}

/* .toggle-title {
	margin-left: -0px;
} */

</style>