function adjustModel(model) {
  if (!model || !model.data || model.data.isDemo) {
    return;
  }

  if (!model.data.bones) {
    model.data.bones = [];
  }
  if (model.data.bones.length === 0) {
    model.data.bones.push({
      name: "Maxilla",
      filename: "maxilla.stl",
    });
    model.data.bones.push({
      name: "Mandibula",
      filename: "mandibula.stl",
    });
  }

  if (!model.data.nerves) {
    model.data.nerves = [];
  }
  if (model.data.nerves.length === 0) {
    model.data.nerves.push({
      name: "Nerve",
      filename: "merged_canals.stl",
    });
  }

  if (!model.data.gingivae || model.data.gingivae.length === 0) {
    if (!model.data.gingivae) {
      model.data.gingivae = [];
    }
    model.data.gingivae.push({
      name: "Lower Gingiva",
      filename: "lower.gingiva.stl",
    });
    model.data.gingivae.push({
      name: "Upper Gingiva",
      filename: "upper.gingiva.stl",
    });
  }

  if (!model.data.airways || model.data.airways.length === 0) {
    if (!model.data.airways) {
      model.data.airways = [];
    }
    model.data.airways.push({
      name: "Airway",
      filename: "airways.stl",
    });
  }

  // model.data.dentations.forEach((dentation) => {
  //   if (dentation.name === "Maxilla" && dentation.groups.length === 0) {
  //     addDentationGroup(dentation, 11, 19);
  //     addDentationGroup(dentation, 21, 29);
  //   }
  //   if (dentation.name === "Mandible" && dentation.groups.length === 0) {
  //     addDentationGroup(dentation, 31, 39);
  //     addDentationGroup(dentation, 41, 49);
  //   }
  // });
}

function addDentationGroup(dentation, min, max) {
  let group = { name: `${min}-${max}`, teeth: [] };
  for (let i = min; i <= max; i++) {
    group.teeth.push({
      name: `${i}`,
      filename: `tooth_${i}.stl`,
    });
  }
  dentation.groups.push(group);
}

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export { adjustModel, generateUUID };
