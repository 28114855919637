<template>
    <div class="item-container" v-if="!item.isDeleted" @mouseover="mouseOver" @mouseleave="mouseleave" v-bind:style="{
        backgroundColor: item.isHovered ? ' #007bff' : '#77a7fd',
        flexDirection: item.isTooth ? 'column' : 'row'
    }">
        <!-- color: item.hasFigures ? 'white' : '#A9A9A9', -->

        <div class="item-header">
            <img class="item-btn item-visible" v-on:click="isVisibleChanged()" title="Visible"
                :src="item.isVisible ? 'images/visible_on_white.png' : 'images/visible_off_white.png'" />
            <span class="item-color" ref="itemColor" :style="{ backgroundColor: item.color }" v-on:click="changeColor()"
                title="Change Color"></span>
            <div class="an-name" :title="item.displayName">{{ item.wasChanged ? "* " : "" }}{{ item.displayName }}
            </div>
            <!-- <img v-if="item.isBone" class="item-btn" v-on:click="edit()" title="Edit"
                :src="item.isEdited ? 'images/annotation_red.png' : 'images/annotation_white.png'" /> -->

        </div>

        <div class="item-tools" v-if="item.isTooth">

            <img v-if="item.isTooth" class="item-btn item-settings" v-on:click="editSettings()" title="Edit Settings"
                :src="'images/gear_white_icon.png'" />
            <img v-if="item.isTooth" class="item-btn item-focus" v-on:click="focus()" title="Focus"
                :src="item.isFocused ? 'images/focus_red.png' : 'images/focus_white.png'" />
            <!-- <img v-if="item.isTooth" class="item-btn" v-on:click="edit()" title="Edit"
                :src="item.isEdited ? 'images/annotation_red.png' : 'images/annotation_white.png'" /> -->
            <img v-if="item.isTooth" class="item-btn" v-on:click="remove()" title="Delete"
                :src="'images/trash_icon.png'" />

        </div>

    </div>
</template>

<script>
import swal from "sweetalert";
import {
    highlightItem, unhighlightItem, focusItem,
    unfocusItem, deleteItem, editItemSettings
} from "./TeethListManager";

export default {
    name: "TeethListItem",
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isMouseOver: false
        };
    },
    watch: {
        "item.isHovered": {
            handler(newVal, oldVal) {
                if (newVal) {
                    highlightItem(this.item);
                    if (!this.isMouseOver) {
                        this.$el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                    }
                }
                else {
                    unhighlightItem(this.item);
                }
            }
        },
    },
    methods: {
        mouseOver: function () {
            this.isMouseOver = true;
            this.item.isHovered = true;

        },
        mouseleave: function () {
            this.isMouseOver = false;
            this.item.isHovered = false;
        },
        download: function () {
        },
        editSettings: function () {
            if (!this.item.isTooth)
                return;
            editItemSettings(this.item);
        },
        edit: function () {
            if (!this.item.isTooth && !this.item.isBone)
                return;
            this.item.isEdited = !this.item.isEdited;
        },
        focus: function () {
            if (!this.item.isTooth)
                return;
            this.item.isFocused = !this.item.isFocused;
            if (this.item.isFocused) {
                focusItem(this.item);
            } else {
                unfocusItem(this.item);
            }
        },
        isVisibleChanged: function () {
            this.item.isVisible = !this.item.isVisible;
            itemVisibilityChanged(this.item, this.item.color, vueApp.teethOpacity);
        },
        remove: function () {
            if (!this.item.isTooth)
                return;
            swal({
                text: "Are you sure you want to delete this tooth?",
                buttons: {
                    yes: { text: "Yes", value: true },
                    no: { text: "No", value: false }
                }
            }).then(willDelete => {
                if (willDelete) {
                    deleteItem(this.item);
                }
            });
        },
        changeColor: function () {
            console.log("changeColor", this.item.filename);
            openColorPicker(this.item.id);

            // setTimeout(() => {
            // 	openColorPicker(this.item.id);
            // }, 100);
        }
    }
}

</script>

<style scoped>
.item-container {
    color: white;
    padding: 0.2rem;
    border: solid white 0.1rem;
    display: flex;
    gap: 3px;
    /* flex-direction: column; */
}

.item-header {
    display: flex;
    align-items: stretch;
    gap: 5px;
    width: 100%;
}

.item-tools {
    display: flex;
    align-items: stretch;
    gap: 14px;
    margin-left: 3px;
}

.item-selected {
    display: block;
}

.item-color {
    margin-top: 2px;
    margin-left: 8px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.item-download {
    margin-top: 3px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    /* display: inline-block; */
}

.an-name {
    margin-top: 1px;
    white-space: nowrap;
    /* text-wrap: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    flex-grow: 1;
    max-width: calc(100% - 68px);
}

.item-btn {
    margin-top: 1px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.item-visible {
    margin-top: 0px;
    height: 25px;
    width: 25px;
}

.item-focus {
    margin-top: 0px;
    height: 23px;
    width: 23px;
}

.item-settings {
    margin-top: 2px;
}
</style>