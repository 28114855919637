<template>
	<div class="modal fade"
		 id="shareLinkDialog"
		 tabindex="-1"
		 role="dialog"
		 aria-labelledby="shareLinkDialogTitle"
		 aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div class="modal-content dialog-content">
				<div class="modal-body">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="modal-title"
						 id="shareLinkDialogTitle"
						 style="font-weight:bold">Share this case as a link.</div>
					<div style="font-size:12px; margin-bottom:7px">This link directs to CephX viewer.</div>

					<AddThis publicId="ra-5d5d06bf44a7d93c"
							 data-title="This link directs to CephX viewer"
							 data-description=""
							 data-media="" />
					<!--<div class="addthis_inline_share_toolbox"
					data-title="This link directs to CephX viewer"></div>-->
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import AddThis from 'vue-simple-addthis-share';

	export default {
		name: "ShareLinkDialog",
		components: {
			AddThis
		},
		props: []
	};
</script>

<!--<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d5d06bf44a7d93c"></script>-->


<style scoped>
</style>
