<template>
	<div id="saveButtonsDiv" class="opacity-ctrl hide-mode-item color-mode-item">
		<div id="saveButtons">
			<button id="globalSave" class="save-button" v-on:click="globalSave()">
				SAVE
			</button>

			<button id="globalCancel" class="save-button"
				v-on:click="globalCancel()">
				CANCEL
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "SaveButtons",
	methods: {
		globalSave: function() {
			// var inMode = this.isHideMode || this.isColorMode || this.isOpacityMode;
			vueApp.leaveModes();
			vueApp.saveData();
			// if (inMode && this.activeMode && this.activeMode.enterMode) {
			// 	this.activeMode.enterMode();
			// }
		},
		globalCancel: function() {
            resetView();
			//vueApp.leaveModes();
		}
	}
};
</script>

<style>
#saveButtonsDiv {
	position: absolute;
	text-align: center;
	left: calc(50% - 75px);
	bottom: 70px;
	z-index: 9;
	display: none;
	width: 330px;
	height: 35px;
}

#saveButtons {
	position: absolute;
	max-width: 330px;
	height: 35px;
	left: -5px;
	text-align: center;
	white-space: nowrap;
}

.save-button {
	display: inline-block;
	margin-right: 10px;
	min-width: 90px;
	width: auto;
	height: 35px;
	border: 0;
	cursor: pointer;
	font-size: small;
	background-color: #4687fc; /*#F7FAFF;*/
	text-align: center;
	color: #ffffff;
}
</style>