import axios from "axios";
// import {getItem} from '@/helpers/persistanceStorage';

axios.defaults.baseURL =
  window.location.href.includes("192.168.0.") ||
  window.location.href.includes("172.30.5.") ||
  window.location.href.includes("localhost")
    ? "https://localhost:7060" // "http://localhost:5000" "https://localhost:7060"
    : window.location.origin;

axios.interceptors.request.use((config) => {
  const token = null; //getItem('accessToken')
  const authorizisationToken = token ? `Token ${token}` : "";
  config.headers.Authorization = authorizisationToken;
  return config;
});

export default axios;
