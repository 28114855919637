const state = {
  isAxial: false,
  isSaggital: false,
  isCoronal: false,
};

export const mutationTypes = {
  isAxialChange: "[dicom] isAxialChange",
  isSaggitalChange: "[dicom] isSaggitalChange",
  isCoronalChange: "[dicom] isCoronalChange",
  closeProjection: "[dicom] closeProjection",
};

const mutations = {
  [mutationTypes.isAxialChange](state, newValue) {
    state.isAxial = newValue;
  },
  [mutationTypes.isSaggitalChange](state, newValue) {
    state.isSaggital = newValue;
  },
  [mutationTypes.isCoronalChange](state, newValue) {
    state.isCoronal = newValue;
  },
  [mutationTypes.closeProjection](state, appIndex) {
    switch (appIndex) {
      case 0:
        state.isAxial = false;
        break;
      case 1:
        state.isSaggital = false;
        break;
      case 2:
        state.isCoronal = false;
        break;
      default:
        break;
    }
  },
};

export default {
  state,
  mutations,
};
