import ToothAddDialog from "./ToothAddDialog.vue";
import { sendAddToothRequest, sendSmoothStlRequest } from "@/api/toothApi";
import { simplifyGeometry } from "@/components/geometry";

let controlsInitialized = false;
let ToothAddMode = {
  newTooth: {
    fileName: "Select *.stl file",
    file: null,
    number: 0,
    numberType: "primary",
    remarks: "",
    smooth: true,
  },
};

function initializeControls() {
  if (!controlsInitialized) {
    var ctrls = [ToothAddDialog];
    ctrls.forEach((component) => {
      const componentInstance = new Vue({
        ...component,
        propsData: { mode: ToothAddMode },
      });
      const vueContainer = document.createElement("div");
      document.getElementById("stl_wrapper").appendChild(vueContainer);
      componentInstance.$mount(vueContainer);
    });
    controlsInitialized = true;
  }
}

function showToothAddDialog() {
  initializeControls();
  //   ToothAddMode.newTooth.number = 0;
  //   ToothAddMode.newTooth.numberType = "primary";
  //   ToothAddMode.newTooth.remarks = "";
  //   ToothAddMode.newTooth.fileName = "Select *.stl file";
  $("#toothAddDialog").modal();
}

async function addTooth(newTooth) {
  console.log("addTooth", newTooth);
  if (newTooth && newTooth.file && newTooth.number) {
    if (newTooth.smooth) {
      const response = await sendSmoothStlRequest(newTooth.file, 5);
      if (response.status == 200) {
        let smoothedFile = new File([response.data], newTooth.file.name);
        newTooth.file = smoothedFile;
      }
    }

    sendAddToothRequest(newTooth);

    let toothNumber = newTooth.number;
    let addedTag = vueApp.generateAddedTag(toothNumber);
    let fileName = `tooth_${toothNumber}-${addedTag}`;
    let numberType = newTooth.numberType;
    if (numberType && numberType.trim() && numberType != "primary")
      fileName = `${fileName}-${numberType}`;

    let remarks = newTooth.remarks;
    if (remarks && remarks.trim()) fileName = `${fileName}-${remarks}`;

    var tooth = {
      id: vueApp.generateNewItemId(),
      number: +newTooth.number,
      savedNumber: +newTooth.number,
      name: `${newTooth.number}`,
      filename: `${fileName}.stl`,
      addedTag: addedTag,
      isVisible: true,
      isLoaded: false,
      isFromData: false,
      isTooth: true,
      color: vueApp.teethColor,
      randomColor: getRandomColor(),
      isAdded: true,
    };
    tooth.isMaxilla = tooth.name.startsWith("1") || tooth.name.startsWith("2");
    tooth.dentition = tooth.isMaxilla ? "Maxilla" : "Mandible";
    tooth.stlModelLoaded = initNewTooth;

    //vueApp.crowns.push(tooth);
    vueApp.addTooth(tooth);

    var stlModel = { local_file: newTooth.file, color: vueApp.teethColor };
    stlModel.id = tooth.id;
    viewer.add_model(stlModel);
  }
}

function initNewTooth(model_id) {
  console.log("initNewTooth", model_id);
  let tooth = vueApp.getItemById(model_id);
  tooth.stlModelLoaded = null;
  let vmesh = viewer.get_model_mesh(tooth.id);
  if (vmesh && vmesh.geometry && vmesh.geometry.boundingBox) {
    const rotate180 = Math.PI;
    viewer.rotate(tooth.id, 0, rotate180, 0);
    viewer.rotate(tooth.id, rotate180, 0, 0);

    let box = new THREE.Box3().setFromObject(vmesh);
    // console.log("box", box);
    // drawBox(box, 0xff0000);

    let xOffset = vueApp.initPosition.x;
    let yOffset = vueApp.initPosition.y;
    let zOffset = vueApp.initPosition.z;

    box = rotateBox(box, xOffset, yOffset, zOffset);

    viewer.rotate(tooth.id, 0, rotateY, 0);
    viewer.rotate(tooth.id, rotateX, 0, 0);
    viewer.set_position(tooth.id, xOffset, yOffset, zOffset);
    tooth.defaultBox = box;
    tooth.uuid = vmesh.uuid;

    vmesh.updateMatrix();
    vmesh.geometry.applyMatrix4(vmesh.matrix);
    vmesh.position.set(0, 0, 0);
    vmesh.rotation.set(0, 0, 0);
    vmesh.scale.set(1, 1, 1);
    vmesh.geometry.center();
    vmesh.updateMatrix();
    vmesh.position.set(box.center.x, box.center.y, box.center.z);
    tooth.defaultPosition = {
      x: box.center.x,
      y: box.center.y,
      z: box.center.z,
    };

    vmesh.material.flatShading = false;
    vmesh.geometry.computeVertexNormals();

    if (tooth.simplified) {
      let elementGeometry = new THREE.BufferGeometry().fromGeometry(
        vmesh.geometry
      );
      elementGeometry = elementGeometry.toIndexed();
      simplifyGeometry(elementGeometry, 1);
      vmesh.geometry = elementGeometry;
      elementGeometry.buffersNeedUpdate = true;
    }

    if (tooth.isTooth) {
      var clone_geometry = vmesh.geometry.clone();
      var clone_material = vmesh.material.clone();
      clone_material.color.set(0xffffff);
      clone_material.opacity = 0.0001;
      clone_material.transparent = true;
      var defaultMesh = new THREE.Mesh(clone_geometry, clone_material);
      defaultMesh.visible = false;
      viewer.scene.add(defaultMesh);
      defaultMesh.position.set(box.center.x, box.center.y, box.center.z);
      tooth.defaultMeshId = defaultMesh.id;
      defaultMesh.isVisible = false;

      setToothMidData(tooth);
      var apexMesh = drawPoint(tooth.apex, 0x0000ff, true, 0.01);
      vmesh.add(apexMesh);
      apexMesh.position.set(
        tooth.apex.x - box.center.x,
        tooth.apex.y - box.center.y,
        tooth.apex.z - box.center.z
      );
      vmesh.apexMesh = apexMesh;

      var rootMesh = drawPoint(tooth.root, 0xff0000, true, 0.01);
      vmesh.add(rootMesh);
      rootMesh.position.set(
        tooth.root.x - box.center.x,
        tooth.root.y - box.center.y,
        tooth.root.z - box.center.z
      );
      vmesh.rootMesh = rootMesh;
    }

    viewer.set_color(model_id, "#FF0000");
    vueApp.switchToDefaultView();
    // vueApp.lookAtPoint(box.center);
    viewer.camera.lookAt(box.center.x, box.center.y, box.center.z);

    setTimeout(function () {
      vueApp.$emit("toothAdded", tooth);
      let color = vueApp.getActualColor(tooth);
      viewer.set_color(model_id, color);
    }, 500);

    // console.log(model);
    // sendAddToothRequest(model);
  }
}

export { ToothAddMode, showToothAddDialog, addTooth };
